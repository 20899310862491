import { ACCESS_TOKEN, SSO_INFO, USER_INFO } from "./Contants";
//var CryptoJS = require("crypto-js");
class LocalStorage {
  aseKey: string = "36344330023443252425b66617756134";

  get ssoInfo(): any {
    return JSON.parse(localStorage.getItem(SSO_INFO)!);
  }
  set ssoInfo(value: any) {
    if (value == null) localStorage.removeItem(SSO_INFO);
    else localStorage.setItem(SSO_INFO, value);
  }

  get userInfo(): any {
    return JSON.parse(localStorage.getItem(USER_INFO)!!);
  }

  set userInfo(value: any) {
    if (value == null) localStorage.removeItem(USER_INFO);
    else localStorage.setItem(USER_INFO, value);
  }

  get accessToken(): any {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  set accessToken(value: string) {
    if (value == null) localStorage.removeItem(ACCESS_TOKEN);
    else localStorage.setItem(ACCESS_TOKEN, value);
  }

  // encryptString(data: string) {
  //   // return data;
  //   return CryptoJS.AES.encrypt(
  //     data.toString(),
  //     CryptoJS.enc.Utf8.parse(this.aseKey),
  //     {
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   ).toString();
  // }

  // decryptString(encryptString: string) {
  //   //return encryptString;
  //   return CryptoJS.AES.decrypt(
  //     encryptString,
  //     CryptoJS.enc.Utf8.parse(this.aseKey),
  //     {
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   ).toString(CryptoJS.enc.Utf8);
  // }
}

export const instance: LocalStorage = new LocalStorage();
