/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPayloadModel
 */
export interface UserPayloadModel {
    /**
     * 
     * @type {string}
     * @memberof UserPayloadModel
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPayloadModel
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPayloadModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPayloadModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPayloadModel
     */
    resId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPayloadModel
     */
    ssoId?: string;
}

export function UserPayloadModelFromJSON(json: any): UserPayloadModel {
    return UserPayloadModelFromJSONTyped(json, false);
}

export function UserPayloadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPayloadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'resId': !exists(json, 'resId') ? undefined : json['resId'],
        'ssoId': !exists(json, 'ssoId') ? undefined : json['ssoId'],
    };
}

export function UserPayloadModelToJSON(value?: UserPayloadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'isAdmin': value.isAdmin,
        'name': value.name,
        'password': value.password,
        'resId': value.resId,
        'ssoId': value.ssoId,
    };
}

