/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    DiscountDtoLite,
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';

/**
 * 
 * @export
 * @interface MarketingEventDtoLite
 */
export interface MarketingEventDtoLite {
    /**
     * 
     * @type {Array<number>}
     * @memberof MarketingEventDtoLite
     */
    articleIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    bannerContent?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    bannerPosition?: number;
    /**
     * 
     * @type {CategoryDto}
     * @memberof MarketingEventDtoLite
     */
    category?: CategoryDto;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof MarketingEventDtoLite
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    isMegaSale?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    promotionCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    status?: string;
}

export function MarketingEventDtoLiteFromJSON(json: any): MarketingEventDtoLite {
    return MarketingEventDtoLiteFromJSONTyped(json, false);
}

export function MarketingEventDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDtoLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleIds': !exists(json, 'articleIds') ? undefined : json['articleIds'],
        'bannerContent': !exists(json, 'bannerContent') ? undefined : json['bannerContent'],
        'bannerPosition': !exists(json, 'bannerPosition') ? undefined : json['bannerPosition'],
        'category': !exists(json, 'category') ? undefined : CategoryDtoFromJSON(json['category']),
        'discount': !exists(json, 'discount') ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': !exists(json, 'expireBannerContent') ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': !exists(json, 'expireBannerEndDate') ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': !exists(json, 'isMegaSale') ? undefined : json['isMegaSale'],
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'marketingEventName': !exists(json, 'marketingEventName') ? undefined : json['marketingEventName'],
        'marketingEventPriority': !exists(json, 'marketingEventPriority') ? undefined : json['marketingEventPriority'],
        'mobileBannerContent': !exists(json, 'mobileBannerContent') ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': !exists(json, 'mobileExpireBannerContent') ? undefined : json['mobileExpireBannerContent'],
        'promotionCode': !exists(json, 'promotionCode') ? undefined : json['promotionCode'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MarketingEventDtoLiteToJSON(value?: MarketingEventDtoLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleIds': value.articleIds,
        'bannerContent': value.bannerContent,
        'bannerPosition': value.bannerPosition,
        'category': CategoryDtoToJSON(value.category),
        'discount': DiscountDtoLiteToJSON(value.discount),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'expireBannerContent': value.expireBannerContent,
        'expireBannerEndDate': value.expireBannerEndDate === undefined ? undefined : (value.expireBannerEndDate.toISOString()),
        'isMegaSale': value.isMegaSale,
        'marketingEventId': value.marketingEventId,
        'marketingEventName': value.marketingEventName,
        'marketingEventPriority': value.marketingEventPriority,
        'mobileBannerContent': value.mobileBannerContent,
        'mobileExpireBannerContent': value.mobileExpireBannerContent,
        'promotionCode': value.promotionCode,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
    };
}

