/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationMapping,
    OrganizationMappingFromJSON,
    OrganizationMappingFromJSONTyped,
    OrganizationMappingToJSON,
} from './OrganizationMapping';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    bannerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    logoUrl?: string;
    /**
     * 
     * @type {Array<OrganizationMapping>}
     * @memberof Organization
     */
    mainOrganizationMappingByOrganizationId?: Array<OrganizationMapping>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    organizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    status?: string;
    /**
     * 
     * @type {Array<OrganizationMapping>}
     * @memberof Organization
     */
    subOrganizationMappingByOrganizationId?: Array<OrganizationMapping>;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerUrl': !exists(json, 'bannerUrl') ? undefined : json['bannerUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'mainOrganizationMappingByOrganizationId': !exists(json, 'mainOrganizationMappingByOrganizationId') ? undefined : ((json['mainOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'serverId': !exists(json, 'serverId') ? undefined : json['serverId'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subOrganizationMappingByOrganizationId': !exists(json, 'subOrganizationMappingByOrganizationId') ? undefined : ((json['subOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingFromJSON)),
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerUrl': value.bannerUrl,
        'description': value.description,
        'fullName': value.fullName,
        'logoUrl': value.logoUrl,
        'mainOrganizationMappingByOrganizationId': value.mainOrganizationMappingByOrganizationId === undefined ? undefined : ((value.mainOrganizationMappingByOrganizationId as Array<any>).map(OrganizationMappingToJSON)),
        'name': value.name,
        'organizationId': value.organizationId,
        'seq': value.seq,
        'serverId': value.serverId,
        'shortName': value.shortName,
        'status': value.status,
        'subOrganizationMappingByOrganizationId': value.subOrganizationMappingByOrganizationId === undefined ? undefined : ((value.subOrganizationMappingByOrganizationId as Array<any>).map(OrganizationMappingToJSON)),
    };
}

