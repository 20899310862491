/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubOrganizationDto,
    SubOrganizationDtoFromJSON,
    SubOrganizationDtoFromJSONTyped,
    SubOrganizationDtoToJSON,
} from './SubOrganizationDto';

/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    bannerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    organizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    shortName?: string;
    /**
     * 
     * @type {Array<SubOrganizationDto>}
     * @memberof OrganizationDto
     */
    subOrganization?: Array<SubOrganizationDto>;
}

export function OrganizationDtoFromJSON(json: any): OrganizationDto {
    return OrganizationDtoFromJSONTyped(json, false);
}

export function OrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerUrl': !exists(json, 'bannerUrl') ? undefined : json['bannerUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'subOrganization': !exists(json, 'subOrganization') ? undefined : ((json['subOrganization'] as Array<any>).map(SubOrganizationDtoFromJSON)),
    };
}

export function OrganizationDtoToJSON(value?: OrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerUrl': value.bannerUrl,
        'description': value.description,
        'fullName': value.fullName,
        'logoUrl': value.logoUrl,
        'name': value.name,
        'organizationId': value.organizationId,
        'seq': value.seq,
        'shortName': value.shortName,
        'subOrganization': value.subOrganization === undefined ? undefined : ((value.subOrganization as Array<any>).map(SubOrganizationDtoToJSON)),
    };
}

