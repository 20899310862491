/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import {
    Hashtag,
    HashtagFromJSON,
    HashtagFromJSONTyped,
    HashtagToJSON,
} from './Hashtag';

/**
 * 
 * @export
 * @interface ArticleHashtag
 */
export interface ArticleHashtag {
    /**
     * 
     * @type {Article}
     * @memberof ArticleHashtag
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof ArticleHashtag
     */
    articleId?: number;
    /**
     * 
     * @type {Hashtag}
     * @memberof ArticleHashtag
     */
    hashtagByHashtagId?: Hashtag;
    /**
     * 
     * @type {number}
     * @memberof ArticleHashtag
     */
    hashtagId?: number;
}

export function ArticleHashtagFromJSON(json: any): ArticleHashtag {
    return ArticleHashtagFromJSONTyped(json, false);
}

export function ArticleHashtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleHashtag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleByArticleId': !exists(json, 'articleByArticleId') ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'hashtagByHashtagId': !exists(json, 'hashtagByHashtagId') ? undefined : HashtagFromJSON(json['hashtagByHashtagId']),
        'hashtagId': !exists(json, 'hashtagId') ? undefined : json['hashtagId'],
    };
}

export function ArticleHashtagToJSON(value?: ArticleHashtag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value.articleByArticleId),
        'articleId': value.articleId,
        'hashtagByHashtagId': HashtagToJSON(value.hashtagByHashtagId),
        'hashtagId': value.hashtagId,
    };
}

