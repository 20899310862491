/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PageSetting,
    PageSettingFromJSON,
    PageSettingFromJSONTyped,
    PageSettingToJSON,
} from './PageSetting';

/**
 * 
 * @export
 * @interface AdminMemberActionLogDataDto
 */
export interface AdminMemberActionLogDataDto {
    /**
     * 
     * @type {PageSetting}
     * @memberof AdminMemberActionLogDataDto
     */
    pageSetting?: PageSetting;
}

export function AdminMemberActionLogDataDtoFromJSON(json: any): AdminMemberActionLogDataDto {
    return AdminMemberActionLogDataDtoFromJSONTyped(json, false);
}

export function AdminMemberActionLogDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMemberActionLogDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSetting': !exists(json, 'pageSetting') ? undefined : PageSettingFromJSON(json['pageSetting']),
    };
}

export function AdminMemberActionLogDataDtoToJSON(value?: AdminMemberActionLogDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSetting': PageSettingToJSON(value.pageSetting),
    };
}

