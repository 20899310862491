/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BookDetailsReportResponseModel,
    BookDetailsReportResponseModelFromJSON,
    BookDetailsReportResponseModelFromJSONTyped,
    BookDetailsReportResponseModelToJSON,
} from './BookDetailsReportResponseModel';

/**
 * 
 * @export
 * @interface ReserveBookResponseModel
 */
export interface ReserveBookResponseModel {
    /**
     * 
     * @type {Array<BookDetailsReportResponseModel>}
     * @memberof ReserveBookResponseModel
     */
    data?: Array<BookDetailsReportResponseModel>;
    /**
     * 
     * @type {number}
     * @memberof ReserveBookResponseModel
     */
    pageTotal?: number;
}

export function ReserveBookResponseModelFromJSON(json: any): ReserveBookResponseModel {
    return ReserveBookResponseModelFromJSONTyped(json, false);
}

export function ReserveBookResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReserveBookResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(BookDetailsReportResponseModelFromJSON)),
        'pageTotal': !exists(json, 'pageTotal') ? undefined : json['pageTotal'],
    };
}

export function ReserveBookResponseModelToJSON(value?: ReserveBookResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(BookDetailsReportResponseModelToJSON)),
        'pageTotal': value.pageTotal,
    };
}

