/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleDto,
    ArticleDtoFromJSON,
    ArticleDtoFromJSONTyped,
    ArticleDtoToJSON,
} from './ArticleDto';
import {
    DiscountDtoLite,
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import {
    MemberLevelDto,
    MemberLevelDtoFromJSON,
    MemberLevelDtoFromJSONTyped,
    MemberLevelDtoToJSON,
} from './MemberLevelDto';

/**
 * 
 * @export
 * @interface MarketingEventDto
 */
export interface MarketingEventDto {
    /**
     * 
     * @type {Array<ArticleDto>}
     * @memberof MarketingEventDto
     */
    articles?: Array<ArticleDto>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    bannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    bannerLinkTo?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    bannerPosition?: number;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof MarketingEventDto
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    isMegaSale?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {Array<MemberLevelDto>}
     * @memberof MarketingEventDto
     */
    memberLevels?: Array<MemberLevelDto>;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof MarketingEventDto
     */
    members?: Array<MemberDto>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    promotionCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    status?: string;
}

export function MarketingEventDtoFromJSON(json: any): MarketingEventDto {
    return MarketingEventDtoFromJSONTyped(json, false);
}

export function MarketingEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articles': !exists(json, 'articles') ? undefined : ((json['articles'] as Array<any>).map(ArticleDtoFromJSON)),
        'bannerContent': !exists(json, 'bannerContent') ? undefined : json['bannerContent'],
        'bannerLinkTo': !exists(json, 'bannerLinkTo') ? undefined : json['bannerLinkTo'],
        'bannerPosition': !exists(json, 'bannerPosition') ? undefined : json['bannerPosition'],
        'discount': !exists(json, 'discount') ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': !exists(json, 'expireBannerContent') ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': !exists(json, 'expireBannerEndDate') ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': !exists(json, 'isMegaSale') ? undefined : json['isMegaSale'],
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'marketingEventName': !exists(json, 'marketingEventName') ? undefined : json['marketingEventName'],
        'marketingEventPriority': !exists(json, 'marketingEventPriority') ? undefined : json['marketingEventPriority'],
        'memberLevels': !exists(json, 'memberLevels') ? undefined : ((json['memberLevels'] as Array<any>).map(MemberLevelDtoFromJSON)),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(MemberDtoFromJSON)),
        'mobileBannerContent': !exists(json, 'mobileBannerContent') ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': !exists(json, 'mobileExpireBannerContent') ? undefined : json['mobileExpireBannerContent'],
        'promotionCode': !exists(json, 'promotionCode') ? undefined : json['promotionCode'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MarketingEventDtoToJSON(value?: MarketingEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articles': value.articles === undefined ? undefined : ((value.articles as Array<any>).map(ArticleDtoToJSON)),
        'bannerContent': value.bannerContent,
        'bannerLinkTo': value.bannerLinkTo,
        'bannerPosition': value.bannerPosition,
        'discount': DiscountDtoLiteToJSON(value.discount),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'expireBannerContent': value.expireBannerContent,
        'expireBannerEndDate': value.expireBannerEndDate === undefined ? undefined : (value.expireBannerEndDate.toISOString()),
        'isMegaSale': value.isMegaSale,
        'marketingEventId': value.marketingEventId,
        'marketingEventName': value.marketingEventName,
        'marketingEventPriority': value.marketingEventPriority,
        'memberLevels': value.memberLevels === undefined ? undefined : ((value.memberLevels as Array<any>).map(MemberLevelDtoToJSON)),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(MemberDtoToJSON)),
        'mobileBannerContent': value.mobileBannerContent,
        'mobileExpireBannerContent': value.mobileExpireBannerContent,
        'promotionCode': value.promotionCode,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
    };
}

