/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import {
    PointRuleDto,
    PointRuleDtoFromJSON,
    PointRuleDtoFromJSONTyped,
    PointRuleDtoToJSON,
} from './PointRuleDto';

/**
 * 
 * @export
 * @interface OrderDtoForReport
 */
export interface OrderDtoForReport {
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    birthdayDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    convertedCash?: number;
    /**
     * 
     * @type {Country}
     * @memberof OrderDtoForReport
     */
    country?: Country;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    countryId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponCashDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponCodeDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    couponCodeType?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponDiscountPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoForReport
     */
    deliveryDate2nd?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    earnedPoint?: number;
    /**
     * 
     * @type {MemberDto}
     * @memberof OrderDtoForReport
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoForReport
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    orderTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    paymentMethod?: string;
    /**
     * 
     * @type {PointRuleDto}
     * @memberof OrderDtoForReport
     */
    pointRule?: PointRuleDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    shippingMedhod?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDtoForReport
     */
    shippingTrackingNumberList?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    storeWideDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    totalPointUsedToBuy?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    trackingNumber2nd?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    usedPoint?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    vipDiscountPrice?: number;
}

export function OrderDtoForReportFromJSON(json: any): OrderDtoForReport {
    return OrderDtoForReportFromJSONTyped(json, false);
}

export function OrderDtoForReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDtoForReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthdayDiscountPrice': !exists(json, 'birthdayDiscountPrice') ? undefined : json['birthdayDiscountPrice'],
        'convertedCash': !exists(json, 'convertedCash') ? undefined : json['convertedCash'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'couponCashDiscountPrice': !exists(json, 'couponCashDiscountPrice') ? undefined : json['couponCashDiscountPrice'],
        'couponCode': !exists(json, 'couponCode') ? undefined : json['couponCode'],
        'couponCodeDiscountPrice': !exists(json, 'couponCodeDiscountPrice') ? undefined : json['couponCodeDiscountPrice'],
        'couponCodeType': !exists(json, 'couponCodeType') ? undefined : json['couponCodeType'],
        'couponDiscountPrice': !exists(json, 'couponDiscountPrice') ? undefined : json['couponDiscountPrice'],
        'deliveryDate2nd': !exists(json, 'deliveryDate2nd') ? undefined : (new Date(json['deliveryDate2nd'])),
        'earnedPoint': !exists(json, 'earnedPoint') ? undefined : json['earnedPoint'],
        'member': !exists(json, 'member') ? undefined : MemberDtoFromJSON(json['member']),
        'orderAddress': !exists(json, 'orderAddress') ? undefined : json['orderAddress'],
        'orderAddress1': !exists(json, 'orderAddress1') ? undefined : json['orderAddress1'],
        'orderAddress2': !exists(json, 'orderAddress2') ? undefined : json['orderAddress2'],
        'orderAddressRecipient': !exists(json, 'orderAddressRecipient') ? undefined : json['orderAddressRecipient'],
        'orderDate': !exists(json, 'orderDate') ? undefined : (new Date(json['orderDate'])),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : json['orderStatus'],
        'orderTotal': !exists(json, 'orderTotal') ? undefined : json['orderTotal'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'pointRule': !exists(json, 'pointRule') ? undefined : PointRuleDtoFromJSON(json['pointRule']),
        'productTotal': !exists(json, 'productTotal') ? undefined : json['productTotal'],
        'shippingFee': !exists(json, 'shippingFee') ? undefined : json['shippingFee'],
        'shippingMedhod': !exists(json, 'shippingMedhod') ? undefined : json['shippingMedhod'],
        'shippingTrackingNumberList': !exists(json, 'shippingTrackingNumberList') ? undefined : json['shippingTrackingNumberList'],
        'storeWideDiscountPrice': !exists(json, 'storeWideDiscountPrice') ? undefined : json['storeWideDiscountPrice'],
        'totalPointUsedToBuy': !exists(json, 'totalPointUsedToBuy') ? undefined : json['totalPointUsedToBuy'],
        'trackingNumber': !exists(json, 'trackingNumber') ? undefined : json['trackingNumber'],
        'trackingNumber2nd': !exists(json, 'trackingNumber2nd') ? undefined : json['trackingNumber2nd'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
        'vipDiscountPrice': !exists(json, 'vipDiscountPrice') ? undefined : json['vipDiscountPrice'],
    };
}

export function OrderDtoForReportToJSON(value?: OrderDtoForReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthdayDiscountPrice': value.birthdayDiscountPrice,
        'convertedCash': value.convertedCash,
        'country': CountryToJSON(value.country),
        'countryId': value.countryId,
        'couponCashDiscountPrice': value.couponCashDiscountPrice,
        'couponCode': value.couponCode,
        'couponCodeDiscountPrice': value.couponCodeDiscountPrice,
        'couponCodeType': value.couponCodeType,
        'couponDiscountPrice': value.couponDiscountPrice,
        'deliveryDate2nd': value.deliveryDate2nd === undefined ? undefined : (value.deliveryDate2nd.toISOString()),
        'earnedPoint': value.earnedPoint,
        'member': MemberDtoToJSON(value.member),
        'orderAddress': value.orderAddress,
        'orderAddress1': value.orderAddress1,
        'orderAddress2': value.orderAddress2,
        'orderAddressRecipient': value.orderAddressRecipient,
        'orderDate': value.orderDate === undefined ? undefined : (value.orderDate.toISOString()),
        'orderId': value.orderId,
        'orderNo': value.orderNo,
        'orderStatus': value.orderStatus,
        'orderTotal': value.orderTotal,
        'paymentMethod': value.paymentMethod,
        'pointRule': PointRuleDtoToJSON(value.pointRule),
        'productTotal': value.productTotal,
        'shippingFee': value.shippingFee,
        'shippingMedhod': value.shippingMedhod,
        'shippingTrackingNumberList': value.shippingTrackingNumberList,
        'storeWideDiscountPrice': value.storeWideDiscountPrice,
        'totalPointUsedToBuy': value.totalPointUsedToBuy,
        'trackingNumber': value.trackingNumber,
        'trackingNumber2nd': value.trackingNumber2nd,
        'usedPoint': value.usedPoint,
        'vipDiscountPrice': value.vipDiscountPrice,
    };
}

