import BookModel from "../../../model/Book";
import NoteModel from "../../../model/Note";
class SearchUtil {
  static MergeArray(arr1, arr2) {
    var _arr = [];
    for (let item of arr1) {
      _arr.push(item);
    }
    for (let item of arr2) {
      var flag = true;
      for (let subitem of arr1) {
        if (item === subitem) {
          flag = false;
          break;
        }
      }
      if (flag) {
        _arr.push(item);
      }
    }
    return _arr;
  }
  static fuzzyQuery(list, keyWord) {
    var arr = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i].indexOf(keyWord) > -1) {
        arr.push(i);
      }
    }
    return arr;
  }
  static MouseSearch(books) {
    let keyword = (
      document.querySelector(".header-search-box")
    ).value.toLowerCase();
    let bookNameArr = [];
    let AuthorNameArr = [];
    if (!books) return [];
    books.forEach((item) => {
      bookNameArr.push(item.name.toLowerCase());
      AuthorNameArr.push(item.author.toLowerCase());
    });
    let bookResults = this.fuzzyQuery(bookNameArr, keyword);
    let authorResults = this.fuzzyQuery(AuthorNameArr, keyword);

    return this.MergeArray(bookResults, authorResults);
  }
  static KeySearch(event, books) {
    if (event && event.keyCode === 13) {
      let bookNameArr = [];
      let AuthorNameArr = [];
      if (!books) return [];

      books.forEach((item) => {
        bookNameArr.push(item.name.toLowerCase());
        AuthorNameArr.push(item.author.toLowerCase());
      });
      let bookResults = this.fuzzyQuery(
        bookNameArr,
        event.target.value.toLowerCase()
      );
      let authorResults = this.fuzzyQuery(
        AuthorNameArr,
        event.target.value.toLowerCase()
      );

      return this.MergeArray(bookResults, authorResults);
    }
  }
  static MouseNoteSearch(notes) {
    let keyword = (
      document.querySelector(".header-search-box")
    ).value.toLowerCase();
    let noteArr = [];
    let textArr = [];
    notes.forEach((item) => {
      noteArr.push(item.notes.toLowerCase());
      textArr.push(item.text.toLowerCase());
    });
    let noteResults = this.fuzzyQuery(noteArr, keyword);
    let textResults = this.fuzzyQuery(textArr, keyword);
    return this.MergeArray(noteResults, textResults);
  }
  static KeyNoteSearch(event, notes) {
    if (event && event.keyCode === 13) {
      let noteArr = [];
      let textArr = [];
      notes.forEach((item) => {
        noteArr.push(item.notes.toLowerCase());
        textArr.push(item.text.toLowerCase());
      });
      let noteResults = this.fuzzyQuery(
        noteArr,
        event.target.value.toLowerCase()
      );
      let textResults = this.fuzzyQuery(
        textArr,
        event.target.value.toLowerCase()
      );
      return this.MergeArray(noteResults, textResults);
    }
  }
}
export default SearchUtil;
