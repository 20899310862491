//添加笔记的弹窗
import localforage from "localforage";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import Note from "../../../../model/Note";
import {
  handleFetchNotes,
  handleMenuMode,
  handleNoteKey,
  handleOpenMenu,
  handleReaderLog,
} from "../../../../redux/actions/reader";
import { getHightlightCoords } from "../../../../utils/reader/fileUtils/pdfUtil";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import NoteTag from "../../noteTag/component";
import "./popupNote.css";

class PopupNote extends React.Component {
  constructor(props) {
    //console.log("PopupNote", props);
    super(props);
    this.state = { tag: [] };
  }
  componentDidMount() {
    let textArea = document.querySelector(".editor-box");
    textArea && textArea.focus();
  }
  handleTag = (tag) => {
    this.setState({ tag });
  };
  createNote() {
    let notes = document.querySelector(".editor-box").value;
    //console.log("this.props.noteKey", this.props.noteKey);
    // if (this.props.noteKey) {
    //   //console.log("createNote 编辑笔记", this.props.noteKey);
    //   //编辑笔记
    //   this.props.notes.forEach((item) => {
    //     if (item.key === this.props.noteKey) {
    //       item.notes = notes;
    //       item.tag = this.state.tag;
    //     }
    //   });
    //   localforage.setItem("notes", this.props.notes).then(() => {
    //     this.props.handleOpenMenu(false);
    //     //alert("Add Successfully");
    //     this.props.handleFetchNotes();
    //     this.props.handleMenuMode("highlight");
    //     this.props.handleNoteKey("");
    //   });
    // } else {
    //console.log("createNote 创建笔记");
    //创建笔记
    console.log("创建笔记");
    let bookKey = this.props.currentBook.key;
    let cfi = "";
    if (this.props.currentBook.format === "EPUB") {
      cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
    } else if (this.props.currentBook.format === "PDF") {
      cfi = JSON.stringify(
        RecordLocation.getPDFLocation(this.props.currentBook.md5)
      );
    } else {
      cfi = JSON.stringify(
        RecordLocation.getHtmlLocation(this.props.currentBook.key)
      );
    }
    /*
      let pageArea = document.getElementById("page-area");
      if (!pageArea) return;
      let iframe = pageArea.getElementsByTagName("iframe")[this.props.leftOrRight];
      if (!iframe) return;
      let doc = iframe.contentDocument;
      if (!doc) {
        return;
      }
      let charRange;
      if (this.props.currentBook.format !== "PDF") {
        charRange = window.rangy
          .getSelection(iframe)
          .saveCharacterRanges(doc.body)[0];
      }
      */
    let _localStorage_range = {};
    try {
      _localStorage_range = JSON.parse(localStorage.getItem("range"));
    } catch (err) {
      console.log("err", err);
    }
    console.log("createNote, localStorage range 111", _localStorage_range);

    let range =
      this.props.currentBook.format === "PDF"
        ? JSON.stringify(getHightlightCoords())
        : JSON.stringify(_localStorage_range);
    //let text = doc.getSelection()?.toString();
    let text = localStorage.getItem("selectionaa");
    if (!text) {
      return;
    }
    text = text.replace(/\s\s/g, "");
    text = text.replace(/\r/g, "");
    text = text.replace(/\n/g, "");
    text = text.replace(/\t/g, "");
    text = text.replace(/\f/g, "");
    let percentage = 0;
    if (this.props.currentBook.format === "EPUB") {
      percentage = RecordLocation.getCfi(this.props.currentBook.key).percentage
        ? RecordLocation.getCfi(this.props.currentBook.key).percentage
        : 0;
    }

    let color = this.props.color || 0;
    let tag = this.state.tag;

    let note = new Note(
      bookKey,
      this.props.chapter,
      this.props.chapterIndex,
      text,
      cfi,
      range,
      notes,
      percentage,
      color,
      this.props.leftOrRight,
      tag
    );

    let noteArr = this.props.notes;
    noteArr.push({ ...note });

    localforage.setItem("notes", noteArr).then(() => {
      this.props.handleOpenMenu(false);
      //alert("Add Successfully");
      console.log("createNote 创建笔记 Add Successfully", this.props);

      if (this.props.readerLog !== undefined) {
        let readerLogObj = this.props.readerLog;
        readerLogObj.notes = this.props.notes;
        this.props.handleReaderLog(readerLogObj);
      }

      this.props.handleFetchNotes();
      this.props.handleMenuMode("highlight");
      this.props.handleNoteKey("");

      //=====

      // try {
      //   let _this = this;
      //   setTimeout(function() {
      //     _this.props.handleRenderBook();
      //   }, 500);
      // } catch (err) {
      //   console.log("err", err)
      // }
    });
    //this.forceUpdate();
    //}
  }
  handleClose = () => {
    let noteIndex = -1;
    if (this.props.noteKey) {
      this.props.notes.forEach((item, index) => {
        if (item.key === this.props.noteKey) {
          noteIndex = index;
        }
      });
      if (noteIndex > -1) {
        this.props.notes.splice(noteIndex, 1);
        localforage.setItem("notes", this.props.notes).then(() => {
          this.props.handleOpenMenu(false);
          this.props.handleMenuMode("menu");
          alert("移除成功");
          this.props.handleMenuMode("highlight");
          this.props.handleFetchNotes();
          this.props.handleNoteKey("");
        });
      }
    } else {
      this.props.handleOpenMenu(false);
      this.props.handleMenuMode("menu");
      this.props.handleNoteKey("");
    }
  };

  render() {
    let note;
    if (this.props.noteKey) {
      this.props.notes.forEach((item) => {
        if (item.key === this.props.noteKey) {
          note = item;
        }
      });
    }

    const renderNoteEditor = () => {
      return (
        <div className="note-editor">
          <div className="editor-box-parent">
            <textarea className="editor-box" />
          </div>
          <div
            className="note-tags"
            style={{ position: "absolute", bottom: "0px", height: "70px" }}
          >
            <NoteTag
              {...{
                handleTag: this.handleTag,
                tag: this.props.noteKey && note ? note.tag : [],
              }}
            />
          </div>

          <div className="note-button-container">
            <span
              className="book-manage-title"
              onClick={() => {
                this.handleClose();
              }}
            >
              {this.props.noteKey ? (
                <Trans>Delete</Trans>
              ) : (
                <Trans>Cancel</Trans>
              )}
            </span>
            <span
              className="book-manage-title"
              onClick={() => {
                this.createNote();
              }}
            >
              <Trans>Confirm</Trans>
            </span>
          </div>
        </div>
      );
    };
    return renderNoteEditor();
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    notes: state.reader.notes,
    color: state.reader.color,
    flattenChapters: state.reader.flattenChapters,
    noteKey: state.reader.noteKey,
    readerLog: state.reader.readerLog,
  };
};
const actionCreator = {
  handleOpenMenu,
  handleMenuMode,
  handleNoteKey,
  handleFetchNotes,
  handleReaderLog,
};
export default connect(mapStateToProps, actionCreator)(PopupNote);
