/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Borrow,
    BorrowFromJSON,
    BorrowFromJSONTyped,
    BorrowToJSON,
} from './Borrow';
import {
    ReserveBorrowDto,
    ReserveBorrowDtoFromJSON,
    ReserveBorrowDtoFromJSONTyped,
    ReserveBorrowDtoToJSON,
} from './ReserveBorrowDto';

/**
 * 
 * @export
 * @interface BookCollectionBorrowStatusDto
 */
export interface BookCollectionBorrowStatusDto {
    /**
     * 
     * @type {number}
     * @memberof BookCollectionBorrowStatusDto
     */
    canBorrowCopy?: number;
    /**
     * 
     * @type {Date}
     * @memberof BookCollectionBorrowStatusDto
     */
    canBorrowDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof BookCollectionBorrowStatusDto
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof BookCollectionBorrowStatusDto
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<Borrow>}
     * @memberof BookCollectionBorrowStatusDto
     */
    lastTenBorrow?: Array<Borrow>;
    /**
     * 
     * @type {number}
     * @memberof BookCollectionBorrowStatusDto
     */
    productId?: number;
    /**
     * 
     * @type {Array<ReserveBorrowDto>}
     * @memberof BookCollectionBorrowStatusDto
     */
    reserveBorrowStatus?: Array<ReserveBorrowDto>;
    /**
     * 
     * @type {number}
     * @memberof BookCollectionBorrowStatusDto
     */
    reserveCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookCollectionBorrowStatusDto
     */
    status?: number;
}

export function BookCollectionBorrowStatusDtoFromJSON(json: any): BookCollectionBorrowStatusDto {
    return BookCollectionBorrowStatusDtoFromJSONTyped(json, false);
}

export function BookCollectionBorrowStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookCollectionBorrowStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canBorrowCopy': !exists(json, 'canBorrowCopy') ? undefined : json['canBorrowCopy'],
        'canBorrowDate': !exists(json, 'canBorrowDate') ? undefined : (new Date(json['canBorrowDate'])),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'lastTenBorrow': !exists(json, 'lastTenBorrow') ? undefined : ((json['lastTenBorrow'] as Array<any>).map(BorrowFromJSON)),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'reserveBorrowStatus': !exists(json, 'reserveBorrowStatus') ? undefined : ((json['reserveBorrowStatus'] as Array<any>).map(ReserveBorrowDtoFromJSON)),
        'reserveCount': !exists(json, 'reserveCount') ? undefined : json['reserveCount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function BookCollectionBorrowStatusDtoToJSON(value?: BookCollectionBorrowStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canBorrowCopy': value.canBorrowCopy,
        'canBorrowDate': value.canBorrowDate === undefined ? undefined : (value.canBorrowDate.toISOString()),
        'categoryId': value.categoryId,
        'categoryName': value.categoryName,
        'lastTenBorrow': value.lastTenBorrow === undefined ? undefined : ((value.lastTenBorrow as Array<any>).map(BorrowToJSON)),
        'productId': value.productId,
        'reserveBorrowStatus': value.reserveBorrowStatus === undefined ? undefined : ((value.reserveBorrowStatus as Array<any>).map(ReserveBorrowDtoToJSON)),
        'reserveCount': value.reserveCount,
        'status': value.status,
    };
}

