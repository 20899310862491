/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorrowReadReportData
 */
export interface BorrowReadReportData {
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    bookName?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    borrowDate?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportData
     */
    brn?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    publisher?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportData
     */
    reportCount?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    reportType?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    returnDate?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportData
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowReadReportData
     */
    userName?: string;
}

export function BorrowReadReportDataFromJSON(json: any): BorrowReadReportData {
    return BorrowReadReportDataFromJSONTyped(json, false);
}

export function BorrowReadReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowReadReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookName': !exists(json, 'bookName') ? undefined : json['bookName'],
        'borrowDate': !exists(json, 'borrowDate') ? undefined : json['borrowDate'],
        'brn': !exists(json, 'brn') ? undefined : json['brn'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'reportCount': !exists(json, 'reportCount') ? undefined : json['reportCount'],
        'reportType': !exists(json, 'reportType') ? undefined : json['reportType'],
        'returnDate': !exists(json, 'returnDate') ? undefined : json['returnDate'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
    };
}

export function BorrowReadReportDataToJSON(value?: BorrowReadReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'bookName': value.bookName,
        'borrowDate': value.borrowDate,
        'brn': value.brn,
        'category': value.category,
        'publisher': value.publisher,
        'reportCount': value.reportCount,
        'reportType': value.reportType,
        'returnDate': value.returnDate,
        'seq': value.seq,
        'user': value.user,
        'userName': value.userName,
    };
}

