/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberActionLogDataDto,
    MemberActionLogDataDtoFromJSON,
    MemberActionLogDataDtoFromJSONTyped,
    MemberActionLogDataDtoToJSON,
} from './MemberActionLogDataDto';

/**
 * 
 * @export
 * @interface MemberActionLogDto
 */
export interface MemberActionLogDto {
    /**
     * 
     * @type {string}
     * @memberof MemberActionLogDto
     */
    actionCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberActionLogDto
     */
    createAt?: Date;
    /**
     * 
     * @type {MemberActionLogDataDto}
     * @memberof MemberActionLogDto
     */
    data?: MemberActionLogDataDto;
}

export function MemberActionLogDtoFromJSON(json: any): MemberActionLogDto {
    return MemberActionLogDtoFromJSONTyped(json, false);
}

export function MemberActionLogDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberActionLogDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionCode': !exists(json, 'actionCode') ? undefined : json['actionCode'],
        'createAt': !exists(json, 'createAt') ? undefined : (new Date(json['createAt'])),
        'data': !exists(json, 'data') ? undefined : MemberActionLogDataDtoFromJSON(json['data']),
    };
}

export function MemberActionLogDtoToJSON(value?: MemberActionLogDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionCode': value.actionCode,
        'createAt': value.createAt === undefined ? undefined : (value.createAt.toISOString()),
        'data': MemberActionLogDataDtoToJSON(value.data),
    };
}

