/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryNameConflict
 */
export interface CategoryNameConflict {
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflict
     */
    cmsName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflict
     */
    libName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflict
     */
    mongoId?: string;
}

export function CategoryNameConflictFromJSON(json: any): CategoryNameConflict {
    return CategoryNameConflictFromJSONTyped(json, false);
}

export function CategoryNameConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryNameConflict {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cmsName': !exists(json, 'cmsName') ? undefined : json['cmsName'],
        'libName': !exists(json, 'libName') ? undefined : json['libName'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
    };
}

export function CategoryNameConflictToJSON(value?: CategoryNameConflict | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cmsName': value.cmsName,
        'libName': value.libName,
        'mongoId': value.mongoId,
    };
}

