import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { AddUpdateMemberResponseModel, MemberControllerApi, UpdateMemberUsingPATCH1Request } from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString, validateEmail } from "../../../utils/GlobalFunction";
import { withRouter } from "../../../utils/withRouter";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import style from "./UserInformation.module.css";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import { UserInfo } from "os";
import DialogBox from "../../DialogBox/DialogBox";

export interface IUserInfo {
    access_token: "";
    author: "";
    displayName: "";
    email: "";
    expires_in: "";
    id: "";
    jti: "";
    name: "";
    organizationName: "";
    refresh_token: "";
    role: "";
    token_type: "";
}

interface IProps {
    title?: string;
    content?: string;
    value?: string;
    styleType?: string;
    children?: any;
    showRecord?: boolean;
    isMobile?: boolean;
    actionLog?: any;
    userInfo?: any;
    handleUserInfo: any;
    callBack: (value: any, value2?: any) => void;
}

class UserInformation extends Component<IProps> {
    state = {
        content: {},
        title: "",
        showError: false,
        loading: false,
        email: "",
        editMode: false,
        showConfirmDialog: false,
        dialogMsg: "",
    };

    static defaultProps = {
        showRecord: false,
        isMobile: false,
    };

    componentDidMount() {
        if (this.props.userInfo?.member !== undefined) {
            this.setState({
                ...this.state,
                email: this.props.userInfo?.member.email,
            });
        }
    }

    encryptName(name: string) {
        console.log("user", this.props);
        let encryptedName = "";
        const userInfo = localStorage.getItem(PATH.USER_INFO);
        try {
            if (userInfo !== null) {
                const userInfoObj = JSON.parse(userInfo);
                if (userInfoObj.chtName !== undefined) {
                    encryptedName = userInfoObj.chtName;
                } else {
                    encryptedName = name.substring(0, 2);
                    for (let i = 2; i < name.length; i++) {
                        encryptedName += "*";
                    }
                }
            }
        } catch (e) {
            console.log("e", e);
            encryptedName = name.substring(0, 2);
            for (let i = 2; i < name.length; i++) {
                encryptedName += "*";
            }
        }

        return encryptedName;
    }

    render() {
        const { content, showError, loading } = this.state;
        const { showRecord, isMobile } = this.props;

        return (
            <>
                {localStorage.getItem(PATH.USER_INFO) === "" ? (
                    <>
                        <Navigate to="/" replace />
                    </>
                ) : (
                    <>
                        <Fragment>
                            <div className={style.tableItemListContainer}>
                                <div className={style.tableListItemGridView}>
                                    <div className={style.topRow}></div>
                                    <div className={style.content}>
                                        <div className={style.padding}>
                                            <div className={style.title}>
                                                <h3>
                                                    <Trans>PersonalInformation</Trans>
                                                </h3>
                                            </div>
                                            <div className={style.nameArea}>
                                                <h1>{this.encryptName(this.props.userInfo?.member.username!!)}</h1>
                                                <div
                                                    className={style.ClientTitle}
                                                    // style={{ width: "30%" }}
                                                >
                                                    {this.props.userInfo?.member !== undefined ? (
                                                        <>
                                                            {this.state.editMode ? (
                                                                <Input
                                                                    type="text"
                                                                    placeholder={"電郵"}
                                                                    value={this.state.email}
                                                                    styleType={PATH.STYLETYPE_MAIN}
                                                                    callBack={(value) =>
                                                                        this.setState({
                                                                            ...this.state,
                                                                            email: value,
                                                                        })
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            color: "black",
                                                                            fontSize: "1.2rem",
                                                                            marginBottom: "4px",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <Trans>AppointmentNoticeTitle</Trans>
                                                                    </div>
                                                                    <div>
                                                                        <Trans>AppointmentNoticeText</Trans>
                                                                        <Link to={PATH.POLICY}>
                                                                            <Trans>AppointmentNoticeTextURL</Trans>
                                                                        </Link>
                                                                        <Trans>AppointmentNoticeTextClose</Trans>
                                                                    </div>
                                                                    <span>{this.props.userInfo?.member.email!!}</span>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {this.props.userInfo?.isAdmin ? (
                                                        <></>
                                                    ) : (
                                                        <div style={{ display: "flex" }}>
                                                            <Button
                                                                styleType={"cancelButton"}
                                                                callBack={(value) => {
                                                                    new MemberControllerApi(genApiConfig())
                                                                        .updateMemberUsingPATCH1({
                                                                            memberId: this.props.userInfo?.member?.memberId!!,
                                                                            email: "",
                                                                            expireDate: "",
                                                                        } as UpdateMemberUsingPATCH1Request)
                                                                        .then((res: AddUpdateMemberResponseModel) => {
                                                                            if (res.success) {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    editMode: false,
                                                                                    email: res.memberDto?.email,
                                                                                });

                                                                                let userInfo = this.props.userInfo;
                                                                                if (userInfo !== undefined) {
                                                                                    userInfo["member"]["email"] = res.memberDto?.email;
                                                                                }

                                                                                this.props.handleUserInfo(userInfo);

                                                                                let userInfoStorage = localStorage.getItem(PATH.USER_INFO) as string;
                                                                                try {
                                                                                    let userInfoObj = JSON.parse(userInfoStorage);
                                                                                    userInfoObj.member.email = res.memberDto?.email;
                                                                                    localStorage.setItem(PATH.USER_INFO, JSON.stringify(userInfoObj));
                                                                                } catch (e) {
                                                                                    console.log("e", e);
                                                                                }

                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    showConfirmDialog: true,
                                                                                    dialogMsg: "電郵刪除成功",
                                                                                });
                                                                                //alert("電郵修改成功");
                                                                            }
                                                                        });
                                                                }}
                                                            >
                                                                <Trans>Delete</Trans>
                                                            </Button>
                                                            <Button
                                                                styleType={"submitButton"}
                                                                callBack={(value) => {
                                                                    if (this.state.editMode) {
                                                                        if (validateEmail(this.state.email)) {
                                                                            //save to api
                                                                            new MemberControllerApi(genApiConfig())
                                                                                .updateMemberUsingPATCH1({
                                                                                    memberId: this.props.userInfo?.member?.memberId!!,
                                                                                    email: this.state.email,
                                                                                    expireDate: "",
                                                                                } as UpdateMemberUsingPATCH1Request)
                                                                                .then((res) => {
                                                                                    if (res.success) {
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            editMode: false,
                                                                                            email: res.memberDto?.email,
                                                                                        });

                                                                                        let userInfo = this.props.userInfo;
                                                                                        if (userInfo !== undefined) {
                                                                                            userInfo["member"]["email"] = res.memberDto?.email;
                                                                                        }

                                                                                        this.props.handleUserInfo(userInfo);

                                                                                        let userInfoStorage = localStorage.getItem(PATH.USER_INFO) as string;
                                                                                        try {
                                                                                            let userInfoObj = JSON.parse(userInfoStorage);
                                                                                            userInfoObj.member.email = res.memberDto?.email;
                                                                                            localStorage.setItem(PATH.USER_INFO, JSON.stringify(userInfoObj));
                                                                                        } catch (e) {
                                                                                            console.log("e", e);
                                                                                        }

                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            showConfirmDialog: true,
                                                                                            dialogMsg: "電郵修改成功",
                                                                                        });
                                                                                        //alert("電郵修改成功");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    console.log("err", err);
                                                                                });
                                                                        } else {
                                                                            this.setState({
                                                                                ...this.state,
                                                                                showConfirmDialog: true,
                                                                                dialogMsg: "請輸入有效電郵",
                                                                            });
                                                                            //alert("請輸入有效電郵");
                                                                        }
                                                                    } else {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            editMode: value,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {this.state.editMode ? "確定" : "修改"}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </>
                )}
                <DialogBox
                    styleType={PATH.STYLETYPE_SECOND}
                    showDialog={this.state.showConfirmDialog}
                    p1={this.state.dialogMsg}
                    title={<Trans>SystemMessage</Trans>}
                    scroll={false}
                >
                    <div className={style.dialogButtonContainer}>
                        <Button styleType={"submitButton"} callBack={() => this.setState({ ...this.state, showConfirmDialog: false })}>
                            <Trans>Confirm</Trans>
                        </Button>
                    </div>
                </DialogBox>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    // console.log("state", state);
    return {
        userInfo: state.userInfo.userInfo,
    };
};
const actionCreator = { handleUserInfo };

export default connect(mapStateToProps, actionCreator)(UserInformation);
