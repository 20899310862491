const initState = {
  borrowBookList : []
};
export function myBorrowBookList(
  state = initState,
  action
) {
  switch (action.type) {

    case "HANDLE_BORROW_BOOK_LIST":
      return {
        ...state,
        borrowBookList: action.payload,
      };

    default:
      return state;
  }
}
