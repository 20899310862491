/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProduct,
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductPoint
 */
export interface ProductPoint {
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof ProductPoint
     */
    cartProductsByCartProductId?: Array<CartProduct>;
    /**
     * 
     * @type {Date}
     * @memberof ProductPoint
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    money?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    point?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductPoint
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    productPointId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductPoint
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductPoint
     */
    status?: string;
}

export function ProductPointFromJSON(json: any): ProductPoint {
    return ProductPointFromJSONTyped(json, false);
}

export function ProductPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartProductsByCartProductId': !exists(json, 'cartProductsByCartProductId') ? undefined : ((json['cartProductsByCartProductId'] as Array<any>).map(CartProductFromJSON)),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'money': !exists(json, 'money') ? undefined : json['money'],
        'point': !exists(json, 'point') ? undefined : json['point'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPointId': !exists(json, 'productPointId') ? undefined : json['productPointId'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ProductPointToJSON(value?: ProductPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartProductsByCartProductId': value.cartProductsByCartProductId === undefined ? undefined : ((value.cartProductsByCartProductId as Array<any>).map(CartProductToJSON)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'money': value.money,
        'point': value.point,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
        'productPointId': value.productPointId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
    };
}

