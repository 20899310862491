// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.Footer_footer__kXlYa{\n    background-color: #f4f4f4;\n    height: 6vh;\n    display:flex;\n    align-items: center;\n    justify-content: center;\n\n    \n    width: 100%;\n    bottom: 0;\n    position: relative;\n    /* position: fixed; */\n}\n\n.Footer_innerFooter__s-JxO{\n    max-width:1440px;\n}\n\n.Footer_innerFooter__s-JxO ul {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    list-style: none;\n    padding: 0 1em 0 1em;\n    color: white;\n    font-size: 0.7rem;\n    color: var(--color-red-main);\n}\n\n\n.Footer_innerFooter__s-JxO ul li a{\n    color: var(--color-red-main);\n}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;;;IAGvB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,iBAAiB;IACjB,4BAA4B;AAChC;;;AAGA;IACI,4BAA4B;AAChC","sourcesContent":["\n\n.footer{\n    background-color: #f4f4f4;\n    height: 6vh;\n    display:flex;\n    align-items: center;\n    justify-content: center;\n\n    \n    width: 100%;\n    bottom: 0;\n    position: relative;\n    /* position: fixed; */\n}\n\n.innerFooter{\n    max-width:1440px;\n}\n\n.innerFooter ul {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    list-style: none;\n    padding: 0 1em 0 1em;\n    color: white;\n    font-size: 0.7rem;\n    color: var(--color-red-main);\n}\n\n\n.innerFooter ul li a{\n    color: var(--color-red-main);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__kXlYa",
	"innerFooter": "Footer_innerFooter__s-JxO"
};
export default ___CSS_LOADER_EXPORT___;
