//搜索框
import React from "react";
import { connect } from 'react-redux';
import {
  handleSearch, handleSearchResults
} from '../../../redux/actions/reader';
import SearchUtil from "../../../utils/reader/serviceUtils/searchUtil";
import "./searchBox.css";

class SearchBox extends React.Component {
  componentDidMount() {
    if (this.props.isNavSearch) {
      let searchBox = document.querySelector(".header-search-box");
      searchBox && searchBox.focus();
    }
  }
  handleMouse = () => {
    let value = (this.refs.searchBox).value;
    if (this.props.isNavSearch) {
      value && this.search(value);
    }
    if (this.props.mode === "nav") {
      this.props.handleSearchState(true);
    }
    let results =
      this.props.tabMode === "note"
        ? SearchUtil.MouseNoteSearch(
          this.props.notes.filter((item) => item.notes !== "")
        )
        : this.props.tabMode === "digest"
          ? SearchUtil.MouseNoteSearch(this.props.digests)
          : SearchUtil.MouseSearch(this.props.books);
    if (results) {
      this.props.handleSearchResults(results);
      this.props.handleSearch(true);
    }
  };
  handleKey = (event) => {
    if (event.keyCode !== 13) {
      return;
    }
    let value = (this.refs.searchBox).value.toLowerCase();
    if (this.props.isNavSearch || this.props.isReading) {
      value && this.search(value);
    }
    let results =
      this.props.tabMode === "note"
        ? SearchUtil.KeyNoteSearch(
          event,
          this.props.notes.filter((item) => item.notes !== "")
        )
        : this.props.tabMode === "digest"
          ? SearchUtil.KeyNoteSearch(event, this.props.digests)
          : SearchUtil.KeySearch(event, this.props.books);
    if (results) {
      this.props.handleSearchResults(results);
      this.props.handleSearch(true);
    }
  };
  search = async (q) => {
    let searchList =
      this.props.currentBook.format === "EPUB"
        ? await this.doSearch(q)
        : this.props.htmlBook.rendition.doSearch(q);

    this.props.handleSearchList(
      searchList.map((item) => {
        item.excerpt = item.excerpt.replace(
          q,
          `<span class="content-search-text">${q}</span>`
        );
        return item;
      })
    );
  };
  doSearch = (q) => {
    return Promise.all(
      this.props.currentEpub.spine.spineItems.map((item) =>
        item
          .load(this.props.currentEpub.load.bind(this.props.currentEpub))
          .then(item.find.bind(item, q))
          .finally(item.unload.bind(item))
      )
    ).then((results) => Promise.resolve([].concat.apply([], results)));
  };
  handleCancel = () => {
    if (this.props.isNavSearch) {
      this.props.handleSearchList(null);
    }
    this.props.handleSearch(false);
    (document.querySelector(".header-search-box")).value =
      "";
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <input
          type="text"
          ref="searchBox"
          className="header-search-box"
          onKeyDown={(event) => {
            this.handleKey(event);
          }}
          onFocus={() => {
            this.props.mode === "nav" && this.props.handleSearchState(true);
          }}
          placeholder={
            this.props.isNavSearch || this.props.mode === "nav"
              ? "Search in the book"
              : this.props.tabMode === "note"
                ? "Search my notes"
                : this.props.tabMode === "digest"
                  ? "Search my highlights"
                  : "Search my library"
          }
          // style={
          //   this.props.mode === "nav"
          //     ? { width: this.props.width, height: this.props.height }
          //     : { paddingRight: "50px" }
          // }
        />
        {this.props.isSearch ? (
          <span
            className="header-search-text"
            onClick={() => {
              this.handleCancel();
            }}
            style={
              this.props.mode === "nav" ? { right: "-9px", top: "14px" } : {}
            }
          >
            <span className="icon-close"></span>
          </span>
        ) : (
          <span
            className="icon-search header-search-icon"
            onClick={() => {
              this.handleMouse();
            }}
            style={this.props.mode === "nav" ? { right: "5px" } : {}}
          ></span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    books: state.manager.books,
    notes: state.reader.notes,
    htmlBook: state.reader.htmlBook,
    digests: state.reader.digests,
    isSearch: state.manager.isSearch,
    isReading: state.book.isReading,
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    tabMode: state.sidebar.mode,
    shelfIndex: state.sidebar.shelfIndex,
  };
};
const actionCreator = {
  handleSearchResults,
  handleSearch,
};
export default connect(
  mapStateToProps,
  actionCreator
)(SearchBox);
