/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorrowViewRecordReportData
 */
export interface BorrowViewRecordReportData {
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    borrowTime?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    cantBorrow?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    chapter?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    contentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    mobileBorrow?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowViewRecordReportData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    preview?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    readOnline?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    readTimeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    userid?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowViewRecordReportData
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportData
     */
    webBorrow?: number;
}

export function BorrowViewRecordReportDataFromJSON(json: any): BorrowViewRecordReportData {
    return BorrowViewRecordReportDataFromJSONTyped(json, false);
}

export function BorrowViewRecordReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowViewRecordReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrowTime': !exists(json, 'borrowTime') ? undefined : json['borrowTime'],
        'cantBorrow': !exists(json, 'cantBorrow') ? undefined : json['cantBorrow'],
        'chapter': !exists(json, 'chapter') ? undefined : json['chapter'],
        'contentCount': !exists(json, 'contentCount') ? undefined : json['contentCount'],
        'mobileBorrow': !exists(json, 'mobileBorrow') ? undefined : json['mobileBorrow'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'preview': !exists(json, 'preview') ? undefined : json['preview'],
        'readOnline': !exists(json, 'readOnline') ? undefined : json['readOnline'],
        'readTimeCount': !exists(json, 'readTimeCount') ? undefined : json['readTimeCount'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'userid': !exists(json, 'userid') ? undefined : json['userid'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'webBorrow': !exists(json, 'webBorrow') ? undefined : json['webBorrow'],
    };
}

export function BorrowViewRecordReportDataToJSON(value?: BorrowViewRecordReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrowTime': value.borrowTime,
        'cantBorrow': value.cantBorrow,
        'chapter': value.chapter,
        'contentCount': value.contentCount,
        'mobileBorrow': value.mobileBorrow,
        'name': value.name,
        'preview': value.preview,
        'readOnline': value.readOnline,
        'readTimeCount': value.readTimeCount,
        'seq': value.seq,
        'userid': value.userid,
        'username': value.username,
        'webBorrow': value.webBorrow,
    };
}

