/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    messageId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    scheduledSendTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsBirthdayMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsBirthdayVipMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsVipMember?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    title?: string;
}

export function MessageDtoFromJSON(json: any): MessageDto {
    return MessageDtoFromJSONTyped(json, false);
}

export function MessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'scheduledSendTime': !exists(json, 'scheduledSendTime') ? undefined : (new Date(json['scheduledSendTime'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'targetAsBirthdayMember': !exists(json, 'targetAsBirthdayMember') ? undefined : json['targetAsBirthdayMember'],
        'targetAsBirthdayVipMember': !exists(json, 'targetAsBirthdayVipMember') ? undefined : json['targetAsBirthdayVipMember'],
        'targetAsMember': !exists(json, 'targetAsMember') ? undefined : json['targetAsMember'],
        'targetAsVipMember': !exists(json, 'targetAsVipMember') ? undefined : json['targetAsVipMember'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function MessageDtoToJSON(value?: MessageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'messageId': value.messageId,
        'scheduledSendTime': value.scheduledSendTime === undefined ? undefined : (value.scheduledSendTime.toISOString()),
        'status': value.status,
        'targetAsBirthdayMember': value.targetAsBirthdayMember,
        'targetAsBirthdayVipMember': value.targetAsBirthdayVipMember,
        'targetAsMember': value.targetAsMember,
        'targetAsVipMember': value.targetAsVipMember,
        'title': value.title,
    };
}

