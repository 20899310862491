import React, { Component } from "react";
import {
  DIALOG_CANCEL,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
} from "../../../utils/Contants";
import Button from "../../Button/Button";
import style from "./ReadSettingSheet.module.css";
import loadingGif from "../../../assets/svg/loading.gif";
import Input from "../../Input/Input";
import packed_w_s from "../../../assets/setting/packed_w_s.svg";
import packed_w_n from "../../../assets/setting/packed_w_n.svg";
import packed_p_s from "../../../assets/setting/packed_p_s.svg";
import packed_p_n from "../../../assets/setting/packed_p_n.svg";
import packed_l_s from "../../../assets/setting/packed_l_s.svg";
import packed_l_n from "../../../assets/setting/packed_l_n.svg";
import normal_w_s from "../../../assets/setting/normal_w_s.svg";
import normal_w_n from "../../../assets/setting/normal_w_n.svg";
import normal_p_s from "../../../assets/setting/normal_p_s.svg";
import normal_p_n from "../../../assets/setting/normal_p_n.svg";
import normal_l_s from "../../../assets/setting/normal_l_s.svg";
import normal_l_n from "../../../assets/setting/normal_l_n.svg";
import comfortable_w_s from "../../../assets/setting/comfortable_w_s.svg";
import comfortable_w_n from "../../../assets/setting/comfortable_w_n.svg";
import comfortable_p_s from "../../../assets/setting/comfortable_p_s.svg";
import comfortable_p_n from "../../../assets/setting/comfortable_p_n.svg";
import comfortable_l_s from "../../../assets/setting/comfortable_l_s.svg";
import comfortable_l_n from "../../../assets/setting/comfortable_l_n.svg";
import vert_n from "../../../assets/setting/vert_n.svg";
import vert_s from "../../../assets/setting/vert_s.svg";
import horizon_n from "../../../assets/setting/horizon_n.svg";
import horizon_s from "../../../assets/setting/horizon_s.svg";

import hei_n from "../../../assets/setting/hei_n.svg";
import hei_s from "../../../assets/setting/hei_s.svg";
import song_n from "../../../assets/setting/song_n.svg";
import song_s from "../../../assets/setting/song_s.svg";
import pingfong_n from "../../../assets/setting/pingfong_n.svg";
import pingfong_s from "../../../assets/setting/pingfong_s.svg";

interface Props {
  callBack: (value: any, value2?: any) => void;
}

class ReadSettingSheet extends Component<Props> {
  state = {
    content: {},
    showError: false,
    loading: false,
  };

  handleCancel = () => {
    this.props.callBack({ action: DIALOG_CANCEL });
  };

  handleSubmit = () => {};

  setRange = () => {};

  render() {
    const { content, showError, loading } = this.state;

    return (
      <>
        <div className={style.content_height}>
          <div className={style.container}>
            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>字體大小</label>
              </div>
              <div className={style.input_style1}>
                <div style={{ width: "50%" }}>
                  <Input
                    styleType={"range"}
                    type="range"
                    min={0}
                    max={30}
                    callBack={(value) => this.setRange()}
                  ></Input>
                </div>
                <div className={style.btn_num}>
                  <div>-</div>
                  <div>12</div>
                  <div>+</div>
                </div>
              </div>
            </div>

            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>字體選擇</label>
              </div>
              <div className={style.input_style2}>
                <div className={style.pingfong}>
                  <img src={pingfong_n}></img>
                </div>
                <div className={style.song}>
                  <img src={song_n}></img>
                </div>
                <div className={style.hei}>
                  <img src={hei_n}></img>
                </div>
              </div>
            </div>

            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>字距調整</label>
              </div>
              <div className={style.input_style3}>
                <div className={style.styleKerningP}>
                  <div className={style.packed_w_n}>
                    <img src={packed_w_n}></img>
                  </div>
                  緊密
                </div>

                <div className={style.styleKerningN}>
                  <div className={style.normal_w_n}>
                    <img src={normal_w_n}></img>
                  </div>
                  適中
                </div>

                <div className={style.styleKerningC}>
                  <div className={style.comfortable_w_n}>
                    <img src={comfortable_w_n}></img>
                  </div>
                  鬆散
                </div>
              </div>
            </div>

            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>行距調整</label>
              </div>
              <div className={style.input_style4}>
                <div className={style.styleLineP}>
                  <div className={style.packed_l_s}>
                    <img src={packed_l_n}></img>
                  </div>
                  緊密
                </div>

                <div className={style.styleLineN}>
                  <div className={style.normal_l_n}>
                    <img src={normal_l_n}></img>
                  </div>
                  適中
                </div>

                <div className={style.styleLineC}>
                  <div className={style.comfortable_l_n}>
                    <img src={comfortable_l_n}></img>
                  </div>
                  鬆散
                </div>
              </div>
            </div>
            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>邊距調整</label>
              </div>
              <div className={style.input_style5}>
                <div className={style.styleMarginP}>
                  <div className={style.packed_p_n}>
                    <img src={packed_p_n}></img>
                  </div>
                  緊密
                </div>

                <div className={style.styleMarginN}>
                  <div className={style.normal_p_n}>
                    <img src={normal_p_n}></img>
                  </div>
                  適中
                </div>

                <div className={style.styleMarginC}>
                  <div className={style.comfortable_p_n}>
                    <img src={comfortable_p_n}></img>
                  </div>
                  鬆散
                </div>
              </div>
            </div>
            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>繁簡轉換</label>
              </div>
              <div className={style.input_style6}>
                <div>繁體中文</div>
                <div>简体中文</div>
              </div>
            </div>
            <div className={style.displayflex}>
              <div className={style.label_style}>
                <label>直橫切換</label>
              </div>
              <div className={style.input_style7}>
                <div className={style.stylecolumn}>
                  <div className={style.vert_n}>
                    <img src={vert_n}></img>
                  </div>
                  直排
                </div>

                <div className={style.stylerow}>
                  <div className={style.horizon_n}>
                    <img src={horizon_n}></img>
                  </div>
                  橫排
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.button_on_right}>
          <Button
            styleType={STYLETYPE_THIRD}
            callBack={(value) => this.handleCancel()}
          >
            取消
          </Button>

          <Button
            styleType={STYLETYPE_MAIN}
            callBack={(value) => this.handleSubmit()}
            disabled={loading}
          >
            {loading ? "" : "儲存設定"}
            <img
              src={loadingGif}
              style={{ width: "20px", height: "20px" }}
              hidden={!loading}
            />
          </Button>
        </div>
      </>
    );
  }
}

export default ReadSettingSheet;
