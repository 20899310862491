/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCommentPayloadModel
 */
export interface ProductCommentPayloadModel {
    /**
     * 
     * @type {string}
     * @memberof ProductCommentPayloadModel
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCommentPayloadModel
     */
    rating?: number;
}

export function ProductCommentPayloadModelFromJSON(json: any): ProductCommentPayloadModel {
    return ProductCommentPayloadModelFromJSONTyped(json, false);
}

export function ProductCommentPayloadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCommentPayloadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
    };
}

export function ProductCommentPayloadModelToJSON(value?: ProductCommentPayloadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'rating': value.rating,
    };
}

