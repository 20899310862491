/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    HashtagDto,
    HashtagDtoFromJSON,
    HashtagDtoFromJSONTyped,
    HashtagDtoToJSON,
} from './HashtagDto';
import {
    MarketingEventDtoLite,
    MarketingEventDtoLiteFromJSON,
    MarketingEventDtoLiteFromJSONTyped,
    MarketingEventDtoLiteToJSON,
} from './MarketingEventDtoLite';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface DisplayProductDto
 */
export interface DisplayProductDto {
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof DisplayProductDto
     */
    categories?: Array<CategoryDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionFeature?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionIngredient?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionUsage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionUseCount?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductImage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    functionalIcon?: string;
    /**
     * 
     * @type {Array<HashtagDto>}
     * @memberof DisplayProductDto
     */
    hashtags?: Array<HashtagDto>;
    /**
     * 
     * @type {Array<MarketingEventDtoLite>}
     * @memberof DisplayProductDto
     */
    marketingEvents?: Array<MarketingEventDtoLite>;
    /**
     * 
     * @type {Member}
     * @memberof DisplayProductDto
     */
    member?: Member;
    /**
     * 
     * @type {MemberDto}
     * @memberof DisplayProductDto
     */
    memberOutput?: MemberDto;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProductDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProductDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    order?: number;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof DisplayProductDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    status?: string;
}

export function DisplayProductDtoFromJSON(json: any): DisplayProductDto {
    return DisplayProductDtoFromJSONTyped(json, false);
}

export function DisplayProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(CategoryDtoFromJSON)),
        'displayProductDescriptionDetail': !exists(json, 'displayProductDescriptionDetail') ? undefined : json['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': !exists(json, 'displayProductDescriptionFeature') ? undefined : json['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': !exists(json, 'displayProductDescriptionIngredient') ? undefined : json['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': !exists(json, 'displayProductDescriptionTarget') ? undefined : json['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': !exists(json, 'displayProductDescriptionUsage') ? undefined : json['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': !exists(json, 'displayProductDescriptionUseCount') ? undefined : json['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': !exists(json, 'displayProductDiscountPrice') ? undefined : json['displayProductDiscountPrice'],
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'displayProductImage': !exists(json, 'displayProductImage') ? undefined : json['displayProductImage'],
        'displayProductNameChi': !exists(json, 'displayProductNameChi') ? undefined : json['displayProductNameChi'],
        'displayProductNameEng': !exists(json, 'displayProductNameEng') ? undefined : json['displayProductNameEng'],
        'displayProductPrice': !exists(json, 'displayProductPrice') ? undefined : json['displayProductPrice'],
        'functionalIcon': !exists(json, 'functionalIcon') ? undefined : json['functionalIcon'],
        'hashtags': !exists(json, 'hashtags') ? undefined : ((json['hashtags'] as Array<any>).map(HashtagDtoFromJSON)),
        'marketingEvents': !exists(json, 'marketingEvents') ? undefined : ((json['marketingEvents'] as Array<any>).map(MarketingEventDtoLiteFromJSON)),
        'member': !exists(json, 'member') ? undefined : MemberFromJSON(json['member']),
        'memberOutput': !exists(json, 'memberOutput') ? undefined : MemberDtoFromJSON(json['memberOutput']),
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function DisplayProductDtoToJSON(value?: DisplayProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(CategoryDtoToJSON)),
        'displayProductDescriptionDetail': value.displayProductDescriptionDetail,
        'displayProductDescriptionFeature': value.displayProductDescriptionFeature,
        'displayProductDescriptionIngredient': value.displayProductDescriptionIngredient,
        'displayProductDescriptionTarget': value.displayProductDescriptionTarget,
        'displayProductDescriptionUsage': value.displayProductDescriptionUsage,
        'displayProductDescriptionUseCount': value.displayProductDescriptionUseCount,
        'displayProductDiscountPrice': value.displayProductDiscountPrice,
        'displayProductId': value.displayProductId,
        'displayProductImage': value.displayProductImage,
        'displayProductNameChi': value.displayProductNameChi,
        'displayProductNameEng': value.displayProductNameEng,
        'displayProductPrice': value.displayProductPrice,
        'functionalIcon': value.functionalIcon,
        'hashtags': value.hashtags === undefined ? undefined : ((value.hashtags as Array<any>).map(HashtagDtoToJSON)),
        'marketingEvents': value.marketingEvents === undefined ? undefined : ((value.marketingEvents as Array<any>).map(MarketingEventDtoLiteToJSON)),
        'member': MemberToJSON(value.member),
        'memberOutput': MemberDtoToJSON(value.memberOutput),
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'order': value.order,
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductDtoToJSON)),
        'status': value.status,
    };
}

