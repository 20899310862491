/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProductDiscount,
    CartProductDiscountFromJSON,
    CartProductDiscountFromJSONTyped,
    CartProductDiscountToJSON,
} from './CartProductDiscount';
import {
    MemberShoppingCart,
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import {
    ProductPoint,
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';

/**
 * 
 * @export
 * @interface CartProduct
 */
export interface CartProduct {
    /**
     * 
     * @type {Array<CartProductDiscount>}
     * @memberof CartProduct
     */
    cartProductDiscountsByCartProductId?: Array<CartProductDiscount>;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    cartProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {MemberShoppingCart}
     * @memberof CartProduct
     */
    memberShoppingCartByShoppingCartId?: MemberShoppingCart;
    /**
     * 
     * @type {Product}
     * @memberof CartProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    productId?: number;
    /**
     * 
     * @type {ProductPoint}
     * @memberof CartProduct
     */
    productPointByProductPointId?: ProductPoint;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    productPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    shoppingCartId?: number;
}

export function CartProductFromJSON(json: any): CartProduct {
    return CartProductFromJSONTyped(json, false);
}

export function CartProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartProductDiscountsByCartProductId': !exists(json, 'cartProductDiscountsByCartProductId') ? undefined : ((json['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountFromJSON)),
        'cartProductId': !exists(json, 'cartProductId') ? undefined : json['cartProductId'],
        'deliveryTimes': !exists(json, 'deliveryTimes') ? undefined : json['deliveryTimes'],
        'memberShoppingCartByShoppingCartId': !exists(json, 'memberShoppingCartByShoppingCartId') ? undefined : MemberShoppingCartFromJSON(json['memberShoppingCartByShoppingCartId']),
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPointByProductPointId': !exists(json, 'productPointByProductPointId') ? undefined : ProductPointFromJSON(json['productPointByProductPointId']),
        'productPointId': !exists(json, 'productPointId') ? undefined : json['productPointId'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'shoppingCartId': !exists(json, 'shoppingCartId') ? undefined : json['shoppingCartId'],
    };
}

export function CartProductToJSON(value?: CartProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartProductDiscountsByCartProductId': value.cartProductDiscountsByCartProductId === undefined ? undefined : ((value.cartProductDiscountsByCartProductId as Array<any>).map(CartProductDiscountToJSON)),
        'cartProductId': value.cartProductId,
        'deliveryTimes': value.deliveryTimes,
        'memberShoppingCartByShoppingCartId': MemberShoppingCartToJSON(value.memberShoppingCartByShoppingCartId),
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
        'productPointByProductPointId': ProductPointToJSON(value.productPointByProductPointId),
        'productPointId': value.productPointId,
        'quantity': value.quantity,
        'shoppingCartId': value.shoppingCartId,
    };
}

