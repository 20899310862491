export function handleOpenMenu(isOpenMenu) {
  return { type: "HANDLE_OPEN_MENU", payload: isOpenMenu };
}
export function handleMenuMode(menuMode) {
  return { type: "HANDLE_MENU_MODE", payload: menuMode };
}
export function handleOpenHighlight(isOpenHighlight) {
  return { type: "HANDLE_OPEN_HIGHLIGHT", payload: isOpenHighlight };
}
export function handleChangeDirection(isChangeDirection) {
  return { type: "HANDLE_CHANGE_DIRECTION", payload: isChangeDirection };
}
export function handleShowBookmark(isShowBookmark) {
  return { type: "HANDLE_SHOW_BOOKMARK", payload: isShowBookmark };
}
export function handleSelection(selection) {
  return { type: "HANDLE_SELECTION", payload: selection };
}
