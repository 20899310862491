/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface DashboardRecommendationDto
 */
export interface DashboardRecommendationDto {
    /**
     * 
     * @type {CategoryDto}
     * @memberof DashboardRecommendationDto
     */
    category?: CategoryDto;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof DashboardRecommendationDto
     */
    products?: Array<ProductDto>;
}

export function DashboardRecommendationDtoFromJSON(json: any): DashboardRecommendationDto {
    return DashboardRecommendationDtoFromJSONTyped(json, false);
}

export function DashboardRecommendationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardRecommendationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : CategoryDtoFromJSON(json['category']),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
    };
}

export function DashboardRecommendationDtoToJSON(value?: DashboardRecommendationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': CategoryDtoToJSON(value.category),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductDtoToJSON)),
    };
}

