import localforage from "localforage";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import arrowBack from "../../assets/svg/back.svg";
import bookmarkOff from "../../assets/svg/bookmark_off@1x.svg";
import bookmarkon from "../../assets/svg/bookmark_on@1x.svg";
import BookViewRelated from "../../components/BookViewRelated/BookViewRelated";
import Button from "../../components/Button/Button";
import DialogBox from "../../components/DialogBox/DialogBox";
import Input from "../../components/Input/Input";
import Rating from "../../components/Rating/Rating";
import Textarea from "../../components/Textarea/Textarea";
import {
    AddMemberFavouriteByMemberIdUsingPUTRequest,
    BookCollectionBorrowStatusDto,
    BorrowBookUsingPOSTRequest,
    BorrowControllerApi,
    BorrowDto,
    DeleteMemberFavouriteByMemberIdUsingDELETERequest,
    GetMemberFavouritesByMemberIdUsingGETRequest,
    GetOnlineReadSametimeUsingGET1Request,
    GetProductByProductIdUsingGETRequest,
    InsertProductCommentUsingPOSTRequest,
    ListAllMyBorrowUsingPOSTRequest,
    ListAllMyReserveBookUsingPOSTRequest,
    ListReserveByBookUsingGETRequest,
    MemberControllerApi,
    MyBorrowStatus,
    MyBorrowStatusStatusEnum,
    ProductBorrowMyStatusUsingPOSTRequest,
    ProductCommentControllerApi,
    ProductControllerApi,
    ProductDto,
    ProductPointFromJSONTyped,
    ReserveBookUsingPOSTRequest,
    ReserveBorrow,
    SuccessResponseModel,
    SystemSettingControllerApi,
    TestIpAddressUsingGETRequest,
    WhiteListIpControllerApi,
} from "../../fetcher";
import { ReportRecordControllerApi, SaveReportRecordUsingPOSTBookTypeEnum, SaveReportRecordUsingPOSTReportTypeEnum } from "../../fetcher";
import { IProductAllListModel } from "../../model";
import { handleLogin } from "../../redux/actions/login/login";
import { myBookMarkList } from "../../redux/actions/myBookMark/myBookMark";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { dateToString, getArgsQuery, getFileExtension, validateEmail } from "../../utils/GlobalFunction";
import BookUtil from "../../utils/reader/fileUtils/bookUtil";
import { addEpub } from "../../utils/reader/fileUtils/epubUtil";
import { getMd5WithBrowser } from "../../utils/reader/fileUtils/md5Util";
import RecentBooks from "../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
import style from "./BookDetail.module.css";
import * as Storage from "../../utils/local-storage";
import { useSocket } from "../../utils/socket/SocketContext";
import { COLLECTION_ID } from "../../utils/Contants";
import PageLoading from "../loading/PageLoading";
//let socket: any = null;

function BookDetail(props: any) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const [toggleInfo, setToggleInfo] = useState("info");
    const [displayData, setDisplayData] = useState<any>({});
    const [productBorrowStatus, setProductBorrowStatus] = useState<any>();
    const [openBorrowDialog, setOpenBorrowDialog] = useState(false);
    const [openReserveDialog, setOpenReserveDialog] = useState(false);
    const [openBookmarkDialog, setOpenBookmarkDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [rating, setRating] = useState(0);
    const [bookMarked, setBookMarked] = useState(props.bookMarkList.bookMarkList.filter((it: any) => it.productId == params.id!).length > 0);
    const [commentDisplay, setCommentDisplay] = useState([]);
    // region loading
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [loadingProd, setLoadingProd] = useState(false);
    const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    // endregion loading

    const rootElement = document.getElementById("root");
    const [currentPage, setCurrerntPage] = useState(1);
    const [pagenum, setPagenum] = useState(0);
    const [pageStatusStart, setPageStatusStart] = useState(false);
    const [pageStatusEnd, setPageStatusEnd] = useState(false);
    const [hiddenOnlineRead, setHiddenOnlineRead] = useState(false);
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [bookingEmail, setBookingEmail] = useState("");
    const [needEmail, setNeedEmail] = useState(true);

    const [reserveList, setReserveList] = useState<Array<ReserveBorrow>>([]);

    const [borrowBookMaxDialog, setBorrowBookMaxDialog] = useState(false);
    const [reserveBookMaxDialog, setReserveBookMaxDialog] = useState(false);

    const [confirmOpenBookDialog, setConfirmOpenBookDialog] = useState(false);

    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
    const [loadingConfrim, setloadingConfrim] = useState(false);
    //#region useSocket to count user open this page, open book or not and count current online read
    // const {
    //   socketMsg,
    //   setDetailPage,
    //   setBookPage,
    //   setOpenBookUser,
    //   //    setTryTouch,
    // } = useSocket();

    const socket = useSocket();

    const [isOpenBook, setIsOpenBook] = useState(false);
    const [showBookIsOpenDialog, setShowBookIsOpenDialog] = useState(false);
    // const [currentIp, setCurrentIp] = useState("");
    const [isWhiteListIp, setIsWhiteListIp] = useState(false);
    const [onlineReadSametime, setOnlineReadSametime] = useState(1);

    const [categoryCollectionId, setCategoryCollectionId] = useState<string | null>();

    useEffect(() => {
        if (displayData.productNameChi) setLoadingData(false);
    }, [displayData]);

    //#endregion
    useEffect(() => {
        // console.log("book detail socket", socket);
        if (socket !== undefined) {
            if (Object.keys(socket.serverMessage).length > 0) {
                const { onlineBook, bookPage, ref, currentOnline } = socket.serverMessage;
                // console.log(Object.keys(onlineBook));
                if (Object.keys(onlineBook).length > 0) {
                    if (Object.values(onlineBook).length > 0) {
                        const onlineBookList = Object.values(onlineBook);
                        const bookId = params.id;

                        const countOnlineUser = onlineBookList.filter((it) => it.toString() === bookId);
                        if (countOnlineUser.length > 1) {
                            setHiddenOnlineRead(true);
                        } else {
                            setHiddenOnlineRead(false);
                        }
                    }
                }

                if (Object.keys(bookPage).length > 0) {
                    for (let i = 0; i < Object.keys(bookPage).length; i++) {
                        if (ref !== undefined) {
                            if (ref.length > 0) {
                                const findOpenBookSocket = ref.find((it) => it === Object.keys(bookPage)[i]);

                                if (findOpenBookSocket !== undefined) {
                                    setIsOpenBook(true);
                                } else {
                                    setIsOpenBook(false);
                                }
                            }
                        }
                    }
                } else {
                    setIsOpenBook(false);
                }

                // if (currentOnline !== undefined) {
                //   if (currentOnline > 1) {
                //     setHiddenOnlineRead(true);
                //   } else {
                //     handleReadBook("online");
                //   }
                // }
            }
        }
    }, [socket]);

    useEffect(() => {
        if (openEmailDialog) {
            setNeedEmail(true);
        }
    }, [openEmailDialog]);

    const fetchIpAddress = async () => {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        let req: TestIpAddressUsingGETRequest = {};
        req.ipAddress = data.ip;
        new WhiteListIpControllerApi(genApiConfig()).testIpAddressUsingGET(req).then((res: SuccessResponseModel) => {
            if (res.success) {
                setIsWhiteListIp(res.success);
            }
        });
        // setCurrentIp(data.ip);
    };

    useEffect(() => {
        setCurrerntPage(1);

        fetchIpAddress();

        if (Object.keys(props.userInfo).length > 0) {
            if (props.userInfo.member.email === undefined) {
                setBookingEmail("");
            } else {
                setBookingEmail(props.userInfo.member.email);
            }
        }
        const openDetailPageMsg = {
            bookId: params.id!!,
            type: PATH.OPEN_DETAIL_PAGE,
            needSend: true,
        };
        socket.setSocketSend?.(openDetailPageMsg);

        let getOnlineReadSametimeUsingGETRequest: GetOnlineReadSametimeUsingGET1Request = {
            productId: parseInt(params.id!),
            collectionId: localStorage.getItem(COLLECTION_ID) != null ? parseInt(localStorage.getItem(COLLECTION_ID)!) : -1,
        };
        new SystemSettingControllerApi(genApiConfig()).getOnlineReadSametimeUsingGET1(getOnlineReadSametimeUsingGETRequest).then((res: number) => {
            setOnlineReadSametime(res);
        });

        return () => {
            const closeDetailPageMsg = {
                type: PATH.CLOSE_DETAIL_PAGE,
                bookId: params.id!!,
                needSend: true,
            };
            socket.setSocketSend?.(closeDetailPageMsg);
        };
    }, []);

    useEffect(() => {
        getApiData();
        if (currentPage === 1) {
            setPageStatusStart(false);
        } else {
            setPageStatusStart(true);
        }

        if (currentPage < pagenum) {
            setPageStatusEnd(true);
        } else {
            setPageStatusEnd(false);
        }

        let local = localStorage.getItem(COLLECTION_ID);

        setCategoryCollectionId(local);
    }, [params.id!, currentPage, pagenum]);

    useEffect(() => {
        rootElement && rootElement.scrollTo(0, 0);
        setBookMarked(props.bookMarkList.bookMarkList.filter((it: any) => it.productId == params.id!).length > 0);
        setCurrerntPage(1);
    }, [pathname]);

    const getApiData = () => {
        if (localStorage.getItem(PATH.USER_INFO) !== null) {
            new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                memberId: props.userInfo?.member?.memberId,
                productId: parseInt(params.id!),
                reportType: SaveReportRecordUsingPOSTReportTypeEnum.Content,
                bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
            });
        }
        new BorrowControllerApi(genApiConfig()).productBorrowStatusUsingPOST({ productId: parseInt(params.id!) }).then((data) => setProductBorrowStatus(data));
        new ProductControllerApi(genApiConfig()).getProductByProductIdUsingGET({ productId: parseInt(params.id!) }).then((data: any) => {
            // console.log("DDDDD", data);
            setDisplayData(data);
        });
        new ProductCommentControllerApi(genApiConfig())
            .getProductCommentByProductIdUsingGET({
                productId: parseInt(params.id!),
                productCommentPage: currentPage,
                productCommentSize: 5,
            })
            .then((data: any) => {
                setCommentDisplay(data.list);
                setPagenum(data.pagenum);
            });

        new BorrowControllerApi(genApiConfig())
            .listReserveByBookUsingGET({
                productId: parseInt(params.id!),
            } as ListReserveByBookUsingGETRequest)
            .then((data: Array<ReserveBorrow>) => {
                // console.log("listReserveByBookUsingGET", data);
                setReserveList(data);
            });

        if (localStorage.getItem(PATH.USER_INFO) !== null) {
            let member = JSON.parse(localStorage.getItem(PATH.USER_INFO)!!);

            if (!member.isAdmin) {
                new BorrowControllerApi(genApiConfig())
                    .listAllMyReserveBookUsingPOST({
                        memberId: member.member.memberId,
                    } as ListAllMyReserveBookUsingPOSTRequest)
                    .then((data: any) => {
                        props.myReserveBookList(data);
                    });
                new BorrowControllerApi(genApiConfig())
                    .listAllMyBorrowUsingPOST({
                        memberId: member.member.memberId,
                        pageNo: 0,
                        pageSize: 50,
                    } as ListAllMyBorrowUsingPOSTRequest)
                    .then((data: any) => {
                        props.myBorrowBookList(data.content);
                    });
                new MemberControllerApi(genApiConfig())
                    .getMemberFavouritesByMemberIdProdIdUsingGET({
                        memberId: member.member.memberId,
                        productId: parseInt(params.id!),
                    })
                    .then((data: any) => {
                        if (data.length > 0) {
                            setBookMarked(true);
                        }
                    });
            }
        }
    };

    useEffect(() => {
        rootElement && rootElement.scrollTo(0, 0);
        setBookMarked(props.bookMarkList.bookMarkList.filter((it: any) => it.productId == params.id!).length > 0);
        setCurrerntPage(1);
    }, [pathname]);

    const emailComfirm = () => {
        // if (bookingEmail === "" || validateEmail(bookingEmail) === null) {
        //   alert("請輸入有效的電郵資料");
        //   return;
        // }

        reserveBookComfirm(params.id!!);
    };

    const handletoggle = (value: any) => {
        switch (value) {
            case "info":
                //console.log(value);
                setToggleInfo(value);
                break;
            case "comment":
                setToggleInfo(value);
                break;
            case "author":
                setToggleInfo(value);
                break;
        }
    };

    const fileExtension = (apiDate: string) => {
        return apiDate.split(".").pop();
    };

    const convertDate = (fileName: string) => {
        //console.log("fileName", typeof fileName);
        return `${fileName.split(" ")[4]}年${fileName.split(" ")[2]}月`;
    };

    const handleReserveBook = (productId: string) => {
        if (props.auth === "" || props.auth === null) {
            setShowConfirmDialog(true);
            setConfirmDialogMessage("請先登入");
            // alert("請先登入");

            // if (
            //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
            //   window.location.host.includes("hkpl") ||
            //   process.env.REACT_APP_DOMAIN === "hkpl"
            // ) {
            //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
            // } else {
            //   navigate(PATH.LOGIN_PATH);
            // }
            return;
        }

        const reserve = props.reserveBookList.reserveBookList.find((it: any) => it.productId == params.id);

        if (reserve !== undefined) {
            alert("此書已在你的書架內");
        } else {
            setOpenReserveDialog(true);
            setOpenEmailDialog(true);
            //setOpenReserveDialog(true);
        }
    };

    const reserveBookComfirm = (productId: string) => {
        new BorrowControllerApi(genApiConfig())
            .reservedBookMoreThanMaxUsingPOST({
                memberId: props.userInfo?.member?.memberId,
                productId: parseInt(params.id!),
            })
            .then((res: SuccessResponseModel) => {
                if (res.success) {
                    setReserveBookMaxDialog(true);
                } else {
                    new BorrowControllerApi(genApiConfig())
                        .reserveBookUsingPOST({
                            memberId: props.userInfo?.member?.memberId,
                            productId: parseInt(productId),
                            email: bookingEmail,
                            needSend: needEmail,
                        } as ReserveBookUsingPOSTRequest)
                        .then((data) => {
                            // console.log("datbbbbb", data);

                            let flag = false;
                            if (data !== undefined && data !== null) {
                                alert("成功預約");
                                setOpenReserveDialog(false);
                                setBookingEmail("");
                                setOpenEmailDialog(false);
                                flag = true;
                            }
                            getApiData();
                            return flag;
                        })
                        .then((flagResult) => {
                            if (flagResult) {
                                new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                                    memberId: props.userInfo?.member.memberId,
                                    productId: parseInt(params.id!),
                                    reportType: SaveReportRecordUsingPOSTReportTypeEnum.BorrowTime,
                                    bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                                });
                            }
                        })
                        .then(() =>
                            new BorrowControllerApi(genApiConfig())
                                .listAllMyReserveBookUsingPOST({
                                    memberId: props.userInfo?.member.memberId,
                                } as ListAllMyReserveBookUsingPOSTRequest)
                                .then((data: any) => {
                                    //console.log("dataaaaaaaa", data);
                                    props.myReserveBookList(data);
                                })
                        )
                        .catch((err) => {
                            err.json().then((er: any) => {
                                //alert(er.message);
                                console.log("reserveBookComfirm", er.message);
                                setBookingEmail("");
                                setOpenEmailDialog(false);
                            });
                        });
                }
            });
        getApiData();
        setBookingEmail("");
        setOpenEmailDialog(false);
    };

    const borrowBookComfirm = () => {
        let requestQuery = {} as ProductBorrowMyStatusUsingPOSTRequest;

        requestQuery.memberId = props.userInfo.member.memberId;
        requestQuery.productId = parseInt(params.id!);

        //console.log("borrowBookComfirm", props);
        if (!loadingConfrim) {
            setloadingConfrim(true);
            // console.log("borrowBookComfirm");
            new BorrowControllerApi(genApiConfig())
                .productBorrowMyStatusUsingPOST(requestQuery)
                .then((res: MyBorrowStatus) => {
                    switch (res.status) {
                        case MyBorrowStatusStatusEnum.AlreadyBorrow:
                            return alert("書本已在書架");
                        case MyBorrowStatusStatusEnum.CanBorrow:
                            new BorrowControllerApi(genApiConfig())
                                .borrowedBookMoreThanMaxUsingPOST({
                                    memberId: props.userInfo?.member.memberId,
                                    productId: parseInt(params.id!),
                                })
                                .then((res: SuccessResponseModel) => {
                                    if (res.success) {
                                        setBorrowBookMaxDialog(true);
                                    } else {
                                        new BorrowControllerApi(genApiConfig())
                                            .borrowBookUsingPOST({
                                                memberId: props.userInfo?.member.memberId,
                                                productId: parseInt(params.id!),
                                            } as BorrowBookUsingPOSTRequest)
                                            .then((data: BorrowDto) => {
                                                let flag = false;
                                                if (data.borrowId !== 0) {
                                                    alert("成功借書");
                                                    setOpenBorrowDialog(false);
                                                    flag = true;
                                                    new BorrowControllerApi(genApiConfig())
                                                        .productBorrowStatusUsingPOST({
                                                            productId: parseInt(params.id!),
                                                        })
                                                        .then((data) => setProductBorrowStatus(data));
                                                }
                                                return flag;
                                            })
                                            .then((flagResult) => {
                                                //console.log("flagResult", flagResult);
                                                if (flagResult) {
                                                    new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                                                        memberId: props.userInfo?.member.memberId,
                                                        productId: parseInt(params.id!),
                                                        reportType: SaveReportRecordUsingPOSTReportTypeEnum.WebBorrow,
                                                        bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                                                    });
                                                }
                                            })
                                            .then(() =>
                                                new BorrowControllerApi(genApiConfig())
                                                    .listAllMyBorrowUsingPOST({
                                                        memberId: props.userInfo?.member.memberId,
                                                        pageNo: 0,
                                                        pageSize: 50,
                                                    } as ListAllMyBorrowUsingPOSTRequest)
                                                    .then((data: any) => {
                                                        props.myBorrowBookList(data.content);
                                                    })
                                            )
                                            .catch((err) => {
                                                console.log("borrowBookComfirm", err);
                                            });
                                    }
                                });

                            break;
                        case MyBorrowStatusStatusEnum.CanReserve:
                            new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                                memberId: props.userInfo?.member.memberId,
                                productId: parseInt(params.id!),
                                reportType: SaveReportRecordUsingPOSTReportTypeEnum.CantBorrow,
                                bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                            });
                            return alert("書本沒有剩餘，是否預約");
                        case MyBorrowStatusStatusEnum.AlreadyReserve:
                            return alert("書本已經預約");
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
        getApiData();
        setOpenBorrowDialog(false);
        setloadingConfrim(false);
    };

    useEffect(() => {
        //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
        let readerConfig = localStorage.getItem("readerConfig");

        if (readerConfig !== null) {
            let readerConfigObj = JSON.parse(readerConfig);

            if (props.reader.mode !== "" && props.reader.mode !== undefined) {
                readerConfigObj["rm"] = props.reader.mode.substring(0, 1);
                localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
                if (props.reader.mode === "read") {
                    setLoadingProd(true);
                    props.handleReaderMode("");
                }

                if (props.reader.mode === "preview") {
                    setLoadingPreview(true);
                    props.handleReaderMode("");
                }

                if (props.reader.mode === "online") {
                    setLoadingOnlineRead(true);
                    props.handleReaderMode("");
                }
            }
        }

        // if (props.userInfo.mode !== undefined) {
        //   console.log("props10", props.userInfo.mode);
        //   if (props.reader.mode === "read") {
        //     setLoadingProd(true);
        //   }
        //   if (props.reader.mode === "preview") {
        //     setLoadingPreview(true);
        //   }
        // }
    }, [props]);

    const handleReadBook = (actionType: string) => {
        if (isOpenBook) {
            setShowBookIsOpenDialog(true);
            return;
        }
        if (actionType === "read" || actionType === "preview" || actionType === "online") {
            props.handleReaderMode(actionType);

            //setReadAction(actionType);
        } else {
            setLoadingOnlineRead(true);
        }

        // if (props.userInfo?.member !== undefined) {
        //   new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
        //     memberId: props.userInfo?.member.memberId,
        //     productId: parseInt(params.id!),
        //     reportType: SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline,
        //     bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
        //   });
        // }
    };

    const handlePreViewBook = () => {
        setLoadingPreview(true);
        new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
            memberId: props.userInfo?.member.memberId,
            productId: parseInt(params.id!),
            reportType: SaveReportRecordUsingPOSTReportTypeEnum.Preview,
            bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
        });
    };

    const openBookClick = (bookObj: any, fileName: string) => {
        //console.log("openBookClick", bookObj, fileName);
        if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
            //props.history.push(BookUtil.getBookUrl(book));
            //props.handleReadingBook(book);
        } else {
            //localStorage.setItem("tempBook", JSON.stringify(book));

            BookUtil.RedirectBook(bookObj, fileName, parseInt(params.id!)).then(() => {
                if (localStorage.getItem("openBookUrl") !== undefined && localStorage.getItem("openBookUrl") !== null && localStorage.getItem("openBookUrl") !== "") {
                    setConfirmOpenBookDialog(true);
                }
            });
        }
        setLoadingPreview(false);
        setLoadingProd(false);
        setLoadingOnlineRead(false);
    };

    useEffect(() => {
        if (loadingPreview) {
            openBook();
        }
    }, [loadingPreview]);

    useEffect(() => {
        if (loadingProd || loadingOnlineRead) {
            openBook();
        }
    }, [loadingProd, loadingOnlineRead]);

    const openBook = () => {
        var item = displayData as IProductAllListModel;

        if (item["ePubProdFileName"] !== undefined) {
            var fileName = item["ePubProdFileName"];
            var fileExtension = getFileExtension(fileName);
            let reportType = "" as SaveReportRecordUsingPOSTReportTypeEnum;

            if (props.reader.mode === "read" || props.reader.mode === "online") {
                reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
            }

            if (props.reader.mode === "preview") {
                reportType = SaveReportRecordUsingPOSTReportTypeEnum.Preview;
            }

            let memberId = 1;

            if (Object.keys(props.userInfo).length > 0) {
                memberId = props.userInfo.member.memberId;
            }

            new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                memberId: memberId,
                productId: item.productId,
                reportType: reportType,
                bookType: fileExtension === "mp3" || fileExtension === "mp4" ? SaveReportRecordUsingPOSTBookTypeEnum.Audio : SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
            });

            if (getFileExtension(fileName) === "mp3") {
                var audioDataList = JSON.parse(item["multimediaFileNames"] as unknown as string);
                var formattedDataList: Array<any> = [];
                audioDataList
                    .sort((a: any, b: any) => {
                        return a["Seq"] - b["Seq"];
                    })
                    .map((chapter: any) => {
                        let chapterData: any = {};
                        chapterData["title"] = chapter["Title"];
                        chapterData["audioFilePath"] = chapter["File"];
                        chapterData["srtFilePath"] = chapter["Subtitle"];
                        chapterData["covers"] = [{ coverFilePath: chapter["Cover"], appearTime: 0 }];
                        formattedDataList.push(chapterData);
                    });
                var formattedData: any = { list: formattedDataList };
                formattedData["bookname"] = displayData && displayData.productNameChi;
                formattedData["author"] = displayData && displayData.author;
                formattedData["cover"] = displayData && displayData.coverFileName;
                formattedData["bookIsbn"] = displayData && (displayData.bookIsbn ? displayData.bookIsbn : displayData.sapArticleNumber);
                localforage.setItem("audio", formattedData);
                // bookIsbn null
                console.log("openBookUrl", `${window.location.href.split("/")[0]}/audioreader/${displayData.bookIsbn ? displayData.bookIsbn : displayData.sapArticleNumber}`);
                localStorage.setItem(
                    "openBookUrl",
                    `${window.location.href.split("/")[0]}/audioreader/${displayData.bookIsbn ? displayData.bookIsbn : displayData.sapArticleNumber}`
                );
                setLoadingPreview(false);
                setLoadingProd(false);
                setLoadingOnlineRead(false);
                setConfirmOpenBookDialog(true);

                return;
            }

            api.getFileByString(fileName).then(async (blob: any) => {
                var file = new File([blob], fileName, {
                    lastModified: new Date().getTime(),
                });

                var fileExtension = getFileExtension(fileName);

                await getMd5WithBrowser(file).then(async (md5) => {
                    if (fileExtension === "pdf") {
                        let reader = new FileReader();
                        reader.readAsArrayBuffer(blob);
                        reader.onload = async (event) => {
                            const file_content = event?.target?.result;
                            BookUtil.generateBook(fileName, fileExtension, md5, blob.size, fileName, file_content).then(async (bookObj) => {
                                //console.log("ffff", bookObj);
                                await BookUtil.addBook(bookObj.key, file_content);
                                RecentBooks.setRecent(bookObj.key);
                                localforage.setItem("books", bookObj);
                                openBookClick(bookObj, item.productNameChi);
                            });
                        };
                    }

                    if (fileExtension === "epub") {
                        addEpub(file, md5, fileName).then(async (bookObj) => {
                            await BookUtil.addBook(bookObj.key, file);
                            RecentBooks.setRecent(bookObj.key);
                            localforage.setItem("books", bookObj);
                            openBookClick(bookObj, item.productNameChi);
                        });
                    }
                });
            });
        }
    };

    // const openBook = () => {
    //   var item = displayData as IProductAllListModel;

    //   if (item["ePubProdFileName"] !== undefined) {
    //     var fileName = item["ePubProdFileName"];
    //     api.getFileByFileName(fileName).then((fileResult: any) => {
    //       fileResult.blob().then(async (blob: any) => {
    //         var file = new File([blob], fileName, {
    //           lastModified: new Date().getTime(),
    //         });

    //         var fileExtension = getFileExtension(fileName);

    //         await getMd5WithBrowser(file).then(async (md5) => {
    //           if (fileExtension === "pdf") {
    //             let reader = new FileReader();
    //             reader.readAsArrayBuffer(blob);
    //             reader.onload = async (event) => {
    //               const file_content = event?.target?.result;
    //               BookUtil.generateBook(
    //                 fileName,
    //                 fileExtension,
    //                 md5,
    //                 blob.size,
    //                 fileName,
    //                 file_content
    //               ).then(async (bookObj) => {
    //                 //console.log("ffff", bookObj);
    //                 await BookUtil.addBook(bookObj.key, file_content);
    //                 RecentBooks.setRecent(bookObj.key);
    //                 localforage.setItem("books", bookObj);
    //                 openBookClick(bookObj, item.productNameChi);
    //               });
    //             };
    //           }

    //           if (fileExtension === "epub") {
    //             addEpub(file, md5, fileName).then(async (bookObj) => {
    //               await BookUtil.addBook(bookObj.key, file);
    //               RecentBooks.setRecent(bookObj.key);
    //               localforage.setItem("books", bookObj);
    //               openBookClick(bookObj, item.productNameChi);
    //             });
    //           }
    //         });

    //         let reportType = "" as SaveReportRecordUsingPOSTReportTypeEnum;

    //         if (props.reader.mode === "read" && props.reader.mode === "online") {
    //           reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
    //         }

    //         if (props.reader.mode === "preview") {
    //           reportType = SaveReportRecordUsingPOSTReportTypeEnum.Preview;
    //         }

    //         if (props.userInfo?.member !== undefined) {
    //           new ReportRecordControllerApi(
    //             genApiConfig()
    //           ).saveReportRecordUsingPOST({
    //             memberId: props.userInfo?.member.memberId,
    //             productId: props.props.productId,
    //             reportType: reportType,
    //             bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
    //           });
    //         }
    //       });
    //     });
    //   }
    // };

    const handlePostComment = () => {
        if (localStorage.getItem(PATH.USER_INFO) === null) {
            setShowConfirmDialog(true);
            setConfirmDialogMessage("請先登入");
            return;
            //return alert(`請先登入`);
        }

        if (props.userInfo.isAdmin) {
            return alert("請使用學生用戶");
        }

        if (message === "") {
            return alert("請輸入評論");
        }
        if (rating === undefined || rating === 0) {
            return alert("請輸入評分");
        }
        const body: { comment: string; rating: number } = {
            comment: message,
            rating: rating,
        };
        //console.log("body", body);
        new ProductCommentControllerApi(genApiConfig())
            .insertProductCommentUsingPOST({
                memberId: props.userInfo?.member.memberId,
                productId: parseInt(params.id!),
                productCommentPayloadModel: body,
            } as InsertProductCommentUsingPOSTRequest)
            .then((data) => {
                if (data.success) {
                    new ProductControllerApi(genApiConfig()).getProductByProductIdUsingGET({ productId: parseInt(params.id!) }).then((data: any) => {
                        setDisplayData(data);
                        //console.log(data);
                        setCommentDisplay(data.productComments);
                    });
                    setMessage("");
                    setRating(0);
                }
            });
        // api.postCommentMessage("4", params.id!.toString(), body).then((data) => {
        //   console.log(data);
        // });
    };

    const handlePreviousPgae = () => {
        if (currentPage === 1) return;
        setCurrerntPage(currentPage - 1);
    };

    const handleNextPgae = () => {
        if (currentPage === pagenum) return;
        setCurrerntPage(currentPage + 1);
    };

    const handleBookMarked = () => {
        if (localStorage.getItem(PATH.USER_INFO) === null) {
            setShowConfirmDialog(true);
            setConfirmDialogMessage("請先登入");
            // alert("請先登入");
            // if (
            //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
            //   window.location.host.includes("hkpl") ||
            //   process.env.REACT_APP_DOMAIN === "hkpl"
            // ) {
            //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
            // } else {
            //   navigate(PATH.LOGIN_PATH);
            // }

            return;
        }

        console.log("handleBookMarked", JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin);
        if (JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin) {
            // if (props.userInfo.isAdmin) {
            return alert("請使用學生用戶");
        }

        if (bookMarked) {
            new MemberControllerApi(genApiConfig())
                .deleteMemberFavouriteByMemberIdUsingDELETE({
                    memberId: props.userInfo?.member.memberId,
                    productId: parseInt(params.id!),
                } as DeleteMemberFavouriteByMemberIdUsingDELETERequest)
                .then((data) => {
                    if (data) {
                        setBookMarked(false);
                        alert(t("RemoveBookmark"));
                        new MemberControllerApi(genApiConfig())
                            .getMemberFavouritesByMemberIdUsingGET({
                                memberId: props.userInfo?.member.memberId,
                            } as GetMemberFavouritesByMemberIdUsingGETRequest)
                            .then((data: Array<ProductDto>) => {
                                props.myBookMarkList(data);
                                //console.log("nonononono", data);
                            });
                    }
                });
            return;
        }

        new MemberControllerApi(genApiConfig())
            .addMemberFavouriteByMemberIdUsingPUT({
                memberId: props.userInfo?.member.memberId,
                productId: parseInt(params.id!),
            } as AddMemberFavouriteByMemberIdUsingPUTRequest)
            .then((data) => {
                if (data) {
                    setBookMarked(true);
                    alert(t("AddBookmark"));
                    setOpenBookmarkDialog(true);
                    new MemberControllerApi(genApiConfig())
                        .getMemberFavouritesByMemberIdUsingGET({
                            memberId: props.userInfo?.member.memberId,
                        } as GetMemberFavouritesByMemberIdUsingGETRequest)
                        .then((data) => {
                            if (data) {
                                setBookMarked(true);
                                new MemberControllerApi(genApiConfig())
                                    .getMemberFavouritesByMemberIdUsingGET({
                                        memberId: props.userInfo?.member.memberId,
                                    } as GetMemberFavouritesByMemberIdUsingGETRequest)
                                    .then((data) => {
                                        props.myBookMarkList(data);
                                        //console.log("hehehehe", data);
                                    });
                            }
                        });
                }
            });
    };

    const checkLogined = () => {
        if (localStorage.getItem(PATH.USER_INFO) === null) {
            setShowConfirmDialog(true);
            setConfirmDialogMessage("請先登入");
            // alert("請先登入");
            // if (
            //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
            //   window.location.host.includes("hkpl") ||
            //   process.env.REACT_APP_DOMAIN === "hkpl"
            // ) {
            //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
            // } else {
            //   navigate(PATH.LOGIN_PATH);
            // }
            return;
        }

        if (JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin) {
            alert("請使用學生用戶");
            return;
        }

        setOpenBorrowDialog(true);
    };

    const handleCancelReserveBook = () => {
        new BorrowControllerApi(genApiConfig())
            .cancelReservedBookUsingDELETE({
                memberId: props.userInfo?.member.memberId,
                productId: parseInt(params.id!),
            })
            .then((data) => {
                if (data.status !== undefined) {
                    alert(`書本 ${displayData.productNameChi} 的預約己取消`);
                    getApiData();
                }
            });
    };

    const checkOnlineUser = () => {
        const checkOnlineUserMsg = {
            type: PATH.CHECK_ONLINE_USER,
            bookId: params.id!!,
            needSend: true,
        };

        socket.setSocketSend?.(checkOnlineUserMsg);
    };

    const getOnlineReadBtn = () => {
        /*
        let flag = false;

        // check user login or not
        if (localStorage.getItem(PATH.USER_INFO) !== null || isWhiteListIp) {
            flag = true;
        }

        // check white list ip list
        // if (currentIp !== "") {
        //   PATH.WHITE_LIST_IP.forEach((it) => {
        //     if (it === currentIp) {
        //       flag = true;
        //     }
        //   });
        // }

        // for testing
        // flag = true;
        // for testing end

        if (flag) {
            return (
                <>
                    <Button
                        styleType={"highlightButton"}
                        loadingStyle={{ width: "20px", height: "20px" }}
                        isLoading={loadingOnlineRead}
                        disabled={hiddenOnlineRead}
                        callBack={() => handleReadBook("online")}
                    >
                        {hiddenOnlineRead ? (
                            <>
                                <Trans>OnlineReadFull</Trans>
                            </>
                        ) : (
                            <>
                                <Trans>OnlineRead</Trans>
                            </>
                        )}
                    </Button>
                </>
            );
        } else {
            return getOnlineReadText();
        }

        return <></>;
        */
        return (
            <>
                <Button
                    styleType={"highlightButton"}
                    loadingStyle={{ width: "20px", height: "20px" }}
                    isLoading={loadingOnlineRead}
                    disabled={hiddenOnlineRead}
                    callBack={() => handleReadBook("online")}
                >
                    {hiddenOnlineRead ? (
                        <>
                            <Trans>OnlineReadFull</Trans>
                        </>
                    ) : (
                        <>
                            <span className={style.highlightButton_text}><Trans>OnlineRead2</Trans></span><Trans>OnlineRead</Trans>
                        </>
                    )}
                </Button>
            </>
        );
    };
    const getOnlineReadText = () => {
        return (
            <div style={{ /* maxWidth: "120px" */ }}>
                讀者登入後可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能即時閱讀，或借閱/預約書籍
            </div>
        );
    };

    return (
        //  <div className={style.BookDetailLayout}>
        //     <div className={style.BookDetailInnerLayout}>
        <>
            <div className={style.loading} style={{ display: loadingData ? "block" : "none" }}>
                <PageLoading />
            </div>
            <div className={style.BookDetailHeader}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
                <div className={style.category}>
                    {/* <div className={style.categoryButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            全部分類
                        </Button>
                    </div>
                    <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
                    <div className={style.categoryButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            中二必讀
                        </Button>
                    </div> */}
                    {/* <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div> */}
                    {/* <div>{displayData.productNameChi}</div> */}
                    <div>
                        {i18n.language === "cn" ? displayData.productNameChi && converter(displayData.productNameChi) : displayData.productNameChi && displayData.productNameChi}
                    </div>
                </div>
            </div>
            <div className={style.pageContentLayout}>
                <div className={style.bookDetailsContainer_holder}>
                    <div className={style.bookDetailsContainer}>
                        <div className={style.bookLayout}>
                            <div className={style.bookImgContainer}>
                                {displayData.coverFileName && displayData.coverFileName !== undefined && displayData.coverFileName !== "" ? (
                                    <>
                                        <img
                                            src={
                                                process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com"
                                                    ? `${process.env.REACT_APP_BACKEND}/file/${displayData.coverFileName}`
                                                    : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${displayData.coverFileName}?x-oss-process=image/resize,h_200`
                                            }
                                            // src={
                                            //   displayData.coverFileName &&
                                            //   process.env.REACT_APP_BACKEND +
                                            //     "/file/" +
                                            //     displayData.coverFileName
                                            // }
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>

                            <div className={style.buttonGroup}>
                                <div>
                                    {
                                        productBorrowStatus
                                        && (
                                            (
                                                productBorrowStatus.canBorrowCopy
                                                && productBorrowStatus.canBorrowCopy > 0
                                            )
                                            || (
                                                productBorrowStatus.collectionBorrowStatusDtos
                                                && productBorrowStatus.collectionBorrowStatusDtos.length > 0
                                            )
                                        )
                                            ?
                                                (
                                                    props.borrowBookList.borrowBookList.find(
                                                        (it: any) => (
                                                            params.id !== undefined
                                                                ? it.productId === parseInt(params.id)
                                                                : false
                                                        )
                                                    ) !== undefined
                                                        ? (
                                                            <div className={style.btnGroupDiv}>
                                                                <Button
                                                                    styleType={"upperNavbarButton"}
                                                                    loadingStyle={{ width: "20px", height: "20px" }}
                                                                    isLoading={loadingProd}
                                                                    callBack={() => handleReadBook("read")}
                                                                >
                                                                    <Trans>Read</Trans>
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div className={style.btnGroupDiv}>
                                                                <Button styleType={"upperNavbarButton"} callBack={() => checkLogined()}>
                                                                    <Trans>Borrow</Trans>
                                                                </Button>
                                                            </div>
                                                        )
                                                )
                                            : 
                                                props.borrowBookList.borrowBookList.find(
                                                    (it: any) => (params.id !== undefined ? it.productId === parseInt(params.id) : false)
                                                ) !== undefined
                                                    ? (
                                                        <div className={style.btnGroupDiv}>
                                                            <Button styleType={"upperNavbarButton"} isLoading={loadingProd} callBack={() => handleReadBook("read")}>
                                                                <Trans>Read</Trans>
                                                            </Button>
                                                        </div>
                                                    ) :
                                                        reserveList.find(
                                                            (it: ReserveBorrow) => it.memberId == props.userInfo?.member?.memberId
                                                        ) === undefined
                                                            ? (
                                                                <div className={style.btnGroupDiv}>
                                                                    {props.userInfo !== undefined && JSON.stringify(props.userInfo) !== "{}" ? (
                                                                        <>
                                                                            <Button
                                                                                styleType={"upperNavbarButton"}
                                                                                callBack={() => {
                                                                                    if (JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin) {
                                                                                        // if (props.userInfo.isAdmin) {
                                                                                        return alert("請使用學生用戶");
                                                                                    }
                                                                                    //setOpenReserveDialog(true)
                                                                                    setOpenEmailDialog(true);
                                                                                    //console.log("bugshere", props.userInfo);
                                                                                }}
                                                                            >
                                                                                <Trans>Booking</Trans>
                                                                            </Button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Button
                                                                                styleType={"upperNavbarButton"}
                                                                                callBack={() => {
                                                                                    setShowConfirmDialog(true);
                                                                                    setConfirmDialogMessage("請先登入");
                                                                                    // alert("請先登入");
                                                                                    // if (
                                                                                    //   process.env.REACT_APP_BACKEND ==
                                                                                    //     "https://suepsso.octopus-tech.com" ||
                                                                                    //   window.location.host.includes("hkpl") ||
                                                                                    //   process.env.REACT_APP_DOMAIN === "hkpl"
                                                                                    // ) {
                                                                                    //   window.parent.parent.location =
                                                                                    //     PATH.HKPL_SSO_PATH;
                                                                                    // } else {
                                                                                    //   navigate(PATH.LOGIN_PATH);
                                                                                    // }
                                                                                }}
                                                                            >
                                                                                <Trans>Booking</Trans>
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div className={style.btnGroupDiv}>
                                                                    <Button
                                                                        styleType={"upperNavbarButton"}
                                                                        callBack={() => {
                                                                            //setOpenReserveDialog(true)
                                                                            // setOpenEmailDialog(true);
                                                                            //console.log("bugshere", props.userInfo);
                                                                            handleCancelReserveBook();
                                                                        }}
                                                                    >
                                                                        <Trans>CancelBooking</Trans>
                                                                    </Button>
                                                                </div>
                                                            )
                                    }

                                    <div className={style.bookmark}>
                                        {props.userInfo !== undefined ? (
                                            <>
                                                <img
                                                    src={bookMarked ? bookmarkon : bookmarkOff}
                                                    onClick={() => {
                                                        handleBookMarked();
                                                        //console.log("bugshere", props.userInfo);
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={bookMarked ? bookmarkon : bookmarkOff}
                                                    onClick={() => {
                                                        setShowConfirmDialog(true);
                                                        setConfirmDialogMessage("請先登入");
                                                        // alert("請先登入");
                                                        // if (
                                                        //   process.env.REACT_APP_BACKEND ==
                                                        //     "https://suepsso.octopus-tech.com" ||
                                                        //   window.location.host.includes("hkpl") ||
                                                        //   process.env.REACT_APP_DOMAIN === "hkpl"
                                                        // ) {
                                                        //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
                                                        // } else {
                                                        //   navigate(PATH.LOGIN_PATH);
                                                        // }
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={[
                                    style.buttonGroup_row2
                                    , (localStorage.getItem(PATH.USER_INFO) !== null || isWhiteListIp)
                                        ? ""
                                        : style.two_row
                                ].join(" ")}>
                                    {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                                        <>
                                            {props.borrowBookList.borrowBookList.find((it: any) => (params.id !== undefined ? it.productId === parseInt(params.id) : false)) ===
                                            undefined ? (
                                                <div>
                                                    <Button
                                                        styleType={"upperNavbarButton"}
                                                        loadingStyle={{ width: "20px", height: "20px" }}
                                                        isLoading={loadingPreview}
                                                        callBack={() => handleReadBook("preview")}
                                                    >
                                                        <Trans>Preview</Trans>
                                                    </Button>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {
                                        (localStorage.getItem(PATH.USER_INFO) !== null || isWhiteListIp)
                                            ? <div className={style.onlineReadBtn_placeholder}>{getOnlineReadBtn()}</div>
                                            : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={style.bookDetailLayout}>
                            <h3>
                                {displayData.productNameChi
                                    ? i18n.language === "cn"
                                        ? displayData.productNameChi && converter(displayData.productNameChi)
                                        : displayData.productNameChi && displayData.productNameChi
                                    : i18n.language === "cn"
                                    ? converter("載入中....")
                                    : "載入中...."}
                            </h3>
                            {/* <h3>{i18n.language === "cn" ? displayData && converter(displayData.productNameChi) : displayData && displayData.productNameChi}</h3> */}
                            {/*
                            <div className={style.star} style={{ cursor: "pointer" }}>
                                <Rating modify={false} rate={displayData && displayData.rating} />
                            </div>
                            <span className={style.clickRate}>
                                <Trans>ClickRate</Trans>
                            </span>
                            */}
                            <div className={style.flex}>
                                {displayData.author &&
                                    displayData.author.split(":::").map((it: any) => (
                                        <>
                                            {it.split(/[、,， ]+/).length != 1 ? (
                                                <>
                                                    {it.split(/[、,， ]+/).map((o: any) => (
                                                        <>
                                                            <p className={style.author} onClick={() => navigate(`/search/result?inputOption=author&keyword=${o}`)}>
                                                                {o}
                                                            </p>
                                                            &nbsp;
                                                        </>
                                                    ))}
                                                </>
                                            ) : (
                                                <p className={style.author} onClick={() => navigate(`/search/result?inputOption=author&keyword=${it}`)}>
                                                    {it && it} &nbsp;
                                                </p>
                                            )}
                                            {/* <p className={style.author} onClick={() => navigate(`/search/result?keyword=${it}`)}>
                                                {it && it} &nbsp;
                                                {it && it.split("、").length != 0 ? it.split("、")[0] : it}
                                            </p> */}
                                        </>
                                    ))}
                            </div>
                            <div className={style.subDetails}>
                                <div>
                                    <p>
                                        <Trans>Organization</Trans>
                                    </p>
                                    <p
                                        style={{ color: "rgba(181 60 63)", cursor: "pointer" }}
                                        onClick={() => navigate(`/search/result?organizationId=${displayData.publishHouse.organizationId}`)}
                                    >
                                        {/* {displayData.publishHouse && displayData.publishHouse.name && displayData.publishHouse.name} */}
                                        {i18n.language === "cn"
                                            ? displayData.publishHouse && displayData.publishHouse.name && converter(displayData.publishHouse.name)
                                            : displayData.publishHouse && displayData.publishHouse.name && displayData.publishHouse.name}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <Trans>PublishDate</Trans>:
                                    </p>
                                    <p>{displayData?.pPublishDate && dateToString(displayData.pPublishDate, "yyyy年MM月")}</p>
                                </div>
                                <div>
                                    <p>
                                        <Trans>Format</Trans>:
                                    </p>
                                    <p>{displayData.ePubProdFileName && fileExtension(displayData.ePubProdFileName)}</p>
                                </div>
                                <div>
                                    <p>
                                        <Trans>Category</Trans>:
                                    </p>
                                    {/* <div style={{ display: "flex" }}> */}
                                    <div className={style.subDetailscategory}>
                                        {displayData.categories &&
                                            displayData.categories.map((it: any) => (
                                                <p style={{ color: "rgba(181 60 63)", cursor: "pointer" }} onClick={() => navigate(`/search/result?categoryId=${it.categoryId}`)}>
                                                    {/* {it.categoryName} */}
                                                    {i18n.language === "cn" ? converter(it.categoryName) : it.categoryName}
                                                    &nbsp;
                                                </p>
                                            ))}
                                    </div>
                                </div>
                                <div>
                                    <p>ISBN :</p>
                                    <p>{displayData.bookIsbn}</p>
                                </div>
                                <div>
                                    {displayData.seriesName != undefined ? (
                                        <p>
                                            <Trans>bookseries</Trans>:
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    <p>{displayData.seriesName != undefined ? displayData.seriesName : ""}</p>
                                    {/*
                                    <p>
                                     <Trans>Readage</Trans>
                                    </p>
                                    */}
                                    {/* TODO */}
                                    {/* <p>借閱次數：</p> */}
                                </div>
                                {/*
                                <div>

                                </div>
                                */}
                            </div>
                            <h4>
                                <>
                                    {/* 
                                    console.log(
                                        "props.userInfo??.member?.memberId",
                                        props.userInfo??.member?.memberId
                                    )
                                    */}
                                    {
                                        productBorrowStatus &&
                                        (
                                            (
                                                productBorrowStatus.canBorrowCopy
                                                && productBorrowStatus.canBorrowCopy > 0
                                            )
                                            || (
                                                productBorrowStatus.collectionBorrowStatusDtos
                                                && productBorrowStatus.collectionBorrowStatusDtos.length > 0
                                                && productBorrowStatus
                                                    .collectionBorrowStatusDtos
                                                    .some(
                                                        (c: BookCollectionBorrowStatusDto) => c.canBorrowCopy && c.canBorrowCopy > 0
                                                    )
                                            )
                                        )
                                            ?
                                                (
                                                    <></>
                                                )
                                            : 
                                                props.borrowBookList.borrowBookList.find(
                                                    (it: any) => (params.id !== undefined ? it.productId === parseInt(params.id) : false)
                                                ) === undefined
                                                    ? (
                                                        reserveList.find((it: ReserveBorrow) => it.memberId == props.userInfo?.member?.memberId) !== undefined ? (
                                                            <>
                                                                <Trans>YourBookingSeq</Trans>
                                                                {reserveList.map((value, index) => {
                                                                    if (value.memberId == props.userInfo?.member?.memberId) {
                                                                        return index + 1;
                                                                    }
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Trans>BookingCount</Trans>
                                                                {reserveList.length}
                                                            </>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )
                                    }
                                </>
                            </h4>
                        </div>
                    </div>
                    {
                        (localStorage.getItem(PATH.USER_INFO) !== null || isWhiteListIp)
                            ? <></>
                            : <div className={style.rowOnlineRead}>{getOnlineReadText()}</div>
                    }
                </div>
                <div className={style.bookInventoryContainer}>
                    <div className={style.bookInventoryDetail}>
                        {
                            categoryCollectionId
                            && productBorrowStatus?.collectionBorrowStatusDtos?.length > 0 
                            && productBorrowStatus?.collectionBorrowStatusDtos?.some(
                                (category: BookCollectionBorrowStatusDto) => category.categoryId === parseInt(categoryCollectionId)
                            )
                                ? (
                                    <>
                                        {/* region collection category name and remain borrow quota*/}
                                        {
                                            productBorrowStatus?.collectionBorrowStatusDtos?.filter(
                                                (category: BookCollectionBorrowStatusDto) => category.categoryId === parseInt(categoryCollectionId)
                                            )?.map(
                                                function (category_bccsd: BookCollectionBorrowStatusDto) {
                                                    return (
                                                        <>
                                                            <div>
                                                                <h3>
                                                                    <Trans>{category_bccsd.categoryName}</Trans>
                                                                </h3>
                                                            </div>

                                                            <div className={style.InventoryRow}>
                                                                <div>
                                                                    {
                                                                        category_bccsd.canBorrowCopy !== undefined
                                                                        && category_bccsd.canBorrowCopy > 0
                                                                            ? (
                                                                                <>
                                                                                    <h5>
                                                                                        <Trans>BorrowRemainder</Trans>
                                                                                    </h5>
                                                                                    <h5 style={{ color: "grey" }}>{category_bccsd.canBorrowCopy}</h5>
                                                                                </>
                                                                            )
                                                                            : (
                                                                                <h5>{i18n.language === "cn" ? converter("借閱已滿") : "借閱已滿"}</h5>
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            )
                                        }
                                        {/* endregion */}
                                    </>
                                )
                                : (
                                    <>
                                        {/* region lib title */}
                                        <div>
                                            {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                                                <h3>
                                                    <Trans>hkpl</Trans>
                                                </h3>
                                            ) : (
                                                <></>
                                            )}

                                            {process.env.REACT_APP_WEB_NAME === "sueplibrary" && process.env.REACT_APP_BACKEND === "https://lib-api.nblib.com" ? <h3>DEMO</h3> : <></>}
                                        </div>
                                        {/* endregion */}

                                        {/* region lib inv */}
                                        <div className={style.InventoryRow}>
                                            <div>
                                                {
                                                    productBorrowStatus
                                                    && productBorrowStatus?.collectionBorrowStatusDtos
                                                    && productBorrowStatus?.collectionBorrowStatusDtos?.length > 0 
                                                        ?
                                                            productBorrowStatus?.collectionBorrowStatusDtos[0]?.canBorrowCopy! > 0 
                                                                ? (
                                                                    <>
                                                                        <h5>
                                                                            <Trans>BorrowRemainder</Trans>
                                                                        </h5>
                                                                        <h5 style={{ color: "grey" }}>{
                                                                            productBorrowStatus?.collectionBorrowStatusDtos[0]?.canBorrowCopy!
                                                                        }</h5>
                                                                    </>
                                                                )
                                                                : (
                                                                    <h5>{i18n.language === "cn" ? converter("借閱已滿") : "借閱已滿"}</h5>
                                                                )
                                                        :
                                                            productBorrowStatus?.canBorrowCopy! > 0 ? (
                                                                <>
                                                                    <h5>
                                                                        <Trans>BorrowRemainder</Trans>
                                                                    </h5>
                                                                    <h5 style={{ color: "grey" }}>{productBorrowStatus?.canBorrowCopy!}</h5>
                                                                </>
                                                            ) : (
                                                                <h5>{i18n.language === "cn" ? converter("借閱已滿") : "借閱已滿"}</h5>
                                                            )
                                                }
                                            </div>
                                        </div>
                                        {/* endregion */}
                                    </>
                                )
                        }
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button
                        buttonType={"button"}
                        styleType={toggleInfo === "info" ? "toggleButtonCurrent" : "toggleButton"}
                        callBack={(value) => handletoggle("info")}
                        // onClick={activeLogin()}
                    >
                        <Trans>BriefIntroduction</Trans>
                    </Button>
                    <Button buttonType={"button"} styleType={toggleInfo === "author" ? "toggleButtonCurrent" : "toggleButton"} callBack={(value) => handletoggle("author")}>
                        <Trans>AuthorProfile</Trans>
                    </Button>
                    {/*
                    <Button
                        buttonType={"button"}
                        styleType={
                            toggleInfo === "comment" ? "toggleButtonCurrent" : "toggleButton"
                        }
                        callBack={(value) => handletoggle("comment")}
                    >
                        <Trans>Comment</Trans>
                    </Button>
                    */}
                </div>
                <div className={style.commentInnerContainer}>
                    <div className={toggleInfo === "info" ? style.introParagraph : style.introParagraphNonActive}>
                        {/* <p>{displayData.productDescription && displayData.productDescription}</p> */}
                        <p>
                            {i18n.language === "cn"
                                ? displayData.productDescription && converter(displayData.productDescription)
                                : displayData.productDescription && displayData.productDescription}
                        </p>
                    </div>
                    <div className={toggleInfo === "author" ? style.introParagraph : style.introParagraphNonActive}>
                        {/* <p>{displayData.aboutAuthor && displayData.aboutAuthor}</p> */}
                        <p>{i18n.language === "cn" ? displayData.aboutAuthor && converter(displayData.aboutAuthor) : displayData.aboutAuthor && displayData.aboutAuthor}</p>
                    </div>
                    <div className={toggleInfo === "comment" ? style.bookComments : style.bookCommentsNonActive}>
                        <div className={style.commentBoxInnerContainer}>
                            <div className={style.commenterInfo}>
                                <div className={style.commenter}>
                                    {localStorage.getItem(PATH.USER_INFO) != null ? JSON.parse(localStorage.getItem(PATH.USER_INFO)!!).name : ""}
                                    {/* {JSON.parse(localStorage.getItem(PATH.USER_INFO)!!).name} */}
                                </div>
                                <div className={style.rating}>
                                    <Rating modify={true} value={rating} callBack={(value: number) => setRating(value)} />
                                </div>
                            </div>
                            <div className={style.comment}>
                                <div className={style.commentTextarea}>
                                    <Textarea placeholder={t("comment100")} styleType={"commentInput"} callBack={(value) => setMessage(value)} value={message}></Textarea>
                                </div>

                                <div className={style.commentButton}>
                                    <Button styleType={"upperNavbarButton"} callBack={() => handlePostComment()}>
                                        <Trans>Comment</Trans>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div className={style.horizontalLineContainer}> */}
                        {commentDisplay.map((it: any, idx: number) => (
                            <div className={style.commentContainer}>
                                <div className={style.commenterInfo}>
                                    <div className={style.commenter}>{it.memberUsername}</div>
                                    <div className={style.rating}>
                                        <Rating modify={false} rate={it.rating} />
                                    </div>
                                </div>
                                <div className={style.comment}>
                                    <div className={style.commentText}>{it.comment}</div>

                                    <div className={style.commentDate} style={{ fontSize: "12px" }}>
                                        {dateToString(it.createAt, "DD/MM/yyyy")}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className={pageStatusStart ? style.pageStatus : ""} onClick={() => handlePreviousPgae()}>
                                {pageStatusStart ? <Trans>PreviousPage</Trans> : ""}
                            </div>

                            <div className={pageStatusEnd ? style.pageStatus : ""} onClick={() => handleNextPgae()}>
                                {pageStatusEnd ? <Trans>NextPage</Trans> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.relatedBookContainer}>
                <h4>
                    <Trans>RelatedBook</Trans>
                </h4>
                <div className={style.relatedBookInnerContainer}>
                    {displayData.relatedProducts &&
                        displayData.relatedProducts.map((item: any, idx: number) => <BookViewRelated props={item} key={idx} bookMarkList={props.bookMarkList} />)}
                </div>
            </div>
            <DialogBox
                styleType={PATH.STYLETYPE_FOUR}
                title={<Trans>BorrowBook</Trans>}
                p1={<Trans>BorrowBookText</Trans>}
                bookName={displayData && displayData.productNameChi}
                p2={<Trans>QuestionMark</Trans>}
                showDialog={openBorrowDialog}
                children={
                    <>
                        {
                            hiddenOnlineRead
                                ? (
                                    <></>
                                )
                                : (
                                    <>
                                        你也可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能，即時閱讀，無須借閱
                                    </>
                                )
                        }
                        <div className={style.dialogButtonContainer}>
                            <Button styleType={"cancelButton"} callBack={() => setOpenBorrowDialog(false)}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button styleType={"submitButton"} callBack={() => borrowBookComfirm()} disabled={loadingConfrim}>
                                <Trans>Borrow</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
            <DialogBox
                styleType={PATH.STYLETYPE_THIRD}
                title={<Trans>BookReservation</Trans>}
                p1={<Trans>BookReservationText</Trans>}
                bookName={displayData && displayData.productNameChi}
                p2={<Trans>QuestionMark</Trans>}
                showDialog={openReserveDialog}
                children={
                    <>
                        {
                            hiddenOnlineRead
                                ? (
                                    <></>
                                )
                                : (
                                    <>
                                        你也可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能，即時閱讀，無須預約等候
                                    </>
                                )
                        }
                        <div className={style.dialogButtonContainer}>
                            <Button styleType={"cancelButton"} callBack={() => setOpenReserveDialog(false)}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                styleType={"submitButton"}
                                callBack={() => setOpenEmailDialog(true)}
                                //callBack={() => reserveBookComfirm(params.id!!)}
                            >
                                <Trans>Booking</Trans>
                            </Button>
                        </div>
                    </>
                }
            />

            <DialogBox
                styleType={PATH.STYLETYPE_THIRD}
                title={<Trans>AppointmentNotice</Trans>}
                p1={
                    <>
                        <Trans>AppointmentNoticeText</Trans>
                        <Link to={PATH.POLICY}>
                            <Trans>AppointmentNoticeTextURL</Trans>
                        </Link>
                        <Trans>AppointmentNoticeTextClose</Trans>
                    </>
                }
                bookName={displayData && displayData.productNameChi}
                showDialog={openEmailDialog}
                children={
                    <>
                        <div className={style.pad}>
                            <Input
                                type="email"
                                placeholder={t("inputEmail")}
                                styleType={PATH.STYLETYPE_FIFTH}
                                disabled={
                                    Object.keys(props.userInfo).length > 0
                                        ? props.userInfo.member.email !== undefined && props.userInfo.member.email !== ""
                                            ? true
                                            : false
                                        : false
                                }
                                // hidden={
                                //   needEmail
                                //     ? false
                                //     : props.userInfo?.member.email !== undefined &&
                                //       props.userInfo?.member.email !== ""
                                //     ? false
                                //     : true
                                // }
                                // hidden={needEmail ? true : props.userInfo?.member.email !== undefined && props.userInfo?.member.email !== "" ? true : false}
                                value={bookingEmail}
                                callBack={(value: any) => setBookingEmail(value)}
                            />
                        </div>
                        {
                            hiddenOnlineRead
                                ? (
                                    <></>
                                )
                                : (
                                    <div className={style.textAppointmentNoticeForOnlineRead}>
                                        你也可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能，即時閱讀，無須預約等候
                                    </div>
                                )
                        }
                        <div className={style.dialogButtonContainer}>
                            <Button
                                styleType={"cancelButton"}
                                callBack={() => {
                                    setBookingEmail("");
                                    setOpenEmailDialog(false);
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                styleType={"submitButton"}
                                callBack={() => {
                                    if (needEmail && !validateEmail(bookingEmail)) {
                                        alert("請輸入有效電郵");
                                    } else {
                                        reserveBookComfirm(params.id!!);
                                    }
                                }}
                            >
                                <Trans>submit</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
            <DialogBox
                styleType={PATH.STYLETYPE_FOUR}
                title="無法借閱"
                p1="你的借閱書本已達上限，請先還書後再嘗試"
                showDialog={borrowBookMaxDialog}
                children={
                    <>
                        {
                            hiddenOnlineRead
                                ? (
                                    <></>
                                )
                                : (
                                    <>
                                        你也可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能，即時閱讀，無須借閱
                                    </>
                                )
                        }
                        <div className={style.dialogButtonContainer}>
                            <Button styleType={"cancelButton"} callBack={() => console.log()} hidden>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button styleType={"submitButton"} callBack={() => setBorrowBookMaxDialog(false)}>
                                <Trans>好</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
            <DialogBox
                styleType={PATH.STYLETYPE_FOUR}
                title="無法預約"
                p1="你的預約書本已達上限，請先取消預約後再嘗試"
                showDialog={reserveBookMaxDialog}
                children={
                    <>
                        {
                            hiddenOnlineRead
                                ? (
                                    <></>
                                )
                                : (
                                    <>
                                        你也可以使用<span style={{ color: "green", display: "contents" }}>線上閱讀</span>功能，即時閱讀，無須預約等候
                                    </>
                                )
                        }
                        <div className={style.dialogButtonContainer}>
                            <Button styleType={"cancelButton"} callBack={() => console.log()} hidden>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button styleType={"submitButton"} callBack={() => setReserveBookMaxDialog(false)}>
                                <Trans>好</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
            <DialogBox
                styleType={PATH.STYLETYPE_FOUR}
                title="是否開啟書本?"
                showDialog={confirmOpenBookDialog}
                children={
                    <div className={style.dialogButtonContainer}>
                        <Button
                            styleType={"cancelButton"}
                            callBack={() => {
                                setConfirmOpenBookDialog(false);
                                localStorage.setItem("openBookUrl", "");
                            }}
                        >
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button
                            styleType={"submitButton"}
                            callBack={() => {
                                setConfirmOpenBookDialog(false);
                                if (
                                    localStorage.getItem("openBookUrl") !== undefined &&
                                    localStorage.getItem("openBookUrl") !== null &&
                                    localStorage.getItem("openBookUrl") !== ""
                                ) {
                                    if (categoryCollectionId) localStorage.removeItem(COLLECTION_ID);
                                    window.open(localStorage.getItem("openBookUrl")!, "_blank");
                                }
                            }}
                        >
                            {/* <Trans>好</Trans> */}
                            確定
                        </Button>
                    </div>
                }
            />

            <DialogBox styleType={PATH.STYLETYPE_SECOND} showDialog={showConfirmDialog} p1={confirmDialogMessage} title={<Trans>SystemMessage</Trans>} scroll={false}>
                <div className={style.dialogButtonContainer2}>
                    <Button
                        styleType={"submitButton"}
                        callBack={() => {
                            localStorage.setItem(PATH.LOGIN_REDIRECT_TXT, window.location.pathname);
                            setShowConfirmDialog(false);
                            if (confirmDialogMessage === "請先登入") {
                                if (
                                    process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
                                    window.location.host.includes("hkpl") ||
                                    process.env.REACT_APP_DOMAIN === "hkpl"
                                ) {
                                    window.parent.parent.location = PATH.HKPL_SSO_PATH;
                                } else {
                                    navigate(PATH.LOGIN_PATH);
                                }
                            }
                        }}
                    >
                        <Trans>Confirm</Trans>
                    </Button>
                </div>
            </DialogBox>

            <DialogBox
                styleType={PATH.STYLETYPE_SECOND}
                showDialog={showBookIsOpenDialog}
                p1={<Trans>isOpenBookMessage</Trans>}
                title={<Trans>SystemMessage</Trans>}
                scroll={false}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"submitButton"} callBack={() => setShowBookIsOpenDialog(false)}>
                        <Trans>Confirm</Trans>
                    </Button>
                </div>
            </DialogBox>
        </>
    );
}

const mapStateToProps = (state: any) => {
    //console.log("taesdfads", state);
    return {
        borrowBookList: state.myBorrowBookList,
        reserveBookList: state.myReserveBookList,
        auth: state.login.auth,
        bookMarkList: state.myBookMarkList,
        userInfo: state.userInfo.userInfo,
        reader: state.reader,
    };
};
const actionCreator = {
    myBorrowBookList,
    handleLogin,
    myReserveBookList,
    myBookMarkList,
    handleUserInfo,
    handleReaderMode,
};
export default connect(mapStateToProps, actionCreator)(BookDetail);
