/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProductPointDto,
    ProductPointDtoFromJSON,
    ProductPointDtoToJSON,
    ProductPointDtoAdmin,
    ProductPointDtoAdminFromJSON,
    ProductPointDtoAdminToJSON,
} from '../models';

export interface AddProductPointUsingPOST1Request {
    productId: number;
    point: number;
    money: number;
    name?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface DeleteProductPointUsingPOST1Request {
    name?: string;
    productPointId?: number;
}

export interface GetAllProductPointAdminUsingGETRequest {
    name?: string;
}

export interface GetProductPointAdminUsingGETRequest {
    productPointId: number;
    name?: string;
}

export interface GetProductPointUsingGETRequest {
    productPointId: number;
}

export interface UpdateProductPointUsingPOSTRequest {
    productPointId: number;
    name?: string;
    productId?: number;
    point?: number;
    money?: number;
    startDate?: Date;
    endDate?: Date;
    status?: string;
}

/**
 * 
 */
export class ProductPointControllerApi extends runtime.BaseAPI {

    /**
     * addProductPoint
     */
    async addProductPointUsingPOST1Raw(requestParameters: AddProductPointUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductPointDtoAdmin>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling addProductPointUsingPOST1.');
        }

        if (requestParameters.point === null || requestParameters.point === undefined) {
            throw new runtime.RequiredError('point','Required parameter requestParameters.point was null or undefined when calling addProductPointUsingPOST1.');
        }

        if (requestParameters.money === null || requestParameters.money === undefined) {
            throw new runtime.RequiredError('money','Required parameter requestParameters.money was null or undefined when calling addProductPointUsingPOST1.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.point !== undefined) {
            queryParameters['point'] = requestParameters.point;
        }

        if (requestParameters.money !== undefined) {
            queryParameters['money'] = requestParameters.money;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPointDtoAdminFromJSON(jsonValue));
    }

    /**
     * addProductPoint
     */
    async addProductPointUsingPOST1(requestParameters: AddProductPointUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductPointDtoAdmin> {
        const response = await this.addProductPointUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOST1Raw(requestParameters: DeleteProductPointUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductPointDtoAdmin>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productPointId !== undefined) {
            queryParameters['productPointId'] = requestParameters.productPointId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPointDtoAdminFromJSON(jsonValue));
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOST1(requestParameters: DeleteProductPointUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductPointDtoAdmin> {
        const response = await this.deleteProductPointUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllProductPointAdmin
     */
    async getAllProductPointAdminUsingGETRaw(requestParameters: GetAllProductPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductPointDtoAdmin>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductPointDtoAdminFromJSON));
    }

    /**
     * getAllProductPointAdmin
     */
    async getAllProductPointAdminUsingGET(requestParameters: GetAllProductPointAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductPointDtoAdmin>> {
        const response = await this.getAllProductPointAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllProductPoint
     */
    async getAllProductPointUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductPointDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductPointDtoFromJSON));
    }

    /**
     * getAllProductPoint
     */
    async getAllProductPointUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductPointDto>> {
        const response = await this.getAllProductPointUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getProductPointAdmin
     */
    async getProductPointAdminUsingGETRaw(requestParameters: GetProductPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductPointDtoAdmin>> {
        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling getProductPointAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/admin/{productPointId}`.replace(`{${"productPointId"}}`, encodeURIComponent(String(requestParameters.productPointId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPointDtoAdminFromJSON(jsonValue));
    }

    /**
     * getProductPointAdmin
     */
    async getProductPointAdminUsingGET(requestParameters: GetProductPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductPointDtoAdmin> {
        const response = await this.getProductPointAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductPoint
     */
    async getProductPointUsingGETRaw(requestParameters: GetProductPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductPointDto>> {
        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling getProductPointUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/{productPointId}`.replace(`{${"productPointId"}}`, encodeURIComponent(String(requestParameters.productPointId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPointDtoFromJSON(jsonValue));
    }

    /**
     * getProductPoint
     */
    async getProductPointUsingGET(requestParameters: GetProductPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductPointDto> {
        const response = await this.getProductPointUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProductPoint
     */
    async updateProductPointUsingPOSTRaw(requestParameters: UpdateProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductPointDtoAdmin>> {
        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling updateProductPointUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productPointId !== undefined) {
            queryParameters['productPointId'] = requestParameters.productPointId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.point !== undefined) {
            queryParameters['point'] = requestParameters.point;
        }

        if (requestParameters.money !== undefined) {
            queryParameters['money'] = requestParameters.money;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/productPoint/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductPointDtoAdminFromJSON(jsonValue));
    }

    /**
     * updateProductPoint
     */
    async updateProductPointUsingPOST(requestParameters: UpdateProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductPointDtoAdmin> {
        const response = await this.updateProductPointUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
