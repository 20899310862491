/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductDtoLite,
    ProductDtoLiteFromJSON,
    ProductDtoLiteFromJSONTyped,
    ProductDtoLiteToJSON,
} from './ProductDtoLite';

/**
 * 
 * @export
 * @interface ProductPointDtoAdmin
 */
export interface ProductPointDtoAdmin {
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    money?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    point?: number;
    /**
     * 
     * @type {ProductDtoLite}
     * @memberof ProductPointDtoAdmin
     */
    product?: ProductDtoLite;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    productPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPointDtoAdmin
     */
    status?: string;
}

export function ProductPointDtoAdminFromJSON(json: any): ProductPointDtoAdmin {
    return ProductPointDtoAdminFromJSONTyped(json, false);
}

export function ProductPointDtoAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPointDtoAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'money': !exists(json, 'money') ? undefined : json['money'],
        'point': !exists(json, 'point') ? undefined : json['point'],
        'product': !exists(json, 'product') ? undefined : ProductDtoLiteFromJSON(json['product']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPointId': !exists(json, 'productPointId') ? undefined : json['productPointId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ProductPointDtoAdminToJSON(value?: ProductPointDtoAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'money': value.money,
        'point': value.point,
        'product': ProductDtoLiteToJSON(value.product),
        'productId': value.productId,
        'productPointId': value.productPointId,
        'status': value.status,
    };
}

