import React from "react";
import "./contentList.css";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import _ from "underscore";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import { handleCurrentChapter } from '../../../../redux/actions/reader'
import { connect } from 'react-redux'

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chapters: [],
      isCollapsed: true,
      currentIndex: -1,
      currentChapter: "",
      isExpandContent: StorageUtil.getReaderConfig("isExpandContent") === "yes",
    };
    this.handleJump = this.handleJump.bind(this);
  }

  componentWillMount() {
    //获取目录
    if (this.props.currentEpub.loaded) {
      this.props.currentEpub.loaded.navigation
        .then((chapters) => {
          this.setState({ chapters: chapters.toc });
        })
        .catch(() => {
          console.log("Error occurs");
        });
    }
  }
  componentDidMount() {
    this.props.htmlBook &&
      this.setState({
        chapters: this.props.htmlBook.chapters,
      });
  }
  handleJump(event) {
    event.preventDefault();
    let href = "";
    try{
      href = event.target.getAttribute("href");
      if(href === undefined){
        href = event.target.href;
      }
    }catch(e){
      href = event.target.href;
    }
   
    
    if (this.props.currentEpub && this.props.currentEpub.loaded) {
      console.log("this.props.currentEpub.spine.items", this.props.currentEpub.spine.items);
      let _href =
        this.props.currentEpub.spine.items.filter(
          
          (item) => decodeURIComponent(item.href).indexOf(href) > -1
          
        )[0].href || href;
      this.props.currentEpub.rendition.display(_href);
    } else {
      let id = href.substr(1);
      let title =
        this.state.chapters[_.findIndex(this.state.chapters, { id })].label;
      RecordLocation.recordHtmlLocation(
        this.props.currentBook.key,
        "test",
        title,
        "test",
        "0"
      );
      this.props.htmlBook.rendition.goToChapter(title);
      this.props.handleCurrentChapter(title);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.htmlBook && nextProps.htmlBook !== this.props.htmlBook) {
      this.setState({ chapters: nextProps.htmlBook.chapters });
    }
  }
  render() {
    const renderContentList = (items, level) => {
      level++;
      return items.map((item, index) => {
        return (
          <li key={index} className="book-content-list">
            {item.subitems.length > 0 &&
              level <= 2 &&
              !this.state.isExpandContent && (
                <span
                  className="icon-dropdown content-dropdown"
                  onClick={() => {
                    this.setState({
                      currentIndex:
                        this.state.currentIndex === index ? -1 : index,
                    });
                  }}
                  style={
                    this.state.currentIndex === index
                      ? {}
                      : { transform: "rotate(-90deg)" }
                  }
                ></span>
              )}

            <a
              href={item.href}
              onClick={this.handleJump}
              className="book-content-name"
            >
              {item.label.indexOf("#") > -1
                ? item.label.split("#")[0]
                : item.label}
            </a>
            {item.subitems.length > 0 &&
              (this.state.currentIndex === index ||
                level > 2 ||
                this.state.isExpandContent) ? (
              <ul>{renderContentList(item.subitems, level)}</ul>
            ) : null}
          </li>
        );
      });
    };

    return (
      <div className="book-content-container">
        <ul className="book-content">
          {this.state.chapters && renderContentList(this.state.chapters, 1)}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    chapters: state.reader.chapters,
    htmlBook: state.reader.htmlBook,
    renderFunc: state.book.renderFunc,
  };
};
const actionCreator = { handleCurrentChapter };
export default connect(mapStateToProps, actionCreator)(ContentList);
