import localforage from "localforage";
import AddTrash from "../../../utils/reader/readUtils/addTrash";

export function handleNotes(notes) {
  return { type: "HANDLE_NOTES", payload: notes };
}
export function handleOriginalText(originalText) {
  return { type: "HANDLE_ORIGINAL_TEXT", payload: originalText };
}
export function handleColor(color) {
  return { type: "HANDLE_COLOR", payload: color };
}
export function handleBookmarks(bookmarks) {
  return { type: "HANDLE_BOOKMARKS", payload: bookmarks };
}
export function handleDigests(digests) {
  return { type: "HANDLE_DIGESTS", payload: digests };
}
export function handleHtmlBook(htmlBook) {
  return { type: "HANDLE_HTML_BOOK", payload: htmlBook };
}
export function handleCurrentChapter(currentChapter) {
  return { type: "HANDLE_CURRENT_CHAPTER", payload: currentChapter };
}
export function handleChapters(chapters) {
  return { type: "HANDLE_CHAPTERS", payload: chapters };
}
export function handleFlattenChapters(flattenChapters) {
  return { type: "HANDLE_FLATTEN_CHAPTERS", payload: flattenChapters };
}
export function handleNoteKey(key) {
  return { type: "HANDLE_NOTE_KEY", payload: key };
}

export function handleReaderMode(mode) {
  return { type: "HANDLE_READER_MODE", payload: mode };
}
export function handleFetchNotes() {
  return (dispatch) => {
    localforage.getItem("notes", (err, value) => {
      //console.log("handleFetchNotes value", value);
      let noteArr;
      if (value === null || value === []) {
        noteArr = [];
      } else {
        noteArr = value;
      }
      let keyArr = AddTrash.getAllTrash();
      //console.log("handleFetchNotes keyArr", keyArr, noteArr);
      dispatch(handleNotes(handleKeyRemove(noteArr, keyArr)));
      dispatch(
        handleDigests(
          handleKeyRemove(
            noteArr.filter((item) => {
              return item.notes === "";
            }),
            keyArr
          )
        )
      );
      //console.log("ffff", noteArr, keyArr);
    });
  };
}
export function flatChapter(chapters) {
  let newChapter = [];
  for (let i = 0; i < chapters.length; i++) {
    if (chapters[i].subitems[0]) {
      newChapter.push(chapters[i]);
      newChapter = newChapter.concat(flatChapter(chapters[i].subitems));
    } else {
      newChapter.push(chapters[i]);
    }
  }
  return newChapter;
}

export function handleFetchChapters(epub) {
  return (dispatch) => {
    epub.loaded.navigation
      .then((chapters) => {
        dispatch(handleChapters(chapters.toc));
        dispatch(handleFlattenChapters(flatChapter(chapters.toc)));
      })
      .catch(() => {
        //console.log("Error occurs");
      });
  };
}
export function handleFetchBookmarks() {
  return (dispatch) => {
    localforage.getItem("bookmarks", (err, value) => {
      let bookmarkArr;
      if (value === null || value === []) {
        bookmarkArr = [];
      } else {
        bookmarkArr = value;
      }
      let keyArr = AddTrash.getAllTrash();
      dispatch(handleBookmarks(handleKeyRemove(bookmarkArr, keyArr)));
    });
  };
}

export function handleReaderLog(log) {
  return { type: "HANDLE_READER_LOG", payload: log };
}

const handleKeyRemove = (items, arr) => {
  let itemArr = [];
  if (!arr[0]) {
    return items;
  }
  for (let i = 0; i < items.length; i++) {
    if (arr.indexOf(items[i].bookKey) === -1) {
      itemArr.push(items[i]);
    }
  }
  return itemArr;
};
