/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import {
    Shipping,
    ShippingFromJSON,
    ShippingFromJSONTyped,
    ShippingToJSON,
} from './Shipping';

/**
 * 
 * @export
 * @interface ShippingProduct
 */
export interface ShippingProduct {
    /**
     * 
     * @type {Product}
     * @memberof ShippingProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    quantity?: number;
    /**
     * 
     * @type {Shipping}
     * @memberof ShippingProduct
     */
    shippingByShippingId?: Shipping;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    shippingId?: number;
}

export function ShippingProductFromJSON(json: any): ShippingProduct {
    return ShippingProductFromJSONTyped(json, false);
}

export function ShippingProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'shippingByShippingId': !exists(json, 'shippingByShippingId') ? undefined : ShippingFromJSON(json['shippingByShippingId']),
        'shippingId': !exists(json, 'shippingId') ? undefined : json['shippingId'],
    };
}

export function ShippingProductToJSON(value?: ShippingProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
        'quantity': value.quantity,
        'shippingByShippingId': ShippingToJSON(value.shippingByShippingId),
        'shippingId': value.shippingId,
    };
}

