import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddMemberFavouriteByMemberIdUsingPUTRequest,
  AdminMemberDto,
  DeleteMemberFavouriteByMemberIdUsingDELETERequest,
  GetMemberFavouritesByMemberIdUsingGETRequest,
  MemberControllerApi,
  MemberDto,
} from "../../fetcher";
import { genApiConfig } from "../../utils/fetch-caller";
import Rating from "../Rating/Rating";
import style from "./BookViewRelated.module.css";
import { Trans, useTranslation } from "react-i18next";

export default function BookViewRelated({ props, bookMarkList }: any) {
  // console.log("myBookMarkList", bookMarkList);
  const navigate = useNavigate();
  const [bookMarked, setBookMarked] = useState(
    bookMarkList.bookMarkList.filter(
      (it: any) => it.productId == props.productId
    ).length > 0
  );

  const redirect = () => {
    navigate(`/product/${props.productId}`);
  };
  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={style.bookLayout}>
        <div className={style.bookImgContainer} onClick={redirect}>
          <img
            style={{ width: "150px", height: "200px" }}
            src={
              process.env.REACT_APP_BACKEND ==
              "https://suepsso.octopus-tech.com"
                ? `${process.env.REACT_APP_BACKEND}/file/${props.coverFileName}`
                : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${props.coverFileName}?x-oss-process=image/resize,h_200`
            }
          />
        </div>
        <div className={style.bookNameLayout}>
          {/* <h5> {props.productNameChi} </h5> */}
          <h5>
            {i18n.language === "cn"
              ? converter(props.productNameChi)
              : props.productNameChi}
          </h5>
        </div>
        {/* <div>
          <Rating rate={props.rating} />
        </div> */}
        {/* <div className={style.butoonGroup}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => handleBorrow()}
            >
              <Trans>Borrow</Trans>
            </Button>
          </div>
          <div>
            <img
              src={bookMarked ? bookmarkon : bookmarkOff}
              onClick={() => handleBookMarked()}
            />
          </div>
        </div> */}
      </div>
    </>
  );
}
