import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import ActivityRecordList from "../../../components/TableListView/ActivityRecordList/ActivityRecordList";
import { AdminMemberDto, GetLogByMemberUsingGETRequest, MemberActionLogControllerApi, MemberDto } from "../../../fetcher";
import { myBorrowBookList } from "../../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../../redux/actions/myReserverBook/myReserveBook";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import * as PATH from "../../../utils/Contants";
import { STYLETYPE_MAIN } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import style from "./ActivityRecord.module.css";
import * as Storage from "../../../utils/local-storage";
function ActivityRecord(props: any) {
    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [actionLog, setActionLog] = useState<any>([]);
    const navigate = useNavigate();
    const [member, setMember] = useState<any>();
    const [adminMember, setAdminMember] = useState<any>();
    const { pathname } = useLocation();

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        let adminUserInfo = JSON.parse(localStorage.getItem("adminMember")!!) as AdminMemberDto;
        let memberUserInfo = JSON.parse(localStorage.getItem("member")!!) as MemberDto;

        if (adminUserInfo !== null && adminUserInfo !== undefined) {
            console.log("a");
            setAdminMember(adminUserInfo.adminMemberId);
        } else if (memberUserInfo !== null && memberUserInfo !== undefined) {
            console.log("b");
            setMember(memberUserInfo.memberId);
        } else {
            console.log("c");
            return;
        }
    }, []);

    useEffect(() => {
        new MemberActionLogControllerApi(genApiConfig())
            .getLogByMemberUsingGET({
                memberId: props.userInfo.userInfo?.member.memberId,
            } as GetLogByMemberUsingGETRequest)
            .then((data) => setActionLog(data.list));
    }, [member, adminMember]);

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>

                <div className={style.pageNav}>
                    <div className={style.ButtonContainer}>
                        <Button
                            styleType={"upperNavbarButton"}
                            callBack={() => (member !== null ? navigate(PATH.STUDENT) : adminMember !== null ? navigate(PATH.ADMIN) : console.log(1))}
                        >
                            平台管理
                        </Button>
                    </div>
                    <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
                    <div className={style.ButtonContainer}>活動記錄</div>
                </div>
            </div>

            <div className={style.RecordContainer}>
                <>
                    <div className={style.container}>
                        <h4>
                            <Trans>ActivityRecord</Trans>
                        </h4>
                        <div className={style.header}>
                            <div className={style.row}>
                                {/* <Input type="text" placeholder={"輸入名稱"} value={searchText} styleType={STYLETYPE_FIFTH} callBack={(value) => setSearchText(value)} />
                                <div className={style.RowBtn}>
                                    <Button styleType={"searchButtom"} callBack={(value) => handleSearchClick(value)}>
                                        搜尋
                                    </Button>
                                </div> */}
                            </div>

                            <div className={style.rowSpan}></div>
                        </div>
                        {/* {loadingData ? (
                        <>
                            <PageLoading />
                        </>
                    ) : displayData.length === 0 ? (
                        <>沒有記錄</>
                    ) : ( */}
                        <div style={{ padding: "8px" }}>
                            <div className={style.tableHeader}>
                                <div className={style.tableHeaderItemCol1}>
                                    <h6>
                                        <Trans>Record</Trans>
                                    </h6>
                                </div>
                                <div></div>
                                <div className={style.tableHeaderItem}>
                                    <h6>
                                        <Trans>Datetime</Trans>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        {/* )} */}

                        <ActivityRecordList actionLog={actionLog}></ActivityRecordList>
                    </div>
                </>
            </div>

            {/* Delete */}
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showDeleteDialog} title={"刪除書本資料"}></DialogBox>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        borrowBookList: state.myBorrowBookList,
        reserveBookList: state.myReserveBookList,
        bookMarkList: state.myBookMarkList,
        userInfo: state.userInfo,
    };
};
const actionCreator = {
    myBorrowBookList,
    myReserveBookList,
    handleUserInfo,
};
export default connect(mapStateToProps, actionCreator)(ActivityRecord);
