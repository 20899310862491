import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import { ADD, AVAILABLE, SELECT } from "../../../utils/Contants";
import style from "./PurchasedItemListView.module.css";
export default function PurchasedItemListView(props: any) {
    return (
        <div className={style.container}>
            <Input type="checkbox" styleType={"checkbox"} checked={props.selectedBook?.find((ele: any) => ele == props.data.productId) ? true : false} callBack={(value) => {
                console.log("tf:", props.selectedBook?.find((ele: any) => ele == props.data.productId) ? true : false)
                console.log("props.data:", props.data)
                props.callBack({
                    action: SELECT,
                    isChecked: props.selectedBook?.find((ele: any) => ele == props.data.productId) ? false : true,
                    book: props.data,
                })
            }} />
            <img
                className={style.img_view}
                src={
                    props.data &&
                    process.env.REACT_APP_BACKEND +
                    "/file/" +
                    props.data.coverFileName
                }
            />
            <h5>{props.data.productNameChi}</h5>
            <div>{props.data?.author}</div>
            <div>ISBN : {props.data?.bookIsbn}</div>
            <div className={style.functionGroup}>
                <Button
                    styleType={"searchButtom"}
                    callBack={() =>
                        props.callBack({
                            action: ADD,
                            book: props.data,
                        })
                    }
                >
                    加入書單
                </Button>
                {props.data.status === 0 ? (
                    <>
                        <Button
                            styleType={"searchButtom"}
                            callBack={() =>
                                props.callBack({
                                    action: AVAILABLE,
                                    book: props.data,
                                })
                            }
                        >
                            上架
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            styleType={"searchButtom"}
                            callBack={() =>
                                props.callBack({
                                    action: AVAILABLE,
                                    book: props.data,
                                })
                            }
                        >
                            下架
                        </Button>
                    </>
                )}
            </div>
        </div >
    )
}