import React from 'react';
import style from './LoadingView.module.css'
export default function LoadingView() {
    return (
        <div className={style.loading}>
            <div className={style.uilRingCss} style={{ transform: 'scale(0.79)' }}>
                <div></div>
            </div>
        </div>
    );
}
