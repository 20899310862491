import { rejects } from "assert";
import { IBookBorrowSetting } from "../model/setting/BorrowSetting";
import * as PATH from "../utils/Contants";
import { getFileExtension } from "../utils/GlobalFunction";
import { httpGet, httpPost } from "./http";

let backendUrl = process.env.REACT_APP_BACKEND;

const api = {
    //login
    checkLogin() {
        return httpGet(`${backendUrl}/login/checkLogin/`);
    },
    //dashboard
    getDashboard() {
        return httpGet(`${backendUrl}/dashboard/`);
    },
    //product
    getProductById(id: string) {
        return httpGet(`${backendUrl}/product/${id}`);
    },
    //organization
    getAllOrganization() {
        return httpGet(`${backendUrl}/organization/all/`);
    },
    //category
    getAllCategories() {
        return httpGet(`${backendUrl}/category/all`);
    },
    getCategoryById(id: string, page: number = 1, size: number = 100) {
        return httpGet(`${backendUrl}/product/category/${id}?page=${page}&size=${size}`);
    },
    //file
    getFileByFileName(fileName: string) {
        return httpGet(`${backendUrl}/file/${fileName}`, {
            "Content-Type": "application/epub+zip",
        });
    },

    getFileByString(fileName: string): Promise<Blob> {
        return new Promise((resolve, rejects) => {
            httpGet(`${backendUrl}/file/fileEncryptionTxt/${fileName}`).then((str: any) => {
                str.text().then((res: any) => {
                    let saltStr = "";
                    let replaceStr: any = fileName.replace(/[^a-zA-Z0-9]/g, "");
                    for (let i = 0; i < replaceStr.length; i++) {
                        Object.defineProperty(String.prototype, "getNumericValue", {
                            value() {
                                return parseInt(this, 36);
                            },
                            writable: true,
                            configurable: true,
                        });

                        saltStr += replaceStr[i].getNumericValue().toString();
                    }

                    if (res !== "") {
                        res = res.replaceAll(saltStr, "");
                    }

                    var sliceSize = 1024;
                    var byteCharacters = atob(res);
                    var bytesLength = byteCharacters.length;
                    var slicesCount = Math.ceil(bytesLength / sliceSize);
                    var byteArrays = new Array(slicesCount);

                    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                        var begin = sliceIndex * sliceSize;
                        var end = Math.min(begin + sliceSize, bytesLength);

                        var bytes = new Array(end - begin);
                        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                            bytes[i] = byteCharacters[offset].charCodeAt(0);
                        }
                        byteArrays[sliceIndex] = new Uint8Array(bytes);
                    }

                    if (getFileExtension(fileName) === PATH.EPUB) {
                        resolve(
                            new Blob(byteArrays, {
                                type: "application/epub+zip",
                            })
                        );
                    }

                    if (getFileExtension(fileName) === PATH.PDF) {
                        resolve(new Blob(byteArrays, { type: "application/pdf" }));
                    }

                    if (getFileExtension(fileName) === PATH.MP3) {
                        resolve(new Blob(byteArrays, { type: `audio/mpeg` }));
                    }

                    if (getFileExtension(fileName) === PATH.MP4) {
                        resolve(new Blob(byteArrays, { type: `audio/mpeg` }));
                    }

                    if (getFileExtension(fileName) === PATH.SRT) {
                        resolve(new Blob(byteArrays, { type: `application/${PATH.SRT}` }));
                    }
                    // resolve(
                    //   new Blob(byteArrays, {
                    //     type: "application/epub+zip",
                    //   })
                    // );
                });
            });
        });
    },

    // getFileByString(fileName: String): Promise<Blob> {
    //   return new Promise((resolve, rejects) => {
    //     httpGet(`${backendUrl}/file/fileEncrypt/${fileName}`).then((str: any) => {
    //       str.text().then((res: any) => {
    //         const today = new Date();
    //         const currentYear = today.getFullYear();
    //         const currentMonth = today.getMonth() + 1;
    //         const currentDay = today.getDate();
    //         const saltStr = (currentYear + currentMonth + currentDay) * TEL;
    //         if (res !== "") {
    //           res = res.replaceAll(saltStr, "");
    //         }
    //         var sliceSize = 1024;
    //         var byteCharacters = atob(res);
    //         var bytesLength = byteCharacters.length;
    //         var slicesCount = Math.ceil(bytesLength / sliceSize);
    //         var byteArrays = new Array(slicesCount);

    //         for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    //           var begin = sliceIndex * sliceSize;
    //           var end = Math.min(begin + sliceSize, bytesLength);

    //           var bytes = new Array(end - begin);
    //           for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
    //             bytes[i] = byteCharacters[offset].charCodeAt(0);
    //           }
    //           byteArrays[sliceIndex] = new Uint8Array(bytes);
    //         }
    //         resolve(new Blob(byteArrays, { type: "application/pdf" }));
    //       });
    //     });
    //   });
    // },

    getFileByStringWithType(fileName: String, type: String): Promise<Blob> {
        return new Promise((resolve, rejects) => {
            httpGet(`${backendUrl}/file/fileEncrypt/${fileName}`).then((str: any) => {
                str.text().then((res: any) => {
                    const today = new Date();
                    const currentYear = today.getFullYear();
                    const currentMonth = today.getMonth() + 1;
                    const currentDay = today.getDate();
                    const saltStr = (currentYear + currentMonth + currentDay) * PATH.TEL;
                    if (res !== "") {
                        res = res.replaceAll(saltStr, "");
                    }
                    var sliceSize = 1024;
                    var byteCharacters = atob(res);
                    var bytesLength = byteCharacters.length;
                    var slicesCount = Math.ceil(bytesLength / sliceSize);
                    var byteArrays = new Array(slicesCount);

                    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                        var begin = sliceIndex * sliceSize;
                        var end = Math.min(begin + sliceSize, bytesLength);

                        var bytes = new Array(end - begin);
                        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                            bytes[i] = byteCharacters[offset].charCodeAt(0);
                        }
                        byteArrays[sliceIndex] = new Uint8Array(bytes);
                    }
                    resolve(new Blob(byteArrays, { type: `application/${type}` }));
                });
            });
        });
    },

    getFileByStringMp3(fileName: String): Promise<Blob> {
        return new Promise((resolve, rejects) => {
            httpGet(`${backendUrl}/file/fileEncrypt/${fileName}`).then((str: any) => {
                str.text().then((res: any) => {
                    const today = new Date();
                    const currentYear = today.getFullYear();
                    const currentMonth = today.getMonth() + 1;
                    const currentDay = today.getDate();
                    const saltStr = (currentYear + currentMonth + currentDay) * PATH.TEL;
                    if (res !== "") {
                        res = res.replaceAll(saltStr, "");
                    }
                    var sliceSize = 1024;
                    var byteCharacters = atob(res);
                    var bytesLength = byteCharacters.length;
                    var slicesCount = Math.ceil(bytesLength / sliceSize);
                    var byteArrays = new Array(slicesCount);

                    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                        var begin = sliceIndex * sliceSize;
                        var end = Math.min(begin + sliceSize, bytesLength);

                        var bytes = new Array(end - begin);
                        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                            bytes[i] = byteCharacters[offset].charCodeAt(0);
                        }
                        byteArrays[sliceIndex] = new Uint8Array(bytes);
                    }
                    resolve(new Blob(byteArrays, { type: `audio/mpeg` }));
                });
            });
        });
    },
    //search
    getSearchResult(page: number = 1, size: number = 100, obj: any) {
        let params = [];
        // console.log('obj', Object.keys(obj))
        const { keyword, year, organizationId, categoryId } = obj;
        // console.log('year',year)
        // console.log('publisher',publisher)
        if (page) params.push("page=" + page);
        if (size) params.push("size=" + size);
        if (keyword) {
            params.push("keyword=" + keyword);
        }
        if (year && year.length > 0) {
            params.push("year=" + year);
        }
        if (organizationId && organizationId.length > 0) {
            // console.log(publisher);
            params.push("organizationId=" + organizationId);
        }
        if (categoryId && categoryId.length > 0) {
            params.push("categoryId=" + categoryId);
        }
        let paramStr = params.join("&");
        // console.log('paramStr',paramStr)
        return httpGet(`${backendUrl}/product/search?${paramStr}`);
    },
    //searchExtra
    getSearchResultExtra(page: number = 1, size: number = 100, obj: any) {
        let params = [];
        console.log("obj", Object.keys(obj));
        const { keyword, inputOption, fromYear, toYear, organizationId, categoryId } = obj;
        // console.log('year',year)
        // console.log('publisher',publisher)
        if (page) params.push("page=" + page);
        if (size) params.push("size=" + size);
        if (keyword && inputOption) {
            if (inputOption === "all") {
                params.push("bookName=" + keyword);
                params.push("author=" + keyword);
                params.push("bookDescription=" + keyword);
            } else {
                params.push(inputOption + "=" + keyword);
            }
        }
        if (fromYear && fromYear.length > 0) {
            params.push("fromYear=" + fromYear);
        }
        if (toYear && toYear.length > 0) {
            params.push("toYear=" + toYear);
        }
        if (organizationId && organizationId.length > 0) {
            // console.log(publisher);
            params.push("organizationId=" + organizationId);
        }
        if (categoryId && categoryId.length > 0) {
            params.push("categoryId=" + categoryId);
        }
        let paramStr = params.join("&");
        // console.log('paramStr',paramStr)
        return httpGet(`${backendUrl}/product/searchExtra?${paramStr}`);
    },
    //borrowBook
    postBorrowBook(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/borrowBook?memberId=${memberId}&productId=${productId}`);
    },
    //canResumeBorrow
    postCanResumeBorrow(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/canResumeBorrow?memberId=${memberId}&productId=${productId}`);
    },
    //listAllMyBorrow
    postListAllMyBorrow(memberId: string, pageNo: number = 1, pageSize: number = 10) {
        return httpPost(`${backendUrl}/borrow/listAllMyBorrowBook?memberId=${memberId}&pageNo=${pageNo}&pageSize=${pageSize}`);
    },
    //listAllMyReserveBook
    postListAllReserveBook(memberId: string) {
        return httpPost(`${backendUrl}/borrow/listAllMyReserveBook?memberId=${memberId}`);
    },
    //listMyBorrow
    postListMyBorrow(memberId: string) {
        return httpPost(`${backendUrl}/borrow/listMyBorrowBook?memberId=${memberId}`);
    },
    //productBorrowMyStatus
    postProductBorrowMyStatus(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/productBorrowMyStatus?productId=${productId}&memberId=${memberId}`);
    },
    //productBorrowStatus
    postProductBorrowStatus(productId: string) {
        return httpPost(`${backendUrl}/borrow/productBorrowStatus?productId=${productId}`);
    },
    //reserveBook
    postReseveBook(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/reserveBook?memberId=${memberId}&productId=${productId}`);
    },
    //resumeBorrow
    postReseveBorrow(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/borrow/resumeBorrowBook?memberId=${memberId}&productId=${productId}`);
    },
    //returnBook
    postReutnBook(memberId: string, productId: string) {
        return httpPost(`${backendUrl}/borrow/returnBook?memberId=${memberId}&productId=${productId}`);
    },
    //sso
    getTokenBySsoEmail(email: string) {
        return httpGet(`${backendUrl}/member/getTokenBySsoEmail?email=${email}`);
    },
    //bookMark
    getAllBookMarked() {
        return httpGet(`${backendUrl}/`); //TODO
    },
    //insertProductComment
    postCommentMessage(memberId: string, productId: string, body: any) {
        return httpPost(`${backendUrl}/product-comment/insert?memberId=${memberId}&productId=${productId}`, body);
    },
    //setting
    setBorrowSetting(value: IBookBorrowSetting) {
        return httpPost(`${backendUrl}/systemSetting/update/book_borrow_setting`, value);
    },
    getBorrowSetting() {
        return httpGet(`${backendUrl}/systemSetting/get/book_borrow_setting`);
    },

    getTokenByCode(code: string, state: string | null): boolean {
        let flag = false;
        console.log("here?");

        const grantType = "authorization_code";
        const param = `grant_type=${grantType}&code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
        const url = `${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/token?${param}`;
        const headers = {
            Authorization: `Basic ${btoa(`${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`)}`,
        };

        fetch(url, {
            method: "POST",
            headers: headers,
        })
            .then(async (res) => {
                flag = true;
                console.log(res);
            })
            .catch((err) => console.log(err));
        return flag;
    },
  getImageUrl(fileName: string, options: string = ""): string {
    if(
      `${process.env.REACT_APP_BACKEND}`.includes("octopus-tech") ||
      `${process.env.REACT_APP_BACKEND}`.includes("localhost")){
      return `${process.env.REACT_APP_BACKEND}/file/${fileName}`
    } else {
      return `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${fileName}${options === "" ? "" : `?${options}`}`
    }
  }
};

export default api;
