/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import {
    MarketingEvent,
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEventArticle
 */
export interface MarketingEventArticle {
    /**
     * 
     * @type {Article}
     * @memberof MarketingEventArticle
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventArticle
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventArticle
     */
    content?: string;
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventArticle
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventArticle
     */
    marketingEventId?: number;
}

export function MarketingEventArticleFromJSON(json: any): MarketingEventArticle {
    return MarketingEventArticleFromJSONTyped(json, false);
}

export function MarketingEventArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleByArticleId': !exists(json, 'articleByArticleId') ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'marketingEventByMarketingEventId': !exists(json, 'marketingEventByMarketingEventId') ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
    };
}

export function MarketingEventArticleToJSON(value?: MarketingEventArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value.articleByArticleId),
        'articleId': value.articleId,
        'content': value.content,
        'marketingEventByMarketingEventId': MarketingEventToJSON(value.marketingEventByMarketingEventId),
        'marketingEventId': value.marketingEventId,
    };
}

