import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BookView from "../../components/BookView/BookView";
import style from "./HonrizontalList.module.css";

export default function HonrizontalList({ props }: any) {
  const { i18n } = useTranslation();
  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });
  return (
    <>
      {props.category.isRecommend && props.products.length > 0 ? (
        <div className={style.honrizontalList}>
          <div className={style.honrizontalListHeaderLine}></div>
          <div className={style.header}>
            <h4>
              {i18n.language === "cn"
                ? converter(props.category.categoryName)
                : props.category.categoryName}
            </h4>
            <div className={style.moreContainer}>
              <Link
                to={
                  "category/" +
                  props.category.categoryId +
                  "&" +
                  props.category.categoryName.toString()
                }
              >
                <Trans>MoreBooks</Trans>
              </Link>
            </div>
          </div>
          <div className={style.scrollingWrapper}>
            {props.products.length > 0 &&
              props.products.map((book: any, idx: number) => (
                <BookView
                  props={book}
                  key={idx}
                  recommendations={props.category.categoryName}
                />
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

// export default withRouter(HonrizontalList)
