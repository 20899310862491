/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingEventMemberLevel,
    MarketingEventMemberLevelFromJSON,
    MarketingEventMemberLevelFromJSONTyped,
    MarketingEventMemberLevelToJSON,
} from './MarketingEventMemberLevel';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    MemberLevelDiscount,
    MemberLevelDiscountFromJSON,
    MemberLevelDiscountFromJSONTyped,
    MemberLevelDiscountToJSON,
} from './MemberLevelDiscount';
import {
    MembershipRule,
    MembershipRuleFromJSON,
    MembershipRuleFromJSONTyped,
    MembershipRuleToJSON,
} from './MembershipRule';
import {
    PointRule,
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';

/**
 * 
 * @export
 * @interface MemberLevel
 */
export interface MemberLevel {
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    isOverride?: string;
    /**
     * 
     * @type {Array<MarketingEventMemberLevel>}
     * @memberof MemberLevel
     */
    marketingEventMemberLevelsByMemberLevelId?: Array<MarketingEventMemberLevel>;
    /**
     * 
     * @type {Array<MemberLevelDiscount>}
     * @memberof MemberLevel
     */
    memberLevelDiscountsByMemberLevelId?: Array<MemberLevelDiscount>;
    /**
     * 
     * @type {number}
     * @memberof MemberLevel
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<Member>}
     * @memberof MemberLevel
     */
    membersByMemberLevelId?: Array<Member>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {Array<MembershipRule>}
     * @memberof MemberLevel
     */
    membershipRulesByMemberLevelId?: Array<MembershipRule>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    order?: string;
    /**
     * 
     * @type {Array<PointRule>}
     * @memberof MemberLevel
     */
    pointRulesByMemberLevelId?: Array<PointRule>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    role?: string;
}

export function MemberLevelFromJSON(json: any): MemberLevel {
    return MemberLevelFromJSONTyped(json, false);
}

export function MemberLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isOverride': !exists(json, 'isOverride') ? undefined : json['isOverride'],
        'marketingEventMemberLevelsByMemberLevelId': !exists(json, 'marketingEventMemberLevelsByMemberLevelId') ? undefined : ((json['marketingEventMemberLevelsByMemberLevelId'] as Array<any>).map(MarketingEventMemberLevelFromJSON)),
        'memberLevelDiscountsByMemberLevelId': !exists(json, 'memberLevelDiscountsByMemberLevelId') ? undefined : ((json['memberLevelDiscountsByMemberLevelId'] as Array<any>).map(MemberLevelDiscountFromJSON)),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'membersByMemberLevelId': !exists(json, 'membersByMemberLevelId') ? undefined : ((json['membersByMemberLevelId'] as Array<any>).map(MemberFromJSON)),
        'membershipName': !exists(json, 'membershipName') ? undefined : json['membershipName'],
        'membershipPeriod': !exists(json, 'membershipPeriod') ? undefined : json['membershipPeriod'],
        'membershipRulesByMemberLevelId': !exists(json, 'membershipRulesByMemberLevelId') ? undefined : ((json['membershipRulesByMemberLevelId'] as Array<any>).map(MembershipRuleFromJSON)),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'pointRulesByMemberLevelId': !exists(json, 'pointRulesByMemberLevelId') ? undefined : ((json['pointRulesByMemberLevelId'] as Array<any>).map(PointRuleFromJSON)),
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function MemberLevelToJSON(value?: MemberLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isOverride': value.isOverride,
        'marketingEventMemberLevelsByMemberLevelId': value.marketingEventMemberLevelsByMemberLevelId === undefined ? undefined : ((value.marketingEventMemberLevelsByMemberLevelId as Array<any>).map(MarketingEventMemberLevelToJSON)),
        'memberLevelDiscountsByMemberLevelId': value.memberLevelDiscountsByMemberLevelId === undefined ? undefined : ((value.memberLevelDiscountsByMemberLevelId as Array<any>).map(MemberLevelDiscountToJSON)),
        'memberLevelId': value.memberLevelId,
        'membersByMemberLevelId': value.membersByMemberLevelId === undefined ? undefined : ((value.membersByMemberLevelId as Array<any>).map(MemberToJSON)),
        'membershipName': value.membershipName,
        'membershipPeriod': value.membershipPeriod,
        'membershipRulesByMemberLevelId': value.membershipRulesByMemberLevelId === undefined ? undefined : ((value.membershipRulesByMemberLevelId as Array<any>).map(MembershipRuleToJSON)),
        'order': value.order,
        'pointRulesByMemberLevelId': value.pointRulesByMemberLevelId === undefined ? undefined : ((value.pointRulesByMemberLevelId as Array<any>).map(PointRuleToJSON)),
        'role': value.role,
    };
}

