// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PurchasedItemListView_container__QmQYO {\n    width: 100%;\n    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);\n    transition: 0.3s;\n    border-radius: 8px;\n    margin-bottom: 1rem;\n    padding: 1rem 0px;\n    display: grid;\n    grid-template-columns: 1fr 3fr 7fr 7fr 7fr 10fr;\n    align-items: center;\n    justify-items: center;\n    grid-gap: 8px;\n}\n\n.PurchasedItemListView_img_view__ooavu {\n    max-height: 130px;\n    width: 100%;\n    object-fit: contain;\n    object-position: center;\n}\n\n.PurchasedItemListView_functionGroup__CT7hm {\n    display: flex;\n    width: 75%;\n}\n", "",{"version":3,"sources":["webpack://./src/layout/Admin/PlatformManagement/PurchasedItemListView.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wCAAwC;IACxC,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,+CAA+C;IAC/C,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;AACd","sourcesContent":[".container {\n    width: 100%;\n    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);\n    transition: 0.3s;\n    border-radius: 8px;\n    margin-bottom: 1rem;\n    padding: 1rem 0px;\n    display: grid;\n    grid-template-columns: 1fr 3fr 7fr 7fr 7fr 10fr;\n    align-items: center;\n    justify-items: center;\n    grid-gap: 8px;\n}\n\n.img_view {\n    max-height: 130px;\n    width: 100%;\n    object-fit: contain;\n    object-position: center;\n}\n\n.functionGroup {\n    display: flex;\n    width: 75%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PurchasedItemListView_container__QmQYO",
	"img_view": "PurchasedItemListView_img_view__ooavu",
	"functionGroup": "PurchasedItemListView_functionGroup__CT7hm"
};
export default ___CSS_LOADER_EXPORT___;
