import { useEffect, useState } from "react"
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { SuccessResponseModel } from "../../../fetcher";
import { CategoryConflictControllerApi } from "../../../fetcher/apis/CategoryConflictControllerApi";
import { CategoryNameConflictPayload } from "../../../fetcher/models/CategoryNameConflictPayload";
import { CategoryNameConflictPayloadList } from "../../../fetcher/models/CategoryNameConflictPayloadList";
import { CategoryParentConflictPayload } from "../../../fetcher/models/CategoryParentConflictPayload";
import { CategoryParentConflictPayloadList } from "../../../fetcher/models/CategoryParentConflictPayloadList";
import { CategoryParentConflictSubResponse } from "../../../fetcher/models/CategoryParentConflictSubResponse";
import { CANCEL, SAVE } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import style from "./CategoryConflicts.module.css";

export default function CategoryConflicts({ data, dialogCallback, conflictType }: any) {

    const [categoryConflictsValue, setCategoryConflictsValue] = useState([])

    useEffect(() => {
        console.log("data", data)
        if (conflictType === "parent") {
            const parentData = data.map((value: any) => {
                if (value.cmsParentCategory === undefined) {
                    let tempCategory = value.category;
                    let tempCMS: any = {
                        categoryId: null,
                        mongoId: null,
                        name: null
                    };
                    let tempLib = value.libParentCategory;
                    return { category: tempCategory, cmsParentCategory: tempCMS, libParentCategory: tempLib }
                } else if (value.libParentCategory === undefined) {
                    let tempCategory = value.category;
                    let tempCMS = value.cmsParentCategory;
                    let tempLib: any = {
                        categoryId: null,
                        mongoId: null,
                        name: null
                    };
                    return { category: tempCategory, cmsParentCategory: tempCMS, libParentCategory: tempLib }
                } else {
                    return { ...value }
                }
            })
            console.log("parentData", parentData)
            data = parentData;
        }
        setCategoryConflictsValue(data.map((value: any) => ({ ...value, isSelected: "" })));
    }, [data])

    const selectItem = (index: number, data: string) => {
        var list: any = categoryConflictsValue.map((value: any, idx: number) => {
            if (idx === index) {
                return { ...value, isSelected: data };
            }
            return value
        });
        setCategoryConflictsValue(list)
    }

    const submit = () => {
        if (conflictType === "name") {
            var categoryNameConflictPayloadList: Array<CategoryNameConflictPayload> = [];
            categoryConflictsValue.map((value: any) => {
                if (value.isSelected === value.libName || value.isSelected === value.cmsName) {
                    let categoryNameConflictPayload: CategoryNameConflictPayload = { mongoId: value.mongoId, name: value.isSelected }
                    categoryNameConflictPayloadList.push(categoryNameConflictPayload)
                }
            })
            console.log(JSON.stringify({ categoryNameConflictPayloadList: categoryNameConflictPayloadList }))
            new CategoryConflictControllerApi(genApiConfig())
                .selectCategoryNameUsingPOST({
                    categoryNameConflictPayloadList: { categoryNameConflictPayloadList } as CategoryNameConflictPayloadList
                })
                .then((res: SuccessResponseModel) => {
                    console.log("selectCategoryNameUsingPOST", res)
                })
                .catch((err) => {
                    console.log("err", err)
                })
        } else if (conflictType === "parent") {
            var categoryParentConflictPayloadList: Array<CategoryParentConflictPayload> = [];
            // var categoryParentConflicts: { catParentConflictId: string; selectCatParentId: string; }[] = [];
            categoryConflictsValue.map((value: any) => {
                if (value.isSelected === value.cmsParentCategory.mongoId || value.isSelected === value.libParentCategory.mongoId) {
                    categoryParentConflictPayloadList.push({ mongoId: value.category.mongoId, parentMongoId: value.isSelected })
                }
            })
            console.log(JSON.stringify({ categoryParentConflictPayloadList: categoryParentConflictPayloadList }))
            new CategoryConflictControllerApi(genApiConfig())
                .selectCategoryParentUsingPOST({
                    categoryParentConflictPayloadList: { categoryParentConflictPayloadList } as CategoryParentConflictPayloadList
                })
                .then((res: SuccessResponseModel) => {
                    console.log("selectCategoryParentUsingPOST", res)
                })
                .catch((err) => {
                    console.log("err", err)
                })
        }
    }

    return (
        <div className={style.main}>
            <div className={style.list}>
                {conflictType === "name" ? (

                    categoryConflictsValue.map((value: any, index: number) =>
                        <div className={style.conflictCard}>
                            <Link
                                to={
                                    "/category/" +
                                    value.categoryId +
                                    "&" +
                                    value.libName
                                }
                                target="_blank"
                            >
                                <div className={[style.conflictCardTitle, value.isSelected === value.libName || value.isSelected === value.cmsName ? style.isSelectedTitle : ""].join(" ")}>
                                    <h2>
                                        {value.isSelected === value.libName || value.isSelected === value.cmsName ? value.isSelected : value.libName}
                                    </h2>
                                </div>
                            </Link>
                            <div
                                className={[style.conflictItemCard, value.libName === value.isSelected ? style.isSelected : ""].join(" ")}
                                onClick={() => value.libName === value.isSelected ? selectItem(index, "") : selectItem(index, value.libName)}
                            >
                                <span>現時名稱：<span style={{ "fontWeight": "bold" }}>{value.libName}</span></span>
                            </div>
                            <div
                                className={[style.conflictItemCard, value.cmsName === value.isSelected ? style.isSelected : ""].join(" ")}
                                onClick={() => value.cmsName === value.isSelected ? selectItem(index, "") : selectItem(index, value.cmsName)}
                            >
                                <span>Suep名稱：<span style={{ "fontWeight": "bold" }}>{value.cmsName}</span></span>
                            </div>
                        </div>
                    )
                ) : conflictType === "parent" ? (

                    categoryConflictsValue.map((value: any, index: number) =>
                        <div className={style.conflictCard}>

                            <div className={
                                [style.conflictCardTitle, value.isSelected === value.cmsParentCategory.mongoId || value.isSelected === value.libParentCategory.mongoId ? style.isSelectedTitle : ""].join(" ")
                            }>
                                <Link
                                    to={
                                        "/category/" +
                                        value.category.categoryId +
                                        "&" +
                                        value.category.name
                                    }
                                    target="_blank"
                                >
                                    <h2>
                                        {value.category.name}
                                    </h2>
                                </Link>
                            </div>
                            <div
                                className={
                                    [style.conflictItemCard, value.cmsParentCategory.mongoId === value.isSelected ? style.isSelected : ""].join(" ")
                                }
                                onClick={() => value.cmsParentCategory.mongoId === value.isSelected ? selectItem(index, "") : selectItem(index, value.cmsParentCategory.mongoId)}
                            >
                                <span>現時主分類：<span style={{ "fontWeight": "bold" }}>{value.cmsParentCategory.name === null ? "無" : value.cmsParentCategory.name}</span></span>
                                {value.cmsParentCategory.categoryId === null ? <></> :
                                    <Link
                                        to={
                                            "/category/" +
                                            value.cmsParentCategory.categoryId +
                                            "&" +
                                            value.cmsParentCategory.name
                                        }
                                        target="_blank"
                                    >
                                        <span style={{ "marginLeft": "auto", "color": "blue" }}>前往</span>
                                    </Link>
                                }
                            </div>
                            <div
                                className={
                                    [style.conflictItemCard, value.libParentCategory.mongoId === value.isSelected ? style.isSelected : ""].join(" ")
                                }
                                onClick={() => value.libParentCategory.mongoId === value.isSelected ? selectItem(index, "") : selectItem(index, value.libParentCategory.mongoId)}
                            >
                                <span>Suep主分類：<span style={{ "fontWeight": "bold" }}>{value.libParentCategory.name === null ? "無" : value.libParentCategory.name}</span></span>

                                {value.libParentCategory.categoryId === null ? <></> :
                                    <Link
                                        to={
                                            "/category/" +
                                            value.libParentCategory.categoryId +
                                            "&" +
                                            value.libParentCategory.name
                                        }
                                        target="_blank"
                                    >
                                        <span style={{ "marginLeft": "auto", "color": "blue" }}>前往</span>
                                    </Link>
                                }

                            </div>
                        </div>
                    )
                ) : <></>}

            </div>
            <div className={style.dialogButtonContainer}>
                <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => {
                        if (window.confirm("所有更改將不會儲存，是否放棄更改？")) {
                            dialogCallback({ action: CANCEL, conflictType: conflictType })
                        }
                    }}
                >
                    <Trans>Cancel</Trans>
                </Button>
                &nbsp;&nbsp;
                <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => {
                        if (window.confirm("所有更改將無法回復，是否儲存更改？")) {
                            submit();
                            dialogCallback({ action: SAVE, conflictType: conflictType })
                        }
                    }}
                >
                    儲存
                </Button>
            </div>
        </div >
    )
}