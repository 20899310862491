/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface UserPointHistory
 */
export interface UserPointHistory {
    /**
     * 
     * @type {Member}
     * @memberof UserPointHistory
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    pointHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPointHistory
     */
    pointType?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    pointValue?: number;
}

export function UserPointHistoryFromJSON(json: any): UserPointHistory {
    return UserPointHistoryFromJSONTyped(json, false);
}

export function UserPointHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPointHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'pointHistoryId': !exists(json, 'pointHistoryId') ? undefined : json['pointHistoryId'],
        'pointType': !exists(json, 'pointType') ? undefined : json['pointType'],
        'pointValue': !exists(json, 'pointValue') ? undefined : json['pointValue'],
    };
}

export function UserPointHistoryToJSON(value?: UserPointHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'pointHistoryId': value.pointHistoryId,
        'pointType': value.pointType,
        'pointValue': value.pointValue,
    };
}

