import {ACCESS_TOKEN, REFRESH_TOKEN, USER_INFO} from "../../utils/Contants";

// const initState = {
//   userInfo: { ...JSON.parse(localStorage.getItem(USER_INFO)!!) },
// };
export function userInfo(state: any = {}, action: any) {
  try {
    let userInfo = localStorage.getItem(USER_INFO);
    if (userInfo === null) {
      state = { userInfo: {} }
    } else {
      if (userInfo.length <= 0) {
        localStorage.removeItem(USER_INFO)
        localStorage.removeItem(ACCESS_TOKEN)
        localStorage.removeItem(REFRESH_TOKEN)
        state = { userInfo: {} }
      } else {
        let userInfoJson = JSON.parse(userInfo)
        if (userInfoJson.isAdmin === undefined || userInfoJson.isAdmin === null || userInfoJson.member === undefined || userInfoJson.member === null) {
          localStorage.removeItem(USER_INFO)
          localStorage.removeItem(ACCESS_TOKEN)
          localStorage.removeItem(REFRESH_TOKEN)
          state = { userInfo: {} }
        } else if (userInfoJson.isAdmin) {
          if (userInfoJson.member.adminMemberId === undefined || userInfoJson.member.adminMemberId === null) {
            localStorage.removeItem(USER_INFO)
            localStorage.removeItem(ACCESS_TOKEN)
            localStorage.removeItem(REFRESH_TOKEN)
            state = { userInfo: {} }
          } else {
            state = { userInfo: userInfoJson }
          }
        } else {
          if (userInfoJson.member.memberId === undefined || userInfoJson.member.memberId === null) {
            localStorage.removeItem(USER_INFO)
            localStorage.removeItem(ACCESS_TOKEN)
            localStorage.removeItem(REFRESH_TOKEN)
            state = { userInfo: {} }
          } else {
            state = { userInfo: userInfoJson }
          }
        }
      }
    }
  } catch {
    localStorage.removeItem(USER_INFO)
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    state = { userInfo: {} }
  }
  switch (action.type) {
    case "HANDLE_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
