import localforage from "localforage";
import AddTrash from "../../../utils/reader/readUtils/addTrash";
import SortUtil from "../../../utils/reader/readUtils/sortUtil";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";

export function handleBooks(books) {
  return { type: "HANDLE_BOOKS", payload: books };
}
export function handleDeletedBooks(deletedBooks) {
  return { type: "HANDLE_DELETED_BOOKS", payload: deletedBooks };
}
export function handleSearchResults(searchResults) {
  return { type: "HANDLE_SEARCH_BOOKS", payload: searchResults };
}
export function handleSearch(isSearch) {
  return { type: "HANDLE_SEARCH", payload: isSearch };
}
export function handleTipDialog(isTipDialog) {
  return { type: "HANDLE_TIP_DIALOG", payload: isTipDialog };
}
export function handleTip(tip) {
  return { type: "HANDLE_TIP", payload: tip };
}
export function handleSetting(isSettingOpen) {
  return { type: "HANDLE_SETTING", payload: isSettingOpen };
}
export function handleAbout(isAboutOpen) {
  return { type: "HANDLE_ABOUT", payload: isAboutOpen };
}
export function handleViewMode(mode) {
  return { type: "HANDLE_VIEW_MODE", payload: mode };
}

export function handleSortDisplay(isSortDisplay) {
  return { type: "HANDLE_SORT_DISPLAY", payload: isSortDisplay };
}
export function handleLoadingDialog(isShowLoading) {
  return { type: "HANDLE_SHOW_LOADING", payload: isShowLoading };
}
export function handleNewDialog(isShowNew) {
  return { type: "HANDLE_SHOW_NEW", payload: isShowNew };
}
export function handleSelectBook(isSelectBook) {
  return { type: "HANDLE_SELECT_BOOK", payload: isSelectBook };
}
export function handleSelectedBooks(selectedBooks) {
  return { type: "HANDLE_SELECTED_BOOKS", payload: selectedBooks };
}
export function handleNewWarning(isNewWarning) {
  return { type: "HANDLE_NEW_WARNING", payload: isNewWarning };
}
export function handleBookSort(isBookSort) {
  return { type: "HANDLE_BOOK_SORT", payload: isBookSort };
}
export function handleNoteSort(isNoteSort) {
  return { type: "HANDLE_NOTE_SORT", payload: isNoteSort };
}
export function handleBookSortCode(bookSortCode) {
  return { type: "HANDLE_SORT_CODE", payload: bookSortCode };
}

export function handleNoteSortCode(noteSortCode) {
  return { type: "HANDLE_NOTE_SORT_CODE", payload: noteSortCode };
}

export function handleFetchBooks(isTrash = false) {
  return (dispatch) => {
    localforage.getItem("books", (err, value) => {
      let bookArr = value;
      let keyArr = AddTrash.getAllTrash();
      if (isTrash) {
        dispatch(handleDeletedBooks(handleKeyFilter(bookArr, keyArr)));
      } else {
        dispatch(handleBooks(handleKeyRemove(bookArr, keyArr)));
      }
    });
  };
}
export function handleFetchBookSortCode() {
  return (dispatch) => {
    let bookSortCode = SortUtil.getBookSortCode();
    dispatch(handleBookSortCode(bookSortCode));
  };
}
export function handleFetchNoteSortCode() {
  return (dispatch) => {
    let noteSortCode = SortUtil.getNoteSortCode();
    dispatch(handleNoteSortCode(noteSortCode));
  };
}
export function handleFetchList() {
  return (dispatch) => {
    let viewMode = StorageUtil.getReaderConfig("viewMode") || "card";
    dispatch(handleViewMode(viewMode));
  };
}
const handleKeyRemove = (items, arr) => {
  if (!items) return [];
  let itemArr = [];
  if (!arr[0]) {
    return items;
  }
  for (let item of items) {
    if (arr.indexOf(item.key) === -1) {
      itemArr.push(item);
    }
  }

  return itemArr;
};
const handleKeyFilter = (items, arr) => {
  if (!items) {
    return [];
  }
  let itemArr = [];
  for (let item of items) {
    if (arr.indexOf(item.key) > -1) {
      itemArr.push(item);
    }
  }
  return itemArr;
};
