/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoFromJSONTyped,
    BorrowDtoToJSON,
} from './BorrowDto';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';
import {
    ReserveBorrowDto,
    ReserveBorrowDtoFromJSON,
    ReserveBorrowDtoFromJSONTyped,
    ReserveBorrowDtoToJSON,
} from './ReserveBorrowDto';

/**
 * 
 * @export
 * @interface MemberActionLogDataDto
 */
export interface MemberActionLogDataDto {
    /**
     * 
     * @type {BorrowDto}
     * @memberof MemberActionLogDataDto
     */
    borrow?: BorrowDto;
    /**
     * 
     * @type {ProductDto}
     * @memberof MemberActionLogDataDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {ReserveBorrowDto}
     * @memberof MemberActionLogDataDto
     */
    reserveBorrow?: ReserveBorrowDto;
}

export function MemberActionLogDataDtoFromJSON(json: any): MemberActionLogDataDto {
    return MemberActionLogDataDtoFromJSONTyped(json, false);
}

export function MemberActionLogDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberActionLogDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrow': !exists(json, 'borrow') ? undefined : BorrowDtoFromJSON(json['borrow']),
        'product': !exists(json, 'product') ? undefined : ProductDtoFromJSON(json['product']),
        'reserveBorrow': !exists(json, 'reserveBorrow') ? undefined : ReserveBorrowDtoFromJSON(json['reserveBorrow']),
    };
}

export function MemberActionLogDataDtoToJSON(value?: MemberActionLogDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrow': BorrowDtoToJSON(value.borrow),
        'product': ProductDtoToJSON(value.product),
        'reserveBorrow': ReserveBorrowDtoToJSON(value.reserveBorrow),
    };
}

