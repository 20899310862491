/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryNameConflictPayload,
    CategoryNameConflictPayloadFromJSON,
    CategoryNameConflictPayloadFromJSONTyped,
    CategoryNameConflictPayloadToJSON,
} from './CategoryNameConflictPayload';

/**
 * 
 * @export
 * @interface CategoryNameConflictPayloadList
 */
export interface CategoryNameConflictPayloadList {
    /**
     * 
     * @type {Array<CategoryNameConflictPayload>}
     * @memberof CategoryNameConflictPayloadList
     */
    categoryNameConflictPayloadList?: Array<CategoryNameConflictPayload>;
}

export function CategoryNameConflictPayloadListFromJSON(json: any): CategoryNameConflictPayloadList {
    return CategoryNameConflictPayloadListFromJSONTyped(json, false);
}

export function CategoryNameConflictPayloadListFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryNameConflictPayloadList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryNameConflictPayloadList': !exists(json, 'categoryNameConflictPayloadList') ? undefined : ((json['categoryNameConflictPayloadList'] as Array<any>).map(CategoryNameConflictPayloadFromJSON)),
    };
}

export function CategoryNameConflictPayloadListToJSON(value?: CategoryNameConflictPayloadList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryNameConflictPayloadList': value.categoryNameConflictPayloadList === undefined ? undefined : ((value.categoryNameConflictPayloadList as Array<any>).map(CategoryNameConflictPayloadToJSON)),
    };
}

