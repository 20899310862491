const initState = {
    reserveBookList : []
  };
  export function myReserveBookList(
    state = initState,
    action
  ) {
    switch (action.type) {
  
      case "HANDLE_RESERVE_BOOK_LIST":
        return {
          ...state,
          reserveBookList: action.payload,
        };
  
      default:
        return state;
    }
  }
  