/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminMemberRoleDto
 */
export interface AdminMemberRoleDto {
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    admin?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    adminMemberRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberRoleDto
     */
    adminMemberRoleName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    articleManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    discountManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    inventoryManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    marketingEventManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    membershipManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    membershipManagementRead?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    orderManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    orderManagementRead?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    pointManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    productManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    productPriceManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRoleDto
     */
    stockLevelManagement?: number;
}

export function AdminMemberRoleDtoFromJSON(json: any): AdminMemberRoleDto {
    return AdminMemberRoleDtoFromJSONTyped(json, false);
}

export function AdminMemberRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMemberRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'adminMemberRoleId': !exists(json, 'adminMemberRoleId') ? undefined : json['adminMemberRoleId'],
        'adminMemberRoleName': !exists(json, 'adminMemberRoleName') ? undefined : json['adminMemberRoleName'],
        'articleManagement': !exists(json, 'articleManagement') ? undefined : json['articleManagement'],
        'discountManagement': !exists(json, 'discountManagement') ? undefined : json['discountManagement'],
        'inventoryManagement': !exists(json, 'inventoryManagement') ? undefined : json['inventoryManagement'],
        'marketingEventManagement': !exists(json, 'marketingEventManagement') ? undefined : json['marketingEventManagement'],
        'membershipManagement': !exists(json, 'membershipManagement') ? undefined : json['membershipManagement'],
        'membershipManagementRead': !exists(json, 'membershipManagementRead') ? undefined : json['membershipManagementRead'],
        'orderManagement': !exists(json, 'orderManagement') ? undefined : json['orderManagement'],
        'orderManagementRead': !exists(json, 'orderManagementRead') ? undefined : json['orderManagementRead'],
        'pointManagement': !exists(json, 'pointManagement') ? undefined : json['pointManagement'],
        'productManagement': !exists(json, 'productManagement') ? undefined : json['productManagement'],
        'productPriceManagement': !exists(json, 'productPriceManagement') ? undefined : json['productPriceManagement'],
        'stockLevelManagement': !exists(json, 'stockLevelManagement') ? undefined : json['stockLevelManagement'],
    };
}

export function AdminMemberRoleDtoToJSON(value?: AdminMemberRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admin': value.admin,
        'adminMemberRoleId': value.adminMemberRoleId,
        'adminMemberRoleName': value.adminMemberRoleName,
        'articleManagement': value.articleManagement,
        'discountManagement': value.discountManagement,
        'inventoryManagement': value.inventoryManagement,
        'marketingEventManagement': value.marketingEventManagement,
        'membershipManagement': value.membershipManagement,
        'membershipManagementRead': value.membershipManagementRead,
        'orderManagement': value.orderManagement,
        'orderManagementRead': value.orderManagementRead,
        'pointManagement': value.pointManagement,
        'productManagement': value.productManagement,
        'productPriceManagement': value.productPriceManagement,
        'stockLevelManagement': value.stockLevelManagement,
    };
}

