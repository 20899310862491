import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { dateToString } from "../../../utils/GlobalFunction";
import {fetchFileFromURLToFile, fetchFileFromURLToFilePost} from "../../../utils/reader/fileUtils/fileUtil";
import style from "./BorrowReport.module.css";

import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import {
    BorrowReportBetweenDateUsingPOSTRequest,
    BorrowViewRecordReportRequestModel,
    BorrowViewRecordReportRequestModelReportTypesEnum,
    BorrowViewRecordReportResponseModel, GetUsingGETRequest,
    ReportControllerApi,
    ReportRecordControllerApi,
    ReportStorageControllerApi, ReportStorageDto,
    ReportStorageIdResponseModel,
    RequestExportBorrowViewRecordReportUsingPOSTRequest,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import * as Storage from "../../../utils/local-storage";
import SelectOption from "../../../components/SelectOption/SelectOption";
import PageLoading from "../../../components/loading/PageLoading";
export default function BorrowReport() {
    const [displayData, setDisplayData] = useState<BorrowViewRecordReportResponseModel>();

    const [yearArr, setYearArr] = useState<any>([]);
    const [monthArr, setMonthArr] = useState<any>([]);
    const [startYear, setSartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [startMonth, setSartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [bookType, setBookType] = useState("ALL");
    const [type, setType] = useState<{ id: string; name: string; isChecked: boolean }[]>([
        { id: "WEB_BORROW", name: "網頁借閱", isChecked: true },
        { id: "MOBILE_BORROW", name: "APP借閱", isChecked: true },
        { id: "READ_ONLINE", name: "線上全本瀏覽", isChecked: true },
        { id: "PREVIEW", name: "試閱", isChecked: true },
        { id: "BORROW_TIME", name: "預約次數", isChecked: true },
        // { id: "CHAPTER", name: "章節", isChecked: true },
        // { id: "CANT_BORROW", name: "無法借閱", isChecked: true },
        { id: "CONTENT", name: "書目次數", isChecked: true },
    ]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [isbn, setIsbn] = useState([]);
    const [brn, setbrn] = useState([]);
    const [userName, setUserName] = useState([]);
    const [lastdate, setLastDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pagelimit, setPageLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    const handleType = (e: any) => {
        const result = type.map((obj: { id: string; name: string; isChecked: boolean }) => {
            if (obj.id === e.target.id) {
                return { ...obj, isChecked: !obj.isChecked };
            }
            return obj;
        });
        setType(result);
    };

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        var now = new Date();
        let yearArr = [];
        for (var i = 2000; i <= now.getFullYear(); i++) {
            yearArr.push({ id: i, name: i.toString() });
        }
        setYearArr(yearArr.reverse());
        let monthArr = [];
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                monthArr.push({ id: i, name: `0${i}` });
            } else {
                monthArr.push({ id: i, name: i.toString() });
            }
        }
        setMonthArr(monthArr);

        var nextyear = new Date(new Date().getFullYear(), 11, 31);
        const currentDayOfMonth = nextyear.getDate();
        const currentMonth = nextyear.getMonth(); // Be careful! January is 0, not 1
        const currentYear = nextyear.getFullYear();
        const dateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
        setLastDate(dateString);
    }, []);

    const handleSubmit = (from: boolean) => {
        if (start === "" || end === "") {
            return alert(`請輸入有效日期`);
        }
        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於最後日期`);
        }

        let pagefrom = 0;
        if (from) {
            pagefrom = (currentPage - 1) * Number(pagelimit);
        }

        let a = {
            borrowViewRecordReportRequestModel: {
                bookType: bookType,
                reportTypes: type
                    .filter((it) => it.isChecked)
                    .map((type) => type.id as BorrowViewRecordReportRequestModelReportTypesEnum) as BorrowViewRecordReportRequestModelReportTypesEnum[],
                startDate: start,
                endDate: end,
                isbn: isbn.length == 1 && isbn[0] == "" ? null : isbn,
                brn: brn.length == 1 && brn[0] == "" ? null : brn,
                userName: userName,
                from: pagefrom,
                limit: Number(pagelimit),
            } as BorrowViewRecordReportRequestModel,
        } as BorrowReportBetweenDateUsingPOSTRequest;

        new ReportControllerApi(genApiConfig())
            .borrowReportBetweenDateUsingPOST(a)
            .then((res) => {
                if (res !== undefined) {
                    console.log(res);
                    setDisplayData(res);
                    setPageCount(res.pageTotal!!);
                }
            })
            .catch((err) => console.log("err", err));

        if (!from) {
            setCurrentPage(1);
        }
    };

    const handleIsbn = (e: any) => {
        const isbnResult = e.target.value.replaceAll(" ", "").split(",");
        setIsbn(isbnResult);
    };

    const handlebrn = (e: any) => {
        const brnResult = e.target.value.replaceAll(" ", "").split(",");
        setbrn(brnResult);
    };

    const handleUserName = (e: any) => {
        const userNameResult = e.target.value.split(",");
        setUserName(userNameResult);
    };

    const downloadBorrowViewRecord = () => {
        // fetchFileFromURLToFile(
        //     `${process.env.REACT_APP_BACKEND}/reportRecord/export-BorrowViewRecord-report`,
        //     `ReadDate_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx` //,
        //     //displayData
        // );

        if (start === "" || end === "") {
            return alert(`請輸入有效日期`);
        }
        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於最後日期`);
        }

        setIsDownloadLoading(true)

        let b = {
            bookType: bookType,
            reportTypes: type
                .filter((it) => it.isChecked)
                .map((type) => type.id as BorrowViewRecordReportRequestModelReportTypesEnum) as BorrowViewRecordReportRequestModelReportTypesEnum[],
            startDate: start,
            endDate: end,
            isbn: isbn.length == 1 && isbn[0] == "" ? null : isbn,
            brn: brn.length == 1 && brn[0] == "" ? null : brn,
            userName: userName,
        } as RequestExportBorrowViewRecordReportUsingPOSTRequest;

        new ReportRecordControllerApi(genApiConfig())
            .requestExportBorrowViewRecordReportUsingPOST({
                borrowViewRecordReportRequestModel: b
            } as RequestExportBorrowViewRecordReportUsingPOSTRequest)
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const reportStorageHandler = (res: ReportStorageIdResponseModel) => {
        function timerFunc() {
            new ReportStorageControllerApi(genApiConfig())
                .getUsingGET({
                    id: res.reportStorageId
                } as GetUsingGETRequest)
                .then((res: ReportStorageDto) => {
                    // Status :
                    // -1 -> Invalid
                    //  0 -> Pending
                    //  1 -> Running
                    //  2 -> Readied
                    //  3 -> Deleted

                    if (res.status === -1 || res.status === 2 || res.status === 3) {
                        if (res.status === 2) {
                            fetchFileFromURLToFile(
                                `${process.env.REACT_APP_BACKEND}/file/${res.fileName}`,
                                res.fileName
                            ).then(() => {
                                setIsDownloadLoading(false)
                            })
                        } else {
                            setIsDownloadLoading(false)
                        }
                    } else {
                        let seconds = 1
                        setTimeout(timerFunc, seconds * 1000)
                    }
                })
        }

        let seconds = 1
        setTimeout(timerFunc, seconds * 1000)
    }

    const handletoggle = (value: any) => {};

    const handlepagelimit = () => {
        let pageArr = [];
        for (var i = 1; i <= 10; i++) {
            pageArr.push({
                id: i,
                name: i + "0",
            });
        }

        return pageArr;
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleSelectPage = (value: any) => {
        setCurrentPage(parseInt(value.name));
    };

    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    useEffect(() => {
        if (displayData != undefined && currentPage > 0) {
            handleSubmit(true);
        }
    }, [currentPage]);

    return (
        <>
            {isDownloadLoading ? (<>
                <PageLoading/>
            </>) : (<></>)}
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.READREPORT)}>
                        {/* <Trans>CateList</Trans> */}閱讀數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => handletoggle(value)}>
                        {/* <Trans>PurchasedBook</Trans> */}借閱及瀏覽紀錄報告
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}書籍數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.CATEGORYREPORT)}>
                        主題分類排行榜
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.RESERVINGREPORT)}>
                        預約中查詢數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREADREPORT)}>
                        借閱瀏覽紀錄報表
                    </Button>
                </div>
            </div>
            <div className={style.counterReport}>
                <h2>借閱及瀏覽紀錄報告</h2>
                <div>
                    <div>
                        <p>選擇統計類型:</p>
                        <input
                            type="radio"
                            id="all"
                            value="ALL"
                            name="bookType"
                            checked={bookType === "ALL" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="ALL">全部</label>
                        <input
                            type="radio"
                            id="ebook"
                            value="EBOOK"
                            name="bookType"
                            checked={bookType === "EBOOK" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="EBOOK">電子書</label>
                        <input
                            type="radio"
                            id="magazine"
                            value="MAGAZINE"
                            name="bookType"
                            checked={bookType === "MAGAZINE" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="MAGAZINE">電子雜誌</label>
                        <input
                            type="radio"
                            id="video"
                            value="AUDIO"
                            name="bookType"
                            checked={bookType === "AUDIO" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="AUDIO">影音</label>
                    </div>

                    <div>
                        <p>選擇閱讀類型:</p>
                        {type.map((it: { id: string; name: string; isChecked: boolean }) => (
                            <>
                                <input type="checkbox" id={it.id} value={it.id} checked={it.isChecked} onClick={(e) => handleType(e)} />
                                <label htmlFor={it.id}>{it.name}</label>
                            </>
                        ))}
                    </div>
                    <p>時間:</p>
                    <div className={style.datePickerContainer}>
                        <label htmlFor="start"></label>
                        <input type="date" id="start" name="trip-start" min="2018-01-01" max={lastdate} value={start} onChange={(e) => setStart(e.target.value)} />
                        <label htmlFor="end"> ~ </label>
                        <input type="date" id="end" name="trip-end" min="2018-01-01" max={lastdate} value={end} onChange={(e) => setEnd(e.target.value)} />
                        {/* <p>(提供查詢5年資料，查詢區間限定1個月內。)</p> */}
                    </div>
                    <div>
                        <h3>ISBN: (請用逗號區隔，例如: 1234,123,3456)</h3>
                        <textarea onChange={(e: any) => handleIsbn(e)} className={style.textarea} />
                    </div>
                    <div>
                        <h3>BRN: (請用逗號區隔，例如: 1234,123,3456)</h3>
                        <textarea onChange={(e: any) => handlebrn(e)} className={style.textarea} />
                    </div>
                    <div>
                        <h3>讀者帳號:</h3>
                        <input type="text" onChange={(e: any) => handleUserName(e)} className={style.userName} />
                    </div>
                </div>
                <Button styleType={"submitButton"} callBack={() => handleSubmit(false)}>
                    查詢
                </Button>
                <Button styleType={"submitButton"} callBack={() => downloadBorrowViewRecord()}>
                    匯出EXCEL
                </Button>
                <hr className={style.line}></hr>
                <div className={style.showrecord}>
                    <div>顯示記錄 (預設為10項)</div>
                    <SelectOption styleType={PATH.STYLETYPE_FIFTH} items={handlepagelimit()} callBack={(value) => setPageLimit(value.name)} placeholder={""} />
                </div>
                <tr className={style.Tabletr}>
                    <th>排名</th>
                    <th>帳號</th>
                    <th>姓名</th>
                    <th>網頁借閱</th>
                    <th>APP借閱</th>
                    <th>線上全本瀏覽</th>
                    <th>試閱</th>
                    <th>預約次數</th>
                    {/* <th>章節</th> */}
                    {/* <th>無法借閱</th> */}
                    <th>書目次數</th>
                    <th>總次數</th>
                    <th>閱讀總時數</th>
                </tr>

                {displayData?.data?.map((val) => {
                    let hours = Math.floor(val?.readTimeCount!! / 3600);
                    let totalTime = val?.readTimeCount!! % 3600;
                    let minutes = Math.floor(totalTime / 60);
                    let seconds = totalTime % 60;
                    return (
                        <tr>
                            <th>{val.seq!! + 1}</th>
                            <th>{val.username}</th>
                            <th>{val.name}</th>
                            <th>{val.webBorrow!!}</th>
                            <th>{val.mobileBorrow!!}</th>
                            <th>{val.readOnline!!}</th>
                            <th>{val.preview!!}</th>
                            <th>{val.borrowTime!!}</th>
                            {/* <th>{val.chapter!!}</th> */}
                            {/* <th>{val.cantBorrow!!}</th> */}
                            <th>{val.contentCount!!}</th>

                            <th>
                                {/* + val.cantBorrow!!  + val.chapter!! */}
                                {val.borrowTime!! + val.contentCount!! + val.preview!! + val.mobileBorrow!! + val.readOnline!! + val.webBorrow!!}
                            </th>
                            <th>
                                {hours}h{minutes}m{seconds}s
                            </th>
                        </tr>
                    );
                })}

                {displayData ? (
                    <>
                        <tr>
                            <th colSpan={3}>累計總次數 :</th>

                            <th>{displayData.webBorrowTotal}</th>
                            <th>{displayData.mobileBorrowTotal}</th>
                            <th>{displayData.readOnlineTotal}</th>
                            <th>{displayData.previewTotal}</th>
                            <th>{displayData.borrowTimeTotal}</th>
                            {/*<th>{displayData.chapterTotal}</th>*/}
                            {/*<th>{displayData.cantBorrowTotal}</th>*/}
                            <th>{displayData.contentTotal}</th>
                        </tr>
                        <tr>
                            <th colSpan={3}>讀者帳號數量 :</th>
                            <th>{displayData.totalUser!!}</th>
                        </tr>
                    </>
                ) : (
                    <></>
                )}
                <tr></tr>

                {displayData?.data ? (
                    <div className={style.page_row}>
                        <div className={style.page_item} onClick={() => handleFirstPage()}>
                            首頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handlePrevPage()}>
                            上一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page}>
                            <SelectOption
                                styleType={PATH.STYLETYPE_FOUR}
                                value={currentPage.toString()}
                                firstValue={""}
                                items={handlepagecount()}
                                callBack={(value) => handleSelectPage(value)}
                            ></SelectOption>
                            <div style={{ marginLeft: "7px" }}>/ {pageCount}</div>
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleNextPage()}>
                            下一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleLastPage()}>
                            尾頁
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
