/**
 * get
 */
export function httpGet(
  url: string,
  headers: HeadersInit = {},
  method: string = "GET",
  credentials: RequestCredentials = "omit",
  mode: RequestMode = "cors",
  redirect: RequestRedirect = "follow",
  cache: RequestCache = "no-cache"
) {
  var result = new Promise(async (resolve, reject) => {
    await fetch(url, {
      headers,
      method,
      credentials,
      mode,
      redirect,
      cache,
    })
      .then((response) => {
        if (response.status === 200) {
          if (url.includes("file")) {
            return response;
          } else {
            return response.json();
          }
        }
        if (response.status === 403) {
          //TODO refresh Token
          reject("Login First");
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return result;
}
/**
 * post
 */
export async function httpPost(
  url: string,
  body?: any,
) {
  let fetchOption: { [key: string]: any } = {
    method: "POST", headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  }
  if (body) {
    fetchOption.body = JSON.stringify(body)
  }
  var result = new Promise(async (resolve, reject) => [
    await fetch(url, body)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      }),
  ]);
  return result;
}

export async function httpPatch(
  url: string,
  body: any,
  headers: HeadersInit = {
    "Content-type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
  method: string = "POST",
  credentials: RequestCredentials = "omit",
  mode: RequestMode = "cors",
  redirect: RequestRedirect = "follow",
  cache: RequestCache = "no-cache"
) {
  var result = new Promise(async (resolve, reject) => {
    await fetch(url, {
      body: JSON.stringify(body),
      method,
      //headers,
      //credentials,
      //mode,
      //redirect,
      //cache,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return result;
}
