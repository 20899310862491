/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminMemberRole,
    AdminMemberRoleFromJSON,
    AdminMemberRoleFromJSONTyped,
    AdminMemberRoleToJSON,
} from './AdminMemberRole';

/**
 * 
 * @export
 * @interface AdminMember
 */
export interface AdminMember {
    /**
     * 
     * @type {number}
     * @memberof AdminMember
     */
    adminMemberId?: number;
    /**
     * 
     * @type {AdminMemberRole}
     * @memberof AdminMember
     */
    adminMemberRoleByMemberRole?: AdminMemberRole;
    /**
     * 
     * @type {boolean}
     * @memberof AdminMember
     */
    dbDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    lastname?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMember
     */
    memberRole?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    memberType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    resId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    ssoId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMember
     */
    username?: string;
}

export function AdminMemberFromJSON(json: any): AdminMember {
    return AdminMemberFromJSONTyped(json, false);
}

export function AdminMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminMemberId': !exists(json, 'adminMemberId') ? undefined : json['adminMemberId'],
        'adminMemberRoleByMemberRole': !exists(json, 'adminMemberRoleByMemberRole') ? undefined : AdminMemberRoleFromJSON(json['adminMemberRoleByMemberRole']),
        'dbDelete': !exists(json, 'dbDelete') ? undefined : json['dbDelete'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'memberRole': !exists(json, 'memberRole') ? undefined : json['memberRole'],
        'memberType': !exists(json, 'memberType') ? undefined : json['memberType'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'resId': !exists(json, 'resId') ? undefined : json['resId'],
        'ssoId': !exists(json, 'ssoId') ? undefined : json['ssoId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function AdminMemberToJSON(value?: AdminMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminMemberId': value.adminMemberId,
        'adminMemberRoleByMemberRole': AdminMemberRoleToJSON(value.adminMemberRoleByMemberRole),
        'dbDelete': value.dbDelete,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'memberRole': value.memberRole,
        'memberType': value.memberType,
        'password': value.password,
        'resId': value.resId,
        'ssoId': value.ssoId,
        'username': value.username,
    };
}

