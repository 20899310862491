/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AwardRequestModel,
    AwardRequestModelFromJSON,
    AwardRequestModelFromJSONTyped,
    AwardRequestModelToJSON,
} from './AwardRequestModel';
import {
    CategoryRequestModel,
    CategoryRequestModelFromJSON,
    CategoryRequestModelFromJSONTyped,
    CategoryRequestModelToJSON,
} from './CategoryRequestModel';
import {
    LanguageRequestModel,
    LanguageRequestModelFromJSON,
    LanguageRequestModelFromJSONTyped,
    LanguageRequestModelToJSON,
} from './LanguageRequestModel';
import {
    OrganizationRequestModel,
    OrganizationRequestModelFromJSON,
    OrganizationRequestModelFromJSONTyped,
    OrganizationRequestModelToJSON,
} from './OrganizationRequestModel';
import {
    ProductRequestModel,
    ProductRequestModelFromJSON,
    ProductRequestModelFromJSONTyped,
    ProductRequestModelToJSON,
} from './ProductRequestModel';

/**
 * 
 * @export
 * @interface Sync
 */
export interface Sync {
    /**
     * 
     * @type {Array<AwardRequestModel>}
     * @memberof Sync
     */
    awardRequestModels?: Array<AwardRequestModel>;
    /**
     * 
     * @type {Array<OrganizationRequestModel>}
     * @memberof Sync
     */
    bookOrganizationRequestModels?: Array<OrganizationRequestModel>;
    /**
     * 
     * @type {Array<CategoryRequestModel>}
     * @memberof Sync
     */
    categoryModels?: Array<CategoryRequestModel>;
    /**
     * 
     * @type {Array<LanguageRequestModel>}
     * @memberof Sync
     */
    languageRequestModels?: Array<LanguageRequestModel>;
    /**
     * 
     * @type {number}
     * @memberof Sync
     */
    missionId?: number;
    /**
     * 
     * @type {Array<ProductRequestModel>}
     * @memberof Sync
     */
    products?: Array<ProductRequestModel>;
}

export function SyncFromJSON(json: any): Sync {
    return SyncFromJSONTyped(json, false);
}

export function SyncFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sync {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'awardRequestModels': !exists(json, 'awardRequestModels') ? undefined : ((json['awardRequestModels'] as Array<any>).map(AwardRequestModelFromJSON)),
        'bookOrganizationRequestModels': !exists(json, 'bookOrganizationRequestModels') ? undefined : ((json['bookOrganizationRequestModels'] as Array<any>).map(OrganizationRequestModelFromJSON)),
        'categoryModels': !exists(json, 'categoryModels') ? undefined : ((json['categoryModels'] as Array<any>).map(CategoryRequestModelFromJSON)),
        'languageRequestModels': !exists(json, 'languageRequestModels') ? undefined : ((json['languageRequestModels'] as Array<any>).map(LanguageRequestModelFromJSON)),
        'missionId': !exists(json, 'missionId') ? undefined : json['missionId'],
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductRequestModelFromJSON)),
    };
}

export function SyncToJSON(value?: Sync | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'awardRequestModels': value.awardRequestModels === undefined ? undefined : ((value.awardRequestModels as Array<any>).map(AwardRequestModelToJSON)),
        'bookOrganizationRequestModels': value.bookOrganizationRequestModels === undefined ? undefined : ((value.bookOrganizationRequestModels as Array<any>).map(OrganizationRequestModelToJSON)),
        'categoryModels': value.categoryModels === undefined ? undefined : ((value.categoryModels as Array<any>).map(CategoryRequestModelToJSON)),
        'languageRequestModels': value.languageRequestModels === undefined ? undefined : ((value.languageRequestModels as Array<any>).map(LanguageRequestModelToJSON)),
        'missionId': value.missionId,
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductRequestModelToJSON)),
    };
}

