import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import loadingGif from "../../../assets/svg/loading.gif";
import { DIALOG_CANCEL, DIALOG_COMPLETE, STYLETYPE_MAIN, STYLETYPE_THIRD } from "../../../utils/Contants";

import Button from "../../Button/Button";
import style from "./DeleteSheet.module.css";
import { Trans, useTranslation } from "react-i18next";
interface IProps {
    title?: string;
    content?: string;
    value?: string;
    callBack: (value: any, value2?: any) => void;
    styleType?: string;
    children?: any;
}

class DeleteSheet extends Component<IProps> {
    state = {
        content: {},
        title: "",
        showError: false,
        loading: false,
    };

    handleCancel = () => {
        this.props.callBack({ action: DIALOG_CANCEL });
    };

    handleSubmit = () => {
        this.props.callBack({ action: DIALOG_COMPLETE });
    };

    render() {
        const { content, showError, loading } = this.state;

        return (
            <Fragment>
                <div className={style.content_height}>
                    <div className={style.container}>
                        <label>你正在刪除出版商{""}</label>
                    </div>

                    <div className={style.button_on_right}>
                        <Button styleType={STYLETYPE_THIRD} callBack={(value) => this.handleCancel()}>
                            <Trans>Cancel</Trans>
                        </Button>

                        <Button styleType={STYLETYPE_MAIN} callBack={(value) => this.handleSubmit()} disabled={loading}>
                            {loading ? "" : <Trans>Delete</Trans>}
                            <img src={loadingGif} style={{ width: "20px", height: "20px" }} hidden={!loading} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default DeleteSheet;
