/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    DisplayProductDto,
    DisplayProductDtoFromJSON,
    DisplayProductDtoFromJSONTyped,
    DisplayProductDtoToJSON,
} from './DisplayProductDto';
import {
    HashtagDto,
    HashtagDtoFromJSON,
    HashtagDtoFromJSONTyped,
    HashtagDtoToJSON,
} from './HashtagDto';
import {
    MarketingEventDtoLite,
    MarketingEventDtoLiteFromJSON,
    MarketingEventDtoLiteFromJSONTyped,
    MarketingEventDtoLiteToJSON,
} from './MarketingEventDtoLite';

/**
 * 
 * @export
 * @interface ArticleDto
 */
export interface ArticleDto {
    /**
     * 
     * @type {number}
     * @memberof ArticleDto
     */
    articleCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleCoverImage?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleDto
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleTitle?: string;
    /**
     * 
     * @type {CategoryDto}
     * @memberof ArticleDto
     */
    category?: CategoryDto;
    /**
     * 
     * @type {Array<DisplayProductDto>}
     * @memberof ArticleDto
     */
    displayProducts?: Array<DisplayProductDto>;
    /**
     * 
     * @type {Array<HashtagDto>}
     * @memberof ArticleDto
     */
    hashtags?: Array<HashtagDto>;
    /**
     * 
     * @type {MarketingEventDtoLite}
     * @memberof ArticleDto
     */
    marketingEvent?: MarketingEventDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    status?: string;
}

export function ArticleDtoFromJSON(json: any): ArticleDto {
    return ArticleDtoFromJSONTyped(json, false);
}

export function ArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleCategory': !exists(json, 'articleCategory') ? undefined : json['articleCategory'],
        'articleContent': !exists(json, 'articleContent') ? undefined : json['articleContent'],
        'articleCoverImage': !exists(json, 'articleCoverImage') ? undefined : json['articleCoverImage'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'articleTitle': !exists(json, 'articleTitle') ? undefined : json['articleTitle'],
        'category': !exists(json, 'category') ? undefined : CategoryDtoFromJSON(json['category']),
        'displayProducts': !exists(json, 'displayProducts') ? undefined : ((json['displayProducts'] as Array<any>).map(DisplayProductDtoFromJSON)),
        'hashtags': !exists(json, 'hashtags') ? undefined : ((json['hashtags'] as Array<any>).map(HashtagDtoFromJSON)),
        'marketingEvent': !exists(json, 'marketingEvent') ? undefined : MarketingEventDtoLiteFromJSON(json['marketingEvent']),
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ArticleDtoToJSON(value?: ArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleCategory': value.articleCategory,
        'articleContent': value.articleContent,
        'articleCoverImage': value.articleCoverImage,
        'articleId': value.articleId,
        'articleTitle': value.articleTitle,
        'category': CategoryDtoToJSON(value.category),
        'displayProducts': value.displayProducts === undefined ? undefined : ((value.displayProducts as Array<any>).map(DisplayProductDtoToJSON)),
        'hashtags': value.hashtags === undefined ? undefined : ((value.hashtags as Array<any>).map(HashtagDtoToJSON)),
        'marketingEvent': MarketingEventDtoLiteToJSON(value.marketingEvent),
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'status': value.status,
    };
}

