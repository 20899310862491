/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {Blob}
     * @memberof Resource
     */
    file?: Blob;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    inputStream?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    url?: string;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'inputStream': !exists(json, 'inputStream') ? undefined : json['inputStream'],
        'open': !exists(json, 'open') ? undefined : json['open'],
        'readable': !exists(json, 'readable') ? undefined : json['readable'],
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'file': value.file,
        'filename': value.filename,
        'inputStream': value.inputStream,
        'open': value.open,
        'readable': value.readable,
        'uri': value.uri,
        'url': value.url,
    };
}

