/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountDtoLite,
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';

/**
 * 
 * @export
 * @interface CouponDto
 */
export interface CouponDto {
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    couponId?: number;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof CouponDto
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    discountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CouponDto
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CouponDto
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    title?: string;
}

export function CouponDtoFromJSON(json: any): CouponDto {
    return CouponDtoFromJSONTyped(json, false);
}

export function CouponDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouponDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'couponCode': !exists(json, 'couponCode') ? undefined : json['couponCode'],
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
        'discount': !exists(json, 'discount') ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function CouponDtoToJSON(value?: CouponDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'couponCode': value.couponCode,
        'couponId': value.couponId,
        'discount': DiscountDtoLiteToJSON(value.discount),
        'discountId': value.discountId,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
        'title': value.title,
    };
}

