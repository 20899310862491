import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BatchAddUserIcon from "../../assets/svg/add_user_many.svg";
import BannerIcon from "../../assets/svg/banner.svg";
import borrow from "../../assets/svg/borrow.svg";
import MarketingIcon from "../../assets/svg/marketing.svg";
import Page from "../../assets/svg/page_management.svg";
import UserIcon from "../../assets/svg/user.svg";
import Book from "../../assets/svg/bk.svg";
import Report from "../../assets/svg/report_man.svg";
import WhiteList from "../../assets/svg/whitelist.svg";

import UserInformation from "../../components/CardView/User/UserInformation";
import * as PATH from "../../utils/Contants";
import style from "./AdminView.module.css";
import { useEffect } from "react";

export default function AdminView() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = localStorage.getItem(PATH.USER_INFO);

    if (userInfo === null) {
      navigate(PATH.LOGIN_PATH);
    }
  });

  return (
    <div>
      {localStorage.getItem(PATH.USER_INFO) === null ? (
        <>{navigate(PATH.LOGIN_PATH)}</>
      ) : (
        <>
          <UserInformation callBack={() => ""} />
          <div className={style.layout}>
            <div className={style.button}>
              <div onClick={() => navigate(PATH.PAGE_SETTING)}>
                <img src={Page} />
                <h4>
                  <Trans>PageSetting</Trans>
                </h4>
              </div>
            </div>
            <div className={style.button}>
              <div onClick={() => navigate(PATH.LENDSETTING)}>
                <img src={borrow} />
                <h4>
                  <Trans>BorrowBookSetting</Trans>
                </h4>
              </div>
            </div>

            <div className={style.button}>
              <div onClick={() => navigate(PATH.MARKETING_EVENT_MANAGEMENT)}>
                <img src={MarketingIcon} />
                <h4>
                  <Trans>Marketing</Trans>
                </h4>
              </div>
            </div>

            <div className={style.button}>
              <div onClick={() => navigate(PATH.BANNER_MANAGEMENT)}>
                <img src={BannerIcon} />
                <h4>Banner管理</h4>
              </div>
            </div>

            {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
              <></>
            ) : (
              <>
                <div className={style.button}>
                  <div onClick={() => navigate(PATH.ALL_BATCH)}>
                    <img src={BatchAddUserIcon} />
                    <h4>批量上傳用戶管理</h4>
                  </div>
                </div>
                <div className={style.button}>
                  <div onClick={() => navigate(PATH.USERSETTING)}>
                    <img src={UserIcon} />
                    <h4>用戶管理</h4>
                  </div>
                </div>
              </>
            )} */}

            <div className={style.button}>
              <div onClick={() => navigate(PATH.BOOKLIST)}>
                <img src={Book} />
                <h4>書籍管理</h4>
              </div>
            </div>

            <div className={style.button}>
              <div onClick={() => navigate(PATH.READREPORT)}>
                <img src={Report} />
                <h4>報告管理</h4>
              </div>
            </div>
            <div className={style.button}>
                <div onClick={() => navigate("/WhiteListSetting")}>
                    <img src={WhiteList} />
                    <h4>線上閱讀白名單</h4>
                </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
