/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayProduct,
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface DisplayProductProduct
 */
export interface DisplayProductProduct {
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductProduct
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductProduct
     */
    displayProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof DisplayProductProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductProduct
     */
    productId?: number;
}

export function DisplayProductProductFromJSON(json: any): DisplayProductProduct {
    return DisplayProductProductFromJSONTyped(json, false);
}

export function DisplayProductProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayProductByDisplayProductId': !exists(json, 'displayProductByDisplayProductId') ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function DisplayProductProductToJSON(value?: DisplayProductProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayProductByDisplayProductId': DisplayProductToJSON(value.displayProductByDisplayProductId),
        'displayProductId': value.displayProductId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
    };
}

