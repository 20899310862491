/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    AgeGroup,
    AgeGroupFromJSON,
    AgeGroupFromJSONTyped,
    AgeGroupToJSON,
} from './AgeGroup';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import {
    MarketingEventMember,
    MarketingEventMemberFromJSON,
    MarketingEventMemberFromJSONTyped,
    MarketingEventMemberToJSON,
} from './MarketingEventMember';
import {
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';
import {
    MemberShoppingCart,
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import {
    UserPointHistory,
    UserPointHistoryFromJSON,
    UserPointHistoryFromJSONTyped,
    UserPointHistoryToJSON,
} from './UserPointHistory';

/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    acceptPromoption?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    accountStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address1?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address2?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address3?: number;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress1?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress2?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress3?: Address;
    /**
     * 
     * @type {AgeGroup}
     * @memberof Member
     */
    ageGroupByAgeGroupId?: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    ageGroupId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    birthday?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    birthdayMonth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    birthdayThisMonth?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    classNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    clientId?: string;
    /**
     * 
     * @type {Country}
     * @memberof Member
     */
    countryByLocationId?: Country;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    dbDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    expireDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    expirePoint?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    facebookId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    googleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    grade?: string;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    joinDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    locationId?: number;
    /**
     * 
     * @type {Array<MarketingEventMember>}
     * @memberof Member
     */
    marketingEventMembersByMemberId?: Array<MarketingEventMember>;
    /**
     * 
     * @type {Member}
     * @memberof Member
     */
    memberByReferalMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    memberId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof Member
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<MemberShoppingCart>}
     * @memberof Member
     */
    memberShoppingCartsByMemberId?: Array<MemberShoppingCart>;
    /**
     * 
     * @type {Array<Member>}
     * @memberof Member
     */
    membersByMemberId?: Array<Member>;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    membershipExpireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    nameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    nameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    openId?: string;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Member
     */
    ordersByMemberId?: Array<Order>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    phone?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Member
     */
    pointsByMemberId?: Array<Point>;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    referalMemberId?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    ssoId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    status?: string;
    /**
     * 
     * @type {Array<UserPointHistory>}
     * @memberof Member
     */
    userPointHistoriesByMemberId?: Array<UserPointHistory>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    validPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    verifyStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    vip?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    vipJoinDate?: Date;
}

export function MemberFromJSON(json: any): Member {
    return MemberFromJSONTyped(json, false);
}

export function MemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Member {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptPromoption': !exists(json, 'acceptPromoption') ? undefined : json['acceptPromoption'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'accountStatus': !exists(json, 'accountStatus') ? undefined : json['accountStatus'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'address3': !exists(json, 'address3') ? undefined : json['address3'],
        'addressByAddress1': !exists(json, 'addressByAddress1') ? undefined : AddressFromJSON(json['addressByAddress1']),
        'addressByAddress2': !exists(json, 'addressByAddress2') ? undefined : AddressFromJSON(json['addressByAddress2']),
        'addressByAddress3': !exists(json, 'addressByAddress3') ? undefined : AddressFromJSON(json['addressByAddress3']),
        'ageGroupByAgeGroupId': !exists(json, 'ageGroupByAgeGroupId') ? undefined : AgeGroupFromJSON(json['ageGroupByAgeGroupId']),
        'ageGroupId': !exists(json, 'ageGroupId') ? undefined : json['ageGroupId'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'birthdayMonth': !exists(json, 'birthdayMonth') ? undefined : json['birthdayMonth'],
        'birthdayThisMonth': !exists(json, 'birthdayThisMonth') ? undefined : json['birthdayThisMonth'],
        'className': !exists(json, 'className') ? undefined : json['className'],
        'classNumber': !exists(json, 'classNumber') ? undefined : json['classNumber'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'countryByLocationId': !exists(json, 'countryByLocationId') ? undefined : CountryFromJSON(json['countryByLocationId']),
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'dbDelete': !exists(json, 'dbDelete') ? undefined : json['dbDelete'],
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : json['defaultLanguage'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'expirePoint': !exists(json, 'expirePoint') ? undefined : json['expirePoint'],
        'facebookId': !exists(json, 'facebookId') ? undefined : json['facebookId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'googleId': !exists(json, 'googleId') ? undefined : json['googleId'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'joinDate': !exists(json, 'joinDate') ? undefined : (new Date(json['joinDate'])),
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'marketingEventMembersByMemberId': !exists(json, 'marketingEventMembersByMemberId') ? undefined : ((json['marketingEventMembersByMemberId'] as Array<any>).map(MarketingEventMemberFromJSON)),
        'memberByReferalMemberId': !exists(json, 'memberByReferalMemberId') ? undefined : MemberFromJSON(json['memberByReferalMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'memberLevelByMemberLevelId': !exists(json, 'memberLevelByMemberLevelId') ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'memberShoppingCartsByMemberId': !exists(json, 'memberShoppingCartsByMemberId') ? undefined : ((json['memberShoppingCartsByMemberId'] as Array<any>).map(MemberShoppingCartFromJSON)),
        'membersByMemberId': !exists(json, 'membersByMemberId') ? undefined : ((json['membersByMemberId'] as Array<any>).map(MemberFromJSON)),
        'membershipExpireDate': !exists(json, 'membershipExpireDate') ? undefined : (new Date(json['membershipExpireDate'])),
        'nameChi': !exists(json, 'nameChi') ? undefined : json['nameChi'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
        'openId': !exists(json, 'openId') ? undefined : json['openId'],
        'ordersByMemberId': !exists(json, 'ordersByMemberId') ? undefined : ((json['ordersByMemberId'] as Array<any>).map(OrderFromJSON)),
        'password': !exists(json, 'password') ? undefined : json['password'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'pointsByMemberId': !exists(json, 'pointsByMemberId') ? undefined : ((json['pointsByMemberId'] as Array<any>).map(PointFromJSON)),
        'referalMemberId': !exists(json, 'referalMemberId') ? undefined : json['referalMemberId'],
        'ssoId': !exists(json, 'ssoId') ? undefined : json['ssoId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'userPointHistoriesByMemberId': !exists(json, 'userPointHistoriesByMemberId') ? undefined : ((json['userPointHistoriesByMemberId'] as Array<any>).map(UserPointHistoryFromJSON)),
        'username': !exists(json, 'username') ? undefined : json['username'],
        'validPoint': !exists(json, 'validPoint') ? undefined : json['validPoint'],
        'verifyStatus': !exists(json, 'verifyStatus') ? undefined : json['verifyStatus'],
        'vip': !exists(json, 'vip') ? undefined : json['vip'],
        'vipJoinDate': !exists(json, 'vipJoinDate') ? undefined : (new Date(json['vipJoinDate'])),
    };
}

export function MemberToJSON(value?: Member | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptPromoption': value.acceptPromoption,
        'accessToken': value.accessToken,
        'accountStatus': value.accountStatus,
        'address1': value.address1,
        'address2': value.address2,
        'address3': value.address3,
        'addressByAddress1': AddressToJSON(value.addressByAddress1),
        'addressByAddress2': AddressToJSON(value.addressByAddress2),
        'addressByAddress3': AddressToJSON(value.addressByAddress3),
        'ageGroupByAgeGroupId': AgeGroupToJSON(value.ageGroupByAgeGroupId),
        'ageGroupId': value.ageGroupId,
        'birthday': value.birthday,
        'birthdayMonth': value.birthdayMonth,
        'birthdayThisMonth': value.birthdayThisMonth,
        'className': value.className,
        'classNumber': value.classNumber,
        'clientId': value.clientId,
        'countryByLocationId': CountryToJSON(value.countryByLocationId),
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString()),
        'dbDelete': value.dbDelete,
        'defaultLanguage': value.defaultLanguage,
        'email': value.email,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
        'expirePoint': value.expirePoint,
        'facebookId': value.facebookId,
        'firstName': value.firstName,
        'gender': value.gender,
        'googleId': value.googleId,
        'grade': value.grade,
        'joinDate': value.joinDate === undefined ? undefined : (value.joinDate.toISOString()),
        'lastName': value.lastName,
        'locationId': value.locationId,
        'marketingEventMembersByMemberId': value.marketingEventMembersByMemberId === undefined ? undefined : ((value.marketingEventMembersByMemberId as Array<any>).map(MarketingEventMemberToJSON)),
        'memberByReferalMemberId': MemberToJSON(value.memberByReferalMemberId),
        'memberId': value.memberId,
        'memberLevelByMemberLevelId': MemberLevelToJSON(value.memberLevelByMemberLevelId),
        'memberLevelId': value.memberLevelId,
        'memberShoppingCartsByMemberId': value.memberShoppingCartsByMemberId === undefined ? undefined : ((value.memberShoppingCartsByMemberId as Array<any>).map(MemberShoppingCartToJSON)),
        'membersByMemberId': value.membersByMemberId === undefined ? undefined : ((value.membersByMemberId as Array<any>).map(MemberToJSON)),
        'membershipExpireDate': value.membershipExpireDate === undefined ? undefined : (value.membershipExpireDate.toISOString()),
        'nameChi': value.nameChi,
        'nameEng': value.nameEng,
        'openId': value.openId,
        'ordersByMemberId': value.ordersByMemberId === undefined ? undefined : ((value.ordersByMemberId as Array<any>).map(OrderToJSON)),
        'password': value.password,
        'phone': value.phone,
        'pointsByMemberId': value.pointsByMemberId === undefined ? undefined : ((value.pointsByMemberId as Array<any>).map(PointToJSON)),
        'referalMemberId': value.referalMemberId,
        'ssoId': value.ssoId,
        'status': value.status,
        'userPointHistoriesByMemberId': value.userPointHistoriesByMemberId === undefined ? undefined : ((value.userPointHistoriesByMemberId as Array<any>).map(UserPointHistoryToJSON)),
        'username': value.username,
        'validPoint': value.validPoint,
        'verifyStatus': value.verifyStatus,
        'vip': value.vip,
        'vipJoinDate': value.vipJoinDate === undefined ? undefined : (value.vipJoinDate.toISOString()),
    };
}

