/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddProductInventoryItem
 */
export interface AddProductInventoryItem {
    /**
     * 
     * @type {Date}
     * @memberof AddProductInventoryItem
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof AddProductInventoryItem
     */
    inventory?: number;
    /**
     * 
     * @type {string}
     * @memberof AddProductInventoryItem
     */
    sap?: string;
    /**
     * 
     * @type {Date}
     * @memberof AddProductInventoryItem
     */
    startDate?: Date;
}

export function AddProductInventoryItemFromJSON(json: any): AddProductInventoryItem {
    return AddProductInventoryItemFromJSONTyped(json, false);
}

export function AddProductInventoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProductInventoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'inventory': !exists(json, 'inventory') ? undefined : json['inventory'],
        'sap': !exists(json, 'sap') ? undefined : json['sap'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
    };
}

export function AddProductInventoryItemToJSON(value?: AddProductInventoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'inventory': value.inventory,
        'sap': value.sap,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
    };
}

