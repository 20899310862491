import { Component, Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  BorrowControllerApi,
  GetMyBookshelfUsingPOSTRequest,
  MyBookshelfDto,
} from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import * as PATH from "../../../utils/Contants";
import { DIALOG_CANCEL, DIALOG_COMPLETE } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import style from "./BookShelf.module.css";
import BookShelfItem from "./BookShelfItem";

interface IProps {
  title?: string;
  content?: string;
  value?: string;
  callBack?: (value: any, value2?: any) => void;
  styleType?: string;
  children?: any;
  navigate?: any;
  userInfo?: any;
}

class BookShelf extends Component<IProps> {
  state = {
    content: {},
    title: "",
    showError: false,
    loading: false,
    borrowBook: [],
  };

  componentDidMount() {
    //    console.log("this.props", this.props.userInfo);
    if (Object.keys(this.props.userInfo?.userInfo).length > 0) {
      new BorrowControllerApi(genApiConfig())
        .getMyBookshelfUsingPOST({
          memberId: this.props.userInfo?.userInfo.member.memberId,
        } as GetMyBookshelfUsingPOSTRequest)
        .then((res: MyBookshelfDto) => {
          this.setState(
            { ...this.state, borrowBook: res.borrow?.slice(0, 5) },
            () => {}
          );
        })
        .catch((err) => {});
    }
  }

  getApi() {
    //console.log("this.props", this.props.userInfo);

    // api.postListAllMyBorrow(JSON.parse(member)?.memberId,1,10).then(data=>console.log('postListAllMyBorrow',data))

    new BorrowControllerApi(genApiConfig())
      .getMyBookshelfUsingPOST({
        memberId: this.props.userInfo?.userInfo.member.memberId,
      } as GetMyBookshelfUsingPOSTRequest)
      .then((res: any) => {
        this.setState(
          { ...this.state, borrowBook: res.borrow.slice(0, 5) },
          () => {}
        );
        this.props.callBack?.(true);
      })
      .catch((err) => {});
  }

  handleCancel = () => {
    this.props.callBack?.({ action: DIALOG_CANCEL });
  };

  handleSubmit = () => {
    this.props.callBack?.({ action: DIALOG_COMPLETE });
  };

  render() {
    const { content, showError, loading } = this.state;

    return (
      <Fragment>
        <div
          style={{ marginTop: "2em" }}
          className={style.tableItemListContainer}
        >
          <div className={style.tableListItemGridView}>
            <div className={style.topRow}></div>

            <div className={style.content}>
              <div className={style.padding}>
                <div className={style.title}>
                  <h3>
                    <Trans>Shelf</Trans>
                  </h3>
                  <div
                    className={style.btn}
                    onClick={() => this.props.navigate(PATH.MYBOOKSHELF)}
                  >
                    <Trans>showMore</Trans>
                  </div>
                </div>
                <div className={style.scrollingWrapper}>
                  {this.state.borrowBook.map((obj) => {
                    return (
                      <BookShelfItem
                        it={obj}
                        callBack={(value) => this.getApi()}
                      ></BookShelfItem>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};

export default connect(mapStateToProps, actionCreator)(BookShelf);
