/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PagingModelAdminMemberActionLogDto,
    PagingModelAdminMemberActionLogDtoFromJSON,
    PagingModelAdminMemberActionLogDtoToJSON,
} from '../models';

export interface GetLogByAdminMemberUsingGETRequest {
    adminMemberId: number;
    name?: string;
    page?: number;
    size?: number;
}

export interface TestingAddLogUsingPOSTRequest {
    adminMemberId: number;
    actionCode: string;
    name?: string;
    pageSettingId?: number;
    page?: number;
    size?: number;
}

/**
 * 
 */
export class AdminMemberActionLogControllerApi extends runtime.BaseAPI {

    /**
     * getLogByAdminMember
     */
    async getLogByAdminMemberUsingGETRaw(requestParameters: GetLogByAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PagingModelAdminMemberActionLogDto>> {
        if (requestParameters.adminMemberId === null || requestParameters.adminMemberId === undefined) {
            throw new runtime.RequiredError('adminMemberId','Required parameter requestParameters.adminMemberId was null or undefined when calling getLogByAdminMemberUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.adminMemberId !== undefined) {
            queryParameters['adminMemberId'] = requestParameters.adminMemberId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin-member-log/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagingModelAdminMemberActionLogDtoFromJSON(jsonValue));
    }

    /**
     * getLogByAdminMember
     */
    async getLogByAdminMemberUsingGET(requestParameters: GetLogByAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PagingModelAdminMemberActionLogDto> {
        const response = await this.getLogByAdminMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * testingAddLog
     */
    async testingAddLogUsingPOSTRaw(requestParameters: TestingAddLogUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PagingModelAdminMemberActionLogDto>> {
        if (requestParameters.adminMemberId === null || requestParameters.adminMemberId === undefined) {
            throw new runtime.RequiredError('adminMemberId','Required parameter requestParameters.adminMemberId was null or undefined when calling testingAddLogUsingPOST.');
        }

        if (requestParameters.actionCode === null || requestParameters.actionCode === undefined) {
            throw new runtime.RequiredError('actionCode','Required parameter requestParameters.actionCode was null or undefined when calling testingAddLogUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.adminMemberId !== undefined) {
            queryParameters['adminMemberId'] = requestParameters.adminMemberId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.actionCode !== undefined) {
            queryParameters['actionCode'] = requestParameters.actionCode;
        }

        if (requestParameters.pageSettingId !== undefined) {
            queryParameters['pageSettingId'] = requestParameters.pageSettingId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin-member-log/test/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagingModelAdminMemberActionLogDtoFromJSON(jsonValue));
    }

    /**
     * testingAddLog
     */
    async testingAddLogUsingPOST(requestParameters: TestingAddLogUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PagingModelAdminMemberActionLogDto> {
        const response = await this.testingAddLogUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
