/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductFile
 */
export interface ProductFile {
    /**
     * 
     * @type {string}
     * @memberof ProductFile
     */
    fileType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFile
     */
    fileUrl?: string;
    /**
     * 
     * @type {Product}
     * @memberof ProductFile
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductFile
     */
    productFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFile
     */
    productId?: number;
}

export function ProductFileFromJSON(json: any): ProductFile {
    return ProductFileFromJSONTyped(json, false);
}

export function ProductFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileType': !exists(json, 'fileType') ? undefined : json['fileType'],
        'fileUrl': !exists(json, 'fileUrl') ? undefined : json['fileUrl'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productFileId': !exists(json, 'productFileId') ? undefined : json['productFileId'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function ProductFileToJSON(value?: ProductFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileType': value.fileType,
        'fileUrl': value.fileUrl,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productFileId': value.productFileId,
        'productId': value.productId,
    };
}

