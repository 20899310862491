/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubOrganizationRequestModel,
    SubOrganizationRequestModelFromJSON,
    SubOrganizationRequestModelFromJSONTyped,
    SubOrganizationRequestModelToJSON,
} from './SubOrganizationRequestModel';

/**
 * 
 * @export
 * @interface OrganizationRequestModel
 */
export interface OrganizationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    bannerUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestModel
     */
    _delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestModel
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestModel
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestModel
     */
    shortName?: string;
    /**
     * 
     * @type {Array<SubOrganizationRequestModel>}
     * @memberof OrganizationRequestModel
     */
    subOrganizationList?: Array<SubOrganizationRequestModel>;
}

export function OrganizationRequestModelFromJSON(json: any): OrganizationRequestModel {
    return OrganizationRequestModelFromJSONTyped(json, false);
}

export function OrganizationRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerUrl': !exists(json, 'bannerUrl') ? undefined : json['bannerUrl'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'serverId': !exists(json, 'serverId') ? undefined : json['serverId'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'subOrganizationList': !exists(json, 'subOrganizationList') ? undefined : ((json['subOrganizationList'] as Array<any>).map(SubOrganizationRequestModelFromJSON)),
    };
}

export function OrganizationRequestModelToJSON(value?: OrganizationRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerUrl': value.bannerUrl,
        'delete': value._delete,
        'description': value.description,
        'fullName': value.fullName,
        'logoUrl': value.logoUrl,
        'name': value.name,
        'seq': value.seq,
        'serverId': value.serverId,
        'shortName': value.shortName,
        'subOrganizationList': value.subOrganizationList === undefined ? undefined : ((value.subOrganizationList as Array<any>).map(SubOrganizationRequestModelToJSON)),
    };
}

