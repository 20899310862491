import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import trashIcon from "../../../assets/svg/trash.svg";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import {
  AddUsingPOSTRequest,
  GetBannerByEventIdUsingGETRequest,
  MarketingEventBannerControllerApi,
  MarketingEventBannerDto,
  MarketingEventDto,
  UpdateUsingPOSTRequest,
} from "../../../fetcher";

import * as PATH from "../../../utils/Contants";
import { STYLETYPE_MAIN } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import style from "./BannerView.module.css";

interface IImageModel extends MarketingEventBannerDto {
  mFile?: File;
  file?: File;
}

interface IResponseMsg {
  isSuccess: boolean;
  message: string;
}

export default function BannerView() {
  const { i18n } = useTranslation();
  const { width, height } = useWindowSize();

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rows, setRows] = useState([]);

  const [blah_src, setBlahSrc] = useState<IImageModel>();
  const [m_blah_src, setMBlahSrc] = useState<IImageModel>();
  const [position, setPosition] = useState(0);

  const [locationData, setLocationData] = useState<MarketingEventDto>();
  const [currentBannerData, setCurrentBannerData] = useState<IImageModel>();

  useEffect(() => {
    if (location.state !== null) {
      let eventDto = location.state as MarketingEventDto;
      setLocationData(eventDto);
      getApiData(eventDto);
    }
  }, []);

  const getApiData = (eventDto: MarketingEventDto) => {
    new MarketingEventBannerControllerApi(genApiConfig())
      .getBannerByEventIdUsingGET({
        marketingEventId: eventDto.marketingEventId,
      } as GetBannerByEventIdUsingGETRequest)
      .then((res: MarketingEventBannerDto) => {
        let resEx = res as IImageModel;
        setCurrentBannerData(resEx);
      })
      .catch((err) => console.log(err));
  };

  const readurl = async (input: any, isMobile: boolean) => {
    if (input.target.files && input.target.files[0]) {
      if (isMobile) {
        setCurrentBannerData({
          ...currentBannerData,
          mobileContent: URL.createObjectURL(input.target.files[0]),
          mFile: input.target.files[0],
        });
      } else {
        setCurrentBannerData({
          ...currentBannerData,
          content: URL.createObjectURL(input.target.files[0]),
          file: input.target.files[0],
        });
      }

      //uploadImage(input.target.files[0], isMobile);
    }
  };

  const uploadImage = async (file: any): Promise<IResponseMsg> => {
    let responseMsg = {
      isSuccess: false,
      message: "",
    } as IResponseMsg;
    let fm = new FormData();
    fm.append("file", file);
    let url = `${process.env.REACT_APP_BACKEND}/file/fileupload/image`;
    let opts = {
      headers: {
        Accept: "plain/text",
        //Authorization: `Bearer ${tokenString}`,
      },
      method: "POST",
      credentials: "omit",
      cache: "no-cache",
      redirect: "follow",
      mode: "cors",
      body: fm,
    };
    let response = await fetch(url, opts as RequestInit);
    if (!response.ok) {
      responseMsg.isSuccess = false;
      responseMsg.message = "request is not success";
    } else {
      await response
        .text()
        .then((res) => {
          responseMsg.isSuccess = true;
          responseMsg.message = res;
        })
        .catch((err) => {
          responseMsg.isSuccess = false;
          responseMsg.message = err;
        });
    }
    return responseMsg;
  };

  const submitForm = (value: any) => {
    setIsLoading(true);
  };

  const checkValidForm = (): IResponseMsg => {
    let result = {
      isSuccess: false,
      message: "",
    };

    //CHECK ?
    // if (currentBannerData?.content === "") {
    //   result.message = "請加入Banner圖像檔案";
    //   return result;
    // }

    result.isSuccess = true;

    return result;
  };

  const getImagePath = (isMobile: boolean): string => {
    if (isMobile) {
      if (currentBannerData?.mobileContent !== "") {
        if (currentBannerData?.mobileContent?.substring(0, 4) === "blob") {
          return currentBannerData?.mobileContent;
        } else {
          return `${process.env.REACT_APP_BACKEND}/file/${currentBannerData?.mobileContent}`;
        }
      }
    } else {
      if (currentBannerData?.content !== "") {
        if (currentBannerData?.content?.substring(0, 4) === "blob") {
          return currentBannerData?.content;
        } else {
          return `${process.env.REACT_APP_BACKEND}/file/${currentBannerData?.content}`;
        }
      }
    }
    return "";
  };

  useEffect(() => {
    if (isLoading) {
      let checkResult = checkValidForm();

      let requestData = {
        content: "",
        mobileContent: "",
        position: 0,
      } as AddUsingPOSTRequest;

      if (checkResult.isSuccess) {
        new Promise(async () => {
          if (
            currentBannerData?.file !== null &&
            currentBannerData?.file !== undefined
          ) {
            await uploadImage(currentBannerData?.file).then((blahSrc) => {
              if (!blahSrc.isSuccess) {
                throw blahSrc.message;
              }
              requestData.content = blahSrc.message;
            });
          } else {
            requestData.content =
              currentBannerData?.content === undefined
                ? ""
                : currentBannerData?.content;
          }

          if (
            currentBannerData?.mFile !== null &&
            currentBannerData?.mFile !== undefined
          ) {
            await uploadImage(currentBannerData?.mFile).then((blahSrc) => {
              if (!blahSrc.isSuccess) {
                throw blahSrc.message;
              }
              requestData.mobileContent = blahSrc.message;
            });
          } else {
            requestData.mobileContent =
              currentBannerData?.mobileContent === undefined
                ? ""
                : currentBannerData.mobileContent;
          }

          requestData.name = currentBannerData?.marketingEventName;
          requestData.position = position;

          if (currentBannerData?.bannerId!! <= 0) {
            // new
            requestData.marketingEventId = locationData?.marketingEventId;
            await new MarketingEventBannerControllerApi(genApiConfig())
              .addUsingPOST(requestData)
              .then((addResponse) => {
                if (addResponse.bannerId) {
                  alert("新增成功");
                }
                setIsLoading(false);
                navigate(PATH.BANNER_MANAGEMENT);
              })
              .catch((err) => {
                alert(err);
                setIsLoading(false);
              });
          } else {
            await new MarketingEventBannerControllerApi(genApiConfig())
              .updateUsingPOST({
                bannerId: currentBannerData?.bannerId,
                content: requestData.content,
                mobileContent: requestData.mobileContent,
                position: requestData?.position,
                name: currentBannerData?.marketingEventName,
                marketingEventId: currentBannerData?.marketingEventId,
              } as UpdateUsingPOSTRequest)
              .then((res) => {
                if (res.bannerId) {
                  console.log("ress", res);
                  alert("更新成功");
                }
                setIsLoading(false);
                navigate(PATH.BANNER_MANAGEMENT);
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        });
      } else {
        alert(checkResult.message);
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  return (
    <>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(PATH.BANNER_MANAGEMENT)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>

      <div className={style.RecordContainer}>
        <>
          <div className={style.container}>
            <h4>
              <Trans>新建活動頁面圖像</Trans>
            </h4>

            <div style={{ padding: "8px" }}>
              <div>
                <form className={style.from}>
                  <label>活動頁面名稱</label>

                  <Input
                    styleType={STYLETYPE_MAIN}
                    type="text"
                    disabled={true}
                    value={
                      currentBannerData?.marketingEventName === undefined
                        ? locationData?.marketingEventName
                        : currentBannerData?.marketingEventName
                    }
                    callBack={() => {}}
                  />

                  <div className={style.banner_size_tips}>
                    <div>最低像數要求</div>
                    <div>桌面: 930x350</div>
                    {/* <div>手機: 750x200</div> */}
                  </div>

                  <label>活動頁面圖像</label>
                  <input
                    className={style.inputImage}
                    accept="image/*"
                    type="file"
                    onChange={(value) => readurl(value, false)}
                  />

                  {currentBannerData?.content !== "" &&
                  currentBannerData?.content !== undefined ? (
                    <>
                      <img
                        className={style.imgPreview}
                        id="blah"
                        src={getImagePath(false)}
                        alt=""
                      />
                      <button
                        type="button"
                        className={style.delete_btn}
                        onClick={() =>
                          setCurrentBannerData({
                            ...currentBannerData,
                            content: "",
                          })
                        }
                      >
                        <span
                          role="img"
                          aria-label="delete"
                          className={style.anticon_delete}
                        >
                          <img src={trashIcon}></img>
                        </span>
                        <span>刪除</span>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  <label>活動頁面圖像 (手機)</label>
                  <input
                    className={style.inputImage}
                    accept="image/*"
                    type="file"
                    onChange={(value) => readurl(value, true)}
                  />

                  {currentBannerData?.mobileContent !== "" &&
                  currentBannerData?.mobileContent !== undefined ? (
                    <>
                      <img
                        className={style.imgPreview}
                        id="blah"
                        src={getImagePath(true)}
                        alt=""
                      />
                      <button
                        type="button"
                        className={style.delete_btn}
                        onClick={() =>
                          setCurrentBannerData({
                            ...currentBannerData,
                            mobileContent: "",
                          })
                        }
                      >
                        <span
                          role="img"
                          aria-label="delete"
                          className={style.anticon_delete}
                        >
                          <img src={trashIcon}></img>
                        </span>
                        <span>刪除</span>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}

                  <label style={{ color: "red" }}>
                    * 手機版活動頁面圖像可留空不填
                  </label>
                  <div>
                    <label>順序</label>
                    <Input
                      styleType={STYLETYPE_MAIN}
                      type="number"
                      value={
                        currentBannerData?.position === undefined
                          ? 0
                          : currentBannerData?.position
                      }
                      callBack={(value) =>
                        setCurrentBannerData({
                          ...currentBannerData,
                          position: value,
                        })
                      }
                    ></Input>
                  </div>
                  <div className={style.button_on_right}>
                    {/* <Button styleType={STYLETYPE_MAIN} isLoading={isLoading} callBack={(value) => navigate(-1)}>
                                            返回上一頁
                                        </Button> */}

                    <Button
                      styleType={STYLETYPE_MAIN}
                      isLoading={isLoading}
                      callBack={(value) => submitForm(value)}
                    >
                      <Trans>submit</Trans>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            {/* )} */}
          </div>
        </>
      </div>
    </>
  );
}
