/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coupon,
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import {
    MemberShoppingCart,
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';

/**
 * 
 * @export
 * @interface ShoppingCartCoupon
 */
export interface ShoppingCartCoupon {
    /**
     * 
     * @type {Coupon}
     * @memberof ShoppingCartCoupon
     */
    couponByCouponId?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartCoupon
     */
    couponId?: number;
    /**
     * 
     * @type {MemberShoppingCart}
     * @memberof ShoppingCartCoupon
     */
    shoppingCartByShoppingCartId?: MemberShoppingCart;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartCoupon
     */
    shoppingCartId?: number;
}

export function ShoppingCartCouponFromJSON(json: any): ShoppingCartCoupon {
    return ShoppingCartCouponFromJSONTyped(json, false);
}

export function ShoppingCartCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'couponByCouponId': !exists(json, 'couponByCouponId') ? undefined : CouponFromJSON(json['couponByCouponId']),
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
        'shoppingCartByShoppingCartId': !exists(json, 'shoppingCartByShoppingCartId') ? undefined : MemberShoppingCartFromJSON(json['shoppingCartByShoppingCartId']),
        'shoppingCartId': !exists(json, 'shoppingCartId') ? undefined : json['shoppingCartId'],
    };
}

export function ShoppingCartCouponToJSON(value?: ShoppingCartCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'couponByCouponId': CouponToJSON(value.couponByCouponId),
        'couponId': value.couponId,
        'shoppingCartByShoppingCartId': MemberShoppingCartToJSON(value.shoppingCartByShoppingCartId),
        'shoppingCartId': value.shoppingCartId,
    };
}

