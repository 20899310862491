/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProduct,
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import {
    DiscountProduct,
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import {
    DisplayProductProduct,
    DisplayProductProductFromJSON,
    DisplayProductProductFromJSONTyped,
    DisplayProductProductToJSON,
} from './DisplayProductProduct';
import {
    GroupProduct,
    GroupProductFromJSON,
    GroupProductFromJSONTyped,
    GroupProductToJSON,
} from './GroupProduct';
import {
    OrderProduct,
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import {
    PointRule,
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';
import {
    ProductAward,
    ProductAwardFromJSON,
    ProductAwardFromJSONTyped,
    ProductAwardToJSON,
} from './ProductAward';
import {
    ProductCategory,
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';
import {
    ProductFile,
    ProductFileFromJSON,
    ProductFileFromJSONTyped,
    ProductFileToJSON,
} from './ProductFile';
import {
    ProductLanguage,
    ProductLanguageFromJSON,
    ProductLanguageFromJSONTyped,
    ProductLanguageToJSON,
} from './ProductLanguage';
import {
    ProductPoint,
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';
import {
    ShippingProduct,
    ShippingProductFromJSON,
    ShippingProductFromJSONTyped,
    ShippingProductToJSON,
} from './ShippingProduct';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    aboutAuthor?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    aboutSpeaker?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof Product
     */
    cartProductsByProductId?: Array<CartProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    coverFileName?: string;
    /**
     * 
     * @type {Array<DiscountProduct>}
     * @memberof Product
     */
    discountProductsByProductId?: Array<DiscountProduct>;
    /**
     * 
     * @type {Array<DisplayProductProduct>}
     * @memberof Product
     */
    displayProductProductsByProductId?: Array<DisplayProductProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubPreviewFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubPreviewFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubProdFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubProdFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    episode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    groupProduct?: boolean;
    /**
     * 
     * @type {Array<GroupProduct>}
     * @memberof Product
     */
    groupProductsByProductId?: Array<GroupProduct>;
    /**
     * 
     * @type {Array<GroupProduct>}
     * @memberof Product
     */
    groupProductsByProductId0?: Array<GroupProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    multimediaFileNames?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    onlineReadSametime?: number;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof Product
     */
    orderProductsByProductId?: Array<OrderProduct>;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    pPublishDate?: Date;
    /**
     * 
     * @type {Array<PointRule>}
     * @memberof Product
     */
    pointRulesByProductId?: Array<PointRule>;
    /**
     * 
     * @type {Array<ProductAward>}
     * @memberof Product
     */
    productAwardsByProductId?: Array<ProductAward>;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Product
     */
    productCategoriesByProductId?: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {Array<ProductFile>}
     * @memberof Product
     */
    productFilesByProductId?: Array<ProductFile>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productImage?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productInventory?: number;
    /**
     * 
     * @type {Array<ProductLanguage>}
     * @memberof Product
     */
    productLanguagesByProductId?: Array<ProductLanguage>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productNameEng?: string;
    /**
     * 
     * @type {Array<ProductPoint>}
     * @memberof Product
     */
    productPointsByProductId?: Array<ProductPoint>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {Organization}
     * @memberof Product
     */
    publishHouseByPublishHouseId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    publishHouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    restrictedContent?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sapArticleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    seriesName?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    seriesNumber?: number;
    /**
     * 
     * @type {Array<ShippingProduct>}
     * @memberof Product
     */
    shippingProductsByProductId?: Array<ShippingProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    speaker?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    subscriptionPeriod?: string;
    /**
     * 
     * @type {Organization}
     * @memberof Product
     */
    supplierBySupplierId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    supplierId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    thumbFileNames?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    totalDuration?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    version?: string;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboutAuthor': !exists(json, 'aboutAuthor') ? undefined : json['aboutAuthor'],
        'aboutSpeaker': !exists(json, 'aboutSpeaker') ? undefined : json['aboutSpeaker'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookIsbn': !exists(json, 'bookIsbn') ? undefined : json['bookIsbn'],
        'cartProductsByProductId': !exists(json, 'cartProductsByProductId') ? undefined : ((json['cartProductsByProductId'] as Array<any>).map(CartProductFromJSON)),
        'coverFileName': !exists(json, 'coverFileName') ? undefined : json['coverFileName'],
        'discountProductsByProductId': !exists(json, 'discountProductsByProductId') ? undefined : ((json['discountProductsByProductId'] as Array<any>).map(DiscountProductFromJSON)),
        'displayProductProductsByProductId': !exists(json, 'displayProductProductsByProductId') ? undefined : ((json['displayProductProductsByProductId'] as Array<any>).map(DisplayProductProductFromJSON)),
        'ePubPreviewFileMd5': !exists(json, 'ePubPreviewFileMd5') ? undefined : json['ePubPreviewFileMd5'],
        'ePubPreviewFileName': !exists(json, 'ePubPreviewFileName') ? undefined : json['ePubPreviewFileName'],
        'ePubProdFileMd5': !exists(json, 'ePubProdFileMd5') ? undefined : json['ePubProdFileMd5'],
        'ePubProdFileName': !exists(json, 'ePubProdFileName') ? undefined : json['ePubProdFileName'],
        'episode': !exists(json, 'episode') ? undefined : json['episode'],
        'groupProduct': !exists(json, 'groupProduct') ? undefined : json['groupProduct'],
        'groupProductsByProductId': !exists(json, 'groupProductsByProductId') ? undefined : ((json['groupProductsByProductId'] as Array<any>).map(GroupProductFromJSON)),
        'groupProductsByProductId0': !exists(json, 'groupProductsByProductId_0') ? undefined : ((json['groupProductsByProductId_0'] as Array<any>).map(GroupProductFromJSON)),
        'isGroupProduct': !exists(json, 'isGroupProduct') ? undefined : json['isGroupProduct'],
        'multimediaFileNames': !exists(json, 'multimediaFileNames') ? undefined : json['multimediaFileNames'],
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'onlineReadSametime': !exists(json, 'onlineReadSametime') ? undefined : json['onlineReadSametime'],
        'orderProductsByProductId': !exists(json, 'orderProductsByProductId') ? undefined : ((json['orderProductsByProductId'] as Array<any>).map(OrderProductFromJSON)),
        'pPublishDate': !exists(json, 'pPublishDate') ? undefined : (new Date(json['pPublishDate'])),
        'pointRulesByProductId': !exists(json, 'pointRulesByProductId') ? undefined : ((json['pointRulesByProductId'] as Array<any>).map(PointRuleFromJSON)),
        'productAwardsByProductId': !exists(json, 'productAwardsByProductId') ? undefined : ((json['productAwardsByProductId'] as Array<any>).map(ProductAwardFromJSON)),
        'productCategoriesByProductId': !exists(json, 'productCategoriesByProductId') ? undefined : ((json['productCategoriesByProductId'] as Array<any>).map(ProductCategoryFromJSON)),
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'productDiscountPrice': !exists(json, 'productDiscountPrice') ? undefined : json['productDiscountPrice'],
        'productFilesByProductId': !exists(json, 'productFilesByProductId') ? undefined : ((json['productFilesByProductId'] as Array<any>).map(ProductFileFromJSON)),
        'productHashtag': !exists(json, 'productHashtag') ? undefined : json['productHashtag'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productImage': !exists(json, 'productImage') ? undefined : json['productImage'],
        'productInventory': !exists(json, 'productInventory') ? undefined : json['productInventory'],
        'productLanguagesByProductId': !exists(json, 'productLanguagesByProductId') ? undefined : ((json['productLanguagesByProductId'] as Array<any>).map(ProductLanguageFromJSON)),
        'productNameChi': !exists(json, 'productNameChi') ? undefined : json['productNameChi'],
        'productNameEng': !exists(json, 'productNameEng') ? undefined : json['productNameEng'],
        'productPointsByProductId': !exists(json, 'productPointsByProductId') ? undefined : ((json['productPointsByProductId'] as Array<any>).map(ProductPointFromJSON)),
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'productVolume': !exists(json, 'productVolume') ? undefined : json['productVolume'],
        'productVolumeUnit': !exists(json, 'productVolumeUnit') ? undefined : json['productVolumeUnit'],
        'publishHouseByPublishHouseId': !exists(json, 'publishHouseByPublishHouseId') ? undefined : OrganizationFromJSON(json['publishHouseByPublishHouseId']),
        'publishHouseId': !exists(json, 'publishHouseId') ? undefined : json['publishHouseId'],
        'restrictedContent': !exists(json, 'restrictedContent') ? undefined : json['restrictedContent'],
        'sapArticleNumber': !exists(json, 'sapArticleNumber') ? undefined : json['sapArticleNumber'],
        'seriesName': !exists(json, 'seriesName') ? undefined : json['seriesName'],
        'seriesNumber': !exists(json, 'seriesNumber') ? undefined : json['seriesNumber'],
        'shippingProductsByProductId': !exists(json, 'shippingProductsByProductId') ? undefined : ((json['shippingProductsByProductId'] as Array<any>).map(ShippingProductFromJSON)),
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'speaker': !exists(json, 'speaker') ? undefined : json['speaker'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subscriptionPeriod': !exists(json, 'subscriptionPeriod') ? undefined : json['subscriptionPeriod'],
        'supplierBySupplierId': !exists(json, 'supplierBySupplierId') ? undefined : OrganizationFromJSON(json['supplierBySupplierId']),
        'supplierId': !exists(json, 'supplierId') ? undefined : json['supplierId'],
        'thumbFileNames': !exists(json, 'thumbFileNames') ? undefined : json['thumbFileNames'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aboutAuthor': value.aboutAuthor,
        'aboutSpeaker': value.aboutSpeaker,
        'author': value.author,
        'bookIsbn': value.bookIsbn,
        'cartProductsByProductId': value.cartProductsByProductId === undefined ? undefined : ((value.cartProductsByProductId as Array<any>).map(CartProductToJSON)),
        'coverFileName': value.coverFileName,
        'discountProductsByProductId': value.discountProductsByProductId === undefined ? undefined : ((value.discountProductsByProductId as Array<any>).map(DiscountProductToJSON)),
        'displayProductProductsByProductId': value.displayProductProductsByProductId === undefined ? undefined : ((value.displayProductProductsByProductId as Array<any>).map(DisplayProductProductToJSON)),
        'ePubPreviewFileMd5': value.ePubPreviewFileMd5,
        'ePubPreviewFileName': value.ePubPreviewFileName,
        'ePubProdFileMd5': value.ePubProdFileMd5,
        'ePubProdFileName': value.ePubProdFileName,
        'episode': value.episode,
        'groupProduct': value.groupProduct,
        'groupProductsByProductId': value.groupProductsByProductId === undefined ? undefined : ((value.groupProductsByProductId as Array<any>).map(GroupProductToJSON)),
        'groupProductsByProductId_0': value.groupProductsByProductId0 === undefined ? undefined : ((value.groupProductsByProductId0 as Array<any>).map(GroupProductToJSON)),
        'isGroupProduct': value.isGroupProduct,
        'multimediaFileNames': value.multimediaFileNames,
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'onlineReadSametime': value.onlineReadSametime,
        'orderProductsByProductId': value.orderProductsByProductId === undefined ? undefined : ((value.orderProductsByProductId as Array<any>).map(OrderProductToJSON)),
        'pPublishDate': value.pPublishDate === undefined ? undefined : (value.pPublishDate.toISOString()),
        'pointRulesByProductId': value.pointRulesByProductId === undefined ? undefined : ((value.pointRulesByProductId as Array<any>).map(PointRuleToJSON)),
        'productAwardsByProductId': value.productAwardsByProductId === undefined ? undefined : ((value.productAwardsByProductId as Array<any>).map(ProductAwardToJSON)),
        'productCategoriesByProductId': value.productCategoriesByProductId === undefined ? undefined : ((value.productCategoriesByProductId as Array<any>).map(ProductCategoryToJSON)),
        'productDescription': value.productDescription,
        'productDiscountPrice': value.productDiscountPrice,
        'productFilesByProductId': value.productFilesByProductId === undefined ? undefined : ((value.productFilesByProductId as Array<any>).map(ProductFileToJSON)),
        'productHashtag': value.productHashtag,
        'productId': value.productId,
        'productImage': value.productImage,
        'productInventory': value.productInventory,
        'productLanguagesByProductId': value.productLanguagesByProductId === undefined ? undefined : ((value.productLanguagesByProductId as Array<any>).map(ProductLanguageToJSON)),
        'productNameChi': value.productNameChi,
        'productNameEng': value.productNameEng,
        'productPointsByProductId': value.productPointsByProductId === undefined ? undefined : ((value.productPointsByProductId as Array<any>).map(ProductPointToJSON)),
        'productPrice': value.productPrice,
        'productVolume': value.productVolume,
        'productVolumeUnit': value.productVolumeUnit,
        'publishHouseByPublishHouseId': OrganizationToJSON(value.publishHouseByPublishHouseId),
        'publishHouseId': value.publishHouseId,
        'restrictedContent': value.restrictedContent,
        'sapArticleNumber': value.sapArticleNumber,
        'seriesName': value.seriesName,
        'seriesNumber': value.seriesNumber,
        'shippingProductsByProductId': value.shippingProductsByProductId === undefined ? undefined : ((value.shippingProductsByProductId as Array<any>).map(ShippingProductToJSON)),
        'shortDescription': value.shortDescription,
        'speaker': value.speaker,
        'status': value.status,
        'subscriptionPeriod': value.subscriptionPeriod,
        'supplierBySupplierId': OrganizationToJSON(value.supplierBySupplierId),
        'supplierId': value.supplierId,
        'thumbFileNames': value.thumbFileNames,
        'totalDuration': value.totalDuration,
        'version': value.version,
    };
}

