/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleHashtag,
    ArticleHashtagFromJSON,
    ArticleHashtagFromJSONTyped,
    ArticleHashtagToJSON,
} from './ArticleHashtag';
import {
    DisplayProductHashtag,
    DisplayProductHashtagFromJSON,
    DisplayProductHashtagFromJSONTyped,
    DisplayProductHashtagToJSON,
} from './DisplayProductHashtag';

/**
 * 
 * @export
 * @interface Hashtag
 */
export interface Hashtag {
    /**
     * 
     * @type {Array<ArticleHashtag>}
     * @memberof Hashtag
     */
    articleHashtagByHashtagId?: Array<ArticleHashtag>;
    /**
     * 
     * @type {Array<DisplayProductHashtag>}
     * @memberof Hashtag
     */
    displayProductHashtagByHashtagId?: Array<DisplayProductHashtag>;
    /**
     * 
     * @type {number}
     * @memberof Hashtag
     */
    hashtagId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hashtag
     */
    hashtagName?: string;
}

export function HashtagFromJSON(json: any): Hashtag {
    return HashtagFromJSONTyped(json, false);
}

export function HashtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hashtag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleHashtagByHashtagId': !exists(json, 'articleHashtagByHashtagId') ? undefined : ((json['articleHashtagByHashtagId'] as Array<any>).map(ArticleHashtagFromJSON)),
        'displayProductHashtagByHashtagId': !exists(json, 'displayProductHashtagByHashtagId') ? undefined : ((json['displayProductHashtagByHashtagId'] as Array<any>).map(DisplayProductHashtagFromJSON)),
        'hashtagId': !exists(json, 'hashtagId') ? undefined : json['hashtagId'],
        'hashtagName': !exists(json, 'hashtagName') ? undefined : json['hashtagName'],
    };
}

export function HashtagToJSON(value?: Hashtag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleHashtagByHashtagId': value.articleHashtagByHashtagId === undefined ? undefined : ((value.articleHashtagByHashtagId as Array<any>).map(ArticleHashtagToJSON)),
        'displayProductHashtagByHashtagId': value.displayProductHashtagByHashtagId === undefined ? undefined : ((value.displayProductHashtagByHashtagId as Array<any>).map(DisplayProductHashtagToJSON)),
        'hashtagId': value.hashtagId,
        'hashtagName': value.hashtagName,
    };
}

