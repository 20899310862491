/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayProductArticle,
    DisplayProductArticleFromJSON,
    DisplayProductArticleFromJSONTyped,
    DisplayProductArticleToJSON,
} from './DisplayProductArticle';
import {
    DisplayProductCategory,
    DisplayProductCategoryFromJSON,
    DisplayProductCategoryFromJSONTyped,
    DisplayProductCategoryToJSON,
} from './DisplayProductCategory';
import {
    DisplayProductHashtag,
    DisplayProductHashtagFromJSON,
    DisplayProductHashtagFromJSONTyped,
    DisplayProductHashtagToJSON,
} from './DisplayProductHashtag';
import {
    DisplayProductProduct,
    DisplayProductProductFromJSON,
    DisplayProductProductFromJSONTyped,
    DisplayProductProductToJSON,
} from './DisplayProductProduct';

/**
 * 
 * @export
 * @interface DisplayProduct
 */
export interface DisplayProduct {
    /**
     * 
     * @type {Array<DisplayProductArticle>}
     * @memberof DisplayProduct
     */
    displayProductArticalsByDisplayProductId?: Array<DisplayProductArticle>;
    /**
     * 
     * @type {Array<DisplayProductCategory>}
     * @memberof DisplayProduct
     */
    displayProductCategoriesByDisplayProductId?: Array<DisplayProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionFeature?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionIngredient?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionUsage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionUseCount?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductDiscountPrice?: number;
    /**
     * 
     * @type {Array<DisplayProductHashtag>}
     * @memberof DisplayProduct
     */
    displayProductHashtagsByDisplayProductId?: Array<DisplayProductHashtag>;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductImage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductPrice?: number;
    /**
     * 
     * @type {Array<DisplayProductProduct>}
     * @memberof DisplayProduct
     */
    displayProductProductsByDisplayProductId?: Array<DisplayProductProduct>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    functionalIcon?: string;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProduct
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProduct
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    status?: string;
}

export function DisplayProductFromJSON(json: any): DisplayProduct {
    return DisplayProductFromJSONTyped(json, false);
}

export function DisplayProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayProductArticalsByDisplayProductId': !exists(json, 'displayProductArticalsByDisplayProductId') ? undefined : ((json['displayProductArticalsByDisplayProductId'] as Array<any>).map(DisplayProductArticleFromJSON)),
        'displayProductCategoriesByDisplayProductId': !exists(json, 'displayProductCategoriesByDisplayProductId') ? undefined : ((json['displayProductCategoriesByDisplayProductId'] as Array<any>).map(DisplayProductCategoryFromJSON)),
        'displayProductDescriptionDetail': !exists(json, 'displayProductDescriptionDetail') ? undefined : json['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': !exists(json, 'displayProductDescriptionFeature') ? undefined : json['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': !exists(json, 'displayProductDescriptionIngredient') ? undefined : json['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': !exists(json, 'displayProductDescriptionTarget') ? undefined : json['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': !exists(json, 'displayProductDescriptionUsage') ? undefined : json['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': !exists(json, 'displayProductDescriptionUseCount') ? undefined : json['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': !exists(json, 'displayProductDiscountPrice') ? undefined : json['displayProductDiscountPrice'],
        'displayProductHashtagsByDisplayProductId': !exists(json, 'displayProductHashtagsByDisplayProductId') ? undefined : ((json['displayProductHashtagsByDisplayProductId'] as Array<any>).map(DisplayProductHashtagFromJSON)),
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'displayProductImage': !exists(json, 'displayProductImage') ? undefined : json['displayProductImage'],
        'displayProductNameChi': !exists(json, 'displayProductNameChi') ? undefined : json['displayProductNameChi'],
        'displayProductNameEng': !exists(json, 'displayProductNameEng') ? undefined : json['displayProductNameEng'],
        'displayProductPrice': !exists(json, 'displayProductPrice') ? undefined : json['displayProductPrice'],
        'displayProductProductsByDisplayProductId': !exists(json, 'displayProductProductsByDisplayProductId') ? undefined : ((json['displayProductProductsByDisplayProductId'] as Array<any>).map(DisplayProductProductFromJSON)),
        'functionalIcon': !exists(json, 'functionalIcon') ? undefined : json['functionalIcon'],
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function DisplayProductToJSON(value?: DisplayProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayProductArticalsByDisplayProductId': value.displayProductArticalsByDisplayProductId === undefined ? undefined : ((value.displayProductArticalsByDisplayProductId as Array<any>).map(DisplayProductArticleToJSON)),
        'displayProductCategoriesByDisplayProductId': value.displayProductCategoriesByDisplayProductId === undefined ? undefined : ((value.displayProductCategoriesByDisplayProductId as Array<any>).map(DisplayProductCategoryToJSON)),
        'displayProductDescriptionDetail': value.displayProductDescriptionDetail,
        'displayProductDescriptionFeature': value.displayProductDescriptionFeature,
        'displayProductDescriptionIngredient': value.displayProductDescriptionIngredient,
        'displayProductDescriptionTarget': value.displayProductDescriptionTarget,
        'displayProductDescriptionUsage': value.displayProductDescriptionUsage,
        'displayProductDescriptionUseCount': value.displayProductDescriptionUseCount,
        'displayProductDiscountPrice': value.displayProductDiscountPrice,
        'displayProductHashtagsByDisplayProductId': value.displayProductHashtagsByDisplayProductId === undefined ? undefined : ((value.displayProductHashtagsByDisplayProductId as Array<any>).map(DisplayProductHashtagToJSON)),
        'displayProductId': value.displayProductId,
        'displayProductImage': value.displayProductImage,
        'displayProductNameChi': value.displayProductNameChi,
        'displayProductNameEng': value.displayProductNameEng,
        'displayProductPrice': value.displayProductPrice,
        'displayProductProductsByDisplayProductId': value.displayProductProductsByDisplayProductId === undefined ? undefined : ((value.displayProductProductsByDisplayProductId as Array<any>).map(DisplayProductProductToJSON)),
        'functionalIcon': value.functionalIcon,
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'order': value.order,
        'status': value.status,
    };
}

