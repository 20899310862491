/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductDtoLite,
    ProductDtoLiteFromJSON,
    ProductDtoLiteFromJSONTyped,
    ProductDtoLiteToJSON,
} from './ProductDtoLite';

/**
 * 
 * @export
 * @interface ProductPointDto
 */
export interface ProductPointDto {
    /**
     * 
     * @type {number}
     * @memberof ProductPointDto
     */
    money?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDto
     */
    point?: number;
    /**
     * 
     * @type {ProductDtoLite}
     * @memberof ProductPointDto
     */
    product?: ProductDtoLite;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDto
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDto
     */
    productPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPointDto
     */
    status?: string;
}

export function ProductPointDtoFromJSON(json: any): ProductPointDto {
    return ProductPointDtoFromJSONTyped(json, false);
}

export function ProductPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'money': !exists(json, 'money') ? undefined : json['money'],
        'point': !exists(json, 'point') ? undefined : json['point'],
        'product': !exists(json, 'product') ? undefined : ProductDtoLiteFromJSON(json['product']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPointId': !exists(json, 'productPointId') ? undefined : json['productPointId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ProductPointDtoToJSON(value?: ProductPointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'money': value.money,
        'point': value.point,
        'product': ProductDtoLiteToJSON(value.product),
        'productId': value.productId,
        'productPointId': value.productPointId,
        'status': value.status,
    };
}

