import PropTypes from "prop-types";

import { Component } from "react";
import style from "./WebMenu.module.css";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function WebMenu(props: any) {
    const { i18n } = useTranslation();
    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });

    const handleClick = () => {
        props.callBack(false);
    };

    const handleblur = () => {
        props.callBack(props.onBlur);
    };
    return (
        <>
            <div className={style.megamenu} style={props.show ? { display: "inline-block" } : { display: "none" }} onBlur={handleblur} tabIndex={0}>
                <div className={style.row}>
                    {props.content &&
                        props.content.map((item: any, idx: any) => (
                            <ul className={style.col}>
                                <li className={style.title}>
                                    <Link to={"category/" + item.categoryId.toString() + "&" + item.categoryName} onClick={handleClick}>
                                        {i18n.language === "cn" ? converter(item.categoryName) : item.categoryName}
                                    </Link>
                                    <div className={style.borderLine}></div>
                                </li>
                                {item.subCategories &&
                                    item.subCategories.map((sub: any, idx: any) => (
                                        <li className={style.lirow}>
                                            <span className={style.chevron_right}></span>
                                            <Link to={"category/" + sub.categoryId.toString() + "&" + sub.categoryName} onClick={handleClick}>
                                                {i18n.language === "cn" ? converter(sub.categoryName) : sub.categoryName}{" "}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        ))}
                </div>
            </div>
        </>
    );
}
