import { CKEditor } from "ckeditor4-react";
// CKEditor.editorUrl = `${process.env.PUBLIC_URL}/lib/ckeditor/ckeditor.js`;

const config = {
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    "/",
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: [
        "list",
        "indent",
        "blocks",
        "align",
        "bidi",
        "paragraph",
        "SpecialChar",
        "Bold",
        "Italic",
        "Strike",
        "Underline",
      ],
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    "/",
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],
  plugins:
    "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,xml,ajax,templates,menu,contextmenu,copyformatting,div,editorplaceholder,resize,elementspath,enterkey,entities,exportpdf,popup,filetools,filebrowser,find,floatingspace,listblock,richcombo,font,fakeobjects,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastetools,pastefromgdocs,pastefromlibreoffice,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage",
  removeButtons:
    "Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Save,NewPage,Preview,Print,Templates,Scayt,Flash,Maximize,About",
  removePlugins: "ExportPDF",
  height: "400px",
  filebrowserImageUploadUrl: `${process.env.REACT_APP_BACKEND}/file/fileupload/ckeditor?type=image`,
  filebrowserUploadUrl: `${process.env.REACT_APP_BACKEND}/file/fileupload/ckeditor?type=any`,
  filebrowserUploadMethod: "xhr",
  fileTools_requestHeaders: {
    "X-Requested-With": "XMLHttpRequest",
    "X-Frame-Options": "sameorigin",
    // "Custom-Header": "header value",
  },
  //extraPlugins: "youtube,html5video,widget,widgetselection,clipboard,lineutils",
  // extraPlugins: "lineheight",
  allowedContent: true,
  youtube_responsive: true,
  youtube_related: false,
  format_tags: "p;h1;h2;h3;pre",
  font_names:
    "新細明體/Noto Serif TC,serif;標楷體/cwTeXKai, serif;圓體/cwtexyen,黑體/Noto Sans TC, sans-serif;Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, cursive",
  contentsCss: [
    "https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Noto+Serif+TC&family=Roboto:wght@300;400;500;700&display=swap",
    "https://fonts.googleapis.com/earlyaccess/cwtexkai.css",
    "https://fonts.googleapis.com/earlyaccess/cwtexyen.css",
  ],
};

export default function CKEditorProxy(props) {
  // console.log("ck", typeof props.initData);
  // console.log("ck", props.initData);
  return (
    <>
      <CKEditor {...props} config={config} />
      {/* {props.initData !== undefined &&
      props.initData !== null &&
      props.initData.length > 0 ? (
        <>
          <CKEditor {...props} config={config} />
        </>
      ) : (
        <></>
      )} */}
    </>
  );
}
