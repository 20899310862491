/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageRequestModel
 */
export interface LanguageRequestModel {
    /**
     * 
     * @type {boolean}
     * @memberof LanguageRequestModel
     */
    _delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LanguageRequestModel
     */
    languageName?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageRequestModel
     */
    seq?: number;
}

export function LanguageRequestModelFromJSON(json: any): LanguageRequestModel {
    return LanguageRequestModelFromJSONTyped(json, false);
}

export function LanguageRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
        'languageName': !exists(json, 'languageName') ? undefined : json['languageName'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
    };
}

export function LanguageRequestModelToJSON(value?: LanguageRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete': value._delete,
        'languageName': value.languageName,
        'mongoId': value.mongoId,
        'seq': value.seq,
    };
}

