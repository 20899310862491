/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisplayProduct,
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';
import {
    Hashtag,
    HashtagFromJSON,
    HashtagFromJSONTyped,
    HashtagToJSON,
} from './Hashtag';

/**
 * 
 * @export
 * @interface DisplayProductHashtag
 */
export interface DisplayProductHashtag {
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductHashtag
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductHashtag
     */
    displayProductId?: number;
    /**
     * 
     * @type {Hashtag}
     * @memberof DisplayProductHashtag
     */
    hashtagByHashtagId?: Hashtag;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductHashtag
     */
    hashtagId?: number;
}

export function DisplayProductHashtagFromJSON(json: any): DisplayProductHashtag {
    return DisplayProductHashtagFromJSONTyped(json, false);
}

export function DisplayProductHashtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductHashtag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayProductByDisplayProductId': !exists(json, 'displayProductByDisplayProductId') ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'hashtagByHashtagId': !exists(json, 'hashtagByHashtagId') ? undefined : HashtagFromJSON(json['hashtagByHashtagId']),
        'hashtagId': !exists(json, 'hashtagId') ? undefined : json['hashtagId'],
    };
}

export function DisplayProductHashtagToJSON(value?: DisplayProductHashtag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayProductByDisplayProductId': DisplayProductToJSON(value.displayProductByDisplayProductId),
        'displayProductId': value.displayProductId,
        'hashtagByHashtagId': HashtagToJSON(value.hashtagByHashtagId),
        'hashtagId': value.hashtagId,
    };
}

