/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BookReportData,
    BookReportDataFromJSON,
    BookReportDataFromJSONTyped,
    BookReportDataToJSON,
} from './BookReportData';

/**
 * 
 * @export
 * @interface BookReportResponseModel
 */
export interface BookReportResponseModel {
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    borrowTimeTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    cantBorrowTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    chapterTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    contentTotal?: number;
    /**
     * 
     * @type {Array<BookReportData>}
     * @memberof BookReportResponseModel
     */
    data?: Array<BookReportData>;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    mobileBorrowTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    pageTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    previewTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    readOnlineTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BookReportResponseModel
     */
    webBorrowTotal?: number;
}

export function BookReportResponseModelFromJSON(json: any): BookReportResponseModel {
    return BookReportResponseModelFromJSONTyped(json, false);
}

export function BookReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrowTimeTotal': !exists(json, 'borrowTimeTotal') ? undefined : json['borrowTimeTotal'],
        'cantBorrowTotal': !exists(json, 'cantBorrowTotal') ? undefined : json['cantBorrowTotal'],
        'chapterTotal': !exists(json, 'chapterTotal') ? undefined : json['chapterTotal'],
        'contentTotal': !exists(json, 'contentTotal') ? undefined : json['contentTotal'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(BookReportDataFromJSON)),
        'mobileBorrowTotal': !exists(json, 'mobileBorrowTotal') ? undefined : json['mobileBorrowTotal'],
        'pageTotal': !exists(json, 'pageTotal') ? undefined : json['pageTotal'],
        'previewTotal': !exists(json, 'previewTotal') ? undefined : json['previewTotal'],
        'readOnlineTotal': !exists(json, 'readOnlineTotal') ? undefined : json['readOnlineTotal'],
        'webBorrowTotal': !exists(json, 'webBorrowTotal') ? undefined : json['webBorrowTotal'],
    };
}

export function BookReportResponseModelToJSON(value?: BookReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrowTimeTotal': value.borrowTimeTotal,
        'cantBorrowTotal': value.cantBorrowTotal,
        'chapterTotal': value.chapterTotal,
        'contentTotal': value.contentTotal,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(BookReportDataToJSON)),
        'mobileBorrowTotal': value.mobileBorrowTotal,
        'pageTotal': value.pageTotal,
        'previewTotal': value.previewTotal,
        'readOnlineTotal': value.readOnlineTotal,
        'webBorrowTotal': value.webBorrowTotal,
    };
}

