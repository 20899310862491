/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeGroupDto
 */
export interface AgeGroupDto {
    /**
     * 
     * @type {number}
     * @memberof AgeGroupDto
     */
    ageGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    ageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    status?: string;
}

export function AgeGroupDtoFromJSON(json: any): AgeGroupDto {
    return AgeGroupDtoFromJSONTyped(json, false);
}

export function AgeGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeGroupDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageGroupId': !exists(json, 'ageGroupId') ? undefined : json['ageGroupId'],
        'ageGroupName': !exists(json, 'ageGroupName') ? undefined : json['ageGroupName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function AgeGroupDtoToJSON(value?: AgeGroupDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageGroupId': value.ageGroupId,
        'ageGroupName': value.ageGroupName,
        'status': value.status,
    };
}

