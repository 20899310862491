const initState = {
  headerItems: []
};
export function headerItems(
  state = initState,
  action
) {
  switch (action.type) {

    case "HANDLE_HEADERITEMS":
      return {
        ...state,
        headerItems: action.payload,
      };


    default:
      return state;
  }
}
