import { isElectron } from "react-device-detect";
import { CouponToJSON } from "../../../fetcher";
import StorageUtil from "./storageUtil";

export const openExternalUrl = (url) => {
  console.log("openExternalUrl", url);
  console.log("openExternalUrl", isElectron);

  var sUsrAg = navigator.userAgent;

  if (sUsrAg.includes("suepiosapp") || sUsrAg.includes("suepandroidapp")) {
    window.open = window.cordova.InAppBrowser.open;
    window.cordova.InAppBrowser.open(url);
  } else {
    window.open(url);
  }

  // isElectron ? (
  //   StorageUtil.getReaderConfig("isUseBuiltIn") === "yes" ? (
  //     window.open(url)
  //   ) : (

  //     window.require("electron").shell.openExternal(url)
  //   )
  // ) : (
  //   <></>
  // ); //window.open(url);
};
