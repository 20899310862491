/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingEventArticle,
    MarketingEventArticleFromJSON,
    MarketingEventArticleFromJSONTyped,
    MarketingEventArticleToJSON,
} from './MarketingEventArticle';
import {
    MarketingEventDiscount,
    MarketingEventDiscountFromJSON,
    MarketingEventDiscountFromJSONTyped,
    MarketingEventDiscountToJSON,
} from './MarketingEventDiscount';
import {
    MarketingEventMember,
    MarketingEventMemberFromJSON,
    MarketingEventMemberFromJSONTyped,
    MarketingEventMemberToJSON,
} from './MarketingEventMember';
import {
    MarketingEventMemberLevel,
    MarketingEventMemberLevelFromJSON,
    MarketingEventMemberLevelFromJSONTyped,
    MarketingEventMemberLevelToJSON,
} from './MarketingEventMemberLevel';
import {
    OrderMarketingEvent,
    OrderMarketingEventFromJSON,
    OrderMarketingEventFromJSONTyped,
    OrderMarketingEventToJSON,
} from './OrderMarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEvent
 */
export interface MarketingEvent {
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    bannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    bannerLinkTo?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    bannerPosition?: number;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    isMegaSale?: string;
    /**
     * 
     * @type {Array<MarketingEventArticle>}
     * @memberof MarketingEvent
     */
    marketingEventArticlesByMarketingEventId?: Array<MarketingEventArticle>;
    /**
     * 
     * @type {Array<MarketingEventDiscount>}
     * @memberof MarketingEvent
     */
    marketingEventDiscountsByMarketingEventId?: Array<MarketingEventDiscount>;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Array<MarketingEventMemberLevel>}
     * @memberof MarketingEvent
     */
    marketingEventMemberLevelsByMarketingEventId?: Array<MarketingEventMemberLevel>;
    /**
     * 
     * @type {Array<MarketingEventMember>}
     * @memberof MarketingEvent
     */
    marketingEventMembersByMarketingEventId?: Array<MarketingEventMember>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {Array<OrderMarketingEvent>}
     * @memberof MarketingEvent
     */
    orderMarketingEventsByMarketingEventId?: Array<OrderMarketingEvent>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    promotionCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    status?: string;
}

export function MarketingEventFromJSON(json: any): MarketingEvent {
    return MarketingEventFromJSONTyped(json, false);
}

export function MarketingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerContent': !exists(json, 'bannerContent') ? undefined : json['bannerContent'],
        'bannerLinkTo': !exists(json, 'bannerLinkTo') ? undefined : json['bannerLinkTo'],
        'bannerPosition': !exists(json, 'bannerPosition') ? undefined : json['bannerPosition'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': !exists(json, 'expireBannerContent') ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': !exists(json, 'expireBannerEndDate') ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': !exists(json, 'isMegaSale') ? undefined : json['isMegaSale'],
        'marketingEventArticlesByMarketingEventId': !exists(json, 'marketingEventArticlesByMarketingEventId') ? undefined : ((json['marketingEventArticlesByMarketingEventId'] as Array<any>).map(MarketingEventArticleFromJSON)),
        'marketingEventDiscountsByMarketingEventId': !exists(json, 'marketingEventDiscountsByMarketingEventId') ? undefined : ((json['marketingEventDiscountsByMarketingEventId'] as Array<any>).map(MarketingEventDiscountFromJSON)),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'marketingEventMemberLevelsByMarketingEventId': !exists(json, 'marketingEventMemberLevelsByMarketingEventId') ? undefined : ((json['marketingEventMemberLevelsByMarketingEventId'] as Array<any>).map(MarketingEventMemberLevelFromJSON)),
        'marketingEventMembersByMarketingEventId': !exists(json, 'marketingEventMembersByMarketingEventId') ? undefined : ((json['marketingEventMembersByMarketingEventId'] as Array<any>).map(MarketingEventMemberFromJSON)),
        'marketingEventName': !exists(json, 'marketingEventName') ? undefined : json['marketingEventName'],
        'marketingEventPriority': !exists(json, 'marketingEventPriority') ? undefined : json['marketingEventPriority'],
        'mobileBannerContent': !exists(json, 'mobileBannerContent') ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': !exists(json, 'mobileExpireBannerContent') ? undefined : json['mobileExpireBannerContent'],
        'orderMarketingEventsByMarketingEventId': !exists(json, 'orderMarketingEventsByMarketingEventId') ? undefined : ((json['orderMarketingEventsByMarketingEventId'] as Array<any>).map(OrderMarketingEventFromJSON)),
        'promotionCode': !exists(json, 'promotionCode') ? undefined : json['promotionCode'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MarketingEventToJSON(value?: MarketingEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerContent': value.bannerContent,
        'bannerLinkTo': value.bannerLinkTo,
        'bannerPosition': value.bannerPosition,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'expireBannerContent': value.expireBannerContent,
        'expireBannerEndDate': value.expireBannerEndDate === undefined ? undefined : (value.expireBannerEndDate.toISOString()),
        'isMegaSale': value.isMegaSale,
        'marketingEventArticlesByMarketingEventId': value.marketingEventArticlesByMarketingEventId === undefined ? undefined : ((value.marketingEventArticlesByMarketingEventId as Array<any>).map(MarketingEventArticleToJSON)),
        'marketingEventDiscountsByMarketingEventId': value.marketingEventDiscountsByMarketingEventId === undefined ? undefined : ((value.marketingEventDiscountsByMarketingEventId as Array<any>).map(MarketingEventDiscountToJSON)),
        'marketingEventId': value.marketingEventId,
        'marketingEventMemberLevelsByMarketingEventId': value.marketingEventMemberLevelsByMarketingEventId === undefined ? undefined : ((value.marketingEventMemberLevelsByMarketingEventId as Array<any>).map(MarketingEventMemberLevelToJSON)),
        'marketingEventMembersByMarketingEventId': value.marketingEventMembersByMarketingEventId === undefined ? undefined : ((value.marketingEventMembersByMarketingEventId as Array<any>).map(MarketingEventMemberToJSON)),
        'marketingEventName': value.marketingEventName,
        'marketingEventPriority': value.marketingEventPriority,
        'mobileBannerContent': value.mobileBannerContent,
        'mobileExpireBannerContent': value.mobileExpireBannerContent,
        'orderMarketingEventsByMarketingEventId': value.orderMarketingEventsByMarketingEventId === undefined ? undefined : ((value.orderMarketingEventsByMarketingEventId as Array<any>).map(OrderMarketingEventToJSON)),
        'promotionCode': value.promotionCode,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
    };
}

