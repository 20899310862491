/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeGroup
 */
export interface AgeGroup {
    /**
     * 
     * @type {number}
     * @memberof AgeGroup
     */
    ageGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof AgeGroup
     */
    ageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeGroup
     */
    status?: string;
}

export function AgeGroupFromJSON(json: any): AgeGroup {
    return AgeGroupFromJSONTyped(json, false);
}

export function AgeGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageGroupId': !exists(json, 'ageGroupId') ? undefined : json['ageGroupId'],
        'ageGroupName': !exists(json, 'ageGroupName') ? undefined : json['ageGroupName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function AgeGroupToJSON(value?: AgeGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageGroupId': value.ageGroupId,
        'ageGroupName': value.ageGroupName,
        'status': value.status,
    };
}

