// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudentView_dialogButtonContainer__ZRABz{\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 15px;\n  }", "",{"version":3,"sources":["webpack://./src/layout/Admin/StudentView.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":[".dialogButtonContainer{\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogButtonContainer": "StudentView_dialogButtonContainer__ZRABz"
};
export default ___CSS_LOADER_EXPORT___;
