/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface MemberFavorite
 */
export interface MemberFavorite {
    /**
     * 
     * @type {Member}
     * @memberof MemberFavorite
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof MemberFavorite
     */
    memberId?: number;
    /**
     * 
     * @type {Product}
     * @memberof MemberFavorite
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof MemberFavorite
     */
    productId?: number;
}

export function MemberFavoriteFromJSON(json: any): MemberFavorite {
    return MemberFavoriteFromJSONTyped(json, false);
}

export function MemberFavoriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberFavorite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function MemberFavoriteToJSON(value?: MemberFavorite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
    };
}

