/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorrowViewRecordReportRequestModel
 */
export interface BorrowViewRecordReportRequestModel {
    /**
     * 
     * @type {string}
     * @memberof BorrowViewRecordReportRequestModel
     */
    bookType?: BorrowViewRecordReportRequestModelBookTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorrowViewRecordReportRequestModel
     */
    brn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BorrowViewRecordReportRequestModel
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportRequestModel
     */
    from?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorrowViewRecordReportRequestModel
     */
    isbn?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BorrowViewRecordReportRequestModel
     */
    limit?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorrowViewRecordReportRequestModel
     */
    reportTypes?: Array<BorrowViewRecordReportRequestModelReportTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof BorrowViewRecordReportRequestModel
     */
    startDate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BorrowViewRecordReportRequestModel
     */
    userName?: Array<string>;
}


/**
 * @export
 */
export const BorrowViewRecordReportRequestModelBookTypeEnum = {
    All: 'ALL',
    Audio: 'AUDIO',
    Ebook: 'EBOOK',
    Magazine: 'MAGAZINE'
} as const;
export type BorrowViewRecordReportRequestModelBookTypeEnum = typeof BorrowViewRecordReportRequestModelBookTypeEnum[keyof typeof BorrowViewRecordReportRequestModelBookTypeEnum];

/**
 * @export
 */
export const BorrowViewRecordReportRequestModelReportTypesEnum = {
    BorrowTime: 'BORROW_TIME',
    CantBorrow: 'CANT_BORROW',
    Chapter: 'CHAPTER',
    Content: 'CONTENT',
    MobileBorrow: 'MOBILE_BORROW',
    Preview: 'PREVIEW',
    ReadOnline: 'READ_ONLINE',
    WebBorrow: 'WEB_BORROW'
} as const;
export type BorrowViewRecordReportRequestModelReportTypesEnum = typeof BorrowViewRecordReportRequestModelReportTypesEnum[keyof typeof BorrowViewRecordReportRequestModelReportTypesEnum];


export function BorrowViewRecordReportRequestModelFromJSON(json: any): BorrowViewRecordReportRequestModel {
    return BorrowViewRecordReportRequestModelFromJSONTyped(json, false);
}

export function BorrowViewRecordReportRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowViewRecordReportRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookType': !exists(json, 'bookType') ? undefined : json['bookType'],
        'brn': !exists(json, 'brn') ? undefined : json['brn'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'isbn': !exists(json, 'isbn') ? undefined : json['isbn'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'reportTypes': !exists(json, 'reportTypes') ? undefined : json['reportTypes'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
    };
}

export function BorrowViewRecordReportRequestModelToJSON(value?: BorrowViewRecordReportRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookType': value.bookType,
        'brn': value.brn,
        'endDate': value.endDate,
        'from': value.from,
        'isbn': value.isbn,
        'limit': value.limit,
        'reportTypes': value.reportTypes,
        'startDate': value.startDate,
        'userName': value.userName,
    };
}

