/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface DiscountProduct
 */
export interface DiscountProduct {
    /**
     * 
     * @type {Discount}
     * @memberof DiscountProduct
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    discountId?: number;
    /**
     * 
     * @type {Product}
     * @memberof DiscountProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    productId?: number;
}

export function DiscountProductFromJSON(json: any): DiscountProduct {
    return DiscountProductFromJSONTyped(json, false);
}

export function DiscountProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function DiscountProductToJSON(value?: DiscountProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
    };
}

