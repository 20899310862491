/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface Borrow
 */
export interface Borrow {
    /**
     * 
     * @type {Date}
     * @memberof Borrow
     */
    borrowDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    borrowId?: number;
    /**
     * 
     * @type {Category}
     * @memberof Borrow
     */
    categoryByCategoryId?: Category;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Borrow
     */
    isMobile?: string;
    /**
     * 
     * @type {Member}
     * @memberof Borrow
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    memberId?: number;
    /**
     * 
     * @type {Product}
     * @memberof Borrow
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    resumeBorrowTime?: number;
    /**
     * 
     * @type {Date}
     * @memberof Borrow
     */
    returnDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Borrow
     */
    status?: number;
}

export function BorrowFromJSON(json: any): Borrow {
    return BorrowFromJSONTyped(json, false);
}

export function BorrowFromJSONTyped(json: any, ignoreDiscriminator: boolean): Borrow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrowDate': !exists(json, 'borrowDate') ? undefined : (new Date(json['borrowDate'])),
        'borrowId': !exists(json, 'borrowId') ? undefined : json['borrowId'],
        'categoryByCategoryId': !exists(json, 'categoryByCategoryId') ? undefined : CategoryFromJSON(json['categoryByCategoryId']),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'isMobile': !exists(json, 'isMobile') ? undefined : json['isMobile'],
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'resumeBorrowTime': !exists(json, 'resumeBorrowTime') ? undefined : json['resumeBorrowTime'],
        'returnDate': !exists(json, 'returnDate') ? undefined : (new Date(json['returnDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function BorrowToJSON(value?: Borrow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrowDate': value.borrowDate === undefined ? undefined : (value.borrowDate.toISOString()),
        'borrowId': value.borrowId,
        'categoryByCategoryId': CategoryToJSON(value.categoryByCategoryId),
        'categoryId': value.categoryId,
        'isMobile': value.isMobile,
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
        'resumeBorrowTime': value.resumeBorrowTime,
        'returnDate': value.returnDate === undefined ? undefined : (value.returnDate.toISOString()),
        'status': value.status,
    };
}

