/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface UserPointRecord
 */
export interface UserPointRecord {
    /**
     * 
     * @type {Member}
     * @memberof UserPointRecord
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    memberId?: number;
    /**
     * 
     * @type {Order}
     * @memberof UserPointRecord
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    orderId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserPointRecord
     */
    userPointRecordDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    userPointRecordId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    userPointRecordNum?: number;
}

export function UserPointRecordFromJSON(json: any): UserPointRecord {
    return UserPointRecordFromJSONTyped(json, false);
}

export function UserPointRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPointRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'orderByOrderId': !exists(json, 'orderByOrderId') ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'userPointRecordDate': !exists(json, 'userPointRecordDate') ? undefined : (new Date(json['userPointRecordDate'])),
        'userPointRecordId': !exists(json, 'userPointRecordId') ? undefined : json['userPointRecordId'],
        'userPointRecordNum': !exists(json, 'userPointRecordNum') ? undefined : json['userPointRecordNum'],
    };
}

export function UserPointRecordToJSON(value?: UserPointRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'orderByOrderId': OrderToJSON(value.orderByOrderId),
        'orderId': value.orderId,
        'userPointRecordDate': value.userPointRecordDate === undefined ? undefined : (value.userPointRecordDate.toISOString()),
        'userPointRecordId': value.userPointRecordId,
        'userPointRecordNum': value.userPointRecordNum,
    };
}

