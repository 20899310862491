/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageContentDto
 */
export interface PageContentDto {
    /**
     * 
     * @type {string}
     * @memberof PageContentDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof PageContentDto
     */
    pageContentId?: number;
    /**
     * 
     * @type {string}
     * @memberof PageContentDto
     */
    pageName?: string;
}

export function PageContentDtoFromJSON(json: any): PageContentDto {
    return PageContentDtoFromJSONTyped(json, false);
}

export function PageContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageContentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'pageContentId': !exists(json, 'pageContentId') ? undefined : json['pageContentId'],
        'pageName': !exists(json, 'pageName') ? undefined : json['pageName'],
    };
}

export function PageContentDtoToJSON(value?: PageContentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'pageContentId': value.pageContentId,
        'pageName': value.pageName,
    };
}

