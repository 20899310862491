import { SortableElement, SortableElementProps } from 'react-sortable-hoc';

interface ISortableItem extends SortableElementProps {
    children: React.ReactNode
}

export const DndItem: React.ComponentClass<ISortableItem, any> = SortableElement(
    ({ children }: { children: React.ReactNode }) => (
        <div >{children}</div>
    )
)