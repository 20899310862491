export function handleEditDialog(mode) {
  return { type: "HANDLE_EDIT_DIALOG", payload: mode };
}
export function handleDeleteDialog(mode) {
  return { type: "HANDLE_DELETE_DIALOG", payload: mode };
}
export function handleAddDialog(mode) {
  return { type: "HANDLE_ADD_DIALOG", payload: mode };
}
export function handleRenderFunc(renderFunc) {
  return { type: "HANDLE_RENDER_FUNC", payload: renderFunc };
}
export function handleActionDialog(mode) {
  return { type: "HANDLE_ACTION_DIALOG", payload: mode };
}
export function handleReadingState(state) {
  return { type: "HANDLE_READING_STATE", payload: state };
}
export function handleReadingBook(book) {
  return { type: "HANDLE_READING_BOOK", payload: book };
}
export function handleReadingEpub(epub) {
  return { type: "HANDLE_READING_EPUB", payload: epub };
}
export function handleDragItem(key) {
  return { type: "HANDLE_DRAG_ITEM", payload: key };
}
