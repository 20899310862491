import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import * as PATH from "../../utils/Contants"; //"../../global/Contants";
import style from "./Footer.module.css";
export default function Footer() {
  return (
    <>
      <div className={style.footer}>
        <div className={style.innerFooter}>
          <ul>
            {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
              <></>
            ) : (
              <>
                <li>
                  <Link to={PATH.APPDOWNLOAD}>
                    <Trans>app</Trans>
                  </Link>
                </li>
                <li>&nbsp;|&nbsp;</li>
              </>
            )} */}

            <li>
              <Link to={PATH.ABOUTS}>
                <Trans>Aboutus</Trans>
              </Link>
            </li>
            <li>&nbsp;|&nbsp;</li>

            {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
              <></>
            ) : (
              <>
                <li>
                  <Link to={PATH.MEDIA}>
                    <Trans>SocialMedia</Trans>
                  </Link>
                </li>
                <li>&nbsp;|&nbsp;</li>
              </>
            )} */}

            {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
              <></>
            ) : (
              <>
                <li>
                  <Link to={PATH.FBCLUB}>
                    <Trans>ReadingClub</Trans>
                  </Link>
                </li>
                <li>&nbsp;|&nbsp;</li>
              </>
            )} */}

            {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
              <></>
            ) : (
              <>
                <li>
                  <Link to={PATH.MANUAL}>
                    <Trans>manual</Trans>
                  </Link>
                </li>
                <li>&nbsp;|&nbsp;</li>
              </>
            )} */}

            <li>
              <Link to={PATH.POLICY}>
                <Trans>policy</Trans>
              </Link>
            </li>
          </ul>
          <div style={{ marginTop: "8px" }}>
            <span>
              Copyright @2023 SUEP | Powered by Octopus InfoTech Limited.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
