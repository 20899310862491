/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SfLocker
 */
export interface SfLocker {
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    sfLockerId?: string;
    /**
     * 
     * @type {number}
     * @memberof SfLocker
     */
    shippingFree?: number;
}

export function SfLockerFromJSON(json: any): SfLocker {
    return SfLockerFromJSONTyped(json, false);
}

export function SfLockerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SfLocker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'sfLockerId': !exists(json, 'sfLockerId') ? undefined : json['sfLockerId'],
        'shippingFree': !exists(json, 'shippingFree') ? undefined : json['shippingFree'],
    };
}

export function SfLockerToJSON(value?: SfLocker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'area': value.area,
        'sfLockerId': value.sfLockerId,
        'shippingFree': value.shippingFree,
    };
}

