/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportStorageDto
 */
export interface ReportStorageDto {
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    createAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    deletedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportStorageDto
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportStorageDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    invalidAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    pendingAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    readiedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    runningAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReportStorageDto
     */
    status?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportStorageDto
     */
    updateAt?: Date;
}

export function ReportStorageDtoFromJSON(json: any): ReportStorageDto {
    return ReportStorageDtoFromJSONTyped(json, false);
}

export function ReportStorageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStorageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': !exists(json, 'createAt') ? undefined : (new Date(json['createAt'])),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'invalidAt': !exists(json, 'invalidAt') ? undefined : (new Date(json['invalidAt'])),
        'pendingAt': !exists(json, 'pendingAt') ? undefined : (new Date(json['pendingAt'])),
        'readiedAt': !exists(json, 'readiedAt') ? undefined : (new Date(json['readiedAt'])),
        'runningAt': !exists(json, 'runningAt') ? undefined : (new Date(json['runningAt'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updateAt': !exists(json, 'updateAt') ? undefined : (new Date(json['updateAt'])),
    };
}

export function ReportStorageDtoToJSON(value?: ReportStorageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': value.createAt === undefined ? undefined : (value.createAt.toISOString()),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
        'fileName': value.fileName,
        'id': value.id,
        'invalidAt': value.invalidAt === undefined ? undefined : (value.invalidAt.toISOString()),
        'pendingAt': value.pendingAt === undefined ? undefined : (value.pendingAt.toISOString()),
        'readiedAt': value.readiedAt === undefined ? undefined : (value.readiedAt.toISOString()),
        'runningAt': value.runningAt === undefined ? undefined : (value.runningAt.toISOString()),
        'status': value.status,
        'updateAt': value.updateAt === undefined ? undefined : (value.updateAt.toISOString()),
    };
}

