/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberLevelDtoLite,
    MemberLevelDtoLiteFromJSON,
    MemberLevelDtoLiteFromJSONTyped,
    MemberLevelDtoLiteToJSON,
} from './MemberLevelDtoLite';

/**
 * 
 * @export
 * @interface MembershipRuleDto
 */
export interface MembershipRuleDto {
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    cashRequire?: number;
    /**
     * 
     * @type {MemberLevelDtoLite}
     * @memberof MembershipRuleDto
     */
    memberLevel?: MemberLevelDtoLite;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    membershipRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipRuleDto
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    pointRequire?: number;
}

export function MembershipRuleDtoFromJSON(json: any): MembershipRuleDto {
    return MembershipRuleDtoFromJSONTyped(json, false);
}

export function MembershipRuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRuleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cashRequire': !exists(json, 'cashRequire') ? undefined : json['cashRequire'],
        'memberLevel': !exists(json, 'memberLevel') ? undefined : MemberLevelDtoLiteFromJSON(json['memberLevel']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'membershipRuleId': !exists(json, 'membershipRuleId') ? undefined : json['membershipRuleId'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'pointRequire': !exists(json, 'pointRequire') ? undefined : json['pointRequire'],
    };
}

export function MembershipRuleDtoToJSON(value?: MembershipRuleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cashRequire': value.cashRequire,
        'memberLevel': MemberLevelDtoLiteToJSON(value.memberLevel),
        'memberLevelId': value.memberLevelId,
        'membershipRuleId': value.membershipRuleId,
        'period': value.period,
        'pointRequire': value.pointRequire,
    };
}

