/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowReadReportData,
    BorrowReadReportDataFromJSON,
    BorrowReadReportDataFromJSONTyped,
    BorrowReadReportDataToJSON,
} from './BorrowReadReportData';

/**
 * 
 * @export
 * @interface BorrowReadReportResponseModel
 */
export interface BorrowReadReportResponseModel {
    /**
     * 
     * @type {Array<BorrowReadReportData>}
     * @memberof BorrowReadReportResponseModel
     */
    data?: Array<BorrowReadReportData>;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportResponseModel
     */
    mobileBorrowTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportResponseModel
     */
    pageTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportResponseModel
     */
    previewTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportResponseModel
     */
    readOnlineTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowReadReportResponseModel
     */
    webBorrowTotal?: number;
}

export function BorrowReadReportResponseModelFromJSON(json: any): BorrowReadReportResponseModel {
    return BorrowReadReportResponseModelFromJSONTyped(json, false);
}

export function BorrowReadReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowReadReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(BorrowReadReportDataFromJSON)),
        'mobileBorrowTotal': !exists(json, 'mobileBorrowTotal') ? undefined : json['mobileBorrowTotal'],
        'pageTotal': !exists(json, 'pageTotal') ? undefined : json['pageTotal'],
        'previewTotal': !exists(json, 'previewTotal') ? undefined : json['previewTotal'],
        'readOnlineTotal': !exists(json, 'readOnlineTotal') ? undefined : json['readOnlineTotal'],
        'webBorrowTotal': !exists(json, 'webBorrowTotal') ? undefined : json['webBorrowTotal'],
    };
}

export function BorrowReadReportResponseModelToJSON(value?: BorrowReadReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(BorrowReadReportDataToJSON)),
        'mobileBorrowTotal': value.mobileBorrowTotal,
        'pageTotal': value.pageTotal,
        'previewTotal': value.previewTotal,
        'readOnlineTotal': value.readOnlineTotal,
        'webBorrowTotal': value.webBorrowTotal,
    };
}

