import htmlParse from "html-react-parser";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { HOME_PATH, LOGIN_PATH, PAGE_SETTING, STYLETYPE_MAIN } from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import Button from "../Button/Button";
import CKEditor from "../Editor/CKEditor";
//import { CKEditor } from "ckeditor4-react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import arrowBack from "../../assets/svg/back.svg";
import { GetPageSettingByUrlUsingGETRequest, PageSetting, PageSettingControllerApi, PageSettingDto, UpdatePageSettingUsingPOSTRequest } from "../../fetcher";
import { getHeaderItems } from "../../redux/actions/headerItems/getHeaderItems";
import { headerItems } from "../../redux/reducers/headerItems/headerItems";
import Input from "../Input/Input";
import style from "./PageSettingComponents.module.css";
import * as PATH from "../../utils/Contants";

import * as Storage from "../../utils/local-storage";
export interface IPageProps {
    data: PageSetting;
    engName?: string;
    chtName?: string;
    cnName?: string;
    callBack?: (value: any) => void;
}

function PageSettingComponents(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [pageSettingData, setPageSettingData] = useState<PageSetting>();
    const [content, setContent] = useState<string>("");
    const [htmlContent, setHtmlContent] = useState<any>();
    const [hidden, setHidden] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [timer, setTimer] = useState<any>();
    const [lang, setLang] = useState("");

    const handleReady = (event: any) => {
        clearTimeout(timer);
        const _timer = setTimeout(function () {
            event.editor.setData(htmlParse(pageSettingData?.content!));
        }, 300);
        setTimer(_timer);
    };

    const handleContentChange = (event: any) => {
        setContent(event.editor.getData());
    };

    const handleSubmit = (value: any) => {
        console.log(pageSettingData);
        setSaveLoading(true);
    };

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }

        const userInfo = localStorage.getItem(PATH.USER_INFO);
        if (userInfo === null) {
            navigate(PATH.LOGIN_PATH);
        }
    }, []);

    useEffect(() => {
        if (saveLoading) {
            new PageSettingControllerApi(genApiConfig())
                .updatePageSettingUsingPOST({
                    id: pageSettingData?.pageSettingId,
                    pageSettingDto: {
                        content: pageSettingData?.content,
                        enable: hidden,
                    } as PageSettingDto,
                } as UpdatePageSettingUsingPOSTRequest)
                .then((res: PageSetting) => {
                    alert("更新成功");
                    navigate(PAGE_SETTING);
                    new PageSettingControllerApi(genApiConfig())
                        .getAllPageSettingUsingGET()
                        .then((res: PageSetting[]) => {
                            if (res !== null && res !== undefined) {
                                props.getHeaderItems(res);
                            }
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
            setSaveLoading(false);
        }
    }, [saveLoading]);

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(LOGIN_PATH)}</>;
        }
        if (location.pathname !== "") {
            new PageSettingControllerApi(genApiConfig())
                .getPageSettingByUrlUsingGET({
                    url: location.pathname,
                } as GetPageSettingByUrlUsingGETRequest)
                .then((res: PageSetting) => {
                    setPageSettingData(res);
                    setHidden(res?.enable!!);
                    if (res.content !== undefined) {
                        setContent(res.content);
                        setHtmlContent(htmlParse(res.content));
                    }
                })
                .catch((err) => {
                    console.log("getPageSettingByUrlUsingGET err : ", err);
                });
        }
    }, []);

    useEffect(() => {
        setPageSettingData(Object.assign({ ...pageSettingData, content: content }));
        if (pageSettingData?.pageSettingId !== undefined) {
            let a = document.getElementById(pageSettingData?.pageSettingId.toString());
        }
    }, [content]);

    // useEffect(() => {
    //     if (i18n.language === "cht") {
    //         setLang("cht");
    //     } else if (i18n.language === "cn") {
    //         setLang("cn");
    //     } else if (i18n.language === "en") {
    //         setLang("en");
    //     }
    // }, [i18n]);
    const handleHiddenComponent = () => {
        setHidden(!hidden);
    };

    return (
        <div>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>

            {/* TODO Check user language */}

            <div className={style.container}>
                <h1>{pageSettingData?.chtName}</h1>
                <div style={{ display: "flex" }}>
                    <Input type="checkbox" id={pageSettingData?.code} name={"顯示此頁"} checked={hidden} styleType={""} callBack={() => handleHiddenComponent()} />{" "}
                </div>
                <div id={pageSettingData?.pageSettingId?.toString()}>
                    {pageSettingData?.content !== undefined && pageSettingData?.content !== null ? (
                        <>
                            <CKEditor initData={htmlParse(pageSettingData?.content!)} onInstanceReady={handleReady} onChange={handleContentChange} />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className={style.button_on_right}>
                <Button styleType={STYLETYPE_MAIN} callBack={(value) => navigate(HOME_PATH)}>
                    取消
                </Button>

                <Button styleType={STYLETYPE_MAIN} isLoading={saveLoading} loadingStyle={{ width: "20px", height: "20px" }} callBack={(value: any) => handleSubmit(value)}>
                    儲存
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        headerItems,
    };
};

const actionCreator = { getHeaderItems };

export default connect(mapStateToProps, actionCreator)(PageSettingComponents);
