/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryNameConflictPayload
 */
export interface CategoryNameConflictPayload {
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflictPayload
     */
    mongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflictPayload
     */
    name?: string;
}

export function CategoryNameConflictPayloadFromJSON(json: any): CategoryNameConflictPayload {
    return CategoryNameConflictPayloadFromJSONTyped(json, false);
}

export function CategoryNameConflictPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryNameConflictPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CategoryNameConflictPayloadToJSON(value?: CategoryNameConflictPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mongoId': value.mongoId,
        'name': value.name,
    };
}

