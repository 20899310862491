import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import Dropzone from "../../../components/Dropzone/Dropzone";
// import { BookBatchMissionApi } from "../../../fetcher";
import { ALL_BATCH, LOGIN_PATH, STYLETYPE_MAIN } from "../../../utils/Contants";
// import { genApiConfig } from "../../../utils/Fetch-caller";
import { Trans } from "react-i18next";
import Uploader from "../../../utils/fileUtils/FileUploader";
import { dateToString } from "../../../utils/GlobalFunction";
import { fetchFileFromURLToFile } from "../../../utils/reader/fileUtils/fileUtil";
import style from "./UploadBatchView.module.css";
import * as Storage from "../../../utils/local-storage";
const BatchView = (props: any) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currentFile, setCurrentFile] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(LOGIN_PATH)}</>;
        }
    }, []);

    const handleSubmit = (value: any) => {
        setLoading(true);
    };

    const submitData = () => {
        if (currentFile.length === 0) {
            alert("請上傳文件");
            setLoading(false);
            return;
        }

        new Uploader(`${process.env.REACT_APP_BACKEND}/member/batch-import`, {
            showProgress: false,
            enableSlice: false,
        })
            .upload(currentFile[0])
            .onComplete((data: any) => {
                try {
                    setLoading(false);
                    let result = JSON.parse(data.response);
                    console.log("res", result);

                    if (result.hasOwnProperty("successMessage")) {
                        alert("已更新用戶");
                        navigate(ALL_BATCH);
                    }

                    //   if (
                    //     result.hasOwnProperty("deletedMember") &&
                    //     result.hasOwnProperty("newMember") &&
                    //     result.hasOwnProperty("updateMember")
                    //   ) {
                    //     alert("已更新用戶");
                    //     navigate(PATH.ALL_BATCH);
                    //   }
                } catch (e) {
                    console.log("eadfadsfsfsf", e);
                }
            })
            .onCatch((catchMsg: any) => {
                setLoading(false);
                alert(catchMsg.response.message);
            });
    };

    useEffect(() => {
        if (loading) {
            submitData();
        }
    }, [loading]);

    const downloadData = () => {
        fetchFileFromURLToFile(`${process.env.REACT_APP_BACKEND}/member/export-all-student`, `all_customer_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx`);

        setDownloadLoading(false);
    };

    const handleDownload = () => {
        setDownloadLoading(true);
    };

    useEffect(() => {
        if (downloadLoading) {
            downloadData();
        }
    }, [downloadLoading]);

    const handleMultiSelectOptionCallBack = (value: any) => {};

    const handleSettingClick = (value: any) => {};

    return (
        <>
            <div className={style.backHomeButtonContainer}>
                <div>
                    <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                        <img src={arrowBack} />
                        <Trans>PreviousPage</Trans>
                    </Button>
                </div>
            </div>

            <div className={style.Uploadbatchview_container}>
                <div className={style.content_style}>
                    <h4>批量上傳用戶</h4>
                </div>

                <div className={style.container}>
                    <div className={style.header}>
                        <h5>用戶資料</h5>
                    </div>
                    <div className={style.dropzone}>
                        <Dropzone
                            callBack={(file: any) => {
                                setCurrentFile(file);
                            }}
                        />
                    </div>
                </div>

                {/* <div className={style.filerow}>
                    <BatchFiles />
                </div> */}

                <div className={style.btnRow}>
                    <Button styleType={STYLETYPE_MAIN} isLoading={downloadLoading} loadingStyle={{ width: "20px", height: "20px" }} callBack={(value) => handleDownload()}>
                        下載現有資料
                    </Button>
                    <Button styleType={STYLETYPE_MAIN} isLoading={loading} loadingStyle={{ width: "20px", height: "20px" }} callBack={(value) => handleSubmit(value)}>
                        提交
                    </Button>
                </div>
            </div>
        </>
    );
};

export default BatchView;
