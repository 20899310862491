/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryParentConflict
 */
export interface CategoryParentConflict {
    /**
     * 
     * @type {string}
     * @memberof CategoryParentConflict
     */
    cmsParentMongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryParentConflict
     */
    libParentMongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryParentConflict
     */
    mongoId?: string;
}

export function CategoryParentConflictFromJSON(json: any): CategoryParentConflict {
    return CategoryParentConflictFromJSONTyped(json, false);
}

export function CategoryParentConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryParentConflict {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cmsParentMongoId': !exists(json, 'cmsParentMongoId') ? undefined : json['cmsParentMongoId'],
        'libParentMongoId': !exists(json, 'libParentMongoId') ? undefined : json['libParentMongoId'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
    };
}

export function CategoryParentConflictToJSON(value?: CategoryParentConflict | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cmsParentMongoId': value.cmsParentMongoId,
        'libParentMongoId': value.libParentMongoId,
        'mongoId': value.mongoId,
    };
}

