import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

import { Trans } from "react-i18next";
import arrowBack from "../../../assets/svg/back.svg";
import {
    AddMarketingEventUsingPOSTRequest,
    MarketingEventControllerApi,
    MarketingEventDto,
    MarketingEventRequestModel,
    UpdateMarketingEventUsingPOSTRequest,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { MARKETING_EVENT_MANAGEMENT, STYLETYPE_MAIN } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString, stringToDate } from "../../../utils/GlobalFunction";
import style from "./MarketingEventView.module.css";
import * as Storage from "../../../utils/local-storage";
export default function MarketingEventView() {
    const navigate = useNavigate();
    const location = useLocation();
    const [submitLoading, setSubmitLoading] = useState(false);

    const [currentEvent, setCurrentEvent] = useState<MarketingEventDto>();

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        if (location.state !== null) {
            //update
            let stateData = location.state as MarketingEventDto;
            setCurrentEvent(stateData);
        }
    }, []);

    useEffect(() => {
        if (submitLoading) {
            console.log("currentEventStartDate", currentEvent);

            if (currentEvent?.marketingEventName === "" || currentEvent?.marketingEventName === undefined || currentEvent?.marketingEventName === null) {
                alert("請輸入名稱");
                setSubmitLoading(false);
                return;
            }

            console.log("start Date", moment(moment(currentEvent?.startDate).format("YYYY-MM-DD")).toDate());

            if (location.state === null) {
                // New
                new MarketingEventControllerApi(genApiConfig())
                    .addMarketingEventUsingPOST({
                        marketingEventRequestModel: {
                            isMegaSale: "0",
                            marketingEventName: currentEvent?.marketingEventName,
                            discountId: 1,
                            bannerLinkTo: currentEvent?.bannerLinkTo,
                            bannerPosition: currentEvent?.bannerPosition,
                            startDate: moment(moment(currentEvent?.startDate).format("YYYY-MM-DD")).toDate(),
                            endDate: moment(moment(currentEvent?.endDate).format("YYYY-MM-DD")).toDate(),
                        } as MarketingEventRequestModel,
                    } as AddMarketingEventUsingPOSTRequest)
                    .then((res) => {
                        console.log(res);
                        alert("保存成功");
                        setSubmitLoading(false);
                        navigate(MARKETING_EVENT_MANAGEMENT);
                    })
                    .catch((err) => {
                        console.log("err", err);
                        setSubmitLoading(false);
                    });
            } else {
                // Update
                new MarketingEventControllerApi(genApiConfig())
                    .updateMarketingEventUsingPOST({
                        marketingEventId: currentEvent?.marketingEventId!!,
                        //marketingEventPriority?: number;
                        marketingEventRequestModel: {
                            isMegaSale: "0",
                            discountId: 1,
                            marketingEventId: currentEvent?.marketingEventId!!,
                            marketingEventName: currentEvent?.marketingEventName!!,
                            bannerLinkTo: currentEvent?.bannerLinkTo,
                            bannerPosition: currentEvent?.bannerPosition,
                            startDate: moment(moment(currentEvent?.startDate).format("YYYY-MM-DD")).toDate(),
                            endDate: moment(moment(currentEvent?.endDate).format("YYYY-MM-DD")).toDate(),
                        } as MarketingEventRequestModel,
                    } as UpdateMarketingEventUsingPOSTRequest)
                    .then((res) => {
                        console.log(res);
                        alert("更新成功");
                        setSubmitLoading(false);
                        navigate(MARKETING_EVENT_MANAGEMENT);
                    })
                    .catch((err) => {
                        err.json().then((res: any) => {
                            console.log(res);
                        });
                    });
            }

            setSubmitLoading(false);
        }
    }, [submitLoading]);

    useEffect(() => {
        console.log("currentEvent", currentEvent);
    }, [currentEvent]);

    return (
        <div>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(PATH.MARKETING_EVENT_MANAGEMENT)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.eventConatiner}>
                <label>Name</label>
                <Input
                    placeholder={"NAME"}
                    type="text"
                    value={currentEvent?.marketingEventName}
                    callBack={(value) => setCurrentEvent({ ...currentEvent, marketingEventName: value })}
                    styleType={STYLETYPE_MAIN}
                />

                <label>Url</label>
                <Input type="text" value={currentEvent?.bannerLinkTo} callBack={(value) => setCurrentEvent({ ...currentEvent, bannerLinkTo: value })} styleType={STYLETYPE_MAIN} />
                <div style={{ margin: "25px 0" }}>
                    <label>Banner位置</label>
                    <div className={style.eventRadio}>
                        <div className={style.eventRadio}>
                            <label>左</label>
                            <Input
                                type="radio"
                                checked={currentEvent?.bannerPosition === 1 ? true : false}
                                callBack={(value) => {
                                    setCurrentEvent({
                                        ...currentEvent,
                                        bannerPosition: value ? 1 : 2,
                                    });
                                }}
                                styleType={STYLETYPE_MAIN}
                            />
                        </div>
                        <div className={style.eventRadio}>
                            <label>右</label>

                            <Input
                                type="radio"
                                checked={currentEvent?.bannerPosition === 2 ? true : false}
                                callBack={(value) => {
                                    setCurrentEvent({
                                        ...currentEvent,
                                        bannerPosition: !value ? 1 : 2,
                                    });
                                }}
                                styleType={STYLETYPE_MAIN}
                            />
                        </div>
                    </div>
                </div>
                <label>開始日期</label>
                <Input
                    type="date"
                    value={currentEvent?.startDate !== undefined ? dateToString(currentEvent?.startDate!!) : ""}
                    callBack={(value) => {
                        setCurrentEvent({
                            ...currentEvent,
                            startDate: stringToDate(value),
                        });
                    }}
                    styleType={STYLETYPE_MAIN}
                />

                <label>結束日期</label>
                <Input
                    type="date"
                    value={currentEvent?.endDate !== undefined ? dateToString(currentEvent?.endDate!!) : ""}
                    callBack={(value) => {
                        console.log("e", value);
                        setCurrentEvent({ ...currentEvent, endDate: stringToDate(value) });
                    }}
                    styleType={STYLETYPE_MAIN}
                />

                <div className={style.event_button}>
                    <Button styleType={STYLETYPE_MAIN} isLoading={submitLoading} loadingStyle={{ width: "20px", height: "20px" }} callBack={(value) => setSubmitLoading(value)}>
                        提交
                    </Button>
                </div>
            </div>
        </div>
    );
}
