import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrowDown from "../../assets/svg/down.svg";
import arrowUp from "../../assets/svg/up.svg";
import style from "./TreeMeun.module.css";
import { Trans, useTranslation } from "react-i18next";

export default function TreeMeun({ props }: any) {
    const [hidden, setHidden] = useState(true);
    const { i18n } = useTranslation();
    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });
    // useEffect(() => {
    //   if (props.subCategories && props.subCategories.length > 0) {
    //     setHidden(false)
    //   }
    // }, [])

    const handleClick = (value: any) => {
        const { action } = value;
        switch (action) {
            case "expand":
                setHidden(!hidden);
                break;
        }
    };

    return (
        <div className={style.treeMenu} key={props.id}>
            <div className={hidden ? style.treeHead : style.treeHeadActive}>
                <span>
                    <Link to={`category/${props.categoryId}&${props.categoryName}`}>
                        {/* {props.categoryName} */}
                        {i18n.language === "cn" ? props.categoryName && converter(props.categoryName) : props.categoryName && props.categoryName}
                    </Link>

                    {/* {props.categoryName} */}
                </span>

                {props.subCategories && props.subCategories.length > 0 ? (
                    hidden ? (
                        <img src={arrowDown} onClick={(event) => handleClick({ action: "expand", event: event })} />
                    ) : (
                        <img src={arrowUp} onClick={(event) => handleClick({ action: "expand", event: event })} />
                    )
                ) : (
                    <></>
                )}
            </div>
            {props.subCategories &&
                props.subCategories.map((cat: any, index: number) => (
                    <div className={hidden ? style.treeBody : style.treeBodyActive} key={index}>
                        <Link to={"category/" + cat.categoryId.toString() + "&" + cat.categoryName}>{i18n.language === "cn" ? converter(cat.categoryName) : cat.categoryName}</Link>
                    </div>
                ))}
        </div>
    );
}
