import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardControllerApi } from "../../fetcher";
import { HOME_PATH } from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import { getArgsQuery } from "../../utils/GlobalFunction";

const ErrorPage = (props: any, state: any) => {
    // const errorCode = getArgsQuery();
    // console.log("ErrorPage1", errorCode);
    console.log("wwww", window.location);

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND2}/dashboard/checkstatus`).then((res) => {
            console.log("res", res);
        });
    }, []);

    return <div style={{ fontSize: "30px" }}>ErrorPage </div>;
};

export default ErrorPage;
