/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {Date}
     * @memberof Point
     */
    expireDate?: Date;
    /**
     * 
     * @type {Member}
     * @memberof Point
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    pointId?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    pointRemain?: number;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expireDate': !exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'pointId': !exists(json, 'pointId') ? undefined : json['pointId'],
        'pointRemain': !exists(json, 'pointRemain') ? undefined : json['pointRemain'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'pointId': value.pointId,
        'pointRemain': value.pointRemain,
    };
}

