// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhiteListSetting_backbtn__UmwJS {\n    display: flex;\n\n    align-items: center;\n    /* padding: 25px 35px 0; */\n}\n\n.WhiteListSetting_actionButtonContainer__QJvpg {\n    width: 30px;\n    margin-left: auto;\n    margin-right: 100px;\n}\n\n.WhiteListSetting_listContainer__2Sng1 {\n    padding: 8px;\n}\n\n.WhiteListSetting_listItem__WKWQd {\n    max-height: 70px;\n    min-height: 65px;\n    width: 100%;\n    display: flex;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n    transition: 0.3s;\n    border-radius: 10px;\n    margin: 10px 0;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px;\n}\n\n.WhiteListSetting_inputField__0Fn5i > * {\n    width: 30px;\n}", "",{"version":3,"sources":["webpack://./src/layout/Admin/WhiteListSetting/WhiteListSetting.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,0CAA0C;IAC1C,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".backbtn {\n    display: flex;\n\n    align-items: center;\n    /* padding: 25px 35px 0; */\n}\n\n.actionButtonContainer {\n    width: 30px;\n    margin-left: auto;\n    margin-right: 100px;\n}\n\n.listContainer {\n    padding: 8px;\n}\n\n.listItem {\n    max-height: 70px;\n    min-height: 65px;\n    width: 100%;\n    display: flex;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\n    transition: 0.3s;\n    border-radius: 10px;\n    margin: 10px 0;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px;\n}\n\n.inputField > * {\n    width: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backbtn": "WhiteListSetting_backbtn__UmwJS",
	"actionButtonContainer": "WhiteListSetting_actionButtonContainer__QJvpg",
	"listContainer": "WhiteListSetting_listContainer__2Sng1",
	"listItem": "WhiteListSetting_listItem__WKWQd",
	"inputField": "WhiteListSetting_inputField__0Fn5i"
};
export default ___CSS_LOADER_EXPORT___;
