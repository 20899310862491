import md5 from "md5";
import React from "react";
import { connect } from "react-redux";
import { translationList } from "../../../../constants/reader/translationList";
import {
  handleMenuMode,
  handleOpenMenu,
} from "../../../../redux/actions/reader";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import "./popupTrans.css";

class PopupTrans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translatedText: "",
    };
  }
  componentDidMount() {
    console.log("PopupTrans selection", localStorage.getItem("selectionaa"));
    // let originalText = this.props.originalText.replace(/(\r\n|\n|\r)/gm, "");
    let originalText = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");
    this.state.translatedText = originalText;
    this.handleTrans(originalText);
  }
  handleTrans = (text) => {

    console.log("tranText",text, this.state.translatedText);
    var script = document.createElement("script");
    script.type = "text/javascript";
    console.log("StorageUtil.getReaderConfig(transTarget)",StorageUtil.getReaderConfig("transTarget"));
    if(StorageUtil.getReaderConfig("transTarget") == 'zh'){
      script.src = `https://fanyi-api.baidu.com/api/trans/vip/translate?q=${text}&from=cht&to=${StorageUtil.getReaderConfig("transTarget") || "auto"
      }&appid=20220808001298057&salt=1435660288&sign=${md5(
        // eslint-disable-next-line
        "20220808001298057" + text + "1435660288" + "0vpZ7HM7l7LEKdR55ZFw"
      )}&callback=handleCallback`;
    }else{
      script.src = `https://fanyi-api.baidu.com/api/trans/vip/translate?q=${text}&from=auto&to=${StorageUtil.getReaderConfig("transTarget") || "auto"
        }&appid=20220808001298057&salt=1435660288&sign=${md5(
          // eslint-disable-next-line
          "20220808001298057" + text + "1435660288" + "0vpZ7HM7l7LEKdR55ZFw"
        )}&callback=handleCallback`;
    }
    document.head.appendChild(script);
    window.handleCallback = (res) => {
      if (res.error_code && res.error_code === 54003) {
        this.setState({
          translatedText: this.props.t("Reach frequency limit"),
        });
      } else {
        this.setState({
          translatedText: res.trans_result
            ? res.trans_result[0].dst
            : this.props.t("Error happens"),
        });
      }
    };
  };
  render() {
    const renderNoteEditor = () => {
      return (
        <div className="trans-container">
          <div className="trans-text-box">{this.state.translatedText}</div>
          <div className="target-lang-container">
            <p className="general-setting-title" style={{ display: "inline" }}>
              目標語言：&nbsp;
            </p>
            <select
              className="booklist-shelf-list"
              style={{ width: "100px" }}
              onChange={(event) => {
                let targetLang = event.target.value;
                StorageUtil.setReaderConfig("transTarget", targetLang);

                //let originalText = this.props.originalText.replace(/(\r\n|\n|\r)/gm, "");
                let originalText = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");

                this.handleTrans(
                  originalText
                );
              }}
            >
              {translationList.map((item, index) => {
                return (
                  <option
                    value={item.value}
                    key={index}
                    className="add-dialog-shelf-list-option"
                    selected={
                      StorageUtil.getReaderConfig("transTarget") === item.value
                        ? true
                        : false
                    }
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    };
    return renderNoteEditor();
  }
}

const mapStateToProps = (state) => {
  return {
    originalText: state.reader.originalText,
  };
};
const actionCreator = {
  handleOpenMenu,
  handleMenuMode,
};
export default connect(mapStateToProps, actionCreator)(PopupTrans);
