/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileUploadItemResponseModel,
    FileUploadItemResponseModelFromJSON,
    FileUploadItemResponseModelFromJSONTyped,
    FileUploadItemResponseModelToJSON,
} from './FileUploadItemResponseModel';

/**
 * 
 * @export
 * @interface FileUploadResponseModel
 */
export interface FileUploadResponseModel {
    /**
     * 
     * @type {number}
     * @memberof FileUploadResponseModel
     */
    fileLength?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileUploadResponseModel
     */
    fileNames?: Array<string>;
    /**
     * 
     * @type {Array<FileUploadItemResponseModel>}
     * @memberof FileUploadResponseModel
     */
    files?: Array<FileUploadItemResponseModel>;
}

export function FileUploadResponseModelFromJSON(json: any): FileUploadResponseModel {
    return FileUploadResponseModelFromJSONTyped(json, false);
}

export function FileUploadResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileLength': !exists(json, 'fileLength') ? undefined : json['fileLength'],
        'fileNames': !exists(json, 'fileNames') ? undefined : json['fileNames'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(FileUploadItemResponseModelFromJSON)),
    };
}

export function FileUploadResponseModelToJSON(value?: FileUploadResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileLength': value.fileLength,
        'fileNames': value.fileNames,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(FileUploadItemResponseModelToJSON)),
    };
}

