/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import {
    DisplayProduct,
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';

/**
 * 
 * @export
 * @interface DisplayProductCategory
 */
export interface DisplayProductCategory {
    /**
     * 
     * @type {Category}
     * @memberof DisplayProductCategory
     */
    categoryByCategoryId?: Category;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    categoryId?: number;
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductCategory
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    displayProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    order?: number;
}

export function DisplayProductCategoryFromJSON(json: any): DisplayProductCategory {
    return DisplayProductCategoryFromJSONTyped(json, false);
}

export function DisplayProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryByCategoryId': !exists(json, 'categoryByCategoryId') ? undefined : CategoryFromJSON(json['categoryByCategoryId']),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'displayProductByDisplayProductId': !exists(json, 'displayProductByDisplayProductId') ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function DisplayProductCategoryToJSON(value?: DisplayProductCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryByCategoryId': CategoryToJSON(value.categoryByCategoryId),
        'categoryId': value.categoryId,
        'displayProductByDisplayProductId': DisplayProductToJSON(value.displayProductByDisplayProductId),
        'displayProductId': value.displayProductId,
        'order': value.order,
    };
}

