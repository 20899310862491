import { Component } from "react";
import {
  DeleteMemberFavouriteByMemberIdUsingDELETERequest,
  GetMemberFavouritesByMemberIdUsingGETRequest,
  MemberControllerApi,
  ProductDto,
} from "../../../fetcher";
import { DIALOG_DELETE } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString } from "../../../utils/GlobalFunction";
import Button from "../../Button/Button";
import style from "./BookMarkItem.module.css";
import { Trans, useTranslation } from "react-i18next";
interface IProps {
  title?: string;
  content?: string;
  value?: string;
  callBack: (value: any, value2?: any) => void;
  styleType?: string;
  children?: any;
  item?: any;
  navigate?: any;
  userInfo?: any;
  params?: any;
}

class BookMarkItem extends Component<IProps> {
  state = {
    content: {},
    title: "",
    showError: false,
    percent: 10,
  };

  handleEditCallBack = () => {};

  handleCallBack = () => {};

  handleCancelBookMark = (productId: any) => {
    if (Object.keys(this.props.userInfo).length > 0) {
      new MemberControllerApi(genApiConfig())
        .deleteMemberFavouriteByMemberIdUsingDELETE({
          memberId: this.props.userInfo?.userInfo.member.memberId,
          productId: parseInt(productId!),
        } as DeleteMemberFavouriteByMemberIdUsingDELETERequest)
        .then((data) => {
          if (data) {
            new MemberControllerApi(genApiConfig())
              .getMemberFavouritesByMemberIdUsingGET({
                memberId: this.props.userInfo?.userInfo.member.memberId,
              } as GetMemberFavouritesByMemberIdUsingGETRequest)
              .then((data: Array<ProductDto>) => {
                this.props.callBack(data);
              });
          }
        });
    }
  };

  render() {
    const { content, showError, percent } = this.state;

    return (
      <>
        <div className={style.Container}>
          <div>
            <div
              className={style.CardView_IMG}
              onClick={() =>
                this.props.navigate(
                  `/product/${this.props && this.props.item.productId}`
                )
              }
            >
              <img
                style={{ width: "150px", height: "200px" }}
                src={
                  process.env.REACT_APP_BACKEND ==
                  "https://suepsso.octopus-tech.com"
                    ? `${process.env.REACT_APP_BACKEND}/file/${this.props.item.coverFileName}`
                    : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${this.props.item.coverFileName}?x-oss-process=image/resize,h_200`
                }
              />
              {/* <Button styleType={STYLETYPE_MAIN} callBack={(value) => this.handleCallBack()}>
                            <Trans>Read</Trans>
                        </Button> */}
            </div>
            <div className={style.Content}>
              <div>
                <h5>{this.props.item.productNameChi}</h5>
              </div>
              <div style={{ fontSize: "10px" }}>
                {this.props.item.author !== undefined
                  ? this.props.item.author
                      .split(":::")
                      .map((it: any) => <p>{it}&nbsp;</p>)
                  : ""}
              </div>

              {/* <div className={style.ContentDate}>
              預期{" "}
              {dateToString(this.props.item.estimateBorrowDate, "DD/MM/YYYY")}
            </div> */}
            </div>
          </div>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() =>
                this.handleCancelBookMark(this.props.item.productId)
              }
            >
              <Trans>CancelBookMark</Trans>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default BookMarkItem;
