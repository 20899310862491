import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
// import { withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/loading.gif";
import {
  AddMemberUsingPOSTRequest,
  MemberControllerApi,
  ResetPasswordUsingPATCHRequest,
  SuccessResponseModel,
  UpdateMemberUsingPATCH1Request,
} from "../../../fetcher";
import { AddUpdateMemberResponseModel } from "../../../fetcher/models/AddUpdateMemberResponseModel";
import { IUserDto } from "../../../layout/Admin/Users/UsersView";
// import { OrganizationApi, RoleApi } from "../../../fetcher";
// import UserApi from "../../../fetcher/apis/UserApi";
// import { handleUserInfo } from "../../../redux/actions";
import {
  ADD,
  DIALOG_COMPLETE,
  EDIT,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
} from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import {
  dateToString,
  stringToDate,
  validateEmail,
} from "../../../utils/GlobalFunction";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import style from "./UserSheet.module.css";

//var sha512 = require("js-sha512");

interface Props {
  content?: IUserDto;
  actionType: "add" | "edit";
  callBack: (value: any, value2?: any) => void;
}

class UserSheet extends Component<Props> {
  state = {
    isLoading: false,
    actionType: "",
    content: {} as IUserDto,
    showError: false,
    showResetPassword: false,
    newPassword: "",
  };

  static propTypes = {
    content: PropTypes.object,
    actionType: PropTypes.string.isRequired,
    callBack: PropTypes.func,
  };

  static defaultProps = {};

  componentWillReceiveProps(nextProps: Props) {
    //console.log("nextProps", nextProps);
    console.log("this.state", this.state);

    if (this.props !== nextProps) {
      if (nextProps.actionType === ADD) {
        this.resetState();
      }
      if (nextProps.actionType === EDIT) {
        this.setState({
          ...this.state,
          showResetPassword: false,
          content: nextProps.content,
          actionType: EDIT,
          newPassword: "",
        });
      }
    }
  }

  // handleRandomPwd = (value: any) => {
  //   this.setState({ ...this.state, password: nanoid(10) });
  // };

  resetPassword = () => {
    if (
      this.state.content.memberId !== undefined &&
      this.state.newPassword !== undefined &&
      this.state.newPassword.length > 0
    ) {
      var opt: ResetPasswordUsingPATCHRequest = {
        memberId: this.state.content.memberId,
        newPassword: this.state.newPassword,
      };
      new MemberControllerApi(genApiConfig())
        .resetPasswordUsingPATCH(opt)
        .then((data: SuccessResponseModel) => {
          if (data.success) {
            alert("成功更改密碼");
            this.setState({ ...this.state, showResetPassword: false });
          }
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } else {
      alert("密碼碼能為空");
    }
  };

  // handleShowPwd = (value: any) => {
  //   const { showPwd } = this.state;
  //   this.setState({ ...this.state, showPwd: !showPwd });
  // };

  checkAddFormVaild = () => {
    // const { name, email, password, roleName } = this.state;
    const {
      username,
      nameChi,
      nameEng,
      password,
      expireDate,
      grade,
      className,
      classNumber,
      dbDelete,
    } = this.state.content;
    console.log("this.state.content", this.state.content);
    console.log(
      "tf2",
      username !== undefined &&
        nameChi !== undefined &&
        nameEng !== undefined &&
        password !== undefined &&
        grade !== undefined &&
        className !== undefined &&
        classNumber !== undefined &&
        expireDate !== undefined
    );
    console.log(
      "tf1",
      username?.length! > 0 &&
        nameChi?.length! > 0 &&
        nameEng?.length! > 0 &&
        password?.length! > 0 &&
        grade?.length! > 0 &&
        className?.length! > 0 &&
        classNumber?.length! > 0
    );
    if (
      username !== undefined &&
      nameChi !== undefined &&
      nameEng !== undefined &&
      password !== undefined &&
      grade !== undefined &&
      className !== undefined &&
      classNumber !== undefined &&
      username?.length! > 0 &&
      nameChi?.length! > 0 &&
      nameEng?.length! > 0 &&
      password?.length! > 0 &&
      grade?.length! > 0 &&
      className?.length! > 0 &&
      classNumber?.length! > 0
    ) {
      console.log("if: true");
      return true;
    } else {
      this.setState({ ...this.state, loading: false, showError: true });
      console.log("if: false");
      return false;
    }
  };

  // checkUpdateFormVaild = () => {
  //   const { name, email, roleName } = this.state;
  //   if (name === "" || validateEmail(email) == null || roleName === "") {
  //     this.setState({ ...this.state, loading: false, showError: true });
  //     return false;
  //   }
  //   return true;
  // };

  // submitAddFormData = () => {
  //   // const {
  //   //   description,
  //   //   email,
  //   //   name,
  //   //   organization,
  //   //   password,
  //   //   roleName,
  //   //   displayRoleData,
  //   // } = this.state;

  //   const {
  //     username,
  //     nameChi,
  //     nameEng,
  //     password,
  //     expireDate,
  //     grade,
  //     className,
  //     classNumber,
  //     dbDelete
  //   } = this.state.content
  //   // const hashPwd = sha512(email + password);
  //   // let opts = {};
  //   if (username !== undefined && nameChi !== undefined && nameEng !== undefined && password !== undefined && expireDate !== undefined && grade !== undefined && className !== undefined && classNumber !== undefined) {
  //     let opts: AddMemberUsingPOSTRequest = {
  //       userName: username,
  //       nameChi,
  //       nameEng,
  //       password,
  //       // expireDate,
  //       expireDate: dateToString(
  //         expireDate,
  //         "yyyy-MM-DD"
  //       ),
  //       grade,
  //       className,
  //       classNumber,
  //       dbDelete
  //     };
  //     console.log("aaaaaaaa", opts);
  //     return opts;
  //   }
  // };

  // submitUpdateFormData = () => {
  //   // const {
  //   //   description,
  //   //   email,
  //   //   name,
  //   //   organization,
  //   //   roleName,
  //   //   displayRoleData,
  //   // } = this.state.content;
  //   // let opts = {};
  //   // let mapping = {};
  //   // mapping["organizationId"] = organization.id;
  //   // mapping["roleId"] = displayRoleData.find((it) => it.name === roleName)?.id;
  //   // opts["patchUserPayloadModel"] = {
  //   //   description: description,
  //   //   email: email,
  //   //   name: name,
  //   //   mapping: [mapping],
  //   // };
  //   const {
  //     memberId,
  //     nameChi,
  //     nameEng,
  //     expireDate,
  //     grade,
  //     className,
  //     classNumber,
  //     dbDelete
  //   } = this.state.content
  //   if (memberId !== undefined) {
  //     let opts: UpdateMemberUsingPATCH1Request = {
  //       memberId,
  //       nameChi,
  //       nameEng,
  //       // expireDate: dateToString(
  //       //   expireDate,
  //       //   "yyyy-MM-DD"
  //       // ),
  //       grade,
  //       className,
  //       classNumber,
  //       dbDelete
  //     };
  //     console.log("aaaaaaaa", opts);
  //     return opts;
  //   }
  // };

  handleSubmit = (value: any) => {
    // this.setState({ ...this.state, loading: true });
    // const { actionType } = this.props;
    // if (actionType === ADD) {
    //     if (!this.checkAddFormVaild()) return;
    //     new UserApi(genApiConfig())
    //         .addUserUsingPOST(this.submitAddFormData())
    //         .then((res: any) => {
    //             console.log("add", res);
    //             if (res.response.status === 202) {
    //                 alert(`電郵已被登記，請使用另一電郵地址開設用戶`);
    //                 this.setState({ ...this.state, loading: false });
    //                 return;
    //             }
    //             if (res.response.status === 200) {
    //                 //console.log("rrrr", res);
    //                 alert(`新增用戶成功 名稱﹕${res.response.body.name}`);
    //                 this.props.callBack({ action: DIALOG_COMPLETE });
    //             }
    //             this.resetState();
    //         })
    //         .catch((err: any) => {
    //             alert(err.body.message);
    //             console.log("err", err.body.message);
    //         });
    //     return;
    // }
    // if (actionType === UPDATE) {
    //     console.log("aaaaaaaaa");
    //     if (!this.checkUpdateFormVaild()) return;
    //     new UserApi(genApiConfig())
    //         .updateUserUsingPATCH(this.state.id, this.submitUpdateFormData())
    //         .then((res: any) => {
    //             console.log("res", res);
    //             if (res.response.ok && res.data.success) {
    //                 alert("更新成功");
    //             } else {
    //                 alert("更新失敗\n" + res.body.message);
    //             }
    //             this.resetState();
    //             this.props.callBack({ action: DIALOG_COMPLETE });
    //         })
    //         .catch((err: any) => {
    //             alert(err.body.message);
    //             console.log("err", err.body.message);
    //         });
    //     return;
    // }

    let dsadasds = 0;

    this.setState({ ...this.state, loading: true });
    const { actionType } = this.props;
    if (actionType === ADD) {
      // if (!this.checkAddFormVaild()) return;
      const {
        username,
        nameChi,
        nameEng,
        password,
        expireDate,
        grade,
        className,
        classNumber,
        dbDelete,
      } = this.state.content;
      // const hashPwd = sha512(email + password);
      // let opts = {};
      if (
        username !== undefined &&
        nameChi !== undefined &&
        nameEng !== undefined &&
        password !== undefined &&
        grade !== undefined &&
        className !== undefined &&
        classNumber !== undefined &&
        expireDate !== undefined &&
        this.checkAddFormVaild()
      ) {
        let opts: AddMemberUsingPOSTRequest = {
          userName: username,
          nameChi,
          nameEng,
          password,
          expireDate: dateToString(expireDate, "yyyy-MM-DD"),
          grade,
          className,
          classNumber,
          dbDelete,
        };
        console.log("AddMemberUsingPOSTRequestOpts", opts);
        new MemberControllerApi(genApiConfig())
          .addMemberUsingPOST(opts)
          .then((res: AddUpdateMemberResponseModel) => {
            console.log("add", res);
            alert(res.message);
            if (res.success) {
              this.props.callBack({ action: DIALOG_COMPLETE });
              this.resetState();
            } else {
              this.setState({ ...this.state, loading: false });
            }
            // if (res.response.status === 202) {
            //   alert(`電郵已被登記，請使用另一電郵地址開設用戶`);
            //   this.setState({ ...this.state, loading: false });
            //   return;
            // }
            // if (res.response.status === 200) {
            //   //console.log("rrrr", res);
            //   alert(`新增用戶成功 名稱﹕${res.response.body.name}`);
            //   this.props.callBack({ action: DIALOG_COMPLETE });
            // }
            // this.resetState();
          })
          .catch((err: any) => {
            console.log("err", err);
            alert(err.body.message);
            console.log("err", err.body.message);
          });
        return;
      } else {
        console.log("Error: undefined");
      }
    }
    if (actionType === EDIT) {
      console.log("aaaaaaaaa");
      // if (!this.checkUpdateFormVaild()) return;
      const {
        memberId,
        nameChi,
        nameEng,
        expireDate,
        grade,
        className,
        classNumber,
        dbDelete,
      } = this.state.content;
      if (memberId !== undefined) {
        let opts: UpdateMemberUsingPATCH1Request = {
          memberId,
          nameChi,
          nameEng,
          expireDate: dateToString(expireDate, "yyyy-MM-DD"),
          grade,
          className,
          classNumber,
          dbDelete,
        };
        console.log("UpdateMemberUsingPATCH1RequestOpts", opts);
        new MemberControllerApi(genApiConfig())
          .updateMemberUsingPATCH1(opts)
          .then((res: AddUpdateMemberResponseModel) => {
            console.log("res", res);
            alert(res.message);
            if (res.success) {
              this.props.callBack({ action: DIALOG_COMPLETE });
              this.resetState();
            } else {
              this.setState({ ...this.state, loading: false });
            }
            // if (res.response.ok && res.data.success) {
            //   alert("更新成功");
            // } else {
            //   alert("更新失敗\n" + res.body.message);
            // }
            // this.resetState();
            // this.props.callBack({ action: DIALOG_COMPLETE });
          })
          .catch((err: any) => {
            alert(err.body.message);
            console.log("err", err.body.message);
          });
        return;
      } else {
        console.log("Error: undefined");
      }
    }
  };

  handleCancel = (value: any) => {
    this.resetState();
    this.props.callBack({ action: DIALOG_COMPLETE });
  };

  resetState = () => {
    this.setState(
      {
        ...this.state,
        isLoading: false,
        actionType: "",
        content: {
          nameChi: "",
          nameEng: "",
          username: "",
          grade: "",
          className: "",
          classNumber: "",
          expireDate: new Date().setFullYear(new Date().getFullYear() + 1),
          dbDelete: false,
          password: "",
        },
        showError: false,
        showResetPassword: false,
      },
      () => {
        console.log("this.state", this.state);
      }
    );
    // const { roleName, organization } = this.state;
    // this.setState({
    //   id: "",
    //   name: "",
    //   email: "",
    //   password: "",
    //   description: "",
    //   roleName: "",
    //   organization: "",
    //   showError: false,
    //   showPwd: false,
    //   loading: false,
    // });
  };

  render() {
    const { content, actionType, showError, showResetPassword, newPassword } =
      this.state;
    if (content)
      if (content.dbDelete === undefined)
        this.setState({
          ...this.state,
          content: Object.assign({
            ...this.state.content,
            dbDelete: false,
          }),
        });
    // console.log("content.dbDelete", content.dbDelete)
    return (
      <Fragment>
        {content !== undefined ? (
          <>
            <div className={style.content_height}>
              <div className={style.container}>
                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.nameChi === "" ||
                        content.nameChi === undefined) &&
                      showError
                        ? false
                        : true
                    } // showError ? (name.length == 0 ? false : true) : true
                    style={{ color: "red" }}
                  >
                    中文名稱必須輸入
                  </label>
                </div>

                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>中文名稱</label>
                  </div>

                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      value={content.nameChi}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) => {
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            nameChi: value,
                          }),
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.nameEng === "" ||
                        content.nameEng === undefined) &&
                      showError
                        ? false
                        : true
                    } // showError ? (name.length == 0 ? false : true) : true
                    style={{ color: "red" }}
                  >
                    英文名稱必須輸入
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>英文名稱</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      value={content.nameEng}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) => {
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            nameEng: value,
                          }),
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.username === "" ||
                        content.username === undefined) &&
                      showError
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                  >
                    請輸入有效的用戶名稱
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>用戶名稱</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      //disabled={actionType === UPDATE ? true : false}
                      value={content.username}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) =>
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            username: value,
                          }),
                        })
                      }
                    />
                  </div>
                </div>

                {actionType === "add" ? (
                  <>
                    <div className={style.gridRowErrorMsg}>
                      <label
                        hidden={
                          (content.password === "" ||
                            content.password === undefined) &&
                          showError
                            ? false
                            : true
                        }
                        style={{ color: "red" }}
                      >
                        請輸入有效的密碼
                      </label>
                    </div>
                    <div className={style.displayflex}>
                      <div className={style.label_style}>
                        <label>密碼</label>
                      </div>
                      <div className={style.input_style}>
                        <Input
                          type={"text"}
                          //disabled={actionType === UPDATE ? true : false}
                          value={content.password}
                          styleType={STYLETYPE_MAIN}
                          callBack={(value) =>
                            this.setState({
                              ...this.state,
                              content: Object.assign({
                                ...this.state.content,
                                password: value,
                              }),
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.grade === "" || content.grade === undefined) &&
                      showError
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                  >
                    請輸入有效的年級
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>年級</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      value={content.grade}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) =>
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            grade: value,
                          }),
                        })
                      }
                    />
                  </div>
                </div>

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.className === "" ||
                        content.className === undefined) &&
                      showError
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                  >
                    請輸入有效的班別名稱
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>班別名稱</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      value={content.className}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) =>
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            className: value,
                          }),
                        })
                      }
                    />
                  </div>
                </div>

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content.classNumber === "" ||
                        content.classNumber === undefined) &&
                      showError
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                  >
                    請輸入有效的班別編號
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>班別編號</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"text"}
                      value={content.classNumber}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) =>
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            classNumber: value,
                          }),
                        })
                      }
                    />
                  </div>
                </div>

                <div className={style.gridRowErrorMsg}>
                  <label
                    hidden={
                      (content?.expireDate?.toString() === "" ||
                        content?.expireDate === undefined) &&
                      showError
                        ? false
                        : true
                    }
                    style={{ color: "red" }}
                  >
                    請輸入有效的日期
                  </label>
                </div>
                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>到期日</label>
                  </div>
                  <div className={style.input_style}>
                    <Input
                      type={"date"}
                      value={dateToString(content.expireDate, "yyyy-MM-DD")}
                      styleType={STYLETYPE_MAIN}
                      callBack={(value) => {
                        this.setState({
                          ...this.state,
                          content: Object.assign({
                            ...this.state.content,
                            expireDate: stringToDate(value),
                          }),
                        });
                        console.log("stringToDate(value)", value);
                        console.log("stringToDate(value)", stringToDate(value));
                      }}
                    />
                  </div>
                </div>

                <div className={style.displayflex}>
                  <div className={style.label_style}>
                    <label>是否有效</label>
                  </div>
                  <div
                    className={style.input_style}
                    style={{ textAlign: "center" }}
                  >
                    <Input
                      type={"checkbox"}
                      value={!content.dbDelete}
                      checked={!content.dbDelete}
                      styleType={STYLETYPE_THIRD}
                      callBack={(value) =>
                        this.setState(
                          {
                            ...this.state,
                            content: Object.assign({
                              ...this.state.content,
                              dbDelete: !value,
                            }),
                          },
                          () => {
                            console.log("adf", this.state);
                          }
                        )
                      }
                    />
                  </div>
                </div>

                {actionType === EDIT ? (
                  <div className={style.displayflex}>
                    {showResetPassword ? (
                      <>
                        <div className={style.label_style}>
                          <Button
                            styleType={STYLETYPE_MAIN}
                            callBack={(value) => {
                              this.resetPassword();
                            }}
                          >
                            儲存密碼
                          </Button>
                        </div>
                        <div className={style.input_style}>
                          <Input
                            type={"text"}
                            value={newPassword}
                            styleType={STYLETYPE_MAIN}
                            callBack={(value) =>
                              this.setState({
                                ...this.state,
                                newPassword: value,
                              })
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <div className={style.label_style}>
                        <Button
                          styleType={STYLETYPE_THIRD}
                          callBack={(value) =>
                            this.setState({
                              ...this.state,
                              showResetPassword: true,
                            })
                          }
                        >
                          重設密碼
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={style.displayflex}>
                    <div className={style.label_style}>
                      <label>密碼</label>
                    </div>
                    <div className={style.input_style}>
                      <Input
                        type={"text"}
                        value={content.password}
                        styleType={STYLETYPE_MAIN}
                        callBack={(value) =>
                          this.setState({
                            ...this.state,
                            content: Object.assign({
                              ...this.state.content,
                              password: value,
                            }),
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={style.button_on_right}>
              <div className={style.margin_left}>
                <Button
                  styleType={STYLETYPE_SECOND}
                  callBack={(value) => this.handleCancel(value)}
                >
                  取消
                </Button>
              </div>
              <div className={style.margin_left}>
                <Button
                  styleType={STYLETYPE_MAIN}
                  callBack={(value) => this.handleSubmit(value)}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "" : "儲存"}
                  <img
                    src={loadingGif}
                    style={{ width: "20px", height: "20px" }}
                    hidden={!this.state.isLoading}
                  />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  //console.log("usersheet state",state)
  return {
    userInfo: state.userInfo,
  };
};
// const actionCreator = { handleUserInfo };
// export default connect(mapStateToProps, actionCreator)(withRouter(UserSheet));
export default UserSheet;
