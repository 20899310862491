/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SetProductOnlineReadSameTimeItem,
    SetProductOnlineReadSameTimeItemFromJSON,
    SetProductOnlineReadSameTimeItemFromJSONTyped,
    SetProductOnlineReadSameTimeItemToJSON,
} from './SetProductOnlineReadSameTimeItem';

/**
 * 
 * @export
 * @interface SetProductOnlineReadSameTime
 */
export interface SetProductOnlineReadSameTime {
    /**
     * 
     * @type {Array<SetProductOnlineReadSameTimeItem>}
     * @memberof SetProductOnlineReadSameTime
     */
    data?: Array<SetProductOnlineReadSameTimeItem>;
}

export function SetProductOnlineReadSameTimeFromJSON(json: any): SetProductOnlineReadSameTime {
    return SetProductOnlineReadSameTimeFromJSONTyped(json, false);
}

export function SetProductOnlineReadSameTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetProductOnlineReadSameTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(SetProductOnlineReadSameTimeItemFromJSON)),
    };
}

export function SetProductOnlineReadSameTimeToJSON(value?: SetProductOnlineReadSameTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(SetProductOnlineReadSameTimeItemToJSON)),
    };
}

