/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BookCollectionBorrowStatusDto,
    BookCollectionBorrowStatusDtoFromJSON,
    BookCollectionBorrowStatusDtoFromJSONTyped,
    BookCollectionBorrowStatusDtoToJSON,
} from './BookCollectionBorrowStatusDto';
import {
    Borrow,
    BorrowFromJSON,
    BorrowFromJSONTyped,
    BorrowToJSON,
} from './Borrow';
import {
    ReserveBorrowDto,
    ReserveBorrowDtoFromJSON,
    ReserveBorrowDtoFromJSONTyped,
    ReserveBorrowDtoToJSON,
} from './ReserveBorrowDto';

/**
 * 
 * @export
 * @interface BookBorrowStatusDto
 */
export interface BookBorrowStatusDto {
    /**
     * 
     * @type {number}
     * @memberof BookBorrowStatusDto
     */
    canBorrowCopy?: number;
    /**
     * 
     * @type {Date}
     * @memberof BookBorrowStatusDto
     */
    canBorrowDate?: Date;
    /**
     * 
     * @type {Array<BookCollectionBorrowStatusDto>}
     * @memberof BookBorrowStatusDto
     */
    collectionBorrowStatusDtos?: Array<BookCollectionBorrowStatusDto>;
    /**
     * 
     * @type {Array<Borrow>}
     * @memberof BookBorrowStatusDto
     */
    lastTenBorrow?: Array<Borrow>;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowStatusDto
     */
    productId?: number;
    /**
     * 
     * @type {Array<ReserveBorrowDto>}
     * @memberof BookBorrowStatusDto
     */
    reserveBorrowStatus?: Array<ReserveBorrowDto>;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowStatusDto
     */
    reserveCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowStatusDto
     */
    status?: number;
}

export function BookBorrowStatusDtoFromJSON(json: any): BookBorrowStatusDto {
    return BookBorrowStatusDtoFromJSONTyped(json, false);
}

export function BookBorrowStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookBorrowStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canBorrowCopy': !exists(json, 'canBorrowCopy') ? undefined : json['canBorrowCopy'],
        'canBorrowDate': !exists(json, 'canBorrowDate') ? undefined : (new Date(json['canBorrowDate'])),
        'collectionBorrowStatusDtos': !exists(json, 'collectionBorrowStatusDtos') ? undefined : ((json['collectionBorrowStatusDtos'] as Array<any>).map(BookCollectionBorrowStatusDtoFromJSON)),
        'lastTenBorrow': !exists(json, 'lastTenBorrow') ? undefined : ((json['lastTenBorrow'] as Array<any>).map(BorrowFromJSON)),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'reserveBorrowStatus': !exists(json, 'reserveBorrowStatus') ? undefined : ((json['reserveBorrowStatus'] as Array<any>).map(ReserveBorrowDtoFromJSON)),
        'reserveCount': !exists(json, 'reserveCount') ? undefined : json['reserveCount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function BookBorrowStatusDtoToJSON(value?: BookBorrowStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canBorrowCopy': value.canBorrowCopy,
        'canBorrowDate': value.canBorrowDate === undefined ? undefined : (value.canBorrowDate.toISOString()),
        'collectionBorrowStatusDtos': value.collectionBorrowStatusDtos === undefined ? undefined : ((value.collectionBorrowStatusDtos as Array<any>).map(BookCollectionBorrowStatusDtoToJSON)),
        'lastTenBorrow': value.lastTenBorrow === undefined ? undefined : ((value.lastTenBorrow as Array<any>).map(BorrowToJSON)),
        'productId': value.productId,
        'reserveBorrowStatus': value.reserveBorrowStatus === undefined ? undefined : ((value.reserveBorrowStatus as Array<any>).map(ReserveBorrowDtoToJSON)),
        'reserveCount': value.reserveCount,
        'status': value.status,
    };
}

