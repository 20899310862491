
import { SortableHandle } from 'react-sortable-hoc';

interface ISortableHandleElement {
    children: React.ReactNode
}

export const DndTrigger: React.ComponentClass<ISortableHandleElement, any> = SortableHandle(
    ({ children }: { children: React.ReactNode }) => (
        <div >{children}</div>
    )
)