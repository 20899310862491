import { ACCESS_TOKEN } from "../../Contants";

export const fetchMD5FromPath = (filePath) => {
  return new Promise((resolve, reject) => {
    var crypto = window.require("crypto");
    var fs = window.require("fs");

    var md5sum = crypto.createHash("md5");
    var s = fs.ReadStream(filePath);
    s.on("data", function (d) {
      md5sum.update(d);
    });

    s.on("end", () => {
      resolve(md5sum.digest("hex"));
    });
    s.on("error", () => {
      reject("");
    });
  });
};
export const fetchFileFromPath = (filePath) => {
  return new Promise((resolve, reject) => {
    const fs = window.require("fs");

    fs.readFile(filePath, (err, data) => {
      if (err) {
        console.error(err);
        return;
      }
      const file = new File(
        [data],
        window.navigator.platform.indexOf("Win") > -1
          ? filePath.split("\\").reverse()[0]
          : filePath.split("/").reverse()[0],
        {
          lastModified: new Date().getTime(),
        }
      );
      resolve(file);
    });
  });
};

export const fetchFileFromURLToFile = async (url, fileName) => {
  console.log("fetchFileFromURLToFile url, fileName, body", url, fileName);

  let blob = await fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  }).then((r) =>
    r.blob().then(async (b) => {
      //var blob = new Blob([body], { type: "octet/stream" });
      console.log("fetchFileFromURLToFile blob", await b);
      new Promise((resolve, reject) => {
        const file = new File([b], fileName, {
          lastModified: new Date().getTime(),
        });
        resolve(file);
      })
        .then((res) => {
          if (res !== null) {
            let aTag = document.createElement("a");
            aTag.href = window.URL.createObjectURL(res);
            aTag.download = fileName;
            aTag.click();
          } else {
            console.log("下載文件失敗");
          }
        })
        .catch((e) => {
          console.log(`下載文件失敗 : ${e}`);
        });
    })
  );
};

export const fetchFileFromURLToFilePost = async (url, fileName, body) => {
  console.log(
    "fetchFileFromURLToFile url, fileName, body",
    url,
    fileName,
    body
  );

  //console.log("access", localStorage.getItem(ACCESS_TOKEN));

  let blob = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      mode: "cors",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  }).then(async (r) => {
    r.blob().then(async (b) => {
      console.log("fetchFileFromURLToFile blob", await b);
      new Promise((resolve, reject) => {
        const file = new File([b], fileName, {
          lastModified: new Date().getTime(),
        });
        resolve(file);
      })
        .then((res) => {
          if (res !== null) {
            let aTag = document.createElement("a");
            aTag.href = window.URL.createObjectURL(res);
            aTag.download = fileName;
            aTag.click();
          } else {
            console.log("下載文件失敗");
          }
        })
        .catch((e) => {
          console.log(`下載文件失敗 : ${e}`);
        });
    });
  });

  //var blob = new Blob([body], { type: "octet/stream" });
};

export const fetchFileFromURL = async (url, fileName) => {
  let blob = await fetch(url).then((r) => r.blob());
  return new Promise((resolve, reject) => {
    const file = new File([blob], fileName, {
      lastModified: new Date().getTime(),
    });
    resolve(file);
  });
};

export const fetchImageFromURL = async (url, fileName) => {
  let blob = await fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  }).then((r) => r.blob());
  return new Promise((resolve, reject) => {
    const file = new File([blob], fileName, {
      lastModified: new Date().getTime(),
    });
    resolve(file);
  });
};

export const fetchBlobImageFromURL = async (url, fileName) => {
  //console.log("blob", fileName);
  let blob = await fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
    },
  }).then((r) => r.blob());

  return new Promise((resolve, reject) => {
    resolve({ blob, fileName });
  });
};

export const fetchPdfFromURL = async (url, fileName) => {
  var pdfjsLib = window["pdfjs-dist/build/pdf"];
  await new Promise((resolve, reject) => {
    var res = pdfjsLib.getDocument(url);
    const file = new File([res], fileName, {
      lastModified: new Date().getTime(),
    });
    resolve(file);
  });
};
