/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminMemberRole,
    AdminMemberRoleFromJSON,
    AdminMemberRoleFromJSONTyped,
    AdminMemberRoleToJSON,
} from './AdminMemberRole';

/**
 * 
 * @export
 * @interface AdminMemberDto
 */
export interface AdminMemberDto {
    /**
     * 
     * @type {number}
     * @memberof AdminMemberDto
     */
    adminMemberId?: number;
    /**
     * 
     * @type {AdminMemberRole}
     * @memberof AdminMemberDto
     */
    adminMemberRole?: AdminMemberRole;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    lastname?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberDto
     */
    memberRole?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    memberType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    username?: string;
}

export function AdminMemberDtoFromJSON(json: any): AdminMemberDto {
    return AdminMemberDtoFromJSONTyped(json, false);
}

export function AdminMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminMemberId': !exists(json, 'adminMemberId') ? undefined : json['adminMemberId'],
        'adminMemberRole': !exists(json, 'adminMemberRole') ? undefined : AdminMemberRoleFromJSON(json['adminMemberRole']),
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'memberRole': !exists(json, 'memberRole') ? undefined : json['memberRole'],
        'memberType': !exists(json, 'memberType') ? undefined : json['memberType'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function AdminMemberDtoToJSON(value?: AdminMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminMemberId': value.adminMemberId,
        'adminMemberRole': AdminMemberRoleToJSON(value.adminMemberRole),
        'firstname': value.firstname,
        'lastname': value.lastname,
        'memberRole': value.memberRole,
        'memberType': value.memberType,
        'username': value.username,
    };
}

