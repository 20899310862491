/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDtoLite,
    OrderDtoLiteFromJSON,
    OrderDtoLiteFromJSONTyped,
    OrderDtoLiteToJSON,
} from './OrderDtoLite';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';
import {
    ProductPointDto,
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface OrderProductDto2
 */
export interface OrderProductDto2 {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    finalPrice?: number;
    /**
     * 
     * @type {OrderDtoLite}
     * @memberof OrderProductDto2
     */
    order?: OrderDtoLite;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    orderProductId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof OrderProductDto2
     */
    product?: ProductDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    productId?: number;
    /**
     * 
     * @type {ProductPointDto}
     * @memberof OrderProductDto2
     */
    productPoint?: ProductPointDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    usedPoint?: number;
}

export function OrderProductDto2FromJSON(json: any): OrderProductDto2 {
    return OrderProductDto2FromJSONTyped(json, false);
}

export function OrderProductDto2FromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDto2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryTimes': !exists(json, 'deliveryTimes') ? undefined : json['deliveryTimes'],
        'finalPrice': !exists(json, 'finalPrice') ? undefined : json['finalPrice'],
        'order': !exists(json, 'order') ? undefined : OrderDtoLiteFromJSON(json['order']),
        'orderProductId': !exists(json, 'orderProductId') ? undefined : json['orderProductId'],
        'product': !exists(json, 'product') ? undefined : ProductDtoFromJSON(json['product']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPoint': !exists(json, 'productPoint') ? undefined : ProductPointDtoFromJSON(json['productPoint']),
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
    };
}

export function OrderProductDto2ToJSON(value?: OrderProductDto2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryTimes': value.deliveryTimes,
        'finalPrice': value.finalPrice,
        'order': OrderDtoLiteToJSON(value.order),
        'orderProductId': value.orderProductId,
        'product': ProductDtoToJSON(value.product),
        'productId': value.productId,
        'productPoint': ProductPointDtoToJSON(value.productPoint),
        'productPrice': value.productPrice,
        'quantity': value.quantity,
        'usedPoint': value.usedPoint,
    };
}

