/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CouponDto,
    CouponDtoFromJSON,
    CouponDtoFromJSONTyped,
    CouponDtoToJSON,
} from './CouponDto';
import {
    DisplayProductDto,
    DisplayProductDtoFromJSON,
    DisplayProductDtoFromJSONTyped,
    DisplayProductDtoToJSON,
} from './DisplayProductDto';
import {
    MarketingEventDto,
    MarketingEventDtoFromJSON,
    MarketingEventDtoFromJSONTyped,
    MarketingEventDtoToJSON,
} from './MarketingEventDto';
import {
    MemberLevelDto,
    MemberLevelDtoFromJSON,
    MemberLevelDtoFromJSONTyped,
    MemberLevelDtoToJSON,
} from './MemberLevelDto';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    actionValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    condition?: string;
    /**
     * 
     * @type {CouponDto}
     * @memberof DiscountDto
     */
    coupon?: CouponDto;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountType?: string;
    /**
     * 
     * @type {Array<DisplayProductDto>}
     * @memberof DiscountDto
     */
    displayProducts?: Array<DisplayProductDto>;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {Array<MarketingEventDto>}
     * @memberof DiscountDto
     */
    marketingEvents?: Array<MarketingEventDto>;
    /**
     * 
     * @type {Array<MemberLevelDto>}
     * @memberof DiscountDto
     */
    memberLevels?: Array<MemberLevelDto>;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof DiscountDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    target?: string;
}

export function DiscountDtoFromJSON(json: any): DiscountDto {
    return DiscountDtoFromJSONTyped(json, false);
}

export function DiscountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionValue': !exists(json, 'actionValue') ? undefined : json['actionValue'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'coupon': !exists(json, 'coupon') ? undefined : CouponDtoFromJSON(json['coupon']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'discountTitle': !exists(json, 'discountTitle') ? undefined : json['discountTitle'],
        'discountType': !exists(json, 'discountType') ? undefined : json['discountType'],
        'displayProducts': !exists(json, 'displayProducts') ? undefined : ((json['displayProducts'] as Array<any>).map(DisplayProductDtoFromJSON)),
        'limitToUse': !exists(json, 'limitToUse') ? undefined : json['limitToUse'],
        'limitToUseRest': !exists(json, 'limitToUseRest') ? undefined : json['limitToUseRest'],
        'marketingEvents': !exists(json, 'marketingEvents') ? undefined : ((json['marketingEvents'] as Array<any>).map(MarketingEventDtoFromJSON)),
        'memberLevels': !exists(json, 'memberLevels') ? undefined : ((json['memberLevels'] as Array<any>).map(MemberLevelDtoFromJSON)),
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function DiscountDtoToJSON(value?: DiscountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionType': value.actionType,
        'actionValue': value.actionValue,
        'condition': value.condition,
        'coupon': CouponDtoToJSON(value.coupon),
        'discountId': value.discountId,
        'discountTitle': value.discountTitle,
        'discountType': value.discountType,
        'displayProducts': value.displayProducts === undefined ? undefined : ((value.displayProducts as Array<any>).map(DisplayProductDtoToJSON)),
        'limitToUse': value.limitToUse,
        'limitToUseRest': value.limitToUseRest,
        'marketingEvents': value.marketingEvents === undefined ? undefined : ((value.marketingEvents as Array<any>).map(MarketingEventDtoToJSON)),
        'memberLevels': value.memberLevels === undefined ? undefined : ((value.memberLevels as Array<any>).map(MemberLevelDtoToJSON)),
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductDtoToJSON)),
        'status': value.status,
        'target': value.target,
    };
}

