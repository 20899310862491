/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleHashtag,
    ArticleHashtagFromJSON,
    ArticleHashtagFromJSONTyped,
    ArticleHashtagToJSON,
} from './ArticleHashtag';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import {
    DisplayProductArticle,
    DisplayProductArticleFromJSON,
    DisplayProductArticleFromJSONTyped,
    DisplayProductArticleToJSON,
} from './DisplayProductArticle';
import {
    MarketingEventArticle,
    MarketingEventArticleFromJSON,
    MarketingEventArticleFromJSONTyped,
    MarketingEventArticleToJSON,
} from './MarketingEventArticle';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    articleCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleContent?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleCoverImage?: string;
    /**
     * 
     * @type {Array<ArticleHashtag>}
     * @memberof Article
     */
    articleHashtagsByArticleId?: Array<ArticleHashtag>;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleTitle?: string;
    /**
     * 
     * @type {Category}
     * @memberof Article
     */
    categoryByArticleCategory?: Category;
    /**
     * 
     * @type {Array<DisplayProductArticle>}
     * @memberof Article
     */
    displayProductArticlesByArticleId?: Array<DisplayProductArticle>;
    /**
     * 
     * @type {Array<MarketingEventArticle>}
     * @memberof Article
     */
    marketingEventArticlesByArticleId?: Array<MarketingEventArticle>;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    status?: string;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleCategory': !exists(json, 'articleCategory') ? undefined : json['articleCategory'],
        'articleContent': !exists(json, 'articleContent') ? undefined : json['articleContent'],
        'articleCoverImage': !exists(json, 'articleCoverImage') ? undefined : json['articleCoverImage'],
        'articleHashtagsByArticleId': !exists(json, 'articleHashtagsByArticleId') ? undefined : ((json['articleHashtagsByArticleId'] as Array<any>).map(ArticleHashtagFromJSON)),
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'articleTitle': !exists(json, 'articleTitle') ? undefined : json['articleTitle'],
        'categoryByArticleCategory': !exists(json, 'categoryByArticleCategory') ? undefined : CategoryFromJSON(json['categoryByArticleCategory']),
        'displayProductArticlesByArticleId': !exists(json, 'displayProductArticlesByArticleId') ? undefined : ((json['displayProductArticlesByArticleId'] as Array<any>).map(DisplayProductArticleFromJSON)),
        'marketingEventArticlesByArticleId': !exists(json, 'marketingEventArticlesByArticleId') ? undefined : ((json['marketingEventArticlesByArticleId'] as Array<any>).map(MarketingEventArticleFromJSON)),
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ArticleToJSON(value?: Article | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleCategory': value.articleCategory,
        'articleContent': value.articleContent,
        'articleCoverImage': value.articleCoverImage,
        'articleHashtagsByArticleId': value.articleHashtagsByArticleId === undefined ? undefined : ((value.articleHashtagsByArticleId as Array<any>).map(ArticleHashtagToJSON)),
        'articleId': value.articleId,
        'articleTitle': value.articleTitle,
        'categoryByArticleCategory': CategoryToJSON(value.categoryByArticleCategory),
        'displayProductArticlesByArticleId': value.displayProductArticlesByArticleId === undefined ? undefined : ((value.displayProductArticlesByArticleId as Array<any>).map(DisplayProductArticleToJSON)),
        'marketingEventArticlesByArticleId': value.marketingEventArticlesByArticleId === undefined ? undefined : ((value.marketingEventArticlesByArticleId as Array<any>).map(MarketingEventArticleToJSON)),
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'status': value.status,
    };
}

