import style from "./CategoryBookCardView.module.css";

import useWindowSize from "../../../utils/useWindowSize";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {COLLECTION_ID} from "../../../utils/Contants";
const CategoryBookCardView = ({ props }: any) => {
    const navigate = useNavigate();
    const handleEditCallBack = () => {};

    const { width, height } = useWindowSize();
    const { i18n } = useTranslation();
    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });

    const onClick = () =>{
        let pathname = window.location.pathname

        if(pathname.includes("/category/")){
            let collectionCategoryId = pathname.replace("/category/", "")
            localStorage.setItem(COLLECTION_ID, collectionCategoryId)
        }

        navigate("/product/" + props.productId)
    }

    return (
        <>
            <div className={width <= 768 ? style.TableCardViewContainer2 : style.TableCardViewContainer} onClick={() => onClick()}>
                <div className={style.TableCardViewContent}>
                    <div>
                        <h5> {i18n.language === "cn" ? converter(props.productNameChi) : props.productNameChi}</h5>
                    </div>
                    <div style={{ fontSize: "12px" }}>{i18n.language === "cn" ? converter(props.author) : props.author}</div>
                    <div className={style.content}>{i18n.language === "cn" ? converter(props.productDescription) : props.productDescription}</div>
                </div>

                <div className={style.TableCardView_IMG}>
                    {/*<img src={process.env.REACT_APP_BACKEND + "/file/" + props.coverFileName} />*/}
                    <img src={`https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${props.coverFileName}?x-oss-process=image/resize,w_140`}/>
                </div>
            </div>
        </>
    );
};

export default CategoryBookCardView;
