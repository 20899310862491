import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";

import { routes } from "./global/Routes";

import {
  initSystemFont,
  initTheme,
} from "./utils/reader/serviceUtils/launchUtil";

function App() {
  const element = useRoutes(routes());

  useEffect(() => {
    initTheme();
    initSystemFont();
  }, []);

  return <>{element}</>;
}

export default App;
