/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddProductInventoryItem,
    AddProductInventoryItemFromJSON,
    AddProductInventoryItemFromJSONTyped,
    AddProductInventoryItemToJSON,
} from './AddProductInventoryItem';

/**
 * 
 * @export
 * @interface AddProductInventory
 */
export interface AddProductInventory {
    /**
     * 
     * @type {Array<AddProductInventoryItem>}
     * @memberof AddProductInventory
     */
    data?: Array<AddProductInventoryItem>;
}

export function AddProductInventoryFromJSON(json: any): AddProductInventory {
    return AddProductInventoryFromJSONTyped(json, false);
}

export function AddProductInventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddProductInventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AddProductInventoryItemFromJSON)),
    };
}

export function AddProductInventoryToJSON(value?: AddProductInventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AddProductInventoryItemToJSON)),
    };
}

