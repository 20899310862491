/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenModel
 */
export interface TokenModel {
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    refreshToken?: string;
}

export function TokenModelFromJSON(json: any): TokenModel {
    return TokenModelFromJSONTyped(json, false);
}

export function TokenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
    };
}

export function TokenModelToJSON(value?: TokenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
    };
}

