/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookDetailsReportResponseModel
 */
export interface BookDetailsReportResponseModel {
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    author?: string;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    bookId?: number;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    bookName?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    cate?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    dateMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    dateYear?: number;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    inventory?: number;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    isbn?: string;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    onlineRead?: number;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    publishHouse?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    purchaseType?: string;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetailsReportResponseModel
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof BookDetailsReportResponseModel
     */
    year?: number;
}

export function BookDetailsReportResponseModelFromJSON(json: any): BookDetailsReportResponseModel {
    return BookDetailsReportResponseModelFromJSONTyped(json, false);
}

export function BookDetailsReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookDetailsReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookId': !exists(json, 'bookId') ? undefined : json['bookId'],
        'bookName': !exists(json, 'bookName') ? undefined : json['bookName'],
        'cate': !exists(json, 'cate') ? undefined : json['cate'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'dateMonth': !exists(json, 'dateMonth') ? undefined : json['dateMonth'],
        'dateYear': !exists(json, 'dateYear') ? undefined : json['dateYear'],
        'inventory': !exists(json, 'inventory') ? undefined : json['inventory'],
        'isbn': !exists(json, 'isbn') ? undefined : json['isbn'],
        'onlineRead': !exists(json, 'onlineRead') ? undefined : json['onlineRead'],
        'publishHouse': !exists(json, 'publishHouse') ? undefined : json['publishHouse'],
        'purchaseType': !exists(json, 'purchaseType') ? undefined : json['purchaseType'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'subCategory': !exists(json, 'subCategory') ? undefined : json['subCategory'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function BookDetailsReportResponseModelToJSON(value?: BookDetailsReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'bookId': value.bookId,
        'bookName': value.bookName,
        'cate': value.cate,
        'category': value.category,
        'count': value.count,
        'dateMonth': value.dateMonth,
        'dateYear': value.dateYear,
        'inventory': value.inventory,
        'isbn': value.isbn,
        'onlineRead': value.onlineRead,
        'publishHouse': value.publishHouse,
        'purchaseType': value.purchaseType,
        'seq': value.seq,
        'subCategory': value.subCategory,
        'url': value.url,
        'year': value.year,
    };
}

