import moment from "moment";

interface IArgs {
  [key: string]: string;
}

export function getFileName(val: string) {
  var filename = val?.split("\\")?.pop()?.split("/").pop();
  filename = filename?.substring(0, filename.lastIndexOf("."));
  return filename;
}

//check email
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
    );
};

export function getFileExtension(filename: string) {
  return /[^.]+$/.exec(filename)?.[0];
}

export function getArgsQuery() {
  var searchStr =
    window.location.search.length > 0
      ? window.location.search.substring(1)
      : "";

  var searchStrArray = searchStr.length > 0 ? searchStr.split("&") : [];

  var args: IArgs = {};
  searchStrArray.forEach(function (item) {
    var name = decodeURIComponent(item.split("=")[0]);

    var value = decodeURIComponent(item.split("=")[1]);
    args[name] = value;
  });
  return args;
}

export function getHashQuery() {
  var hashStr =
    window.location.hash.length > 0 ? window.location.hash.substring(1) : "";

  var hashStrArray = hashStr.length > 0 ? hashStr.split("&") : [];

  var args: IArgs = {};
  hashStrArray.forEach(function (item) {
    var name = decodeURIComponent(item.split("=")[0]);

    var value = decodeURIComponent(item.split("=")[1]);
    args[name] = value;
  });
  return args;
}

export function toParams(query: string) {
  const q = query.replace(/^(\?|#)/, "");

  return q.split("&").reduce((values: any, param: any) => {
    const [key, value] = param.split("=");

    return { ...values, [key]: decodeURIComponent(value) };
  }, {});
}
export function toQuery(params: any, delimiter = "&") {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < keys.length - 1) {
      query += delimiter;
    }

    return query;
  }, "");
}

export const dateToString = (value: any, pattern: string = "YYYY-MM-DD") => {
  return moment(value).format(pattern).toString();
};

export const stringToDate = (
  value: any,
  pattern: string = "YYYY-MM-DD"
): Date => {
  return moment(value, pattern).toDate();
};

export function Uint8ArrayToString(fileData: any) {
  var dataString = "";
  for (var i = 0; i < fileData.length; i++) {
    dataString += String.fromCharCode(fileData[i]);
  }

  return dataString;
}

export function groupBy(objectArray: any, property: any) {
  return objectArray.reduce((acc: any, obj: any) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}
