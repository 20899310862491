import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";
import style from "./Dropzone.module.css";

const Dropzone = (props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    props,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {parseFloat(file.size / 1024).toFixed(2)} KB
    </li>
  ));

  useEffect(() => {
    props.callBack(acceptedFiles);
  }, [acceptedFiles]);

  //    console.log("acceptedFiles", acceptedFiles);

  return (
    <>
      <section className={style.container}>
        <input {...getInputProps()} />
        <div {...getRootProps({ className: "dropzone" })}>
          {acceptedFiles.length == 0 ? (
            <p>Drop some files here, or click to select files</p>
          ) : (
            <></>
          )}
        </div>
        <aside className={style.content_file}>
          <h4>Files</h4>
          <ul>
            <h5>{files}</h5>
          </ul>
        </aside>
      </section>
    </>
  );
};

export default Dropzone;
