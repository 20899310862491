import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import logo2 from "../../assets/lib_suep.jpg";
import logo1 from "../../assets/logo.png";
import loadingGif from "../../assets/svg/loading.gif";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import {
  AuthLoginUsingPOSTRequest,
  CheckSSoUserUsingPOSTRequest,
  MemberControllerApi,
  RoleDto,
  SsoUserDto,
} from "../../fetcher";
import { SsoUserResponse } from "../../fetcher/models/SsoUserResponse";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants"; //"../../global/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import useWindowSize from "../../utils/useWindowSize";
import style from "./Login.module.css";
import * as LocalStorage from "../../utils/local-storage";

function Login(props: any) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hashPassword, setHashPassword] = useState("");
  const [useHash, setUseHash] = useState(false);
  const [remember, setRemember] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [isIpSubmit, setIsIpSubmit] = useState(false);

  useEffect(() => {
    if (props.userInfo !== undefined) {
      console.log("props", props.userInfo);
      if (Object.keys(props.userInfo).length > 0) {
        if (props.userInfo.isAdmin) {
          navigate(PATH.ADMIN);
          return;
        } else {
          navigate(PATH.STUDENT);
          return;
        }
      }
    }
  }, []);

  function onInputFormKeyDown(e: any) {
    if (e.key === 'Enter') {
      console.log("Key : Enter")
      handleLoginClick()
    }
  }

  const handleLoginClick = () => {
    if (email.length === 0) {
      alert("帳號不可為空");
      return
    }

    if (password.length === 0) {
      alert("密碼不可為空");
      return
    }

    fetch(
      `${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/token?grant_type=${process.env.REACT_APP_GRANT_TYPE}&username=${email}&password=${password}&scope=app`,
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
          )}`,
        },
      }
    )
      .then(async (res) => {
        return await res.json();
      })
      .then(async (res) => {
        //console.log("rrrr", res);
        if (res.hasOwnProperty("error")) {
          new MemberControllerApi(genApiConfig())
            .authLoginUsingPOST({
              username: email,
              password: password,
            } as AuthLoginUsingPOSTRequest)
            .then((loginRes) => {
              //console.log("loginRes", loginRes);
              if (loginRes.member === undefined) {
                if (loginRes.adminMember === undefined) {
                  alert("存取被拒");
                } else {
                  let newObj = Object.assign({
                    isAdmin: true,
                    member: loginRes.adminMember!!,
                  });

                  localStorage.setItem(PATH.USER_INFO, JSON.stringify(newObj));
                  localStorage.setItem(PATH.ACCESS_TOKEN, loginRes.token!!);
                  localStorage.setItem(
                    PATH.REFRESH_TOKEN,
                    loginRes.refreshToken!!
                  );
                  props.handleUserInfo(newObj);
                  navigate(PATH.HOME_PATH);
                  // navigate(-1);
                }
              } else {
                //console.log("loginRes2", loginRes);
                let newObj = Object.assign({
                  isAdmin: false,
                  member: loginRes.member,
                });

                localStorage.setItem(PATH.USER_INFO, JSON.stringify(newObj));
                localStorage.setItem(PATH.ACCESS_TOKEN, loginRes.token!!);
                localStorage.setItem(
                  PATH.REFRESH_TOKEN,
                  loginRes.refreshToken!!
                );
                props.handleUserInfo(newObj);
                navigate(PATH.HOME_PATH);
                // navigate(-1);

                // console.log("aaa", loginRes);
              }
            })
            .catch((err) => {
              console.log("login err", err);
              alert("存取被拒");
            });
          return;
        } else {
          fetch(
            `${process.env.REACT_APP_RESOURCE_SERVER_URL}/user/showPersonalDetail`,
            {
              method: "GET",
              headers: {
                Authorization: `bearer ${await res.access_token}`,
              },
            }
          ).then((reso) => {
            reso.json().then(async (res) => {
              if (res.hasOwnProperty("error")) {
                alert(res.error_description);
                return;
              }
              let newObj = {};

              console.log("adfas", res.role[0]);

              new MemberControllerApi(genApiConfig())
                .checkSSoUserUsingPOST({
                  username: res.name,
                  ssoUserDto: {
                    age: res.age,
                    className: res.className,
                    classNumber: res.classNumber,
                    email: res.email,
                    gender: res.gender,
                    grade: res.grade,
                    hashPassword: res.hashPassword,
                    role: {
                      description: res.role[0].description,
                      name: res.role[0].name,
                      roleId: res.role[0].id,
                      isAdmin: res.role[0].isAdmin,
                    } as RoleDto,
                    userId: res.userId,
                  } as SsoUserDto,
                } as CheckSSoUserUsingPOSTRequest)
                .then((resLibrary: SsoUserResponse) => {
                  if (resLibrary.adminMember !== undefined) {
                    newObj = Object.assign({
                      ...res,
                      isAdmin: res.role[0].isAdmin,
                      member: resLibrary.adminMember,
                    });
                  }

                  if (resLibrary.member !== undefined) {
                    newObj = Object.assign({
                      ...res,
                      isAdmin: res.role[0].isAdmin,
                      member: resLibrary.member,
                    });
                  }

                  console.log("userInfo", newObj);
                  localStorage.setItem(PATH.ACCESS_TOKEN, resLibrary.token!!);
                  localStorage.setItem(
                    PATH.REFRESH_TOKEN,
                    resLibrary.refreshToken!!
                  );
                  localStorage.setItem(PATH.USER_INFO, JSON.stringify(newObj));
                  props.handleUserInfo(newObj);
                  navigate(PATH.HOME_PATH);
                  // navigate(-1);
                });
            });
          });
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  const handleOpenIdClick = () => {};

  return (
    <>
      <div
        className={width <= 768 ? style.loginContainer2 : style.loginContainer}
      >
        <div className={style.row1}>
          <div className={style.logoTitleDiv}>
            <img src={logo1} className={style.logoImg} />
            <img src={logo2} className={style.logoImg} />
          </div>
        </div>
        <div className={style.row2}>
          <label htmlFor="email">
            <Trans>LoginName</Trans>
          </label>
        </div>
        <div className={style.row3}>
          <Input
            type="text"
            value={email}
            styleType={"loginInput"}
            callBack={(value) => setEmail(value)}
            onKeyDown={onInputFormKeyDown}
          />
        </div>
        <div className={style.row4}>
          <label htmlFor="password">
            <Trans>Password</Trans>
          </label>
        </div>
        <div className={style.row5}>
          <Input
            type="password"
            value={password}
            styleType={"loginInput"}
            callBack={(value) => setPassword(value)}
            onKeyDown={onInputFormKeyDown}
          />
        </div>
        <div className={style.row6}>
          <div className={style.row6sub1}>
            <Input
              type="checkbox"
              styleType={"checkbox"}
              checked={remember}
              callBack={(value) => setRemember(value)}
            />
          </div>
          <div className={style.row6sub2}>
            <Trans>Remember</Trans>
          </div>
          {/* <div className={style.row6sub3}>
            <NavLink to={PATH.FORGET}>
              <Trans>ForgetPw</Trans>
            </NavLink>
          </div> */}
        </div>{" "}
        {/* * */}
        <div className={style.row7}>
          {/* <Link to={PATH.ADMIN}> */}

          <div className={style.LoginbtnRow}>
            <Button
              styleType={PATH.STYLETYPE_MAIN}
              callBack={(value) => handleLoginClick()}
              disabled={isSubmit || isIpSubmit}
            >
              {isSubmit ? "" : <Trans>login</Trans>}
              <img
                src={loadingGif}
                style={{
                  margin: "0 auto",
                  width: "30px",
                  height: "30px",
                  display: isSubmit ? "block" : "none",
                }}
              />
            </Button>

            {/* <button
            onClick={() => {
              window.parent.parent.location =
                "https://uatepas.hkpl.gov.hk/sso/oauth2/authorize?response_type=code%20id_token&client_id=EV-Ebook-500&realm=%2F&scope=openid&redirect_uri=https://www.nblib.com/library-sso/endpoint&nonce=n-0S6_WzA2Mj&acr_values=epas&state=epasslogin";
            }}
          >
            openId Login1
          </button>

          <button
            onClick={() => {
              window.parent.parent.location =
                "http://uatepas.hkpl.gov.hk/sso/UI/Login?realm=%2F&service=epasac&goto=http%3A%2F%2Fuatepas.hkpl.gov.hk%2Fsso%2Foauth2%2Fauthorize%3Fresponse_type%3Dcode%2520token%2520id_token%26client_id%3DEV-Ebook-600%26realm%3D%252F%26scope%3Dopenid%2520profile%26redirect_uri%3Dhttps%253A%252F%252Fsueplib.octopus-tech.com%252Flibrary-sso%252Fendpoint%26nonce%3Dn-0S6_WzA2Mj%26acr_values%3Depas%26state%3Depasslogin";
            }}
          >
            openId Login2
          </button> */}

            {/* Final use by HKPL */}
            {/* {process.env.REACT_APP_BACKEND ==
              "https://suepsso.octopus-tech.com" ||
            window.location.host.includes("hkpl") ||
            process.env.REACT_APP_DOMAIN === "hkpl" ? (
              <Button
                styleType={PATH.STYLETYPE_MAIN}
                callBack={(value) => {
                  window.parent.parent.location =
                    //"https://uatepas.hkpl.gov.hk/sso/oauth2/authorize?response_type=code%20token%20id_token&client_id=EV-Ebook-650&realm=%2F&scope=openid%20profile&redirect_uri=https://sueplib.octopus-tech.com&nonce=n-0S6_WzA2Mj&acr_values=epas&state=epasslogin";
                    //"http://uatepas.hkpl.gov.hk/openid/disclaimerm.jsp?target=http%3A%2F%2Fuatepas.hkpl.gov.hk%2Fsso%2Foauth2%2Fauthorize%3Fresponse_type%3Dcode%2520token%2520id_token%26client_id%3DEV-Ebook-600%26realm%3D%252F%26scope%3Dopenid%2520profile%26redirect_uri=https://sueplib.octopus-tech.com%26nonce%3Dn-0S6_WzA2Mj%26acr_values%3Depas%26state%3Depasslogin";
                    "http://uatepas.hkpl.gov.hk/openid/disclaimerm.jsp?target=http%3A%2F%2Fuatepas.hkpl.gov.hk%2Fsso%2Foauth2%2Fauthorize%3Fresponse_type%3Dcode%2520token%2520id_token%26client_id%3DEV-Ebook-650%26realm%3D%252F%26scope%3Dopenid%2520profile%26redirect_uri=https://sueplib.octopus-tech.com%26nonce%3Dn-0S6_WzA2Mj%26acr_values%3Depas%26state%3Depasslogin";
                }}
                disabled={isSubmit || isIpSubmit}
              >
                香港公共圖書館登入
              </Button>
            ) : (
              <></>
            )} */}
            {/* Final use by HKPL */}

            {/* <button
            onClick={() => {
              window.parent.parent.location =
                " http://uatepas.hkpl.gov.hk/openid/disclaimerm.jsp?target=http%3A%2F%2Fuatepas.hkpl.gov.hk%2Fsso%2Foauth2%2Fauthorize%3Fresponse_type%3Dcode%2520token%2520id_token%26client_id%3DEV-Ebook-600%26realm%3D%252F%26scope%3Dopenid%2520profile%26redirect_uri%3Dhttps%253A%252F%252Fsueplib.octopus-tech.com%252Flibrary-sso%252Fendpoint%26nonce%3Dn-0S6_WzA2Mj%26acr_values%3Depas%26state%3Depasslogin";
            }}
          >
            openId disclaimerm Login3
          </button> */}
          </div>

          {/* <OAuth2Login
            authorizationUrl="https://uatepas.hkpl.gov.hk/sso/oauth2/authorize"
            responseType="code%20id_token"
            grantType={process.env.REACT_APP_GRANT_TYPE}
            clientId={process.env.REACT_APP_CLIENT_ID}
            clientSecret={process.env.REACT_APP_CLIENT_SECRET}
            scope={process.env.REACT_APP_SCOPE}
            redirectUri={process.env.REACT_APP_REDIRECT_URI}
            onSuccess={(res) => setSsoResult(res)}
            onFailure={(err) => alert("登入失敗")}
          /> */}

          {/* </Link> */}
        </div>
        <div className={style.row8}>
          備注:
          若不清楚帳號密碼，可向學校圖書館詢問。或向平台客服cs@suep.com查詢。(請附學校名稱、班級及姓名)
        </div>
        {/* <div className={style.row8}>
                        <Button
                            styleType={STYLETYPE_THIRD}
                            
                            callBack={value => handleIpLoginClick(value)}
                            disabled={isIpSubmit || isSubmit}
                        >
                            {isIpSubmit ? "" : "直接使用IP登入"}
                            <img src={loadingGif} style={{
                                margin: "0 45% 0",
                                width: "30px",
                                height: "30px",
                                display: isIpSubmit ? 'block' : 'none'
                            }} />
                        </Button>
                    </div> */}
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};

export default connect(mapStateToProps, actionCreator)(Login);
