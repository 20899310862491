/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProduct,
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';

/**
 * 
 * @export
 * @interface CartProductDiscount
 */
export interface CartProductDiscount {
    /**
     * 
     * @type {CartProduct}
     * @memberof CartProductDiscount
     */
    cartProductByCartProductId?: CartProduct;
    /**
     * 
     * @type {number}
     * @memberof CartProductDiscount
     */
    cartProductId?: number;
    /**
     * 
     * @type {Discount}
     * @memberof CartProductDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof CartProductDiscount
     */
    discountId?: number;
}

export function CartProductDiscountFromJSON(json: any): CartProductDiscount {
    return CartProductDiscountFromJSONTyped(json, false);
}

export function CartProductDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartProductDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartProductByCartProductId': !exists(json, 'cartProductByCartProductId') ? undefined : CartProductFromJSON(json['cartProductByCartProductId']),
        'cartProductId': !exists(json, 'cartProductId') ? undefined : json['cartProductId'],
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
    };
}

export function CartProductDiscountToJSON(value?: CartProductDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartProductByCartProductId': CartProductToJSON(value.cartProductByCartProductId),
        'cartProductId': value.cartProductId,
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
    };
}

