/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgeGroupDto,
    AgeGroupDtoFromJSON,
    AgeGroupDtoToJSON,
} from '../models';

export interface AddAgeGroupUsingGETRequest {
    ageGroupName: string;
    name?: string;
}

export interface DeleteUsingGETRequest {
    ageGroupId: number;
    name?: string;
}

export interface GetAgeGroupByAgeGroupIdUsingGETRequest {
    ageGroupId: number;
}

export interface UpdateAgeGroupUsingGETRequest {
    ageGroupId: number;
    ageGroupName: string;
    name?: string;
}

/**
 * 
 */
export class AgeGroupControllerApi extends runtime.BaseAPI {

    /**
     * addAgeGroup
     */
    async addAgeGroupUsingGETRaw(requestParameters: AddAgeGroupUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AgeGroupDto>> {
        if (requestParameters.ageGroupName === null || requestParameters.ageGroupName === undefined) {
            throw new runtime.RequiredError('ageGroupName','Required parameter requestParameters.ageGroupName was null or undefined when calling addAgeGroupUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.ageGroupName !== undefined) {
            queryParameters['ageGroupName'] = requestParameters.ageGroupName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ageGroup/add`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeGroupDtoFromJSON(jsonValue));
    }

    /**
     * addAgeGroup
     */
    async addAgeGroupUsingGET(requestParameters: AddAgeGroupUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AgeGroupDto> {
        const response = await this.addAgeGroupUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete
     */
    async deleteUsingGETRaw(requestParameters: DeleteUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AgeGroupDto>> {
        if (requestParameters.ageGroupId === null || requestParameters.ageGroupId === undefined) {
            throw new runtime.RequiredError('ageGroupId','Required parameter requestParameters.ageGroupId was null or undefined when calling deleteUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ageGroup/delete/{ageGroupId}`.replace(`{${"ageGroupId"}}`, encodeURIComponent(String(requestParameters.ageGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeGroupDtoFromJSON(jsonValue));
    }

    /**
     * delete
     */
    async deleteUsingGET(requestParameters: DeleteUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AgeGroupDto> {
        const response = await this.deleteUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAgeGroupByAgeGroupId
     */
    async getAgeGroupByAgeGroupIdUsingGETRaw(requestParameters: GetAgeGroupByAgeGroupIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AgeGroupDto>> {
        if (requestParameters.ageGroupId === null || requestParameters.ageGroupId === undefined) {
            throw new runtime.RequiredError('ageGroupId','Required parameter requestParameters.ageGroupId was null or undefined when calling getAgeGroupByAgeGroupIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ageGroup/{ageGroupId}`.replace(`{${"ageGroupId"}}`, encodeURIComponent(String(requestParameters.ageGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeGroupDtoFromJSON(jsonValue));
    }

    /**
     * getAgeGroupByAgeGroupId
     */
    async getAgeGroupByAgeGroupIdUsingGET(requestParameters: GetAgeGroupByAgeGroupIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AgeGroupDto> {
        const response = await this.getAgeGroupByAgeGroupIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAgeGroup
     */
    async getAllAgeGroupUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AgeGroupDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ageGroup/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgeGroupDtoFromJSON));
    }

    /**
     * getAllAgeGroup
     */
    async getAllAgeGroupUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AgeGroupDto>> {
        const response = await this.getAllAgeGroupUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateAgeGroup
     */
    async updateAgeGroupUsingGETRaw(requestParameters: UpdateAgeGroupUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AgeGroupDto>> {
        if (requestParameters.ageGroupId === null || requestParameters.ageGroupId === undefined) {
            throw new runtime.RequiredError('ageGroupId','Required parameter requestParameters.ageGroupId was null or undefined when calling updateAgeGroupUsingGET.');
        }

        if (requestParameters.ageGroupName === null || requestParameters.ageGroupName === undefined) {
            throw new runtime.RequiredError('ageGroupName','Required parameter requestParameters.ageGroupName was null or undefined when calling updateAgeGroupUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.ageGroupId !== undefined) {
            queryParameters['ageGroupId'] = requestParameters.ageGroupId;
        }

        if (requestParameters.ageGroupName !== undefined) {
            queryParameters['ageGroupName'] = requestParameters.ageGroupName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ageGroup/update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeGroupDtoFromJSON(jsonValue));
    }

    /**
     * updateAgeGroup
     */
    async updateAgeGroupUsingGET(requestParameters: UpdateAgeGroupUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AgeGroupDto> {
        const response = await this.updateAgeGroupUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
