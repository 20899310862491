/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryResponseModel
 */
export interface CategoryResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseModel
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryResponseModel
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseModel
     */
    mainCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryResponseModel
     */
    seq?: number;
}

export function CategoryResponseModelFromJSON(json: any): CategoryResponseModel {
    return CategoryResponseModelFromJSONTyped(json, false);
}

export function CategoryResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'mainCategory': !exists(json, 'mainCategory') ? undefined : json['mainCategory'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
    };
}

export function CategoryResponseModelToJSON(value?: CategoryResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'count': value.count,
        'mainCategory': value.mainCategory,
        'seq': value.seq,
    };
}

