/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    OrderCoupon,
    OrderCouponFromJSON,
    OrderCouponFromJSONTyped,
    OrderCouponToJSON,
} from './OrderCoupon';
import {
    OrderMarketingEvent,
    OrderMarketingEventFromJSON,
    OrderMarketingEventFromJSONTyped,
    OrderMarketingEventToJSON,
} from './OrderMarketingEvent';
import {
    OrderProduct,
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import {
    PointRule,
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';
import {
    Shipping,
    ShippingFromJSON,
    ShippingFromJSONTyped,
    ShippingToJSON,
} from './Shipping';
import {
    UserPointRecord,
    UserPointRecordFromJSON,
    UserPointRecordFromJSONTyped,
    UserPointRecordToJSON,
} from './UserPointRecord';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Country}
     * @memberof Order
     */
    countryByCountryId?: Country;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    countryId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deliveryDate2nd?: Date;
    /**
     * 
     * @type {Member}
     * @memberof Order
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {Array<OrderCoupon>}
     * @memberof Order
     */
    orderCouponsByOrderId?: Array<OrderCoupon>;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderId?: number;
    /**
     * 
     * @type {Array<OrderMarketingEvent>}
     * @memberof Order
     */
    orderMarketingEventsByOrderId?: Array<OrderMarketingEvent>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderNo?: string;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof Order
     */
    orderProductsByOrderId?: Array<OrderProduct>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    phone?: string;
    /**
     * 
     * @type {PointRule}
     * @memberof Order
     */
    pointRuleByPointRuleId?: PointRule;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    pointRuleId?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    shippingMedhod?: string;
    /**
     * 
     * @type {Array<Shipping>}
     * @memberof Order
     */
    shippingsByOrderId?: Array<Shipping>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    trackingNumber2nd?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    usedPoint?: number;
    /**
     * 
     * @type {UserPointRecord}
     * @memberof Order
     */
    userPointRecord?: UserPointRecord;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryByCountryId': !exists(json, 'countryByCountryId') ? undefined : CountryFromJSON(json['countryByCountryId']),
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'deliveryDate2nd': !exists(json, 'deliveryDate2nd') ? undefined : (new Date(json['deliveryDate2nd'])),
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'orderAddress': !exists(json, 'orderAddress') ? undefined : json['orderAddress'],
        'orderAddress1': !exists(json, 'orderAddress1') ? undefined : json['orderAddress1'],
        'orderAddress2': !exists(json, 'orderAddress2') ? undefined : json['orderAddress2'],
        'orderAddressRecipient': !exists(json, 'orderAddressRecipient') ? undefined : json['orderAddressRecipient'],
        'orderCouponsByOrderId': !exists(json, 'orderCouponsByOrderId') ? undefined : ((json['orderCouponsByOrderId'] as Array<any>).map(OrderCouponFromJSON)),
        'orderDate': !exists(json, 'orderDate') ? undefined : (new Date(json['orderDate'])),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderMarketingEventsByOrderId': !exists(json, 'orderMarketingEventsByOrderId') ? undefined : ((json['orderMarketingEventsByOrderId'] as Array<any>).map(OrderMarketingEventFromJSON)),
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'orderProductsByOrderId': !exists(json, 'orderProductsByOrderId') ? undefined : ((json['orderProductsByOrderId'] as Array<any>).map(OrderProductFromJSON)),
        'orderStatus': !exists(json, 'orderStatus') ? undefined : json['orderStatus'],
        'orderTotal': !exists(json, 'orderTotal') ? undefined : json['orderTotal'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'pointRuleByPointRuleId': !exists(json, 'pointRuleByPointRuleId') ? undefined : PointRuleFromJSON(json['pointRuleByPointRuleId']),
        'pointRuleId': !exists(json, 'pointRuleId') ? undefined : json['pointRuleId'],
        'productTotal': !exists(json, 'productTotal') ? undefined : json['productTotal'],
        'shippingFee': !exists(json, 'shippingFee') ? undefined : json['shippingFee'],
        'shippingMedhod': !exists(json, 'shippingMedhod') ? undefined : json['shippingMedhod'],
        'shippingsByOrderId': !exists(json, 'shippingsByOrderId') ? undefined : ((json['shippingsByOrderId'] as Array<any>).map(ShippingFromJSON)),
        'trackingNumber': !exists(json, 'trackingNumber') ? undefined : json['trackingNumber'],
        'trackingNumber2nd': !exists(json, 'trackingNumber2nd') ? undefined : json['trackingNumber2nd'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
        'userPointRecord': !exists(json, 'userPointRecord') ? undefined : UserPointRecordFromJSON(json['userPointRecord']),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryByCountryId': CountryToJSON(value.countryByCountryId),
        'countryId': value.countryId,
        'deliveryDate2nd': value.deliveryDate2nd === undefined ? undefined : (value.deliveryDate2nd.toISOString()),
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'orderAddress': value.orderAddress,
        'orderAddress1': value.orderAddress1,
        'orderAddress2': value.orderAddress2,
        'orderAddressRecipient': value.orderAddressRecipient,
        'orderCouponsByOrderId': value.orderCouponsByOrderId === undefined ? undefined : ((value.orderCouponsByOrderId as Array<any>).map(OrderCouponToJSON)),
        'orderDate': value.orderDate === undefined ? undefined : (value.orderDate.toISOString()),
        'orderId': value.orderId,
        'orderMarketingEventsByOrderId': value.orderMarketingEventsByOrderId === undefined ? undefined : ((value.orderMarketingEventsByOrderId as Array<any>).map(OrderMarketingEventToJSON)),
        'orderNo': value.orderNo,
        'orderProductsByOrderId': value.orderProductsByOrderId === undefined ? undefined : ((value.orderProductsByOrderId as Array<any>).map(OrderProductToJSON)),
        'orderStatus': value.orderStatus,
        'orderTotal': value.orderTotal,
        'paymentMethod': value.paymentMethod,
        'phone': value.phone,
        'pointRuleByPointRuleId': PointRuleToJSON(value.pointRuleByPointRuleId),
        'pointRuleId': value.pointRuleId,
        'productTotal': value.productTotal,
        'shippingFee': value.shippingFee,
        'shippingMedhod': value.shippingMedhod,
        'shippingsByOrderId': value.shippingsByOrderId === undefined ? undefined : ((value.shippingsByOrderId as Array<any>).map(ShippingToJSON)),
        'trackingNumber': value.trackingNumber,
        'trackingNumber2nd': value.trackingNumber2nd,
        'usedPoint': value.usedPoint,
        'userPointRecord': UserPointRecordToJSON(value.userPointRecord),
    };
}

