/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HashtagDto
 */
export interface HashtagDto {
    /**
     * 
     * @type {number}
     * @memberof HashtagDto
     */
    hashtagId?: number;
    /**
     * 
     * @type {string}
     * @memberof HashtagDto
     */
    hashtagName?: string;
}

export function HashtagDtoFromJSON(json: any): HashtagDto {
    return HashtagDtoFromJSONTyped(json, false);
}

export function HashtagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HashtagDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hashtagId': !exists(json, 'hashtagId') ? undefined : json['hashtagId'],
        'hashtagName': !exists(json, 'hashtagName') ? undefined : json['hashtagName'],
    };
}

export function HashtagDtoToJSON(value?: HashtagDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hashtagId': value.hashtagId,
        'hashtagName': value.hashtagName,
    };
}

