/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';

/**
 * 
 * @export
 * @interface AddUpdateMemberResponseModel
 */
export interface AddUpdateMemberResponseModel {
    /**
     * 
     * @type {MemberDto}
     * @memberof AddUpdateMemberResponseModel
     */
    memberDto?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof AddUpdateMemberResponseModel
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddUpdateMemberResponseModel
     */
    success?: boolean;
}

export function AddUpdateMemberResponseModelFromJSON(json: any): AddUpdateMemberResponseModel {
    return AddUpdateMemberResponseModelFromJSONTyped(json, false);
}

export function AddUpdateMemberResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddUpdateMemberResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberDto': !exists(json, 'memberDto') ? undefined : MemberDtoFromJSON(json['memberDto']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'success': !exists(json, 'success') ? undefined : json['success'],
    };
}

export function AddUpdateMemberResponseModelToJSON(value?: AddUpdateMemberResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberDto': MemberDtoToJSON(value.memberDto),
        'message': value.message,
        'success': value.success,
    };
}

