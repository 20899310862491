/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingEvent,
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface MarketingEventMember
 */
export interface MarketingEventMember {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventMember
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMember
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Member}
     * @memberof MarketingEventMember
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMember
     */
    memberId?: number;
}

export function MarketingEventMemberFromJSON(json: any): MarketingEventMember {
    return MarketingEventMemberFromJSONTyped(json, false);
}

export function MarketingEventMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': !exists(json, 'marketingEventByMarketingEventId') ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
    };
}

export function MarketingEventMemberToJSON(value?: MarketingEventMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value.marketingEventByMarketingEventId),
        'marketingEventId': value.marketingEventId,
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
    };
}

