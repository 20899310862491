import { Component } from "react";
import { MemberActionLogDto } from "../../../fetcher";

import { STYLETYPE_MAIN } from "../../../utils/Contants";
import { dateToString } from "../../../utils/GlobalFunction";
import style from "./ActivityRecordList.module.css";
import { Trans, useTranslation } from "react-i18next";

interface Props {
    value?: string;
    content?: any[];
    styleType?: string;
    callBack?: (value: any, value2?: any) => void;
    canEdit?: boolean;
    canDelete?: boolean;
    actionLog?: any;
}

export default class ActivityRecordList extends Component<Props> {
    static defaultProps = {
        styleType: STYLETYPE_MAIN,
    };

    handleCheckedCallBack = (value: any) => {
        // this.props.callBack({
        //     action: "checkbox",
        //     value,
        //     content: this.props.content,
        // });
    };

    handleDeleteCallBack = (value: any) => {
        // this.props.callBack({ action: DELETE, value, content: this.props.content });
    };

    handleEditCallBack = (value: any) => {
        //this.props.callBack({ action: EDIT, value, content: this.props.content });
    };

    handleSelectCallBack = () => {
        //this.props.callBack({ action: SELECT, content: this.props.content });
    };

    render() {
        const { content, styleType, canEdit, canDelete } = this.props;
        //console.log(this.props.actionLog);
        ////console.log("content", content);
        return (
            <>
                {this.props.actionLog.map((it: MemberActionLogDto) =>
                    //Activity Record
                    it.actionCode === "BORROW_BOOK" ? (
                        <div className={style.tableItemList}>
                            <div className={style.tableItemListContainer}>
                                <div className={style.tableListItemGridView}>
                                    <div className={style.TableListviewRow_grid} onClick={() => this.handleSelectCallBack()}>
                                        <div
                                            style={{
                                                display: "inline",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <Trans>successBorrow</Trans>
                                            <span className={style.actionLogHighlightText}>{it.data?.borrow?.bookname}</span>
                                        </div>
                                        <div style={{ textDecoration: "underline" }}>{/* <Link to={PATH.HOME_PATH}>前往清單查看 </Link> */}</div>
                                    </div>
                                    {/* <p>{content?.status === BookListDto.StatusEnum.Draft ? "暫存" : content?.status === BookListDto.StatusEnum.Publish ? "已發佈" : ""}</p> */}
                                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                </div>
                            </div>
                        </div>
                    ) : it.actionCode === "RETURN_BOOK" ? (
                        <div className={style.tableItemList}>
                            <div className={style.tableItemListContainer}>
                                <div className={style.tableListItemGridView}>
                                    <div className={style.TableListviewRow_grid} onClick={() => this.handleSelectCallBack()}>
                                        <div
                                            style={{
                                                display: "inline",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <Trans>Booksuccessfullyreturned</Trans>
                                            <span className={style.actionLogHighlightText}>{it.data?.borrow?.bookname}</span>
                                        </div>
                                        <div style={{ textDecoration: "underline" }}>{/* <Link to={PATH.HOME_PATH}>前往清單查看 </Link> */}</div>
                                    </div>
                                    {/* <p>{content?.status === BookListDto.StatusEnum.Draft ? "暫存" : content?.status === BookListDto.StatusEnum.Publish ? "已發佈" : ""}</p> */}

                                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                </div>
                            </div>
                        </div>
                    ) : it.actionCode === "RESUME_BOOK" ? (
                        <div className={style.tableItemList}>
                            <div className={style.tableItemListContainer}>
                                <div className={style.tableListItemGridView}>
                                    <div className={style.TableListviewRow_grid} onClick={() => this.handleSelectCallBack()}>
                                        <div
                                            style={{
                                                display: "inline",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <Trans>renewBorrowed</Trans>
                                            <span className={style.actionLogHighlightText}>{it.data?.borrow?.bookname}</span>， <Trans>returndate</Trans>
                                            <span className={style.actionLogHighlightText}>{dateToString(it.data?.borrow?.returnDate, "DD/MM/yyyy")}</span>
                                        </div>
                                        <div style={{ textDecoration: "underline" }}>{/* <Link to={PATH.HOME_PATH}>前往清單查看 </Link> */}</div>
                                    </div>
                                    {/* <p>{content?.status === BookListDto.StatusEnum.Draft ? "暫存" : content?.status === BookListDto.StatusEnum.Publish ? "已發佈" : ""}</p> */}

                                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                </div>
                            </div>
                        </div>
                    ) : it.actionCode === "RESERVE_BOOK" ? (
                        <div className={style.tableItemList}>
                            <div className={style.tableItemListContainer}>
                                <div className={style.tableListItemGridView}>
                                    <div className={style.TableListviewRow_grid} onClick={() => this.handleSelectCallBack()}>
                                        <div
                                            style={{
                                                display: "inline",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <Trans>AppointmentSuccessful</Trans>
                                            <span className={style.actionLogHighlightText}>{it.data?.reserveBorrow?.bookname}</span>， <Trans>Estimating</Trans>
                                            <span className={style.actionLogHighlightText}>{dateToString(it.data?.reserveBorrow?.estimateBorrowDate, "DD/MM/yyyy")}</span>
                                            <Trans>AddedtoShelf</Trans>
                                        </div>
                                        <div style={{ textDecoration: "underline" }}>{/* <Link to={PATH.HOME_PATH}>前往清單查看 </Link> */}</div>
                                    </div>
                                    {/* <p>{content?.status === BookListDto.StatusEnum.Draft ? "暫存" : content?.status === BookListDto.StatusEnum.Publish ? "已發佈" : ""}</p> */}

                                    <div style={{ fontSize: "12px", fontWeight: "bold" }}>{dateToString(it.createAt, "DD/MM/yyyy")}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                )}
            </>
        );
    }
}
