import { Component } from "react";
// import Button from "../Button/Button";
import style from "./Textarea.module.css";

interface IProps {
  //   type: string;
  placeholder: string;
  value?: string;
  callBack: (value: any, value2?: any) => void;
  styleType: string;
  id?: string;
  name?: string;
}

interface IState {
  textLength: number;
  validData: object;
  showError: boolean;
  errorStyle: object;
}

export default class Textarea extends Component<IProps, IState> {
  state = {
    textLength: 0,
    validData: {},
    showError: false,
    errorStyle: {},
  };

  resetState = () => {
    this.setState({ textLength: 0, validData: {}, showError: false });
  };
  hanldeOnChangeText = (event: any) => {
    this.resetState();
    if (event.target.value === "") {
      this.setState({ textLength: event.target.value.length });
    }
    this.props.callBack?.(event.target.value);
  };

  render() {
    return (
      <div
        className={
          this.props.styleType === "commentInput" ? style.commentInput : ""
        }
      >
        <textarea
          placeholder={this.props.placeholder}
          onChange={this.hanldeOnChangeText}
          value={this.props.value}
        >
          
        </textarea>
      </div>
    );
  }
}
