import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';

interface ISortableContainer extends SortableContainerProps {
    children: React.ReactNode
}

export const DndList: React.ComponentClass<ISortableContainer, any> = SortableContainer(
    ({ children }: { children: React.ReactNode }) => {
        return <div >{children}</div>
    }
)