/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import {
    MarketingEvent,
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEventDiscount
 */
export interface MarketingEventDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof MarketingEventDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventDiscount
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDiscount
     */
    marketingEventId?: number;
}

export function MarketingEventDiscountFromJSON(json: any): MarketingEventDiscount {
    return MarketingEventDiscountFromJSONTyped(json, false);
}

export function MarketingEventDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'marketingEventByMarketingEventId': !exists(json, 'marketingEventByMarketingEventId') ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
    };
}

export function MarketingEventDiscountToJSON(value?: MarketingEventDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
        'marketingEventByMarketingEventId': MarketingEventToJSON(value.marketingEventByMarketingEventId),
        'marketingEventId': value.marketingEventId,
    };
}

