/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberLevelDtoLite
 */
export interface MemberLevelDtoLite {
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDtoLite
     */
    memberLevelId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    order?: string;
}

export function MemberLevelDtoLiteFromJSON(json: any): MemberLevelDtoLite {
    return MemberLevelDtoLiteFromJSONTyped(json, false);
}

export function MemberLevelDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDtoLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'membershipName': !exists(json, 'membershipName') ? undefined : json['membershipName'],
        'membershipPeriod': !exists(json, 'membershipPeriod') ? undefined : json['membershipPeriod'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function MemberLevelDtoLiteToJSON(value?: MemberLevelDtoLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberLevelId': value.memberLevelId,
        'membershipName': value.membershipName,
        'membershipPeriod': value.membershipPeriod,
        'order': value.order,
    };
}

