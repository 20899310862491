import React from "react";
import "./navList.css";

import DeleteIcon from '../../../../components/Reader/deleteIcon/component'
import { connect } from 'react-redux'
import { Trans } from "react-i18next";

class NavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteIndex: -1,
    };
  }
  //跳转到图书的指定位置
  handleJump(cfi) {
    if (!cfi) {
      alert("Wrong bookmark");
      return;
    }
    if (this.props.currentBook.format === "EPUB") {
      this.props.currentEpub.rendition.display(cfi);
    } else {
      let bookLocation = JSON.parse(cfi) || {};
      this.props.htmlBook.rendition.goToPosition(
        bookLocation.text,
        bookLocation.chapterTitle,
        bookLocation.count
      );
    }
  }
  handleShowDelete = (index) => {
    this.setState({ deleteIndex: index });
  };
  render() {
    let currentData = (
      (this.props.currentTab === "bookmarks"
        ? this.props.bookmarks
        : this.props.currentTab === "notes"
          ? this.props.notes.filter((item) => item.notes !== "")
          : this.props.digests)
    ).filter((item) => {
      return item.bookKey === this.props.currentBook.key;
    });
    const renderBookNavList = () => {
      return currentData.reverse().map((item, index) => {
        const bookmarkProps = {
          itemKey: item.key,
          mode: this.props.currentTab === "bookmarks" ? "bookmarks" : "notes",
        };
        return (
          <li
            className="book-bookmark-list"
            key={item.key}
            onMouseEnter={() => {
              this.handleShowDelete(index);
            }}
            onMouseLeave={() => {
              this.handleShowDelete(-1);
            }}
            itemKey={item.key}
          >
            <p className="book-bookmark-digest">
              {this.props.currentTab === "bookmarks"
                ? item.label
                : this.props.currentTab === "notes"
                  ? item.notes
                  : item.text}
            </p>
            <div className="bookmark-page-list-item-title">
              {item.chapter}
            </div>
            <div className="book-bookmark-progress">
              {Math.floor(item.percentage * 100)}%
            </div>
            {this.state.deleteIndex === index ? (
              <DeleteIcon {...bookmarkProps} />
            ) : null}
            <div
              className="book-bookmark-link"
              onClick={() => {
                this.handleJump(item.cfi);
              }}
              style={{ cursor: "pointer" }}
            >
              <Trans>Go To</Trans>
            </div>
          </li>
        );
      });
    };
    if (!currentData[0]) {
      return (
        <div className="navigation-panel-empty-bookmark">
          Empty
        </div>
      );
    }
    return (
      <div className="book-bookmark-container">
        <ul className="book-bookmark">{renderBookNavList()}</ul>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    currentBook: state.book.currentBook,
    currentEpub: state.book.currentEpub,
    htmlBook: state.reader.htmlBook,

    bookmarks: state.reader.bookmarks,
    notes: state.reader.notes,
    digests: state.reader.digests,
  };
};
const actionCreator = {};
export default connect(
  mapStateToProps,
  actionCreator
)(NavList);

