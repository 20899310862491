import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import {
    BookReportRequestModel,
    CategoryControllerApi,
    IntegerListRequestModel,
    BookCountModel,
    CategoryConflictControllerApi,
    CategoryNameConflictResponse,
    CategoryParentConflictResponse,
    AddCategoryUsingPOSTRequest,
    UpdateCategoryUsingPOSTRequest,
    CategoryDto,
    ReportStorageControllerApi,
    GetUsingGETRequest,
    ReportStorageDto,
    ExcelControllerApi, ReportStorageIdResponseModel, MarcControllerApi, ReportRecordControllerApi,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { STYLETYPE_SECOND } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString } from "../../../utils/GlobalFunction";
import { fetchFileFromURLToFile } from "../../../utils/reader/fileUtils/fileUtil";
import useWindowSize from "../../../utils/useWindowSize";
import PageLoading from "../../loading/PageLoading";
import { DndItem } from "../../Sorting/DndItem";
import { DndList } from "../../Sorting/DndList";
import { DndTrigger } from "../../Sorting/DndTrigger";
import style from "./BookListView.module.css";
import TreeMeunCategory from "./TreeMeunCategory/TreeMeunCategory";
import puller from "../../../assets/svg/menu.svg";
import CategoryConflicts from "./CategoryConflicts";
import trashIcon from "../../../assets/svg/trash.svg";

interface ImageModel {
    mobileContent?: string;
    mFile?: File;

  content?: string;
    file?: File;
}

interface IResponseMsg {
    isSuccess: boolean;
    message: string;
}

export default function BookListView() {
    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showAddSubDialog, setShowAddSubDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRecommedDialog, setShowRecommedDialog] = useState(false);
    const [showAllDialog, setShowAllDialog] = useState(false);
    const [clearAllRecommendDialog, setClearAllRecommendDialog] = useState(false);
    const [recommendStatus, setIsRecommendStatus] = useState(false);
    const [subCategorySeq, setSubCategorySeq] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [toggleList, setToggleList] = useState(true);
    const [toggleComment, setToggleComment] = useState(false);
    const [displayData, setDisplayData] = useState<any>([]);

    const [productListName, setProductListName] = useState<string>("");
    const [seq, setSeq] = useState<number>(0);
    const [isCollection, setIsCollection] = useState(false);

    // region
    const [borrowSameTime, setBorrowSameTime] = useState<number>(0);
    const [borrowMaxDay, setBorrowMaxDay] = useState<number>(0);
    const [borrowMaxResumeCount, setBorrowMaxResumeCount] = useState<number>(0);
    const [borrowResumeDay, setBorrowResumeDay] = useState<number>(0);
    const [borrowResumeCount, setBorrowResumeCount] = useState<number>(0);
    // endregion

    const [selectedId, setSelectedId] = useState(0);

    // region loading
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    // endregion

    const [isbn, setIsbn] = useState("");
    const [isSort, setIsSort] = useState(false);
    const [sortingArray, setSortingArray] = useState<any>([]);
    const [parentId, setParentId] = useState(-1);
    const [showNameConflictDialog, setShowNameConflictDialog] = useState(false);
    const [showParentConflictDialog, setShowParentConflictDialog] = useState(false);
    const [categoryNameConflicts, setCategoryNameConflicts] = useState(Array<CategoryNameConflictResponse>);
    const [categoryParentConflicts, setCategoryParentConflicts] = useState(Array<CategoryParentConflictResponse>);

    const [bannerData, setBannerData] = useState<ImageModel>();

    const genApi = () => {
        new CategoryControllerApi(genApiConfig())
            .getAllCategoryByOrderUsingGET()
            // .then((res) => {
            //     setDisplayData(res);
            // })
            .then((res) => {
                var allCategory = res;
                var displayCate: Array<any> = [];
                // console.log("allCategory", allCategory)
                new CategoryControllerApi(genApiConfig()).getBookCountUsingGET().then((res: Array<BookCountModel>) => {
                    allCategory.map((cate) => {
                        let temp = res.find((it) => it.categoryId === cate.categoryId);
                        let bookCountTemp = 0;
                        {
                            if (temp !== undefined) {
                                bookCountTemp = temp!.bookCount!;
                            }
                        }
                        displayCate.push({ ...cate, bookCount: bookCountTemp });
                    });
                    console.log("displayCate", displayCate);
                    // setDisplayData(res);
                    setDisplayData(displayCate);
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (categoryNameConflicts.length > 0) {
            setShowNameConflictDialog(true);
        } else {
            setShowNameConflictDialog(false);
        }
    }, [categoryNameConflicts]);

    useEffect(() => {
        console.log("useEffect: categoryParentConflicts", showNameConflictDialog);
        if (categoryParentConflicts.length > 0 && !showNameConflictDialog) {
            setShowParentConflictDialog(true);
        }
    }, [categoryParentConflicts]);

    const handleDialogCallBack = (value: {
        action: string,
        category?: CategoryDto,
        parentId?: number,
        seq?: []
    }) => {
        console.log("handleDialogCallBack", value);
        if (value.action === PATH.ADD) {
            setShowAddDialog(true);
        }
        if (value.action === "addSub") {
            setShowAddSubDialog(true);
            setProductListName(value.category!.categoryName!);
            setSeq(value.category!.seq!);
            setSelectedId(value.category!.categoryId!);
        }
        if (value.action === PATH.EDIT) {
            setShowEditDialog(true);
            setSelectedId(value.category!.categoryId!)
            setProductListName(value.category!.categoryName!);
            setSeq(value.category!.seq!);
            setIsCollection(value.category!.isCollection!)

            setBorrowSameTime(value.category!.borrowSameTime != null ? value.category!.borrowSameTime! : 0)
            setBorrowMaxDay(value.category!.borrowMaxDay != null ? value.category!.borrowMaxDay! : 0)
            setBorrowMaxResumeCount(value.category!.borrowMaxResumeCount != null ? value.category!.borrowMaxResumeCount! : 0)
            setBorrowResumeDay(value.category!.borrowResumeDay != null ? value.category!.borrowResumeDay! : 0)
            setBorrowResumeCount(value.category!.borrowResumeCount != null ? value.category!.borrowResumeCount! : 0)

            setBannerData({
                content: value.category!.bannerImage,
                mobileContent: value.category!.mobileBannerImage
            })
        }
        if (value.action === PATH.DELETE) {
            setShowDeleteDialog(true);
            setProductListName(value.category!.categoryName!);
            setSelectedId(value.category!.categoryId!);
        }
        if (value.action === PATH.UPDATE) {
            setShowRecommedDialog(true);
            setProductListName(value.category!.categoryName!);
            setSelectedId(value.category!.categoryId!);
            setIsRecommendStatus(value.category!.isRecommend!);
        }
        if (value.action === "moveUp") {
            let oldCategoryIdx = displayData.map((x: any) => x.categoryId).indexOf(value.category!.categoryId);
            if (oldCategoryIdx === 0) return;
            console.log("oldCategoryIdx", oldCategoryIdx);

            setDisplayData((prevState: any) => {
                let data = [...prevState];
                console.log("data", data);

                let temp = data[oldCategoryIdx - 1];
                data[oldCategoryIdx - 1] = data[oldCategoryIdx];
                data[oldCategoryIdx] = temp;

                return data;
            });
        }
        if (value.action === "moveDown") {
            let oldCategoryIdx = displayData.map((x: any) => x.categoryId).indexOf(value.category!.categoryId);
            if (oldCategoryIdx === displayData.length - 1) return;
            console.log("oldCategoryIdx", oldCategoryIdx);

            setDisplayData((prevState: any) => {
                let data = [...prevState];
                console.log("data", data);

                let temp = data[oldCategoryIdx + 1];
                data[oldCategoryIdx + 1] = data[oldCategoryIdx];
                data[oldCategoryIdx] = temp;

                return data;
            });
        }
        if (value.action === "updateSubCategorySeq") {
            setParentId(value.parentId!);
            setSubCategorySeq(value.seq!);
            return;
        }
        if (value.action === PATH.ALL) {
            setShowAllDialog(true);
            return;
        }
        if (value.action === "clearAll") {
            setClearAllRecommendDialog(true);
            return;
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem(PATH.USER_INFO);

        if (userInfo === null) {
            navigate(PATH.LOGIN_PATH);
        }

        genApi();
        new CategoryConflictControllerApi(genApiConfig()).getAllCategoryNameConflictUsingGET().then((res: Array<CategoryNameConflictResponse>) => {
            setCategoryNameConflicts(res);
            new CategoryConflictControllerApi(genApiConfig()).getAllCategoryParentConflictUsingGET().then((res: Array<CategoryParentConflictResponse>) => {
                setCategoryParentConflicts(res);
            });
        });
    }, []);

    const uploadImage = async (file: any): Promise<IResponseMsg> => {
        let resMsg: IResponseMsg = {isSuccess: false, message: ""}

        let form = new FormData()
        form.append("file", file)

        let url = `${process.env.REACT_APP_BACKEND}/file/fileupload/image`;
        let option: RequestInit = {
            headers: {Accept: "plain/text",},
            method: "POST",
            credentials: "omit",
            cache: "no-cache",
            redirect: "follow",
            mode: "cors",
            body: form,
        }

        let uploadRes = await fetch(url, option)

        if(!uploadRes.ok){
            resMsg.isSuccess = false
            resMsg.message = "request is not success"
        } else {
            await uploadRes
              .text()
              .then((res) => {
                  resMsg.isSuccess = true
                  resMsg.message = res
              })
              .catch((err) => {
                  resMsg.isSuccess = false
                  resMsg.message = err
              });
        }
        return resMsg;
    }

    const handleSeqConfrim = () => {
        console.log("subCategorySeq", subCategorySeq);
        const x = displayData.map((x: any) => x.categoryId);
        new CategoryControllerApi(genApiConfig())
            .updateParentCategorySeqUsingPOST({
                integerListRequestModel: {
                    integers: displayData.map((x: any) => x.categoryId),
                } as IntegerListRequestModel,
            })
            .then(() => {
                if (parentId > 0) {
                    new CategoryControllerApi(genApiConfig()).updateSubCategorySeqUsingPOST({
                        parentCategoryId: parentId,
                        integerListRequestModel: {
                            integers: subCategorySeq,
                        } as IntegerListRequestModel,
                    });
                }
            })
            .then(() => genApi())
            .finally(() => {
                setIsSort(false);
                setParentId(-1);
            });
    };

    const updateCategoryStatus = () => {
        new CategoryControllerApi(genApiConfig())
            .updateStatusCategoryUsingPOST({ id: selectedId })
            .then((res: any) => {
                setIsLoading(true);
                //console.log(res);
            })
            .finally(() => {
                setShowRecommedDialog(false);
                genApi();
            });
    };

    const handleAddCategory = () => {
        let req: AddCategoryUsingPOSTRequest = {
            categoryName: productListName,
            seq: seq,
            isCollection: isCollection ? 1 : 0,
            borrowSameTime,
            borrowMaxDay,
            borrowMaxResumeCount,
            borrowResumeDay,
            borrowResumeCount
        }
        new Promise(async () => {
            // region upload banner
            if (bannerData?.file !== null && bannerData?.file !== undefined) {
                await uploadImage(bannerData!.file).then(bannerRes => {
                    if (!bannerRes.isSuccess) throw bannerRes.message;
                    req.bannerImage = bannerRes.message
                })
            } else {
                req.bannerImage = bannerData?.content === undefined ? "" : bannerData?.content;
            }
            // endregion

            // region mobile banner
            if (bannerData?.mFile !== null && bannerData?.mFile !== undefined) {
                await uploadImage(bannerData!.mFile).then(bannerRes => {
                    if (!bannerRes.isSuccess) throw bannerRes.message;
                    req.mobileBannerImage = bannerRes.message
                })
            } else {
                req.mobileBannerImage = bannerData?.mobileContent === undefined ? "" : bannerData?.mobileContent;
            }
            // endregion

            new CategoryControllerApi(genApiConfig()).addCategoryUsingPOST(req)
              .then((res) => {
                  if (res !== undefined) {
                      setProductListName("");
                      setIsLoading(true);
                      setIsCollection(false);
                      setBannerData({})
                  }
              }).then(() => {
                setShowAddDialog(false);
                genApi();
            })
        })
    };

    const handleAddSubCategory = () => {
        console.log(productListName, seq);
        new CategoryControllerApi(genApiConfig())
            .addSubCategoryUsingPOST({
                categoryName: productListName,
                parentCategoryId: selectedId,
                seq: 0,
                isCollection: isCollection ? 1 : 0
            })
            .then((res) => {
                if (res !== undefined) {
                    setProductListName("");
                    setShowAddSubDialog(false);
                    setIsLoading(true);
                }
            })
            .then(() => {
                genApi();
            });
    };

    const handleUpdateClick = () => {

        let req : UpdateCategoryUsingPOSTRequest = {
            id: selectedId,
            categoryName: productListName,
            seq: seq,
            isCollection: isCollection ? 1 : 0,
            borrowSameTime,
            borrowMaxDay,
            borrowMaxResumeCount,
            borrowResumeDay,
            borrowResumeCount
        }

        new Promise(async () => {
            // region upload banner
            if (bannerData?.file !== null && bannerData?.file !== undefined) {
                await uploadImage(bannerData!.file).then(bannerRes => {
                    if (!bannerRes.isSuccess) throw bannerRes.message;
                    req.bannerImage = bannerRes.message
                })
            } else {
                req.bannerImage = bannerData?.content === undefined ? "" : bannerData?.content;
            }
            // endregion

            // region mobile banner
            if (bannerData?.mFile !== null && bannerData?.mFile !== undefined) {
                await uploadImage(bannerData!.mFile).then(bannerRes => {
                    if (!bannerRes.isSuccess) throw bannerRes.message;
                    req.mobileBannerImage = bannerRes.message
                })
            } else {
                req.mobileBannerImage = bannerData?.mobileContent === undefined ? "" : bannerData?.mobileContent;
            }
            // endregion

            new CategoryControllerApi(genApiConfig())
              .updateCategoryUsingPOST(req)
              .then((res) => {
                  setProductListName("");
                  setIsLoading(true);
                  setIsCollection(false);
                  setBannerData({})
              })
              .finally(() => {
                  setShowEditDialog(false);
                  genApi();
              });
        })
    };

    const handleDeleteClick = (value: any) => {
        new CategoryControllerApi(genApiConfig())
            .deleteCategoryUsingPOST({ categoryId: selectedId })
            .then((res) => setIsLoading(true))
            .finally(() => {
                setShowDeleteDialog(false);
                genApi();
            });
    };

    const updateAllCategoryStatus = (boolean: boolean) => {
        let newArray = [] as number[];
        displayData.map((x: any) => {
            newArray.push(x.categoryId!!);
            x.subCategories?.map((it: any) => {
                newArray.push(it.categoryId!!);
            });
        });

        console.log("newArray", newArray);

        new CategoryControllerApi(genApiConfig())
            .updateAllStatusCategoryUsingPOST({
                integerListRequestModel: { integers: newArray },
                isAll: boolean,
            } as any)
            .then((res) => setIsLoading(true))
            .finally(() => {
                setShowAllDialog(false);
                setClearAllRecommendDialog(false);
                genApi();
            });
    };

    const handleMarc = () => {
        setIsDownloadLoading(true)
        new MarcControllerApi(genApiConfig())
            .requestGetMarcFileUsingGET()
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const handleMarcExcel = () => {
        setIsDownloadLoading(true)
        new ExcelControllerApi(genApiConfig())
            .requestMarcExcelUsingGET()
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
        setDisplayData((prevState: any) => {
            let data = [...prevState];
            const element = data.splice(oldIndex, 1)[0];
            data.splice(newIndex, 0, element);
            return data;
        });
    };

    const handleCateforyConflictCallback = (returnValue: any) => {
        console.log("handleCateforyConflictCallback", returnValue);
        if (returnValue.action === PATH.CANCEL || PATH.SAVE) {
            if (returnValue.conflictType === "name") {
                setShowNameConflictDialog(false);
                if (returnValue.conflictType === "name") {
                    new CategoryConflictControllerApi(genApiConfig()).getAllCategoryParentConflictUsingGET().then((res: Array<CategoryParentConflictResponse>) => {
                        setCategoryParentConflicts(res);
                    });
                }
            } else if (returnValue.conflictType === "parent") {
                setShowParentConflictDialog(false);
            }
        }
        genApi();
    };

    const handleBookDetails = () => {
        setIsDownloadLoading(true)
        new ReportRecordControllerApi(genApiConfig())
            .requestExportBookDetailsReportUsingGET()
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const handleReserveBook = () => {
        //new ReportRecordControllerApi(genApiConfig()).exportReservingBookReportUsingGET().then((res) => console.log(res));

        let a = {
            bookReportRequestModel: {
                //  bookType: bookType,
                //  brn:  null ,
                // bookName: bookName,
            } as BookReportRequestModel,
        };

        setIsDownloadLoading(true)
        new ReportRecordControllerApi(genApiConfig())
            .requestExportReservingBookReportUsingPOST(a)
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))

    };

    const handlePurchaseBook = () => {
        setIsDownloadLoading(true)
        new ExcelControllerApi(genApiConfig())
            .requestGetCategoryStatsExcelFileUsingGET()
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const reportStorageHandler = (res: ReportStorageIdResponseModel) => {
        function timerFunc() {
            new ReportStorageControllerApi(genApiConfig())
                .getUsingGET({
                    id: res.reportStorageId
                } as GetUsingGETRequest)
                .then((res: ReportStorageDto) => {
                    // Status :
                    // -1 -> Invalid
                    //  0 -> Pending
                    //  1 -> Running
                    //  2 -> Readied
                    //  3 -> Deleted

                    if (res.status === -1 || res.status === 2 || res.status === 3) {
                        if (res.status === 2) {
                            fetchFileFromURLToFile(
                                `${process.env.REACT_APP_BACKEND}/file/${res.fileName}`,
                                res.fileName
                            ).then(() => {
                                setIsDownloadLoading(false)
                            })
                        } else {
                            setIsDownloadLoading(false)
                        }
                    } else {
                        let seconds = 1
                        setTimeout(timerFunc, seconds * 1000)
                    }
                })
        }

        let seconds = 1
        setTimeout(timerFunc, seconds * 1000)
    }

    const trigger = (
        <DndTrigger>
            <img src={puller} />
        </DndTrigger>
    );

    const readBannerUrl = async (input: any, isMobile: boolean) => {
        if (input.target.files && input.target.files[0]) {
            if (isMobile) {
                setBannerData({
                    ...bannerData,
                    mobileContent: URL.createObjectURL(input.target.files[0]),
                    mFile: input.target.files[0],
                })
            } else {
                setBannerData({
                    ...bannerData,
                    content: URL.createObjectURL(input.target.files[0]),
                    file: input.target.files[0],
                })
            }
        }
    };

    const getBannerUrl = (isMobile: boolean): string => {
        if (isMobile) {
            if (bannerData?.mobileContent !== "") {
                if (bannerData?.mobileContent?.substring(0, 4) === "blob") {
                    return bannerData?.mobileContent;
                } else {
                    return `${process.env.REACT_APP_BACKEND}/file/${bannerData?.mobileContent}`;
                }
            }
        } else {
            if (bannerData?.content !== "") {
                if (bannerData?.content?.substring(0, 4) === "blob") {
                    return bannerData?.content;
                } else {
                    return `${process.env.REACT_APP_BACKEND}/file/${bannerData?.content}`;
                }
            }
        }
        return "";
    }

    return (
        <>
            {isDownloadLoading ? (<>
                <PageLoading/>
            </>) : (<></>)}
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>

                <div className={style.pageNav}>
                    <div className={style.ButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            平台書目管理
                        </Button>
                    </div>
                    <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
                    <div className={style.ButtonContainer}>
                        <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
                            書目清單
                        </Button>
                    </div>
                </div>
            </div>

            <div className={style.PlatformContainer}>
                <div className={style.toggleGroupContainer}>
                    <div className={style.toggleGroup}>
                        {/* <div className={style.toggleSilder}>123</div> */}
                        <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => console.log(value)}>
                            <Trans>CateList</Trans>
                        </Button>
                        <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.PURCHASED)}>
                            <Trans>PurchasedBook</Trans>
                        </Button>
                    </div>
                </div>

                <>
                    <div className={style.container}>
                        <div className={style.header}>
                            <div className={style.rowSpanItem}>
                                <h1>
                                    <Trans>CateList</Trans>
                                </h1>
                            </div>
                            <div className={style.rowSpancontainer}>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleBookDetails()}>
                                        書籍基本資料
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleReserveBook()}>
                                        預約中查詢
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handlePurchaseBook()}>
                                        採購冊數總覽
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleMarc()}>
                                        Marc
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleMarcExcel()}>
                                        MarcExcel
                                    </Button>
                                </div>

                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: PATH.ADD })}>
                                        <Trans>AddList</Trans>
                                    </Button>
                                </div>
                                <div className={style.rowSpanItem}>
                                    {isSort ? (
                                        <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleSeqConfrim()}>
                                            確定
                                        </Button>
                                    ) : (
                                        <Button styleType={STYLETYPE_SECOND} callBack={(value) => setIsSort(true)}>
                                            編輯排序
                                        </Button>
                                    )}
                                </div>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: PATH.ALL })}>
                                        全選
                                    </Button>
                                </div>
                                <div className={style.rowSpanItem}>
                                    <Button styleType={STYLETYPE_SECOND} callBack={(value) => handleDialogCallBack({ action: "clearAll" })}>
                                        清空
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={style.padding8}>
                            <div className={style.tableHeader}>
                                <div className={style.tableHeader2}>
                                    <div className={style.tableHeaderItem}>
                                        <h4>
                                            <Trans>ListName</Trans>
                                        </h4>
                                    </div>

                                    <div className={[style.tableHeaderItem,style.fontSize16, style.marginLeft10].join(" ")}>
                                        數量
                                    </div>
                                    <div className={[style.tableHeaderItem,style.fontSize16, style.marginLeft10].join(" ")}>
                                        系列
                                    </div>
                                </div>
                                {/* <div className={style.tableHeaderItem}></div> */}
                                <div style={{ fontSize: "16px" }} className={style.tableHeaderItem}>
                                    顯示書單
                                </div>
                            </div>
                            {isLoading === false ? (
                                isSort ? (
                                    <>
                                        <DndList lockAxis="y" lockToContainerEdges={true} useDragHandle onSortEnd={onSortEnd}>
                                            {displayData.map((obj: any, idx: number) => (
                                                <DndItem key={`item-${idx}`} index={idx}>
                                                    <TreeMeunCategory
                                                        Collection={true}
                                                        props={obj}
                                                        key={idx}
                                                        callBack={(e: any) => handleDialogCallBack(e)}
                                                        isSorting={isSort}
                                                        trigger={trigger}
                                                    />
                                                </DndItem>
                                            ))}
                                        </DndList>
                                    </>
                                ) : (
                                    <>
                                        {displayData
                                            .sort((a: any, b: any) => {
                                                return a.seq - b.seq;
                                            })
                                            .map((obj: any, idx: number) => (
                                                <TreeMeunCategory Collection={true} props={obj} key={idx} callBack={(e: any) => handleDialogCallBack(e)} isSorting={isSort} />
                                            ))}
                                    </>
                                )
                            ) : (
                                <>
                                    <PageLoading />
                                </>
                            )}
                        </div>
                    </div>
                </>
            </div>

            {/* region Add Dialog Content */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAddDialog} title={"新增書單"}>
                <div className={style.dialogRow}>
                    <h5>書單名稱</h5>
                    <Input type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div className={style.dialogRow}>
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>
                <div className={style.dialogRow}>
                    <h5>
                        <Trans>Collection</Trans>
                    </h5>
                    <div>
                        <Input type="checkbox" styleType={"checkbox"} checked={isCollection} callBack={(value) => setIsCollection(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                  <h5 className={style.width60}>
                    <Trans>橫幅</Trans>
                  </h5>
                    <div className={style.width40}>
                        <input
                          className={style.inputImage}
                          accept="image/*"
                          type="file"
                          onChange={(value) => readBannerUrl(value, false)}
                        />
                        {
                            bannerData?.content !== undefined && bannerData?.content !== "" ? (
                              <>
                                  <img
                                    className={style.imgPreview}
                                    id="blah"
                                    src={getBannerUrl(false)}
                                    alt=""
                                  />
                                  <button
                                    type="button"
                                    className={style.delete_btn}
                                    onClick={() =>
                                      setBannerData({
                                          ...bannerData,
                                          content: "",
                                          file: undefined
                                      })
                                    }
                                  >
                                    <span
                                      role="img"
                                      aria-label="delete"
                                      className={style.anticon_delete}
                                    >
                                      <img src={trashIcon} alt={"trash"}/>
                                    </span>
                                      <span>刪除</span>
                                  </button>
                              </>
                            ) : (
                              <>
                              </>
                            )
                        }
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>上方Logo</Trans>
                    </h5>
                    <div className={style.width40}>
                        <input
                          className={style.inputImage}
                          accept="image/*"
                          type="file"
                          onChange={(value) => readBannerUrl(value, true)}
                        />
                        {
                          bannerData?.mobileContent !== undefined && bannerData?.mobileContent !== "" ? (
                            <>
                              <img
                                className={style.imgPreview}
                                id="blah"
                                src={getBannerUrl(true)}
                                alt=""
                              />
                              <button
                                type="button"
                                className={style.delete_btn}
                                onClick={() =>
                                  setBannerData({
                                    ...bannerData,
                                    mobileContent: "",
                                    mFile: undefined
                                  })
                                }
                              >
                                    <span
                                      role="img"
                                      aria-label="delete"
                                      className={style.anticon_delete}
                                    >
                                      <img src={trashIcon} alt={"trash"}/>
                                    </span>
                                <span>刪除</span>
                              </button>
                            </>
                          ) : (
                            <>
                            </>
                          )
                        }
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BrorrowQuantity</Trans>
                    </h5>
                    <div className={style.width40}>
                        <Input type="text" styleType={"commentInput"} value={borrowSameTime} callBack={(value) => setBorrowSameTime(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BookBrorrowPeriod</Trans>
                    </h5>
                    <div className={style.width40}>
                        <Input type="text" styleType={"commentInput"} value={borrowMaxDay} callBack={(value) => setBorrowMaxDay(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>RenewableQuantity</Trans>
                    </h5>
                    <div className={style.width40}>
                        <Input type="text" styleType={"commentInput"} value={borrowMaxResumeCount} callBack={(value) => setBorrowMaxResumeCount(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BookRenewalPeriod</Trans>
                    </h5>
                    <div className={style.width40}>
                        <Input type="text" styleType={"commentInput"} value={borrowResumeDay} callBack={(value) => setBorrowResumeDay(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>ResumeBookCount</Trans>
                    </h5>
                    <div className={style.width40}>
                        <Input type="text" styleType={"commentInput"} value={borrowResumeCount} callBack={(value) => setBorrowResumeCount(value)} />
                    </div>
                </div>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAddDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleAddCategory()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* endregion */}

            {/* region AddSub Dialog Content */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAddSubDialog} title={"新增書單"}>
                <div className={style.dialogRow}>
                    <h5>書單名稱</h5>
                    <Input type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div className={style.dialogRow}>
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>

                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAddSubDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleAddSubCategory()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* endregion */}

            {/* region Edit Dialog Content */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showEditDialog} title={"更新書單"}>
                <div className={style.dialogRow}>
                    <h5>書單名稱</h5>
                    <Input value={productListName} type="text" styleType={"commentInput"} callBack={(value) => setProductListName(value)} />
                </div>
                <div className={style.dialogRow}>
                    <h5>位置</h5>
                    <Input type="text" styleType={"commentInput"} value={seq} callBack={(value) => setSeq(value)} />
                </div>
                <div className={style.dialogRow}>
                    <h5>
                        <Trans>Collection</Trans>
                    </h5>
                    <div>
                        <Input type="checkbox" styleType={"checkbox"} checked={isCollection} callBack={(value) => setIsCollection(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>橫幅</Trans>
                    </h5>
                    <div className={style.width40}>
                        <input
                          className={style.inputImage}
                          accept="image/*"
                          type="file"
                          onChange={(value) => readBannerUrl(value, false)}
                        />
                        {
                            bannerData?.content !== undefined && bannerData?.content !== "" ? (
                              <>
                                  <img
                                    className={style.imgPreview}
                                    id="blah"
                                    src={getBannerUrl(false)}
                                    alt=""
                                  />
                                  <button
                                    type="button"
                                    className={style.delete_btn}
                                    onClick={() =>
                                      setBannerData({
                                          ...bannerData,
                                          content: "",
                                          file: undefined
                                      })
                                    }
                                  >
                                    <span
                                      role="img"
                                      aria-label="delete"
                                      className={style.anticon_delete}
                                    >
                                      <img src={trashIcon} alt={"trash"}/>
                                    </span>
                                      <span>刪除</span>
                                  </button>
                              </>
                            ) : (
                              <>
                              </>
                            )
                        }
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>上方Logo</Trans>
                    </h5>
                    <div className={style.width40}>
                        <input
                          className={style.inputImage}
                          accept="image/*"
                          type="file"
                          onChange={(value) => readBannerUrl(value, true)}
                        />
                        {
                            bannerData?.mobileContent !== undefined && bannerData?.mobileContent !== "" ? (
                              <>
                                  <img
                                    className={style.imgPreview}
                                    id="blah"
                                    src={getBannerUrl(true)}
                                    alt=""
                                  />
                                  <button
                                    type="button"
                                    className={style.delete_btn}
                                    onClick={() =>
                                      setBannerData({
                                          ...bannerData,
                                          mobileContent: "",
                                          mFile: undefined
                                      })
                                    }
                                  >
                                    <span
                                      role="img"
                                      aria-label="delete"
                                      className={style.anticon_delete}
                                    >
                                      <img src={trashIcon} alt={"trash"}/>
                                    </span>
                                      <span>刪除</span>
                                  </button>
                              </>
                            ) : (
                              <>
                              </>
                            )
                        }
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BrorrowQuantity</Trans>
                    </h5>
                    <div className={style.width40}>
                      <Input type="text" styleType={"commentInput"} value={borrowSameTime} callBack={(value) => setBorrowSameTime(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BookBrorrowPeriod</Trans>
                    </h5>
                    <div className={style.width40}>
                      <Input type="text" styleType={"commentInput"} value={borrowMaxDay} callBack={(value) => setBorrowMaxDay(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>RenewableQuantity</Trans>
                    </h5>
                    <div className={style.width40}>
                      <Input type="text" styleType={"commentInput"} value={borrowMaxResumeCount} callBack={(value) => setBorrowMaxResumeCount(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>BookRenewalPeriod</Trans>
                    </h5>
                    <div className={style.width40}>
                      <Input type="text" styleType={"commentInput"} value={borrowResumeDay} callBack={(value) => setBorrowResumeDay(value)} />
                    </div>
                </div>
                <div className={style.dialogRow}>
                    <h5 className={style.width60}>
                        <Trans>ResumeBookCount</Trans>
                    </h5>
                    <div className={style.width40}>
                      <Input type="text" styleType={"commentInput"} value={borrowResumeCount} callBack={(value) => setBorrowResumeCount(value)} />
                    </div>
                </div>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowEditDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => handleUpdateClick()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* endregion */}

            {/* region Delete Dialog Content */}

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showDeleteDialog} title={"刪除書本資料"}>
                <h5>書單名稱</h5>
                <p>{productListName}</p>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowDeleteDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={(value) => handleDeleteClick(value)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* endregion */}

            {/* region 顯示書單 Dialog Content */}
            <DialogBox
                styleType={STYLETYPE_SECOND}
                showDialog={showRecommedDialog}
                title={recommendStatus ? `${productListName}從推薦書單中移除` : `設定${productListName}為推薦書單`}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowRecommedDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateCategoryStatus()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
            {/* endregion */}

            {/* 顯示書單 */}

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showRecommedDialog} title={`設定${productListName}為推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowRecommedDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateCategoryStatus()}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showAllDialog} title={`設定所有為推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setShowAllDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateAllCategoryStatus(true)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={STYLETYPE_SECOND} showDialog={clearAllRecommendDialog} title={`清空推薦書單`}>
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => setClearAllRecommendDialog(false)}>
                        <Trans>Cancel</Trans>
                    </Button>
                    &nbsp;&nbsp;
                    <Button styleType={"upperNavbarButton"} callBack={() => updateAllCategoryStatus(false)}>
                        儲存
                    </Button>
                </div>
            </DialogBox>

            <DialogBox styleType={"conflictCategory"} showDialog={showNameConflictDialog} title={`分類名稱衝突`}>
                <CategoryConflicts data={categoryNameConflicts} conflictType="name" dialogCallback={(e: any) => handleCateforyConflictCallback(e)} />
            </DialogBox>

            <DialogBox styleType={"conflictCategory"} showDialog={showParentConflictDialog} title={`主分類衝突`}>
                <CategoryConflicts data={categoryParentConflicts} conflictType="parent" dialogCallback={(e: any) => handleCateforyConflictCallback(e)} />
            </DialogBox>
        </>
    );
}
