const initState = {
    bookMarkList : []
  };
  export function myBookMarkList(
    state = initState,
    action
  ) {
    switch (action.type) {
  
      case "HANDLE_BOOKMARK_LIST":
        return {
          ...state,
          bookMarkList: action.payload,
        };
  
      default:
        return state;
    }
  }
  