/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarketingEventBannerDto
 */
export interface MarketingEventBannerDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    bannerId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    marketingEventName?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    mobileContent?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    position?: number;
}

export function MarketingEventBannerDtoFromJSON(json: any): MarketingEventBannerDto {
    return MarketingEventBannerDtoFromJSONTyped(json, false);
}

export function MarketingEventBannerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventBannerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerId': !exists(json, 'bannerId') ? undefined : json['bannerId'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'marketingEventName': !exists(json, 'marketingEventName') ? undefined : json['marketingEventName'],
        'mobileContent': !exists(json, 'mobileContent') ? undefined : json['mobileContent'],
        'position': !exists(json, 'position') ? undefined : json['position'],
    };
}

export function MarketingEventBannerDtoToJSON(value?: MarketingEventBannerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerId': value.bannerId,
        'content': value.content,
        'marketingEventId': value.marketingEventId,
        'marketingEventName': value.marketingEventName,
        'mobileContent': value.mobileContent,
        'position': value.position,
    };
}

