/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductDtoLite
 */
export interface ProductDtoLite {
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    deposit?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductDtoLite
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDtoLite
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDtoLite
     */
    productImage?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productStep?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    sales?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    subscriptionPeriod?: string;
}

export function ProductDtoLiteFromJSON(json: any): ProductDtoLite {
    return ProductDtoLiteFromJSONTyped(json, false);
}

export function ProductDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDtoLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deposit': !exists(json, 'deposit') ? undefined : json['deposit'],
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'isGroupProduct': !exists(json, 'isGroupProduct') ? undefined : json['isGroupProduct'],
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'productDiscountPrice': !exists(json, 'productDiscountPrice') ? undefined : json['productDiscountPrice'],
        'productHashtag': !exists(json, 'productHashtag') ? undefined : json['productHashtag'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productImage': !exists(json, 'productImage') ? undefined : json['productImage'],
        'productInventory': !exists(json, 'productInventory') ? undefined : json['productInventory'],
        'productNameChi': !exists(json, 'productNameChi') ? undefined : json['productNameChi'],
        'productNameEng': !exists(json, 'productNameEng') ? undefined : json['productNameEng'],
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'productStep': !exists(json, 'productStep') ? undefined : json['productStep'],
        'productVolume': !exists(json, 'productVolume') ? undefined : json['productVolume'],
        'productVolumeUnit': !exists(json, 'productVolumeUnit') ? undefined : json['productVolumeUnit'],
        'sales': !exists(json, 'sales') ? undefined : json['sales'],
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subscriptionPeriod': !exists(json, 'subscriptionPeriod') ? undefined : json['subscriptionPeriod'],
    };
}

export function ProductDtoLiteToJSON(value?: ProductDtoLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deposit': value.deposit,
        'displayProductId': value.displayProductId,
        'isGroupProduct': value.isGroupProduct,
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'productDescription': value.productDescription,
        'productDiscountPrice': value.productDiscountPrice,
        'productHashtag': value.productHashtag,
        'productId': value.productId,
        'productImage': value.productImage,
        'productInventory': value.productInventory,
        'productNameChi': value.productNameChi,
        'productNameEng': value.productNameEng,
        'productPrice': value.productPrice,
        'productStep': value.productStep,
        'productVolume': value.productVolume,
        'productVolumeUnit': value.productVolumeUnit,
        'sales': value.sales,
        'sku': value.sku,
        'status': value.status,
        'subscriptionPeriod': value.subscriptionPeriod,
    };
}

