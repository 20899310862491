/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportStorageIdResponseModel
 */
export interface ReportStorageIdResponseModel {
    /**
     * 
     * @type {number}
     * @memberof ReportStorageIdResponseModel
     */
    reportStorageId?: number;
}

export function ReportStorageIdResponseModelFromJSON(json: any): ReportStorageIdResponseModel {
    return ReportStorageIdResponseModelFromJSONTyped(json, false);
}

export function ReportStorageIdResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStorageIdResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportStorageId': !exists(json, 'reportStorageId') ? undefined : json['reportStorageId'],
    };
}

export function ReportStorageIdResponseModelToJSON(value?: ReportStorageIdResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportStorageId': value.reportStorageId,
    };
}

