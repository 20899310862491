/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCommentDto
 */
export interface ProductCommentDto {
    /**
     * 
     * @type {boolean}
     * @memberof ProductCommentDto
     */
    admin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductCommentDto
     */
    createAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    memberFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    memberLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    memberNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    memberNameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentDto
     */
    memberUsername?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCommentDto
     */
    rating?: number;
}

export function ProductCommentDtoFromJSON(json: any): ProductCommentDto {
    return ProductCommentDtoFromJSONTyped(json, false);
}

export function ProductCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'createAt': !exists(json, 'createAt') ? undefined : (new Date(json['createAt'])),
        'memberFirstName': !exists(json, 'memberFirstName') ? undefined : json['memberFirstName'],
        'memberLastName': !exists(json, 'memberLastName') ? undefined : json['memberLastName'],
        'memberNameChi': !exists(json, 'memberNameChi') ? undefined : json['memberNameChi'],
        'memberNameEng': !exists(json, 'memberNameEng') ? undefined : json['memberNameEng'],
        'memberUsername': !exists(json, 'memberUsername') ? undefined : json['memberUsername'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
    };
}

export function ProductCommentDtoToJSON(value?: ProductCommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admin': value.admin,
        'comment': value.comment,
        'createAt': value.createAt === undefined ? undefined : (value.createAt.toISOString()),
        'memberFirstName': value.memberFirstName,
        'memberLastName': value.memberLastName,
        'memberNameChi': value.memberNameChi,
        'memberNameEng': value.memberNameEng,
        'memberUsername': value.memberUsername,
        'rating': value.rating,
    };
}

