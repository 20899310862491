export const dropdownList = [
  {
    id: 5,
    title: "Font Size",
    value: "fontSize",
    option: ["8", "12", "16", "24", "32", "40", "48", "56", "64", "72", "80"],
  },
  {
    id: 6,
    title: "Letter Spacing",
    value: "letterSpacing",
    option: ["0", "5", "10", "15", "20"],
  },
  {
    id: 7,
    title: "Paragraph Spacing",
    value: "paraSpacing",
    option: ["0", "10", "20", "30", "40", "50", "60"],
  },
  // {
  //   id: 8,
  //   title: "Brightness",
  //   value: "brightness",
  //   option: ["0.5", "1", "1.5", "2"],
  // },
  {
    id: 1,
    title: "Font Family",
    value: "fontFamily",
    option: [
      "Built-in font",
      "Helvetica",
      "Times New Roman",
      "Microsoft YaHei",
      "SimSun",
      "SimHei",
      "Arial",
    ],
  },
  {
    id: 2,
    title: "Line Height",
    value: "lineHeight",
    option: ["1", "1.25", "1.5", "1.75", "2"],
  },
  {
    id: 3,
    title: "Text Align",
    value: "textAlign",
    option: ["Left", "Justify", "Right"],
  },
  {
    id: 4,
    title: "Chinese Conversion",
    value: "convertChinese",
    option: [
      "Default",
      "Simplified To Traditional",
      "Traditional To Simplified",
    ],
  },
];
export const speedList = {
  id: 3,
  title: "Speed",
  value: "speed",
  option: [
    "0.1",
    "0.2",
    "0.3",
    "0.4",
    "0.5",
    "0.75",
    "1",
    "1.25",
    "1.5",
    "1.75",
    "2",
    "3",
    "4",
    "5",
  ],
};
