/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubCategoryRequestModel
 */
export interface SubCategoryRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SubCategoryRequestModel
     */
    categoryName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubCategoryRequestModel
     */
    isCollection?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof SubCategoryRequestModel
     */
    seq?: number;
}

export function SubCategoryRequestModelFromJSON(json: any): SubCategoryRequestModel {
    return SubCategoryRequestModelFromJSONTyped(json, false);
}

export function SubCategoryRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'isCollection': !exists(json, 'isCollection') ? undefined : json['isCollection'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
    };
}

export function SubCategoryRequestModelToJSON(value?: SubCategoryRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryName': value.categoryName,
        'isCollection': value.isCollection,
        'mongoId': value.mongoId,
        'seq': value.seq,
    };
}

