import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { dateToString } from "../../../utils/GlobalFunction";
import {fetchFileFromURLToFile, fetchFileFromURLToFilePost} from "../../../utils/reader/fileUtils/fileUtil";
import style from "./BookReport.module.css";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import {
    BookDetailsReportResponseModel,
    BookReportBetweenDateUsingPOSTRequest,
    BookReportRequestModel,
    BookReportRequestModelReportTypesEnum,
    BookReportRequestModelTopReportTypeEnum,
    BookReportResponseModel,
    BorrowReportBetweenDateUsingPOSTRequest, GetUsingGETRequest,
    ReportControllerApi,
    ReportRecordControllerApi, ReportStorageControllerApi, ReportStorageDto, ReportStorageIdResponseModel,
    ReserveBookResponseModel,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import SelectOption from "../../../components/SelectOption/SelectOption";
import { STYLETYPE_SECOND } from "../../../utils/Contants";
import downIcon from "../../../assets/svg/down.svg";
import upIcon from "../../../assets/svg/up.svg";
import * as Storage from "../../../utils/local-storage";
import PageLoading from "../../loading/PageLoading";

export default function ReservingReport() {
    const [displayData, setDisplayData] = useState<ReserveBookResponseModel>();

    const [yearArr, setYearArr] = useState<any>([]);
    const [monthArr, setMonthArr] = useState<any>([]);
    const [startYear, setSartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [startMonth, setSartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [bookType, setBookType] = useState("ALL");

    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [brn, setbrn] = useState([]);
    const [bookName, setBookName] = useState([]);
    const [cate, setcate] = useState([]);

    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();

    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagelimit, setPageLimit] = useState(20);

    const [sortingObj, setSortingObj] = useState({
        web: false,
        app: false,
        online: false,
        preview: false,
        reserve: false,
        cant: false,
        concent: false,
        seq: false,
        time: false,
    });
    const [descending, setDescending] = useState(true);
    const navigate = useNavigate();

    const [lastdate, setLastDate] = useState("");

    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        var now = new Date();
        let yearArr = [];
        for (var i = 2000; i <= now.getFullYear(); i++) {
            yearArr.push({ id: i, name: i.toString() });
        }
        setYearArr(yearArr.reverse());
        let monthArr = [];
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                monthArr.push({ id: i, name: `0${i}` });
            } else {
                monthArr.push({ id: i, name: i.toString() });
            }
        }
        setMonthArr(monthArr);

        var nextyear = new Date(new Date().getFullYear(), 11, 31);
        const currentDayOfMonth = nextyear.getDate();
        const currentMonth = nextyear.getMonth(); // Be careful! January is 0, not 1
        const currentYear = nextyear.getFullYear();
        const dateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
        setLastDate(dateString);
    }, []);

    const handleSubmit = (from: boolean) => {
        let pagefrom = 0;
        if (from) {
            pagefrom = (currentPage - 1) * Number(pagelimit);
        }

        let a = {
            bookReportRequestModel: {
                bookType: bookType,
                brn: brn.length == 1 && brn[0] == "" ? null : brn,
                from: pagefrom,
                limit: Number(pagelimit),
                // bookName: bookName,
            } as BookReportRequestModel,
        };

        new ReportControllerApi(genApiConfig())
            .reserveBookReportUsingPOST(a)
            .then((res) => {
                if (res !== undefined) {
                    console.log(res);
                    setDisplayData(res);
                    setPageCount(res.pageTotal!!);
                }
            })
            .catch((err) => console.log("err", err));

        if (!from) {
            setCurrentPage(1);
        }
    };

    const handlebrn = (e: any) => {
        const brnResult = e.target.value.replaceAll(" ", "").split(",");
        setbrn(brnResult);
    };

    const handleBookName = (e: any) => {
        const bookNameResult = e.target.value.split(",");
        setBookName(bookNameResult);
    };

    const downloadBookReportRecord = () => {
        let b = {
            bookType: bookType,
            brn: brn.length == 1 && brn[0] == "" ? null : brn,
            // bookName: bookName,
        } as BookReportRequestModel;

        // fetchFileFromURLToFilePost(
        //     `${process.env.REACT_APP_BACKEND}/reportRecord/export-Reserving-Book-report`,
        //     `ReservingBook${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx`,
        //     b
        // );

        setIsDownloadLoading(true)

        new ReportRecordControllerApi(genApiConfig())
            .requestExportReservingBookReportUsingPOST({
                bookReportRequestModel: b
            })
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const reportStorageHandler = (res: ReportStorageIdResponseModel) => {
        function timerFunc() {
            new ReportStorageControllerApi(genApiConfig())
                .getUsingGET({
                    id: res.reportStorageId
                } as GetUsingGETRequest)
                .then((res: ReportStorageDto) => {
                    // Status :
                    // -1 -> Invalid
                    //  0 -> Pending
                    //  1 -> Running
                    //  2 -> Readied
                    //  3 -> Deleted

                    if (res.status === -1 || res.status === 2 || res.status === 3) {
                        if (res.status === 2) {
                            fetchFileFromURLToFile(
                                `${process.env.REACT_APP_BACKEND}/file/${res.fileName}`,
                                res.fileName
                            ).then(() => {
                                setIsDownloadLoading(false)
                            })
                        } else {
                            setIsDownloadLoading(false)
                        }
                    } else {
                        let seconds = 1
                        setTimeout(timerFunc, seconds * 1000)
                    }
                })
        }

        let seconds = 1
        setTimeout(timerFunc, seconds * 1000)
    }

    const handletoggle = (value: any) => {};

    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleSelectPage = (value: any) => {
        setCurrentPage(parseInt(value.name));
    };

    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    useEffect(() => {
        if (displayData != undefined && currentPage > 0) {
            handleSubmit(true);
        }
    }, [currentPage]);

    return (
        <>
            {isDownloadLoading ? (<>
                <PageLoading/>
            </>) : (<></>)}
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.READREPORT)}>
                        {/* <Trans>CateList</Trans> */}閱讀數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}借閱及瀏覽紀錄報告
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}書籍數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.CATEGORYREPORT)}>
                        主題分類排行榜
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => handletoggle(value)}>
                        預約中查詢數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREADREPORT)}>
                        借閱瀏覽紀錄報表
                    </Button>
                </div>
            </div>
            <div className={style.BookReport}>
                <h2>預約中查詢數據統計</h2>
                <div>
                    <div>
                        <p>選擇統計類型:</p>
                        <input
                            type="radio"
                            id="all"
                            value="ALL"
                            name="bookType"
                            checked={bookType === "ALL" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="ALL">全部</label>
                        {/* <input
                            type="radio"
                            id="ebook"
                            value="EBOOK"
                            name="bookType"
                            checked={bookType === "EBOOK" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="EBOOK">電子書</label> */}
                        {/* <input
                            type="radio"
                            id="magazine"
                            value="MAGAZINE"
                            name="bookType"
                            checked={bookType === "MAGAZINE" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="MAGAZINE">電子雜誌</label> */}
                    </div>

                    {/* <div className={style.datePickerContainer}>
                        <label htmlFor="start"></label>
                        <input type="date" id="start" name="trip-start" min="2018-01-01" max={lastdate} value={start} onChange={(e) => setStart(e.target.value)} />
                        <label htmlFor="end"> ~ </label>
                        <input type="date" id="end" name="trip-end" min="2018-01-01" max={lastdate} value={end} onChange={(e) => setEnd(e.target.value)} />
                    </div> */}
                    <div></div>
                    <div style={{ marginTop: "10px" }}>
                        <h3>BRN: (請用逗號區隔，例如: 1234,123,3456)</h3>
                        <textarea onChange={(e: any) => handlebrn(e)} className={style.textarea} />
                    </div>
                </div>

                <div>
                    <Button styleType={"submitButton"} callBack={() => handleSubmit(false)}>
                        查詢
                    </Button>
                    <Button styleType={"submitButton"} callBack={() => downloadBookReportRecord()}>
                        匯出EXCEL
                    </Button>
                </div>
                <hr className={style.line}></hr>
                <div className={style.showrecord}>
                    <div>顯示記錄 (預設為20項)</div>
                    <SelectOption
                        styleType={PATH.STYLETYPE_FIFTH}
                        items={[
                            {
                                id: 1,
                                name: "20",
                                value: 20,
                            },
                            {
                                id: 2,
                                name: "50",
                                value: 50,
                            },
                            {
                                id: 3,
                                name: "100",
                                value: 100,
                            },
                            {
                                id: 4,
                                name: "ALL",
                                value: 100000,
                            },
                        ]}
                        callBack={(value) => setPageLimit(value.value)}
                        placeholder={""}
                    />
                </div>
                <table>
                    <tr className={style.Tabletr}>
                        <th>排名</th>
                        <th>書號BRN</th>
                        <th>書籍名稱</th>
                        <th>作者</th>
                        <th>出版社</th>
                        <th>主題分類</th>
                        <th>預約中次數</th>
                    </tr>

                    {displayData?.data?.map((val) => {
                        return (
                            <tr>
                                <th>{val.seq!!}</th>
                                <th>{val.bookId!!}</th>
                                <th>{val.bookName}</th>
                                <th>{val.author!!}</th>
                                <th>{val.publishHouse!!}</th>
                                <th>{val.category!!}</th>
                                <th>{val.count!!}</th>
                            </tr>
                        );
                    })}

                    <tr></tr>
                </table>
                <div></div>

                {displayData?.data ? (
                    <div className={style.page_row}>
                        <div className={style.page_item} onClick={() => handleFirstPage()}>
                            首頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handlePrevPage()}>
                            上一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page}>
                            <SelectOption
                                styleType={PATH.STYLETYPE_FOUR}
                                value={currentPage.toString()}
                                firstValue={""}
                                items={handlepagecount()}
                                callBack={(value) => handleSelectPage(value)}
                            ></SelectOption>
                            <div style={{ marginLeft: "7px" }}>/ {pageCount}</div>
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleNextPage()}>
                            下一頁
                        </div>
                        <div className={style.border_line}></div>
                        <div className={style.page_item} onClick={() => handleLastPage()}>
                            尾頁
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}
