/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BookDetailsReportResponseModel,
    BookDetailsReportResponseModelFromJSON,
    BookDetailsReportResponseModelToJSON,
    BookReportRequestModel,
    BookReportRequestModelFromJSON,
    BookReportRequestModelToJSON,
    BookReportResponseModel,
    BookReportResponseModelFromJSON,
    BookReportResponseModelToJSON,
    BorrowReadReportResponseModel,
    BorrowReadReportResponseModelFromJSON,
    BorrowReadReportResponseModelToJSON,
    BorrowViewRecordReportRequestModel,
    BorrowViewRecordReportRequestModelFromJSON,
    BorrowViewRecordReportRequestModelToJSON,
    BorrowViewRecordReportResponseModel,
    BorrowViewRecordReportResponseModelFromJSON,
    BorrowViewRecordReportResponseModelToJSON,
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    OrderDtoForReport,
    OrderDtoForReportFromJSON,
    OrderDtoForReportToJSON,
    OrderProductDto2,
    OrderProductDto2FromJSON,
    OrderProductDto2ToJSON,
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    ReadDataReportResponseModel,
    ReadDataReportResponseModelFromJSON,
    ReadDataReportResponseModelToJSON,
    ReportStorageIdResponseModel,
    ReportStorageIdResponseModelFromJSON,
    ReportStorageIdResponseModelToJSON,
    ReserveBookResponseModel,
    ReserveBookResponseModelFromJSON,
    ReserveBookResponseModelToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface BirthdayDiscountUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BookDetailsReportUsingGETRequest {
    name?: string;
}

export interface BookReportBetweenDateUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface BorrowReadReportBetweenDateUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface BorrowReportBetweenDateUsingPOSTRequest {
    name?: string;
    borrowViewRecordReportRequestModel?: BorrowViewRecordReportRequestModel;
}

export interface BusinessReportBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CategoryChartsReportUsingPOST1Request {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface CouponCashDiscountUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface InventoryStockingUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointProductUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ReadDataReportBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface RequestCategoryChartsReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface ReserveBookReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

/**
 * 
 */
export class ReportControllerApi extends runtime.BaseAPI {

    /**
     * birthdayDiscount
     */
    async birthdayDiscountUsingGETRaw(requestParameters: BirthdayDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling birthdayDiscountUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling birthdayDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     */
    async birthdayDiscountUsingGET(requestParameters: BirthdayDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.birthdayDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * bookDetailsReport
     */
    async bookDetailsReportUsingGETRaw(requestParameters: BookDetailsReportUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BookDetailsReportResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/bookDetailsReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookDetailsReportResponseModelFromJSON));
    }

    /**
     * bookDetailsReport
     */
    async bookDetailsReportUsingGET(requestParameters: BookDetailsReportUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BookDetailsReportResponseModel>> {
        const response = await this.bookDetailsReportUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * bookReportBetweenDate
     */
    async bookReportBetweenDateUsingPOSTRaw(requestParameters: BookReportBetweenDateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BookReportResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/bookReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookReportResponseModelFromJSON(jsonValue));
    }

    /**
     * bookReportBetweenDate
     */
    async bookReportBetweenDateUsingPOST(requestParameters: BookReportBetweenDateUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BookReportResponseModel> {
        const response = await this.bookReportBetweenDateUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * borrowReadReportBetweenDate
     */
    async borrowReadReportBetweenDateUsingPOSTRaw(requestParameters: BorrowReadReportBetweenDateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BorrowReadReportResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/BorrowReadReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BorrowReadReportResponseModelFromJSON(jsonValue));
    }

    /**
     * borrowReadReportBetweenDate
     */
    async borrowReadReportBetweenDateUsingPOST(requestParameters: BorrowReadReportBetweenDateUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BorrowReadReportResponseModel> {
        const response = await this.borrowReadReportBetweenDateUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * borrowReportBetweenDate
     */
    async borrowReportBetweenDateUsingPOSTRaw(requestParameters: BorrowReportBetweenDateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BorrowViewRecordReportResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/borrowReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BorrowViewRecordReportRequestModelToJSON(requestParameters.borrowViewRecordReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BorrowViewRecordReportResponseModelFromJSON(jsonValue));
    }

    /**
     * borrowReportBetweenDate
     */
    async borrowReportBetweenDateUsingPOST(requestParameters: BorrowReportBetweenDateUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BorrowViewRecordReportResponseModel> {
        const response = await this.borrowReportBetweenDateUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * businessReportBetweenDate
     */
    async businessReportBetweenDateUsingGETRaw(requestParameters: BusinessReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling businessReportBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling businessReportBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     */
    async businessReportBetweenDateUsingGET(requestParameters: BusinessReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.businessReportBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for category charts
     */
    async categoryChartsReportUsingPOST1Raw(requestParameters: CategoryChartsReportUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/categoryCharts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for category charts
     */
    async categoryChartsReportUsingPOST1(requestParameters: CategoryChartsReportUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.categoryChartsReportUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * couponCashDiscount
     */
    async couponCashDiscountUsingGETRaw(requestParameters: CouponCashDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling couponCashDiscountUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling couponCashDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     */
    async couponCashDiscountUsingGET(requestParameters: CouponCashDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.couponCashDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * couponCodeDiscount
     */
    async couponCodeDiscountUsingGETRaw(requestParameters: CouponCodeDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling couponCodeDiscountUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling couponCodeDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     */
    async couponCodeDiscountUsingGET(requestParameters: CouponCodeDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.couponCodeDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * inventoryStocking
     */
    async inventoryStockingUsingGETRaw(requestParameters: InventoryStockingUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling inventoryStockingUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling inventoryStockingUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     */
    async inventoryStockingUsingGET(requestParameters: InventoryStockingUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.inventoryStockingUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * memberPoint
     */
    async memberPointUsingGETRaw(requestParameters: MemberPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling memberPointUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling memberPointUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     */
    async memberPointUsingGET(requestParameters: MemberPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MemberDto>> {
        const response = await this.memberPointUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointProduct
     */
    async pointProductUsingGETRaw(requestParameters: PointProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling pointProductUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling pointProductUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     */
    async pointProductUsingGET(requestParameters: PointProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.pointProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToCash
     */
    async pointToCashUsingGETRaw(requestParameters: PointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling pointToCashUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling pointToCashUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     */
    async pointToCashUsingGET(requestParameters: PointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.pointToCashUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToGift
     */
    async pointToGiftUsingGETRaw(requestParameters: PointToGiftUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling pointToGiftUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling pointToGiftUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     */
    async pointToGiftUsingGET(requestParameters: PointToGiftUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.pointToGiftUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * productSales
     */
    async productSalesUsingGETRaw(requestParameters: ProductSalesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling productSalesUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling productSalesUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     */
    async productSalesUsingGET(requestParameters: ProductSalesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.productSalesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * readDataReportBetweenDate
     */
    async readDataReportBetweenDateUsingGETRaw(requestParameters: ReadDataReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ReadDataReportResponseModel>>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling readDataReportBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling readDataReportBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/readDataReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadDataReportResponseModelFromJSON));
    }

    /**
     * readDataReportBetweenDate
     */
    async readDataReportBetweenDateUsingGET(requestParameters: ReadDataReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ReadDataReportResponseModel>> {
        const response = await this.readDataReportBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for category charts
     */
    async requestCategoryChartsReportUsingPOSTRaw(requestParameters: RequestCategoryChartsReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/requestCategoryCharts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for category charts
     */
    async requestCategoryChartsReportUsingPOST(requestParameters: RequestCategoryChartsReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestCategoryChartsReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * reserveBookReport
     */
    async reserveBookReportUsingPOSTRaw(requestParameters: ReserveBookReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReserveBookResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/resserveBookReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReserveBookResponseModelFromJSON(jsonValue));
    }

    /**
     * reserveBookReport
     */
    async reserveBookReportUsingPOST(requestParameters: ReserveBookReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReserveBookResponseModel> {
        const response = await this.reserveBookReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
