/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoToJSON,
} from '../models';

export interface AddOrganizationUsingPOSTRequest {
    name?: string;
    data?: string;
}

/**
 * 
 */
export class OrganizationControllerApi extends runtime.BaseAPI {

    /**
     * addOrganization
     */
    async addOrganizationUsingPOSTRaw(requestParameters: AddOrganizationUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.data !== undefined) {
            queryParameters['data'] = requestParameters.data;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organization/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoFromJSON));
    }

    /**
     * addOrganization
     */
    async addOrganizationUsingPOST(requestParameters: AddOrganizationUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDto>> {
        const response = await this.addOrganizationUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllOrganization
     */
    async getAllOrganizationUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<OrganizationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organization/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoFromJSON));
    }

    /**
     * getAllOrganization
     */
    async getAllOrganizationUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<OrganizationDto>> {
        const response = await this.getAllOrganizationUsingGETRaw(initOverrides);
        return await response.value();
    }

}
