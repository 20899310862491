/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryNameConflictResponse
 */
export interface CategoryNameConflictResponse {
    /**
     * 
     * @type {number}
     * @memberof CategoryNameConflictResponse
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflictResponse
     */
    cmsName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflictResponse
     */
    libName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryNameConflictResponse
     */
    mongoId?: string;
}

export function CategoryNameConflictResponseFromJSON(json: any): CategoryNameConflictResponse {
    return CategoryNameConflictResponseFromJSONTyped(json, false);
}

export function CategoryNameConflictResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryNameConflictResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'cmsName': !exists(json, 'cmsName') ? undefined : json['cmsName'],
        'libName': !exists(json, 'libName') ? undefined : json['libName'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
    };
}

export function CategoryNameConflictResponseToJSON(value?: CategoryNameConflictResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'cmsName': value.cmsName,
        'libName': value.libName,
        'mongoId': value.mongoId,
    };
}

