import React from "react";
import { useSocket } from "./socket/SocketContext";

export interface CompementProps<Params = any, State = any> {}

export function withSocket<P extends CompementProps>(
  Child: React.ComponentClass<P>
) {
  //const { socketMsg, setDetailPage, setBookPage } = useSocket();

  return (props: Omit<P, keyof CompementProps>) => {
    // return <Child {...(props as P)} {...useSocket()} />;
    return <Child {...(props as P)} {...useSocket()} />;
  };
}
