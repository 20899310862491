/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryParentConflictSubResponse,
    CategoryParentConflictSubResponseFromJSON,
    CategoryParentConflictSubResponseFromJSONTyped,
    CategoryParentConflictSubResponseToJSON,
} from './CategoryParentConflictSubResponse';

/**
 * 
 * @export
 * @interface CategoryParentConflictResponse
 */
export interface CategoryParentConflictResponse {
    /**
     * 
     * @type {CategoryParentConflictSubResponse}
     * @memberof CategoryParentConflictResponse
     */
    category?: CategoryParentConflictSubResponse;
    /**
     * 
     * @type {CategoryParentConflictSubResponse}
     * @memberof CategoryParentConflictResponse
     */
    cmsParentCategory?: CategoryParentConflictSubResponse;
    /**
     * 
     * @type {CategoryParentConflictSubResponse}
     * @memberof CategoryParentConflictResponse
     */
    libParentCategory?: CategoryParentConflictSubResponse;
}

export function CategoryParentConflictResponseFromJSON(json: any): CategoryParentConflictResponse {
    return CategoryParentConflictResponseFromJSONTyped(json, false);
}

export function CategoryParentConflictResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryParentConflictResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : CategoryParentConflictSubResponseFromJSON(json['category']),
        'cmsParentCategory': !exists(json, 'cmsParentCategory') ? undefined : CategoryParentConflictSubResponseFromJSON(json['cmsParentCategory']),
        'libParentCategory': !exists(json, 'libParentCategory') ? undefined : CategoryParentConflictSubResponseFromJSON(json['libParentCategory']),
    };
}

export function CategoryParentConflictResponseToJSON(value?: CategoryParentConflictResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': CategoryParentConflictSubResponseToJSON(value.category),
        'cmsParentCategory': CategoryParentConflictSubResponseToJSON(value.cmsParentCategory),
        'libParentCategory': CategoryParentConflictSubResponseToJSON(value.libParentCategory),
    };
}

