import React from "react";
import {useNavigate} from "react-router-dom";
import {
  Configuration,
  MemberControllerApi,
  NewRefreshTokenUsingGETRequest,
  RefreshTokenUsingPOSTRequest,
  TokenModel,
} from "../fetcher";
import {ACCESS_TOKEN, HOME_PATH, ERROR_PAGE_PATH} from "./Contants";
import * as PATH from "./Contants";

let baseUrl = process.env.REACT_APP_BACKEND;

export const genApiConfig = (
  noCache: boolean | null = null,
  header: { [key: string]: any } = {
    credentials: "include",
  },
  mode: string = "cors"
): Configuration => {
  // let header: { [key: string]: any } = {
  //   credentials: "include",
  // };

  if (localStorage.getItem(ACCESS_TOKEN) !== null) {
    header["Authorization"] = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  header["Access-Control-Allow-Origin"] = "*";
  header["Access-Control-Allow-Credentials"] = "true";

  //headers.append('Access-Control-Allow-Credentials', 'true');

  // "Accept-Language": Storage.language,

  var conf = new Configuration({
    basePath: baseUrl,
    headers: header,

    fetchApi: (input: any, init: any) => {
      //init["mode"] = "no-cors";
      return fetch(input, init)
        .then(async (res) => {
          // console.log("res", res);
          if (res.status && res.status === 401) {
            await new MemberControllerApi(
              new Configuration({
                basePath: baseUrl,
              })
            )
              .newRefreshTokenUsingGET({
                refreshToken: localStorage.getItem("refreshToken")?.toString(),
              } as NewRefreshTokenUsingGETRequest)
              .then(async (token: TokenModel) => {
                //console.log("token", token);
                if (Object.keys(token).length > 0) {
                  if (token.accessToken !== "" && token.refreshToken !== "") {
                    //console.log("token1", token);
                    localStorage.setItem("accessToken", token.accessToken!!);
                    localStorage.setItem("refreshToken", token.refreshToken!!);
                    init.headers[
                      "Authorization"
                      ] = `Bearer ${token.accessToken}`;

                    return await fetch(input, init);
                  } else {
                    //console.log("token2", token);
                    let isPhone = localStorage.getItem("isPhone");
                    let savedSchool = localStorage.getItem("savedSchool");
                    localStorage.clear();
                    localStorage.setItem("isPhone", isPhone!.toString());
                    if (savedSchool !== null)
                      localStorage.setItem("savedSchool", savedSchool!);
                    window.location.pathname = HOME_PATH;
                  }
                } else {
                  //console.log("token3", token);
                  let isPhone = localStorage.getItem("isPhone");
                  let savedSchool = localStorage.getItem("savedSchool");
                  localStorage.clear();
                  localStorage.setItem("isPhone", isPhone!.toString());
                  if (savedSchool !== null)
                    localStorage.setItem("savedSchool", savedSchool!);
                  window.location.pathname = HOME_PATH;
                }
              })
              .catch(async (err) => {
                console.log("err1", err);
                // let isPhone = localStorage.getItem("isPhone");
                // let savedSchool = localStorage.getItem("savedSchool");
                // localStorage.clear();
                // localStorage.setItem("isPhone", isPhone!.toString());
                // if (savedSchool !== null)
                //   localStorage.setItem("savedSchool", savedSchool!);
                // window.location.pathname = HOME_PATH;
                return Promise.reject(err);
              });
          }

          if (res.status && res.status === 500) {
            // res.text().then((resJson) => {
            //   const resJsonObj = JSON.parse(resJson)
            //   alert(resJsonObj.message);
            // });
            //            console.log("status", res);
            //TODO Server Error GOTO 404?
            // let isPhone = localStorage.getItem("isPhone");
            // let savedSchool = localStorage.getItem("savedSchool");
            // localStorage.clear();
            // localStorage.setItem("isPhone", isPhone!.toString());
            // if (savedSchool !== null)
            //   localStorage.setItem("savedSchool", savedSchool!);
            // window.location.pathname = HOME_PATH;
          }

          if (!res.ok) {
            return Promise.reject(res);
          } else {
            return Promise.resolve(res);
          }
        })
        .catch(async (err) => {
          let errJson = await err.json()

          let errKeys = Object.keys(errJson)
          let errHaveKey = errKeys.length > 0

          let isMemberNotSame = errHaveKey && errKeys.includes("message") && errJson.message === "Member not same as login member"
          let is401 = errHaveKey && errKeys.includes("status") && errJson.status === 401

          let goToLogout = false

          if (isMemberNotSame) {
            goToLogout = true
          } else if (is401) {
            goToLogout = true
          }

          if (goToLogout) {
            await new MemberControllerApi(
              new Configuration({
                basePath: baseUrl,
              })
            )
              .newRefreshTokenUsingGET({
                refreshToken: localStorage.getItem("refreshToken")?.toString(),
              } as NewRefreshTokenUsingGETRequest)
              .then(async (token: TokenModel) => {
                //console.log("token", token);
                if (Object.keys(token).length > 0) {
                  if (token.accessToken !== "" && token.refreshToken !== "") {
                    //console.log("token1", token);
                    localStorage.setItem("accessToken", token.accessToken!!);
                    localStorage.setItem("refreshToken", token.refreshToken!!);
                    init.headers[
                      "Authorization"
                      ] = `Bearer ${token.accessToken}`;

                    return await fetch(input, init);
                  } else {
                    //console.log("token2", token);
                    let isPhone = localStorage.getItem("isPhone");
                    let savedSchool = localStorage.getItem("savedSchool");
                    localStorage.clear();
                    localStorage.setItem("isPhone", isPhone!.toString());
                    if (savedSchool !== null)
                      localStorage.setItem("savedSchool", savedSchool!);
                    window.location.pathname = HOME_PATH;
                  }
                } else {
                  //console.log("token3", token);
                  let isPhone = localStorage.getItem("isPhone");
                  let savedSchool = localStorage.getItem("savedSchool");
                  localStorage.clear();
                  localStorage.setItem("isPhone", isPhone!.toString());
                  if (savedSchool !== null)
                    localStorage.setItem("savedSchool", savedSchool!);
                  window.location.pathname = HOME_PATH;
                }
              })
              .catch(async (err) => {

                console.log("Logout begin")
                await fetch(`${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/logout`, {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
                  },
                }).then(async (res) => {
                  console.log("setLogout success", res);
                  res.text().then((resText) => {

                  });
                }).catch(async (err) => {
                  console.log("setLogout error", err);
                }).finally(async () => {
                  let isPhone = localStorage.getItem("isPhone");
                  localStorage.clear();
                  localStorage.setItem("isPhone", isPhone!.toString());
                  window.location.href = PATH.HOME_PATH;
                })

                console.log("err1", err);
                // let isPhone = localStorage.getItem("isPhone");
                // let savedSchool = localStorage.getItem("savedSchool");
                // localStorage.clear();
                // localStorage.setItem("isPhone", isPhone!.toString());
                // if (savedSchool !== null)
                //   localStorage.setItem("savedSchool", savedSchool!);
                // window.location.pathname = HOME_PATH;
                return Promise.reject(err);
              });

          }
          return Promise.reject(err);
        });
    },
  });

  return conf;
};

//export const sharedBaseUrl = baseUrl;
