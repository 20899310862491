/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubOrganizationDto
 */
export interface SubOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    bannerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SubOrganizationDto
     */
    organizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubOrganizationDto
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationDto
     */
    shortName?: string;
}

export function SubOrganizationDtoFromJSON(json: any): SubOrganizationDto {
    return SubOrganizationDtoFromJSONTyped(json, false);
}

export function SubOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerUrl': !exists(json, 'bannerUrl') ? undefined : json['bannerUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
    };
}

export function SubOrganizationDtoToJSON(value?: SubOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerUrl': value.bannerUrl,
        'description': value.description,
        'fullName': value.fullName,
        'logoUrl': value.logoUrl,
        'name': value.name,
        'organizationId': value.organizationId,
        'seq': value.seq,
        'shortName': value.shortName,
    };
}

