import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrowDown from "../../../../assets/svg/down.svg";
import arrowDownV2 from "../../../../assets/svg/down_v2.svg";
import edit from "../../../../assets/svg/edit.svg";
import arrowUp from "../../../../assets/svg/up.svg";
import { UPDATE } from "../../../../utils/Contants";
import { DndItem } from "../../../Sorting/DndItem";
import { DndList } from "../../../Sorting/DndList";
import { DndTrigger } from "../../../Sorting/DndTrigger";
import style from "./TreeMeunCategory.module.css";
import puller from "../../../../assets/svg/menu.svg";

export default function TreeMeun({
  props,
  callBack,
  multipleSelect,
  mission,
  category,
  book,
  isSorting,
  selectedCategoryList,
  Collection,
  trigger,
}: any) {
  const [hidden, setHidden] = useState(true);
  const [displayData, setDisplayData] = useState<any>([]);
  const [currentBook, setCurrentBook] = useState<any>();

  // TODO save selected category as list for checking

  const navigate = useNavigate();

  useEffect(() => {
    if (book === undefined) {
      setCurrentBook({ categories: [] });
    } else {
      setCurrentBook(book);
    }
    // console.log("select cate list",selectedCategoryList)
    setHidden(true);
  }, [book, props, selectedCategoryList]);

  useEffect(() => {
    if (props != undefined && props.subCategories != undefined) {
      setDisplayData(props.subCategories);
    }
  }, [props]);

  const handleClick = (value: any) => {
    // console.log("tree click props",props)
    // console.log("tree click book",book)
    const { action } = value;
    switch (action) {
      case "expand":
        setHidden(!hidden);
        break;
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    setDisplayData((prevState: any) => {
      let data = [...prevState];
      const element = data.splice(oldIndex, 1)[0];
      data.splice(newIndex, 0, element);
      callBack({
        action: "updateSubCategorySeq",
        seq: data.map((x: any) => x.categoryId),
        parentId: props.categoryId,
      });
      return data;
    });
    // setDisplayData(arrayMoveImmutable(state, oldIndex, newIndex))
  };

  const handleUpAndDown = (value: any) => {
    // console.log("value", value);
    // console.log("props", props);
    // console.log("displayData", displayData);
    if (value.action === "moveUp") {
      let oldCategoryIdx = displayData
        .map((x: any) => x.categoryId)
        .indexOf(value.category.categoryId);
      if (oldCategoryIdx === 0) return;

      setDisplayData((prevState: any) => {
        let data = [...prevState];

        let temp = data[oldCategoryIdx - 1];
        data[oldCategoryIdx - 1] = data[oldCategoryIdx];
        data[oldCategoryIdx] = temp;

        callBack({
          action: "updateSubCategorySeq",
          seq: data.map((x: any) => x.categoryId),
          parentId: props.categoryId,
        });
        return data;
      });
    }
    if (value.action === "moveDown") {
      let oldCategoryIdx = displayData
        .map((x: any) => x.categoryId)
        .indexOf(value.category.categoryId);
      if (oldCategoryIdx === displayData.length - 1) return;
      // console.log("oldCategoryIdx", oldCategoryIdx);

      setDisplayData((prevState: any) => {
        let data = [...prevState];
        console.log("data", data);

        let temp = data[oldCategoryIdx + 1];
        data[oldCategoryIdx + 1] = data[oldCategoryIdx];
        data[oldCategoryIdx] = temp;

        callBack({
          action: "updateSubCategorySeq",
          seq: data.map((x: any) => x.categoryId),
          parentId: props.categoryId,
        });
        return data;
      });
    }
  };

  const checkIfCategoryMatch = (
    currentCategory: any,
    isSubcategory: boolean
  ) => {
    if (
      currentBook !== undefined &&
      selectedCategoryList !== undefined &&
      props.categoryId !== undefined
    ) {
      if (Array.isArray(currentBook)) {
        return false;
      }
      if (currentBook.categories === undefined) {
        return false;
      }
      if (selectedCategoryList.length === 0) {
        return false;
      }

      let thisCategoryId = currentCategory.categoryId;
      let bookSubCategoryIds = selectedCategoryList;

      var matchResults = bookSubCategoryIds.filter(function (categoryId: any) {
        return categoryId === thisCategoryId;
      });

      if (matchResults.length > 0) {
        // callBack({
        //   isChecked: true,
        //   categoryId: thisCategoryId,
        // });
        if (isSubcategory && hidden) {
          setHidden(false);
        }
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <>
      {multipleSelect ? (
        <>
          <div
            className={style.treeMenu}
            key={props.id}
            hidden={multipleSelect ? false : true}
          >
            <div className={hidden ? style.treeHead : style.treeHeadActive}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <input
                  type="checkbox"
                  checked={checkIfCategoryMatch(props, false)}
                  onChange={(e) => {
                    callBack({
                      isChecked: e.target.checked,
                      categoryId: props.categoryId,
                    });
                  }}
                />
                <span>
                  {props.categoryName}
                  {/* {props.categoryName} */}
                </span>
                <div className={style.iconConatiner}></div>
              </div>
              {hidden ? (
                <img
                  src={arrowDown}
                  onClick={(event) =>
                    handleClick({ action: "expand", event: event })
                  }
                />
              ) : (
                <img
                  src={arrowUp}
                  onClick={(event) =>
                    handleClick({ action: "expand", event: event })
                  }
                />
              )}
            </div>
            {displayData &&
              displayData.map((cat: any, index: number) => (
                <div
                  className={hidden ? style.treeBody : style.treeBodyActive}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={checkIfCategoryMatch(cat, true)}
                      onChange={(e) => {
                        callBack({
                          isChecked: e.target.checked,
                          categoryId: cat.categoryId,
                        });
                      }}
                    />
                    <span>
                      {cat.categoryName}
                      {/* {props.categoryName} */}
                    </span>
                    <div className={style.iconConatiner}></div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <div
          className={style.treeMenu}
          key={props.id}
          hidden={multipleSelect === undefined ? false : true}
        >
          <div className={hidden ? style.treeHead : style.treeHeadActive}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "99%",
              }}
            >
              <div className={style.treeHeadGrid}>
                {mission &&
                mission.find((x: any) => x.categoryId === props.categoryId) ? (
                  <span
                    style={{ color: "red" }}
                    onClick={() =>
                      navigate(`/admin/booklist/category/` + props.categoryId)
                    }
                  >
                    {props.categoryName}
                    {/* {props.categoryName} */}
                  </span>
                ) : (
                  <span
                    onClick={() =>
                      navigate(`/admin/booklist/category/` + props.categoryId)
                    }
                  >
                    {props && props.categoryName}
                    {/* {props.categoryName} */}
                  </span>
                )}
                <span>{props && props.bookCount}</span>
                {Collection ? (
                  process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                    <input
                      style={{ pointerEvents: "none" }}
                      // disabled={true}
                      type="checkbox"
                      checked={props.isCollection == 1 ? true : false}
                      // onChange={(e) => {
                      //     callBack({
                      //         isChecked: e.target.checked,
                      //     });
                      // }}
                    />
                  ) : (
                    <div />
                  )
                ) : (
                  <></>
                )}
              </div>
              {isSorting ? (
                hidden ? (
                  <>
                    <div className={style.sortIconContainer}>
                      {/* <img src={arrowDownV2} onClick={() => callBack({ action: "moveDown", category: props })} />
                                            <img src={arrowUp} onClick={() => callBack({ action: "moveUp", category: props })} /> */}
                      {trigger}
                      <img
                        src={arrowDown}
                        onClick={(event) =>
                          handleClick({ action: "expand", event: event })
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={arrowUp}
                      onClick={(event) =>
                        handleClick({ action: "expand", event: event })
                      }
                    />
                  </>
                )
              ) : (
                <div className={style.iconConatiner}>
                  {hidden ? (
                    <div style={{ display: "flex", gap: "1.5em" }}>
                      <img
                        src={edit}
                        style={{ width: "25px" }}
                        onClick={() =>
                          callBack({ action: "edit", category: props })
                        }
                      />
                      <input
                        type="checkbox"
                        checked={props.isRecommend}
                        onChange={() =>
                          callBack({ action: UPDATE, category: props })
                        }
                      />
                      <img
                        src={arrowDown}
                        onClick={(event) =>
                          handleClick({ action: "expand", event: event })
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "12px" }}>
                      <div
                        onClick={(e) =>
                          callBack({ action: "addSub", category: props })
                        }
                        className={style.addSubCategoryButton}
                      >
                        新增子書單
                      </div>
                      <img
                        src={arrowUp}
                        onClick={(event) =>
                          handleClick({ action: "expand", event: event })
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {displayData && isSorting ? (
            <>
              <DndList
                lockAxis="y"
                lockToContainerEdges={true}
                useDragHandle
                onSortEnd={onSortEnd}
              >
                {displayData.map((cat: any, index: number) => (
                  <DndItem key={`item-${index}`} index={index}>
                    <div
                      className={hidden ? style.treeBody : style.treeBodyActive}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "99%",
                        }}
                      >
                        <span
                          onClick={() =>
                            navigate(
                              `/admin/booklist/category/` + cat.categoryId
                            )
                          }
                        >
                          {cat.categoryName}
                        </span>

                        <DndTrigger>
                          <img src={puller} />
                        </DndTrigger>
                        {/* {isSorting ? (
                                                        <div className={style.sortIconContainer}>
                                                            <img
                                                                src={arrowDownV2}
                                                                onClick={() =>
                                                                    handleUpAndDown({
                                                                        action: "moveDown",
                                                                        category: cat,
                                                                    })
                                                                }
                                                            />
                                                            <img src={arrowUp} onClick={() => handleUpAndDown({ action: "moveUp", category: cat })} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <div className={style.iconConatiner}>
                                                                <img src={edit} style={{ width: "25px" }} onClick={() => callBack({ action: "edit", category: cat })} />
                                                            </div>
                                                            <input type="checkbox" onChange={() => callBack({ action: UPDATE, category: cat })} checked={cat.isRecommend} />
                                                        </div>
                                                    )} */}
                      </div>
                    </div>
                  </DndItem>
                ))}
              </DndList>
              {/* {displayData.map((cat: any, index: number) => (
                                <div className={hidden ? style.treeBody : style.treeBodyActive} key={index}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "99%",
                                        }}
                                    >
                                        <span onClick={() => navigate(`/admin/booklist/category/` + cat.categoryId)}>
                                            {cat.categoryName}
                                        </span>
                                        {isSorting ? (
                                            <div className={style.sortIconContainer}>
                                                <img
                                                    src={arrowDownV2}
                                                    onClick={() =>
                                                        handleUpAndDown({
                                                            action: "moveDown",
                                                            category: cat,
                                                        })
                                                    }
                                                />
                                                <img src={arrowUp} onClick={() => handleUpAndDown({ action: "moveUp", category: cat })} />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div className={style.iconConatiner}>
                                                    <img src={edit} style={{ width: "25px" }} onClick={() => callBack({ action: "edit", category: cat })} />
                                                </div>
                                                <input type="checkbox" onChange={() => callBack({ action: UPDATE, category: cat })} checked={cat.isRecommend} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))} */}
            </>
          ) : (
            <>
              {displayData
                .sort((a: any, b: any) => {
                  return a.seq - b.seq;
                })
                .map((cat: any, index: number) => (
                  <div
                    className={hidden ? style.treeBody : style.treeBodyActive}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span
                        onClick={() =>
                          navigate(`/admin/booklist/category/` + cat.categoryId)
                        }
                      >
                        {cat.categoryName}
                      </span>
                      {isSorting ? (
                        <div className={style.sortIconContainer}>
                          <img
                            src={arrowDownV2}
                            onClick={() =>
                              handleUpAndDown({
                                action: "moveDown",
                                category: cat,
                              })
                            }
                          />
                          <img
                            src={arrowUp}
                            onClick={() =>
                              handleUpAndDown({
                                action: "moveUp",
                                category: cat,
                              })
                            }
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "2em",
                          }}
                        >
                          <div className={style.iconConatiner}>
                            <img
                              src={edit}
                              style={{ width: "25px" }}
                              onClick={() =>
                                callBack({ action: "edit", category: cat })
                              }
                            />
                          </div>
                          <input
                            type="checkbox"
                            onChange={() =>
                              callBack({ action: UPDATE, category: cat })
                            }
                            checked={cat.isRecommend}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      )}
    </>
  );
}
