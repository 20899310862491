/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegerListRequestModel
 */
export interface IntegerListRequestModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof IntegerListRequestModel
     */
    integers?: Array<number>;
}

export function IntegerListRequestModelFromJSON(json: any): IntegerListRequestModel {
    return IntegerListRequestModelFromJSONTyped(json, false);
}

export function IntegerListRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegerListRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'integers': !exists(json, 'integers') ? undefined : json['integers'],
    };
}

export function IntegerListRequestModelToJSON(value?: IntegerListRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integers': value.integers,
    };
}

