import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import { PageSetting, PageSettingControllerApi } from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import style from "./PageSettingView.module.css";
import * as Storage from "../../../utils/local-storage";

var parse = require("html-react-parser");

export default function PageSettingView() {
  const [pageData, setPageData] = useState<PageSetting[]>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (
      Storage.instance.userInfo === null ||
      !Storage.instance.userInfo.isAdmin
    ) {
      <>{navigate(PATH.LOGIN_PATH)}</>;
    }

    // const userInfo = localStorage.getItem(PATH.USER_INFO);

    // if (userInfo === null) {
    //     navigate(PATH.LOGIN_PATH);
    // }
    new PageSettingControllerApi(genApiConfig())
      .getAllPageSettingUsingGET()
      .then((res: PageSetting[]) => {
        if (res !== null && res !== undefined) {
          setPageData(res.filter((it) => it.enable));
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, []);
  // process.env.REACT_APP_BACKEND + "/file/" +
  return (
    <div>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(PATH.ADMIN)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>

      <div className={style.pageSettingContainer}>
        {/*/
        TODO if www.nblib.com show abouts, another site dont show
        */}
        {pageData?.map((obj) => {
          return (
            <>
              <div className={style.button}>
                <div
                  onClick={() =>
                    navigate(obj.url!, { replace: false, state: obj })
                  }
                >
                  <img
                    src={
                      process.env.REACT_APP_BACKEND +
                      "/file/" +
                      obj.iconFileName
                    }
                  />
                  <h4>
                    <Trans> {obj.chtName}</Trans>
                  </h4>
                </div>
              </div>

              {/*  <Button styleType={STYLETYPE_MAIN} callBack={() => navigate(obj.url!, { replace: false, state: obj })}>
                                 {obj.chtName}
                                  </Button> */}
            </>
          );
        })}
      </div>
    </div>
  );
}
