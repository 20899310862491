/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BookReportRequestModel,
    BookReportRequestModelFromJSON,
    BookReportRequestModelToJSON,
    BorrowViewRecordReportRequestModel,
    BorrowViewRecordReportRequestModelFromJSON,
    BorrowViewRecordReportRequestModelToJSON,
    ReportStorageIdResponseModel,
    ReportStorageIdResponseModelFromJSON,
    ReportStorageIdResponseModelToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface ExportBookDetailsReportUsingGETRequest {
    name?: string;
}

export interface ExportBookReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface ExportBorrowViewRecordReportUsingPOSTRequest {
    name?: string;
    borrowViewRecordReportRequestModel?: BorrowViewRecordReportRequestModel;
}

export interface ExportReadDataReportUsingPOSTRequest {
    startDate: Date;
    endDate: Date;
    name?: string;
}

export interface ExportReservingBookReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface ExportborrowReadReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface RequestExportBookDetailsReportUsingGETRequest {
    name?: string;
}

export interface RequestExportBookReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface RequestExportBorrowReadReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface RequestExportBorrowViewRecordReportUsingPOSTRequest {
    name?: string;
    borrowViewRecordReportRequestModel?: BorrowViewRecordReportRequestModel;
}

export interface RequestExportReadDataReportUsingPOSTRequest {
    startDate: Date;
    endDate: Date;
    name?: string;
}

export interface RequestExportReservingBookReportUsingPOSTRequest {
    name?: string;
    bookReportRequestModel?: BookReportRequestModel;
}

export interface SaveReportRecordUsingPOSTRequest {
    memberId: number;
    productId: number;
    reportType: SaveReportRecordUsingPOSTReportTypeEnum;
    bookType: SaveReportRecordUsingPOSTBookTypeEnum;
}

/**
 * 
 */
export class ReportRecordControllerApi extends runtime.BaseAPI {

    /**
     * export excel for Book Details report
     */
    async exportBookDetailsReportUsingGETRaw(requestParameters: ExportBookDetailsReportUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-Book-Details-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for Book Details report
     */
    async exportBookDetailsReportUsingGET(requestParameters: ExportBookDetailsReportUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportBookDetailsReportUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for Book report
     */
    async exportBookReportUsingPOSTRaw(requestParameters: ExportBookReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-Book-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for Book report
     */
    async exportBookReportUsingPOST(requestParameters: ExportBookReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportBookReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for BorrowViewRecord report
     */
    async exportBorrowViewRecordReportUsingPOSTRaw(requestParameters: ExportBorrowViewRecordReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-BorrowViewRecord-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BorrowViewRecordReportRequestModelToJSON(requestParameters.borrowViewRecordReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for BorrowViewRecord report
     */
    async exportBorrowViewRecordReportUsingPOST(requestParameters: ExportBorrowViewRecordReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportBorrowViewRecordReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for ReadData report
     */
    async exportReadDataReportUsingPOSTRaw(requestParameters: ExportReadDataReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling exportReadDataReportUsingPOST.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling exportReadDataReportUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-ReadData-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for ReadData report
     */
    async exportReadDataReportUsingPOST(requestParameters: ExportReadDataReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportReadDataReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for Reserving Book
     */
    async exportReservingBookReportUsingPOSTRaw(requestParameters: ExportReservingBookReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-Reserving-Book-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for Reserving Book
     */
    async exportReservingBookReportUsingPOST(requestParameters: ExportReservingBookReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportReservingBookReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for borrowReadReport
     */
    async exportborrowReadReportUsingPOSTRaw(requestParameters: ExportborrowReadReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/export-borrowReadReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for borrowReadReport
     */
    async exportborrowReadReportUsingPOST(requestParameters: ExportborrowReadReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportborrowReadReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for Book Details report
     */
    async requestExportBookDetailsReportUsingGETRaw(requestParameters: RequestExportBookDetailsReportUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-Book-Details-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for Book Details report
     */
    async requestExportBookDetailsReportUsingGET(requestParameters: RequestExportBookDetailsReportUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportBookDetailsReportUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for Book report
     */
    async requestExportBookReportUsingPOSTRaw(requestParameters: RequestExportBookReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-Book-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for Book report
     */
    async requestExportBookReportUsingPOST(requestParameters: RequestExportBookReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportBookReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for borrowReadReport
     */
    async requestExportBorrowReadReportUsingPOSTRaw(requestParameters: RequestExportBorrowReadReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-borrowReadReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for borrowReadReport
     */
    async requestExportBorrowReadReportUsingPOST(requestParameters: RequestExportBorrowReadReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportBorrowReadReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for BorrowViewRecord report
     */
    async requestExportBorrowViewRecordReportUsingPOSTRaw(requestParameters: RequestExportBorrowViewRecordReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-BorrowViewRecord-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BorrowViewRecordReportRequestModelToJSON(requestParameters.borrowViewRecordReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for BorrowViewRecord report
     */
    async requestExportBorrowViewRecordReportUsingPOST(requestParameters: RequestExportBorrowViewRecordReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportBorrowViewRecordReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for ReadData report
     */
    async requestExportReadDataReportUsingPOSTRaw(requestParameters: RequestExportReadDataReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling requestExportReadDataReportUsingPOST.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling requestExportReadDataReportUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-ReadData-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for ReadData report
     */
    async requestExportReadDataReportUsingPOST(requestParameters: RequestExportReadDataReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportReadDataReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * request export excel for Reserving Book
     */
    async requestExportReservingBookReportUsingPOSTRaw(requestParameters: RequestExportReservingBookReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageIdResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/request-export-Reserving-Book-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookReportRequestModelToJSON(requestParameters.bookReportRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageIdResponseModelFromJSON(jsonValue));
    }

    /**
     * request export excel for Reserving Book
     */
    async requestExportReservingBookReportUsingPOST(requestParameters: RequestExportReservingBookReportUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageIdResponseModel> {
        const response = await this.requestExportReservingBookReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveReportRecord
     */
    async saveReportRecordUsingPOSTRaw(requestParameters: SaveReportRecordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling saveReportRecordUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling saveReportRecordUsingPOST.');
        }

        if (requestParameters.reportType === null || requestParameters.reportType === undefined) {
            throw new runtime.RequiredError('reportType','Required parameter requestParameters.reportType was null or undefined when calling saveReportRecordUsingPOST.');
        }

        if (requestParameters.bookType === null || requestParameters.bookType === undefined) {
            throw new runtime.RequiredError('bookType','Required parameter requestParameters.bookType was null or undefined when calling saveReportRecordUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.reportType !== undefined) {
            queryParameters['reportType'] = requestParameters.reportType;
        }

        if (requestParameters.bookType !== undefined) {
            queryParameters['bookType'] = requestParameters.bookType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportRecord/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * saveReportRecord
     */
    async saveReportRecordUsingPOST(requestParameters: SaveReportRecordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.saveReportRecordUsingPOSTRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const SaveReportRecordUsingPOSTReportTypeEnum = {
    BorrowTime: 'BORROW_TIME',
    CantBorrow: 'CANT_BORROW',
    Chapter: 'CHAPTER',
    Content: 'CONTENT',
    MobileBorrow: 'MOBILE_BORROW',
    Preview: 'PREVIEW',
    ReadOnline: 'READ_ONLINE',
    WebBorrow: 'WEB_BORROW'
} as const;
export type SaveReportRecordUsingPOSTReportTypeEnum = typeof SaveReportRecordUsingPOSTReportTypeEnum[keyof typeof SaveReportRecordUsingPOSTReportTypeEnum];
/**
 * @export
 */
export const SaveReportRecordUsingPOSTBookTypeEnum = {
    All: 'ALL',
    Audio: 'AUDIO',
    Ebook: 'EBOOK',
    Magazine: 'MAGAZINE'
} as const;
export type SaveReportRecordUsingPOSTBookTypeEnum = typeof SaveReportRecordUsingPOSTBookTypeEnum[keyof typeof SaveReportRecordUsingPOSTBookTypeEnum];
