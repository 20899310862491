/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingEvent,
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface OrderMarketingEvent
 */
export interface OrderMarketingEvent {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof OrderMarketingEvent
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingEvent
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Order}
     * @memberof OrderMarketingEvent
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingEvent
     */
    orderId?: number;
}

export function OrderMarketingEventFromJSON(json: any): OrderMarketingEvent {
    return OrderMarketingEventFromJSONTyped(json, false);
}

export function OrderMarketingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMarketingEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': !exists(json, 'marketingEventByMarketingEventId') ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'orderByOrderId': !exists(json, 'orderByOrderId') ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
    };
}

export function OrderMarketingEventToJSON(value?: OrderMarketingEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value.marketingEventByMarketingEventId),
        'marketingEventId': value.marketingEventId,
        'orderByOrderId': OrderToJSON(value.orderByOrderId),
        'orderId': value.orderId,
    };
}

