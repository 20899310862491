/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    roleId?: number;
}

export function RoleDtoFromJSON(json: any): RoleDto {
    return RoleDtoFromJSONTyped(json, false);
}

export function RoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
    };
}

export function RoleDtoToJSON(value?: RoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'isAdmin': value.isAdmin,
        'name': value.name,
        'roleId': value.roleId,
    };
}

