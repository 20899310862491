/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchMembersDto
 */
export interface BatchMembersDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchMembersDto
     */
    failMessage?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchMembersDto
     */
    successMessage?: Array<string>;
}

export function BatchMembersDtoFromJSON(json: any): BatchMembersDto {
    return BatchMembersDtoFromJSONTyped(json, false);
}

export function BatchMembersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchMembersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failMessage': !exists(json, 'failMessage') ? undefined : json['failMessage'],
        'successMessage': !exists(json, 'successMessage') ? undefined : json['successMessage'],
    };
}

export function BatchMembersDtoToJSON(value?: BatchMembersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failMessage': value.failMessage,
        'successMessage': value.successMessage,
    };
}

