/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CKEditorResponseDTO
 */
export interface CKEditorResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CKEditorResponseDTO
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof CKEditorResponseDTO
     */
    uploaded?: number;
    /**
     * 
     * @type {string}
     * @memberof CKEditorResponseDTO
     */
    url?: string;
}

export function CKEditorResponseDTOFromJSON(json: any): CKEditorResponseDTO {
    return CKEditorResponseDTOFromJSONTyped(json, false);
}

export function CKEditorResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CKEditorResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploaded': !exists(json, 'uploaded') ? undefined : json['uploaded'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function CKEditorResponseDTOToJSON(value?: CKEditorResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'uploaded': value.uploaded,
        'url': value.url,
    };
}

