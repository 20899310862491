/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminMemberDto,
    AdminMemberDtoFromJSON,
    AdminMemberDtoFromJSONTyped,
    AdminMemberDtoToJSON,
} from './AdminMemberDto';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';

/**
 * 
 * @export
 * @interface SsoEmailResponseModel
 */
export interface SsoEmailResponseModel {
    /**
     * 
     * @type {AdminMemberDto}
     * @memberof SsoEmailResponseModel
     */
    adminMember?: AdminMemberDto;
    /**
     * 
     * @type {MemberDto}
     * @memberof SsoEmailResponseModel
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof SsoEmailResponseModel
     */
    token?: string;
}

export function SsoEmailResponseModelFromJSON(json: any): SsoEmailResponseModel {
    return SsoEmailResponseModelFromJSONTyped(json, false);
}

export function SsoEmailResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SsoEmailResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminMember': !exists(json, 'adminMember') ? undefined : AdminMemberDtoFromJSON(json['adminMember']),
        'member': !exists(json, 'member') ? undefined : MemberDtoFromJSON(json['member']),
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function SsoEmailResponseModelToJSON(value?: SsoEmailResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminMember': AdminMemberDtoToJSON(value.adminMember),
        'member': MemberDtoToJSON(value.member),
        'token': value.token,
    };
}

