/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadDataReportResponseModel
 */
export interface ReadDataReportResponseModel {
    /**
     * 
     * @type {Date}
     * @memberof ReadDataReportResponseModel
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    ebookDeniedByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    ebookNumberSectionRequestByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    ebookReadCountByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    ebookSearchByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    magazineDeniedByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    magazineNumberSectionRequestByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    magazineReadCountByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    magazineSearchByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    totalTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    videoPlayCountByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    videoPlayDeniedByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    videoPlayNumberSectionRequestByMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadDataReportResponseModel
     */
    videoSearchByMonth?: number;
}

export function ReadDataReportResponseModelFromJSON(json: any): ReadDataReportResponseModel {
    return ReadDataReportResponseModelFromJSONTyped(json, false);
}

export function ReadDataReportResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadDataReportResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'ebookDeniedByMonth': !exists(json, 'ebookDeniedByMonth') ? undefined : json['ebookDeniedByMonth'],
        'ebookNumberSectionRequestByMonth': !exists(json, 'ebookNumberSectionRequestByMonth') ? undefined : json['ebookNumberSectionRequestByMonth'],
        'ebookReadCountByMonth': !exists(json, 'ebookReadCountByMonth') ? undefined : json['ebookReadCountByMonth'],
        'ebookSearchByMonth': !exists(json, 'ebookSearchByMonth') ? undefined : json['ebookSearchByMonth'],
        'magazineDeniedByMonth': !exists(json, 'magazineDeniedByMonth') ? undefined : json['magazineDeniedByMonth'],
        'magazineNumberSectionRequestByMonth': !exists(json, 'magazineNumberSectionRequestByMonth') ? undefined : json['magazineNumberSectionRequestByMonth'],
        'magazineReadCountByMonth': !exists(json, 'magazineReadCountByMonth') ? undefined : json['magazineReadCountByMonth'],
        'magazineSearchByMonth': !exists(json, 'magazineSearchByMonth') ? undefined : json['magazineSearchByMonth'],
        'totalTime': !exists(json, 'totalTime') ? undefined : json['totalTime'],
        'videoPlayCountByMonth': !exists(json, 'videoPlayCountByMonth') ? undefined : json['videoPlayCountByMonth'],
        'videoPlayDeniedByMonth': !exists(json, 'videoPlayDeniedByMonth') ? undefined : json['videoPlayDeniedByMonth'],
        'videoPlayNumberSectionRequestByMonth': !exists(json, 'videoPlayNumberSectionRequestByMonth') ? undefined : json['videoPlayNumberSectionRequestByMonth'],
        'videoSearchByMonth': !exists(json, 'videoSearchByMonth') ? undefined : json['videoSearchByMonth'],
    };
}

export function ReadDataReportResponseModelToJSON(value?: ReadDataReportResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'ebookDeniedByMonth': value.ebookDeniedByMonth,
        'ebookNumberSectionRequestByMonth': value.ebookNumberSectionRequestByMonth,
        'ebookReadCountByMonth': value.ebookReadCountByMonth,
        'ebookSearchByMonth': value.ebookSearchByMonth,
        'magazineDeniedByMonth': value.magazineDeniedByMonth,
        'magazineNumberSectionRequestByMonth': value.magazineNumberSectionRequestByMonth,
        'magazineReadCountByMonth': value.magazineReadCountByMonth,
        'magazineSearchByMonth': value.magazineSearchByMonth,
        'totalTime': value.totalTime,
        'videoPlayCountByMonth': value.videoPlayCountByMonth,
        'videoPlayDeniedByMonth': value.videoPlayDeniedByMonth,
        'videoPlayNumberSectionRequestByMonth': value.videoPlayNumberSectionRequestByMonth,
        'videoSearchByMonth': value.videoSearchByMonth,
    };
}

