/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportStorageDto,
    ReportStorageDtoFromJSON,
    ReportStorageDtoToJSON,
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models';

export interface DeleteUsingDELETERequest {
    id: number;
}

export interface GetReportStorageUsingGETRequest {
    status?: number;
    skip?: number;
    size?: number;
    startDate?: Date;
    endDate?: Date;
}

export interface GetUsingGETRequest {
    id: number;
}

/**
 * 
 */
export class ReportStorageControllerApi extends runtime.BaseAPI {

    /**
     * delete
     */
    async deleteUsingDELETERaw(requestParameters: DeleteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportStorage/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * delete
     */
    async deleteUsingDELETE(requestParameters: DeleteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.deleteUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getReportStorage
     */
    async getReportStorageUsingGETRaw(requestParameters: GetReportStorageUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ReportStorageDto>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.skip !== undefined) {
            queryParameters['skip'] = requestParameters.skip;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportStorage/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportStorageDtoFromJSON));
    }

    /**
     * getReportStorage
     */
    async getReportStorageUsingGET(requestParameters: GetReportStorageUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ReportStorageDto>> {
        const response = await this.getReportStorageUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get
     */
    async getUsingGETRaw(requestParameters: GetUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReportStorageDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/reportStorage/get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportStorageDtoFromJSON(jsonValue));
    }

    /**
     * get
     */
    async getUsingGET(requestParameters: GetUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReportStorageDto> {
        const response = await this.getUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
