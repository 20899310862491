// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageContent_exContainer__ERqCi{\n  box-shadow: 1px -1px 5px 1px rgb(235 235 235);\n  padding: 2em;\n  margin: 2em 0 0 0;\n  width: 100%;\n}\n\n.PageContent_exContainer__ERqCi  *{\n  max-width: 100% !important;\n  max-height: 100% !important;\n  word-break: break-word;\n}\n\n.PageContent_exContainer__ERqCi > div{\n    margin: 1em 0 0 0;\n    font-size: 0.8rem\n}", "",{"version":3,"sources":["webpack://./src/layout/PageContent/PageContent.module.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;IACI,iBAAiB;IACjB;AACJ","sourcesContent":[".exContainer{\n  box-shadow: 1px -1px 5px 1px rgb(235 235 235);\n  padding: 2em;\n  margin: 2em 0 0 0;\n  width: 100%;\n}\n\n.exContainer  *{\n  max-width: 100% !important;\n  max-height: 100% !important;\n  word-break: break-word;\n}\n\n.exContainer > div{\n    margin: 1em 0 0 0;\n    font-size: 0.8rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exContainer": "PageContent_exContainer__ERqCi"
};
export default ___CSS_LOADER_EXPORT___;
