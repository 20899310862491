/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Award,
    AwardFromJSON,
    AwardFromJSONTyped,
    AwardToJSON,
} from './Award';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductAward
 */
export interface ProductAward {
    /**
     * 
     * @type {Award}
     * @memberof ProductAward
     */
    awardByAwardId?: Award;
    /**
     * 
     * @type {number}
     * @memberof ProductAward
     */
    awardId?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductAward
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductAward
     */
    productId?: number;
}

export function ProductAwardFromJSON(json: any): ProductAward {
    return ProductAwardFromJSONTyped(json, false);
}

export function ProductAwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'awardByAwardId': !exists(json, 'awardByAwardId') ? undefined : AwardFromJSON(json['awardByAwardId']),
        'awardId': !exists(json, 'awardId') ? undefined : json['awardId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function ProductAwardToJSON(value?: ProductAward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'awardByAwardId': AwardToJSON(value.awardByAwardId),
        'awardId': value.awardId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
    };
}

