/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductRequestModel
 */
export interface ProductRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    aboutAuthor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    aboutSpeaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    categoryIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    coverFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    episode?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubPreviewFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubPreviewFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubProdFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubProdFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    multimediaFileNames?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ProductRequestModel
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductRequestModel
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductRequestModel
     */
    ppublishDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productDescription?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductRequestModel
     */
    productIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    productImage?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    publishHouseId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    restrictedContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    sapArticleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    seriesName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    seriesNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    speaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    subscriptionPeriod?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    supplierId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    thumbFileNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    totalDuration?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    version?: string;
}

export function ProductRequestModelFromJSON(json: any): ProductRequestModel {
    return ProductRequestModelFromJSONTyped(json, false);
}

export function ProductRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboutAuthor': !exists(json, 'aboutAuthor') ? undefined : json['aboutAuthor'],
        'aboutSpeaker': !exists(json, 'aboutSpeaker') ? undefined : json['aboutSpeaker'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookIsbn': !exists(json, 'bookIsbn') ? undefined : json['bookIsbn'],
        'categoryIds': !exists(json, 'categoryIds') ? undefined : json['categoryIds'],
        'coverFileName': !exists(json, 'coverFileName') ? undefined : json['coverFileName'],
        'episode': !exists(json, 'episode') ? undefined : json['episode'],
        'epubPreviewFileMd5': !exists(json, 'epubPreviewFileMd5') ? undefined : json['epubPreviewFileMd5'],
        'epubPreviewFileName': !exists(json, 'epubPreviewFileName') ? undefined : json['epubPreviewFileName'],
        'epubProdFileMd5': !exists(json, 'epubProdFileMd5') ? undefined : json['epubProdFileMd5'],
        'epubProdFileName': !exists(json, 'epubProdFileName') ? undefined : json['epubProdFileName'],
        'isGroupProduct': !exists(json, 'isGroupProduct') ? undefined : json['isGroupProduct'],
        'multimediaFileNames': !exists(json, 'multimediaFileNames') ? undefined : json['multimediaFileNames'],
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'ppublishDate': !exists(json, 'ppublishDate') ? undefined : (new Date(json['ppublishDate'])),
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'productIds': !exists(json, 'productIds') ? undefined : json['productIds'],
        'productInventory': !exists(json, 'productInventory') ? undefined : json['productInventory'],
        'productNameChi': !exists(json, 'productNameChi') ? undefined : json['productNameChi'],
        'productNameEng': !exists(json, 'productNameEng') ? undefined : json['productNameEng'],
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'productVolume': !exists(json, 'productVolume') ? undefined : json['productVolume'],
        'productVolumeUnit': !exists(json, 'productVolumeUnit') ? undefined : json['productVolumeUnit'],
        'productImage': !exists(json, 'product_image') ? undefined : json['product_image'],
        'publishHouseId': !exists(json, 'publishHouseId') ? undefined : json['publishHouseId'],
        'restrictedContent': !exists(json, 'restrictedContent') ? undefined : json['restrictedContent'],
        'sapArticleNumber': !exists(json, 'sapArticleNumber') ? undefined : json['sapArticleNumber'],
        'seriesName': !exists(json, 'seriesName') ? undefined : json['seriesName'],
        'seriesNumber': !exists(json, 'seriesNumber') ? undefined : json['seriesNumber'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'speaker': !exists(json, 'speaker') ? undefined : json['speaker'],
        'subscriptionPeriod': !exists(json, 'subscriptionPeriod') ? undefined : json['subscriptionPeriod'],
        'supplierId': !exists(json, 'supplierId') ? undefined : json['supplierId'],
        'thumbFileNames': !exists(json, 'thumbFileNames') ? undefined : json['thumbFileNames'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ProductRequestModelToJSON(value?: ProductRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aboutAuthor': value.aboutAuthor,
        'aboutSpeaker': value.aboutSpeaker,
        'author': value.author,
        'bookIsbn': value.bookIsbn,
        'categoryIds': value.categoryIds,
        'coverFileName': value.coverFileName,
        'episode': value.episode,
        'epubPreviewFileMd5': value.epubPreviewFileMd5,
        'epubPreviewFileName': value.epubPreviewFileName,
        'epubProdFileMd5': value.epubProdFileMd5,
        'epubProdFileName': value.epubProdFileName,
        'isGroupProduct': value.isGroupProduct,
        'multimediaFileNames': value.multimediaFileNames,
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'ppublishDate': value.ppublishDate === undefined ? undefined : (value.ppublishDate.toISOString()),
        'productDescription': value.productDescription,
        'productIds': value.productIds,
        'productInventory': value.productInventory,
        'productNameChi': value.productNameChi,
        'productNameEng': value.productNameEng,
        'productPrice': value.productPrice,
        'productVolume': value.productVolume,
        'productVolumeUnit': value.productVolumeUnit,
        'product_image': value.productImage,
        'publishHouseId': value.publishHouseId,
        'restrictedContent': value.restrictedContent,
        'sapArticleNumber': value.sapArticleNumber,
        'seriesName': value.seriesName,
        'seriesNumber': value.seriesNumber,
        'shortDescription': value.shortDescription,
        'speaker': value.speaker,
        'subscriptionPeriod': value.subscriptionPeriod,
        'supplierId': value.supplierId,
        'thumbFileNames': value.thumbFileNames,
        'totalDuration': value.totalDuration,
        'version': value.version,
    };
}

