/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import {
    OrderProduct,
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';

/**
 * 
 * @export
 * @interface OrderProductDiscount
 */
export interface OrderProductDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof OrderProductDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {OrderProduct}
     * @memberof OrderProductDiscount
     */
    orderProductByOrderProductId?: OrderProduct;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDiscount
     */
    orderProductId?: number;
}

export function OrderProductDiscountFromJSON(json: any): OrderProductDiscount {
    return OrderProductDiscountFromJSONTyped(json, false);
}

export function OrderProductDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'orderProductByOrderProductId': !exists(json, 'orderProductByOrderProductId') ? undefined : OrderProductFromJSON(json['orderProductByOrderProductId']),
        'orderProductId': !exists(json, 'orderProductId') ? undefined : json['orderProductId'],
    };
}

export function OrderProductDiscountToJSON(value?: OrderProductDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
        'orderProductByOrderProductId': OrderProductToJSON(value.orderProductByOrderProductId),
        'orderProductId': value.orderProductId,
    };
}

