import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import SelectOption from "../../../components/SelectOption/SelectOption";
import style from "./CounterReport.module.css";
import { STYLETYPE_FIFTH, STYLETYPE_MAIN, STYLETYPE_SECOND } from "../../../utils/Contants";
import { fetchFileFromURLToFile, fetchFileFromURLToFilePost } from "../../../utils/reader/fileUtils/fileUtil";
import {
    GetUsingGETRequest,
    ReadDataReportResponseModel,
    ReportControllerApi,
    ReportRecordControllerApi, ReportStorageControllerApi, ReportStorageDto, ReportStorageIdResponseModel,
    RequestExportReadDataReportUsingPOSTRequest
} from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import PageLoading from "../../loading/PageLoading";
import useWindowSize from "../../../utils/useWindowSize";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import * as PATH from "../../../utils/Contants";
import { dateToString } from "../../../utils/GlobalFunction";
import * as Storage from "../../../utils/local-storage";
export default function CounterReport() {
    const [displayData, setDisplayData] = useState<Array<ReadDataReportResponseModel>>();

    const [yearArr, setYearArr] = useState<any>([]);
    const [monthArr, setMonthArr] = useState<any>([]);
    // const [startYear, setSartYear] = useState("");
    // const [endYear, setEndYear] = useState("");
    // const [startMonth, setSartMonth] = useState("");
    // const [endMonth, setEndMonth] = useState("");

    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    const [lastdate, setLastDate] = useState("");

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        var now = new Date();
        let yearArr = [];
        for (var i = 2000; i <= now.getFullYear(); i++) {
            yearArr.push({ id: i, name: i.toString() });
        }
        setYearArr(yearArr.reverse());
        let monthArr = [];
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                monthArr.push({ id: i, name: `0${i}` });
            } else {
                monthArr.push({ id: i, name: i.toString() });
            }
        }
        setMonthArr(monthArr);

        var nextyear = new Date(new Date().getFullYear(), 11, 31);
        const currentDayOfMonth = nextyear.getDate();
        const currentMonth = nextyear.getMonth(); // Be careful! January is 0, not 1
        const currentYear = nextyear.getFullYear();
        const dateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
        setLastDate(dateString);
    }, []);

    const handleDateSubmit = () => {
        // console.log(startYear);
        // console.log(startMonth);
        // console.log(endYear);
        // console.log(endMonth);

        // if (startYear == null || startYear == "") {
        //     return alert(`請選擇開始年份`);
        // }
        // if (startMonth == null || startMonth == "") {
        //     return alert(`請選擇開始月份`);
        // }
        // if (endYear == null || endYear == "") {
        //     return alert(`請選擇完結年份`);
        // }
        // if (endMonth == null || endMonth == "") {
        //     return alert(`請選擇完結月份`);
        // }
        const dateObj = {
            startDate: start,
            endDate: end,
        };
        if (new Date(dateObj.startDate) > new Date(dateObj.endDate)) {
            return alert(`開始日期必須小於最後日期`);
        }

        //var sDate = new Date(dateObj.startDate);

        //var eDate = new Date(dateObj.endDate);

        new ReportControllerApi(genApiConfig())
            .readDataReportBetweenDateUsingGET({
                startDate: dateObj.startDate,
                endDate: dateObj.endDate,
            })
            .then((res: ReadDataReportResponseModel[]) => {
                if (res !== undefined) {
                    console.log(res);
                    setDisplayData(res);
                }
            })
            .catch((err) => console.log("err", err));

        //console.log("dateObj", dateObj);
        //  console.log("sDate", sDate);
        //  console.log("eDate", eDate);
    };
    const downloadReadDataReport = () => {
        // if (startYear == null || startYear == "") {
        //     return alert(`請選擇開始年份`);
        // }
        // if (startMonth == null || startMonth == "") {
        //     return alert(`請選擇開始月份`);
        // }
        // if (endYear == null || endYear == "") {
        //     return alert(`請選擇完結年份`);
        // }
        // if (endMonth == null || endMonth == "") {
        //     return alert(`請選擇完結月份`);
        // }
        // const dateObj = {
        //     startDate: `${startYear}-${startMonth}`,
        //     endDate: endYear + "-" + endMonth,
        // };

        console.log("start", start)
        console.log("end", end)

        if(start.length === 0){
            return alert(`請選擇開始日期`);
        }

        if(end.length === 0){
            return alert(`請選擇完結日期`);
        }

        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於完結日期`);
        }

        const dateObj = {
            startDate: new Date(start),
            endDate: new Date(end),
        } as RequestExportReadDataReportUsingPOSTRequest;

        setIsDownloadLoading(true)

        new ReportRecordControllerApi(genApiConfig())
            .requestExportReadDataReportUsingPOST(dateObj)
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))

    };

    const reportStorageHandler = (res: ReportStorageIdResponseModel) => {
        function timerFunc() {
            new ReportStorageControllerApi(genApiConfig())
                .getUsingGET({
                    id: res.reportStorageId
                } as GetUsingGETRequest)
                .then((res: ReportStorageDto) => {
                    // Status :
                    // -1 -> Invalid
                    //  0 -> Pending
                    //  1 -> Running
                    //  2 -> Readied
                    //  3 -> Deleted

                    if (res.status === -1 || res.status === 2 || res.status === 3) {
                        if (res.status === 2) {
                            fetchFileFromURLToFile(
                                `${process.env.REACT_APP_BACKEND}/file/${res.fileName}`,
                                res.fileName
                            ).then(() => {
                                setIsDownloadLoading(false)
                            })
                        } else {
                            setIsDownloadLoading(false)
                        }
                    } else {
                        let seconds = 1
                        setTimeout(timerFunc, seconds * 1000)
                    }
                })
        }

        let seconds = 1
        setTimeout(timerFunc, seconds * 1000)
    }

    const handletoggle = (value: any) => {};

    return (
        <>
            {isDownloadLoading ? (<>
                <PageLoading/>
            </>) : (<></>)}
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => handletoggle(value)}>
                        {/* <Trans>CateList</Trans> */}閱讀數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}借閱及瀏覽紀錄報告
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}書籍數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.CATEGORYREPORT)}>
                        主題分類排行榜
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.RESERVINGREPORT)}>
                        預約中查詢數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREADREPORT)}>
                        借閱瀏覽紀錄報表
                    </Button>
                </div>
            </div>
            <div className={style.counterReport}>
                <h2>閱讀數據統計</h2>
                <div>
                    {/* <p>時間:</p>
                    <div className={style.selectGroupContainer}>
                        <SelectOption styleType={STYLETYPE_SECOND} items={yearArr} callBack={(value) => setSartYear(value.name)} placeholder={"由(年份)"} />
                        <SelectOption styleType={STYLETYPE_SECOND} items={monthArr} callBack={(value) => setSartMonth(value.name)} placeholder={"月份"} />
                        <SelectOption styleType={STYLETYPE_SECOND} items={yearArr} callBack={(value) => setEndYear(value.name)} placeholder={"至(年份)"} />
                        <SelectOption styleType={STYLETYPE_SECOND} items={monthArr} callBack={(value) => setEndMonth(value.name)} placeholder={"月份"} />
                    </div> */}
                    <p>時間:</p>
                    <div className={style.datePickerContainer}>
                        <label htmlFor="start"></label>
                        <input type="date" id="start" name="trip-start" min="2018-01-01" max={lastdate} value={start} onChange={(e) => setStart(e.target.value)} />
                        <label htmlFor="end"> ~ </label>
                        <input type="date" id="end" name="trip-end" min="2018-01-01" max={lastdate} value={end} onChange={(e) => setEnd(e.target.value)} />
                        {/* <p>(提供查詢5年資料，查詢區間限定1個月內。)</p> */}
                    </div>
                    <Button styleType={"submitButton"} callBack={() => handleDateSubmit()}>
                        查詢
                    </Button>
                    <Button styleType={"submitButton"} callBack={() => downloadReadDataReport()}>
                        匯出EXCEL
                    </Button>

                    <hr className={style.line}></hr>
                    <div>
                        <table className={style.table}>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={3}>BR1</th>

                                <th colSpan={3}>BR2</th>

                                <th colSpan={3}>BR3</th>

                                <th colSpan={2}>BR5</th>
                            </tr>
                            <tr>
                                <th style={{ minWidth: "100px" }}>年/月</th>
                                <th>每月閱讀電子書次數</th>
                                <th>每月閱讀雜誌次數</th>
                                <th>每月閱讀音視頻次數</th>
                                <th>每月閱讀電子書章節次數</th>
                                <th>每月閱讀雜誌章節次數</th>
                                <th>每月閱讀音視頻章節次數</th>
                                <th>每月借閱電子書已滿次數</th>
                                <th>每月借閱雜誌已滿次數</th>
                                <th>每月影片播放已滿次數</th>
                                <th>每月瀏覽電子書詳目次數</th>
                                <th>每月瀏覽雜誌詳目次數</th>
                                <th>每月瀏覽影片詳目次數</th>
                                <th>每月閱讀總時數</th>
                            </tr>

                            {displayData?.map((it) => {
                                let hours = Math.floor(it?.totalTime!! / 3600);
                                let totalTime = it?.totalTime!! % 3600;
                                let minutes = Math.floor(totalTime / 60);
                                let seconds = totalTime % 60;
                                return (
                                    <tr>
                                        <td>{dateToString(it?.date, "YYYY-MM")}</td>
                                        <td>{it?.ebookReadCountByMonth}</td>
                                        <td>{it?.magazineReadCountByMonth}</td>
                                        <td>{it?.videoPlayCountByMonth}</td>
                                        <td>{it?.ebookNumberSectionRequestByMonth}</td>
                                        <td>{it?.magazineNumberSectionRequestByMonth}</td>
                                        <td>{it?.videoPlayNumberSectionRequestByMonth}</td>
                                        <td>{it?.ebookDeniedByMonth}</td>
                                        <td>{it?.magazineDeniedByMonth}</td>
                                        <td>{it?.videoPlayDeniedByMonth}</td>
                                        <td>{it?.ebookSearchByMonth}</td>
                                        <td>{it?.magazineSearchByMonth}</td>
                                        <td>{it?.videoSearchByMonth}</td>
                                        <td>
                                            {hours}h{minutes}m{seconds}s
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
