/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    addressId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    contactNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    isDefault?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    phoneCountryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    recipient?: string;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressId': !exists(json, 'addressId') ? undefined : json['addressId'],
        'addressRow1': !exists(json, 'addressRow1') ? undefined : json['addressRow1'],
        'addressRow2': !exists(json, 'addressRow2') ? undefined : json['addressRow2'],
        'contactNumber': !exists(json, 'contactNumber') ? undefined : json['contactNumber'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'phoneCountryId': !exists(json, 'phoneCountryId') ? undefined : json['phoneCountryId'],
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressId': value.addressId,
        'addressRow1': value.addressRow1,
        'addressRow2': value.addressRow2,
        'contactNumber': value.contactNumber,
        'countryId': value.countryId,
        'isDefault': value.isDefault,
        'phoneCountryId': value.phoneCountryId,
        'recipient': value.recipient,
    };
}

