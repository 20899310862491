/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoToJSON,
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoToJSON,
    DashboardDto,
    DashboardDtoFromJSON,
    DashboardDtoToJSON,
    DashboardRecommendationDto,
    DashboardRecommendationDtoFromJSON,
    DashboardRecommendationDtoToJSON,
    MarketingEventBannerDto,
    MarketingEventBannerDtoFromJSON,
    MarketingEventBannerDtoToJSON,
    MarketingEventDto,
    MarketingEventDtoFromJSON,
    MarketingEventDtoToJSON,
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
} from '../models';

/**
 * 
 */
export class DashboardControllerApi extends runtime.BaseAPI {

    /**
     * check status
     */
    async checkStatusUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/checkstatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * check status
     */
    async checkStatusUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.checkStatusUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with banner
     */
    async getDashboardBannerUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventBannerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/banner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventBannerDtoFromJSON));
    }

    /**
     * dashboard data with banner
     */
    async getDashboardBannerUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventBannerDto>> {
        const response = await this.getDashboardBannerUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with banner
     */
    async getDashboardCategoryUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * dashboard data with banner
     */
    async getDashboardCategoryUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getDashboardCategoryUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data
     */
    async getDashboardDataUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DashboardDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDtoFromJSON(jsonValue));
    }

    /**
     * dashboard data
     */
    async getDashboardDataUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DashboardDto> {
        const response = await this.getDashboardDataUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with marketing event
     */
    async getDashboardMarketingEventUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/marketingevent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * dashboard data with marketing event
     */
    async getDashboardMarketingEventUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getDashboardMarketingEventUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with rand book
     */
    async getDashboardMessageUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/message`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * dashboard data with rand book
     */
    async getDashboardMessageUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.getDashboardMessageUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with rand book
     */
    async getDashboardRandBookUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/rand/book`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * dashboard data with rand book
     */
    async getDashboardRandBookUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getDashboardRandBookUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with recommend category
     */
    async getDashboardRecommendCategoryUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DashboardRecommendationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/recommend/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DashboardRecommendationDtoFromJSON));
    }

    /**
     * dashboard data with recommend category
     */
    async getDashboardRecommendCategoryUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DashboardRecommendationDto>> {
        const response = await this.getDashboardRecommendCategoryUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with return book data
     */
    async getDashboardReturnBookUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<BorrowDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/return/book`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorrowDtoFromJSON));
    }

    /**
     * dashboard data with return book data
     */
    async getDashboardReturnBookUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<BorrowDto>> {
        const response = await this.getDashboardReturnBookUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * Dashboard data for sort
     */
    async getDashboardSortUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/category/sort`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * Dashboard data for sort
     */
    async getDashboardSortUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getDashboardSortUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * dashboard data with top ten borrow book
     */
    async getDashboardTopTenBorrowBookUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/dashboard/top-ten/book`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * dashboard data with top ten borrow book
     */
    async getDashboardTopTenBorrowBookUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getDashboardTopTenBorrowBookUsingGETRaw(initOverrides);
        return await response.value();
    }

}
