/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoFromJSONTyped,
    BorrowDtoToJSON,
} from './BorrowDto';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    DashboardRecommendationDto,
    DashboardRecommendationDtoFromJSON,
    DashboardRecommendationDtoFromJSONTyped,
    DashboardRecommendationDtoToJSON,
} from './DashboardRecommendationDto';
import {
    MarketingEventBannerDto,
    MarketingEventBannerDtoFromJSON,
    MarketingEventBannerDtoFromJSONTyped,
    MarketingEventBannerDtoToJSON,
} from './MarketingEventBannerDto';
import {
    MarketingEventDto,
    MarketingEventDtoFromJSON,
    MarketingEventDtoFromJSONTyped,
    MarketingEventDtoToJSON,
} from './MarketingEventDto';

/**
 * 
 * @export
 * @interface DashboardDto
 */
export interface DashboardDto {
    /**
     * 
     * @type {Array<MarketingEventBannerDto>}
     * @memberof DashboardDto
     */
    banners?: Array<MarketingEventBannerDto>;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof DashboardDto
     */
    categories?: Array<CategoryDto>;
    /**
     * 
     * @type {Array<MarketingEventDto>}
     * @memberof DashboardDto
     */
    events?: Array<MarketingEventDto>;
    /**
     * 
     * @type {Array<DashboardRecommendationDto>}
     * @memberof DashboardDto
     */
    recommendations?: Array<DashboardRecommendationDto>;
    /**
     * 
     * @type {Array<BorrowDto>}
     * @memberof DashboardDto
     */
    returnBooks?: Array<BorrowDto>;
}

export function DashboardDtoFromJSON(json: any): DashboardDto {
    return DashboardDtoFromJSONTyped(json, false);
}

export function DashboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'banners': !exists(json, 'banners') ? undefined : ((json['banners'] as Array<any>).map(MarketingEventBannerDtoFromJSON)),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(CategoryDtoFromJSON)),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(MarketingEventDtoFromJSON)),
        'recommendations': !exists(json, 'recommendations') ? undefined : ((json['recommendations'] as Array<any>).map(DashboardRecommendationDtoFromJSON)),
        'returnBooks': !exists(json, 'returnBooks') ? undefined : ((json['returnBooks'] as Array<any>).map(BorrowDtoFromJSON)),
    };
}

export function DashboardDtoToJSON(value?: DashboardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'banners': value.banners === undefined ? undefined : ((value.banners as Array<any>).map(MarketingEventBannerDtoToJSON)),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(CategoryDtoToJSON)),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(MarketingEventDtoToJSON)),
        'recommendations': value.recommendations === undefined ? undefined : ((value.recommendations as Array<any>).map(DashboardRecommendationDtoToJSON)),
        'returnBooks': value.returnBooks === undefined ? undefined : ((value.returnBooks as Array<any>).map(BorrowDtoToJSON)),
    };
}

