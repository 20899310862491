import React from "react";
import { STYLETYPE_IMG } from "../../../utils/Contants";
import style from "./SearchResultCardView.module.css";

import editIcon from "../../../../assets/images/btn/edit.svg";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const SearchResultCardView = ({ props }: any) => {
    const navigate = useNavigate();
    const handleEditCallBack = () => {};
    const { i18n } = useTranslation();
    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });

    return (
        <>
            <div className={style.TableCardViewContainer} onClick={() => navigate(`/product/${props && props.productId}`)}>
                <div className={style.TableCardView_IMG}>
                    <img src={props && process.env.REACT_APP_BACKEND + "/file/" + props.coverFileName} />
                </div>
                <div className={style.TableCardViewContent}>
                    <div>
                        <h4>{i18n.language === "cn" ? props && converter(props.productNameChi) : props && props.productNameChi}</h4>
                    </div>
                    <div style={{ fontSize: "1em" }}>{i18n.language === "cn" ? props && converter(props.author) : props && props.author}</div>
                </div>
            </div>
        </>
    );
};

export default SearchResultCardView;
