/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    callingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    numberOfDigit?: number;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'callingCode': !exists(json, 'callingCode') ? undefined : json['callingCode'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'numberOfDigit': !exists(json, 'numberOfDigit') ? undefined : json['numberOfDigit'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'callingCode': value.callingCode,
        'countryName': value.countryName,
        'locationId': value.locationId,
        'numberOfDigit': value.numberOfDigit,
    };
}

