/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminMemberActionLogDto,
    AdminMemberActionLogDtoFromJSON,
    AdminMemberActionLogDtoFromJSONTyped,
    AdminMemberActionLogDtoToJSON,
} from './AdminMemberActionLogDto';

/**
 * 
 * @export
 * @interface PagingModelAdminMemberActionLogDto
 */
export interface PagingModelAdminMemberActionLogDto {
    /**
     * 
     * @type {Array<AdminMemberActionLogDto>}
     * @memberof PagingModelAdminMemberActionLogDto
     */
    list?: Array<AdminMemberActionLogDto>;
    /**
     * 
     * @type {number}
     * @memberof PagingModelAdminMemberActionLogDto
     */
    numPerPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModelAdminMemberActionLogDto
     */
    pageno?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModelAdminMemberActionLogDto
     */
    pagenum?: number;
}

export function PagingModelAdminMemberActionLogDtoFromJSON(json: any): PagingModelAdminMemberActionLogDto {
    return PagingModelAdminMemberActionLogDtoFromJSONTyped(json, false);
}

export function PagingModelAdminMemberActionLogDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagingModelAdminMemberActionLogDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : ((json['list'] as Array<any>).map(AdminMemberActionLogDtoFromJSON)),
        'numPerPage': !exists(json, 'numPerPage') ? undefined : json['numPerPage'],
        'pageno': !exists(json, 'pageno') ? undefined : json['pageno'],
        'pagenum': !exists(json, 'pagenum') ? undefined : json['pagenum'],
    };
}

export function PagingModelAdminMemberActionLogDtoToJSON(value?: PagingModelAdminMemberActionLogDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : ((value.list as Array<any>).map(AdminMemberActionLogDtoToJSON)),
        'numPerPage': value.numPerPage,
        'pageno': value.pageno,
        'pagenum': value.pagenum,
    };
}

