/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import {
    DisplayProduct,
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';

/**
 * 
 * @export
 * @interface DisplayProductArticle
 */
export interface DisplayProductArticle {
    /**
     * 
     * @type {Article}
     * @memberof DisplayProductArticle
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductArticle
     */
    articleId?: number;
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductArticle
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductArticle
     */
    displayProductId?: number;
}

export function DisplayProductArticleFromJSON(json: any): DisplayProductArticle {
    return DisplayProductArticleFromJSONTyped(json, false);
}

export function DisplayProductArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleByArticleId': !exists(json, 'articleByArticleId') ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'displayProductByDisplayProductId': !exists(json, 'displayProductByDisplayProductId') ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
    };
}

export function DisplayProductArticleToJSON(value?: DisplayProductArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value.articleByArticleId),
        'articleId': value.articleId,
        'displayProductByDisplayProductId': DisplayProductToJSON(value.displayProductByDisplayProductId),
        'displayProductId': value.displayProductId,
    };
}

