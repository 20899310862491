/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import {
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MemberLevelDiscount
 */
export interface MemberLevelDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof MemberLevelDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MemberLevelDiscount
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDiscount
     */
    memberLevelId?: number;
}

export function MemberLevelDiscountFromJSON(json: any): MemberLevelDiscount {
    return MemberLevelDiscountFromJSONTyped(json, false);
}

export function MemberLevelDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'memberLevelByMemberLevelId': !exists(json, 'memberLevelByMemberLevelId') ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
    };
}

export function MemberLevelDiscountToJSON(value?: MemberLevelDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
        'memberLevelByMemberLevelId': MemberLevelToJSON(value.memberLevelByMemberLevelId),
        'memberLevelId': value.memberLevelId,
    };
}

