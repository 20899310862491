/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgeGroup,
    AgeGroupFromJSON,
    AgeGroupFromJSONTyped,
    AgeGroupToJSON,
} from './AgeGroup';
import {
    MemberLevelDtoLite,
    MemberLevelDtoLiteFromJSON,
    MemberLevelDtoLiteFromJSONTyped,
    MemberLevelDtoLiteToJSON,
} from './MemberLevelDtoLite';

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    acceptPromoption?: string;
    /**
     * 
     * @type {AgeGroup}
     * @memberof MemberDto
     */
    ageGroup?: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    ageGroupId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    birthday?: Date;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    birthdayMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    classNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDto
     */
    dbDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    expireDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    expiredPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    googleId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    grade?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    joinDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    lastname?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    memberId?: number;
    /**
     * 
     * @type {MemberLevelDtoLite}
     * @memberof MemberDto
     */
    memberLevel?: MemberLevelDtoLite;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    membershipExpireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    nameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    nameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    orderNum?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    totalPoint?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    usedPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    validPoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    vipJoinDate?: Date;
}

export function MemberDtoFromJSON(json: any): MemberDto {
    return MemberDtoFromJSONTyped(json, false);
}

export function MemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptPromoption': !exists(json, 'acceptPromoption') ? undefined : json['acceptPromoption'],
        'ageGroup': !exists(json, 'ageGroup') ? undefined : AgeGroupFromJSON(json['ageGroup']),
        'ageGroupId': !exists(json, 'ageGroupId') ? undefined : json['ageGroupId'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'birthdayMonth': !exists(json, 'birthdayMonth') ? undefined : json['birthdayMonth'],
        'className': !exists(json, 'className') ? undefined : json['className'],
        'classNumber': !exists(json, 'classNumber') ? undefined : json['classNumber'],
        'dbDelete': !exists(json, 'dbDelete') ? undefined : json['dbDelete'],
        'defaultLanguage': !exists(json, 'defaultLanguage') ? undefined : json['defaultLanguage'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'expireDate': !exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'expiredPoint': !exists(json, 'expiredPoint') ? undefined : json['expiredPoint'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'googleId': !exists(json, 'googleId') ? undefined : json['googleId'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'joinDate': !exists(json, 'joinDate') ? undefined : (new Date(json['joinDate'])),
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'memberLevel': !exists(json, 'memberLevel') ? undefined : MemberLevelDtoLiteFromJSON(json['memberLevel']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'membershipExpireDate': !exists(json, 'membershipExpireDate') ? undefined : (new Date(json['membershipExpireDate'])),
        'nameChi': !exists(json, 'nameChi') ? undefined : json['nameChi'],
        'nameEng': !exists(json, 'nameEng') ? undefined : json['nameEng'],
        'orderNum': !exists(json, 'orderNum') ? undefined : json['orderNum'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'totalPoint': !exists(json, 'totalPoint') ? undefined : json['totalPoint'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'validPoint': !exists(json, 'validPoint') ? undefined : json['validPoint'],
        'vipJoinDate': !exists(json, 'vipJoinDate') ? undefined : (new Date(json['vipJoinDate'])),
    };
}

export function MemberDtoToJSON(value?: MemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptPromoption': value.acceptPromoption,
        'ageGroup': AgeGroupToJSON(value.ageGroup),
        'ageGroupId': value.ageGroupId,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString()),
        'birthdayMonth': value.birthdayMonth,
        'className': value.className,
        'classNumber': value.classNumber,
        'dbDelete': value.dbDelete,
        'defaultLanguage': value.defaultLanguage,
        'email': value.email,
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
        'expiredPoint': value.expiredPoint,
        'firstname': value.firstname,
        'gender': value.gender,
        'googleId': value.googleId,
        'grade': value.grade,
        'joinDate': value.joinDate === undefined ? undefined : (value.joinDate.toISOString()),
        'lastname': value.lastname,
        'locationId': value.locationId,
        'memberId': value.memberId,
        'memberLevel': MemberLevelDtoLiteToJSON(value.memberLevel),
        'memberLevelId': value.memberLevelId,
        'membershipExpireDate': value.membershipExpireDate === undefined ? undefined : (value.membershipExpireDate.toISOString()),
        'nameChi': value.nameChi,
        'nameEng': value.nameEng,
        'orderNum': value.orderNum,
        'phone': value.phone,
        'totalPoint': value.totalPoint,
        'usedPoint': value.usedPoint,
        'username': value.username,
        'validPoint': value.validPoint,
        'vipJoinDate': value.vipJoinDate === undefined ? undefined : (value.vipJoinDate.toISOString()),
    };
}

