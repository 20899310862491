import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import { BookBorrowSettingDto, SystemSetting, SystemSettingControllerApi, UpdateBookBorrowSettingUsingPOSTRequest } from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { STYLETYPE_MAIN } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";

import useWindowSize from "../../../utils/useWindowSize";
import style from "./LendSetting.module.css";
import * as Storage from "../../../utils/local-storage";
export default function LendSetting() {
    const { i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [bookBorrowMaxDay, setBookBorrowMaxDay] = useState<number>(0);
    const [bookBorrowSameTime, setBookBorrowSameTime] = useState<number>(0);
    const [bookBorrowResumeDay, setBookBorrowResumeDay] = useState<number>(0);
    const [bookBorrowMaxResumeCount, setBookBorrowMaxResumeCount] = useState<number>(0);
    const [bookBorrowResumeCount, setBookBorrowResumeCount] = useState<number>(0);
    const [popularBooks, setPopularBooks] = useState<number>(0);
    const [surpriseMe, setSurpriseMe] = useState<number>(0);
    const [recentlyReturned, setRecentlyReturned] = useState<number>(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }

        // const userInfo = localStorage.getItem(PATH.USER_INFO);

        // if (userInfo === null) {
        //     navigate(PATH.LOGIN_PATH);
        // }

        new SystemSettingControllerApi(genApiConfig())
            .getBookBorrowSettingUsingGET()
            .then((res: SystemSetting) => {
                setBookBorrowMaxDay(res.bookBorrowMaxDay!);
                setBookBorrowMaxResumeCount(res.bookBorrowMaxResumeCount!);
                setBookBorrowResumeDay(res.bookBorrowResumeDay!);
                setBookBorrowSameTime(res.bookBorrowSameTime!);
                setBookBorrowResumeCount(res.bookBorrowResumeCount!);
                setPopularBooks(res.popularBooks!);
                setSurpriseMe(res.surpriseMe!);
                setRecentlyReturned(res.recentlyReturned!);
            })
            .catch((err) => alert(`找不到系統設定︰${err}`));
    }, []);

    const handleSubmit = () => {
        let obj = {
            bookBorrowMaxDay,
            bookBorrowMaxResumeCount,
            bookBorrowResumeDay,
            bookBorrowSameTime,
            bookBorrowResumeCount,
            popularBooks,
            surpriseMe,
            recentlyReturned,
        } as BookBorrowSettingDto;

        obj.bookBorrowMaxDay = bookBorrowMaxDay;
        obj.bookBorrowMaxResumeCount = bookBorrowMaxResumeCount;
        obj.bookBorrowResumeDay = bookBorrowResumeDay;
        obj.bookBorrowSameTime = bookBorrowSameTime;
        obj.bookBorrowResumeCount = bookBorrowResumeCount;
        obj.popularBooks = popularBooks;
        obj.surpriseMe = surpriseMe;
        obj.recentlyReturned = recentlyReturned;

        let requestQuery = {} as UpdateBookBorrowSettingUsingPOSTRequest;
        requestQuery.bookBorrowSettingDto = obj;

        new SystemSettingControllerApi(genApiConfig())
            .updateBookBorrowSettingUsingPOST(requestQuery)
            .then((res: SystemSetting) => {
                setBookBorrowMaxDay(res.bookBorrowMaxDay!);
                setBookBorrowMaxResumeCount(res.bookBorrowMaxResumeCount!);
                setBookBorrowResumeDay(res.bookBorrowResumeDay!);
                setBookBorrowSameTime(res.bookBorrowSameTime!);
                setBookBorrowResumeCount(res.bookBorrowResumeCount!);
                setPopularBooks(res.popularBooks!);
                setSurpriseMe(res.surpriseMe!);
                setRecentlyReturned(res.recentlyReturned!);
                alert("更新設定成功");
            })
            .catch((err) => alert(`更新設定失敗 : ${err}`));
    };

    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(PATH.ADMIN)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.ContentLayout}>
                <div className={style.title}>
                    <h1>
                        <Trans>BorrowRenewSetting</Trans>
                    </h1>
                </div>

                <div className={style.ContentItem_container}>
                    <div>
                        <h4>
                            <Trans>BookBrorrowPeriod</Trans>
                        </h4>

                        <Input type="number" value={bookBorrowMaxDay} callBack={(value) => setBookBorrowMaxDay(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>BrorrowQuantity</Trans>
                        </h4>
                        <Input type="number" value={bookBorrowSameTime} callBack={(value) => setBookBorrowSameTime(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>BookRenewalPeriod</Trans>
                        </h4>
                        <Input type="number" value={bookBorrowResumeDay} callBack={(value) => setBookBorrowResumeDay(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>RenewableQuantity</Trans>
                        </h4>
                        <Input
                            type="number"
                            value={bookBorrowMaxResumeCount}
                            callBack={(value) => setBookBorrowMaxResumeCount(value)}
                            styleType={STYLETYPE_MAIN}
                            min={1}
                            max={100}
                        />
                    </div>
                    <div>
                        <h4>
                            <Trans>ResumeBookCount</Trans>
                        </h4>
                        <Input type="number" value={bookBorrowResumeCount} callBack={(value) => setBookBorrowResumeCount(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>PopularBorrow</Trans>
                            <Trans>displayQuantity</Trans>
                        </h4>
                        <Input type="number" value={popularBooks} callBack={(value) => setPopularBooks(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>RandomSurprise</Trans>
                            <Trans>displayQuantity</Trans>
                        </h4>
                        <Input type="number" value={surpriseMe} callBack={(value) => setSurpriseMe(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                    <div>
                        <h4>
                            <Trans>ReturnBook</Trans>
                            <Trans>displayQuantity</Trans>
                        </h4>
                        <Input type="number" value={recentlyReturned} callBack={(value) => setRecentlyReturned(value)} styleType={STYLETYPE_MAIN} min={1} max={100} />
                    </div>
                </div>

                <div className={style.btn}>
                    {/* <Button styleType={STYLETYPE_THIRD} callBack={() => setSetting()}>
            <Trans>Cancel</Trans>
          </Button> */}
                    <Button styleType={STYLETYPE_MAIN} callBack={() => handleSubmit()}>
                        <Trans>Save</Trans>
                    </Button>
                </div>
            </div>
            <DialogBox styleType={STYLETYPE_MAIN} showDialog={showDeleteDialog} title={"刪除書本資料"}></DialogBox>
        </>
    );
}
