/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSetting
 */
export interface PageSetting {
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    chtName?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    cnName?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PageSetting
     */
    enable?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PageSetting
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    engName?: string;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    iconFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof PageSetting
     */
    pageSettingId?: number;
    /**
     * 
     * @type {Date}
     * @memberof PageSetting
     */
    startTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof PageSetting
     */
    url?: string;
}

export function PageSettingFromJSON(json: any): PageSetting {
    return PageSettingFromJSONTyped(json, false);
}

export function PageSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chtName': !exists(json, 'chtName') ? undefined : json['chtName'],
        'cnName': !exists(json, 'cnName') ? undefined : json['cnName'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'enable': !exists(json, 'enable') ? undefined : json['enable'],
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'engName': !exists(json, 'engName') ? undefined : json['engName'],
        'iconFileName': !exists(json, 'iconFileName') ? undefined : json['iconFileName'],
        'pageSettingId': !exists(json, 'pageSettingId') ? undefined : json['pageSettingId'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function PageSettingToJSON(value?: PageSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chtName': value.chtName,
        'cnName': value.cnName,
        'code': value.code,
        'content': value.content,
        'enable': value.enable,
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'engName': value.engName,
        'iconFileName': value.iconFileName,
        'pageSettingId': value.pageSettingId,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'url': value.url,
    };
}

