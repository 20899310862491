import { useEffect, useState } from "react";
import { DashboardControllerApi } from "../../fetcher";
import { genApiConfig } from "../../utils/fetch-caller";
import style from "./Marquee.module.css";
export default function Marquee() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    new DashboardControllerApi(genApiConfig())
      .getDashboardMessageUsingGET()
      .then((res) => {
        setMessage(res);
      });
    // setMessage("為改善服務，知書閱聽圖書館平台將於2023/3/1, 10:00AM - 10:30AM進行系統維護。期間服務將會受影響，敬請注意。Nowbook Digital Library will be under system maintenance on I March 2023 from s AM 10:30 AM. All services will not be available during the system maintenance. Sorry for any inconvenience caused. ")
  }, []);

  return (
    // <div>
    //     為改善服務，知書閱聽圖書館平台
    // </div>
    message && message.length ? (
      <div className={style.logoWall}>
        {/* //本尊！ */}
        <div className={style.logoWrapper + " " + style.first}>
          <div className={style.logo}>
            <p style={{ whiteSpace: "nowrap" }}>{message}</p>
          </div>
          {/* <div className={style.logo}><p style={{whiteSpace: "nowrap"}}></p></div> */}
        </div>
      </div>
    ) : (
      <></>
    )
  );
}
