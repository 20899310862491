import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalRecord from "../../components/CardView/Bookmarked/PersonalRecord";
import BookMarked from "../../components/CardView/Bookmarked/BookMarkComponents";
import BookShelf from "../../components/CardView/BookShelf/BookShelf";
import Reservation from "../../components/CardView/Reservation/Reservation";
import UserInformation from "../../components/CardView/User/UserInformation";
import {
  BorrowControllerApi,
  GetLogByMemberUsingGETRequest,
  ListAllMyReserveBookUsingPOSTRequest,
  MemberActionLogControllerApi,
} from "../../fetcher";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { handleUserInfo } from "../../redux/actions/userInfo";
import { DIALOG_DELETE, LOGIN_PATH } from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import useWindowSize from "../../utils/useWindowSize";
import { Trans, useTranslation } from "react-i18next";
import DialogBox from "../../components/DialogBox/DialogBox";
import * as PATH from "../../utils/Contants";
import Button from "../../components/Button/Button";
import style from "./StudentView.module.css";

function StudentView(props: any) {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const [actionLog, setActionLog] = useState<any>([]);
  const [reserveBookList, setReserveBookList] = useState([]);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dialogMsg, setDialogMsg] = useState("");

  const updateActionLog = () => {
    new MemberActionLogControllerApi(genApiConfig())
      .getLogByMemberUsingGET({
        memberId: props.userInfo.userInfo?.member.memberId,
        size: 5,
      } as GetLogByMemberUsingGETRequest)
      .then((data) => {
        setActionLog(data.list);
      });

    new BorrowControllerApi(genApiConfig())
      .listAllMyReserveBookUsingPOST({
        memberId: props.userInfo.userInfo?.member.memberId,
      } as ListAllMyReserveBookUsingPOSTRequest)
      .then((data: any) => {
        //console.log("dataaaaaaaa", data);
        props.myReserveBookList(data);
      });
  };

  // useEffect(() => {
  //   console.log("StudentView", props);
  // }, []);

  const handleCallback = (obj: any) => {
    // console.log("obj", obj);
    if (obj !== null && obj !== undefined) {
      switch (obj.action) {
        case DIALOG_DELETE:
          setShowConfirmDialog(true);
          setDialogMsg(t("RemoveBookmark"));
          //updateActionLog();
          break;
      }
    }
  };

  useEffect(() => {
    // if(Object.keys(props.userInfo).length > 0){
    //   console.log('Student View', Object.keys(props.userInfo).length)
    //   navigate(LOGIN_PATH)
    // }
    //console.log("Student View", props);
  }, [props]);

  return (
    <div>
      <>
        <UserInformation
          // showRecord={false}
          callBack={() => updateActionLog()}
          actionLog={actionLog}
          isMobile={width <= 768 ? true : false}
        ></UserInformation>

        <BookShelf
          callBack={(value) => updateActionLog()}
          navigate={navigate}
        />

        <Reservation
          navigate={navigate}
          item={props.reserveBookList}
          callBack={(value) => updateActionLog()}
        ></Reservation>
        <BookMarked
          callBack={(value) => handleCallback(value)}
          navigate={navigate}
          item={props.bookMarkList}
        ></BookMarked>

        <PersonalRecord
          callBack={() => "1"}
          actionLog={actionLog}
          //item={props.bookMarkList}
        ></PersonalRecord>
      </>
      <DialogBox
        styleType={PATH.STYLETYPE_SECOND}
        showDialog={showConfirmDialog}
        p1={dialogMsg}
        title={<Trans>SystemMessage</Trans>}
        scroll={false}
      >
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"submitButton"}
            callBack={() => setShowConfirmDialog(false)}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      </DialogBox>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  // console.log("state", state);
  return {
    borrowBookList: state.myBorrowBookList,
    reserveBookList: state.myReserveBookList,
    bookMarkList: state.myBookMarkList,
    userInfo: state.userInfo,
  };
};
const actionCreator = {
  myBorrowBookList,
  myReserveBookList,
  handleUserInfo,
};
export default connect(mapStateToProps, actionCreator)(StudentView);
