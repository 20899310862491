/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProductDiscount,
    CartProductDiscountFromJSON,
    CartProductDiscountFromJSONTyped,
    CartProductDiscountToJSON,
} from './CartProductDiscount';
import {
    Coupon,
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import {
    DiscountProduct,
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import {
    MarketingEventDiscount,
    MarketingEventDiscountFromJSON,
    MarketingEventDiscountFromJSONTyped,
    MarketingEventDiscountToJSON,
} from './MarketingEventDiscount';
import {
    MemberLevelDiscount,
    MemberLevelDiscountFromJSON,
    MemberLevelDiscountFromJSONTyped,
    MemberLevelDiscountToJSON,
} from './MemberLevelDiscount';

/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    actionValue?: number;
    /**
     * 
     * @type {Array<CartProductDiscount>}
     * @memberof Discount
     */
    cartProductDiscountsByCartProductId?: Array<CartProductDiscount>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    condition?: string;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof Discount
     */
    couponsByDiscountId?: Array<Coupon>;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountId?: number;
    /**
     * 
     * @type {Array<DiscountProduct>}
     * @memberof Discount
     */
    discountProductsByDiscountId?: Array<DiscountProduct>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountType?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {Array<MarketingEventDiscount>}
     * @memberof Discount
     */
    marketingEventDiscountsByDiscountId?: Array<MarketingEventDiscount>;
    /**
     * 
     * @type {Array<MemberLevelDiscount>}
     * @memberof Discount
     */
    memberLevelDiscountsByDiscountId?: Array<MemberLevelDiscount>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    target?: string;
}

export function DiscountFromJSON(json: any): Discount {
    return DiscountFromJSONTyped(json, false);
}

export function DiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionValue': !exists(json, 'actionValue') ? undefined : json['actionValue'],
        'cartProductDiscountsByCartProductId': !exists(json, 'cartProductDiscountsByCartProductId') ? undefined : ((json['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountFromJSON)),
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'couponsByDiscountId': !exists(json, 'couponsByDiscountId') ? undefined : ((json['couponsByDiscountId'] as Array<any>).map(CouponFromJSON)),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'discountProductsByDiscountId': !exists(json, 'discountProductsByDiscountId') ? undefined : ((json['discountProductsByDiscountId'] as Array<any>).map(DiscountProductFromJSON)),
        'discountTitle': !exists(json, 'discountTitle') ? undefined : json['discountTitle'],
        'discountType': !exists(json, 'discountType') ? undefined : json['discountType'],
        'limitToUse': !exists(json, 'limitToUse') ? undefined : json['limitToUse'],
        'limitToUseRest': !exists(json, 'limitToUseRest') ? undefined : json['limitToUseRest'],
        'marketingEventDiscountsByDiscountId': !exists(json, 'marketingEventDiscountsByDiscountId') ? undefined : ((json['marketingEventDiscountsByDiscountId'] as Array<any>).map(MarketingEventDiscountFromJSON)),
        'memberLevelDiscountsByDiscountId': !exists(json, 'memberLevelDiscountsByDiscountId') ? undefined : ((json['memberLevelDiscountsByDiscountId'] as Array<any>).map(MemberLevelDiscountFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function DiscountToJSON(value?: Discount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionType': value.actionType,
        'actionValue': value.actionValue,
        'cartProductDiscountsByCartProductId': value.cartProductDiscountsByCartProductId === undefined ? undefined : ((value.cartProductDiscountsByCartProductId as Array<any>).map(CartProductDiscountToJSON)),
        'condition': value.condition,
        'couponsByDiscountId': value.couponsByDiscountId === undefined ? undefined : ((value.couponsByDiscountId as Array<any>).map(CouponToJSON)),
        'discountId': value.discountId,
        'discountProductsByDiscountId': value.discountProductsByDiscountId === undefined ? undefined : ((value.discountProductsByDiscountId as Array<any>).map(DiscountProductToJSON)),
        'discountTitle': value.discountTitle,
        'discountType': value.discountType,
        'limitToUse': value.limitToUse,
        'limitToUseRest': value.limitToUseRest,
        'marketingEventDiscountsByDiscountId': value.marketingEventDiscountsByDiscountId === undefined ? undefined : ((value.marketingEventDiscountsByDiscountId as Array<any>).map(MarketingEventDiscountToJSON)),
        'memberLevelDiscountsByDiscountId': value.memberLevelDiscountsByDiscountId === undefined ? undefined : ((value.memberLevelDiscountsByDiscountId as Array<any>).map(MemberLevelDiscountToJSON)),
        'status': value.status,
        'target': value.target,
    };
}

