import _ from "underscore";
import { getArgsQuery } from "../../GlobalFunction";

class RecordLocation {
  static recordCfi(bookKey, cfi, percentage) {
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    obj[bookKey] = { cfi: cfi, percentage: percentage };
    localStorage.setItem("recordLocation", JSON.stringify(obj));
  }

  static getCfi(bookKey) {
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    return obj[bookKey] || {};
  }
  static recordHtmlLocation(bookKey, text, chapterTitle, count, percentage) {
    if (
      (!text || !chapterTitle || !count || !percentage) &&
      document.location.href.indexOf("/cb") === -1 //漫画的情况，cbr,cbt,cbz
    )
      return;
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    obj[bookKey] = { text, chapterTitle, count, percentage };
    localStorage.setItem("recordLocation", JSON.stringify(obj));
  }

  static getHtmlLocation(bookKey) {
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    return obj[bookKey] || {};
  }
  static getPDFLocation(fingerprint) {
    let json = localStorage.getItem("pdfjs.history");
    let arr = JSON.parse(json || "{}").files || [];
    return arr[_.findLastIndex(arr, { fingerprint })] || {};
  }
  static recordPDFLocation(fingerprint, obj) {
    let json = localStorage.getItem("pdfjs.history");
    let _obj = JSON.parse(json || "{}");
    _obj.files[_.findLastIndex(_obj.files, { fingerprint })] = obj;
    localStorage.setItem("pdfjs.history", JSON.stringify(_obj));
  }
  static getAllCfi() {
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    return obj;
  }
  static clear(bookKey) {
    let json = localStorage.getItem("recordLocation");
    let obj = JSON.parse(json || "{}");
    delete obj[bookKey];
    localStorage.setItem("recordLocation", JSON.stringify(obj));
  }
}

export default RecordLocation;
