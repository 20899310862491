import PropTypes from "prop-types";
import { Component } from "react";
import editIcon from "../../../assets/images/btn/edit.svg";
import deleteIcon from "../../../assets/images/btn/trash.svg";
// import editIcon from "../../../../assets/images/btn/edit.svg";
// import deleteIcon from "../../../../assets/images/btn/trash.svg";
import { IUserDto } from "../../../layout/Admin/Users/UsersView";
import {
  CHECKBOX,
  CODE_USER_DELETE,
  CODE_USER_UPDATE,
  DELETE,
  EDIT,
  STYLETYPE_DEFAULT,
  STYLETYPE_IMG,
} from "../../../utils/Contants";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import style from "./UserTableView.module.css";

interface IProps {
  value?: string;
  content: IUserDto;
  styleType?: string;
  showType: "CARD" | "LIST";
  callBack: (value: any, action: string) => void;
  canEdit?: boolean;
  canDelete?: boolean;
  checked?: boolean;
}

export default class UserTableView extends Component<IProps> {
  static propTypes = {
    content: PropTypes.object.isRequired,
    callBack: PropTypes.func,
  };

  handleCheckedCallBack = (value: any) => {
    this.props?.callBack(this.props.content, CHECKBOX);
  };

  handleDeleteCallBack = (value: any) => {
    this.props?.callBack(this.props.content, DELETE);
  };

  handleEditCallBack = (value: any) => {
    this.props.callBack(this.props.content, EDIT);
  };

  render() {
    const { content, showType } = this.props;
    console.log("content", content);
    return (
      <>
        {showType === "CARD" ? (
          <>
            <div className={style.tableItemCard}>
              <div className={style.tableItemCardContainer}>
                <div className={style.tableListItemGridView}>
                  <div className={style.GridViewCol1}>{content?.nameEng}</div>
                  <div className={style.GridViewCol2}>{content?.nameChi}</div>
                  <div className={style.GridViewCol3}>{content?.className}</div>
                  <div className={style.GridViewCol4}>
                    {content?.classNumber}
                  </div>
                  <div className={style.GridViewCol2}>
                    <Input
                      styleType={STYLETYPE_DEFAULT}
                      type="checkbox"
                      checked={content.isChecked}
                      callBack={(value) => this.handleCheckedCallBack(value)}
                    />
                  </div>
                  <div className={style.GridViewCol3}>
                    {/* {`${content.organizations.fullName}-${content.organizations.role.name}`} */}
                    fullName
                    <br />
                    {/* {content.description} */}description
                  </div>
                  <div className={style.GridViewCol4}></div>
                  <div className={style.GridViewCol5}></div>

                  <div className={style.GridViewCol6}>
                    <Button
                      styleType={STYLETYPE_IMG}
                      code={CODE_USER_UPDATE}
                      callBack={(value) => this.handleEditCallBack(value)}
                    >
                      <img src={editIcon} />
                    </Button>
                    <Button
                      styleType={STYLETYPE_IMG}
                      code={CODE_USER_DELETE}
                      callBack={(value) => this.handleDeleteCallBack(value)}
                    >
                      <img src={deleteIcon} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : showType === "LIST" ? (
          <>
            <div className={style.tableItemList}>
              <img />
              <div className={style.tableItemListContainer}>
                <div className={style.tableListItemGridView}>
                  <div className={style.ListViewCol1}>
                    <Input
                      styleType={STYLETYPE_DEFAULT}
                      type="checkbox"
                      checked={content.isChecked}
                      callBack={(value) => this.handleCheckedCallBack(value)}
                    />
                  </div>

                  <div className={style.ListViewCol2}>
                    <p>{content?.nameEng}</p>
                  </div>

                  <div className={style.ListViewCol2}>
                    <p>{content?.nameChi}</p>
                  </div>

                  <div className={style.ListViewCol3}>
                    <p>{content?.className}</p>
                  </div>

                  <div className={style.ListViewCol4}>
                    <p>{content?.classNumber}</p>
                  </div>

                  <div className={style.ListViewCol5}>
                    <Button
                      styleType={STYLETYPE_IMG}
                      code={CODE_USER_UPDATE}
                      callBack={(value) => this.handleEditCallBack(value)}
                    >
                      <img src={editIcon} />
                    </Button>
                  </div>

                  <div className={style.ListViewCol6}>
                    <Button
                      styleType={STYLETYPE_IMG}
                      code={CODE_USER_DELETE}
                      callBack={(value) => this.handleDeleteCallBack(value)}
                    >
                      <img src={deleteIcon} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
