/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookBorrowSettingDto
 */
export interface BookBorrowSettingDto {
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    bookBorrowMaxDay?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    bookBorrowMaxResumeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    bookBorrowResumeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    bookBorrowResumeDay?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    bookBorrowSameTime?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    onlineReadSametime?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    popularBooks?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    recentlyReturned?: number;
    /**
     * 
     * @type {number}
     * @memberof BookBorrowSettingDto
     */
    surpriseMe?: number;
}

export function BookBorrowSettingDtoFromJSON(json: any): BookBorrowSettingDto {
    return BookBorrowSettingDtoFromJSONTyped(json, false);
}

export function BookBorrowSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookBorrowSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookBorrowMaxDay': !exists(json, 'bookBorrowMaxDay') ? undefined : json['bookBorrowMaxDay'],
        'bookBorrowMaxResumeCount': !exists(json, 'bookBorrowMaxResumeCount') ? undefined : json['bookBorrowMaxResumeCount'],
        'bookBorrowResumeCount': !exists(json, 'bookBorrowResumeCount') ? undefined : json['bookBorrowResumeCount'],
        'bookBorrowResumeDay': !exists(json, 'bookBorrowResumeDay') ? undefined : json['bookBorrowResumeDay'],
        'bookBorrowSameTime': !exists(json, 'bookBorrowSameTime') ? undefined : json['bookBorrowSameTime'],
        'onlineReadSametime': !exists(json, 'onlineReadSametime') ? undefined : json['onlineReadSametime'],
        'popularBooks': !exists(json, 'popularBooks') ? undefined : json['popularBooks'],
        'recentlyReturned': !exists(json, 'recentlyReturned') ? undefined : json['recentlyReturned'],
        'surpriseMe': !exists(json, 'surpriseMe') ? undefined : json['surpriseMe'],
    };
}

export function BookBorrowSettingDtoToJSON(value?: BookBorrowSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookBorrowMaxDay': value.bookBorrowMaxDay,
        'bookBorrowMaxResumeCount': value.bookBorrowMaxResumeCount,
        'bookBorrowResumeCount': value.bookBorrowResumeCount,
        'bookBorrowResumeDay': value.bookBorrowResumeDay,
        'bookBorrowSameTime': value.bookBorrowSameTime,
        'onlineReadSametime': value.onlineReadSametime,
        'popularBooks': value.popularBooks,
        'recentlyReturned': value.recentlyReturned,
        'surpriseMe': value.surpriseMe,
    };
}

