/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MarketingEventBannerDto,
    MarketingEventBannerDtoFromJSON,
    MarketingEventBannerDtoToJSON,
} from '../models';

export interface AddUsingPOSTRequest {
    content: string;
    mobileContent: string;
    position: number;
    name?: string;
    marketingEventId?: number;
}

export interface DeleteByIdUsingPOSTRequest {
    bannerId: number;
    name?: string;
}

export interface GetAllUsingGETRequest {
    name?: string;
}

export interface GetBannerByEventIdUsingGETRequest {
    marketingEventId: number;
    name?: string;
}

export interface GetByIdUsingGETRequest {
    bannerId: number;
    name?: string;
}

export interface UpdateUsingPOSTRequest {
    bannerId: number;
    content: string;
    mobileContent: string;
    position: number;
    name?: string;
    marketingEventId?: number;
}

/**
 * 
 */
export class MarketingEventBannerControllerApi extends runtime.BaseAPI {

    /**
     * add
     */
    async addUsingPOSTRaw(requestParameters: AddUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling addUsingPOST.');
        }

        if (requestParameters.mobileContent === null || requestParameters.mobileContent === undefined) {
            throw new runtime.RequiredError('mobileContent','Required parameter requestParameters.mobileContent was null or undefined when calling addUsingPOST.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling addUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.marketingEventId !== undefined) {
            queryParameters['marketingEventId'] = requestParameters.marketingEventId;
        }

        if (requestParameters.content !== undefined) {
            queryParameters['content'] = requestParameters.content;
        }

        if (requestParameters.mobileContent !== undefined) {
            queryParameters['mobileContent'] = requestParameters.mobileContent;
        }

        if (requestParameters.position !== undefined) {
            queryParameters['position'] = requestParameters.position;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * add
     */
    async addUsingPOST(requestParameters: AddUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventBannerDto> {
        const response = await this.addUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteById
     */
    async deleteByIdUsingPOSTRaw(requestParameters: DeleteByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
            throw new runtime.RequiredError('bannerId','Required parameter requestParameters.bannerId was null or undefined when calling deleteByIdUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/delete/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters.bannerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteById
     */
    async deleteByIdUsingPOST(requestParameters: DeleteByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteByIdUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * getAll
     */
    async getAllUsingGETRaw(requestParameters: GetAllUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventBannerDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventBannerDtoFromJSON));
    }

    /**
     * getAll
     */
    async getAllUsingGET(requestParameters: GetAllUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventBannerDto>> {
        const response = await this.getAllUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBannerByEventId
     */
    async getBannerByEventIdUsingGETRaw(requestParameters: GetBannerByEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters.marketingEventId === null || requestParameters.marketingEventId === undefined) {
            throw new runtime.RequiredError('marketingEventId','Required parameter requestParameters.marketingEventId was null or undefined when calling getBannerByEventIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/event/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters.marketingEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * getBannerByEventId
     */
    async getBannerByEventIdUsingGET(requestParameters: GetBannerByEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventBannerDto> {
        const response = await this.getBannerByEventIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getById
     */
    async getByIdUsingGETRaw(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
            throw new runtime.RequiredError('bannerId','Required parameter requestParameters.bannerId was null or undefined when calling getByIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters.bannerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * getById
     */
    async getByIdUsingGET(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventBannerDto> {
        const response = await this.getByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async updateUsingPOSTRaw(requestParameters: UpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
            throw new runtime.RequiredError('bannerId','Required parameter requestParameters.bannerId was null or undefined when calling updateUsingPOST.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling updateUsingPOST.');
        }

        if (requestParameters.mobileContent === null || requestParameters.mobileContent === undefined) {
            throw new runtime.RequiredError('mobileContent','Required parameter requestParameters.mobileContent was null or undefined when calling updateUsingPOST.');
        }

        if (requestParameters.position === null || requestParameters.position === undefined) {
            throw new runtime.RequiredError('position','Required parameter requestParameters.position was null or undefined when calling updateUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.bannerId !== undefined) {
            queryParameters['bannerId'] = requestParameters.bannerId;
        }

        if (requestParameters.marketingEventId !== undefined) {
            queryParameters['marketingEventId'] = requestParameters.marketingEventId;
        }

        if (requestParameters.content !== undefined) {
            queryParameters['content'] = requestParameters.content;
        }

        if (requestParameters.mobileContent !== undefined) {
            queryParameters['mobileContent'] = requestParameters.mobileContent;
        }

        if (requestParameters.position !== undefined) {
            queryParameters['position'] = requestParameters.position;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/banner/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * update
     */
    async updateUsingPOST(requestParameters: UpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MarketingEventBannerDto> {
        const response = await this.updateUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
