// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Textarea_commentInput__WvGLD textarea{\n    width:100%;\n    border-radius: 15px;\n    border: 1px solid rgb(230,230,230);\n    background-color:#FAFAFA;\n    height:80px;\n    resize:none;\n    padding: .5rem 0 0 .5rem;\n    /* vertical-align:text-top; */\n}", "",{"version":3,"sources":["webpack://./src/components/Textarea/Textarea.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,kCAAkC;IAClC,wBAAwB;IACxB,WAAW;IACX,WAAW;IACX,wBAAwB;IACxB,6BAA6B;AACjC","sourcesContent":[".commentInput textarea{\n    width:100%;\n    border-radius: 15px;\n    border: 1px solid rgb(230,230,230);\n    background-color:#FAFAFA;\n    height:80px;\n    resize:none;\n    padding: .5rem 0 0 .5rem;\n    /* vertical-align:text-top; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentInput": "Textarea_commentInput__WvGLD"
};
export default ___CSS_LOADER_EXPORT___;
