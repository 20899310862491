import PropTypes from "prop-types";
import React, { Component } from "react";
import {} from "react-router-dom";

import {
  ADD,
  UPDATE,
  DELETE,
  SELECT,
  DIALOG_CANCEL,
  DIALOG_COMPLETE,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
  AVAILABLE,
} from "../../../utils/Contants";
import Button from "../../Button/Button";
import DialogBox from "../../DialogBox/DialogBox";
import DeleteSheet from "../../Sheet/Delete/DeleteSheet";
import style from "./BookLibraryCardView.module.css";
import { Trans, useTranslation } from "react-i18next";
import CategorySearchFilter from "../../../layout/Admin/PlatformManagement/CategorySearchFilter";
import { CategoryControllerApi } from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import TreeMeunCategory from "../../../layout/Admin/PlatformManagement/TreeMeunCategory/TreeMeunCategory";
import Input from "../../Input/Input";
interface IProps {
  content?: any[];
  data?: any;
  value?: string;
  callBack: (value?: any, value2?: any) => void;
  styleType?: string;
  children?: any;
  byCategory?: boolean;
  selectedBook?: []
}

class BookLibraryCardView extends Component<IProps> {
  state = {
    image: null,
    imageURL: "",
    openDialog: false,
    isSelected: true,
    category: [],
    show: false,
    selectedCategory: [],
  };

  componentDidMount() {}
  genApi = () => {
    new CategoryControllerApi(genApiConfig())
      .getAllCategoryUsingGET()
      .then((res: any) => {
        this.setState({
          ...this.state,
          category: res,
        });
      });
  };

  setSelectdObjForCategory = (obj: any, updateObj: any) => {
    // console.log("obj", obj);
    // console.log("updateObj", updateObj);
    if (updateObj !== null && updateObj !== undefined) {
      let findUpdateObj = updateObj.find((newObj: any) => newObj.id === obj.id);
      if (findUpdateObj !== undefined) {
        return {
          ...obj,
          isSelected: true,
          isSearch: true,
        };
      } else {
        return {
          ...obj,
          isSelected: false,
          isSearch: true,
        };
      }
    } else {
      return {
        ...obj,
        isSelected: false,
        isSearch: true,
        isRoot: true,
      };
    }
  };

  handleShowCategoryMeun = (value: any) => {
    if (typeof value === "object") {
      if (value.isChecked) {
        const joined = this.state.selectedCategory.concat(value.categoryId);
        this.setState({ ...this.state, selectedCategory: joined });
      } else {
        const joined = this.state.selectedCategory.filter(
          (x: any) => x !== value.categoryId

        );
        this.setState({ ...this.state, selectedCategory: joined });
      }
    } else if (value === UPDATE) {
      console.log("request", {
        isbn: this.props.data && this.props.data?.bookIsbn,
        requestBody: { isChecked: this.state.selectedCategory },
      });
      this.setState({ ...this.state, show: !this.state.show });
    } else {
      this.setState({ ...this.state, show: !this.state.show });
    }
  };

  handleSelectedBookAddToCategory = (e: any) => {
    let ResquetBody: any = [];
    if (e.isChecked) {
      console.log("xxx");
      ResquetBody.push(e.categoryId);
    } else {
      ResquetBody.filter((x: any) => x != e.categoryId);
    }
    console.log(e);
    console.log("ResquetBody", ResquetBody);
  };

  handleDeleteCallBack = () => {};

  handleEditCallBack = () => {};

  handleMultiSelectOptionCallBack = () => {};

  handlelaunch = () => {};

  handleAddBooklistClick = () => {
    this.setState({ isSelected: false });
  };

  handleRemoveBooklistClick = () => {
    this.setState({ openDialog: false });
  };

  handleCallback = (obj: any) => {
    if (obj !== null && obj !== undefined) {
      switch (obj.action) {
        case DIALOG_CANCEL:
          this.setState({ openDialog: false });
          break;
        case DIALOG_COMPLETE:
          this.setState({ openDialog: false, isSelected: true });
          break;
      }
    }
  };

  render() {
    const { content } = this.props;
    const { openDialog, isSelected } = this.state;
    return (
      <>
        <div className={style.tableItemCard}>
          <div className={style.tableItemCardContainer}>
            <div className={style.tableListItemGridView}>
              <div className={style.GridViewCol1}>
                {/* <input
                  type="checkbox"
                  onChange={(e) =>
                    this.props.callBack({
                      action: SELECT,
                      isChecked: e.target.checked,
                      book: this.props.data,
                    })
                  }
                /> */}
                <Input type="checkbox" styleType={"checkbox"} checked={this.props.selectedBook?.find(ele => ele == this.props.data.productId) ? true : false} callBack={(value) => {
                  console.log("tf:", this.props.selectedBook?.find(ele => ele == this.props.data.productId) ? true : false)
                  console.log("this.props.data:", this.props.data)
                  this.props.callBack({
                    action: SELECT,
                    isChecked: this.props.selectedBook?.find(ele => ele == this.props.data.productId) ? false : true,
                    book: this.props.data,
                  })
                }} />
                <h5>{this.props.data && this.props.data.productNameChi}</h5>
              </div>

              <div className={style.GridViewCol2}>
                <img
                  className={style.img_view}
                  src={
                    this.props.data &&
                    process.env.REACT_APP_BACKEND +
                      "/file/" +
                      this.props.data.coverFileName
                  }
                />
              </div>
              <div className={style.GridViewCol3}>
                ISBN : {this.props.data && this.props.data?.bookIsbn}
              </div>

              <div className={style.GridViewCol4}>
                {this.props.data && this.props.data?.author}
              </div>

              <div className={style.GridViewCol5}>
                <>
                  <Button
                    styleType={"searchButtom"}
                    callBack={() =>
                      this.props.callBack({
                        action: ADD,
                        book: this.props.data,
                      })
                    }
                  >
                    加入書單
                  </Button>
                  {this.props.data.status === 0 ? (
                    <>
                      <Button
                        styleType={"searchButtom"}
                        callBack={() =>
                          this.props.callBack({
                            action: AVAILABLE,
                            book: this.props.data,
                          })
                        }
                      >
                        上架
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        styleType={"searchButtom"}
                        callBack={() =>
                          this.props.callBack({
                            action: AVAILABLE,
                            book: this.props.data,
                          })
                        }
                      >
                        下架
                      </Button>
                    </>
                  )}
                </>

                <>
                  {/* {
                    //!content.isSelected
                    isSelected ? (
                      <Button
                        styleType={STYLETYPE_SECOND}
                        callBack={(value) => this.handleAddBooklistClick()}
                      >
                        <Trans>AddBookList</Trans>
                      </Button>
                    ) : (
                      <Button
                        styleType={STYLETYPE_THIRD}
                        callBack={(value) =>
                          this.setState({ openDialog: true })
                        }
                      >
                        <Trans>RemoveBookList</Trans>
                      </Button>
                    )
                  } */}
                </>

                {/* <Button styleType={STYLETYPE_MAIN} callBack={(value) => this.handlelaunch()}>
                                    <Trans>Launch</Trans>
                                </Button> */}
              </div>
            </div>
          </div>
        </div>
        <DialogBox
          styleType={STYLETYPE_MAIN}
          title={this.props.data && this.props.data.productNameChi}
          showDialog={this.state.show}
          scroll={this.state.category.map((category: any) => (
            <TreeMeunCategory
              multipleSelect={true}
              props={category}
              callBack={(e: any) => this.handleShowCategoryMeun(e)}
            />
          ))}
        >
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => this.handleShowCategoryMeun(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => this.handleShowCategoryMeun(UPDATE)}
            >
              儲存
            </Button>
          </div>
        </DialogBox>
        <DialogBox styleType={STYLETYPE_SECOND} showDialog={openDialog}>
          <DeleteSheet callBack={(obj) => this.handleCallback(obj)} />
        </DialogBox>
      </>
    );
  }
}
export default BookLibraryCardView;
