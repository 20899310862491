import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {Outlet, useNavigate} from "react-router-dom";
import closeButton from "../../assets/svg/close.svg";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import useWindowSize from "../../utils/useWindowSize";
import LowerNavBar from "../NavBar/LowerNavBar";
import TreeMeun from "../TreeMeun/TreeMeun";
import style from "./Layout.module.css";
import DialogBox from "../../components/DialogBox/DialogBox";
import * as PATH from "../../utils/Contants";
import * as Storage from "../../utils/local-storage";

const treeData = [
  {
    id: 1,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
  {
    id: 2,
    category: "分類名稱",
    books: [
      { id: 1, bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
    ],
  },
  {
    id: 3,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
  {
    id: 4,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
  {
    id: 5,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
    ],
  },
  {
    id: 6,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
  {
    id: 7,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
  {
    id: 8,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
      { bookname: "book3" },
    ],
  },
  {
    id: 9,
    category: "分類名稱",
    books: [
      { bookname: "book1" },
      { bookname: "book2" },
      { bookname: "book3" },
    ],
  },
];

export default function Layout() {
  const { width, height } = useWindowSize();
  const { i18n } = useTranslation();
  const [needLoad, setNeedLoad] = useState(false);
  const [isReLogin, setIsReLogin] = useState(localStorage.getItem("relogin") !== undefined && localStorage.getItem("relogin") == "true");
  const navigate = useNavigate();
  const checkLoginedUser = () => {
    if (Storage.instance.userInfo === null) {
      process.env.REACT_APP_CLIENT_ID === "hkpl" ? (window.parent.parent.location = PATH.HKPL_SSO_PATH) : <>{navigate(PATH.LOGIN_PATH)}</>;
    }

    if (Storage.instance.userInfo.isAdmin) {
      navigate(PATH.ADMIN);
    } else {
      navigate(PATH.STUDENT);
    }
  }

  return (
    <>
      <LowerNavBar />

      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        showDialog={isReLogin}
        p1={"Session 已過期，請重新登入"}
        scroll={false}>
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"cancelButton"}
            callBack={() => {
              localStorage.removeItem("relogin")
              setIsReLogin(false)
            }}
          >
            <Trans>Cancel</Trans>
          </Button>

          <Button
            styleType={"submitButton"}
            callBack={() => {
              localStorage.removeItem("relogin")
              setIsReLogin(false)
              checkLoginedUser()
            }}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      </DialogBox>

      <div className={style.mainLayoutContainer}>
        <div className={style.mainContainer}>
          <div className={style.floatingSidebar}>
            <div className={style.floatingSidebarHeader}>
              <div className={style.closeButton}>
                <img src={closeButton} />
                <Button
                  styleType={"upperNavbarButton"}
                  callBack={() => console.log(1)}
                >
                  <Trans>close</Trans>
                </Button>
              </div>
            </div>
            {treeData.map((item, idx) => (
              <TreeMeun props={item} key={idx} />
            ))}
          </div>
          <div
            className={
              width <= 768
                ? style.mainLayoutInnerContainer2
                : style.mainLayoutInnerContainer
            }
          >
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
