/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookCountModel
 */
export interface BookCountModel {
    /**
     * 
     * @type {number}
     * @memberof BookCountModel
     */
    bookCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookCountModel
     */
    categoryId?: number;
}

export function BookCountModelFromJSON(json: any): BookCountModel {
    return BookCountModelFromJSONTyped(json, false);
}

export function BookCountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookCountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookCount': !exists(json, 'bookCount') ? undefined : json['bookCount'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
    };
}

export function BookCountModelToJSON(value?: BookCountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookCount': value.bookCount,
        'categoryId': value.categoryId,
    };
}

