/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';

/**
 * 
 * @export
 * @interface PagingModelMemberDto
 */
export interface PagingModelMemberDto {
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof PagingModelMemberDto
     */
    list?: Array<MemberDto>;
    /**
     * 
     * @type {number}
     * @memberof PagingModelMemberDto
     */
    numPerPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModelMemberDto
     */
    pageno?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingModelMemberDto
     */
    pagenum?: number;
}

export function PagingModelMemberDtoFromJSON(json: any): PagingModelMemberDto {
    return PagingModelMemberDtoFromJSONTyped(json, false);
}

export function PagingModelMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagingModelMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : ((json['list'] as Array<any>).map(MemberDtoFromJSON)),
        'numPerPage': !exists(json, 'numPerPage') ? undefined : json['numPerPage'],
        'pageno': !exists(json, 'pageno') ? undefined : json['pageno'],
        'pagenum': !exists(json, 'pagenum') ? undefined : json['pagenum'],
    };
}

export function PagingModelMemberDtoToJSON(value?: PagingModelMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : ((value.list as Array<any>).map(MemberDtoToJSON)),
        'numPerPage': value.numPerPage,
        'pageno': value.pageno,
        'pagenum': value.pagenum,
    };
}

