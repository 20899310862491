/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discount,
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import {
    MemberShoppingCart,
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import {
    OrderCoupon,
    OrderCouponFromJSON,
    OrderCouponFromJSONTyped,
    OrderCouponToJSON,
} from './OrderCoupon';

/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    couponId?: number;
    /**
     * 
     * @type {Discount}
     * @memberof Coupon
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    discountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    ignore?: boolean;
    /**
     * 
     * @type {Array<OrderCoupon>}
     * @memberof Coupon
     */
    orderCouponsByCouponId?: Array<OrderCoupon>;
    /**
     * 
     * @type {Array<MemberShoppingCart>}
     * @memberof Coupon
     */
    shoppingCartsByCouponId?: Array<MemberShoppingCart>;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    title?: string;
}

export function CouponFromJSON(json: any): Coupon {
    return CouponFromJSONTyped(json, false);
}

export function CouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'couponCode': !exists(json, 'couponCode') ? undefined : json['couponCode'],
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
        'discountByDiscountId': !exists(json, 'discountByDiscountId') ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'ignore': !exists(json, 'ignore') ? undefined : json['ignore'],
        'orderCouponsByCouponId': !exists(json, 'orderCouponsByCouponId') ? undefined : ((json['orderCouponsByCouponId'] as Array<any>).map(OrderCouponFromJSON)),
        'shoppingCartsByCouponId': !exists(json, 'shoppingCartsByCouponId') ? undefined : ((json['shoppingCartsByCouponId'] as Array<any>).map(MemberShoppingCartFromJSON)),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function CouponToJSON(value?: Coupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'couponCode': value.couponCode,
        'couponId': value.couponId,
        'discountByDiscountId': DiscountToJSON(value.discountByDiscountId),
        'discountId': value.discountId,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'ignore': value.ignore,
        'orderCouponsByCouponId': value.orderCouponsByCouponId === undefined ? undefined : ((value.orderCouponsByCouponId as Array<any>).map(OrderCouponToJSON)),
        'shoppingCartsByCouponId': value.shoppingCartsByCouponId === undefined ? undefined : ((value.shoppingCartsByCouponId as Array<any>).map(MemberShoppingCartToJSON)),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
        'title': value.title,
    };
}

