/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoFromJSONTyped,
    BorrowDtoToJSON,
} from './BorrowDto';
import {
    ReserveBorrowDto,
    ReserveBorrowDtoFromJSON,
    ReserveBorrowDtoFromJSONTyped,
    ReserveBorrowDtoToJSON,
} from './ReserveBorrowDto';

/**
 * 
 * @export
 * @interface MyBookshelfDto
 */
export interface MyBookshelfDto {
    /**
     * 
     * @type {Array<BorrowDto>}
     * @memberof MyBookshelfDto
     */
    borrow?: Array<BorrowDto>;
    /**
     * 
     * @type {Array<ReserveBorrowDto>}
     * @memberof MyBookshelfDto
     */
    reserveBorrows?: Array<ReserveBorrowDto>;
}

export function MyBookshelfDtoFromJSON(json: any): MyBookshelfDto {
    return MyBookshelfDtoFromJSONTyped(json, false);
}

export function MyBookshelfDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyBookshelfDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrow': !exists(json, 'borrow') ? undefined : ((json['borrow'] as Array<any>).map(BorrowDtoFromJSON)),
        'reserveBorrows': !exists(json, 'reserveBorrows') ? undefined : ((json['reserveBorrows'] as Array<any>).map(ReserveBorrowDtoFromJSON)),
    };
}

export function MyBookshelfDtoToJSON(value?: MyBookshelfDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrow': value.borrow === undefined ? undefined : ((value.borrow as Array<any>).map(BorrowDtoToJSON)),
        'reserveBorrows': value.reserveBorrows === undefined ? undefined : ((value.reserveBorrows as Array<any>).map(ReserveBorrowDtoToJSON)),
    };
}

