/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SfStation
 */
export interface SfStation {
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    sfStationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    sfStationName?: string;
}

export function SfStationFromJSON(json: any): SfStation {
    return SfStationFromJSONTyped(json, false);
}

export function SfStationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SfStation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'area': !exists(json, 'area') ? undefined : json['area'],
        'sfStationCode': !exists(json, 'sfStationCode') ? undefined : json['sfStationCode'],
        'sfStationName': !exists(json, 'sfStationName') ? undefined : json['sfStationName'],
    };
}

export function SfStationToJSON(value?: SfStation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'area': value.area,
        'sfStationCode': value.sfStationCode,
        'sfStationName': value.sfStationName,
    };
}

