/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CartProduct,
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import {
    Coupon,
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import {
    ShoppingCartCoupon,
    ShoppingCartCouponFromJSON,
    ShoppingCartCouponFromJSONTyped,
    ShoppingCartCouponToJSON,
} from './ShoppingCartCoupon';

/**
 * 
 * @export
 * @interface MemberShoppingCart
 */
export interface MemberShoppingCart {
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof MemberShoppingCart
     */
    cartProductByShoppingCartId?: Array<CartProduct>;
    /**
     * 
     * @type {Coupon}
     * @memberof MemberShoppingCart
     */
    couponByCouponId?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    couponId?: number;
    /**
     * 
     * @type {Member}
     * @memberof MemberShoppingCart
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberShoppingCart
     */
    promotionCode?: string;
    /**
     * 
     * @type {Array<ShoppingCartCoupon>}
     * @memberof MemberShoppingCart
     */
    shoppingCartCouponsByShoppingCartId?: Array<ShoppingCartCoupon>;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    shoppingCartId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    total?: number;
}

export function MemberShoppingCartFromJSON(json: any): MemberShoppingCart {
    return MemberShoppingCartFromJSONTyped(json, false);
}

export function MemberShoppingCartFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberShoppingCart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartProductByShoppingCartId': !exists(json, 'cartProductByShoppingCartId') ? undefined : ((json['cartProductByShoppingCartId'] as Array<any>).map(CartProductFromJSON)),
        'couponByCouponId': !exists(json, 'couponByCouponId') ? undefined : CouponFromJSON(json['couponByCouponId']),
        'couponId': !exists(json, 'couponId') ? undefined : json['couponId'],
        'memberByMemberId': !exists(json, 'memberByMemberId') ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'promotionCode': !exists(json, 'promotionCode') ? undefined : json['promotionCode'],
        'shoppingCartCouponsByShoppingCartId': !exists(json, 'shoppingCartCouponsByShoppingCartId') ? undefined : ((json['shoppingCartCouponsByShoppingCartId'] as Array<any>).map(ShoppingCartCouponFromJSON)),
        'shoppingCartId': !exists(json, 'shoppingCartId') ? undefined : json['shoppingCartId'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function MemberShoppingCartToJSON(value?: MemberShoppingCart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cartProductByShoppingCartId': value.cartProductByShoppingCartId === undefined ? undefined : ((value.cartProductByShoppingCartId as Array<any>).map(CartProductToJSON)),
        'couponByCouponId': CouponToJSON(value.couponByCouponId),
        'couponId': value.couponId,
        'memberByMemberId': MemberToJSON(value.memberByMemberId),
        'memberId': value.memberId,
        'promotionCode': value.promotionCode,
        'shoppingCartCouponsByShoppingCartId': value.shoppingCartCouponsByShoppingCartId === undefined ? undefined : ((value.shoppingCartCouponsByShoppingCartId as Array<any>).map(ShoppingCartCouponToJSON)),
        'shoppingCartId': value.shoppingCartId,
        'total': value.total,
    };
}

