/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProductCommentPayloadModel,
    ProductCommentPayloadModelFromJSON,
    ProductCommentPayloadModelToJSON,
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models';

export interface GetProductCommentByProductIdUsingGETRequest {
    productId: number;
    productCommentPage?: number;
    productCommentSize?: number;
}

export interface InsertProductCommentUsingPOSTRequest {
    productId: number;
    memberId?: number;
    adminMemberId?: number;
    productCommentPayloadModel?: ProductCommentPayloadModel;
}

/**
 * 
 */
export class ProductCommentControllerApi extends runtime.BaseAPI {

    /**
     * getProductCommentByProductId
     */
    async getProductCommentByProductIdUsingGETRaw(requestParameters: GetProductCommentByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductCommentByProductIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.productCommentPage !== undefined) {
            queryParameters['productCommentPage'] = requestParameters.productCommentPage;
        }

        if (requestParameters.productCommentSize !== undefined) {
            queryParameters['productCommentSize'] = requestParameters.productCommentSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/product-comment/get/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getProductCommentByProductId
     */
    async getProductCommentByProductIdUsingGET(requestParameters: GetProductCommentByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getProductCommentByProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * insertProductComment
     */
    async insertProductCommentUsingPOSTRaw(requestParameters: InsertProductCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling insertProductCommentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.adminMemberId !== undefined) {
            queryParameters['adminMemberId'] = requestParameters.adminMemberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/product-comment/insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductCommentPayloadModelToJSON(requestParameters.productCommentPayloadModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * insertProductComment
     */
    async insertProductCommentUsingPOST(requestParameters: InsertProductCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.insertProductCommentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
