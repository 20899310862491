import { useEffect } from "react";
import { Link } from "react-router-dom";
import BookView from "../../components/BookView/BookView";
import { dateToString } from "../../utils/GlobalFunction";
import style from "./ReturnBook.module.css";
import { Trans, useTranslation } from "react-i18next";

export default function RecommendBook({ props, idx }: any) {
  const { i18n } = useTranslation();
  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });
  return (
    <>
      <div className={style.treeMenu}>
        <Link to={"/product/" + props.productId.toString()}>
          <>
            {idx <= 0 ? (
              <img
                className={style.bookCover}
                src={
                  process.env.REACT_APP_BACKEND ==
                  "https://suepsso.octopus-tech.com"
                    ? `${process.env.REACT_APP_BACKEND}/file/${props.coverFileName}`
                    : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${props.coverFileName}?x-oss-process=image/resize,h_200`
                }
                height={"160px"}
              />
            ) : (
              <></>
            )}

            <div className={style.treeHead}>
              <div style={{ display: "flex" }}>
                <div className={style.bookIndex}>{idx + 1}</div>
                <div className={style.bookInfo}>
                  <h5 className={style.bookName}>
                    {i18n.language === "cn"
                      ? converter(props.productNameChi)
                      : props.productNameChi}
                  </h5>
                  <p style={{ fontSize: "0.8em" }}>
                    {i18n.language === "cn"
                      ? converter(props.author)
                      : props.author}
                  </p>
                </div>
              </div>
              {/* <span className={style.date}>
                {dateToString(props.returnDate, "DD/MM/yyyy")}
              </span> */}
            </div>
          </>
        </Link>
      </div>
    </>
  );
}
