/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminMember,
    AdminMemberFromJSON,
    AdminMemberFromJSONTyped,
    AdminMemberToJSON,
} from './AdminMember';
import {
    Member,
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface SsoUserResponse
 */
export interface SsoUserResponse {
    /**
     * 
     * @type {AdminMember}
     * @memberof SsoUserResponse
     */
    adminMember?: AdminMember;
    /**
     * 
     * @type {Member}
     * @memberof SsoUserResponse
     */
    member?: Member;
    /**
     * 
     * @type {string}
     * @memberof SsoUserResponse
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserResponse
     */
    token?: string;
}

export function SsoUserResponseFromJSON(json: any): SsoUserResponse {
    return SsoUserResponseFromJSONTyped(json, false);
}

export function SsoUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SsoUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminMember': !exists(json, 'adminMember') ? undefined : AdminMemberFromJSON(json['adminMember']),
        'member': !exists(json, 'member') ? undefined : MemberFromJSON(json['member']),
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function SsoUserResponseToJSON(value?: SsoUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminMember': AdminMemberToJSON(value.adminMember),
        'member': MemberToJSON(value.member),
        'refreshToken': value.refreshToken,
        'token': value.token,
    };
}

