/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface GroupProduct
 */
export interface GroupProduct {
    /**
     * 
     * @type {number}
     * @memberof GroupProduct
     */
    groupProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof GroupProduct
     */
    productByGroupProductId?: Product;
    /**
     * 
     * @type {Product}
     * @memberof GroupProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof GroupProduct
     */
    productId?: number;
}

export function GroupProductFromJSON(json: any): GroupProduct {
    return GroupProductFromJSONTyped(json, false);
}

export function GroupProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupProductId': !exists(json, 'groupProductId') ? undefined : json['groupProductId'],
        'productByGroupProductId': !exists(json, 'productByGroupProductId') ? undefined : ProductFromJSON(json['productByGroupProductId']),
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
    };
}

export function GroupProductToJSON(value?: GroupProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupProductId': value.groupProductId,
        'productByGroupProductId': ProductToJSON(value.productByGroupProductId),
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
    };
}

