/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileUploadItemResponseModel
 */
export interface FileUploadItemResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof FileUploadItemResponseModel
     */
    empty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileUploadItemResponseModel
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof FileUploadItemResponseModel
     */
    fileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUploadItemResponseModel
     */
    uploadedFileName?: string;
}

export function FileUploadItemResponseModelFromJSON(json: any): FileUploadItemResponseModel {
    return FileUploadItemResponseModelFromJSONTyped(json, false);
}

export function FileUploadItemResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadItemResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'uploadedFileName': !exists(json, 'uploadedFileName') ? undefined : json['uploadedFileName'],
    };
}

export function FileUploadItemResponseModelToJSON(value?: FileUploadItemResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'empty': value.empty,
        'fileName': value.fileName,
        'fileSize': value.fileSize,
        'uploadedFileName': value.uploadedFileName,
    };
}

