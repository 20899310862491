const initState = {
  auth : {}
};
export function login(
  state = initState,
  action
) {
  switch (action.type) {

    case "HANDLE_LOGIN":
      return {
        ...state,
        auth: action.payload,
      };

    default:
      return state;
  }
}
