
import { useEffect, useState } from "react";
import style from "./WhiteListSetting.module.css";
import { AddIpAddressUsingPOSTRequest, DeleteIpAddressUsingDELETERequest, SuccessResponseModel, WhiteListIP, WhiteListIpControllerApi } from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import trashSvg from "../../../assets/svg/trash.svg"
import BackButton from "../../../components/Button/BackButton/BackButton";
import Button from "../../../components/Button/Button";
import { STYLETYPE_MAIN } from "../../../utils/Contants";

export default function WhiteListSetting() {

    const [ipList, setIpList] = useState<Array<WhiteListIP>>([])
    const [ipPart1, setIpPart1] = useState("")
    const [ipPart2, setIpPart2] = useState("")
    const [ipPart3, setIpPart3] = useState("")
    const [ipPart4, setIpPart4] = useState("")

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        new WhiteListIpControllerApi(genApiConfig()).listAllIpAddressUsingGET()
            .then((res: Array<WhiteListIP>) => {
                setIpList(res)
            })
    }

    const deleteIp = (ip: any) => {
        if (window.confirm("確認刪除以下IP？\n" + ip)) {
            const req: DeleteIpAddressUsingDELETERequest = {}
            req.ipAddress = ip;
            new WhiteListIpControllerApi(genApiConfig()).deleteIpAddressUsingDELETE(req)
                .then((res: SuccessResponseModel) => {
                    if (res.success) {
                        alert("刪除成功！")
                        fetchData()
                    } else {
                        alert("Error Occurs")
                    }
                })
        }
    }

    const addIp = () => {
        if (ipPart1.length > 0 && ipPart2.length > 0 && ipPart3.length > 0 && ipPart4.length > 0 && Number(ipPart1) < 256 && Number(ipPart2) < 256 && Number(ipPart3) < 256 && Number(ipPart4) < 256) {
            const req: AddIpAddressUsingPOSTRequest = {}
            req.ipaddress = ipPart1 + "." + ipPart2 + "." + ipPart3 + "." + ipPart4
            if (window.confirm("確認新增以下IP？\n" + req.ipaddress)) {
                new WhiteListIpControllerApi(genApiConfig()).addIpAddressUsingPOST(req)
                    .then(() => {
                        alert("新增成功！")
                        setIpPart1("")
                        setIpPart2("")
                        setIpPart3("")
                        setIpPart4("")
                        fetchData()
                    })
            }
        } else {
            alert("IP格式不正確")
        }
    }
    return (
        <>
            <div className={style.backbtn}>
                <BackButton />
            </div>
            <div className={style.listContainer}>
                {
                    ipList.map(ipObj =>
                        <div className={style.listItem}>
                            {ipObj.ipAddress}
                            <img src={trashSvg} onClick={() => deleteIp(ipObj.ipAddress)} />
                        </div>
                    )
                }
                <div className={style.listItem}>
                    <div className={style.inputField}>
                        <input maxLength={3} value={ipPart1} onChange={(e) => setIpPart1(e.target.value.replace(/\D/g, ''))} />.
                        <input maxLength={3} value={ipPart2} onChange={(e) => setIpPart2(e.target.value.replace(/\D/g, ''))} />.
                        <input maxLength={3} value={ipPart3} onChange={(e) => setIpPart3(e.target.value.replace(/\D/g, ''))} />.
                        <input maxLength={3} value={ipPart4} onChange={(e) => setIpPart4(e.target.value.replace(/\D/g, ''))} />
                    </div>
                    <div>
                        <Button styleType={STYLETYPE_MAIN} callBack={addIp} >
                            新增
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}