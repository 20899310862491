/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface PointRule
 */
export interface PointRule {
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    appliedMemberLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    appliedProduct?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    cashForOnePoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof PointRule
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    forBirthday?: string;
    /**
     * 
     * @type {MemberLevel}
     * @memberof PointRule
     */
    memberLevelByAppliedMemberLevel?: MemberLevel;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    oneCashToPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    pointRuleId?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    pointToOneCash?: number;
    /**
     * 
     * @type {Product}
     * @memberof PointRule
     */
    productByAppliedProduct?: Product;
    /**
     * 
     * @type {Date}
     * @memberof PointRule
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    status?: string;
}

export function PointRuleFromJSON(json: any): PointRule {
    return PointRuleFromJSONTyped(json, false);
}

export function PointRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appliedMemberLevel': !exists(json, 'appliedMemberLevel') ? undefined : json['appliedMemberLevel'],
        'appliedProduct': !exists(json, 'appliedProduct') ? undefined : json['appliedProduct'],
        'cashForOnePoint': !exists(json, 'cashForOnePoint') ? undefined : json['cashForOnePoint'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'forBirthday': !exists(json, 'forBirthday') ? undefined : json['forBirthday'],
        'memberLevelByAppliedMemberLevel': !exists(json, 'memberLevelByAppliedMemberLevel') ? undefined : MemberLevelFromJSON(json['memberLevelByAppliedMemberLevel']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'oneCashToPoint': !exists(json, 'oneCashToPoint') ? undefined : json['oneCashToPoint'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'pointRuleId': !exists(json, 'pointRuleId') ? undefined : json['pointRuleId'],
        'pointToOneCash': !exists(json, 'pointToOneCash') ? undefined : json['pointToOneCash'],
        'productByAppliedProduct': !exists(json, 'productByAppliedProduct') ? undefined : ProductFromJSON(json['productByAppliedProduct']),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function PointRuleToJSON(value?: PointRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appliedMemberLevel': value.appliedMemberLevel,
        'appliedProduct': value.appliedProduct,
        'cashForOnePoint': value.cashForOnePoint,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'forBirthday': value.forBirthday,
        'memberLevelByAppliedMemberLevel': MemberLevelToJSON(value.memberLevelByAppliedMemberLevel),
        'name': value.name,
        'oneCashToPoint': value.oneCashToPoint,
        'period': value.period,
        'pointRuleId': value.pointRuleId,
        'pointToOneCash': value.pointToOneCash,
        'productByAppliedProduct': ProductToJSON(value.productByAppliedProduct),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'status': value.status,
    };
}

