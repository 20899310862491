import localforage from "localforage";
import { Component } from "react";
import { Trans } from "react-i18next";
import api from "../../../api/api";
import {
    BorrowControllerApi,
    BorrowDto,
    ReportRecordControllerApi,
    ResumeBorrowMissionResponseModel,
    SaveReportRecordUsingPOSTBookTypeEnum,
    SaveReportRecordUsingPOSTReportTypeEnum,
} from "../../../fetcher";

import { STYLETYPE_MAIN, STYLETYPE_THIRD } from "../../../utils/Contants";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { dateToString, getFileExtension } from "../../../utils/GlobalFunction";
import BookUtil from "../../../utils/reader/fileUtils/bookUtil";
import { addEpub } from "../../../utils/reader/fileUtils/epubUtil";
import { getMd5WithBrowser } from "../../../utils/reader/fileUtils/md5Util";
import RecentBooks from "../../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import Button from "../../Button/Button";
import DialogBox from "../../DialogBox/DialogBox";
import style from "./BookShelfItem.module.css";
import { withSocket } from "../../../utils/withSocket";
import * as Storage from "../../../utils/local-storage";
import { ISocketMsg } from "../../../utils/socket/SocketContext";

interface IProps {
    title?: string;
    content?: string;
    value?: string;
    callBack: (value: any, value2?: any) => void;
    styleType?: string;
    children?: any;
    it: any;
    serverMessage?: ISocketMsg;
    setOpenBookUser?: any;
    ref?: Array<string>;
}

class BookShelfItem extends Component<IProps> {
    state = {
        content: {},
        title: "",
        showError: false,
        showresumeBook: false,
        return: this.props.it.returnDate,
        previewBook: false,
        confirmOpenBookDialog: false,
        showConfirmDialog: false,
        dialogMsg: "",
        borrowData: [] as BorrowDto,
        isOpenBook: false,
        showBookIsOpenDialog: false,
    };
    componentWillReceiveProps(nextProps: IProps) {
        if (
            nextProps !== undefined &&
            Object.keys(nextProps).length > 0 &&
            nextProps.serverMessage !== undefined &&
            Object.keys(nextProps.serverMessage).length > 0 &&
            nextProps.serverMessage.bookPage !== undefined &&
            Object.keys(nextProps.serverMessage.bookPage).length > 0
        ) {
            for (let i = 0; i < Object.keys(nextProps.serverMessage.bookPage).length; i++) {
                if (Object.keys(nextProps.serverMessage).includes("ref") && nextProps.serverMessage.ref !== undefined) {
                    if (nextProps.serverMessage?.ref.length > 0) {
                        const findOpenBookSocket = nextProps.serverMessage?.ref.find((it) => it === Object.keys(nextProps.serverMessage?.bookPage!!)[i]);

                        if (findOpenBookSocket !== undefined) {
                            this.setState({ ...this.state, isOpenBook: true });
                        } else {
                            this.setState({ ...this.state, isOpenBook: false });
                        }
                    }
                }
            }
        } else {
            this.setState({ ...this.state, isOpenBook: false });
        }
    }

    handleEditCallBack = () => {};

    handleCallBack = () => {
        //TODO
    };

    handleReturn = () => {
        new BorrowControllerApi(genApiConfig())
            .returnBookUsingPOST({
                memberId: this.props.it.memberId,
                productId: this.props.it.productId,
            })
            .then((data: BorrowDto[]) => {
                if (data) {
                    this.setState({
                        ...this.state,
                        showConfirmDialog: true,
                        dialogMsg: "成功還書",
                        borrowData: data,
                    });
                }
            });
    };

    handleResumeBook = () => {
        new BorrowControllerApi(genApiConfig())
            .resumeBorrowUsingPOST({
                memberId: this.props.it.memberId,
                productId: this.props.it.productId,
            })
            .then((data: ResumeBorrowMissionResponseModel) => {
                if (data.missionSuccess) {
                    this.setState({
                        ...this.state,
                        showConfirmDialog: true,
                        dialogMsg: `成功續借 還書日期至${dateToString(data.borrowDto?.returnDate, "DD/MM/yyyy")}`,
                        return: data.borrowDto?.returnDate,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        showConfirmDialog: true,
                        dialogMsg: data.message,
                    });
                }
            })
            .catch((reason) => {
                console.log("reason", reason);
            });
    };

    openBookClick = (bookObj: any, fileName: string, readAction: string) => {
        //console.log("openBookClick", bookObj, fileName);
        if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
            //props.history.push(BookUtil.getBookUrl(book));
            //props.handleReadingBook(book);
        } else {
            //localStorage.setItem("tempBook", JSON.stringify(book));
            BookUtil.RedirectBook(bookObj, fileName, this.props.it.productId).then(() => {
                if (localStorage.getItem("openBookUrl") !== undefined && localStorage.getItem("openBookUrl") !== null && localStorage.getItem("openBookUrl") !== "") {
                    this.setState({ ...this.state, confirmOpenBookDialog: true });
                }
            });
            this.setState({
                ...this.state,
                previewBook: false,
            });
        }
    };

    handlePreViewBook = () => {
        console.log("this.props", this.props);

        const { isOpenBook } = this.state;

        if (isOpenBook) {
            this.setState({ ...this.state, showBookIsOpenDialog: true });
            return;
        }

        this.setState(
            {
                ...this.state,
                previewBook: true,
            },
            () => {
                if (this.state.previewBook) {
                    let readerConfig = localStorage.getItem("readerConfig");

                    if (readerConfig !== null) {
                        let readerConfigObj = JSON.parse(readerConfig);
                        readerConfigObj.rm = "r";
                        localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
                    }

                    if (this.props.it["ePubProFileName"] !== undefined) {
                        var fileName = this.props.it["ePubProFileName"];

                        if (getFileExtension(fileName) === "mp3") {
                            var audioDataList = JSON.parse(this.props.it["multimediaFileNames"] as unknown as string);
                            var formattedDataList: Array<any> = [];
                            audioDataList
                                .sort((a: any, b: any) => {
                                    return a["Seq"] - b["Seq"];
                                })
                                .map((chapter: any) => {
                                    let chapterData: any = {};
                                    chapterData["title"] = chapter["Title"];
                                    chapterData["audioFilePath"] = chapter["File"];
                                    chapterData["srtFilePath"] = chapter["Subtitle"];
                                    chapterData["covers"] = [{ coverFilePath: chapter["Cover"], appearTime: 0 }];
                                    formattedDataList.push(chapterData);
                                });
                            var formattedData: any = { list: formattedDataList };
                            formattedData["bookname"] = this.props.it && this.props.it.productNameChi;
                            formattedData["author"] = this.props.it && this.props.it.author;
                            formattedData["cover"] = this.props.it && this.props.it.coverFileName;
                            formattedData["bookIsbn"] = this.props.it && this.props.it.bookISBN;
                            localforage.setItem("audio", formattedData);
                            console.log("openBookUrl", `${window.location.href.split("/")[0]}/audioreader/${this.props.it.bookISBN}`);
                            localStorage.setItem(
                                "openBookUrl",
                                `${window.location.href.split("/")[0]}/audioreader/${this.props.it.bookISBN}?bookId=${parseInt(this.props.it.productId!)}`
                            );
                            this.setState({ ...this.state, confirmOpenBookDialog: true, previewBook: false });

                            try {
                                const userInfo = localStorage.getItem("userInfo");
                                const userInfoObj = JSON.parse(userInfo!!);
                                if (userInfoObj !== null) {
                                    if (userInfoObj.member !== undefined) {
                                        new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                                            memberId: userInfoObj.member.memberId,
                                            productId: this.props.it.productId,
                                            reportType: SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline,
                                            bookType: SaveReportRecordUsingPOSTBookTypeEnum.Audio,
                                        });
                                    }
                                }
                            } catch (e) {
                                console.log("e", e);
                            }
                            return;
                        }

                        if (getFileExtension(fileName) === "mp4") {
                            var videoDataList = JSON.parse(this.props.it["multimediaFileNames"] as unknown as string);
                            var formattedDataList: Array<any> = [];
                            videoDataList
                                .sort((a: any, b: any) => {
                                    return a["Seq"] - b["Seq"];
                                })
                                .map((chapter: any) => {
                                    let chapterData: any = {};
                                    chapterData["title"] = chapter["Title"];
                                    chapterData["videoFilePath"] = chapter["File"];
                                    chapterData["srtFilePath"] = chapter["Subtitle"];
                                    chapterData["covers"] = [{ coverFilePath: chapter["Cover"], appearTime: 0 }];
                                    formattedDataList.push(chapterData);
                                });
                            var formattedData: any = { list: formattedDataList };
                            formattedData["bookname"] = this.props.it && this.props.it.productNameChi;
                            formattedData["author"] = this.props.it && this.props.it.author;
                            formattedData["cover"] = this.props.it && this.props.it.coverFileName;
                            formattedData["bookIsbn"] = this.props.it && this.props.it.bookISBN;
                            localforage.setItem("video", formattedData);
                            localStorage.setItem(
                                "openBookUrl",
                                `${window.location.href.split("/")[0]}/videoreader/${this.props.it.bookISBN}?bookId=${parseInt(this.props.it.productId!)}`
                            );
                            this.setState({ ...this.state, confirmOpenBookDialog: true, previewBook: false });

                            try {
                                const userInfo = localStorage.getItem("userInfo");
                                const userInfoObj = JSON.parse(userInfo!!);
                                if (userInfoObj !== null) {
                                    if (userInfoObj.member !== undefined) {
                                        new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                                            memberId: userInfoObj.member.memberId,
                                            productId: this.props.it.productId,
                                            reportType: SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline,
                                            bookType: SaveReportRecordUsingPOSTBookTypeEnum.Audio,
                                        });
                                    }
                                }
                            } catch (e) {
                                console.log("e", e);
                            }
                            return;
                        }

                        api.getFileByString(fileName)
                            .then(async (blob: any) => {
                                var file = new File([blob], fileName, {
                                    lastModified: new Date().getTime(),
                                });

                                var fileExtension = getFileExtension(fileName);

                                await getMd5WithBrowser(file).then(async (md5) => {
                                    if (fileExtension === "pdf") {
                                        let reader = new FileReader();
                                        reader.readAsArrayBuffer(blob);
                                        reader.onload = async (event) => {
                                            const file_content = event?.target?.result;
                                            BookUtil.generateBook(fileName, fileExtension, md5, blob.size, fileName, file_content).then(async (bookObj) => {
                                                await BookUtil.addBook(bookObj.key, file_content);
                                                RecentBooks.setRecent(bookObj.key);
                                                //localforage.setItem("books", bookObj);
                                                localforage.setItem(
                                                    "books",
                                                    Object.assign({
                                                        ...bookObj,
                                                        productId: this.props.it.productId,
                                                    })
                                                );
                                                this.openBookClick(bookObj, this.props.it.bookname, "read");
                                            });
                                        };
                                    }

                                    if (fileExtension === "epub") {
                                        addEpub(file, md5, fileName).then(async (bookObj) => {
                                            await BookUtil.addBook(bookObj.key, file);

                                            RecentBooks.setRecent(bookObj.key);

                                            //localforage.setItem("books", bookObj);
                                            localforage.setItem(
                                                "books",
                                                Object.assign({
                                                    ...bookObj,
                                                    productId: this.props.it.productId,
                                                })
                                            );
                                            this.openBookClick(bookObj, this.props.it.bookname, "read");
                                        });
                                    }
                                });
                            })
                            .catch((e: any) => {});
                    }
                }
            }
        );
    };

    handleConfirmOpenBook = () => {
        try {
            const userInfo = localStorage.getItem("userInfo");
            const userInfoObj = JSON.parse(userInfo!!);
            if (userInfoObj !== null) {
                if (userInfoObj.member !== undefined) {
                    new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
                        memberId: userInfoObj.member.memberId,
                        productId: this.props.it.productId,
                        reportType: SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline,
                        bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                    });
                }
            }

            this.setState({
                ...this.state,
                confirmOpenBookDialog: false,
            });
            if (localStorage.getItem("openBookUrl") !== undefined && localStorage.getItem("openBookUrl") !== null && localStorage.getItem("openBookUrl") !== "") {
                window.open(localStorage.getItem("openBookUrl")!, "_blank");
            }
        } catch (e) {
            console.log("e", e);
        }
    };

    render() {
        let _showProcent = true;
        const { content, showError } = this.state;
        return (
            <>
                <div className={style.Container}>
                    <div className={style.CardView_IMG}>
                        <img
                            style={{ width: "150px", height: "200px" }}
                            src={
                                process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com"
                                    ? `${process.env.REACT_APP_BACKEND}/file/${this.props.it.bookCover}`
                                    : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${this.props.it.bookCover}?x-oss-process=image/resize,h_200`
                            }
                        />
                    </div>

                    <div className={style.Content}>
                        <h5 style={{ height: "32px" }}>{this.props.it.bookname}</h5>
                        <div style={{ display: "flex", fontSize: "10px" }}>
                            {this.props.it.author !== undefined ? this.props.it.author.split(":::").map((it: any) => <p>{it}&nbsp;</p>) : ""}
                        </div>
                        <div style={{ display: "flex", width: "100%" }} className={style.buttonContainer}>
                            <div>
                                <Button
                                    styleType={STYLETYPE_MAIN}
                                    callBack={() => this.handlePreViewBook()}
                                    isLoading={this.state.previewBook}
                                    loadingStyle={{ width: "20px", height: "20px" }}
                                >
                                    <Trans>Read</Trans>
                                </Button>
                                <Button styleType={STYLETYPE_MAIN} callBack={(value) => this.handleReturn()}>
                                    還書
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className={style.bottom}>
                        <div className={_showProcent ? style.processRow : style.processRowHide}>
                            {isNaN(this.props.it.percentage) ? (
                                <>
                                    <div className={style.processbarContainer} onClick={() => console.log("CLICK")}>
                                        <div className={style.processbar} style={{ width: "0%" }}></div>
                                    </div>
                                    <div className={style.percent}>(0%)</div>
                                </>
                            ) : (
                                <>
                                    <div className={style.processbarContainer} onClick={() => console.log("CLICK")}>
                                        <div
                                            className={style.processbar}
                                            style={{
                                                width: parseInt(this.props.it.percentage) + "%",
                                            }}
                                        ></div>
                                    </div>
                                    <div className={style.percent}>({parseInt(this.props.it.percentage)}%)</div>
                                </>
                            )}
                        </div>
                        <Button styleType={STYLETYPE_THIRD} callBack={(value) => this.handleResumeBook()}>
                            <Trans>Renewal</Trans>
                        </Button>

                        {/* <Button styleType={STYLETYPE_THIRD} callBack={(value) => this.setState({ ...this.state, showresumeBook: true })}>
                            <Trans>Renewal</Trans>
                        </Button> */}

                        <div className={style.ContentDate}>借書期至 {dateToString(this.state.return, "DD/MM/YYYY")}</div>
                    </div>
                </div>

                <DialogBox
                    styleType={PATH.STYLETYPE_FOUR}
                    title="是否開啟書本?"
                    showDialog={this.state.confirmOpenBookDialog}
                    children={
                        <div className={style.dialogButtonContainer}>
                            <Button
                                styleType={"cancelButton"}
                                callBack={() => {
                                    this.setState({
                                        ...this.state,
                                        confirmOpenBookDialog: false,
                                    });
                                    localStorage.setItem("openBookUrl", "");
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                styleType={"submitButton"}
                                callBack={() => {
                                    this.handleConfirmOpenBook();
                                }}
                            >
                                {/* <Trans>好</Trans> */}
                                確定
                            </Button>
                        </div>
                    }
                />

                <DialogBox
                    styleType={PATH.STYLETYPE_SECOND}
                    showDialog={this.state.showConfirmDialog}
                    p1={this.state.dialogMsg}
                    title={<Trans>SystemMessage</Trans>}
                    scroll={false}
                >
                    <div className={style.dialogButtonContainer}>
                        <Button
                            styleType={"submitButton"}
                            callBack={() => {
                                this.setState({ ...this.state, showConfirmDialog: false, dialogMsg: "" }, () => {
                                    this.props.callBack?.(this.state.borrowData);
                                });
                            }}
                        >
                            <Trans>Confirm</Trans>
                        </Button>
                    </div>
                </DialogBox>
                <DialogBox
                    styleType={PATH.STYLETYPE_SECOND}
                    showDialog={this.state.showBookIsOpenDialog}
                    p1={<Trans>isOpenBookMessage</Trans>}
                    title={<Trans>SystemMessage</Trans>}
                    scroll={false}
                >
                    <div className={style.dialogButtonContainer}>
                        <Button styleType={"submitButton"} callBack={() => this.setState({ ...this.state, showBookIsOpenDialog: false })}>
                            <Trans>Confirm</Trans>
                        </Button>
                    </div>
                </DialogBox>
            </>
        );
    }
}

export default withSocket(BookShelfItem);
