/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CouponDto,
    CouponDtoFromJSON,
    CouponDtoToJSON,
} from '../models';

export interface AddCouponUsingPOSTRequest {
    couponCode: string;
    discountId: number;
    startDate: Date;
    endDate: Date;
    name?: string;
}

export interface DeleteCouponByCouponCodeUsingPOSTRequest {
    couponCode: string;
    name?: string;
}

export interface DeleteCouponUsingPOSTRequest {
    couponId: number;
    name?: string;
}

export interface GetAllCouponAdminUsingGETRequest {
    name?: string;
}

export interface GetCouponAdminUsingGETRequest {
    couponId: number;
    name?: string;
}

export interface GetCouponByCouponCodeAdminUsingGETRequest {
    couponCode: string;
    name?: string;
}

export interface GetCouponByCouponCodeUsingGET1Request {
    couponCode: string;
    name?: string;
}

export interface GetCouponByMemberIdUsingGETRequest {
    name?: string;
}

export interface GetCouponUsingGETRequest {
    couponId: number;
    name?: string;
}

export interface ListAvailableAndRelatedCouponCodeUsingGETRequest {
    name?: string;
}

export interface SetCouponDiscountUsingPOSTRequest {
    couponId: string;
    discountId: number;
    name?: string;
}

export interface UpdateCouponUsingPOSTRequest {
    couponId: number;
    couponCode: string;
    discountId: number;
    startDate: Date;
    endDate: Date;
    name?: string;
}

/**
 * 
 */
export class CouponControllerApi extends runtime.BaseAPI {

    /**
     * addCoupon
     */
    async addCouponUsingPOSTRaw(requestParameters: AddCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode','Required parameter requestParameters.couponCode was null or undefined when calling addCouponUsingPOST.');
        }

        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling addCouponUsingPOST.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling addCouponUsingPOST.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling addCouponUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.discountId !== undefined) {
            queryParameters['discountId'] = requestParameters.discountId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * addCoupon
     */
    async addCouponUsingPOST(requestParameters: AddCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.addCouponUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteCouponByCouponCode
     */
    async deleteCouponByCouponCodeUsingPOSTRaw(requestParameters: DeleteCouponByCouponCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode','Required parameter requestParameters.couponCode was null or undefined when calling deleteCouponByCouponCodeUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * deleteCouponByCouponCode
     */
    async deleteCouponByCouponCodeUsingPOST(requestParameters: DeleteCouponByCouponCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.deleteCouponByCouponCodeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteCoupon
     */
    async deleteCouponUsingPOSTRaw(requestParameters: DeleteCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling deleteCouponUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/delete/{couponId}`.replace(`{${"couponId"}}`, encodeURIComponent(String(requestParameters.couponId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * deleteCoupon
     */
    async deleteCouponUsingPOST(requestParameters: DeleteCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.deleteCouponUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllCouponAdmin
     */
    async getAllCouponAdminUsingGETRaw(requestParameters: GetAllCouponAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CouponDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CouponDtoFromJSON));
    }

    /**
     * getAllCouponAdmin
     */
    async getAllCouponAdminUsingGET(requestParameters: GetAllCouponAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CouponDto>> {
        const response = await this.getAllCouponAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllCoupon
     */
    async getAllCouponUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CouponDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CouponDtoFromJSON));
    }

    /**
     * getAllCoupon
     */
    async getAllCouponUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CouponDto>> {
        const response = await this.getAllCouponUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getCouponAdmin
     */
    async getCouponAdminUsingGETRaw(requestParameters: GetCouponAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling getCouponAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/admin/{couponId}`.replace(`{${"couponId"}}`, encodeURIComponent(String(requestParameters.couponId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * getCouponAdmin
     */
    async getCouponAdminUsingGET(requestParameters: GetCouponAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.getCouponAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCouponByCouponCodeAdmin
     */
    async getCouponByCouponCodeAdminUsingGETRaw(requestParameters: GetCouponByCouponCodeAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode','Required parameter requestParameters.couponCode was null or undefined when calling getCouponByCouponCodeAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * getCouponByCouponCodeAdmin
     */
    async getCouponByCouponCodeAdminUsingGET(requestParameters: GetCouponByCouponCodeAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.getCouponByCouponCodeAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCouponByCouponCode
     */
    async getCouponByCouponCodeUsingGET1Raw(requestParameters: GetCouponByCouponCodeUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode','Required parameter requestParameters.couponCode was null or undefined when calling getCouponByCouponCodeUsingGET1.');
        }

        const queryParameters: any = {};

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * getCouponByCouponCode
     */
    async getCouponByCouponCodeUsingGET1(requestParameters: GetCouponByCouponCodeUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.getCouponByCouponCodeUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCouponByMemberId
     */
    async getCouponByMemberIdUsingGETRaw(requestParameters: GetCouponByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CouponDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CouponDtoFromJSON));
    }

    /**
     * getCouponByMemberId
     */
    async getCouponByMemberIdUsingGET(requestParameters: GetCouponByMemberIdUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CouponDto>> {
        const response = await this.getCouponByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCoupon
     */
    async getCouponUsingGETRaw(requestParameters: GetCouponUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling getCouponUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/{couponId}`.replace(`{${"couponId"}}`, encodeURIComponent(String(requestParameters.couponId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * getCoupon
     */
    async getCouponUsingGET(requestParameters: GetCouponUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.getCouponUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listAvailableAndRelatedCouponCode
     */
    async listAvailableAndRelatedCouponCodeUsingGETRaw(requestParameters: ListAvailableAndRelatedCouponCodeUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CouponDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CouponDtoFromJSON));
    }

    /**
     * listAvailableAndRelatedCouponCode
     */
    async listAvailableAndRelatedCouponCodeUsingGET(requestParameters: ListAvailableAndRelatedCouponCodeUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CouponDto>> {
        const response = await this.listAvailableAndRelatedCouponCodeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setCouponDiscount
     */
    async setCouponDiscountUsingPOSTRaw(requestParameters: SetCouponDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling setCouponDiscountUsingPOST.');
        }

        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling setCouponDiscountUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.couponId !== undefined) {
            queryParameters['couponId'] = requestParameters.couponId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.discountId !== undefined) {
            queryParameters['discountId'] = requestParameters.discountId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/setDiscount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * setCouponDiscount
     */
    async setCouponDiscountUsingPOST(requestParameters: SetCouponDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.setCouponDiscountUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateCoupon
     */
    async updateCouponUsingPOSTRaw(requestParameters: UpdateCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<CouponDto>> {
        if (requestParameters.couponId === null || requestParameters.couponId === undefined) {
            throw new runtime.RequiredError('couponId','Required parameter requestParameters.couponId was null or undefined when calling updateCouponUsingPOST.');
        }

        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode','Required parameter requestParameters.couponCode was null or undefined when calling updateCouponUsingPOST.');
        }

        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling updateCouponUsingPOST.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling updateCouponUsingPOST.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling updateCouponUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.couponId !== undefined) {
            queryParameters['couponId'] = requestParameters.couponId;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.discountId !== undefined) {
            queryParameters['discountId'] = requestParameters.discountId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/coupon/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponDtoFromJSON(jsonValue));
    }

    /**
     * updateCoupon
     */
    async updateCouponUsingPOST(requestParameters: UpdateCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<CouponDto> {
        const response = await this.updateCouponUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
