import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./assets/reader/locales/en/translation.json";
import translationTW from "./assets/reader/locales/tw/translation.json";
import translationCN from "./assets/reader/locales/cn/translation.json";
// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    cn: {
        translation: translationCN,
    },
    cht: {
        translation: translationTW,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "cht",
        fallbackLng: "cht",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
