/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import {
    PointRuleDto,
    PointRuleDtoFromJSON,
    PointRuleDtoFromJSONTyped,
    PointRuleDtoToJSON,
} from './PointRuleDto';

/**
 * 
 * @export
 * @interface OrderDtoLite
 */
export interface OrderDtoLite {
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    birthdayDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    convertedCash?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    couponDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    earnedPoint?: number;
    /**
     * 
     * @type {MemberDto}
     * @memberof OrderDtoLite
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoLite
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    orderTotal?: number;
    /**
     * 
     * @type {PointRuleDto}
     * @memberof OrderDtoLite
     */
    pointRule?: PointRuleDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    usedPoint?: number;
}

export function OrderDtoLiteFromJSON(json: any): OrderDtoLite {
    return OrderDtoLiteFromJSONTyped(json, false);
}

export function OrderDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDtoLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthdayDiscountPrice': !exists(json, 'birthdayDiscountPrice') ? undefined : json['birthdayDiscountPrice'],
        'convertedCash': !exists(json, 'convertedCash') ? undefined : json['convertedCash'],
        'couponCode': !exists(json, 'couponCode') ? undefined : json['couponCode'],
        'couponDiscountPrice': !exists(json, 'couponDiscountPrice') ? undefined : json['couponDiscountPrice'],
        'earnedPoint': !exists(json, 'earnedPoint') ? undefined : json['earnedPoint'],
        'member': !exists(json, 'member') ? undefined : MemberDtoFromJSON(json['member']),
        'orderAddress': !exists(json, 'orderAddress') ? undefined : json['orderAddress'],
        'orderAddress1': !exists(json, 'orderAddress1') ? undefined : json['orderAddress1'],
        'orderAddress2': !exists(json, 'orderAddress2') ? undefined : json['orderAddress2'],
        'orderAddressRecipient': !exists(json, 'orderAddressRecipient') ? undefined : json['orderAddressRecipient'],
        'orderDate': !exists(json, 'orderDate') ? undefined : (new Date(json['orderDate'])),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : json['orderStatus'],
        'orderTotal': !exists(json, 'orderTotal') ? undefined : json['orderTotal'],
        'pointRule': !exists(json, 'pointRule') ? undefined : PointRuleDtoFromJSON(json['pointRule']),
        'productTotal': !exists(json, 'productTotal') ? undefined : json['productTotal'],
        'shippingFee': !exists(json, 'shippingFee') ? undefined : json['shippingFee'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
    };
}

export function OrderDtoLiteToJSON(value?: OrderDtoLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthdayDiscountPrice': value.birthdayDiscountPrice,
        'convertedCash': value.convertedCash,
        'couponCode': value.couponCode,
        'couponDiscountPrice': value.couponDiscountPrice,
        'earnedPoint': value.earnedPoint,
        'member': MemberDtoToJSON(value.member),
        'orderAddress': value.orderAddress,
        'orderAddress1': value.orderAddress1,
        'orderAddress2': value.orderAddress2,
        'orderAddressRecipient': value.orderAddressRecipient,
        'orderDate': value.orderDate === undefined ? undefined : (value.orderDate.toISOString()),
        'orderId': value.orderId,
        'orderNo': value.orderNo,
        'orderStatus': value.orderStatus,
        'orderTotal': value.orderTotal,
        'pointRule': PointRuleDtoToJSON(value.pointRule),
        'productTotal': value.productTotal,
        'shippingFee': value.shippingFee,
        'usedPoint': value.usedPoint,
    };
}

