/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductListDto
 */
export interface ProductListDto {
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    bookTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListDto
     */
    productListId?: number;
}

export function ProductListDtoFromJSON(json: any): ProductListDto {
    return ProductListDtoFromJSONTyped(json, false);
}

export function ProductListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookTotal': !exists(json, 'bookTotal') ? undefined : json['bookTotal'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'productListId': !exists(json, 'productListId') ? undefined : json['productListId'],
    };
}

export function ProductListDtoToJSON(value?: ProductListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookTotal': value.bookTotal,
        'description': value.description,
        'name': value.name,
        'productListId': value.productListId,
    };
}

