/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingEvent,
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import {
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MarketingEventMemberLevel
 */
export interface MarketingEventMemberLevel {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventMemberLevel
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMemberLevel
     */
    marketingEventId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MarketingEventMemberLevel
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMemberLevel
     */
    memberLevelId?: number;
}

export function MarketingEventMemberLevelFromJSON(json: any): MarketingEventMemberLevel {
    return MarketingEventMemberLevelFromJSONTyped(json, false);
}

export function MarketingEventMemberLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventMemberLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': !exists(json, 'marketingEventByMarketingEventId') ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': !exists(json, 'marketingEventId') ? undefined : json['marketingEventId'],
        'memberLevelByMemberLevelId': !exists(json, 'memberLevelByMemberLevelId') ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
    };
}

export function MarketingEventMemberLevelToJSON(value?: MarketingEventMemberLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value.marketingEventByMarketingEventId),
        'marketingEventId': value.marketingEventId,
        'memberLevelByMemberLevelId': MemberLevelToJSON(value.memberLevelByMemberLevelId),
        'memberLevelId': value.memberLevelId,
    };
}

