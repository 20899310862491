import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  AddMemberFavouriteByMemberIdUsingPUTRequest,
  BookBorrowStatusDto,
  BorrowBookUsingPOSTRequest,
  BorrowControllerApi,
  BorrowDto,
  DeleteMemberFavouriteByMemberIdUsingDELETERequest,
  GetMemberFavouritesByMemberIdUsingGETRequest,
  ListAllMyBorrowUsingPOSTRequest,
  ListAllMyReserveBookUsingPOSTRequest,
  MemberControllerApi,
  MyBorrowStatus,
  MyBorrowStatusStatusEnum,
  ProductBorrowMyStatusUsingPOSTRequest,
  ProductControllerApi,
  ProductDto,
  ReportRecordControllerApi,
  ReserveBookUsingPOSTRequest,
  ReserveBorrow,
  SaveReportRecordUsingPOSTBookTypeEnum,
  SaveReportRecordUsingPOSTReportTypeEnum,
  SuccessResponseModel,
  UpdateMemberUsingPATCH1Request,
} from "../../fetcher";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import style from "./BookView.module.css";
import { handleUserInfo } from "../../redux/actions/userInfo";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { myBookMarkList } from "../../redux/actions/myBookMark/myBookMark";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { IProductAllListModel } from "../../model";
import api from "../../api/api";
import { getFileExtension, validateEmail } from "../../utils/GlobalFunction";
import { getMd5WithBrowser } from "../../utils/reader/fileUtils/md5Util";
import BookUtil from "../../utils/reader/fileUtils/bookUtil";
import RecentBooks from "../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
import localforage from "localforage";
import { addEpub } from "../../utils/reader/fileUtils/epubUtil";
import loadingGif from "../../assets/images/loading.gif";
import DialogBox from "../DialogBox/DialogBox";
import Button from "../Button/Button";
import Input from "../Input/Input";
import { resolve } from "path";
import * as Storage from "../../utils/local-storage";
import { useSocket } from "../../utils/socket/SocketContext";

function BookView(props: any) {
  //const [userInfo, setUserInfo] = useState<any>({});

  const [productBorrowStatus, setProductBorrowStatus] = useState<any>();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openBorrowDialog, setOpenBorrowDialog] = useState(false);
  const [openReserveDialog, setOpenReserveDialog] = useState(false);
  const [bookingEmail, setBookingEmail] = useState("");
  //const [needEmail, setNeedEmail] = useState(true);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);

  const [borrowBookMaxDialog, setBorrowBookMaxDialog] = useState(false);
  const [reserveBookMaxDialog, setReserveBookMaxDialog] = useState(false);

  const [confirmOpenBookDialog, setConfirmOpenBookDialog] = useState(false);

  const [tempProductId, setTempProductId] = useState<string>("");

  const { t, i18n } = useTranslation();
  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });
  const navigate = useNavigate();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");

  //#region useSocket to count user open this page, open book or not and count current online read
  // const {
  //   socketMsg,
  //   setDetailPage,
  //   setBookPage,
  //   setOpenBookUser,
  //   //setTryTouch,
  // } = useSocket();

  const socket = useSocket();

  const [isOpenBook, setIsOpenBook] = useState(false);
  const [showBookIsOpenDialog, setShowBookIsOpenDialog] = useState(false);
  //#endregion

  useEffect(() => {
    //console.log("book view socket", socket);
    if (socket !== undefined) {
      if (Object.keys(socket.serverMessage).length > 0) {
        const { bookPage, ref } = socket.serverMessage;

        if (Object.keys(bookPage).length > 0) {
          for (let i = 0; i < Object.keys(bookPage).length; i++) {
            if (ref !== undefined) {
              if (ref.length > 0) {
                const findOpenBookSocket = ref.find(
                  (it) => it === Object.keys(bookPage)[i]
                );

                if (findOpenBookSocket !== undefined) {
                  setIsOpenBook(true);
                } else {
                  setIsOpenBook(false);
                }
              }
            }
          }
        } else {
          setIsOpenBook(false);
        }
      }
    }
  }, [socket]);

  useEffect(() => {
    if (Object.keys(props.userInfo).length > 0) {
      setBookingEmail(props.userInfo.member.email);
    }
    setProductBorrowStatus(props.props.borrowStatus);
  }, []);

  useEffect(() => {
    //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";

    if (tempProductId !== "") {
      let readerConfig = localStorage.getItem("readerConfig");

      if (readerConfig !== null) {
        let readerConfigObj = JSON.parse(readerConfig);

        if (props.reader.mode !== "" && props.reader.mode !== undefined) {
          readerConfigObj["rm"] = props.reader.mode.substring(0, 1);
          localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));

          if (props.reader.mode === "read") {
            setLoadingProd(true);
            props.handleReaderMode("");
          }

          if (props.reader.mode === "preview") {
            setLoadingPreview(true);
            props.handleReaderMode("");
          }

          if (props.reader.mode === "online") {
            setLoadingOnlineRead(true);
            props.handleReaderMode("");
          }
        }
      }
    }

    // if (props.userInfo.mode !== undefined) {
    //   console.log("props10", props.userInfo.mode);
    //   if (props.reader.mode === "read") {
    //     setLoadingProd(true);
    //   }
    //   if (props.reader.mode === "preview") {
    //     setLoadingPreview(true);
    //   }
    // }
  }, [props]);

  useEffect(() => {
    if (loadingPreview) {
      openBook();
    }
  }, [loadingPreview]);

  useEffect(() => {
    if (loadingProd || loadingOnlineRead) {
      openBook();
    }
  }, [loadingProd, loadingOnlineRead]);

  const openBook = () => {
    var item = props.props as IProductAllListModel;

    if (item["ePubProdFileName"] !== undefined) {
      var fileName = item["ePubProdFileName"];

      const readerConfig = localStorage.getItem("readerConfig");

      var fileExtension = getFileExtension(fileName);

      let reportType = "" as SaveReportRecordUsingPOSTReportTypeEnum;

      if (readerConfig !== null) {
        const readerConfigObj = JSON.parse(readerConfig);
        if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
          reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
        }

        if (readerConfigObj.rm === "p") {
          reportType = SaveReportRecordUsingPOSTReportTypeEnum.Preview;
        }
      }

      if (reportType === ("" as SaveReportRecordUsingPOSTReportTypeEnum)) {
        reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
      }

      let memberId = 1;

      if (Object.keys(props.userInfo).length > 0) {
        memberId = props.userInfo.member.memberId;
      }

      new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
        memberId: memberId,
        productId: props.props.productId,
        reportType: reportType,
        bookType:
          fileExtension === "mp3" || fileExtension === "mp4"
            ? SaveReportRecordUsingPOSTBookTypeEnum.Audio
            : SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
      });

      if (getFileExtension(fileName) === "mp3") {
        var audioDataList = JSON.parse(
          item["multimediaFileNames"] as unknown as string
        );
        var formattedDataList: Array<any> = [];
        audioDataList
          .sort((a: any, b: any) => {
            return a["Seq"] - b["Seq"];
          })
          .map((chapter: any) => {
            let chapterData: any = {};
            chapterData["title"] = chapter["Title"];
            chapterData["audioFilePath"] = chapter["File"];
            chapterData["srtFilePath"] = chapter["Subtitle"];
            chapterData["covers"] = [
              { coverFilePath: chapter["Cover"], appearTime: 0 },
            ];
            formattedDataList.push(chapterData);
          });
        var formattedData: any = { list: formattedDataList };
        formattedData["bookname"] = item && item.productNameChi;
        formattedData["author"] = item && item.author;
        formattedData["cover"] = item && item.coverFileName;
        formattedData["bookIsbn"] = item && item.bookIsbn;
        localforage.setItem("audio", formattedData);
        console.log(
          "openBookUrl",
          `${window.location.href.split("/")[0]}/audioreader/${item.bookIsbn}`
        );
        localStorage.setItem(
          "openBookUrl",
          `${window.location.href.split("/")[0]}/audioreader/${item.bookIsbn}`
        );
        setLoadingPreview(false);
        setLoadingProd(false);
        setLoadingOnlineRead(false);
        setConfirmOpenBookDialog(true);
        setTempProductId("");
        return;
      }

      api
        .getFileByString(fileName)

        .then((blob: any) => {
          var file = new File([blob], "fileName", {
            lastModified: new Date().getTime(),
          });

          var fileExtension = getFileExtension(fileName);

          getMd5WithBrowser(file).then(async (md5) => {
            if (fileExtension === "pdf") {
              let reader = new FileReader();
              reader.readAsArrayBuffer(blob);
              reader.onload = async (event) => {
                const file_content = event?.target?.result as String;
                BookUtil.generateBook(
                  fileName,
                  fileExtension,
                  md5,
                  blob.size,
                  fileName,
                  file_content
                ).then(async (bookObj) => {
                  await BookUtil.addBook(bookObj.key, file_content);
                  RecentBooks.setRecent(bookObj.key);
                  localforage.setItem("books", bookObj);
                  openBookClick(bookObj, item.productNameChi);
                });
              };
            }

            if (fileExtension === "epub") {
              addEpub(file, md5, fileName).then(async (bookObj) => {
                await BookUtil.addBook(bookObj.key, file);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                openBookClick(bookObj, item.productNameChi);
              });
            }
          });
        });
    }
  };

  const openBookClick = (bookObj: any, fileName: string) => {
    //console.log("openBookClick", bookObj, fileName);
    if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
      //props.history.push(BookUtil.getBookUrl(book));
      //props.handleReadingBook(book);
    } else {
      //localStorage.setItem("tempBook", JSON.stringify(book));

      BookUtil.RedirectBook(bookObj, fileName, props.props.productId).then(
        () => {
          if (
            localStorage.getItem("openBookUrl") !== undefined &&
            localStorage.getItem("openBookUrl") !== null &&
            localStorage.getItem("openBookUrl") !== ""
          ) {
            setConfirmOpenBookDialog(true);
          }
        }
      );
    }
    setLoadingPreview(false);
    setLoadingProd(false);
    setLoadingOnlineRead(false);
    setTempProductId("");
  };

  const handleReadBook = (actionType: string, productId: string) => {
    if (isOpenBook) {
      setShowBookIsOpenDialog(true);
      return;
    }
    setTempProductId(productId);
    console.log("productIdproductIdproductId", productId);
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      props.handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      setLoadingOnlineRead(true);
    }
  };

  const handleCancelReserveBook = () => {
    new BorrowControllerApi(genApiConfig())
      .cancelReservedBookUsingDELETE({
        memberId: props.userInfo.member.memberId,
        productId: props.props.productId,
      })
      .then((data) => {
        if (data.status !== undefined) {
          new BorrowControllerApi(genApiConfig())
            .listAllMyReserveBookUsingPOST({
              memberId: props.userInfo.member.memberId,
            } as ListAllMyReserveBookUsingPOSTRequest)
            .then((data: any) => {
              //console.log("dataaaaaaaa", data);
              props.myReserveBookList(data);
            });
          setConfirmDialogMessage(
            `書本 ${props.props.productNameChi} 的預約己取消`
          );
          setShowConfirmDialog(true);
        }
      });
  };

  const reserveBookComfirm = () => {
    //console.log("reserveBookComfirm", needEmail);
    new MemberControllerApi(genApiConfig())
      .updateMemberUsingPATCH1({
        memberId: props.userInfo?.member?.memberId!!,
        email: bookingEmail,
        expireDate: "",
      } as UpdateMemberUsingPATCH1Request)
      .then((res) => {
        if (res.success) {
          let userInfo = props.userInfo;
          if (userInfo !== undefined) {
            userInfo.member.email = res.memberDto?.email;
          }
          console.log("dadfsffs", userInfo);
          props.handleUserInfo(userInfo);

          new BorrowControllerApi(genApiConfig())
            .reservedBookMoreThanMaxUsingPOST({
              memberId: props.userInfo.member.memberId,
              productId: props.props.productId,
            })
            .then((res: SuccessResponseModel) => {
              if (res.success) {
                setReserveBookMaxDialog(true);
              } else {
                new BorrowControllerApi(genApiConfig())
                  .reserveBookUsingPOST({
                    memberId: props.userInfo.member.memberId,
                    productId: props.props.productId,
                    email: bookingEmail,
                    //needSend: needEmail,
                  } as ReserveBookUsingPOSTRequest)
                  .then((data) => {
                    // console.log("datbbbbb", data);

                    let flag = false;
                    if (data !== undefined && data !== null) {
                      setConfirmDialogMessage("成功預約");
                      setShowConfirmDialog(true);
                      setOpenReserveDialog(false);
                      setOpenEmailDialog(false);
                      flag = true;
                    }
                    return flag;
                  })
                  .then((flagResult) => {
                    //console.log("flagResult", flagResult);
                    if (flagResult) {
                      new ReportRecordControllerApi(
                        genApiConfig()
                      ).saveReportRecordUsingPOST({
                        memberId: props.userInfo.member.memberId,
                        productId: props.props.productId,
                        reportType:
                        SaveReportRecordUsingPOSTReportTypeEnum.BorrowTime,
                        bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                      });
                    }
                  })
                  .then(() =>
                    new BorrowControllerApi(genApiConfig())
                      .listAllMyReserveBookUsingPOST({
                        memberId: props.userInfo.member.memberId,
                      } as ListAllMyReserveBookUsingPOSTRequest)
                      .then((data: any) => {
                        //console.log("dataaaaaaaa", data);
                        props.myReserveBookList(data);
                      })
                  )
                  .catch((err) => {
                    err.json().then((er: any) => {
                      console.log("reserveBookComfirm", er.message);
                      setOpenEmailDialog(false);
                    });
                  });
              }
            });
        }
      });

    setOpenEmailDialog(false);
  };

  const borrowBookComfirm = () => {
    let requestQuery = {} as ProductBorrowMyStatusUsingPOSTRequest;

    requestQuery.memberId = props.userInfo.member.memberId;
    requestQuery.productId = props.props.productId;

    new BorrowControllerApi(genApiConfig())
      .productBorrowMyStatusUsingPOST(requestQuery)
      .then((res: MyBorrowStatus) => {
        switch (res.status) {
          case MyBorrowStatusStatusEnum.AlreadyBorrow:
            setConfirmDialogMessage("書本已在書架");
            setShowConfirmDialog(true);
            return;
          case MyBorrowStatusStatusEnum.CanBorrow:
            new BorrowControllerApi(genApiConfig())
              .borrowedBookMoreThanMaxUsingPOST({
                memberId: props.userInfo.member.memberId,
                productId: parseInt(props.props.productId)
              })
              .then((res: SuccessResponseModel) => {
                if (res.success) {
                  setBorrowBookMaxDialog(true);
                } else {
                  new BorrowControllerApi(genApiConfig())
                    .borrowBookUsingPOST({
                      memberId: props.userInfo.member.memberId,
                      productId: props.props.productId,
                    } as BorrowBookUsingPOSTRequest)
                    .then((data: BorrowDto) => {
                      let flag = false;
                      if (data.borrowId !== 0) {
                        setConfirmDialogMessage("成功借書");
                        setShowConfirmDialog(true);
                        setOpenBorrowDialog(false);
                        flag = true;
                        new BorrowControllerApi(genApiConfig())
                          .productBorrowStatusUsingPOST({
                            productId: props.props.productId,
                          })
                          .then((data) => setProductBorrowStatus(data));
                      }
                      return flag;
                    })
                    .then((flagResult) => {
                      //console.log("flagResult", flagResult);
                      if (flagResult) {
                        new ReportRecordControllerApi(
                          genApiConfig()
                        ).saveReportRecordUsingPOST({
                          memberId: props.userInfo.member.memberId,
                          productId: props.props.productId,
                          reportType:
                          SaveReportRecordUsingPOSTReportTypeEnum.WebBorrow,
                          bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                        });
                      }
                    })
                    .then(() =>
                      new BorrowControllerApi(genApiConfig())
                        .listAllMyBorrowUsingPOST({
                          memberId: props.userInfo.member.memberId,
                          pageNo: 0,
                          pageSize: 50,
                        } as ListAllMyBorrowUsingPOSTRequest)
                        .then((data: any) => {
                          props.myBorrowBookList(data.content);
                        })
                    )
                    .catch((err) => {
                      console.log("borrowBookComfirm", err);
                    });
                }
              });

            break;
          case MyBorrowStatusStatusEnum.CanReserve:
            new ReportRecordControllerApi(
              genApiConfig()
            ).saveReportRecordUsingPOST({
              memberId: props.userInfo.member.memberId,
              productId: props.props.productId,
              reportType: SaveReportRecordUsingPOSTReportTypeEnum.CantBorrow,
              bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
            });
            setConfirmDialogMessage("書本沒有剩餘，是否預約");
            setShowConfirmDialog(true);
            break;
          case MyBorrowStatusStatusEnum.AlreadyReserve:
            setConfirmDialogMessage("書本已經預約");
            setShowConfirmDialog(true);
            break;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    setOpenBorrowDialog(false);
  };

  const checkLogin = () => {
    if (localStorage.getItem(PATH.USER_INFO) === null) {
      setConfirmDialogMessage("請先登入");
      setShowConfirmDialog(true);
      return false;
    }
    return true;
  };

  const checkIsStud = () => {
    if (props.userInfo.isAdmin) {
      setConfirmDialogMessage("請使用學生用戶");
      setShowConfirmDialog(true);
      return false;
    }
    return true;
  };

  const addBookMark = () => {
    if (props.userInfo.isAdmin) {
      setConfirmDialogMessage("請使用學生用戶");
      setShowConfirmDialog(true);
      return;
    }

    // console.log("props", )

    new MemberControllerApi(genApiConfig())
      .addMemberFavouriteByMemberIdUsingPUT({
        memberId: props.userInfo.member.memberId,
        productId: props.props.productId,
      } as AddMemberFavouriteByMemberIdUsingPUTRequest)
      .then((data) => {
        if (data) {
          setConfirmDialogMessage(t("AddBookmark"));
          setShowConfirmDialog(true);

          new MemberControllerApi(genApiConfig())
            .getMemberFavouritesByMemberIdUsingGET({
              memberId: props.userInfo.member.memberId,
            } as GetMemberFavouritesByMemberIdUsingGETRequest)
            .then((data: Array<ProductDto>) => {
              props.myBookMarkList(data);
              //console.log("nonononono", data);
            });
        }
      });
  };

  const unBookMark = () => {
    // let userInfo = JSON.parse(localStorage.getItem(PATH.USER_INFO)!);
    new MemberControllerApi(genApiConfig())
      .deleteMemberFavouriteByMemberIdUsingDELETE({
        memberId: props.userInfo.member.memberId,
        productId: props.props.productId,
      } as DeleteMemberFavouriteByMemberIdUsingDELETERequest)
      .then((data) => {
        if (data) {
          setConfirmDialogMessage(t("RemoveBookmark"));
          setShowConfirmDialog(true);

          new MemberControllerApi(genApiConfig())
            .getMemberFavouritesByMemberIdUsingGET({
              memberId: props.userInfo.member.memberId,
            } as GetMemberFavouritesByMemberIdUsingGETRequest)
            .then((data: Array<ProductDto>) => {
              props.myBookMarkList(data);
              //console.log("nonononono", data);
            });
        }
      });
  };

  const handleReturn = () => {
    new BorrowControllerApi(genApiConfig())
      .returnBookUsingPOST({
        memberId: props.userInfo.member.memberId,
        productId: props.props.productId,
      })
      .then((data: BorrowDto[]) => {
        if (data) {
          setConfirmDialogMessage("成功還書");
          setShowConfirmDialog(true);
          new BorrowControllerApi(genApiConfig())
            .listAllMyBorrowUsingPOST({
              memberId: props.userInfo.member.memberId,
              pageNo: 0,
              pageSize: 50,
            } as ListAllMyBorrowUsingPOSTRequest)
            .then((data: any) => {
              props.myBorrowBookList(data.content);
            });
        }
      });
  };

  return (
    <>
      {props.props.status === 1 ? (
        <div className={style.bookContainer}>
          <div className={style.bookImgContainer}>
            {loadingPreview || loadingProd || loadingOnlineRead ? (
              <img
                src={loadingGif}
                height="30px"
                width="30px"
                style={{ margin: "auto", boxShadow: "none" }}
              />
            ) : (
              <>
                <Link to={"/product/" + props.props.productId.toString()}>
                  <img
                    style={{ maxHeight: "160px" }}
                    src={
                      process.env.REACT_APP_BACKEND ==
                      "https://suepsso.octopus-tech.com"
                        ? `${process.env.REACT_APP_BACKEND}/file/${props.props.coverFileName}`
                        : `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${props.props.coverFileName}?x-oss-process=image/resize,h_200`
                    }
                  />
                </Link>

                {isMobile ? (
                  <></>
                ) : (
                  <div className={style.bookImgButtonGroup}>
                    <button
                      className={style.bookImgButton}
                      onClick={() =>
                        navigate("/product/" + props.props.productId.toString())
                      }
                    >
                      <h4 style={{ color: "white" }}>
                        <Trans>intro</Trans>
                      </h4>
                    </button>
                    {localStorage.getItem(PATH.USER_INFO) === null ||
                    props.borrowBookList.borrowBookList.find(
                      (it: any) => it.productId == props.props.productId
                    ) === undefined ? (
                      <>
                        <button
                          className={style.bookImgButton}
                          onClick={() =>
                            handleReadBook("preview", props.props.productId)
                          }
                        >
                          <h4 style={{ color: "white" }}>
                            <Trans>Preview</Trans>
                          </h4>
                        </button>
                        {productBorrowStatus &&
                        productBorrowStatus.canBorrowCopy > 0 ? (
                          <button
                            className={style.bookImgButton}
                            onClick={() => {
                              if (checkIsStud() && checkLogin()) {
                                setOpenBorrowDialog(true);
                              }
                            }}
                          >
                            <h4 style={{ color: "white" }}>
                              <Trans>Borrow</Trans>
                            </h4>
                          </button>
                        ) : props.reserveBookList.reserveBookList.find(
                          (it: any) => it.productId == props.props.productId
                        ) ? (
                          <button
                            className={style.bookImgButton}
                            onClick={() => {
                              handleCancelReserveBook();
                            }}
                          >
                            <h4 style={{ color: "white" }}>
                              <Trans>CancelBooking</Trans>
                            </h4>
                          </button>
                        ) : (
                          <button
                            className={style.bookImgButton}
                            onClick={() => {
                              if (checkIsStud() && checkLogin()) {
                                setOpenEmailDialog(true);
                              }
                            }}
                          >
                            <h4 style={{ color: "white" }}>
                              <Trans>Booking</Trans>
                            </h4>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className={style.bookImgButton}
                          onClick={() =>
                            handleReadBook("read", props.props.productId)
                          }
                        >
                          <h4 style={{ color: "white" }}>
                            <Trans>Read</Trans>
                          </h4>
                        </button>
                        <button
                          className={style.bookImgButton}
                          onClick={() => handleReturn()}
                        >
                          <h4 style={{ color: "white" }}>
                            <Trans>ReturnTheBook</Trans>
                          </h4>
                        </button>
                      </>
                    )}
                    {localStorage.getItem(PATH.USER_INFO) === null ||
                    props.bookMarkList.bookMarkList.find(
                      (it: any) => it.productId == props.props.productId
                    ) === undefined ? (
                      <button
                        className={style.bookImgButton}
                        onClick={() => {
                          if (checkLogin()) {
                            addBookMark();
                          }
                        }}
                      >
                        <h4 style={{ color: "white" }}>
                          <Trans>addFavorites</Trans>
                        </h4>
                      </button>
                    ) : (
                      <button
                        className={style.bookImgButton}
                        onClick={() => {
                          unBookMark();
                        }}
                      >
                        <h4 style={{ color: "white" }}>
                          <Trans>CancelBookMark</Trans>
                        </h4>
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <Link to={"/product/" + props.props.productId.toString()}>
            <h4 title={props.props.productNameChi}>
              {i18n.language === "cn"
                ? converter(props.props.productNameChi)
                : props.props.productNameChi}
            </h4>
            <div className={style.bookflex}>
              {props.props.author ? (
                props.props.author
                  .split(":::")
                  .map((it: any) => (
                    <p style={{ fontSize: "1em" }}>
                      {i18n.language === "cn" ? converter(it) : it}
                    </p>
                  ))
              ) : (
                <></>
              )}
            </div>
          </Link>
        </div>
      ) : null}
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title={<Trans>BorrowBook</Trans>}
        p1={<Trans>BorrowBookText</Trans>}
        bookName={props.props.productNameChi}
        p2={<Trans>QuestionMark</Trans>}
        showDialog={openBorrowDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"cancelButton"}
              callBack={() => setOpenBorrowDialog(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"submitButton"}
              callBack={() => borrowBookComfirm()}
            >
              <Trans>Borrow</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_THIRD}
        title={<Trans>BookReservation</Trans>}
        p1={<Trans>BookReservationText</Trans>}
        bookName={props.props.productNameChi}
        p2={<Trans>QuestionMark</Trans>}
        showDialog={openReserveDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"cancelButton"}
              callBack={() => setOpenReserveDialog(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"submitButton"}
              callBack={() => setOpenEmailDialog(true)}
              //callBack={() => reserveBookComfirm(params.id!!)}
            >
              <Trans>Booking</Trans>
            </Button>
          </div>
        }
      />

      <DialogBox
        styleType={PATH.STYLETYPE_THIRD}
        title={<Trans>AppointmentNotice</Trans>}
        p1={
          <>
            <div>
              <Trans>AppointmentNoticeText</Trans>
              <Link to={PATH.POLICY}>
                <Trans>AppointmentNoticeTextURL</Trans>
              </Link>
              <Trans>AppointmentNoticeTextClose</Trans>
            </div>
          </>
        }
        bookName={props.props.productNameChi}
        showDialog={openEmailDialog}
        children={
          <>
            <div className={style.pad}>
              <Input
                type="email"
                placeholder={t("inputEmail")}
                styleType={PATH.STYLETYPE_FIFTH}
                disabled={
                  Object.keys(props.userInfo).length > 0
                    ? props.userInfo.member.email !== undefined &&
                    props.userInfo.member.email !== ""
                      ? true
                      : false
                    : false
                }
                value={bookingEmail}
                callBack={(value: any) => setBookingEmail(value)}
              />
            </div>
            <div className={style.dialogButtonContainer2}>
              <Button
                styleType={"cancelButton"}
                callBack={() => {
                  setBookingEmail("");
                  setOpenEmailDialog(false);
                }}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                styleType={"submitButton"}
                callBack={() => {
                  if (!validateEmail(bookingEmail)) {
                    setConfirmDialogMessage("請輸入有效電郵");
                    setShowConfirmDialog(true);
                  } else {
                    reserveBookComfirm();
                  }
                }}
              >
                <Trans>submit</Trans>
              </Button>
            </div>
          </>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="無法借閱"
        p1="你的借閱書本已達上限，請先還書後再嘗試"
        showDialog={borrowBookMaxDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"cancelButton"}
              callBack={() => console.log()}
              hidden
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"submitButton"}
              callBack={() => setBorrowBookMaxDialog(false)}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="無法預約"
        p1="你的預約書本已達上限，請先取消預約後再嘗試"
        showDialog={reserveBookMaxDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"cancelButton"}
              callBack={() => console.log()}
              hidden
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"submitButton"}
              callBack={() => setReserveBookMaxDialog(false)}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="是否開啟書本?"
        showDialog={confirmOpenBookDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"cancelButton"}
              callBack={() => {
                setConfirmOpenBookDialog(false);
                localStorage.setItem("openBookUrl", "");
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"submitButton"}
              callBack={() => {
                setConfirmOpenBookDialog(false);
                if (
                  localStorage.getItem("openBookUrl") !== undefined &&
                  localStorage.getItem("openBookUrl") !== null &&
                  localStorage.getItem("openBookUrl") !== ""
                ) {
                  window.open(localStorage.getItem("openBookUrl")!, "_blank");
                }
              }}
            >
              {/* <Trans>好</Trans> */}
              確定
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_SECOND}
        showDialog={showConfirmDialog}
        p1={confirmDialogMessage}
        title={<Trans>SystemMessage</Trans>}
        scroll={false}
      >
        <div className={style.dialogButtonContainer2}>
          <Button
            styleType={"submitButton"}
            callBack={() => {
              setShowConfirmDialog(false);
              if (confirmDialogMessage === "請先登入") {
                if (process.env.REACT_APP_CLIENT_ID === "hkpl") {
                  window.parent.parent.location = PATH.HKPL_SSO_PATH;
                } else {
                  navigate(PATH.LOGIN_PATH);
                }
              }
            }}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      </DialogBox>
      <DialogBox
        styleType={PATH.STYLETYPE_SECOND}
        showDialog={showBookIsOpenDialog}
        p1={<Trans>isOpenBookMessage</Trans>}
        title={<Trans>SystemMessage</Trans>}
        scroll={false}
      >
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"submitButton"}
            callBack={() => setShowBookIsOpenDialog(false)}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      </DialogBox>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    borrowBookList: state.myBorrowBookList,
    reserveBookList: state.myReserveBookList,
    auth: state.login.auth,
    bookMarkList: state.myBookMarkList,
    reader: state.reader,
    userInfo: state.userInfo.userInfo,
  };
};

const actionCreator = {
  myBorrowBookList,
  myReserveBookList,
  myBookMarkList,
  handleReaderMode,
  handleUserInfo,
};
export default connect(mapStateToProps, actionCreator)(BookView);
