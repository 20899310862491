/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface OrganizationMapping
 */
export interface OrganizationMapping {
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMapping
     */
    mainOrganizationByMainOrganizationId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMapping
     */
    mainOrganizationId?: number;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMapping
     */
    subOrganizationBySubOrganizationId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMapping
     */
    subOrganizationId?: number;
}

export function OrganizationMappingFromJSON(json: any): OrganizationMapping {
    return OrganizationMappingFromJSONTyped(json, false);
}

export function OrganizationMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainOrganizationByMainOrganizationId': !exists(json, 'mainOrganizationByMainOrganizationId') ? undefined : OrganizationFromJSON(json['mainOrganizationByMainOrganizationId']),
        'mainOrganizationId': !exists(json, 'mainOrganizationId') ? undefined : json['mainOrganizationId'],
        'subOrganizationBySubOrganizationId': !exists(json, 'subOrganizationBySubOrganizationId') ? undefined : OrganizationFromJSON(json['subOrganizationBySubOrganizationId']),
        'subOrganizationId': !exists(json, 'subOrganizationId') ? undefined : json['subOrganizationId'],
    };
}

export function OrganizationMappingToJSON(value?: OrganizationMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mainOrganizationByMainOrganizationId': OrganizationToJSON(value.mainOrganizationByMainOrganizationId),
        'mainOrganizationId': value.mainOrganizationId,
        'subOrganizationBySubOrganizationId': OrganizationToJSON(value.subOrganizationBySubOrganizationId),
        'subOrganizationId': value.subOrganizationId,
    };
}

