import PageSettingComponents from "../components/Page/PageSettingComponents";
import { AudioReader } from "../containers/Reader/audioReader/AudioReader";
import ActivityRecord from "../layout/Admin/ActivityRecord/ActivityRecord";
import AdminView from "../layout/Admin/AdminView"; //"../layout/Admin/AdminView";
import BatchView from "../layout/Admin/Batch/BatchView";
import BannerManagementView from "../layout/Admin/Marketing/BannerManagementView";
import BannerView from "../layout/Admin/Marketing/BannerView";
import MarketingEventManagementView from "../layout/Admin/Marketing/MarketingEventManagementView";
import MarketingEventView from "../layout/Admin/Marketing/MarketingEventView";
import BookListByCategory from "../layout/Admin/PlatformManagement/BookListByCategory";
import BookList from "../layout/Admin/PlatformManagement/BookListView";
import PageSettingView from "../layout/Admin/PlatformManagement/PageSettingView";
import Purchased from "../layout/Admin/PlatformManagement/Purchased";
import BorrowReport from "../layout/Admin/Report/BorrowReport";
import CounterReport from "../layout/Admin/Report/CounterReport";
import BookReport from "../layout/Admin/Report/BookReport";
import StudentView from "../layout/Admin/StudentView";
import UsersView from "../layout/Admin/Users/UsersView";
import BookDetail from "../layout/BookDetail/BookDetail";
import CategoryView from "../layout/Category/CategoryView";
import ErrorPage from "../layout/ErrorPage/ErrorPage";
import Home from "../layout/Home/Home";
import Layout from "../layout/Layout/Layout";
import Login from "../layout/Login/Login";
import MyBookShelf from "../layout/MyBookShelf/MyBookShelf";
import SsoRedriectView from "../layout/openId/OpenIdRedirectView";
import PageContent from "../layout/PageContent/PageContent";
import EpubReader from "../layout/reader/epubPage/component";
import PdfReader from "../layout/reader/pdfReader/component";
import Search from "../layout/Search/Search";
import SearchResult from "../layout/Search/SearchResult";
import SelectOrgs from "../layout/SelectOrgs/SelectOrgs";
import LendSetting from "../layout/Setting/LendSetting/LendSetting";
import ReservingReport from "../layout/Admin/Report/ReservingReport";
import * as PATH from "../utils/Contants";
import CategoryReport from "../layout/Admin/Report/CategortReport";
import BorrowReadReport from "../layout/Admin/Report/BorrowReadReport";
import { VideoReader } from "../containers/Reader/videoReader/VideoReader";
import WhiteListSetting from "../layout/Admin/WhiteListSetting/WhiteListSetting";

export const routes = () => [
  {
    path: "*",
    element: <ErrorPage />,
    status: 404,
  },
  {
    path: PATH.Test_PATH,
    element: <Layout />,
    children: [{ index: true, element: <Purchased /> }],
  },
  {
    path: PATH.LOGIN_PATH,
    element: <Layout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: PATH.HOME_PATH,
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "product/:id", element: <BookDetail /> },
      { path: "category/:id", element: <CategoryView /> },
      { path: PATH.ABOUTS, element: <PageContent /> },
      { path: PATH.MANUAL, element: <PageContent /> },
      { path: PATH.APPDOWNLOAD, element: <PageContent /> },
      { path: PATH.THEMATIC, element: <PageContent /> },
      { path: PATH.INFORMATION, element: <PageContent /> },
      { path: PATH.POLICY, element: <PageContent /> },
      { path: PATH.ACTIVITY, element: <PageContent /> },
      { path: PATH.NEWS, element: <PageContent /> },
      { path: PATH.MEDIA, element: <PageContent /> },
      { path: PATH.FBCLUB, element: <PageContent /> },
      { path: PATH.APPNEWS, element: <PageContent /> },
      { path: PATH.SEARCH_PATH, element: <Search /> },
      { path: PATH.SEARCH_RESULT_PATH, element: <SearchResult /> },
      { path: PATH.ADMIN, element: <AdminView /> },
      { path: PATH.PAGE_SETTING, element: <PageSettingView /> },
      { path: PATH.BOOKLIST, element: <BookList /> },
      { path: PATH.PURCHASED, element: <Purchased /> },
      {
        path: PATH.STUDENT,
        element: <StudentView />,
        children: [
          {
            index: true,
            element: <Purchased />,
          },
        ],
      },

      { path: PATH.READREPORT, element: <CounterReport /> },
      { path: PATH.BORROWREPORT, element: <BorrowReport /> },
      { path: PATH.MYBOOKSHELF, element: <MyBookShelf /> },
      { path: PATH.BOOKREPORT, element: <BookReport /> },
      { path: "/whiteListSetting", element: <WhiteListSetting /> },
      { path: PATH.CATEGORYREPORT, element: <CategoryReport /> },
      { path: PATH.RESERVINGREPORT, element: <ReservingReport /> },
      { path: PATH.BORROWREADREPORT, element: <BorrowReadReport /> },
      { path: "/admin/booklist/category/:id", element: <BookListByCategory /> },
      //#region TODO getApiRoute PageSettingControllerApi
      {
        path: "/thematic",
        element: <PageSettingComponents />,
      },
      {
        path: "/news",
        element: <PageSettingComponents />,
      },
      {
        path: "/app_download",
        element: <PageSettingComponents />,
      },
      {
        path: "/manual",
        element: <PageSettingComponents />,
      },
      {
        path: "/activity",
        element: <PageSettingComponents />,
      },
      {
        path: "/app_news",
        element: <PageSettingComponents />,
      },
      {
        path: "/abouts",
        element: <PageSettingComponents />,
      },
      {
        path: "/policy",
        element: <PageSettingComponents />,
      },
      {
        path: "/media",
        element: <PageSettingComponents />,
      },
      {
        path: "/fbclub",
        element: <PageSettingComponents />,
      },
      {
        path: "/information",
        element: <PageSettingComponents />,
      },
      //#endregion
      {
        path: PATH.LENDSETTING,
        element: <LendSetting />,
      },
      {
        path: PATH.ACTIVITYRECORD,
        element: <ActivityRecord />,
      },
      {
        path: PATH.ALL_BATCH,
        element: <BatchView />,
      },
      {
        path: PATH.USERSETTING,

        element: <UsersView />,
      },
      {
        path: PATH.BANNER,

        element: <BannerView />,
      },
      {
        path: PATH.MARKETING_EVENT_MANAGEMENT,
        element: <MarketingEventManagementView />,
      },
      {
        path: PATH.MARKETING_EVENT,
        element: <MarketingEventView />,
      },
      {
        path: PATH.BANNER_MANAGEMENT,
        element: <BannerManagementView />,
      },
    ],
  },
  {
    path: "/epub/:id",
    element: <EpubReader />,
  },
  {
    path: "/pdf/:id",
    element: <PdfReader />,
  },
  {
    path: "/library-sso/endpoint",
    element: <SsoRedriectView />,
  },
  {
    path: "/selectOrg",
    element: <SelectOrgs />,
  },
  {
    path: "/audioreader/:id",
    element: <AudioReader />,
  },
  {
    path: "/videoreader/:id",
    element: <VideoReader />,
  },
];
