import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/images/btn/backWithText.png"; //"../../../assets/images/btn/backWithText.png";
import style from "./BackButton.module.css";

export interface IBackButtonProps {
  myLink?: string;
}

const BackButton = (props: IBackButtonProps) => {
  const navigate = useNavigate();

  const goback = () => {
    if (Object.keys(props).length === 0) {
      navigate.length == 1 ? navigate("/") : navigate(-1);
      //   history.push("/") : history.goBack();
    } else {
      navigate(props.myLink!);
      //   history.push(props.myLink);
    }
  };
  return (
    <div className={style.backButtonContainer} onClick={() => goback()}>
      <img src={backIcon} />
    </div>
  );
};
export default BackButton;
