/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';

/**
 * 
 * @export
 * @interface MemberLevelDto
 */
export interface MemberLevelDto {
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof MemberLevelDto
     */
    members?: Array<MemberDto>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    order?: string;
}

export function MemberLevelDtoFromJSON(json: any): MemberLevelDto {
    return MemberLevelDtoFromJSONTyped(json, false);
}

export function MemberLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(MemberDtoFromJSON)),
        'membershipName': !exists(json, 'membershipName') ? undefined : json['membershipName'],
        'membershipPeriod': !exists(json, 'membershipPeriod') ? undefined : json['membershipPeriod'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function MemberLevelDtoToJSON(value?: MemberLevelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberLevelId': value.memberLevelId,
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(MemberDtoToJSON)),
        'membershipName': value.membershipName,
        'membershipPeriod': value.membershipPeriod,
        'order': value.order,
    };
}

