/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MembershipRule
 */
export interface MembershipRule {
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    cashRequire?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MembershipRule
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    memberLevelId?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    membershipRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipRule
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    pointRequire?: number;
}

export function MembershipRuleFromJSON(json: any): MembershipRule {
    return MembershipRuleFromJSONTyped(json, false);
}

export function MembershipRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cashRequire': !exists(json, 'cashRequire') ? undefined : json['cashRequire'],
        'memberLevelByMemberLevelId': !exists(json, 'memberLevelByMemberLevelId') ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': !exists(json, 'memberLevelId') ? undefined : json['memberLevelId'],
        'membershipRuleId': !exists(json, 'membershipRuleId') ? undefined : json['membershipRuleId'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'pointRequire': !exists(json, 'pointRequire') ? undefined : json['pointRequire'],
    };
}

export function MembershipRuleToJSON(value?: MembershipRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cashRequire': value.cashRequire,
        'memberLevelByMemberLevelId': MemberLevelToJSON(value.memberLevelByMemberLevelId),
        'memberLevelId': value.memberLevelId,
        'membershipRuleId': value.membershipRuleId,
        'period': value.period,
        'pointRequire': value.pointRequire,
    };
}

