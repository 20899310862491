import { MAX_REQUEST_NUM, MAX_RETRY_NUM } from "../Contants";
import { fetchMD5 } from "../reader/fileUtils/md5Util";
import { ajax, UploadFile, VERIFY_FILE_API } from "./FileRequest";
import { createFileChunk } from "./FileUtil";

export default class Uploader {
  constructor(url, options) {
    this.uploadUrl = url;
    this.showProgress = options?.showProgress;
    this.customProgressHandler = null;
    this.enableSlice = options?.enableSlice || false;
    this.length = 0;
    this.requestList = [];
    this.chunkList = [];
    this.customCompleteHandler = null;
    this.customCatchHandler = null;
    this.uploadTime = null;
    this.calHashTime = null;
    this.dbFileId = null;
    this.bookIsbn = options?.bookIsbn;
    this.bookType = options?.bookType;
    this.bookInfo = options?.bookInfo;
    this.md5 = options?.md5;
    this.hash = null;
  }
  onProgress = (fn) => {
    this.customProgressHandler = fn;

    return this;
  };

  onComplete = (fn) => {
    this.customCompleteHandler = fn;
    return this;
  };

  onCatch = (fn) => {
    this.customCatchHandler = fn;
    return this;
  };

  calcProcessInSliceMode = () => {
    let loadedSum = 0;
    this.chunkList.forEach(({ progress = null }) => {
      if (progress) {
        loadedSum += progress.loaded;
      }
    });

    if (this.customProgressHandler) {
      this.customProgressHandler({
        percentage: parseInt(String((loadedSum / this.length) * 100)),
        loaded: loadedSum,
        total: this.length,
        progressDetail: this.chunkList,
      });
    }
  };

  onProgressHandler = (chunkFile, e) => {
    if (this.showProgress && this.customProgressHandler) {
      chunkFile.progress = {
        percentage: parseInt(String((e.loaded / e.total) * 100)),
        loaded: e.loaded,
        total: e.total,
      };

      if (this.enableSlice) {
        this.calcProcessInSliceMode();
      } else {
        this.customProgressHandler(chunkFile.progress);
      }
    }
  };

  verifyHash(fileSize, contentHash) {
    const formData = new FormData();
    formData.append("bookIsbn", this.bookIsbn);
    formData.append("bookType", this.bookType);
    formData.append("bookInfo", this.bookInfo);
    formData.append("fileSize", fileSize);
    formData.append("contentHash", contentHash);
    formData.append("md5", this.md5);
    return ajax({
      url: VERIFY_FILE_API,
      data: formData,
    });
  }

  pause = () => {
    this.requestList.forEach((xhr) => xhr?.abort());
    this.requestList = [];
  };

  markAsSuccess = (fileChunk) => {
    fileChunk.status = "SUCCESS";
    const chunkSize = fileChunk.chunk.size;
    fileChunk.progress = {
      percentage: 100,
      loaded: chunkSize,
      total: chunkSize,
    };
  };

  markAsReady = (fileChunk) => {
    fileChunk.status = "READY";

    if (fileChunk.progress) {
      fileChunk.progress.loaded = 0;
      fileChunk.progress.percentage = 0;
    }
  };

  secondUpload = (file, response) => {
    const { size } = file;
    if (this.showProgress && this.customProgressHandler) {
      this.chunkList.forEach((fileChunk) => {
        this.markAsSuccess(fileChunk);
      });

      this.customProgressHandler({
        percentage: 100,
        loaded: size,
        total: size,
        progressDetail: this.chunkList,
        response: response,
      });
    }

    if (this.customCompleteHandler) {
      this.customCompleteHandler({
        uploadTime: 0,
        hashTime: this.calHashTime,
        response: response,
      });
    }
  };

  requestWithLimit = (
    fileChunkList,
    max = MAX_REQUEST_NUM,
    retry = MAX_RETRY_NUM
  ) => {
    return new Promise((resolve, reject) => {
      const requestNum = fileChunkList.filter((fileChunk) => {
        return fileChunk.status === "ERROR" || fileChunk.status === "READY";
      }).length;
      let counter = 0;
      const retryArr = [];
      const request = () => {
        while (counter < requestNum && max > 0) {
          max--;
          const fileChunk = fileChunkList.find((chunk) => {
            return chunk.status === "ERROR" || chunk.status === "READY";
          });

          if (!fileChunk) {
            return;
          }

          const formData = fileChunk.toFormData();
          fileChunk.status = "UPLOADING";

          UploadFile({
            method: "POST",
            url: this.uploadUrl,
            data: formData,
            onProgress: this.onProgressHandler.bind(this, fileChunk),
            requestList: this.requestList,
          })
            .then((res) => {
              this.customCompleteHandler({ response: res });
              fileChunk.status = "SUCCESS";
              max++;
              counter++;
              if (counter === requestNum) {
                resolve(res);
              } else {
                request();
              }
            })
            .catch((e) => {
              if (e === "Cancel") {
                this.markAsReady(fileChunk);
                this.calcProcessInSliceMode();
              } else {
                fileChunk.status = "ERROR";
                if (typeof retryArr[fileChunk.index] !== "number") {
                  retryArr[fileChunk.index] = 0;
                }
                retryArr[fileChunk.index]++;
                if (retryArr[fileChunk.index] >= retry) {
                  return reject();
                }
                fileChunk.progress = {};
                max++;

                request();
              }
            });
        }
      };

      request();
    });
  };

  uploadFile = (file) => {
    const formData = new FormData();
    const startTime = new Date().getTime();

    formData.append("file", file);

    UploadFile({
      method: "POST",
      url: this.uploadUrl,
      data: formData,
      onProgress: this.onProgressHandler.bind(this, file),
    })
      .then((res) => {
        //this.customCompleteHandler({ response: res });
        const endTime = new Date().getTime();
        this.uploadTime = parseInt(String((endTime - startTime) / 10)) / 100;
        if (this.customCompleteHandler) {
          this.customCompleteHandler({
            uploadTime: this.uploadTime,
            hashTime: this.calHashTime,
            response: res,
          });
        }
      })
      .catch((e) => {
        try {
          let errorJSON = JSON.parse(e.res);
          this.customCatchHandler({
            response: errorJSON,
          });
        } catch (ex) {
          console.log("e", ex);
        }
      });
  };

  uploadChunks = (fileChunkList) => {
    const startTime = new Date().getTime();

    this.requestWithLimit(fileChunkList)
      .then((res) => {
        const endTime = new Date().getTime();
        this.uploadTime = parseInt(String((endTime - startTime) / 10)) / 100;
        if (this.customCompleteHandler) {
          this.customCompleteHandler({
            uploadTime: this.uploadTime,
            hashTime: this.calHashTime,
            response: res,
            // progressDetail: this.chunkList[0],
            // fileId: res,
          });
        }
      })
      .catch(() => {
        this.pause();
      });
  };

  proceed = () => {
    this.uploadChunks(this.chunkList);
    this.calcProcessInSliceMode();
  };

  upload = (file) => {
    this.length = file.size;
    if (this.enableSlice) {
      const chunkList = createFileChunk(
        file,
        this.bookIsbn,
        this.bookType,
        this.md5
      );

      this.chunkList = chunkList;
      const hashStartTime = new Date().getTime();
      //calculateFileHash(chunkList).then((hash) => {
      fetchMD5(file).then((hash) => {
        const hashEndTime = new Date().getTime();
        this.calHashTime =
          parseInt(String((hashEndTime - hashStartTime) / 10)) / 100;
        this.verifyHash(file.size, hash).then((res) => {
          //console.log(res)
          const response = JSON.parse(res);
          const { uploaded, uploadedChunkList } = response;

          if (uploaded) {
            this.secondUpload(file, response);
          } else {
            this.chunkList.forEach((chunk) => {
              chunk.fileHash = hash;
              uploadedChunkList.forEach((uploadedChunk) => {
                if (uploadedChunk.chunkName === chunk.chunkName) {
                  this.markAsSuccess(chunk);
                }
              });
            });
            this.uploadChunks(chunkList);
          }
        });
      });
    } else {
      this.uploadFile(file);
    }

    return this;
  };
}
