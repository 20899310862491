const initState = {
    searchText : ""
  };
  export function searchText(
    state = initState,
    action :any
  ) {
    switch (action.type) {
  
      case "HANDLE_SEARCH_TEXT":
        return {
          ...state,
          searchText: action.payload,
        };
  
      default:
        return state;
    }
  }
  