/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BorrowDto,
    BorrowDtoFromJSON,
    BorrowDtoFromJSONTyped,
    BorrowDtoToJSON,
} from './BorrowDto';

/**
 * 
 * @export
 * @interface ResumeBorrowMissionResponseModel
 */
export interface ResumeBorrowMissionResponseModel {
    /**
     * 
     * @type {BorrowDto}
     * @memberof ResumeBorrowMissionResponseModel
     */
    borrowDto?: BorrowDto;
    /**
     * 
     * @type {string}
     * @memberof ResumeBorrowMissionResponseModel
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResumeBorrowMissionResponseModel
     */
    missionSuccess?: boolean;
}

export function ResumeBorrowMissionResponseModelFromJSON(json: any): ResumeBorrowMissionResponseModel {
    return ResumeBorrowMissionResponseModelFromJSONTyped(json, false);
}

export function ResumeBorrowMissionResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResumeBorrowMissionResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'borrowDto': !exists(json, 'borrowDto') ? undefined : BorrowDtoFromJSON(json['borrowDto']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'missionSuccess': !exists(json, 'missionSuccess') ? undefined : json['missionSuccess'],
    };
}

export function ResumeBorrowMissionResponseModelToJSON(value?: ResumeBorrowMissionResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'borrowDto': BorrowDtoToJSON(value.borrowDto),
        'message': value.message,
        'missionSuccess': value.missionSuccess,
    };
}

