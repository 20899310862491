import React from "react";
import style from "./LoadingView.module.css";
export default function PageLoading() {
    return (
        <div className={style.loading2}>
            <div className={style.uilRingCss2} style={{ transform: "scale(0.79)" }}>
                <div></div>
            </div>
        </div>
    );
}
