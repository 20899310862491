import htmlParse from "html-react-parser";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  GetPageSettingByUrlUsingGETRequest,
  PageSetting,
  PageSettingControllerApi,
} from "../../fetcher";
// import {
//   GetPageSettingByUrlUsingGETRequest,
//   PageSetting,
//   PageSettingControllerApi,
// } from "../../fetcher";
import { genApiConfig } from "../../utils/fetch-caller";
import style from "./PageContent.module.css";

export default function PageContent() {
  const [contentData, setContentData] = useState<PageSetting>();
  const location = useLocation();

  useEffect(() => {
    //if (location.pathname !== "") {
    new PageSettingControllerApi(genApiConfig())
      .getPageSettingByUrlUsingGET({
        url: "/" + location.pathname.split("/")[2],
      } as GetPageSettingByUrlUsingGETRequest)
      .then((res: PageSetting) => {
        setContentData(res);

        console.log("res");
      })
      .catch((err: any) => {
        console.log(err);
      });
    // }
  }, [location]);

  return (
    <div className={style.exContainer}>
      {contentData && htmlParse(contentData?.content!)}
    </div>
  );
}
