import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import loadingGif from "../../assets/svg/loading.gif";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import SelectOption from "../../components/SelectOption/SelectOption";
import CategoryBookCardView from "../../components/TableCardView/Category/CategoryBookCardView";
import { STYLETYPE_MAIN, STYLETYPE_SECOND, STYLETYPE_SEARCH_INPUT, STYLETYPE_SEARCH_INPUT_OPTION, STYLETYPE_DATALIST, STYLETYPE_FOUR } from "../../utils/Contants";
import useWindowSize from "../../utils/useWindowSize";
import style from "./CategoryView.module.css";
import { Trans, useTranslation } from "react-i18next";
import { getArgsQuery } from "../../utils/GlobalFunction";
import PageLoading from "../loading/PageLoading";
import {
    CategoryControllerApi,
    GetPagedProductByCategoryIdExtraUsingGETRequest,
    ProductControllerApi
} from "../../fetcher";
import { genApiConfig } from "../../utils/fetch-caller";

export default function CategoryView() {
    const params = useParams();
    const [apiData, setApiData] = useState<any>([]);
    const [displayBookData, setDisplayBookData] = useState<any>([]);
    const { width, height } = useWindowSize();
    const [keyword, setKeyword] = useState("");
    const [years, setYears] = useState<any>([]);
    const [inputOption, setInputOption] = useState("all");
    const [fromYear, setFromYear] = useState<any>([]);
    const [toYear, setToYear] = useState<any>([]);
    const [organization, setOrganization] = useState<any>([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [publishHouse, setPublishHouse] = useState([]);
    const [year, setYear] = useState([]);
    const [publisher, setPublisher] = useState<any>([]);
    const [test, setTest] = useState<any>([]);
    const { t, i18n } = useTranslation();
    const OpenCC = require("opencc-js");
    const converter = OpenCC.Converter({ from: "hk", to: "cn" });
    const [page, setPage] = useState({});
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageloading, setPageLoading] = useState(false);
    let selOptPublishingYearElement = document.getElementById("selOptPublishingYear");

    let selOptOrganizationElement = document.getElementById("selOptOrganization");
    const rootElement = document.getElementById("root");

    let paramsId = params.id?.toString().split("&");

    const [paramsIdState, setParamsIdState] = useState<string[]>(paramsId ?? []);

    const [isCollectionCategory, setIsCollectionCategory] = useState<boolean>(false);
    const [collectionCategoryBanner, setCollectionCategoryBanner] = useState<string>("");

    //fetch
    useEffect(() => {
        getapiBookdata();
        // api.getCategoryById(paramsId![0]).then((data) => setApiData(data));
        api.getAllOrganization().then((data) => setOrganization(data));
        rootElement && rootElement.scrollTo(0, 0);
        const handleKeyup = (e: any) => {
            // console.log(e);
            if (e.keyCode === 13) {
                // console.log("navigate");
                handleNavigate();
            }
        };

        document.getElementById("InputKeyword")?.addEventListener("keyup", handleKeyup);
        return () => document.getElementById("InputKeyword")?.removeEventListener("keyup", handleKeyup);
        // api.postBorrowBook(1,1).then(data=>console.log(data))
    }, [params.id]);

    useEffect(() => {
        console.log("api", apiData);
        var now = new Date();
        let arr = [];
        setDisplayBookData(apiData.list);
        setPageCount(apiData.pagenum);
        setPublishHouse(
            [].concat.apply(
                [],
                organization.map((org: any) => org.subOrganization)
            )
        );
        for (var i = 1900; i <= now.getFullYear(); i++) {
            arr.push({ id: i, name: i.toString() });
        }
        setYears(arr.reverse());

        console.log("displayBookData", displayBookData);
        console.log("api data", apiData);
    }, [apiData, organization]);

    useEffect(() => {
        getapiBookdata();
    }, [currentPage]);

    const getapiBookdata = () => {

        try {
            new CategoryControllerApi().getCategoryByCategoryIdUsingGET({
                categoryId: Number(paramsId![0])
            }).then(category => {
                if(category.categoryName){
                    setParamsIdState([
                        paramsId![0],
                        category.categoryName
                    ])
                }

                if(category.isCollection){
                    setIsCollectionCategory(category.isCollection)
                    setCollectionCategoryBanner(category.bannerImage !== undefined ? category.bannerImage : "")
                } else {
                    setIsCollectionCategory(false)
                    setCollectionCategoryBanner("")
                }
            })
        }catch (e) {
            console.log("paramsId[0] is not a number", paramsIdState)
        }

        setPageLoading(true);
        let urlQueryResult = getArgsQuery();
        urlQueryResult.categoryId = paramsId![0].toString();
        new ProductControllerApi(genApiConfig())
            .getPagedProductByCategoryIdExtraUsingGET({
                categoryId: +urlQueryResult.categoryId,
                page: currentPage,
                size: 20,
            } as GetPagedProductByCategoryIdExtraUsingGETRequest)
            .then((res: any) => {
                setApiData(res);
                setPageLoading(false);
            })
          .finally(() => {
              // set time out
              setTimeout(async () => {
                  if(document.getElementById("content")){
                      document.getElementById("content")!.style.height = "100%";
                  }
              }, 1000)
          });


    };

    // TODO update onClick
    const handleSearchClick = () => {
        let p: GetPagedProductByCategoryIdExtraUsingGETRequest = {
            categoryId: +paramsId![0].toString(),
            page: currentPage,
            size: 20,
        };

        console.log("from year", fromYear);
        console.log("to year", toYear);
        console.log("publisher", publisher);
        console.log("inputOption", inputOption);
        console.log("keyword", keyword);

        if (keyword !== "" && (inputOption === "author" || inputOption === "bookName" || inputOption === "bookDescription")) {
            // p["keyword"] = keyword;
            console.log("keyword", keyword);
            console.log("inputOption", inputOption);
            p[inputOption] = keyword;
        } else if (keyword !== "" && inputOption === "all") {
            p["author"] = keyword;
            p["bookName"] = keyword;
            p["bookDescription"] = keyword;
        }
        if (fromYear && fromYear.id) {
            p["fromYear"] = fromYear.id.toString();
        }
        if (toYear && toYear.id) {
            p["toYear"] = toYear.id.toString();
        }
        if (publisher && publisher.organizationId) {
            p["organizationId"] = publisher.organizationId.toString();
        }

        new ProductControllerApi(genApiConfig()).getPagedProductByCategoryIdExtraUsingGET(p).then((res: any) => {
            setApiData(res);
        });
        // console.log("paramStr", paramStr);
        console.log("p", p);
        // fetch(url)
        //   .then((response) => response.json())
        //   .then((data) => setApiData(data));
    };

    const handleClearAll = (keyword: string, year: any, publisher: any) => {
        setKeyword("");

        // setYear();
        setYear([]);
        // if (selOptPublishingYearElement) {
        //   console.log("selOptPublishingYearElement", selOptPublishingYearElement);
        //   //selOptPublishingYearElement.selectedIndex = 0;
        // }
        // setPublisher();
        setPublisher([]);
        if (selOptOrganizationElement) {
            //selOptOrganizationElement.selectedIndex = 0;
        }
    };

    const handleNavigate = () => {
        //navigate(ToParams());
        document.getElementById("submit_btn2")?.click();
    };
    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleSelectPage = (value: any) => {
        setCurrentPage(parseInt(value.id));
    };

    return (
        <>
            {/* <div className={style.Header}>
        <div>
          <span className={style.open_Nav}>☰</span>
        </div>
        <div className={style.category}>
          <div className={style.categoryButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => console.log(1)}
            >
              全部分類
            </Button>
          </div>
          <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
          <div className={style.categoryButtonContainer}>
            <Button
              styleType={"upperNavbarButtoncpse"}
              callBack={() => console.log(1)}
            >
              中二必讀
            </Button>
          </div>
        </div>
      </div> */}

            <div className={style.pageContentLayout}>
                {!isCollectionCategory ? (
                  <>
                    {/* region search bar */}
                      <div className={width < 768 ? style.selectOptionsRow2 : style.selectOptionsRow}>
                          <div className={style.InputKeyword}>
                              <SelectOption placeholder={t("InputKeyword")} value={keyword} styleType={STYLETYPE_SEARCH_INPUT} callBack={(value) => setKeyword(value)} />
                          </div>

                          <div className={style.inputOption}>
                              <SelectOption placeholder="InputOption" firstValue="pleasechoose" styleType={STYLETYPE_SEARCH_INPUT_OPTION} callBack={(value) => setInputOption(value)} />
                          </div>

                          <div className={style.selectMarginLong}>
                              <SelectOption
                                placeholder={t("SearchPublisherKeyword")}
                                firstValue="pleasechoose"
                                items={publishHouse}
                                styleType={STYLETYPE_DATALIST}
                                callBack={(value) => setPublisher(value)}
                              />
                          </div>

                          <div className={style.selectMargin}>
                              <SelectOption
                                placeholder="PublishingYearFrom"
                                firstValue="pleasechoose"
                                items={years}
                                styleType={STYLETYPE_SECOND}
                                callBack={(value) => setFromYear(value)}
                              />
                          </div>
                          <div className={style.selectMargin}>
                              <SelectOption placeholder="PublishingYearTo" firstValue="pleasechoose" items={years} styleType={STYLETYPE_SECOND} callBack={(value) => setToYear(value)} />
                          </div>

                          <div className={style.searchButtonContainer}>
                              <Button id={"submit_btn2"} styleType={STYLETYPE_MAIN} callBack={(value) => handleSearchClick()} disabled={isSubmit}>
                                  {isSubmit ? "" : <Trans>Search</Trans>}
                                  <img
                                    src={loadingGif}
                                    style={{
                                        margin: "0 auto",
                                        width: "30px",
                                        height: "30px",
                                        display: isSubmit ? "block" : "none",
                                    }}
                                  />
                              </Button>
                              {/* <Button styleType={STYLETYPE_MAIN} callBack={(value) => handleClearAll(keyword, year, publisher)}>
                            <Trans>clearSearch</Trans>
                        </Button> */}
                          </div>
                      </div>
                    {/* endregion */}
                  </>
                ) : (
                  <>
                      {/* region collection category banner */}
                      <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                      >
                          <img
                            alt={paramsIdState![1]}
                            src={api.getImageUrl(collectionCategoryBanner)}
                            style={{
                                width: "auto",
                                maxWidth: "100%",
                                objectFit: "contain",
                                maxHeight: "300px"
                            }}
                          />
                      </div>
                      {/* endregion */}
                  </>
                )}
                {/* region search result */}
                <div className={style.tableItemListContainer}>
                    <div className={style.tableListItemGridView}>
                        <div className={style.titleRow}>{i18n.language === "cn" ? converter(paramsIdState![1]) : paramsIdState![1]}</div>

                        {pageloading ? (
                            <>
                                <PageLoading />
                            </>
                        ) : (
                            <div className={style.content} id={"content"}>
                                {displayBookData && displayBookData.length > 0 ? (
                                    displayBookData.map((item: any, idx: any) => <CategoryBookCardView props={item} key={idx} />)
                                ) : (
                                    <>
                                        <Trans>NoInformation</Trans>
                                    </>
                                )}
                            </div>
                        )}
                        <div className={style.tableItemListContainer_bottom}>
                            {displayBookData && displayBookData.length > 0 ? (
                                <div className={style.page_row}>
                                    <div className={style.page_item} onClick={() => handleFirstPage()}>
                                        首頁
                                    </div>
                                    <div className={style.border_line}></div>
                                    <div className={style.page_item} onClick={() => handlePrevPage()}>
                                        上一頁
                                    </div>
                                    <div className={style.border_line}></div>
                                    <div className={style.page}>
                                        <SelectOption
                                            styleType={STYLETYPE_FOUR}
                                            value={currentPage.toString()}
                                            firstValue={""}
                                            items={handlepagecount()}
                                            callBack={(value) => handleSelectPage(value)}
                                        ></SelectOption>
                                        <div style={{ marginLeft: "7px" }}>/ {pageCount}</div>
                                    </div>
                                    <div className={style.border_line}></div>
                                    <div className={style.page_item} onClick={() => handleNextPage()}>
                                        下一頁
                                    </div>
                                    <div className={style.border_line}></div>
                                    <div className={style.page_item} onClick={() => handleLastPage()}>
                                        尾頁
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                {/* endregion */}
            </div>
        </>
    );
}
