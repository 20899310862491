/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import {
    OrderProductDiscount,
    OrderProductDiscountFromJSON,
    OrderProductDiscountFromJSONTyped,
    OrderProductDiscountToJSON,
} from './OrderProductDiscount';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import {
    ProductPoint,
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';

/**
 * 
 * @export
 * @interface OrderProduct
 */
export interface OrderProduct {
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    finalPrice?: number;
    /**
     * 
     * @type {Order}
     * @memberof OrderProduct
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    orderId?: number;
    /**
     * 
     * @type {Array<OrderProductDiscount>}
     * @memberof OrderProduct
     */
    orderProductDiscountsByOrderProductId?: Array<OrderProductDiscount>;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    orderProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof OrderProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productId?: number;
    /**
     * 
     * @type {ProductPoint}
     * @memberof OrderProduct
     */
    productPointByProductPointId?: ProductPoint;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    usedPoint?: number;
}

export function OrderProductFromJSON(json: any): OrderProduct {
    return OrderProductFromJSONTyped(json, false);
}

export function OrderProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryTimes': !exists(json, 'deliveryTimes') ? undefined : json['deliveryTimes'],
        'finalPrice': !exists(json, 'finalPrice') ? undefined : json['finalPrice'],
        'orderByOrderId': !exists(json, 'orderByOrderId') ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': !exists(json, 'orderId') ? undefined : json['orderId'],
        'orderProductDiscountsByOrderProductId': !exists(json, 'orderProductDiscountsByOrderProductId') ? undefined : ((json['orderProductDiscountsByOrderProductId'] as Array<any>).map(OrderProductDiscountFromJSON)),
        'orderProductId': !exists(json, 'orderProductId') ? undefined : json['orderProductId'],
        'productByProductId': !exists(json, 'productByProductId') ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productPointByProductPointId': !exists(json, 'productPointByProductPointId') ? undefined : ProductPointFromJSON(json['productPointByProductPointId']),
        'productPointId': !exists(json, 'productPointId') ? undefined : json['productPointId'],
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'usedPoint': !exists(json, 'usedPoint') ? undefined : json['usedPoint'],
    };
}

export function OrderProductToJSON(value?: OrderProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryTimes': value.deliveryTimes,
        'finalPrice': value.finalPrice,
        'orderByOrderId': OrderToJSON(value.orderByOrderId),
        'orderId': value.orderId,
        'orderProductDiscountsByOrderProductId': value.orderProductDiscountsByOrderProductId === undefined ? undefined : ((value.orderProductDiscountsByOrderProductId as Array<any>).map(OrderProductDiscountToJSON)),
        'orderProductId': value.orderProductId,
        'productByProductId': ProductToJSON(value.productByProductId),
        'productId': value.productId,
        'productPointByProductPointId': ProductPointToJSON(value.productPointByProductPointId),
        'productPointId': value.productPointId,
        'productPrice': value.productPrice,
        'quantity': value.quantity,
        'usedPoint': value.usedPoint,
    };
}

