/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryParentConflictPayload,
    CategoryParentConflictPayloadFromJSON,
    CategoryParentConflictPayloadFromJSONTyped,
    CategoryParentConflictPayloadToJSON,
} from './CategoryParentConflictPayload';

/**
 * 
 * @export
 * @interface CategoryParentConflictPayloadList
 */
export interface CategoryParentConflictPayloadList {
    /**
     * 
     * @type {Array<CategoryParentConflictPayload>}
     * @memberof CategoryParentConflictPayloadList
     */
    categoryParentConflictPayloadList?: Array<CategoryParentConflictPayload>;
}

export function CategoryParentConflictPayloadListFromJSON(json: any): CategoryParentConflictPayloadList {
    return CategoryParentConflictPayloadListFromJSONTyped(json, false);
}

export function CategoryParentConflictPayloadListFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryParentConflictPayloadList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryParentConflictPayloadList': !exists(json, 'categoryParentConflictPayloadList') ? undefined : ((json['categoryParentConflictPayloadList'] as Array<any>).map(CategoryParentConflictPayloadFromJSON)),
    };
}

export function CategoryParentConflictPayloadListToJSON(value?: CategoryParentConflictPayloadList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryParentConflictPayloadList': value.categoryParentConflictPayloadList === undefined ? undefined : ((value.categoryParentConflictPayloadList as Array<any>).map(CategoryParentConflictPayloadToJSON)),
    };
}

