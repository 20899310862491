/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface PointRuleDto
 */
export interface PointRuleDto {
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    appliedMemberLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    appliedProduct?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    cashForOnePoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof PointRuleDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    isBirthday?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    oneCashToPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    pointRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    pointRuleName?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    pointToOneCash?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof PointRuleDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {Date}
     * @memberof PointRuleDto
     */
    startDate?: Date;
}

export function PointRuleDtoFromJSON(json: any): PointRuleDto {
    return PointRuleDtoFromJSONTyped(json, false);
}

export function PointRuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointRuleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appliedMemberLevel': !exists(json, 'appliedMemberLevel') ? undefined : json['appliedMemberLevel'],
        'appliedProduct': !exists(json, 'appliedProduct') ? undefined : json['appliedProduct'],
        'cashForOnePoint': !exists(json, 'cashForOnePoint') ? undefined : json['cashForOnePoint'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'isBirthday': !exists(json, 'isBirthday') ? undefined : json['isBirthday'],
        'oneCashToPoint': !exists(json, 'oneCashToPoint') ? undefined : json['oneCashToPoint'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'pointRuleId': !exists(json, 'pointRuleId') ? undefined : json['pointRuleId'],
        'pointRuleName': !exists(json, 'pointRuleName') ? undefined : json['pointRuleName'],
        'pointToOneCash': !exists(json, 'pointToOneCash') ? undefined : json['pointToOneCash'],
        'product': !exists(json, 'product') ? undefined : ProductDtoFromJSON(json['product']),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
    };
}

export function PointRuleDtoToJSON(value?: PointRuleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appliedMemberLevel': value.appliedMemberLevel,
        'appliedProduct': value.appliedProduct,
        'cashForOnePoint': value.cashForOnePoint,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'isBirthday': value.isBirthday,
        'oneCashToPoint': value.oneCashToPoint,
        'period': value.period,
        'pointRuleId': value.pointRuleId,
        'pointRuleName': value.pointRuleName,
        'pointToOneCash': value.pointToOneCash,
        'product': ProductDtoToJSON(value.product),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
    };
}

