import React from "react";
import { CookiesProvider } from "react-cookie";
import { isElectron } from "react-device-detect";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-tippy/dist/tippy.css";
import App from "./App";
import "./assets/reader/styles/global.css";
import "./assets/reader/styles/style.css";
import { dropdownList } from "./constants/reader/dropdownList";
import { RequiredError } from "./fetcher";
import "./i18n";
import "./index.css";
import store from "./redux/stores";
import StyleUtil from "./utils/reader/readUtils/styleUtil";
import { SocketProvider } from "./utils/socket/SocketContext";

declare let window: any;
//declare var cordova: any;

var sBrowser,
  sUsrAg = navigator.userAgent;

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <CookiesProvider>
            <SocketProvider store={store}>
              <App />
            </SocketProvider>
          </CookiesProvider>
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  if (isElectron) {
    const fontList = window.require("font-list");
    fontList.getFonts({ disableQuoting: true }).then((result: any) => {
      if (!result || result.length === 0) return;
      dropdownList[0].option = result;
      dropdownList[0].option.push("Built-in font");
    });
  }

  var isPhone = false;
  // var isPhone = true;

  if (sUsrAg.includes("suepiosapp")) {
    isPhone = true;
    //sBrowser = "Suep ios app";
    //window.open = window.cordova.InAppBrowser.open;
  } else if (sUsrAg.includes("suepandroidapp")) {
    isPhone = true;
    //sBrowser = "Android";
    //window.open = window.cordova.InAppBrowser.open;
  } else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  localStorage.setItem("isPhone", isPhone.toString());

  //console.log("You are using: " + sBrowser);

  StyleUtil.applyTheme();
};

if (window.cordova) {
  document.addEventListener("deviceready", startApp, false);
} else {
  startApp();
}

// const rootElement = document.getElementById("root");

// const root = createRoot(rootElement!);

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Router>
//         <CookiesProvider>
//           <App />
//         </CookiesProvider>
//       </Router>
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
