/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageDto
 */
export interface LanguageDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    languageId?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    mongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    status?: string;
}

export function LanguageDtoFromJSON(json: any): LanguageDto {
    return LanguageDtoFromJSONTyped(json, false);
}

export function LanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageId': !exists(json, 'languageId') ? undefined : json['languageId'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function LanguageDtoToJSON(value?: LanguageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageId': value.languageId,
        'mongoId': value.mongoId,
        'name': value.name,
        'seq': value.seq,
        'status': value.status,
    };
}

