import React, { useEffect, useMemo, useRef, useState } from "react";
import "./AudioReader.css";
import SampleImage from "./coverImage.PNG";
import previousIcon from "./asset/previous.svg";
import nextIcon from "./asset/next.svg";
import pauseIcon from "./asset/pause.svg";
import playIcon from "./asset/play.svg";
import backwardIcon from "./asset/backward.svg";
import forwardIcon from "./asset/forward.svg";
import waveIcon from "./asset/wave.svg";
import closeIcon from "./asset/close.svg";
import tocIcon from "./asset/menu.svg";
import backIcon from "./asset/left.svg";
import localforage from "localforage";
import api from "../../../api/api";
import * as PATH from "../../../utils/Contants";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import { Trans } from "react-i18next";
import useWindowSize from "../../../utils/useWindowSize";
import parseSRT from 'parse-srt'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import wavePlayingGif from "../../../assets/svg/wave_playing.gif"
import LoadingView from "../../../layout/loading/LoadingView";
import { ReaderLogControllerApi } from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import { getArgsQuery } from "../../../utils/GlobalFunction";


export const flattenChapters = (chaptersList, tocList, level) => {
  if (chaptersList?.length > 0) {
    chaptersList.map((chapter) => {
      tocList.push({
        title: chapter.title,
        audioFilePath: chapter.audioFilePath || null,
        srtFilePath: chapter.srtFilePath || null,
        covers:
          chapter.covers?.sort((a, b) => {
            return a.appearTime - b.appearTime;
          }) || null,
        level: level,
      });
      if (chapter?.sub?.length > 0) {
        flattenChapters(chapter.sub, tocList, level + 1);
      }
    });
  }
  return tocList;
};
// const sampleAudioFiles = [audio1, audio2, audio3, audio4];
const audioSkipSeconds = 15;
export const AudioReader = () => {
  const { width, height } = useWindowSize();
  const [isShowTOC, setIsShowTOC] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [playingTOCIndex, setPlayingTOCIndex] = useState(0);
  const [nextCoverIndex, setNextCoverIndex] = useState(0);
  const [displayingImage, setDisplayingImage] = useState("");
  const [audioBookData, setAudioBookData] = useState({});
  const [updateProgressBarIntervalId, setUpdateProgressBarIntervalId] =
    useState(null);
  const [toc, setTOC] = useState([]);

  const [subTitle, setSubTitle] = useState([])

  const [isLoading, setIsLoading] = useState(true);

  const [isPreview, setIsPreview] = useState(true);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const [progressControlling, setProgressControlling] = useState(false);

  const [progressHover, setProgressHover] = useState(false);
  const [readTime, setReadTime] = useState(0);

  const audioRef = useRef(null);

  const tocButtonOnClick = () => {
    setIsShowTOC(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      backward();
    } else if (e.key === "ArrowRight") {
      fastForward();
    } else if (e.key === "p" || e.key === " ") {
      if (audioRef.current.paused) {
        playOnClick()
      } else {
        pauseOnClick()
      }
    }
    /* else if (e.key === "ArrowUp") {
      if (audioRef.current.muted) audioRef.current.muted = false
      audioRef.current.volume = Math.min(1, audioRef.current.volume + 0.1);
      console.log("audioRef.current.volume", audioRef.current.volume)
    } else if (e.key === "ArrowDown") {
      audioRef.current.volume = Math.max(0, audioRef.current.volume - 0.1);
      console.log("audioRef.current.volume", audioRef.current.volume)
    } else if (e.key === "m") {
      if (audioRef.current.muted) {
        audioRef.current.muted = false
      } else {
        audioRef.current.muted = true
      }
    } else {
      console.log("UnKnown Key Pressed", e.key)
    }*/
  }

  useEffect(() => {
    if (document.getElementById("progressTrigger") && document.getElementById("progressBar")) {
      document.getElementById("progressTrigger").style.display = "block";
      document.getElementById("progressTrigger").style.top = (document.getElementById('progressBar').getBoundingClientRect().top - 5) + 'px';
      document.getElementById("progressTrigger").style.left = (document.getElementById('progressBar').getBoundingClientRect().left - 10) + 'px';
    }

    if (document.getElementById("progressTrigger")) {
      document.getElementById("progressTrigger").style.left = ((document.getElementById('progressBar').getBoundingClientRect().left + document.getElementById("progressBar").offsetWidth * (progressBarValue / 100)) - 10) + 'px';
    }

    if (document.getElementById("progressHoverTime") && document.getElementById("progressTrigger") && document.getElementById("progressBar")) {
      document.getElementById("progressHoverTime").style.top = (document.getElementById('progressTrigger').getBoundingClientRect().top - 25) + 'px';
    }

    document.addEventListener("keydown", handleKeyDown)
    return (() => {
      document.removeEventListener("keydown", handleKeyDown)
    })
  })

  useEffect(() => {
    console.log("playingTOCIndex", playingTOCIndex)
  }, [playingTOCIndex])

  useEffect(() => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "p") {
      setIsPreview(true)
    } else {
      setIsPreview(false)
      let userInfo = localStorage.getItem("userInfo");
      let userInfoObj = JSON.parse(userInfo);
      let bookId = getArgsQuery().bookId
      if (bookId && bookId !== undefined && bookId.length > 0) {
        new ReaderLogControllerApi(genApiConfig())
          .getLogUsingGET({
            memberId: userInfoObj.member.memberId,
            productId: bookId
          }).then(res => {
            let readerlog = JSON.parse(res.content)
            console.log("readerlog", readerlog)
            if (readerlog.currentChapter) {
              console.log("setPlayingTOCIndex(readerlog.currentChapter - 1)")
              setPlayingTOCIndex(readerlog.currentChapter - 1)
              audioRef.current.currentTime = readerlog.currentTime
            }
          })
      }
    }
    /*let audioData = */
    localforage.getItem("audio").then((audioData) => {
      if (window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] !== audioData['bookIsbn']) {
        alert("系統出錯，請再重新開啟書本");
        window.close();
      }
      setAudioBookData(audioData)
      // setIsLoading(false)
    })

    // saveReaderLog()

    const interval = setInterval(() => setReadTime((t) => t + 1), 1000);

    return () => clearInterval(interval);

  }, [])

  useEffect(() => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObj = JSON.parse(userInfo);
    let bookId = getArgsQuery().bookId
    if (!isPreview && readTime % 10 === 0 && bookId && bookId !== undefined && bookId.length > 0 && true) {
      new ReaderLogControllerApi(genApiConfig())
        .saveLogUsingPOST({
          memberId: userInfoObj.member.memberId,
          productId: bookId,
          body: {
            percentage: (playingTOCIndex + 1) / toc.length,
            currentTime: audioRef.current.currentTime,
            currentChapter: playingTOCIndex + 1
          },
        })
      // .then(res => { console.log("saveLogUsingPOST", res) })
    }
  }, [readTime])


  const tocCancelButtonOnClick = () => {
    setIsShowTOC(false);
  };

  const playOnClick = () => {
    audioRef.current.play();
    setIsPlaying(!audioRef.current.paused);
    const intervalId = setInterval(() => {
      setProgressBarValue(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      );
    }, 1000);
    setUpdateProgressBarIntervalId(intervalId);
  };

  const pauseOnClick = () => {
    // ***** console.log(audioRef.current.currentTime);
    setIsPlaying(false);
    audioRef.current.pause();
    clearInterval(updateProgressBarIntervalId);
  };

  const handleAudioEnded = () => {
    setProgressBarValue(100);
    clearInterval(updateProgressBarIntervalId);
    setIsPlaying(false);
  };

  const fastForward = () => {
    if (
      audioRef.current.currentTime + audioSkipSeconds <=
      audioRef.current.duration
    ) {
      audioRef.current.currentTime =
        audioRef.current.currentTime + audioSkipSeconds;
    } else {
      audioRef.current.currentTime = audioRef.current.duration;
    }
    setProgressBarValue(
      (audioRef.current.currentTime / audioRef.current.duration) * 100
    );
  };

  const backward = () => {
    if (audioRef.current.currentTime - audioSkipSeconds >= 0) {
      audioRef.current.currentTime =
        audioRef.current.currentTime - audioSkipSeconds;
    } else {
      audioRef.current.currentTime = 0;
    }
    setProgressBarValue(
      (audioRef.current.currentTime / audioRef.current.duration) * 100
    );
  };

  const getNextTOCAudioIndex = () => {
    if (playingTOCIndex < toc.length - 1) {
      const restOfTOC = toc.slice(playingTOCIndex + 1);
      let nextAudioTOCIndex = restOfTOC.findIndex(
        (chapter) => chapter.audioFilePath
      );
      if (nextAudioTOCIndex >= 0) {
        nextAudioTOCIndex = nextAudioTOCIndex + playingTOCIndex + 1;
        return nextAudioTOCIndex;
      } else {
        return playingTOCIndex;
      }
    }
    return playingTOCIndex;
  };

  const getPrevTOCAudioIndex = () => {
    if (playingTOCIndex > 0) {
      const prevPortionOfTOC = toc.slice(0, playingTOCIndex);
      let prevAudioTOCIndex = playingTOCIndex;
      for (let i = prevPortionOfTOC.length - 1; i >= 0; i--) {
        if (prevPortionOfTOC[i].audioFilePath) {
          prevAudioTOCIndex = i;
          break;
        }
      }
      return prevAudioTOCIndex;
    }
    return playingTOCIndex;
  };

  useEffect(() => {
    pauseOnClick()
    // ***** console.log("toc", toc[playingTOCIndex])
    if (toc[playingTOCIndex] !== undefined) {
      if (toc[playingTOCIndex].srtFilePath && toc[playingTOCIndex].srtFilePath !== undefined && toc[playingTOCIndex].srtFilePath !== "") {
        // api.getFileByStringWithType(toc[playingTOCIndex].srtFilePath, "srt").then(async (blob) => {
        api.getFileByString(toc[playingTOCIndex].srtFilePath).then(async (blob) => {
          blob.text().then((result) => {
            setSubTitle(parseSRT(result))
          })
        })
      } else {
        setSubTitle([])
      }
      // ***** console.log("toc[playingTOCIndex].audioFilePath", toc[playingTOCIndex].audioFilePath)
      if (toc[playingTOCIndex].audioFilePath && toc[playingTOCIndex].audioFilePath !== undefined && toc[playingTOCIndex].audioFilePath !== "") {
        api.getFileByString(toc[playingTOCIndex].audioFilePath).then(async (blob) => {
          audioRef.current.src = URL.createObjectURL(blob);
          resetAudio();
          // // ***** console.log("createObjectURL(blob)", URL.createObjectURL(blob))
        })
      } else {
      }
    } else {
      setSubTitle([])
    }
    // const defaultCoverImagePath =
    //   process.env.REACT_APP_BACKEND +
    //   "/file/" + audioBookData.cover;
    // setDisplayingImage(defaultCoverImagePath);

    // new ReaderLogControllerApi(genApiConfig()).saveLogUsingPOST
  }, [playingTOCIndex, toc])

  // useEffect(() => {
  // }, [playingTOCIndex])

  const nextAudio = () => {
    setIsLoading(true);
    // if (!isPlaying) {
    const nextTOCAudioIndex = getNextTOCAudioIndex();
    console.log("playingTOCIndex", playingTOCIndex)
    console.log("nextTOCAudioIndex", nextTOCAudioIndex)
    setPlayingTOCIndex(nextTOCAudioIndex);
    // resetAudio();
    // }
  };

  const prevAudio = () => {
    setIsLoading(true);
    // if (!isPlaying) {
    const prevTOCAudioIndex = getPrevTOCAudioIndex();
    setPlayingTOCIndex(prevTOCAudioIndex);
    // resetAudio();
    // }
  };

  const resetAudio = () => {
    if (updateProgressBarIntervalId) {
      clearInterval(updateProgressBarIntervalId);
    }
    setProgressBarValue(0);
    if (isPlaying) {
      setIsPlaying(false);
    }
    audioRef.current.load();
    playOnClick();
    setIsLoading(false);
  };

  const flatChapters = useMemo(() => {
    return flattenChapters(audioBookData.list, [], 0);
  }, [audioBookData]);

  const updateCoverImages = () => {
    const currentTime = audioRef.current.currentTime;
    const covers = toc[playingTOCIndex].covers;
    if (
      covers &&
      covers.length > 0 &&
      nextCoverIndex < covers.length &&
      currentTime > covers[nextCoverIndex].appearTime &&
      covers[nextCoverIndex].coverFilePath
    ) {
      const imagePath = `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${covers[nextCoverIndex].coverFilePath}?x-oss-process=image/resize,h_500`
      // process.env.REACT_APP_BACKEND +
      // "/file/" + covers[nextCoverIndex].coverFilePath;
      setDisplayingImage(imagePath);
      setNextCoverIndex(nextCoverIndex + 1);
    }
  };
  const chapterNameOnClick = (index) => {
    setIsLoading(true);
    // if (!isPlaying) {
    setPlayingTOCIndex(index);
    // }
    // resetAudio();
    tocCancelButtonOnClick();
  };

  useEffect(() => {
    if (flatChapters.length > 0) {
      setTOC(flatChapters);

      const firstAudioIndex = flatChapters.findIndex(
        (chapter) => !!chapter.audioFilePath
      );
      const defaultCoverImagePath = `https://image.nblib.com/${process.env.REACT_APP_CLIENT_ID}/${audioBookData.cover}?x-oss-process=image/resize,h_500`
      // process.env.REACT_APP_BACKEND +
      // "/file/" + audioBookData.cover;
      setDisplayingImage(defaultCoverImagePath);
      // ***** console.log("setPlayingTOCIndex(firstAudioIndex);", firstAudioIndex)
      // setPlayingTOCIndex(firstAudioIndex);
    }
  }, [flatChapters]);

  const progressMouseHandler = (e) => {
    audioRef.current.currentTime = ((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById('progressBar').offsetWidth) * audioRef.current.duration

    if (e.clientX < document.getElementById('progressBar').getBoundingClientRect().left) {
      setProgressBarValue(0)
    } else if (e.clientX > document.getElementById('progressBar').getBoundingClientRect().right) {
      setProgressBarValue(100)
    } else {
      setProgressBarValue(((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * 100)
    }
  }

  const progressTouchHandler = (e) => {
    audioRef.current.currentTime = ((e.touches[e.touches.length - 1].clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * audioRef.current.duration

    if (e.touches[e.touches.length - 1].clientX < document.getElementById('progressBar').getBoundingClientRect().left) {
      setProgressBarValue(0)
    } else if (e.touches[e.touches.length - 1].clientX > document.getElementById('progressBar').getBoundingClientRect().right) {
      setProgressBarValue(100)
    } else {
      setProgressBarValue(((e.touches[e.touches.length - 1].clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * 100)
    }
  }

  const handleProgressTouchMove = (e) => {
    if (progressControlling) {
      progressTouchHandler(e)
    }
  }

  const handleProgressMouseMove = (e) => {
    setHovenTime(e)
    if (progressControlling || e.clientX <= document.getElementById('progressBar').getBoundingClientRect().left || e.clientX >= document.getElementById('progressBar').getBoundingClientRect().right) {
      document.getElementById("progressHoverTime").style.left = (document.getElementById('progressTrigger').getBoundingClientRect().left - 10) + 'px';
    } else {
      document.getElementById("progressHoverTime").style.left = (e.clientX - 20) + 'px';
    }
    if (progressControlling) {
      progressMouseHandler(e)
    }
  }

  const setHovenTime = (e) => {
    if (progressHover || progressControlling) {
      // // ***** console.log("setHovenTime", e.clientX)
      if (e.clientX <= document.getElementById('progressBar').getBoundingClientRect().left) {
        document.getElementById("progressHoverTime").innerHTML = "00:00"
      } else if (e.clientX >= document.getElementById('progressBar').getBoundingClientRect().right) {
        document.getElementById("progressHoverTime").innerHTML = Math.floor(audioRef.current.duration / 60).toString().padStart(2, '0') + ":" + Math.floor(audioRef.current.duration % 60).toString().padStart(2, '0')
      } else {
        let hoverTime = ((e.clientX - document.getElementById('progressBar').getBoundingClientRect().left) / document.getElementById("progressBar").offsetWidth) * audioRef.current.duration
        document.getElementById("progressHoverTime").innerHTML = Math.floor(hoverTime / 60).toString().padStart(2, '0') + ":" + Math.floor(hoverTime % 60).toString().padStart(2, '0')
      }
    }
  }

  return (
    <div className="audioBookContainer" id="audioBookContainerBase"
      onMouseUp={(e) => setProgressControlling(false)}
      onTouchEnd={(e) => setProgressControlling(false)}
      onMouseMove={(e) => handleProgressMouseMove(e)}
      onTouchMove={(e) => { handleProgressTouchMove(e) }}
    // onKeyDown={(e) => // ***** console.log("e", e)}
    >
      <div className="audioBookTopBar" onClick={() => window.confirm("是否關閉閱讀器？") ? window.close() : ""}>
        <button className="audioBookBackButton">
          <img
            src={backIcon}
            alt="Back"
          // className="audioBookControlSmallIcon"
          />
        </button>
        <span>關閉閱讀器</span>
      </div>
      <div className="audioBookPlayerContainer">
        {
          isShowTOC || width >= 1024 ? (
            <div className={width >= 1024 ? "topicController" : "topicControllerPhone"} onClick={() => width >= 1024 ? isShowTOC : setIsShowTOC(false)}>
              <div className={width >= 1024 ? "audioBookChapterList" : "audioBookChapterListPhone"}>
                <div className="audioBookTOCTopSection">
                  <h5 style={{ color: "#000000" }}>目錄</h5>
                  {
                    width >= 1024 ? <></> : (
                      <button
                        onClick={tocCancelButtonOnClick}
                        className="audioBookControlButton"
                      >
                        <img src={closeIcon} alt="Close" className="" />
                      </button>
                    )
                  }
                </div>
                <div className="audioBookChapterItemsContainer">
                  {toc?.map((chapter, index) => {
                    return (
                      <div
                        className={
                          chapter.level === 0
                            ? "audioBookChapterGroupTitle"
                            : "audioBookChapterItem"
                        }
                        style={isPreview && index > 0 ? {
                          marginLeft: `${chapter.level * 8}px`,
                          cursor: chapter.audioFilePath ? "pointer" : "",
                          padding: "0 10px",
                          color: "#ccc",
                        } : {
                          marginLeft: `${chapter.level * 8}px`,
                          cursor: chapter.audioFilePath ? "pointer" : "",
                          padding: "0 10px",
                        }
                        }
                        onClick={
                          chapter.audioFilePath &&
                          (() => isPreview && index > 0 ? setShowPreviewDialog(true) : playingTOCIndex !== index ? chapterNameOnClick(index) : tocCancelButtonOnClick())
                        }
                        key={chapter.title}
                      >
                        <span className="audioBookChapterTitle">
                          {chapter.title}
                        </span>
                        {chapter.title === toc[playingTOCIndex]?.title && (
                          isPlaying ?
                            <img src={wavePlayingGif} alt="wave" />
                            :
                            <img src={waveIcon} alt="wave" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : <></>
        }


        <div className="audioBookController">
          <div
            className="AudioReaderBox"
          >
            <div>
              <div className="audioBookControllerTopSection">
                <div className="audioBookDetail">
                  <div className="audioBookName">
                    {audioBookData.bookname}
                  </div>
                  <div className="audioBookAuthor">
                    {audioBookData.author}
                  </div>
                </div>
                {
                  width >= 1024 ? <></> : (
                    <button
                      onClick={tocButtonOnClick}
                      className="audioBookControlButton"
                    >
                      <img src={tocIcon} alt="TOC" />
                    </button>
                  )
                }
              </div>
              <div className="audioBookChapter">
                {toc[playingTOCIndex]?.title}
              </div>
              {subTitle && width >= 1024 ? (
                <div className="audioBookCoverImageContainer">
                  <div className="audioBookCoverImage">
                    <img
                      className={"coverimg"}
                      src={displayingImage ? displayingImage : ""}
                      alt="Cover Image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {
                    subTitle.length > 0 ? (
                      <div className={"audioBookSubTitleContainer"}>
                        {
                          audioRef.current !== undefined ? (
                            subTitle.filter((value) => audioRef.current.currentTime < value.end).map((st, idx) =>
                              idx < 5 ? (
                                <div style={{ "flexGrow": "1" }}>
                                  {
                                    <h1 style={audioRef.current.currentTime >= st.start ? { "color": "#DB7940", "paddingBottom": "1rem" } : { "paddingBottom": "1rem" }}>{st.text}</h1>
                                  }
                                </div>
                              ) : <></>
                            )
                          ) : <></>
                        }
                      </div>
                    ) : <></>
                  }

                </div>
              ) : (
                <Swiper
                  // slidesPerView={"auto"}
                  // centeredSlides={true}
                  // spaceBetween={30}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  // modules={[Pagination]}
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="audioBookCoverImageContainer"
                >
                  <SwiperSlide>
                    <div className="audioBookCoverImagePhone">
                      <img
                        className={"coverimg"}
                        src={displayingImage ? displayingImage : ""}
                        alt="Cover Image"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </SwiperSlide>
                  {
                    subTitle.length > 0 ? (
                      <SwiperSlide>
                        <div className={"audioBookSubTitleContainer"}>
                          {
                            audioRef.current !== undefined ? (
                              subTitle.filter((value) => audioRef.current.currentTime < value.end).map((st, idx) =>
                                idx < 5 ? (
                                  <div style={{ "flexGrow": "1" }}>
                                    {
                                      <h1 style={audioRef.current.currentTime >= st.start ? { "color": "#DB7940", "paddingBottom": "1rem" } : { "paddingBottom": "1rem" }}>{st.text}</h1>
                                    }
                                  </div>
                                ) : <></>
                              )
                            ) : <></>
                          }
                        </div>
                      </SwiperSlide>
                    ) : <></>
                  }
                </Swiper>
              )}
              {/* <div className="audioBookCoverImageText">封面</div>
                  <div className="audioBookCoverImageButtonsContainer">
                    <button className="audioBookCoverImageButton audioBookCoverImageActiveButton"></button>
                    <button className="audioBookCoverImageButton audioBookCoverImageNonActiveButton"></button>
                  </div></div> */}
              <div className="audioBookProgressBarContainer">
                {/* {
                    audioRef && audioRef !== undefined && audioRef.current !== null && audioRef.current !== undefined ? (
                      <button
                        onClick={
                          audioRef.current.muted || audioRef.current.volume <= 0 ? () => {
                            if (audioRef.current.muted) {
                              audioRef.current.muted = false
                            } else {
                              audioRef.current.volume = 0.1
                            }
                            setUnUsedState(unUsedState)
                          } : () => {
                            audioRef.current.muted = true
                            setUnUsedState(unUsedState)
                          }}
                        className="audioBookControlButton"
                      >
                        <img src={audioRef.current.muted || audioRef.current.volume <= 0 ? audio_muteIcon : audioRef.current.volume <= 0.33 ? audio_lowIcon : audioRef.current.volume <= 0.66 ? audio_middleIcon : audio_highIcon} alt="audio_muteIcon" className="audioBookControlSmallIcon" />
                      </button>
                    ) : <></>
                  } */}
                <progress value={progressBarValue} max="100" id="progressBar"
                  onMouseDown={(e) => {
                    progressMouseHandler(e)
                    setProgressControlling(true)
                  }}
                  onTouchStart={(e) => {
                    progressTouchHandler(e)
                    setProgressControlling(true)
                  }}
                  onMouseUp={(e) => setProgressControlling(false)} onTouchEnd={(e) => setProgressControlling(false)}
                  onMouseOver={(e) => {
                    setProgressHover(true)
                    setHovenTime(e)
                  }}
                  onMouseOut={(e) => setProgressHover(false)}
                />
                {
                  audioRef && audioRef !== undefined && audioRef.current !== null && audioRef.current !== undefined ?
                    <div>
                      {
                        Math.floor(audioRef.current.currentTime / 60).toString().padStart(2, '0')
                      }:{
                        Math.floor(audioRef.current.currentTime % 60).toString().padStart(2, '0')
                      }/{
                        audioRef.current.duration ? Math.floor(audioRef.current.duration / 60).toString().padStart(2, '0') : "00"
                      }:{
                        audioRef.current.duration ? Math.floor(audioRef.current.duration % 60).toString().padStart(2, '0') : "00"
                      }
                    </div>
                    : <></>
                }

              </div>
            </div>

            <audio
              ref={audioRef}
              onEnded={handleAudioEnded}
              onTimeUpdate={updateCoverImages}

              // src={
              //   // process.env.REACT_APP_BACKEND +
              //   // "/file/" + toc[playingTOCIndex].audioFilePath
              //   audioUrl
              // }
              type="audio/mpeg"
            >
              {/* <source
                  src={
                    // process.env.REACT_APP_BACKEND +
                    // "/file/" + toc[playingTOCIndex].audioFilePath
                    audioUrl
                  }
                  type="audio/mpeg"
                /> */}
            </audio>
            <div className="audioBookControlButtonsContainer">
              {
                isPreview ? <></> : (
                  <button className="audioBookControlButton" onClick={prevAudio}>
                    <img
                      src={previousIcon}
                      alt="Previous"
                      className="audioBookControlSmallIcon"
                    />
                  </button>
                )
              }
              <button className="audioBookControlButton" onClick={backward}>
                <img
                  src={backwardIcon}
                  alt="Backward"
                  className="audioBookControlSmallIcon"
                />
              </button>
              <button
                className="audioBookControlButton"
                onClick={isPlaying ? pauseOnClick : playOnClick}
              >
                <img
                  src={isPlaying ? pauseIcon : playIcon}
                  alt={isPlaying ? "Pause" : "Play"}
                  className="audioBookControlLargeIcon"
                />
              </button>
              <button className="audioBookControlButton" onClick={fastForward}>
                <img
                  src={forwardIcon}
                  alt="Forward"
                  className="audioBookControlSmallIcon"
                />
              </button>
              {
                isPreview ? <></> : (
                  <button className="audioBookControlButton" onClick={nextAudio}>
                    <img
                      src={nextIcon}
                      alt="Next"
                      className="audioBookControlSmallIcon"
                    />
                  </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="只能試閱第一集"
        showDialog={showPreviewDialog}
        children={
          <div className="dialogButtonContainer">
            <Button
              styleType={"submitButton"}
              callBack={() => {
                setShowPreviewDialog(false)
              }}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      {
        isLoading ? <LoadingView /> : <></>
      }
      <div /*style={{ backgroundColor: "#DB7940", height: "20px", width: "20px", borderRadius: "50%", display: "none", position: "absolute", border: "solid grey" }}*/ id="progressTrigger" className="progressTrigger"
        onMouseDown={(e) => {
          progressMouseHandler(e)
          setProgressControlling(true)
        }}
        onTouchStart={(e) => {
          progressTouchHandler(e)
          setProgressControlling(true)
        }}
        onMouseUp={(e) => setProgressControlling(false)} onTouchEnd={(e) => setProgressControlling(false)}
        onMouseOver={(e) => {
          setProgressHover(true)
          setHovenTime(e)
        }}
        onMouseOut={(e) => setProgressHover(false)}
      />
      <div style={{ display: progressHover || progressControlling ? "block" : "none", /*backgroundColor: "#535353", height: "20px", width: "40px", borderRadius: "10px", position: "absolute", color: "white", fontSize: "13px", textAlign: "center", paddingTop: "2px"*/ }} id="progressHoverTime" className="progressHoverTime" >00:00</div>
    </div >
  );
};
