import { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import {
  BorrowControllerApi,
  ListAllMyReserveBookUsingPOSTRequest,
  ReserveBorrowDto,
} from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions/userInfo";
import { DIALOG_CANCEL, DIALOG_COMPLETE } from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import style from "./Reservation.module.css";
import ReservationItem from "./ReservationItem";

interface IProps {
  title?: string;
  content?: string;
  value?: string;
  callBack: (value: any, value2?: any) => void;
  styleType?: string;
  children?: any;
  item?: any;
  navigate?: any;
  userInfo?: any;
}

class Reservation extends Component<IProps> {
  state = {
    content: {},
    title: "",
    showError: false,
    loading: false,
    reserveBookData: [] as Array<ReserveBorrowDto>,
  };

  handleCancel = () => {
    this.props.callBack({ action: DIALOG_CANCEL });
  };

  handleSubmit = () => {
    this.props.callBack({ action: DIALOG_COMPLETE });
  };

  componentDidMount() {
    this.getApiData();
  }

  getApiData() {
    if (Object.keys(this.props.userInfo).length > 0) {
      new BorrowControllerApi(genApiConfig())
        .listAllMyReserveBookUsingPOST({
          memberId: this.props.userInfo?.userInfo.member.memberId,
        } as ListAllMyReserveBookUsingPOSTRequest)
        .then((res: Array<ReserveBorrowDto>) => {
          this.setState({ ...this.state, reserveBookData: res });
          this.props.callBack?.(this.state.reserveBookData);
        })
        .catch((err) => {});
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (nextProps.item !== this.props.item) {
      if (
        this.state.reserveBookData.length !==
        nextProps.item.reserveBookList.length
      ) {
        this.getApiData();
      }
    }
  }

  render() {
    const { content, showError, loading } = this.state;

    return (
      <Fragment>
        <div
          style={{ marginTop: "2em" }}
          className={style.tableItemListContainer}
        >
          <div className={style.tableListItemGridView}>
            <div className={style.topRow}></div>

            <div className={style.content}>
              <div className={style.padding}>
                <div className={style.title}>
                  <h3>
                    <Trans>MyBooking</Trans>
                  </h3>
                </div>
                <div className={style.scrollingWrapper}>
                  {this.state.reserveBookData &&
                    this.state.reserveBookData.map((obj: any) => {
                      return (
                        <ReservationItem
                          callBack={() => this.getApiData()}
                          navigate={this.props.navigate}
                          item={obj}
                        ></ReservationItem>
                      );
                    })}

                  {/* {this.props.item.reserveBookList &&
                    this.props.item.reserveBookList.map((it: any) => (
                      <ReservationItem
                        callBack={(value) => this.props.callBack(value)}
                        navigate={this.props.navigate}
                        item={it}
                      ></ReservationItem>
                    ))} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};

export default connect(mapStateToProps, actionCreator)(Reservation);
