import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import BookLibraryCardView from "../../../components/TableCardView/Book/BookLibraryCardView";
import { CategoryControllerApi, GetPagedProductAdminBySearchUsingGETRequest, GetPagedProductAdminUsingGETRequest, ProductControllerApi } from "../../../fetcher";
import * as PATH from "../../../utils/Contants"; //"../../../global/Contants";
import { ADD, CANCEL, DELETE, SAVE, STYLETYPE_DATALIST, STYLETYPE_FIFTH, STYLETYPE_FOUR, STYLETYPE_IMG, STYLETYPE_MAIN, STYLETYPE_SECOND, UPDATE } from "../../../utils/Contants"; //"../../../global/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import style from "./Purchased.module.css";
import TreeMeunCategory from "./TreeMeunCategory/TreeMeunCategory";
import { IntegerListRequestModel } from "../../../fetcher";
import PageLoading from "../../loading/PageLoading";
import * as Storage from "../../../utils/local-storage";
import SelectOption from "../../../components/SelectOption/SelectOption";
import listViewIcon from "../../../assets/svg/list_view.svg";
import titleViewIcon from "../../../assets/svg/title_view.svg";
import PurchasedItemListView from "./PurchasedItemListView";
import api from "../../../api/api";

export default function Purchased() {
    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();
    const navigate = useNavigate();

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddAllToCategory, setShowAddAllToCategory] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [toggleList, setToggleList] = useState(true);
    const [toggleComment, setToggleComment] = useState(false);
    const [apiData, setApiData] = useState<any>([]);
    const [displayData, setDisplayData] = useState<any>([]);
    const [category, setCategory] = useState<any>([]);
    const [book, setBook] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBook, setSelectedBook] = useState<any>([]);
    const [currentPage, setCurrerntPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isList, setIsList] = useState(false);
    const [organizationList, setOrganizationList] = useState<any>([]);
    const [publishHouse, setPublishHouse] = useState([]);
    const [productStatus, setProductStatus] = useState({ id: 0, name: "全部" });
    const [organization, setOrganization] = useState<any>(undefined);

    const statusList = [{ id: 0, name: "全部" }, { id: 1, name: "上架" }, { id: 2, name: "下架" }];

    useEffect(() => {
        api.getAllOrganization().then((data) => setOrganizationList(data));
    }, [])

    // useEffect(() => {
    //     console.log("productStatus", productStatus)
    // }, [productStatus])

    // useEffect(() => {
    //     console.log("organization", organization)
    // }, [organization])

    useEffect(() => {
        setPublishHouse(
            [].concat.apply(
                [],
                organizationList.map((org: any) => org.subOrganization)
            )
        );
    }, [organizationList]);

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        fetchApi();
    }, [currentPage]);

    const fetchApi = () => {
        let searchingKeyword: GetPagedProductAdminBySearchUsingGETRequest = {
            size: "48",
            page: currentPage.toString(),
        }
        if (organization !== undefined) {
            searchingKeyword = { ...searchingKeyword, organizationId: organization.organizationId }
        }
        if (productStatus !== undefined) {
            searchingKeyword = { ...searchingKeyword, productStatus: productStatus.id }
        }
        console.log("searchText", searchText)
        if (searchText && searchText !== undefined && searchText !== "") {
            searchingKeyword = {
                ...searchingKeyword,
                bookName: searchText,
                author: searchText,
                bookISBN: searchText
            }
        }
        new ProductControllerApi(genApiConfig())
            .getPagedProductAdminBySearchUsingGET(searchingKeyword)
            .then((res: any) => {
                setDisplayData(res.list);
                setApiData(res.list);
                setPageCount(res.pagenum)
            })
            .finally(() => {
                setIsLoading(false);
                document.getElementById("root")?.scrollTo(0, 0);
            });
        new CategoryControllerApi(genApiConfig()).getAllCategoryUsingGET().then((res) => {
            setCategory(res);
        });
    };

    const handlePageChanging = (action: string) => {
        if (action === "next") {
            if (displayData.length < 50) return;
            setIsLoading(true);
            setCurrerntPage(currentPage + 1);
            return;
        }
        if (action === "previous") {
            if (currentPage === 1) return;
            setIsLoading(true);
            setCurrerntPage(currentPage - 1);
            return;
        }
    };

    const getSelectCategoryFromBook = (currentBook: any) => {
        let categoriesList: any = [];
        let currentCategories = currentBook.categories;
        currentCategories.forEach((category: any) => {
            let categoryId = category.categoryId;
            categoriesList.push(categoryId);
        });
        // console.log("prepared cate list ",categoriesList);
        return categoriesList;
    };

    const handleCategoryMeun = (value: any) => {
        // if (typeof value === "object") {
        //   if (value.isChecked) {
        //     const joined = this.state.selectedCategory.concat(value.categoryId);
        //     this.setState({ ...this.state, selectedCategory: joined });
        //   } else {
        //     const joined = this.state.selectedCategory.filter(
        //       (x: any) => x !== value.categoryId
        //     );
        //     this.setState({ ...this.state, selectedCategory: joined });
        //   }
        // } else if (value === UPDATE) {
        //   console.log("request", {
        //     isbn: this.props.data && this.props.data?.bookIsbn,
        //     requestBody: { isChecked: this.state.selectedCategory },
        //   });
        //   this.setState({ ...this.state, show: !this.state.show });
        // } else {
        //   this.setState({ ...this.state, show: !this.state.show });
        // }

        if (value.isChecked) {
            setSelectedCategory((prev) => {
                const joined = prev.concat(value.categoryId);
                return joined;
            });
            // console.log(selectedCategory);
            return;
        } else {
            setSelectedCategory((prev) => {
                const joined = prev.filter((x) => x !== value.categoryId);
                return joined;
            });
            // console.log(selectedCategory);
            return;
        }

        if (value.action === UPDATE) {
            console.log("display", displayData);
            console.log("e", value.event);
        }
        // else {
        // setShowAddAllToCategory(!showAddAllToCategory);
        // }
    };

    const setSelectdObjForCategory = (obj: any, updateObj: any) => {
        // console.log("obj", obj);
        // console.log("updateObj", updateObj);
        if (updateObj !== null && updateObj !== undefined) {
            let findUpdateObj = updateObj.find((newObj: any) => newObj.id === obj.id);
            if (findUpdateObj !== undefined) {
                return {
                    ...obj,
                    isSelected: true,
                    isSearch: true,
                };
            } else {
                return {
                    ...obj,
                    isSelected: false,
                    isSearch: true,
                };
            }
        } else {
            return {
                ...obj,
                isSelected: false,
                isSearch: true,
                isRoot: true,
            };
        }
    };

    const ItemCheckedChange = (value: any) => { };

    // const handleSearchClick = (value: any) => {
    //     if (value === "") {
    //         fetchApi();
    //         return;
    //     }
    //     let bookResult: any[] = [];
    //     console.log("displayData", displayData);
    //     const isbnArray = value.replaceAll(" ", "").split(",");
    //     console.log("isbnArray", isbnArray);

    //     for (let eachArray = 0; eachArray <= isbnArray.length - 1; eachArray++) {
    //         const result = apiData && apiData.filter((bookItem: any) => bookItem.bookIsbn.includes(isbnArray[eachArray]));
    //         // console.log("search result", result);
    //         if (result !== undefined) {
    //             bookResult = bookResult.concat(result);
    //         }
    //     }

    //     // remove duplicate books from search result
    //     if (bookResult.length > 1) {
    //         let uniqueResultList = bookResult.filter((value, index, self) => index === self.findIndex((t) => t.productId === value.productId));
    //         // console.log("search bookResult unique", uniqueResultList);
    //         bookResult = uniqueResultList;
    //     }

    //     setDisplayData(bookResult);
    // };

    const handleDialogCallBack = (value: any) => {
        console.log("handleDialogCallBack", value);
        if (value.action === ADD) {
            setBook(null);
            setBook(value.book);
            setSelectedCategory((prev) => {
                return getSelectCategoryFromBook(value.book);
            });
            setShowAddDialog(true);
            // console.log("current viewed book", book);
        } else if (value.action === "ALL") {
            setBook(null);
            setBook([]);
            setSelectedCategory([]);
            setShowAddAllToCategory(true);
        } else if (value.action === UPDATE) {
            setShowEditDialog(true);
        } else if (value.action === PATH.AVAILABLE) {
            setShowDeleteDialog(true);
            setBook(value.book);
        } else if (value.action === CANCEL) {
            setShowAddDialog(false);
            setShowEditDialog(false);
            setShowDeleteDialog(false);
            setShowAddAllToCategory(false);
        } else if (value.action === DELETE) {
            setShowDeleteDialog(false);
            updateBookStatus();
        } else if (value.action === SAVE) {
            // TODO save category change
            // console.log("selectedCategory", selectedCategory);
            // console.log("selectedBook", selectedBook);
            setShowAddDialog(false);
            setShowEditDialog(false);
            handleCategory();
        } else if (value.action === "select") {
            handleSelectedBook(value);
        } else if (value.action === "confirm") {
            // console.log("selected book", selectedBook);
            // console.log("selected category", selectedCategory);
            setShowAddDialog(false);
            setShowEditDialog(false);
            setShowDeleteDialog(false);
            setShowAddAllToCategory(false);
            handleCategorySelectAll();
            alert("成功更新書單");
        }
    };

    const handleCategory = () => {
        new ProductControllerApi(genApiConfig())
            .updateProductUsingPOST({
                productId: book.productId,
                integerListRequestModel: {
                    integers: selectedCategory,
                } as IntegerListRequestModel,
            })
            .then(() => {
                fetchApi();
                alert("成功更新書單");
            });
    };

    const handleCategorySelectAll = () => {
        // console.log(displayData);
        selectedBook.forEach((selectedBookId: any, index: any) => {
            let currentHandleBook = displayData.find((book: any) => {
                return book.productId === selectedBookId;
            });
            let currentBookCategories = getSelectCategoryFromBook(currentHandleBook);
            let newCategories = currentBookCategories.concat(selectedCategory);
            let newUniqueCategories = [];
            newUniqueCategories = Array.from(new Set(newCategories));

            // console.log("index",index);
            // console.log("selectedBookId",selectedBookId);
            // console.log("currentHandleBook",currentHandleBook);
            // console.log("currentBookCategories",currentBookCategories);
            // console.log("newCategories",newCategories);
            // console.log("newUniqueCategories",newUniqueCategories);

            new ProductControllerApi(genApiConfig())
                .updateProductUsingPOST({
                    productId: selectedBookId,
                    integerListRequestModel: {
                        integers: newUniqueCategories,
                    } as IntegerListRequestModel,
                })
                .then(() => {
                    fetchApi();
                });
        });
    };

    const updateBookStatus = () => {
        console.log("book.productId", book.productId);
        new ProductControllerApi(genApiConfig())
            .updateProductStatusAdminUsingGET({
                productId: book.productId,
            })
            .then((res) => {
                if (res) {
                    fetchApi();
                }
            })
            .then();
    };

    const handleSelectedBook = (e: any) => {
        console.log("selectedBook", e.book.productId);
        console.log("selectedBook before", selectedBook);
        if (e.isChecked) {
            setSelectedBook([...selectedBook, e.book.productId]);
        } else {
            setSelectedBook(selectedBook.filter((x: any) => x !== e.book.productId));
        }
    };

    useEffect(() => {
        console.log("selectedBook changed", selectedBook)
    }, [selectedBook])

    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    const handleFirstPage = () => {
        setCurrerntPage(1);
    };
    const handleLastPage = () => {
        setCurrerntPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrerntPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrerntPage(currentPage + 1);
        }
    };
    const handleSelectPage = (value: any) => {
        setCurrerntPage(parseInt(value));
    };


    return (
        <>
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <>
                    <PageLoading />
                </>
            ) : (
                <div className={style.PlatformContainer}>
                    <div className={style.toggleGroupContainer}>
                        <div className={style.toggleGroup}>
                            {/* <div className={style.toggleSilder}>123</div> */}
                            <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKLIST)}>
                                <Trans>CateList</Trans>
                            </Button>
                            <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => console.log(value)}>
                                <Trans>PurchasedBook</Trans>
                            </Button>
                        </div>
                    </div>

                    <>
                        <div className={style.container}>
                            <h4>
                                <Trans>PurchasedBook</Trans>
                            </h4>
                            <div className={style.header}>
                                <div className={style.row}>
                                    <div className={style.RowBtn}>
                                        <SelectOption styleType={STYLETYPE_SECOND} items={statusList} callBack={(value) => setProductStatus(value)} placeholder={"上架/下架"} />
                                    </div>
                                    <div className={style.RowBtn}>
                                        <SelectOption
                                            placeholder={t("SearchPublisherKeyword")}
                                            firstValue="pleasechoose"
                                            items={publishHouse}
                                            styleType={STYLETYPE_DATALIST}
                                            callBack={(value) => setOrganization(value)}
                                        />
                                    </div>
                                    <div className={style.RowBtn}>
                                        <Input type="text" placeholder={"輸入ISBN、書名、出版社"/*t("inputISBN")*/} value={searchText} styleType={STYLETYPE_FIFTH} callBack={(value) => setSearchText(value)} />
                                    </div>
                                    <div className={style.RowBtn}>
                                        <Button styleType={"searchButtom"} callBack={() => fetchApi()}>
                                            <Trans>Search</Trans>
                                        </Button>
                                    </div>
                                </div>

                                {/* <div className={style.row}>
                                </div> */}

                                <div className={style.rowSpan}>
                                    <Button
                                        styleType={STYLETYPE_IMG}
                                        callBack={() => setIsList(true)}
                                    >
                                        <img src={listViewIcon} />
                                    </Button>
                                    <Button
                                        styleType={STYLETYPE_IMG}
                                        callBack={() => setIsList(false)}
                                    >
                                        <img src={titleViewIcon} />
                                    </Button>
                                    {
                                        selectedBook.length > 0 ? (
                                            <>
                                                <div className={style.border_line}></div>
                                                <Button
                                                    hidden={selectedBook.length > 0 ? false : true}
                                                    styleType={"searchButtom"}
                                                    callBack={(value) => handleDialogCallBack({ action: "ALL" })}
                                                >
                                                    全選並加入書單
                                                </Button>
                                            </>
                                        ) : <></>
                                    }

                                </div>
                            </div>
                            <div style={{ padding: "8px" }}>
                                {
                                    isList ? (
                                        <>
                                            <div className={style.listViewHeader}>
                                                <div />
                                                <h3>封面</h3>
                                                <h3>書名</h3>
                                                <h3>作者</h3>
                                                <h3>ISBN</h3>
                                                <h3>功能</h3>
                                            </div>
                                            {displayData.length > 0 && displayData.map((bookItem: any, key: number) => <PurchasedItemListView selectedBook={selectedBook} data={bookItem} callBack={(e: any) => handleDialogCallBack(e)} />)}
                                        </>
                                    ) : (
                                        <div className={style.cardViewContainer}>
                                            {displayData.length > 0 && displayData.map((bookItem: any, key: number) => <BookLibraryCardView selectedBook={selectedBook} data={bookItem} callBack={(e) => handleDialogCallBack(e)} />)}
                                        </div>
                                    )
                                }
                                {/* <div className={style.cardViewContainer}>
                                    {displayData.length > 0 && displayData.map((bookItem: any, key: number) => <BookLibraryCardView selectedBook={selectedBook} data={bookItem} callBack={(e) => handleDialogCallBack(e)} />)}
                                </div> */}
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "center" }}>
                            <div
                                onClick={() => handlePageChanging("previous")}
                                style={
                                    currentPage === 1
                                        ? {
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                          }
                                        : {
                                              color: "rgba(181 60 63)",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              fontSize: "12px",
                                          }
                                }
                            >
                                <Trans>PreviousPage</Trans>
                            </div>
                            <p
                                style={{
                                    color: "rgba(181 60 63)",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                }}
                            >
                                &nbsp;&nbsp;/&nbsp;&nbsp;
                            </p>
                            <div
                                onClick={() => handlePageChanging("next")}
                                style={
                                    displayData.length < 50
                                        ? {
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                          }
                                        : {
                                              color: "rgba(181 60 63)",
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              fontSize: "12px",
                                          }
                                }
                            >
                                <Trans>NextPage</Trans>
                            </div>
                        </div> */}

                        {displayData && displayData.length > 0 ? (
                            <div className={style.page_row}>
                                <div className={style.page_item} onClick={() => handleFirstPage()}>
                                    首頁
                                </div>
                                <div className={style.border_line}></div>
                                <div className={style.page_item} onClick={() => handlePrevPage()}>
                                    上一頁
                                </div>
                                <div className={style.border_line}></div>
                                <div className={style.page}>
                                    <SelectOption
                                        styleType={STYLETYPE_FOUR}
                                        value={currentPage.toString()}
                                        firstValue={""}
                                        items={handlepagecount()}
                                        callBack={(value) => {
                                            // console.log("pagevalue:", value)
                                            handleSelectPage(value.name)
                                        }}
                                    ></SelectOption>
                                    <div style={{ marginLeft: "7px" }}>/ {pageCount}</div>
                                </div>
                                <div className={style.border_line}></div>
                                <div className={style.page_item} onClick={() => handleNextPage()}>
                                    下一頁
                                </div>
                                <div className={style.border_line}></div>
                                <div className={style.page_item} onClick={() => handleLastPage()}>
                                    尾頁
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                </div>
            )
            }

            {/* Add */}
            <DialogBox
                styleType={STYLETYPE_MAIN}
                showDialog={showAddDialog}
                title={`${book && book.productNameChi}`}
                scroll={category.map((cate: any) => (
                    <TreeMeunCategory book={book} multipleSelect={true} selectedCategoryList={selectedCategory} props={cate} callBack={(e: any) => handleCategoryMeun(e)} />
                ))}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => handleDialogCallBack({ action: CANCEL })}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button styleType={"upperNavbarButton"} callBack={() => handleDialogCallBack({ action: SAVE })}>
                        確定
                    </Button>
                </div>
            </DialogBox>

            {/* Edit */}
            <DialogBox styleType={STYLETYPE_SECOND} showDialog={showEditDialog} title={"更新書單"}></DialogBox>

            {/* Delete */}
            <DialogBox
                styleType={STYLETYPE_SECOND}
                showDialog={showDeleteDialog}
                title={`確定之後，書名${book && book.productNameChi}將從清單中${book.status ? "下架" : "上架"}。`}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => handleDialogCallBack({ action: CANCEL })}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button styleType={"upperNavbarButton"} callBack={() => handleDialogCallBack({ action: DELETE })}>
                        確定
                    </Button>
                </div>
            </DialogBox>

            {/* AddAllToCategory */}

            <DialogBox
                styleType={STYLETYPE_MAIN}
                showDialog={showAddAllToCategory}
                title={"全部加入書單"}
                scroll={category.map((cate: any) => (
                    <TreeMeunCategory multipleSelect={true} selectedCategoryList={selectedCategory} props={cate} callBack={(e: any) => handleCategoryMeun(e)} />
                ))}
            >
                <div className={style.dialogButtonContainer}>
                    <Button styleType={"upperNavbarButton"} callBack={() => handleDialogCallBack({ action: CANCEL })}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button styleType={"upperNavbarButton"} callBack={(e) => handleDialogCallBack({ action: "confirm" })}>
                        儲存
                    </Button>
                </div>
            </DialogBox>
        </>
    );
}