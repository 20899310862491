import React, { useState ,useEffect} from "react";
import { FaStar } from "react-icons/fa";

export default function Rating(props:any) {
  const stars = Array(5).fill(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined);
  

  const handleClick = (value: number) => {
    console.log('handleClick',value)
    setCurrentValue(value)
    props.callBack(value)
  };

  const handleMouseOver = (value: number) => {
    setHoverValue(value);
  };
  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  useEffect(() => {
    setCurrentValue(props.rate)
  },[props.rate])
   
  // useEffect(() => {
  //   setCurrentValue(props.value)
  // },[props.value])

  return (
    <>

      {props.modify  && props.modify ? stars.map((_: any, idx: number) => {
          return (
            <FaStar
              key={idx}
              size={15}
              color={(hoverValue || currentValue )> idx ? "#FFBA5A" : "#a9a9a9"}
              onClick={() => handleClick(idx + 1)}
              onMouseOver={() => handleMouseOver(idx + 1)}
              onMouseLeave={handleMouseLeave}
              
            />
          );
        }):
        stars.map((_: any, idx: number) => {
          return (
            <FaStar
              key={idx}
              size={15}
              color={(currentValue )> idx ? "#FFBA5A" : "#a9a9a9"}

            />
          );
        })
      }
      {/* {props.callBack !== undefined ? stars.map((_: any, idx: number) => {
        return (
          <FaStar
            key={idx}
            size={15}
            color={(hoverValue || currentValue )> idx ? "#FFBA5A" : "#a9a9a9"}
            onClick={() => handleClick(idx + 1)}
            onMouseOver={() => handleMouseOver(idx + 1)}
            onMouseLeave={handleMouseLeave}
          />
        );
      }): stars.map((_: any, idx: number) => {
        return (
          <FaStar
            key={idx}
            size={15}
            color={currentValue > idx ? "#FFBA5A" : "#a9a9a9"}

          />
        ); 
      })} */}
    </>
  );
}
