/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Award
 */
export interface Award {
    /**
     * 
     * @type {number}
     * @memberof Award
     */
    awardId?: number;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    mongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Award
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    yearOf?: string;
}

export function AwardFromJSON(json: any): Award {
    return AwardFromJSONTyped(json, false);
}

export function AwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Award {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'awardId': !exists(json, 'awardId') ? undefined : json['awardId'],
        'mongoId': !exists(json, 'mongoId') ? undefined : json['mongoId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'yearOf': !exists(json, 'yearOf') ? undefined : json['yearOf'],
    };
}

export function AwardToJSON(value?: Award | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'awardId': value.awardId,
        'mongoId': value.mongoId,
        'name': value.name,
        'seq': value.seq,
        'status': value.status,
        'yearOf': value.yearOf,
    };
}

