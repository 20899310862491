/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountDtoLite
 */
export interface DiscountDtoLite {
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    actionValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    condition?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    discountType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    target?: string;
}

export function DiscountDtoLiteFromJSON(json: any): DiscountDtoLite {
    return DiscountDtoLiteFromJSONTyped(json, false);
}

export function DiscountDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDtoLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionValue': !exists(json, 'actionValue') ? undefined : json['actionValue'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'discountTitle': !exists(json, 'discountTitle') ? undefined : json['discountTitle'],
        'discountType': !exists(json, 'discountType') ? undefined : json['discountType'],
        'limitToUse': !exists(json, 'limitToUse') ? undefined : json['limitToUse'],
        'limitToUseRest': !exists(json, 'limitToUseRest') ? undefined : json['limitToUseRest'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}

export function DiscountDtoLiteToJSON(value?: DiscountDtoLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionType': value.actionType,
        'actionValue': value.actionValue,
        'condition': value.condition,
        'discountId': value.discountId,
        'discountTitle': value.discountTitle,
        'discountType': value.discountType,
        'limitToUse': value.limitToUse,
        'limitToUseRest': value.limitToUseRest,
        'status': value.status,
        'target': value.target,
    };
}

