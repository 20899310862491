import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Input from "../../../components/Input/Input";
import BookLibraryCardView from "../../../components/TableCardView/Book/BookLibraryCardView";
import {
  CategoryControllerApi,
  IntegerListRequestModel,
  ProductControllerApi,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants"; //"../../../global/Contants";
import {
  ADD,
  CANCEL,
  DELETE,
  SAVE,
  STYLETYPE_FIFTH,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  UPDATE,
} from "../../../utils/Contants"; //"../../../global/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import style from "./BookListByCategory.module.css";
import TreeMeunCategory from "./TreeMeunCategory/TreeMeunCategory";
export default function BookListByCategory() {
  const { t, i18n } = useTranslation();
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const { id } = useParams();

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddAllToCategory, setShowAddAllToCategory] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [toggleList, setToggleList] = useState(true);
  const [toggleComment, setToggleComment] = useState(false);
  const [displayData, setDisplayData] = useState<any>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [book, setBook] = useState<any>({});
  const [categoryId, setCategoryId] = useState<any>({});
  const [selectedBook, setSelectedBook] = useState<any>([]);

  useEffect(() => {
    fetchApi();
  }, []);
  // console.log("id", id!);
  const fetchApi = () => {
    new ProductControllerApi(genApiConfig())
      .getProductByCategoryIdAdminUsingGET({ categoryId: +id! })
      .then((res: any) => {
        setDisplayData(res);
        setApiData(res);
      });
    new CategoryControllerApi(genApiConfig())
      .getAllCategoryUsingGET()
      .then((res) => {
        setCategory(res);
      });
    new CategoryControllerApi(genApiConfig())
      .getCategoryByCategoryIdUsingGET({
        categoryId: parseInt(id!),
      })
      .then((res) => setCategoryId(res));
  };

  const handleSelectedBook = (e: any) => {
    // console.log("selectedBook", e.book.productId);
    // console.log("selectedBook", selectedBook.length);
    if (e.isChecked) {
      setSelectedBook([...selectedBook, e.book.productId]);
    } else {
      setSelectedBook(selectedBook.filter((x: any) => x !== e.book.productId));
    }
  };

  const updateBookStatus = () => {
    console.log("book.productId", book.productId);
    new ProductControllerApi(genApiConfig())
      .updateProductStatusAdminUsingGET({
        productId: book.productId,
      })
      .then((res) => {
        if (res) {
          fetchApi();
        }
      })
      .then();
  };

  const getSelectCategoryFromBook = (currentBook: any) => {
    let categoriesList: any = [];
    let currentCategories = currentBook.categories;
    currentCategories.forEach((category:any)=>{
      let categoryId = category.categoryId;
      categoriesList.push(categoryId);
    });
    // console.log("prepared cate list ",categoriesList);
    return categoriesList;
  }

  const handleShowCategoryMeun = (value: any) => {
    // if (typeof value === "object") {
    //   if (value.isChecked) {
    //     const joined = this.state.selectedCategory.concat(value.categoryId);
    //     this.setState({ ...this.state, selectedCategory: joined });
    //   } else {
    //     const joined = this.state.selectedCategory.filter(
    //       (x: any) => x !== value.categoryId
    //     );
    //     this.setState({ ...this.state, selectedCategory: joined });
    //   }
    // } else if (value === UPDATE) {
    //   console.log("request", {
    //     isbn: this.props.data && this.props.data?.bookIsbn,
    //     requestBody: { isChecked: this.state.selectedCategory },
    //   });
    //   this.setState({ ...this.state, show: !this.state.show });
    // } else {
    //   this.setState({ ...this.state, show: !this.state.show });
    // }

    console.log("value222", value);

    if (value.isChecked) {
      setSelectedCategory(prev => {
        const joined = prev.concat(value.categoryId);
        return joined
      });
    } else {
      setSelectedCategory(prev => {
        const joined = prev.filter((x) => x !== value.categoryId);
        return joined
      });
    }

    // if (value.action === ADD) {
    //   if (value.isChecked) {
    //     const joined = selectedCategory.concat(value.categoryId);
    //     console.log("joined", joined);
    //     setSelectedCategory(joined);
    //   } else {
    //     const joined = selectedCategory.filter((x) => x !== value.categoryId);
    //     setSelectedCategory(joined);
    //   }
    // } else if (value === UPDATE) {
    //   const Request = displayData.map((x: any) => {
    //     return {
    //       isChecked: { isbn: x.bookIsbn, categories: selectedCategory },
    //     };
    //   });
    //   console.log({ RequestBody: Request });
    // } else if (value.action === PATH.DELETE) {
    //   setBook(value.book);
    //   setShowDeleteDialog(!showDeleteDialog);
    // } else if (value.action === PATH.CANCEL) {
    //   setShowAddAllToCategory(false);
    //   setShowDeleteDialog(false);
    // }
  };

  const setSelectdObjForCategory = (obj: any, updateObj: any) => {
    // console.log("obj", obj);
    // console.log("updateObj", updateObj);
    if (updateObj !== null && updateObj !== undefined) {
      let findUpdateObj = updateObj.find((newObj: any) => newObj.id === obj.id);
      if (findUpdateObj !== undefined) {
        return {
          ...obj,
          isSelected: true,
          isSearch: true,
        };
      } else {
        return {
          ...obj,
          isSelected: false,
          isSearch: true,
        };
      }
    } else {
      return {
        ...obj,
        isSelected: false,
        isSearch: true,
        isRoot: true,
      };
    }
  };

  const ItemCheckedChange = (value: any) => {};

  const handleSearchClick = (value: any) => {
    if (value === "") {
      fetchApi();
      return;
    }
    let bookResult: any[] = [];
    // console.log("displayData", displayData);
    const isbnArray = value.replaceAll(" ", "").split(",");
    // console.log("isbnArray", isbnArray);

    for (let eachArray = 0; eachArray <= isbnArray.length - 1; eachArray++) {
      const result =
      apiData &&
      apiData.filter((bookItem: any) =>
          bookItem.bookIsbn.includes(isbnArray[eachArray])
        );
      if (result !== undefined) {
        bookResult = bookResult.concat(result);
      }
    }

    // remove duplicate books from search result
    if (bookResult.length > 1) {
      let uniqueResultList = bookResult.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.productId === value.productId
        ))
      )
      // console.log("search bookResult unique", uniqueResultList);
      bookResult = uniqueResultList;
    }

    setDisplayData(bookResult);
  };

  const handletoggle = (value: any) => {};

  const handleDialogCallBack = (value: any) => {
    // console.log("handleDialogCallBack", value);
    if (value.action === ADD) {
      setBook(null);
      setBook(value.book);
      setSelectedCategory(prev => {
        return getSelectCategoryFromBook(value.book);
      });
      setShowAddDialog(true);
    } else if (value.action === "ALL") {
      setBook(null);
      setBook([]);
      setSelectedCategory([]);
      setShowAddAllToCategory(true);
    } else if (value.action === UPDATE) {
      setShowEditDialog(true);
    } else if (value.action === PATH.AVAILABLE) {
      setShowDeleteDialog(true);
      setBook(value.book);
    } else if (value.action === CANCEL) {
      setShowAddDialog(false);
      setShowEditDialog(false);
      setShowDeleteDialog(false);
      setShowAddAllToCategory(false);
    } else if (value.action === SAVE) {
      // console.log("selectedCategory", selectedCategory);
      // console.log("selectedBook", selectedCategory);
      setShowAddDialog(false);
      setShowEditDialog(false);
      setShowAddAllToCategory(false);
      handleCategory(value);
    } else if (value.action === "select") {
      handleSelectedBook(value);
    } else if (value.action === "confirm") {
      setShowAddDialog(false);
      setShowEditDialog(false);
      setShowDeleteDialog(false);
      setShowAddAllToCategory(false);
      handleCategorySelectAll();
      alert("成功更新書單");
    } else if (value.action === DELETE) {
      updateBookStatus();
      setShowDeleteDialog(false);
    }
  };

  const handleCategory = (value: any) => {
    // console.log("book",book);
    // console.log("selectedCategory",selectedCategory);
    new ProductControllerApi(genApiConfig())
    .updateProductUsingPOST({
      productId: book.productId,
      integerListRequestModel: {
        integers: selectedCategory,
      } as IntegerListRequestModel,
    })
    .then(() => {
      fetchApi();
      alert("成功更新書單");
    });
  };

  const handleCategorySelectAll = () => {
    // console.log(displayData);
    selectedBook.forEach((selectedBookId: any, index: any) => {
      let currentHandleBook = displayData.find((book:any)=>{return book.productId === selectedBookId});
      let currentBookCategories = getSelectCategoryFromBook(currentHandleBook);
      let newCategories = currentBookCategories.concat(selectedCategory);
      let newUniqueCategories = [];
      newUniqueCategories = Array.from(new Set(newCategories));

      // console.log("index",index);
      // console.log("selectedBookId",selectedBookId);
      // console.log("newUniqueCategories",newUniqueCategories);

      new ProductControllerApi(genApiConfig())
      .updateProductUsingPOST({
        productId: selectedBookId,
        integerListRequestModel: {
          integers: newUniqueCategories,
        } as IntegerListRequestModel,
      })
      .then(() => fetchApi());
    })
  };

  const handleAddClick = (value: any) => {};

  const handleDeleteClick = (value: any) => {};

  const tableHeaderCheck = (value: any) => {};

  const addAllToCategory = (value: any) => {};

  return (
    <>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(-1)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>

      <div className={style.PlatformContainer}>
        <div className={style.toggleGroupContainer}>
          {/* <div className={style.toggleGroup}>
            <Button
              buttonType={"button"}
              styleType={"toggleButton"}
              callBack={(value) => navigate(PATH.BOOKLIST)}
            >
              <Trans>CateList</Trans>
            </Button>
            <Button
              buttonType={"button"}
              styleType={"toggleButtonCurrent"}
              callBack={(value) => handletoggle(value)}
            >
              <Trans>PurchasedBook</Trans>
            </Button>
          </div>  */}
        </div>

        <>
          <div className={style.container}>
            <h4>{categoryId.categoryName}</h4>
            <div className={style.header}>
              <div className={style.row}>
                <Input
                  type="text"
                  placeholder={t("inputISBN")}
                  value={searchText}
                  styleType={STYLETYPE_FIFTH}
                  callBack={(value) => setSearchText(value)}
                />
                <div className={style.RowBtn}>
                  <Button
                    styleType={"searchButtom"}
                    callBack={() => handleSearchClick(searchText)}
                  >
                    <Trans>Search</Trans>
                  </Button>
                </div>
              </div>

              <div className={style.row}></div>

              <div className={style.rowSpan}>
                <Button
                  hidden={selectedBook.length > 0 ? false : true}
                  styleType={"searchButtom"}
                  callBack={(value) => handleDialogCallBack({ action: "ALL" })}
                >
                  全選並加入書單
                </Button>
              </div>
            </div>
            <div style={{ padding: "8px" }}>
              <div className={style.cardViewContainer}>
                {displayData.length > 0 &&
                  displayData.map((bookItem: any, key: number) => (
                    <BookLibraryCardView
                      data={bookItem}
                      callBack={(e) => handleDialogCallBack(e)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      </div>

      {/* Add */}
      <DialogBox
        styleType={STYLETYPE_MAIN}
        showDialog={showAddDialog}
        title={`${book && book.productNameChi}`}
        scroll={category.map((cate: any) => (
          <TreeMeunCategory
            book={book}
            multipleSelect={true}
            selectedCategoryList={selectedCategory}
            props={cate}
            callBack={(e: any) => handleShowCategoryMeun(e)}
          />
        ))}
      >
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"upperNavbarButton"}
            callBack={() => handleDialogCallBack({ action: CANCEL })}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            styleType={"upperNavbarButton"}
            callBack={() => handleDialogCallBack({ action: SAVE })}
          >
            確定
          </Button>
        </div>
      </DialogBox>

      {/* Edit */}
      <DialogBox
        styleType={STYLETYPE_SECOND}
        showDialog={showEditDialog}
        title={"更新書單"}
      ></DialogBox>

      {/* Delete */}

      <DialogBox
        styleType={STYLETYPE_SECOND}
        showDialog={showDeleteDialog}
        title={`確定之後，書名${book && book.productNameChi}將從${
          categoryId.categoryName
        }中上架。`}
      >
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"upperNavbarButton"}
            callBack={() => handleDialogCallBack({ action: CANCEL })}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            styleType={"upperNavbarButton"}
            callBack={() => handleDialogCallBack({ action: DELETE })}
          >
            確定
          </Button>
        </div>
      </DialogBox>

      {/* AddToCategory */}

      <DialogBox
        styleType={STYLETYPE_MAIN}
        showDialog={showAddAllToCategory}
        title={"全部加入書單"}
        scroll={category.map((cate: any) => (
          <TreeMeunCategory
            multipleSelect={true}
            selectedCategoryList={selectedCategory}
            props={cate}
            callBack={(e: any) => handleShowCategoryMeun(e)}
          />
        ))}
      >
        <div className={style.dialogButtonContainer}>
          <Button
            styleType={"upperNavbarButton"}
            callBack={() => handleDialogCallBack({ action: PATH.CANCEL })}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            styleType={"upperNavbarButton"}
            callBack={(e) => handleDialogCallBack({ action: "confirm" })}
          >
            儲存
          </Button>
        </div>
      </DialogBox>
    </>
  );
}
