import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { dateToString } from "../../../utils/GlobalFunction";
import {fetchFileFromURLToFile, fetchFileFromURLToFilePost} from "../../../utils/reader/fileUtils/fileUtil";
import style from "./BookReport.module.css";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/svg/back.svg";
import {
    BookReportBetweenDateUsingPOSTRequest,
    BookReportRequestModel,
    BookReportRequestModelReportTypesEnum,
    BookReportRequestModelTopReportTypeEnum,
    BookReportResponseModel,
    BorrowReportBetweenDateUsingPOSTRequest,
    CategoryControllerApi,
    CategoryResponseModel, GetUsingGETRequest,
    ReportControllerApi,
    ReportRecordControllerApi,
    ReportStorageControllerApi, ReportStorageDto,
    ReportStorageIdResponseModel,
    RequestCategoryChartsReportUsingPOSTRequest,
} from "../../../fetcher";
import * as PATH from "../../../utils/Contants";
import { genApiConfig } from "../../../utils/fetch-caller";
import useWindowSize from "../../../utils/useWindowSize";
import SelectOption from "../../../components/SelectOption/SelectOption";
import { STYLETYPE_SECOND } from "../../../utils/Contants";
import downIcon from "../../../assets/svg/down.svg";
import upIcon from "../../../assets/svg/up.svg";
import * as Storage from "../../../utils/local-storage";
import PageLoading from "../../loading/PageLoading";

export default function CategoryReport() {
    const [displayData, setDisplayData] = useState<CategoryResponseModel[]>();

    const [yearArr, setYearArr] = useState<any>([]);
    const [monthArr, setMonthArr] = useState<any>([]);
    const [startYear, setSartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [startMonth, setSartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [bookType, setBookType] = useState("ALL");
    const [type, setType] = useState<{ id: string; name: string; isChecked: boolean }[]>([
        { id: "WEB_BORROW", name: "網頁借閱", isChecked: true },
        { id: "MOBILE_BORROW", name: "APP借閱", isChecked: true },
        { id: "READ_ONLINE", name: "線上全本瀏覽", isChecked: true },
        { id: "PREVIEW", name: "試閱", isChecked: true },
    ]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [isbn, setIsbn] = useState([]);
    const [bookName, setBookName] = useState([]);
    const [cate, setcate] = useState([]);

    const { t, i18n } = useTranslation();
    const { width, height } = useWindowSize();

    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagelimit, setPageLimit] = useState(10);

    const [sortingObj, setSortingObj] = useState({
        web: false,
        app: false,
        online: false,
        preview: false,
        reserve: false,
        cant: false,
        concent: false,
        seq: false,
        time: false,
    });
    const [descending, setDescending] = useState(true);
    const navigate = useNavigate();

    const [lastdate, setLastDate] = useState("");

    const [isDownloadLoading, setIsDownloadLoading] = useState(false);


    const handleType = (e: any) => {
        const result = type.map((obj: { id: string; name: string; isChecked: boolean }) => {
            if (obj.id === e.target.id) {
                return { ...obj, isChecked: !obj.isChecked };
            }
            return obj;
        });
        setType(result);
    };

    useEffect(() => {
        if (Storage.instance.userInfo === null || !Storage.instance.userInfo.isAdmin) {
            <>{navigate(PATH.LOGIN_PATH)}</>;
        }
        var now = new Date();
        let yearArr = [];
        for (var i = 2000; i <= now.getFullYear(); i++) {
            yearArr.push({ id: i, name: i.toString() });
        }
        setYearArr(yearArr.reverse());
        let monthArr = [];
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                monthArr.push({ id: i, name: `0${i}` });
            } else {
                monthArr.push({ id: i, name: i.toString() });
            }
        }
        setMonthArr(monthArr);

        var nextyear = new Date(new Date().getFullYear(), 11, 31);
        const currentDayOfMonth = nextyear.getDate();
        const currentMonth = nextyear.getMonth(); // Be careful! January is 0, not 1
        const currentYear = nextyear.getFullYear();
        const dateString = currentYear + "-" + (currentMonth + 1) + "-" + currentDayOfMonth;
        setLastDate(dateString);
    }, []);

    const handleSubmit = (from: boolean) => {
        const dateObj = {
            startDate: start,
            endDate: end,
        };
        if (new Date(dateObj.startDate) > new Date(dateObj.endDate)) {
            return alert(`開始日期必須小於最後日期`);
        }

        let pagefrom = 0;
        // if (from) {
        //     pagefrom = (currentPage - 1) * Number(pagelimit);
        // }

        let a = {
            bookReportRequestModel: {
                bookType: bookType,
                reportTypes: type.filter((it) => it.isChecked).map((type) => type.id as BookReportRequestModelReportTypesEnum) as BookReportRequestModelReportTypesEnum[],
                startDate: dateObj.startDate,
                endDate: dateObj.endDate,
                // from: pagefrom,
                // limit: pagelimit,
            } as BookReportRequestModel,
        };

        console.log("model", a);

        new CategoryControllerApi(genApiConfig())
            .categoryChartsReportUsingPOST(a)
            .then((res) => {
                if (res !== undefined) {
                    console.log(res);
                    setDisplayData(res);
                    //setPageCount(res.pageTotal!!);
                }
            })
            .catch((err) => console.log("err", err));

        // if (!from) {
        //     setCurrentPage(1);
        // }
    };

    const handleIsbn = (e: any) => {
        const isbnResult = e.target.value.replaceAll(" ", "").split(",");
        setIsbn(isbnResult);
    };

    const handleBookName = (e: any) => {
        const bookNameResult = e.target.value.split(",");
        setBookName(bookNameResult);
    };

    const downloadBookReportRecord = () => {

        if(start.length === 0){
            return alert(`請選擇開始日期`);
        }

        if(end.length === 0){
            return alert(`請選擇完結日期`);
        }

        if (new Date(start) > new Date(end)) {
            return alert(`開始日期必須小於完結日期`);
        }

        const dateObj = {
            startDate: start,
            endDate: end,
        };
        if (new Date(dateObj.startDate) > new Date(dateObj.endDate)) {
            return alert(`開始日期必須小於最後日期`);
        }
        let b = {
            bookType: bookType,
            reportTypes: type.filter((it) => it.isChecked).map((type) => type.id as BookReportRequestModelReportTypesEnum) as BookReportRequestModelReportTypesEnum[],
            startDate: dateObj.startDate,
            endDate: dateObj.endDate,
        } as BookReportRequestModel;

        setIsDownloadLoading(true)

        // fetchFileFromURLToFilePost(`${process.env.REACT_APP_BACKEND}/report/categoryCharts`, `CategoryCharts_${dateToString(new Date(), "YYYYMMDDHHMMSS")}.xlsx`, b);

        new ReportControllerApi(genApiConfig())
            .requestCategoryChartsReportUsingPOST({
                bookReportRequestModel: b
            } as RequestCategoryChartsReportUsingPOSTRequest)
            .then((res: ReportStorageIdResponseModel) => reportStorageHandler(res))
    };

    const reportStorageHandler = (res: ReportStorageIdResponseModel) => {
        function timerFunc() {
            new ReportStorageControllerApi(genApiConfig())
                .getUsingGET({
                    id: res.reportStorageId
                } as GetUsingGETRequest)
                .then((res: ReportStorageDto) => {
                    // Status :
                    // -1 -> Invalid
                    //  0 -> Pending
                    //  1 -> Running
                    //  2 -> Readied
                    //  3 -> Deleted

                    if (res.status === -1 || res.status === 2 || res.status === 3) {
                        if (res.status === 2) {
                            fetchFileFromURLToFile(
                                `${process.env.REACT_APP_BACKEND}/file/${res.fileName}`,
                                res.fileName
                            ).then(() => {
                                setIsDownloadLoading(false)
                            })
                        } else {
                            setIsDownloadLoading(false)
                        }
                    } else {
                        let seconds = 1
                        setTimeout(timerFunc, seconds * 1000)
                    }
                })
        }

        let seconds = 1
        setTimeout(timerFunc, seconds * 1000)
    }

    const handletoggle = (value: any) => {};

    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };
    const handlePrevPage = () => {
        if (currentPage !== 1 && currentPage !== 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleNextPage = () => {
        if (currentPage !== pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleSelectPage = (value: any) => {
        setCurrentPage(parseInt(value.name));
    };

    const handlepagecount = () => {
        let pageArr = [];
        for (var i = 1; i <= pageCount; i++) {
            pageArr.push({
                id: i,
                name: i + "",
            });
        }

        return pageArr;
    };

    const handlepagelimit = () => {
        let pageArr = [];
        for (var i = 1; i <= 10; i++) {
            pageArr.push({
                id: i,
                name: i + "0",
            });
        }

        return pageArr;
    };

    // useEffect(() => {
    //     if (displayData != undefined && currentPage > 0) {
    //         handleSubmit(true);
    //     }
    // }, [currentPage]);

    console.log(displayData);
    return (
        <>
            {isDownloadLoading ? (<>
                <PageLoading/>
            </>) : (<></>)}
            <div className={style.Header}>
                <div className={style.backHomeButtonContainer}>
                    <div>
                        <Button styleType={"upperNavbarButton"} callBack={(value) => navigate(-1)}>
                            <img src={arrowBack} />
                            <Trans>PreviousPage</Trans>
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.toggleGroupContainer}>
                <div className={style.toggleGroup}>
                    {/* <div className={style.toggleSilder}>123</div> */}
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.READREPORT)}>
                        {/* <Trans>CateList</Trans> */}閱讀數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}借閱及瀏覽紀錄報告
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BOOKREPORT)}>
                        {/* <Trans>PurchasedBook</Trans> */}書籍數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButtonCurrent"} callBack={(value) => handletoggle(value)}>
                        主題分類排行榜
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.RESERVINGREPORT)}>
                        預約中查詢數據統計
                    </Button>
                    <Button buttonType={"button"} styleType={"toggleButton"} callBack={(value) => navigate(PATH.BORROWREADREPORT)}>
                        借閱瀏覽紀錄報表
                    </Button>
                </div>
            </div>
            <div className={style.BookReport}>
                <h2>主題分類排行榜</h2>
                <div>
                    <div>
                        <p>選擇統計類型:</p>
                        <input
                            type="radio"
                            id="all"
                            value="ALL"
                            name="bookType"
                            checked={bookType === "ALL" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="ALL">全部</label>
                        {/* <input
                            type="radio"
                            id="ebook"
                            value="EBOOK"
                            name="bookType"
                            checked={bookType === "EBOOK" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="EBOOK">電子書</label> */}
                        {/* <input
                            type="radio"
                            id="magazine"
                            value="MAGAZINE"
                            name="bookType"
                            checked={bookType === "MAGAZINE" ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBookType(e.target.value)}
                        />
                        <label htmlFor="MAGAZINE">電子雜誌</label> */}
                    </div>

                    <div className={style.labelstyle}>
                        <p>選擇統計類型:</p>
                        {type.map((it: { id: string; name: string; isChecked: boolean }) => (
                            <>
                                <input type="checkbox" id={it.id} value={it.id} checked={it.isChecked} onClick={(e) => handleType(e)} />
                                <label htmlFor={it.id}>{it.name}</label>
                            </>
                        ))}
                    </div>

                    <div className={style.datePickerContainer}>
                        <label htmlFor="start"></label>
                        <input type="date" id="start" name="trip-start" min="2018-01-01" max={lastdate} value={start} onChange={(e) => setStart(e.target.value)} />
                        <label htmlFor="end"> ~ </label>
                        <input type="date" id="end" name="trip-end" min="2018-01-01" max={lastdate} value={end} onChange={(e) => setEnd(e.target.value)} />
                    </div>
                    <div></div>
                </div>

                <div>
                    <Button styleType={"submitButton"} callBack={() => handleSubmit(false)}>
                        查詢
                    </Button>
                    <Button styleType={"submitButton"} callBack={() => downloadBookReportRecord()}>
                        匯出EXCEL
                    </Button>
                </div>
                <hr className={style.line}></hr>
                {/* <div className={style.showrecord}>
                    <div>顯示記錄 (預設為10項)</div>
                    <SelectOption styleType={PATH.STYLETYPE_FIFTH} items={handlepagelimit()} callBack={(value) => setPageLimit(value.name)} placeholder={""} />
                </div> */}
                <table>
                    <tr className={style.Tabletr}>
                        <th>排名</th>
                        <th>主分類</th>
                        <th>主題分類</th>
                        <th>次數</th>
                    </tr>

                    {displayData?.map((val) => {
                        return (
                            <tr>
                                <th>{val.seq!!}</th>
                                <th>{val.mainCategory!!}</th>
                                <th>{val.category!!}</th>
                                <th>{val.count!!}</th>
                            </tr>
                        );
                    })}

                    <tr></tr>
                </table>
                <div></div>
            </div>
        </>
    );
}
