import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import io, { Socket, ManagerOptions, SocketOptions } from "socket.io-client";
import * as PATH from "../Contants";

interface ISocketValue {
  socketRef: any;
  serverMessage: ISocketMsg;
  setSocketSend?: (data: ISocketSend) => void;
}

interface ISendMessage {
  sessionId?: string;
  bookId: string;
  bookConfig?: string;
  type: string;
}

export interface ISocketMsg {
  bookPage: IBookPage;
  detailPage: IDetailPage;
  onlineBook: IonlineBook;
  currentOnline?: number;
  sessionId?: string;
  ref: Array<string>;
  error?: boolean;
}

interface IBookPage {
  [key: string]: [];
}

interface IDetailPage {
  [key: string]: [];
}

interface IonlineBook {
  [key: string]: [];
}

export interface ISocketSend {
  type: string;
  bookId: string;
  sessionId?: string;
  needSend: boolean;
}

const initialDefault = {
  socketRef: undefined,
  serverMessage: {} as ISocketMsg,
};

export const SocketContext = createContext<ISocketValue>(initialDefault);

export const SocketProvider = ({ children, store }: any) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const [serverMessage, setServerMessage] = useState<ISocketMsg>(
    {} as ISocketMsg
  );

  const [socketSend, setSocketSend] = useState<ISocketSend>();

  const socketUrl = `${PATH.SOCKET_URL_PATH}${process.env.REACT_APP_CLIENT_ID}`;

  const socketRef = useRef<Socket>();

  const handleOnMessage = (message: ISocketMsg) => {
    //console.log("handleOnMessage", message);
    setServerMessage(message);
  };

  const handleStartSession = (message: ISocketMsg) => {
    //console.log("handleStartSession", message);
    const sessionId = sessionStorage.getItem(PATH.GUEST);
    if (sessionId === null) {
      sessionStorage.setItem(PATH.GUEST, message.sessionId!!);
    }
  };

  const handleEmitMessage = (sendMessage: ISendMessage) => {
    const sendData = {
      sessionId: sendMessage.sessionId,
      bookId: sendMessage.bookId,
    };

    socketRef.current!!.emit(sendMessage.type, JSON.stringify(sendData));
  };

  useEffect(() => {
    if (socketSend?.needSend) {
      let sendMessage = {} as ISendMessage;

      const guestInfo = sessionStorage.getItem(PATH.GUEST);

      sendMessage.sessionId = guestInfo!!;
      sendMessage.bookId = socketSend.bookId;
      sendMessage.type = socketSend.type;

      const resetSocketSend = {
        type: "",
        bookId: "",
        needSend: false,
      } as ISocketSend;

      setSocketSend(resetSocketSend);

      handleEmitMessage(sendMessage);
    }
  }, [socketSend]);

  useEffect(() => {
    const token = localStorage.getItem(PATH.ACCESS_TOKEN);
    const userInfo = localStorage.getItem(PATH.USER_INFO);
    let userInfoObj;
    if (userInfo !== null) {
      try {
        userInfoObj = JSON.parse(userInfo);
      } catch (e) {
        console.log("socket json e", e);
      }
    }

    if (!isConnected && socketRef.current === undefined) {
      socketRef.current = io(socketUrl, {
        transports: ["websocket"],
        auth: { token: token === null ? "" : token },
        query: {
          data: JSON.stringify({
            guest: sessionStorage.getItem(PATH.GUEST),
            userId:
              userInfoObj === undefined ? "" : userInfoObj.member.memberId,
          }),
        },
      });

      socketRef.current.on("connect", () => {
        //console.info(`connected to socket at ${socketUrl}`);
        //console.log("socketsocket", socketRef);
        setIsConnected(true);
      });

      socketRef.current.on("disconnect", () => {
        setIsConnected(false);
      });

      socketRef.current.on("error", (err: any) => {
        console.log("Socket Error:", err.message);
      });

      socketRef.current.on("message", handleOnMessage);
      socketRef.current.on("start-session", handleStartSession);
    }

    return () => {
      if (socketRef.current !== undefined) {
        if (socketRef.current.connected) {
          socketRef.current.disconnect();
        }
      }
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      const startMessage = {
        type: PATH.START_SESSION,
        needSend: true,
      } as ISocketSend;
      setSocketSend(startMessage);
    }
  }, [isConnected]);

  return (
    <SocketContext.Provider
      value={
        {
          socketRef,
          serverMessage,
          setSocketSend,
        } as ISocketValue
      }
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
