import { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import settingSvg from "../../assets/svg/setting@1x.svg";
import ticksvg from "../../assets/svg/tick.svg";
import { CategoryControllerApi, DashboardControllerApi } from "../../fetcher";
import i18n from "../../i18n";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import * as Storage from "../../utils/local-storage";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
import withNavigateHook from "../../utils/withNavigateHook";
import Button from "../Button/Button";
import style from "./SideMenu.module.css";
import arrowDown from "../../assets/svg/down.svg";
import arrowUp from "../../assets/svg/up.svg";
import SideMenu_TreeMeun from "./SideMenu_TreeMeun";

interface Props {
  isMobile?: boolean;
  show?: boolean;
  callBack?: (value: any) => void;
  handleUserInfo?: any;
  navigation?: any;
  headerItems?: any;
}

interface State {}

class SideMenu extends Component<Props, State> {
  state = {
    isselected: false,
    show: false,
    isLogined: false,
    displayCateData: [],
    showCategory: false,
    displayCollectData: [],
    showCollect: false,
  };

  static defaultProps = {
    isMobile: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps !== this.props) {
      if (nextProps !== null) {
        this.setState({
          show: nextProps.show,
        });
      }
      return;
    }
  }

  category = () => {
    let _flag = this.state.isselected;
    _flag = !_flag;

    this.setState({
      isselected: _flag,
    });
  };

  closeNav = () => {
    this.setState({
      show: false,
    });
    this.props.callBack?.(false);
  };
  componentDidMount() {
    new DashboardControllerApi(genApiConfig())
      .getDashboardSortUsingGET()
      .then((res) => {
        // console.log("category", res);
        this.setState({ ...this.state, displayCateData: res });
        // setDisplayCateData(res);
      });

    new CategoryControllerApi(genApiConfig())
      .getAllCollectionUsingGET()
      .then((res) => {
        this.setState({ ...this.state, displayCollectData: res });
      });
  }

  // setLogout = () => {
  //     localStorage.clear();
  //     props.myBookMarkList([]);
  //     setIsLogined(false);
  //     alert("登出成功");
  //     navigate(PATH.HOME_PATH);
  // };

  // setLogout() {
  //   fetch(`${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/logout`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `bearer ${Storage.instance.accessToken}`,
  //     },
  //   })
  //     .then((res) => {
  //       res.text().then((resText) => {
  //         console.log("rrrr", resText);
  //         this.props.handleUserInfo();
  //         let isPhone = localStorage.getItem("isPhone")
  //         let savedSchool = localStorage.getItem("savedSchool")
  //         localStorage.clear();
  //         localStorage.setItem("isPhone", isPhone!.toString())
  //         if(savedSchool!== null)  localStorage.setItem("savedSchool", savedSchool!)
  //         //window.location.pathname = PATH.HOME_PATH;
  //         //navigate(PATH.HOME_PATH);
  //         //window.location.reload();
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });

  //   this.props.navigation(PATH.HOME_PATH);
  //   window.location.reload();
  // }

  setLogout = () => {
    fetch(`${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/logout`, {
      method: "GET",
      headers: {
        Authorization: `bearer ${Storage.instance.accessToken}`,
      },
    })
      .then((res) => {
        res.text().then((resText) => {
          this.props.handleUserInfo();
          let isPhone = localStorage.getItem("isPhone");
          let savedSchool = localStorage.getItem("savedSchool");
          localStorage.clear();
          localStorage.setItem("isPhone", isPhone!.toString());
          if (savedSchool !== null)
            localStorage.setItem("savedSchool", savedSchool!);
          window.location.pathname = PATH.HOME_PATH;
          this.props.navigation(PATH.HOME_PATH);
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log("setLogout", err);
        window.location.pathname = PATH.HOME_PATH;
        this.props.navigation(PATH.HOME_PATH);
        window.location.reload();
      });
    localStorage.clear();
    window.location.pathname = PATH.HOME_PATH;
    this.props.navigation(PATH.HOME_PATH);
    window.location.reload();
  };

  render() {
    const { isselected, show } = this.state;
    const { isMobile } = this.props;

    return (
      <div style={{ display: show ? "block" : "none" }}>
        <div
          className={style.background_sidemenu}
          onClick={() => this.closeNav()}
        ></div>
        <div className={isMobile ? style.sideMenuMobile : style.sideMenu}>
          <div
            className={style.closeNav}
            //onClick={() => this.closeNav()}
          >
            {/* <div> X </div>
                        <div>
                            <Trans>close</Trans>
                        </div> */}
          </div>

          {isselected || !isMobile ? (
            <>
              {isMobile ? (
                <div className={style.back}>
                  <div onClick={() => this.category()}>
                    <Trans>Back</Trans>
                  </div>
                  <div></div>
                </div>
              ) : (
                <></>
              )}
              {/* <div className={style.cate}>
                                {treeData?.map((item, idx) => (
                                    <TreeMeun props={item} key={idx} />
                                ))}
                            </div> */}

              <div className={style.nav}>
                <ul>
                  <li>
                    <Link to={PATH.HOME_PATH}>
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() => this.closeNav()}
                      >
                        <Trans>Home</Trans>
                      </Button>
                    </Link>
                  </li>

                  <li
                    className={style.nav_item_lang}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Trans>Bookcategory</Trans>
                      <Link
                        to={"/search/result?inputOption=all"}
                        style={{ fontSize: "0.5rem" }}
                        onClick={() => this.closeNav()}
                      >
                        <Trans>all</Trans>
                      </Link>
                    </div>
                    {this.state.showCategory ? (
                      <div
                        className={style.arrow_btn}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showCategory: !this.state.showCategory,
                          })
                        }
                      >
                        <img src={arrowUp} />
                      </div>
                    ) : (
                      <div
                        className={style.arrow_btn}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showCategory: !this.state.showCategory,
                          })
                        }
                      >
                        <img src={arrowDown} />
                      </div>
                    )}
                  </li>
                  {this.state.showCategory &&
                    this.state.displayCateData &&
                    this.state.displayCateData.map((item: any, idx: any) => (
                      <SideMenu_TreeMeun props={item} key={idx} />
                    ))}

                  {this.state.displayCollectData &&
                  this.state.displayCollectData.length > 0 ? (
                    <>
                      <li
                        className={style.nav_item_lang}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Trans>Collection</Trans>
                        </div>
                        {this.state.showCollect ? (
                          <div
                            className={style.arrow_btn}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                showCollect: !this.state.showCollect,
                              })
                            }
                          >
                            <img src={arrowUp} />
                          </div>
                        ) : (
                          <div
                            className={style.arrow_btn}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                showCollect: !this.state.showCollect,
                              })
                            }
                          >
                            <img src={arrowDown} />
                          </div>
                        )}
                      </li>
                      {this.state.showCollect &&
                        this.state.displayCollectData &&
                        this.state.displayCollectData.map(
                          (item: any, idx: any) => (
                            <SideMenu_TreeMeun props={item} key={idx} />
                          )
                        )}
                    </>
                  ) : (
                    <></>
                  )}

                  {this.props.headerItems.headerItems &&
                    this.props.headerItems.headerItems
                      .filter(
                        (it: any) =>
                          // it.url == "/thematic" ||
                          // it.url === "/news" ||
                          // it.url === "/activity" ||
                          it.url === "/manual"
                      )

                      .map((headerItem: any) =>
                        headerItem.enable === true ? (
                          <li>
                            <Link to={"info" + headerItem.url}>
                              <Button
                                styleType={"upperNavbarButton"}
                                callBack={() => this.closeNav()}
                              >
                                {headerItem.chtName}
                              </Button>
                            </Link>
                          </li>
                        ) : (
                          <></>
                        )
                      )}

                  {/* <li>
                                        <Link to={PATH.THEMATIC}>
                                            <Button styleType={"upperNavbarButton"} callBack={() => this.closeNav()}>
                                                <Trans>BookFair</Trans>
                                            </Button>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={PATH.NEWS}>
                                            <Button styleType={"upperNavbarButton"} callBack={() => this.closeNav()}>
                                                <Trans>BookNews</Trans>
                                            </Button>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={PATH.ACTIVITY}>
                                            <Button styleType={"upperNavbarButton"} callBack={() => this.closeNav()}>
                                                <Trans>Activity</Trans>
                                            </Button>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={PATH.APPDOWNLOAD}>
                                            <Button styleType={"upperNavbarButton"} callBack={() => this.closeNav()}>
                                                <Trans>app</Trans>
                                            </Button>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={PATH.MANUAL}>
                                            <Button styleType={"upperNavbarButton"} callBack={() => this.closeNav()}>
                                                <Trans>manual</Trans>
                                            </Button>
                                        </Link>
                                    </li> */}

                  {/* {process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                    <></>
                  ) : (
                    <>
                      <li>
                        <Link to={"/selectOrg"}>
                          <Button
                            styleType={"upperNavbarButton"}
                            callBack={() => this.closeNav()}
                          >
                            <Trans>ChangeSchool</Trans>
                          </Button>
                        </Link>
                      </li>
                    </>
                  )} */}

                  <li
                    className={style.nav_item_lang}
                    onClick={() => {
                      i18n.changeLanguage("cht");
                      StorageUtil.setReaderConfig("lang", "cht");
                    }}
                    style={{ color: i18n.language === "cht" ? "#E37B3C" : "" }}
                  >
                    繁{i18n.language === "cht" ? <img src={ticksvg}></img> : ""}
                  </li>

                  <li
                    className={style.nav_item_lang}
                    onClick={() => {
                      i18n.changeLanguage("cn");
                      StorageUtil.setReaderConfig("lang", "cn");
                    }}
                    style={{ color: i18n.language === "cn" ? "#E37B3C" : "" }}
                  >
                    简{i18n.language === "cn" ? <img src={ticksvg}></img> : ""}
                  </li>

                  <li
                    className={style.nav_item_lang}
                    onClick={() => {
                      i18n.changeLanguage("en");
                      StorageUtil.setReaderConfig("lang", "en");
                    }}
                    style={{ color: i18n.language === "en" ? "#E37B3C" : "" }}
                  >
                    ENG{i18n.language === "en" ? <img src={ticksvg}></img> : ""}
                  </li>

                  {/* <li>
                                        <Button styleType={"upperNavbarButton"} callBack={() => this.category()}>
                                            <Trans>Category</Trans>
                                        </Button>
                                    </li> */}
                  <li>
                    {localStorage.getItem(PATH.USER_INFO) !== null ? (
                      <>
                        <Button
                          styleType={"upperNavbarButton"}
                          callBack={() => this.setLogout()}
                        >
                          <Trans>logout</Trans>
                        </Button>
                        &nbsp;&nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                  </li>
                  <li>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => console.log(1)}
                    >
                      {/* <img src={settingSvg} /> */}
                    </Button>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <div className={style.nav}>
              <ul>
                <li>
                  <Link to={PATH.HOME_PATH}>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => console.log(1)}
                    >
                      <Trans>Home</Trans>
                    </Button>
                  </Link>
                </li>

                <li>
                  <Link to={PATH.FRIST_PATH}>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => console.log(1)}
                    >
                      <Trans>BookFair</Trans>
                    </Button>
                  </Link>
                </li>

                <li>
                  <Link to={PATH.SECOND_PATH}>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => console.log(1)}
                    >
                      <Trans>BookNews</Trans>
                    </Button>
                  </Link>
                </li>

                <li>
                  <Link to={PATH.THRID_PATH}>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => console.log(1)}
                    >
                      <Trans>Activity</Trans>
                    </Button>
                  </Link>
                </li>

                <li>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => this.category()}
                  >
                    <Trans>Category</Trans>
                  </Button>
                </li>

                {/* <div> */}
                <li>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => console.log(1)}
                  >
                    <img src={settingSvg} />
                  </Button>
                </li>
                {/* </div> */}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = {
  handleUserInfo,
};
export default connect(
  mapStateToProps,
  actionCreator
)(withNavigateHook(SideMenu));
