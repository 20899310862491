import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import {
  STYLETYPE_CUSTOM,
  STYLETYPE_FIFTH,
  STYLETYPE_FOUR,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
  STYLETYPE_DATALIST,
  STYLETYPE_SEARCH_INPUT,
  STYLETYPE_SEARCH_INPUT_OPTION
} from "../../utils/Contants";
import style from "./SelectOption.module.css";

interface IProps {
  firstValue?: string;
  items?: any[];
  value?: string;
  styleType?: string;
  placeholder?: string;
  callBack?: (value: any, value2?: any) => void;
  multi?: boolean;
}

export default class SelectOption extends Component<IProps> {
  state = {
    currentSelected: "",
  };

  static defaultProps = {
    firstValue: "pleasechoose",
    value: "",
    styleType: STYLETYPE_MAIN,
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps !== this.props) {
      if (nextProps.value !== null) {
        this.setState({
          currentSelected: nextProps.value,
        });
      }
      return;
    }
  }

  handleSelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ currentSelected: event.target.value });
    this.props.callBack?.(
      this.props.items?.find((it) => it.name === event.target.value)
    );
  };

  handleSelectOptionOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ currentSelectedOption: event.target.value });
    this.props.callBack?.(event.target.value);
  };

  hanldeOnChangeText = (event: any) => {
    this.setState({ currentSelected: event.target.value });
    this.props.callBack?.(
      this.props.items?.find((it) => it.name === event.target.value)
    );
  };

  hanldeOnInputText = (event: any) => {
    this.props.callBack?.(event.target.value)
  }

  render() {
    const { firstValue, styleType, items, value, multi, placeholder } =
      this.props;
    const { currentSelected } = this.state;

    return (
      <Fragment>
        {styleType === STYLETYPE_MAIN ? (
          <div className={style.mainSelect}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <div className={style.overflow}>
              <select
                onChange={this.handleSelectOnChange}
                defaultValue={value !== "" ? value : ""}
                autoComplete={"off"}
              >
                {currentSelected.length > 0 ? (
                  items &&
                  items.map((item) => {
                    return (
                      <>
                        {item.name === currentSelected ? (
                          <option key={item.id} value={item.name} selected>
                            {item.name}
                          </option>
                        ) : (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <option key={"-1"} value="-1">
                      <Trans>{firstValue}</Trans>
                    </option>
                    {items &&
                      items.map((item) => {
                        return (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                  </>
                )}
              </select>
            </div>
          </div>
        ) : styleType === STYLETYPE_SECOND ? (
          <div className={style.secondSelect}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <div className={style.overflow}>
              <select
                onChange={this.handleSelectOnChange}
                defaultValue={value !== "" ? value : ""}
                autoComplete={"off"}
                multiple={multi}
              >
                {currentSelected.length > 0 ? (
                  items &&
                  items.map((item) => {
                    return (
                      <>
                        {item.name === currentSelected ? (
                          <option key={item.id} value={item.name} selected>
                            {item.name}
                          </option>
                        ) : (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <option key={"-1"} value="">
                      <Trans>{firstValue}</Trans>
                    </option>
                    {items &&
                      items.map((item) => {
                        return (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                  </>
                )}
              </select>
            </div>
          </div>
        ) : styleType === STYLETYPE_THIRD ? (
          <div className={style.thirdSelect}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
              multiple={multi}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="">
                    <Trans> {firstValue}</Trans>
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_FOUR ? (
          <div className={style.fourSelect}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
              multiple={multi}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  //  console.log(item.name, currentSelected);
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_FIFTH ? (
          <div className={style.fiveSelect}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
            >
              {currentSelected !== "" ? (
                items &&
                items.map((item, index) => {
                  return (
                    <>
                      {index === 0 ? (
                        <option key={"-1"} value="">
                          <Trans>{firstValue}</Trans>
                        </option>
                      ) : (
                        <></>
                      )}
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="">
                    <Trans>{firstValue}</Trans>
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_CUSTOM ? (
          <></>
        ) : styleType === STYLETYPE_DATALIST ? (
          <div
            className={style.inputPrimary}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{"SearchPublisherKeywordLabel"}</Trans>
              ) : (
                ""
              )}
            </label>
            <input
              id={'PublisherSearchInput'}
              type={""}
              placeholder={
                this.props.placeholder !== "" || this.props.placeholder !== null
                  ? this.props.placeholder
                  : ""
              }
              onChange={this.hanldeOnChangeText}

              list={"PublisherList"}
            />
            <datalist id="PublisherList">
              {(
                items &&
                items.map((item) => {
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              )}
            </datalist>
          </div>
        ) : styleType === STYLETYPE_SEARCH_INPUT ? (
          <div
            className={style.inputPrimary}
          >
            <input
              id={'InputKeyword'}
              type={""}
              placeholder={
                this.props.placeholder !== "" || this.props.placeholder !== null
                  ? this.props.placeholder
                  : ""
              }
              onChange={this.hanldeOnInputText}
            />
          </div>
        ) : styleType === STYLETYPE_SEARCH_INPUT_OPTION ? (
          <div className={[style.secondSelect, style.inputOption].join(' ')}>
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? (
                <Trans>{placeholder}</Trans>
              ) : (
                ""
              )}
            </label>
            <div className={style.overflow}>
              <select
                onChange={this.handleSelectOptionOnChange}
                autoComplete={"off"}
                multiple={multi}
              >

                <option value="all" selected>
                  <Trans>InputOptionAll</Trans>
                </option>
                <option value="bookName">
                  <Trans>InputOptionBookName</Trans>
                </option>
                <option value="author">
                  <Trans>InputOptionAuthor</Trans>
                </option>
                <option value="bookDescription">
                  <Trans>InputOptionBookDesc</Trans>
                </option>

              </select>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    );
  }
}
